import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { useState } from "react";
import { color } from "../../../theme/Color";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { HorizontalSpacer } from "../../../component/Spacer";
import { ICON_HIDE, ICON_SHOW } from "../../../constants/imagePath";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { useNavigate } from "react-router-dom";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  const navigate = useNavigate();

  const countryCodeMap: {
    "KR": string;
    KH: string;
    VN: string;
  } = {
    "KR" : "South Korea",
    KH: "Cambodia",
    VN: "Vietnam",
  };
  return (
    <>
      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/announcement/announceDetails/${row.id}`);
        }}
      >
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.title}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {countryCodeMap[row.country as keyof typeof countryCodeMap] ||
              row.country}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          {row.showHide == true ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box component="img" src={ICON_SHOW} />
              <HorizontalSpacer width={4} />
              <Typography fontStyle={fontStyle.regularXS}>Show</Typography>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box component="img" src={ICON_HIDE} />
              <HorizontalSpacer width={4} />
              <Typography fontStyle={fontStyle.regularXS}>Hide</Typography>
            </Box>
          )}
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.openDate == 0 ? (
              <></>
            ) : (
              <>
                {LocalizedDate({
                  date: row.openDate,
                  type: DateFormatType.AdminType,
                })}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.registrationDate == 0 ? (
              <></>
            ) : (
              <>
                {LocalizedDate({
                  date: row.registrationDate,
                  type: DateFormatType.AdminType,
                })}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.view}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface AnnoumceMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

const AnnoumceMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: AnnoumceMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },

    {
      id: "title",
      align: "left",
      disablePadding: false,
      label: "Title",
    },
    {
      id: "country",
      align: "left",
      disablePadding: false,
      label: "Country",
    },
    {
      id: "showHide",
      align: "left",
      disablePadding: false,
      label: "Show/Hide",
    },
    {
      id: "openDate",
      align: "left",
      disablePadding: false,
      label: "Open Date",
    },
    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Registration Date",
    },
    {
      id: "view",
      align: "left",
      disablePadding: false,
      label: "View",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows || []  , rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows?.map((row: any, index: any) => (
        <Row row={row} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default AnnoumceMngTable;
