import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { color } from "../theme/Color";
import {
  ICON_X_CIRCLE_GRAY,
  ICON_X_CIRCLE_PRIMARY500,
} from "../constants/appImagePath";
import { fontStyle } from "../theme/Style";

interface CommonSingleLineTextFieldProps {
  inputValue: string;
  heightPx: number;
  placeholder?: string;
  inputValueError?: boolean;
  closeIconSize?: number;
  onChange: (value: string) => void;
  onClear?: () => void;
}

export default function CommonSingleLineTextField({
  inputValue,
  heightPx = 38,
  placeholder,
  inputValueError = false,
  closeIconSize = 16,
  onChange,
  onClear,
}: CommonSingleLineTextFieldProps) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <TextField
        value={inputValue}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        rows={1}
        variant="outlined"
        fullWidth
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        InputProps={{
          style: {
            color: `${inputValue.length === 0 ? color.gray400 : color.default}`,
            height: `${heightPx}px`,
          },
        }}
        sx={{
          height: `${heightPx}px`,
          "& .MuiOutlinedInput-root": {
            borderRadius: "6px",
            border: `1px solid ${isFocused ? color.primary500 : inputValueError ? color.error : color.gray300}`,
            "& .MuiInputBase-input": {
              paddingLeft: "8px",
              paddingRight: "28px",
            },
            "& fieldset": {
              border: "0px",
            },

            "& textarea": {
              fontStyle: fontStyle.bodyMr,
            },
          },
        }}
      />

      <img
        src={ICON_X_CIRCLE_PRIMARY500}
        alt={""}
        style={{
          width: `${closeIconSize}px`,
          height: `${closeIconSize}px`,
          position: "absolute",
          top: `${heightPx / 2 - closeIconSize / 2}px`,
          right: "8px",
        }}
        onClick={onClear}
      />
    </Box>
  );
}
