import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import VerticalSpacer from "../../../../component/Spacer";
import { getCSStatusColor } from "../../../MobileInquiry/section/InquiryHistoryItem";
import { CSHistoryStatus } from "../../list/model/CSHistoryStatus";

interface CSDetailHeaderProps {
  status: CSHistoryStatus;
  createdDate: number;
  title: string;
}

export function CSDetailHeader({
  status,
  createdDate,
  title,
}: CSDetailHeaderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        py: "8px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography fontStyle={fontStyle.titleS} color={getCSStatusColor(status)}>
          {status}
        </Typography>
        <Typography fontStyle={fontStyle.bodySr} color={color.gray600}>
          {LocalizedDate({
            date: createdDate,
            type: DateFormatType.YearMonthDayTime,
          })}
        </Typography>
      </Box>
      <VerticalSpacer height={8} />
      <Typography
        fontStyle={fontStyle.titleL}
        color={color.default}
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
