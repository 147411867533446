import React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { ICON_CHECK2, ICON_UNCHECK } from "../constants/appImagePath";
import { fontStyle } from "../theme/Style";

interface CommonIndeterminateCheckBoxProps {
  parentLabel: string;
  childLabels: string[];
  checked: boolean[];
  setChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
  onParentClick?: () => void;
  disabled?: boolean;
}

const CommonIndeterminateCheckBox = ({
  parentLabel,
  childLabels,
  checked,
  setChecked,
  onParentClick,
  disabled,
}: CommonIndeterminateCheckBoxProps) => {
  const handleParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(Array(childLabels.length).fill(isChecked));
    if (onParentClick) onParentClick();
  };

  const handleChildChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedChecked = [...checked];
      updatedChecked[index] = event.target.checked;
      setChecked(updatedChecked);
    };

  const allChecked = checked.every(Boolean);
  const isIndeterminate = checked.some(Boolean) && !allChecked;

  return (
    <Box sx={{ p: "16px" }}>
      <FormControlLabel
        sx={{
          my: -0.5,
          color: disabled ? "gray" : "inherit", // 부모 라벨 텍스트 색상
        }}
        label={
          <Typography fontStyle={fontStyle.regularXS}>{parentLabel}</Typography>
        }
        control={
          <Checkbox
            checked={allChecked}
            indeterminate={isIndeterminate}
            onChange={handleParentChange}
            icon={
              <img
                src={ICON_UNCHECK}
                alt="unchecked"
                style={{
                  filter: disabled ? "grayscale(100%)" : "none", // 비활성화 시 회색
                }}
              />
            }
            indeterminateIcon={
              <img
                src={ICON_UNCHECK}
                alt="indeterminate"
                style={{
                  filter: disabled ? "grayscale(100%)" : "none",
                }}
              />
            }
            checkedIcon={
              <img
                src={ICON_CHECK2}
                alt="checked"
                style={{
                  filter: disabled ? "grayscale(100%)" : "none",
                }}
              />
            }
            disabled={disabled}
          />
        }
      />
      <Box sx={{ display: "flex", flexDirection: "column", ml: 1.8 }}>
        {childLabels.map((label, index) => (
          <FormControlLabel
            sx={{
              my: -0.5,
              color: disabled ? "gray" : "inherit", // 자식 라벨 텍스트 색상
            }}
            key={index}
            label={
              <Typography fontStyle={fontStyle.regularXS}>{label}</Typography>
            }
            control={
              <Checkbox
                checked={checked[index]}
                onChange={handleChildChange(index)}
                icon={
                  <img
                    src={ICON_UNCHECK}
                    alt="unchecked"
                    style={{
                      filter: disabled ? "grayscale(100%)" : "none",
                    }}
                  />
                }
                indeterminateIcon={
                  <img
                    src={ICON_UNCHECK}
                    alt="indeterminate"
                    style={{
                      filter: disabled ? "grayscale(100%)" : "none",
                    }}
                  />
                }
                checkedIcon={
                  <img
                    src={ICON_CHECK2}
                    alt="checked"
                    style={{
                      filter: disabled ? "grayscale(100%)" : "none",
                    }}
                  />
                }
                disabled={disabled}
              />
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default CommonIndeterminateCheckBox;
