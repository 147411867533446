import ProductItem from "../../model/ProductItem";
import { Box, Typography } from "@mui/material";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer from "../../../../component/Spacer";
import ProductCard from "./ProductCard";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";

interface MembershipSectionProps {
  membershipData: ProductItem[];
}

const MembershipSection = ({ membershipData }: MembershipSectionProps) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          px: "16px",
          boxSizing: "border-box",
        }}
      >
        {membershipData
          .filter((item) => !item.name.includes("Lite"))
          .map((item, index) => (
            <ProductCard
              key={item.id}
              productType="Membership"
              title={item.name}
              price={`$ ${item.price}`}
              description={item.description}
              tag={item.label}
            />
          ))}
      </Box>

      <VerticalSpacer height={60} />

      <Typography
        fontStyle={fontStyle.titleL}
        color={color.default}
        sx={{ textAlign: "center", whiteSpace: "pre-line" }}
      >
        {LocalizedText("ua_g410_lowermembership")}
      </Typography>

      <VerticalSpacer height={32} />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          px: "16px",
          boxSizing: "border-box",
        }}
      >
        {membershipData
          .filter((item) => item.name.includes("Lite"))
          .map((item, index) => (
            <ProductCard
              key={item.id}
              productType="Membership"
              title={item.name}
              price={`$ ${item.price}`}
              description={item.description}
              tag={item.label}
            />
          ))}
      </Box>
    </Box>
  );
};

export default MembershipSection;
