import { Box, Drawer, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { MembershipOverviewUiEvent } from "./MembershipOverviewUiEvent";
import { MembershipOverviewUiState } from "./MembershipOverviewUiState";
import { MembershipOverviewSideEffect } from "./MembershipOverviewSideEffect";
import MileageSection from "./section/MileageSection";
import InfoCard from "./section/InfoCard";
import StatusDescription from "./section/StatusDescription";
import OtherChoiceButton from "./section/OtherChoiceButton";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import PurchaseDrawer from "../section/PurchaseDrawer";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import { MembershipStatus } from "../model/MembershipInfo";
import Toolbar from "../../../component/Toolbar";
import { Text } from "../../../component/Text";

interface MembershipOverviewRenderProps {
  sideEffect: MembershipOverviewSideEffect;
  uiState: MembershipOverviewUiState;
  uiEvent: MembershipOverviewUiEvent;
  userUUID: string;
}

const MembershipOverviewRender = ({
  sideEffect,
  uiState,
  uiEvent,
  userUUID,
}: MembershipOverviewRenderProps) => {
  const hasMembership = uiState.membershipInfo.status;
  const remainingMileage = uiState.membershipInfo.status
    ? (uiState.membershipInfo.membershipAllowanceKm ?? 0) +
      uiState.membershipInfo.voucherKm
    : uiState.membershipInfo.voucherKm;
    
  const renewalDate = hasMembership ? new Date(uiState.membershipInfo.endDate ?? 0) : null;
  renewalDate?.setDate(renewalDate.getDate() + 1);

  const handleMembeshipChangeClick = () => {
    (uiState.membershipStatus === MembershipStatus.Expired
      ? uiEvent.ShowChangeMembershipDrawer
      : uiEvent.ShowChangeMembershipDialog)();
  };

  const changeMembershipMessage = LocalizedText(
    "ua_g420_alert_changememebership_message"
  );
  const notPeriodChangeMessage = LocalizedText(
    "ua_g420_alert_notperiodchange_message"
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g410_membership")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: color.white,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "auto",
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            position: "relative",
          }}
        >
          <Box
            sx={{
              zIndex: 0,
              width: "100%",
              height: "auto",
              px: "20px",
              pt: "30px",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "282px",
                background: "linear-gradient(125deg, #5D38E5 0%, #9E84FF 100%)",
                position: "absolute",
                zIndex: -1,
                top: 0,
                left: 0,
              }}
            />
            {/* Remaining Mileage Section */}
            <MileageSection
              remainingMileage={remainingMileage}
              maxMileage={uiState.membershipInfo.totalAllowance ?? 0}
              onClick={() => sideEffect.navigate(ROUTE_PATHS.USAGE_HISTORY)} // 마일리지 사용내역 페이지로 이동
            />
            <VerticalSpacer height={24} />
            {/* Details Section */}
            <InfoCard
              membershipItem={uiState.membershipInfo}
              status={uiState.membershipStatus}
              onClickVoucher={uiEvent.ShowVoucherDrawer} // 바우처 구매 모달 출력
              onClickExtend={() =>
                sideEffect.navigate(ROUTE_PATHS.PAY_QR, {
                  state: {
                    productId: uiState.membershipInfo.productId,
                    userUUID: userUUID,
                  },
                })
              } // QR 결제 페이지로 이동
              onClickPurchaseHistory={() =>
                sideEffect.navigate(ROUTE_PATHS.PURCHASE_HISTORY)
              } // 구매내역 페이지로 이동
            />
            <VerticalSpacer height={20} />
            {/* Membership Status Description */}
            <StatusDescription
              status={uiState.membershipStatus}
              renewalStartDate={LocalizedDate({
                date: uiState.membershipInfo.extendStartDate ?? 0,
                type: DateFormatType.YearMonthDay,
              })}
              renewalEndDate={LocalizedDate({
                date: uiState.membershipInfo.extendEndDate ?? 0,
                type: DateFormatType.YearMonthDay,
              })}
            />
            <VerticalSpacer height={32} />
            {/* Other Choices */}

            <OtherChoiceButton // 멤버십 변경
              text={LocalizedText("ua_g420_needchange")}
              buttonText={LocalizedText("ua_g420_changemembership")}
              backgroundColor={color.primary50}
              borderColor={color.primary200}
              onClick={handleMembeshipChangeClick}
            />
            <VerticalSpacer height={16} />

            <OtherChoiceButton // 둘러보기
              text={LocalizedText("ua_g420_explore_message")}
              buttonText={LocalizedText("ua_g420_explore_title")}
              backgroundColor={color.secondary100}
              borderColor={color.secondary400}
              onClick={() =>
                sideEffect.navigate(ROUTE_PATHS.MEMBERSHIP_PURCHASE, {
                  state: { userUUID: userUUID, hidePurchaseButton: true },
                })
              }
            />
          </Box>

          <VerticalSpacer height={24} />

          <Box
            sx={{
              borderTop: `1px solid ${color.gray200}`,
              bgcolor: color.gray50,
              width: "100%",
              height: "auto",
              position: "relative",
            }}
          >
            <Typography
              component="ul"
              sx={{ marginTop: "20px", marginRight: "20px" }}
            >
              <Typography
                component="li"
                fontStyle={fontStyle.bodyMr}
                color={color.gray800}
                sx={{
                  marginBottom: "10px",
                }}
              >
                {LocalizedText("ua_g420_plzextend")}
              </Typography>
              <Typography
                component="li"
                fontStyle={fontStyle.bodyMr}
                color={color.gray800}
                sx={{
                  marginBottom: "10px",
                }}
              >
                {LocalizedText("ua_g420_voucherinfo")}
              </Typography>
            </Typography>

            <VerticalSpacer height={20} />
          </Box>

          {/** Purchasing Voucher Drawer **/}
          <Drawer
            anchor="bottom"
            open={uiState.isVoucherDrawerOpen}
            onClose={uiEvent.HideVoucherDrawer}
            sx={{
              "& .MuiDrawer-paper": {
                width: "100%",
                height: "auto",
                maxHeight: "calc(100vh - 40px)",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              },
            }}
          >
            <PurchaseDrawer
              productOptions={uiState.voucherItems}
              selectedProduct={uiState.selectedVoucher}
              onSelectProduct={(product) => {
                uiEvent.SelectVoucher(product);
              }}
              onClose={uiEvent.HideVoucherDrawer}
              onPay={(product) => {
                sideEffect.navigate(ROUTE_PATHS.PAY_QR, {
                  state: {
                    productId: product.id,
                    userUUID: userUUID,
                  },
                });
              }}
              titleKey="Purchasing Voucher"
            />
          </Drawer>

          {/** Changing Membership Drawer  **/}
          <Drawer
            anchor="bottom"
            open={uiState.isChangeMembershipDrawerOpen}
            onClose={uiEvent.HideChangeMembershipDrawer}
            sx={{
              "& .MuiDrawer-paper": {
                width: "100%",
                height: "auto",
                maxHeight: "calc(100vh - 40px)",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              },
            }}
          >
            <PurchaseDrawer
              productOptions={uiState.changeMembershipItems}
              selectedProduct={uiState.selectedMembership}
              onSelectProduct={(product) => {
                uiEvent.SelectMembership(product);
              }}
              onClose={uiEvent.HideChangeMembershipDrawer}
              onPay={(product) => {
                sideEffect.navigate(ROUTE_PATHS.PAY_QR, {
                  state: {
                    productId: product.id,
                    userUUID: userUUID,
                  },
                });
              }}
              titleKey="Changing Membership"
            />
          </Drawer>

          {/** Changing Membership Dialog **/}
          {uiState.membershipStatus == MembershipStatus.ActiveWithinRenewal && (
            <SimpleDialog
              positiveText="OK"
              nagativeText="Cancel"
              isOpen={uiState.isChangeMembershipDialogOpen}
              onPositive={() => {
                uiEvent.HideChangeMembershipDialog();
                uiEvent.ShowChangeMembershipDrawer();
              }}
              onNagative={uiEvent.HideChangeMembershipDialog}
              onClose={uiEvent.HideChangeMembershipDialog}
            >
              {changeMembershipMessage}{" "}
              <strong>
                {LocalizedDate({
                  date: renewalDate?.getTime() ?? 0,
                  type: DateFormatType.YearMonthDay,
                })}
              </strong>
            </SimpleDialog>
          )}

          {uiState.membershipStatus ==
            MembershipStatus.ActiveOutsideRenewal && (
            <SimpleDialog
              positiveText="OK"
              isOpen={uiState.isChangeMembershipDialogOpen}
              onPositive={uiEvent.HideChangeMembershipDialog}
              onClose={uiEvent.HideChangeMembershipDialog}
            >
              <Typography
                fontStyle={fontStyle.bodyMr}
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {notPeriodChangeMessage}{" "}
                <strong>
                  {LocalizedDate({
                    date: uiState.membershipInfo.extendStartDate ?? 0,
                    type: DateFormatType.YearMonthDay,
                  })}
                </strong>
              </Typography>
            </SimpleDialog>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MembershipOverviewRender;
