import { Box, Paper, Typography } from "@mui/material";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { ICON_BELL_FILL, ICON_TARGET } from "../../../constants/appImagePath";
import VerticalSpacer from "../../../component/Spacer";
import { color } from "../../../theme/Color";
import EmergencyDialog from "../../MobileSupport/section/EmergencyDialog";
import { PWUserAppMapUiState } from "../PWUserAppMapUiState";
import { PWUserAppMapSideEffect } from "../PWUserAppMapSideEffect";
import { PWUserAppMapUiEvent } from "../PWUserAppMapUiEvent";
import { fontStyle } from "../../../theme/Style";

interface RightBottomButtonProps {
  uiState: PWUserAppMapUiState;
  sideEffect: PWUserAppMapSideEffect;
  uiEvent: PWUserAppMapUiEvent;
}

const RightBottomButton = ({
  uiState,
  sideEffect,
  uiEvent,
}: RightBottomButtonProps) => {
  let emergencyNumber: string = "+855 069 878 689";
  // 권한 확인 및 요청 함수
  const handleLocationRequest = () => {
    if (window.Android) {
      if (uiState.isLocationPermissionGranted == true) {
        uiEvent.CoordinatesClickTrue();
      } else {
        console.log("requestLocationPermission");
        window.Android.requestLocationPermission();
      }
    } else if (window.webkit) {
      uiEvent.CoordinatesClickTrue();
      window.webkit.messageHandlers.requestLocationPermission.postMessage(
        "requestLocationPermission"
      );
    }
    console.log(
      "uiState.isLocationPermissionGranted is : ",
      uiState.isLocationPermissionGranted
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        position: "fixed",
        zIndex: 10,
        bottom: "16px",
        right: "20px",
      }}
    >
      <Paper
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "44px",
          height: "44px",
          boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.2)",
          borderRadius: "44px",
          bgcolor: color.errorBg,
        }}
        elevation={1}
        onClick={() => uiEvent.ShowEmergencyDialog()}
      >
        <Box component="img" src={ICON_BELL_FILL} width={18} />
        <Typography fontStyle={fontStyle.titleS} color={color.error}>
          {LocalizedText("ua_c300_call")}
        </Typography>
      </Paper>
      <VerticalSpacer height={16} />
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "44px",
          height: "44px",
          boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.2)",
          borderRadius: "44px",
        }}
        elevation={1}
        onClick={() => {
          // TODO 심사 버전에는 disable
          handleLocationRequest();
        }}
      >
        <Box component="img" src={ICON_TARGET} />
      </Paper>
      <EmergencyDialog
        isOpen={uiState.isShowEmergencyDialog}
        title={LocalizedText("common_alert_roadside_subtitle")}
        subTitle={LocalizedText("common_alert_roadside_title")}
        content={LocalizedText("common_alert_roadside_message")}
        iconText={LocalizedText("common_alert_roadside_inquiry_button")}
        buttonText={emergencyNumber} /// TODO
        onClick={() => {
          sideEffect.callEmergency(emergencyNumber);
          uiEvent.HideEmergencyDialog();
        }}
        onClose={() => uiEvent.HideEmergencyDialog()}
      />
    </Box>
  );
};

export default RightBottomButton;
