import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Methodology } from "../../../store/methodologyType";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { MethodologyType } from "../methodology/enum";

interface MethodologyTableProps {
  rowsPerPage: number;
  rows: Methodology[];
  page: number;
  onChangePage: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  count: number;
  data: Methodology[];
}

const CustomCell = ({
  title,
  align,
  onSort,
}: {
  title: string;
  align: "left" | "center" | "right";
  onSort?: () => void;
}) => {
  return (
    <TableCell
      sx={{
        backgroundColor: "white",
        color: "#99989D",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "var(--bs-font-sans-serif)",
        overflow: "hidden",
        whiteSpace: "nowrap",
        position: "relative",
      }}
      align={align}
    >
      <span style={{ marginRight: "8px" }}>{title}</span>
      {onSort && (
        <div
          onClick={onSort}
          style={{
            position: "absolute",
            right: -8,
            top: "40%",
            transform: "translateY(-50%)",
          }}
        >
          <UnfoldMoreIcon
            style={{
              marginTop: "10px",
              marginRight: "18px",
              fontSize: "16px",
              color: "#1D1B26",
            }}
          />
        </div>
      )}
    </TableCell>
  );
};

const MethodologyTable: React.FC<MethodologyTableProps> = ({
  data,
  rowsPerPage,
  onChangePage,
  count,
  page,
}) => {
  const navigate = useNavigate();

  const [dateSortOrder, setDateSortOrder] = useState<"asc" | "desc">("asc");
  const [timeSortOrder, setTimeSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedData, setSortedData] = useState<Methodology[]>(data);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleDateSort = () => {
    const newOrder = dateSortOrder === "asc" ? "desc" : "asc";
    const sorted = [...sortedData].sort((a, b) => {
      const dateA = new Date(a.createdAt || "");
      const dateB = new Date(b.createdAt || "");
      return newOrder === "asc"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });

    setSortedData(sorted);
    setDateSortOrder(newOrder);
  };

  const handleTimeSort = () => {
    const newOrder = timeSortOrder === "asc" ? "desc" : "asc";
    const sorted = [...sortedData].sort((a, b) => {
      const timeA = new Date(a.createdAt || "").getTime();
      const timeB = new Date(b.createdAt || "").getTime();
      return newOrder === "asc" ? timeA - timeB : timeB - timeA;
    });

    setSortedData(sorted);
    setTimeSortOrder(newOrder);
  };

  const handleRowClick = (id: number) => {
    navigate(`/methodologyMng/updateMethodology/${id}`);
  };

  const standardToKorean = (standard: string) => {
    const mapping: { [key: string]: string } = {
      [MethodologyType.DOMESTIC]: "국내 방법론",
      [MethodologyType.INTERNATIONAL]: "국외 방법론",
      [MethodologyType.CDM]: "CDM",
      [MethodologyType.ALL]: "전체",
    };
    return mapping[standard] || standard;
  };

  return (
    <Paper
      sx={{
        width: "100%",
        border: 0,
        borderColor: "#F3F3F4",
        borderRadius: "15px",
        mt: 3,
      }}
      elevation={0}
    >
      <TableContainer
        sx={{
          overflow: "hidden",
          border: 0,
          borderRadius: "15px",
          borderBottom: "1px solid #E9E9EA",
        }}
      >
        <Table
          sx={{
            width: "100%",
            border: "0px solid #E9E9EA",
            borderCollapse: "collapse",
            "&th": {
              border: "1px solid #E9E9EA",
            },
          }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <CustomCell title="No" align="center" />
              <CustomCell title="방법론 기준" align="center" />
              <CustomCell title="방법론명" align="center" />
              <CustomCell title="방법론 ID" align="center" />
              <CustomCell title="버전" align="center" />
              <CustomCell
                title="유효기간 시작일"
                align="center"
                onSort={handleDateSort}
              />
              <CustomCell
                title="생성일"
                align="center"
                onSort={handleTimeSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length > 0 ? (
              sortedData.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    ":hover": {
                      backgroundColor: "#F9F6FD",
                      cursor: "pointer",
                    },
                    "& td": {
                      color: "#53515A",
                      "& .MuiSvgIcon-root": {
                        color: "red",
                      },
                    },
                  }}
                  onClick={() => handleRowClick(row.id)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{ color: "#53515A", fontWeight: 400 }}
                  >
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {standardToKorean(row.standard)}
                  </TableCell>
                  <TableCell align="center">{row.title}</TableCell>
                  <TableCell align="center">{row.methodologyIdName}</TableCell>
                  <TableCell align="center">{row.version}</TableCell>
                  <TableCell align="center">
                    {format(new Date(row.startDate), "yyyy/MM/dd")}
                  </TableCell>
                  <TableCell align="center">
                    {row.createdAt
                      ? format(
                          new Date(Number(row.createdAt) * 1000),
                          "yyyy/MM/dd HH:mm"
                        )
                      : "Invalid date"}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  조회 할 데이터가 없습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <hr style={{ border: "1px #E9E9EA " }} />
      </div>
      <Pagination
        sx={{
          mt: 2,
          ml: 1,
          mb: 2,
          display: "flex",
          justifyContent: "flex-start",
          size: "medium",
          gap: "10px",
          opacity: 1,
          "& .MuiPaginationItem-root": {
            color: "blue",
            backgroundColor: "white",
            borderRadius: "50%",
            minWidth: "28px",
            height: "28px",
            padding: "4px",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
            "&.Mui-selected": {
              backgroundColor: "#EFECFD",
              color: "#5D38E5",
              shape: "circular",
            },
          },
        }}
        count={Math.ceil(count / rowsPerPage)}
        shape="rounded"
        page={page + 1}
        color="primary"
        onChange={onChangePage}
      />
    </Paper>
  );
};

export default MethodologyTable;
