import { Tooltip, TooltipProps, tooltipClasses, styled } from "@mui/material";
import { color } from "../theme/Color";
import { fontStyle } from "../theme/Style";

const CommonTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    PopperProps={{
      //disablePortal: true,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, -15], // 이 값을 조절하여 위치 조정
          },
        },
      ],
    }}
    disableFocusListener
    disableHoverListener
    disableTouchListener // 해당 옵션을 추가하여, 자동 close를 방지
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: color.primary50,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color.primary50,
    color: "#676665",
    fontStyle: fontStyle.bodySr,
    border: "1px solid #E6E8ED",
    borderRadius: "10px",
    margin: "16px",
    padding: "8px",
  },
}));

export default CommonTooltip;
