import { Status, Wrapper } from "@googlemaps/react-wrapper";
import PWUserAppMapRender from "./PWUserAppMapRender";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { getLocale } from "../../customHook/useLocale";
import { useEffect, useState } from "react";

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    case Status.FAILURE:
      return <>Error</>;
    case Status.SUCCESS:
      return <PWUserAppMapRender />;
  }
};

const PWUserAppMapPage = () => {
  const [locale, setLocale] = useState(getLocale());

  useEffect(() => {
    localStorage.setItem("auto_login", "true");
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const newLanguageCode = getLocale();
      if (newLanguageCode !== locale) {
        setLocale(newLanguageCode);
        // window.location.reload(); // TODO 언어 변경시 페이지 새로고침
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [locale]); // locale을 의존성 배열에 추가

  return (
    <Wrapper
      apiKey="AIzaSyAeFkKWDx0JWkB808pK61mD-dB9Jc3hcJ4"
      render={render}
      libraries={["marker"]}
      language={locale}
    />
  );
};

export default PWUserAppMapPage;
