import { Box } from "@mui/material";
import Toolbar from "../../../component/Toolbar";
import { NoticeList } from "./section/NoticeList";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { Notice } from "./model/Notice";
import VerticalSpacer from "../../../component/Spacer";

interface NoticePageRenderProps {
  noticeList: Notice[];
  onClick: (id: string) => void;
}

export default function NoticePageRender({
  noticeList,
  onClick,
}: NoticePageRenderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        overflowY: "hidden",
      }}
    >
      <Toolbar
        onBack={() => {
          window.history.back();
        }}
        title={LocalizedText("ua_f510_notice")}
      />
      <Box
        sx={{
          overflowY: "scroll",
        }}
      >
        <NoticeList noticeList={noticeList} onClick={onClick} />
      </Box>
    </Box>
  );
}
