import React, { useState } from "react";
import { Box, hexToRgb, TextField, Typography } from "@mui/material";
import { color } from "../theme/Color";
import { ICON_X_CIRCLE_PRIMARY500 } from "../constants/appImagePath";
import { fontStyle } from "../theme/Style";

interface CommonMultiLineTextFieldProps {
  inputValue: string;
  maxLength?: number;
  heightPx: number;
  placeholder?: string;
  inputValueError?: boolean;
  closeIconSize?: number;
  onChange: (value: string) => void;
  onClear?: () => void;
}

export default function CommonMultiLineTextField({
  inputValue,
  maxLength,
  heightPx,
  placeholder,
  inputValueError = false,
  closeIconSize = 16,
  onChange,
  onClear,
}: CommonMultiLineTextFieldProps) {
  const [isFocused, setIsFocused] = useState(false); // 포커스 상태 관리

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <TextField
        value={inputValue}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        multiline
        variant="outlined"
        fullWidth
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        InputProps={{
          style: {
            color: `${inputValue.length === 0 ? color.gray400 : color.default}`,
            height: `${heightPx}px`,
            overflow: "auto",
          },
        }}
        sx={{
          height: `${heightPx}px`,
          "& .MuiOutlinedInput-root": {
            padding: "8px",
            paddingTop: "8px",
            alignItems: "stretch",
            borderRadius: "6px",
            border: `1px solid ${isFocused ? color.primary500 : inputValueError ? color.error : color.gray300}`,
            "& .MuiInputBase-input": {
              paddingRight: "20px",
            },
            "& fieldset": {
              border: "0px",
            },

            "& textarea": {
              fontStyle: fontStyle.bodyMr,
            },
          },
        }}
      />

      <img
        src={ICON_X_CIRCLE_PRIMARY500}
        alt={""}
        style={{
          width: `${closeIconSize}px`,
          height: `${closeIconSize}px`,
          position: "absolute",
          top: `8px`,
          right: "8px",
        }}
        onClick={onClear}
      />
      <Typography
        sx={{
          fontStyle: fontStyle.bodyMr,
          opacity: inputValue.length == 0 ? 0.3 : 1,
          color: color.black,
          position: "absolute",
          bottom: "-24px",
          right: 0,
        }}
      >
        {inputValue.length}/{maxLength}
      </Typography>
    </Box>
  );
}
