import { Box } from "@mui/material";
import { color } from "../theme/Color";

interface DividerProps {
  height?: number;
}

export function Divider({ height = 8 }: DividerProps) {
  return (
    <Box
      sx={{
        width: "100%",
        height: `${height}px`,
      }}
    >
      <Box
        sx={{
          height: "1px",
          backgroundColor: color.gray300,
        }}
      />
      <Box
        sx={{
          height: `${height - 1}px`,
          backgroundColor: color.gray100,
        }}
      />
    </Box>
  );
}

export function HorizontalGrayDivider({}) {
  return (
    <Box
      sx={{
        minHeight: "1px",
        width: "100%",
        backgroundColor: color.gray200,
      }}
    ></Box>
  );
}
