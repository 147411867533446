import {
  Box,
  Card,
  darken,
  Divider,
  Grid,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ICON_ADD,
  ICON_COPY,
  ICON_EDIT,
  ICON_SHOW_WHITE,
  ICON_TRASH2,
} from "../../../../constants/imagePath";
import PopupManagementCreateCard from "./PopupManagementCreateCard";
import { PopupManagementUiState } from "../model/PopupManagementUiState";
import { PopupManagementUiEvent } from "../model/PopupManagementUiEvent";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import CommonDialog from "../../../../component/CommonDialog";
import CommonTextField from "../../../../component/CommonTextField";
import { CommonSelect } from "../../../../component/CommonSelect";
import CommonDatePicker from "../../../../component/CommonDatePicker";

export interface DataType {
  id: string;
  title: string;
  country: string;
  state: string;
  startDate: number;
  endDate: number;
  link: string;
  image: string;
  editCard: boolean;
}

interface PopupGridProps {
  data: DataType;
  countryLists: any;
  uiEvent: PopupManagementUiEvent;
  uiState: PopupManagementUiState;
  fileInputRef: React.RefObject<HTMLInputElement>;
  fileUrl: string | null;
}

const PopupGrid = ({
  data,
  countryLists,
  uiEvent,
  uiState,
  fileUrl,
  fileInputRef,
}: PopupGridProps) => {
  // 국가관련 변수
  const [countryList, setCountryList] = useState(countryLists || "");
  const [country, setCountry] = useState(data.country[0] || uiState.country[0]);

  // 상태 관련 변수
  const [status, setStatus] = useState(data.state);

  const editmode = uiState.editCard === true && data.id === uiState.id;

  const countryCodeToName: { [key: string]: string } = {
    KR: "South Korea",
    KH: "Cambodia",
    VN: "Vietnam",
  };
  useEffect(() => {
    setCountry(data.country[0] || data.country[0]);
    setStatus(data.state);
    console.log();
  }, [data.id, uiState.id, data]);

  return (
    <Grid item lg={4}>
      <Card
        elevation={0}
        sx={{ borderRadius: "10px", minHeight: "100px", minWidth: "328px" }}
      >
        {/* {fileUrl && uiState.id === data.id ? (
          // 이미지가 있을 때
          <Box
            component="img"
            src={fileUrl}
            sx={{
              width: "100%",
              height: "328px",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={uiEvent.HandleButtonClick}
          />
        ) : ( */}
        <Box
          sx={{
            width: "100%",
            height: "328px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.5s ease", // 애니메이션 적용
            backgroundImage: `url(${data.image})`, // 이미지 URL을 배경으로 사용
            backgroundSize: "contain", // 이미지가 요소에 맞게 조정되도록
            backgroundPosition: "center", // 이미지 중앙 배치
            backgroundRepeat: "no-repeat", // 이미지 반복 방지
            cursor: "pointer",
            ":hover": {
              backgroundColor: darken(color.white, 0.3),
              "& .hover-content": {
                opacity: 1, // 호버 시 보이도록
                visibility: "visible", // 호버 시 보이도록
              },
            },
          }}
          onClick={editmode ? uiEvent.HandleButtonClick : undefined}
        >
          {/* {editmode || ( */}
          <Box
            className="hover-content"
            sx={{
              opacity: 0, // 기본 상태에서 보이지 않도록
              visibility: "hidden", // 기본 상태에서 보이지 않도록
              transition: "opacity 0.5s ease, visibility 0.5s ease", // 부드럽게 보이도록
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 100,
            }}
          >
            {/* <Box //Todo 추후 구현
              sx={{
                bgcolor: color.gray800,
                borderRadius: "54px",
                width: "56px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: color.gray600,
                },
              }}
            >
              <Box component="img" src={ICON_SHOW_WHITE} />
            </Box> */}
            <HorizontalSpacer width={12} />
            <Box
              sx={{
                bgcolor: color.gray800,
                borderRadius: "54px",
                width: "56px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: color.gray600,
                },
                //클릭하면 편집할 수 있게
              }}
              onClick={() => {
                uiEvent.HandleEditClick(data);
              }}
            >
              <Box component="img" src={ICON_EDIT} />
            </Box>
            <HorizontalSpacer width={12} />
            {/* <Box  //Todo 추후 구현
              sx={{
                bgcolor: color.gray800,
                borderRadius: "54px",
                width: "56px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: color.gray600,
                },
              }}
            >
              <Box component="img" src={ICON_COPY} />
            </Box> */}
            <Box>
              <Box
                sx={{
                  ml: "12px",
                  bgcolor: color.gray800,
                  borderRadius: "54px",
                  width: "56px",
                  height: "56px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: color.gray600,
                  },
                  //클릭하면 삭제 할 수 있게
                }}
                onClick={() => {
                  uiEvent.HandleUiStateChange("deleteDialogOpen", true);
                  uiEvent.HandleUiStateChange("id", data.id);
                }}
              >
                <Box component="img" src={ICON_TRASH2} />
              </Box>
            </Box>
          </Box>
          {/* )} */}
        </Box>
        {/* )} */}
        {/* <input
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={uiEvent.HandleFileUpload}
            ref={fileInputRef}
            hidden
          /> */}
        <Divider />
        <Box sx={{ m: "24px", mb: "40px" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Name
          </Typography>
          <VerticalSpacer height={6} />
          <CommonTextField
            value={editmode ? uiState.name : data.title}
            sx={{ width: "100%" }}
            inputProps={{
              sx: {
                px: "12px",
                py: "10px",
                height: "20px",
                minWidth: "260px",
              },
            }}
            onChange={(event) => {
              uiEvent.HandleUiStateChange("name", event.target.value);
            }}
            disabled={!editmode}
            error={uiState.inValidCheck.name && uiState.id === data.id}
          />
          {uiState.inValidCheck.name && uiState.id === data.id && (
            <Typography fontStyle={fontStyle.regularXS} color={color.error}>
              Please enter a name
            </Typography>
          )}
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Country
          </Typography>
          <VerticalSpacer height={6} />
          <CommonSelect
            selectValue={country || ""}
            menuitems={countryList}
            onChange={(event) => {
              setCountry(countryCodeToName[event.target.value]);
              console.log("selectedCountry : ", event.target.value);
              uiEvent.HandleUiStateChange("country", event.target.value);
              console.log("selectedCountryList : ", countryList);
            }}
            sx={{
              height: "40px",
              fontStyle: fontStyle.regularS,
              color: "black",
              width: "100%",
            }}
            placeholder="Select"
            error={uiState.inValidCheck.country && uiState.id === data.id}
            disabled={!editmode}
          />
          {uiState.inValidCheck.country && uiState.id === data.id && (
            <Typography fontStyle={fontStyle.regularXS} color={color.error}>
              Please enter a country
            </Typography>
          )}
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Status
          </Typography>
          <VerticalSpacer height={6} />
          <CommonSelect
            selectValue={editmode ? uiState.status : status}
            menuitems={["Show", "Period", "Hide"]}
            onChange={(event) => {
              const selectedStatus = event.target.value;
              uiEvent.HandleUiStateChange("status", selectedStatus);
              setStatus(selectedStatus);

              if (selectedStatus !== "Period") {
                uiEvent.HandleUiStateChange("startDay", 0);
                uiEvent.HandleUiStateChange("startTimes", 0);
                uiEvent.HandleUiStateChange("endDay", 0);
                uiEvent.HandleUiStateChange("endTimes", 0);
              } else {
                uiEvent.HandleUiStateChange("startDay", data.startDate);
                uiEvent.HandleUiStateChange("startTimes", data.startDate);
                uiEvent.HandleUiStateChange("endDay", data.endDate);
                uiEvent.HandleUiStateChange("endTimes", data.endDate);
              }
            }}
            sx={{
              height: "40px",
              fontStyle: fontStyle.regularS,
              color: "black",
              width: "100%",
            }}
            placeholder="Select"
            error={uiState.inValidCheck.status && uiState.id === data.id}
            disabled={!editmode}
          />
          {uiState.inValidCheck.status && uiState.id === data.id && (
            <Typography fontStyle={fontStyle.regularXS} color={color.error}>
              Please enter a status
            </Typography>
          )}
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Start Date
          </Typography>
          <VerticalSpacer height={6} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <CommonDatePicker
                value={
                  editmode
                    ? uiState.startDay
                      ? dayjs(uiState.startDay)
                      : null
                    : data.startDate
                      ? dayjs(data.startDate)
                      : null
                }
                onChange={(date) => uiEvent.HandleDateChange(date, "startDay")}
                views={["year", "month", "day"]}
                textFieldSx={{ width: "160px" }}
                InputPropsSx={{ height: "40px", width: "160px" }}
                // error={inValidDataCheck.openDate}
                placeholder="Select Date"
                error={uiState.inValidCheck.startDate && uiState.id === data.id}
                disabled={
                  !editmode ||
                  (uiState.status !== "Period" && uiState.id === data.id)
                }
              />
              {uiState.inValidCheck.startDate && uiState.id === data.id && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please enter a startdate
                </Typography>
              )}
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                inputProps={{
                  sx: {
                    padding: "0px",
                  },
                }}
                InputProps={{
                  sx: {
                    px: "12px",
                    py: "10px",
                    height: "40px",
                    width: "110px", // 여기에 width 설정
                    borderRadius: "8px",
                    backgroundColor:
                      editmode && uiState.status === "Period"
                        ? ""
                        : color.gray200,
                  },
                }}
                value={
                  editmode
                    ? uiState.startTimes === 0
                      ? dayjs("00:00:00", "HH:mm:ss") // 0일 때 명시적으로 처리
                      : dayjs(uiState.startTimes)
                    : data.startDate === 0
                      ? dayjs("00:00:00", "HH:mm:ss") // 0일 때 명시적으로 처리
                      : dayjs(data.startDate)
                }
                onChange={(newValue) => {
                  {
                    editmode
                      ? uiEvent.HandleTimeChange(newValue, "startTimes")
                      : uiEvent.HandleTimeChange(newValue, "startTime");
                  }
                }}
                format="HH:mm:ss"
                disabled={
                  !editmode ||
                  (uiState.status !== "Period" && uiState.id === data.id)
                }
              />
            </LocalizationProvider>
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            End Date
          </Typography>
          <VerticalSpacer height={6} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <CommonDatePicker
                value={
                  editmode
                    ? uiState.endDay
                      ? dayjs(uiState.endDay)
                      : null
                    : data.endDate
                      ? dayjs(data.endDate)
                      : null
                }
                onChange={(date) => uiEvent.HandleDateChange(date, "endDay")}
                views={["year", "month", "day"]}
                textFieldSx={{ width: "160px" }}
                InputPropsSx={{ height: "40px", width: "160px" }}
                // error={inValidDataCheck.openDate}
                placeholder="Select Date"
                error={uiState.inValidCheck.endDate && uiState.id === data.id}
                disabled={
                  !editmode ||
                  (uiState.status !== "Period" && uiState.id === data.id)
                }
              />
              {uiState.inValidCheck.endDate && uiState.id === data.id && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please enter a enddate
                </Typography>
              )}
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                inputProps={{
                  sx: {
                    padding: "0px",
                  },
                }}
                InputProps={{
                  sx: {
                    px: "12px",
                    py: "10px",
                    height: "40px",
                    width: "110px", // 여기에 width 설정
                    borderRadius: "8px",
                    backgroundColor:
                      editmode && uiState.status === "Period"
                        ? ""
                        : color.gray200,
                  },
                }}
                value={
                  editmode
                    ? uiState.endTimes === 0
                      ? dayjs("00:00:00", "HH:mm:ss") // 0일 때 명시적으로 처리
                      : dayjs(uiState.endTimes)
                    : data.endDate === 0
                      ? dayjs("00:00:00", "HH:mm:ss") // 0일 때 명시적으로 처리
                      : dayjs(data.endDate)
                }
                onChange={(newValue) => {
                  {
                    editmode
                      ? uiEvent.HandleTimeChange(newValue, "endTimes")
                      : uiEvent.HandleTimeChange(newValue, "endTime");
                  }
                  console.log("time : ", uiState.endTime, uiState.endTimes);
                }}
                format="HH:mm:ss"
                disabled={!editmode || uiState.status !== "Period"}
              />
            </LocalizationProvider>
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            URL
          </Typography>
          <VerticalSpacer height={8} />
          <Paper
            component="form"
            sx={{
              p: "10px 12px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "40px",
              borderRadius: "8px",
              border: 1,
              borderColor:
                uiState.inValidCheck.url && uiState.id === data.id
                  ? color.error
                  : color.gray300,
              backgroundColor: editmode ? "" : color.gray200,
            }}
            elevation={0}
          >
            <InputBase
              value={editmode ? uiState.url : data.link}
              sx={{ width: "100%" }}
              disabled={!editmode}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("url", event.target.value);
              }}
            />
          </Paper>
          {uiState.inValidCheck.url && uiState.id === data.id && (
            <Typography fontStyle={fontStyle.regularXS} color={color.error}>
              Please enter a URL
            </Typography>
          )}
          {editmode && (
            <Box
              sx={{
                mt: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  fontStyle: fontStyle.semiboldS,
                  color: color.gray600,
                  cursor: "pointer",
                }}
                onClick={() => {
                  uiEvent.HandleUiStateChange("cancelDialogOpen", true);
                }}
              >
                Cancel
              </Box>
              <CommonWebHeaderButton
                sx={{ display: "flex", flexDirection: "column" }}
                buttonContent="Update"
                onClick={() => {
                  uiEvent.HandleUiStateChange("updateDialogOpen", true);
                  console.log("데이터 : ", uiState.name);
                  console.log("데이터 : ", uiState.country);
                  console.log("데이터 : ", uiState.status);
                  console.log("데이터 : ", uiState.startDay);
                  console.log("데이터 : ", uiState.endDay);
                  console.log("데이터 : ", uiState.url);
                  console.log("데이터 : ", uiState.id);
                  console.log("데이터 : ", uiState.image);
                }}
              />
            </Box>
          )}
        </Box>
      </Card>
    </Grid>
  );
};

interface PopupMngGridProps {
  rows: any;
  countryLists: any;
  uiState: PopupManagementUiState;
  uiEvent: PopupManagementUiEvent;
  fileInputRef: React.RefObject<HTMLInputElement>;
  fileUrl: string;
}

const PopupMngGrid = ({
  rows,
  countryLists,
  uiState,
  uiEvent,
  fileInputRef,
  fileUrl,
}: PopupMngGridProps) => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {rows?.map((data: any, index: any) => {
        return (
          <PopupGrid
            key={index}
            data={data}
            countryLists={countryLists}
            uiEvent={uiEvent}
            uiState={uiState}
            fileInputRef={fileInputRef}
            fileUrl={fileUrl}
          />
        );
      })}
      {uiState.createCard === true && (
        <Grid item lg={4}>
          <PopupManagementCreateCard
            uiState={uiState}
            uiEvent={uiEvent}
            fileInputRef={fileInputRef}
            fileUrl={fileUrl}
          />
        </Grid>
      )}
      <Grid item lg={4}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            minWidth: "330px",
            height: "100%",
            minHeight: "912px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "80px",
              height: "80px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: color.primary500,
              borderRadius: "80px",
              cursor: "pointer",
              ":hover": {
                backgroundColor: color.primary300,
                "& .hover-content": {
                  opacity: 1, // 호버 시 보이도록
                  visibility: "visible", // 호버 시 보이도록
                },
              },
            }}
            onClick={uiEvent.HandleNewCard}
          >
            <Box component="img" src={ICON_ADD} />
          </Box>
        </Box>
      </Grid>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={uiState.cancelDialogOpen}
        handleOk={uiEvent.HandleCancelOk}
        handleClose={uiEvent.HandleCancelClose}
      >
        Do you want to stop editing and move? <br />
        The current content will not be saved.
      </CommonDialog>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={uiState.updateDialogOpen}
        handleOk={uiEvent.HandleUpdateOk}
        handleClose={uiEvent.HandleUpdateCancel}
      >
        Would you like to update the popup?
      </CommonDialog>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={uiState.deleteDialogOpen}
        handleOk={uiEvent.HandleDeleteOk}
        handleClose={uiEvent.HandleDeleteCancel}
      >
        Do you want to delete the popup? <br />
        It cannot be restored afterwards.
      </CommonDialog>
    </Grid>
  );
};

export default PopupMngGrid;
