export class LoginDetailUiState {
  day: string = "";
  month: string = "";
  year: string = "";
  gender: string = "";
  country: string = "none";
  cityCountyDistrict: string = "";
  cityDetail: string = "";

  dayError: string = "";
  monthError: string = "";
  yearError: string = "";
  genderError: string = "";
  countryError: string = "";
  cityCountyDistrictError: string = "";
  cityDetailError: string = "";

  dialogOpen: boolean = false;
  finishOpen: boolean = false; // 회원가입 완료 화면
  networkError: boolean = false;
}
