export class UserDetailsUiState {
  // drawerOpen: boolean = false;
  //User Data
  uuid: string = "";
  Name: string = "";
  Email: string = "";
  LocalNumber: string = "+82";
  PhoneNumber: string = "";
  Status: string = "";
  IDCard: File | undefined;
  IdCardImageFileName: string = "";
  idCardImageURL: string = "";
  hasIdCard: boolean = false;

  Type: string = "";
  Group: string = "";
  BirthOfDate: number = 0;
  Gender: string = "";
  CountryAddress: string = "";
  CityAddress: string = "";
  DetailAddress: string = "";
  TribeCategory: string = "";
  TribeName: string = "";
  UserVerified: string = "";
  UserVerifiedDate: string = "";
  RegistrationDate: number = 0;
  LastModifiedDate: number = 0;

  Contractby: string = "";
  Verifiedby: string = "";
  VerifiedDate: number = 0;

  Contract: File | undefined;
  ContractName: string = "";
  ContractUrl: string = "";
  ResidentCheck: boolean = false;
  ResidentCheckTime: number = 0;
  ContractCheck: boolean = false;
  ContractCheckTime: number = 0;
  OwnershipType: string = "";
  ScooterProductName: string = "";

  ScooterSheet: Array<string> = [];
  SelectedScooterContractSheet: string = "";

  isNewSheet: boolean = true;

  //Page State
  AddScooter: boolean = false;
  addLoadingState: boolean = false;

  //Scooter Data
  scooterSNList: Array<string> = [];
  plateNumberList: Array<string> = [];
  scooterSNPNJson: Array<{ serialNumber: string; plateNumber: string }> = []; // JSON 배열 형태로 정의
  selectScooterSN: string = "";
  selectScooterPN: string = "";
  isHandOver: boolean = false;
  handOverTime: number = 0;
  handOverTimeLimit: number = 0;

  //Rental Data
  RentalStartDate: number = 0;
  RentalEndDate: number = 0;
  OwnerList: Array<Object> = [];
  OwnerNameEmail: string = "Select";
  OwnerUUid: string = "Select";
  ReturnDate: number = 0;
  ReturnDateCheck: boolean = false;
}
