import { TableRow } from "@mui/material";
import { InventoryTableCell } from "./InventoryTableCell";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { Text } from "../../../component/Text";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { InventoryData } from "./model/InventoryData";

interface InventoryTableRowProps {
  row: InventoryData;
}

export function InventoryTableRow({ row }: InventoryTableRowProps) {
  return (
    <TableRow>
      <InventoryTableCell
        sx={{ paddingLeft: "24px" }}
        childrenContainerSx={{ width: "36px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.no}`}
          />
        }
        align={"center"}
      />
      <InventoryTableCell
        childrenContainerSx={{ width: "74px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row?.category}`}
          />
        }
        align={"center"}
      />
      <InventoryTableCell
        childrenContainerSx={{ width: "94px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.partsCode}`}
          />
        }
        align={"center"}
      />
      <InventoryTableCell
        childrenContainerSx={{ width: "123px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.productName}`}
          />
        }
        align={"center"}
      />
      <InventoryTableCell
        childrenContainerSx={{ width: "152px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.spec}`}
          />
        }
        align={"center"}
      />
      <InventoryTableCell
        childrenContainerSx={{ width: "91px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.company}`}
          />
        }
        align={"center"}
      />
      <InventoryTableCell
        childrenContainerSx={{ width: "53px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.quantities}`}
          />
        }
        align={"center"}
      />
      <InventoryTableCell
        childrenContainerSx={{ width: "49px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.amount}`}
          />
        }
        align={"center"}
      />
      <InventoryTableCell
        childrenContainerSx={{ width: "120px" }}
        sx={{ paddingRight: "24px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={LocalizedDate({
              date: row.registerDate,
              type: DateFormatType.AdminType,
            })}
          />
        }
      />
    </TableRow>
  );
}
