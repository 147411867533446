import { Box, Typography } from "@mui/material";
import UsageHistoryItem from "../../model/UsageHistoryItem";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import UsageHistoryCell from "./UsageHistoryCell";

interface UsageHistoryListProps {
  histories: UsageHistoryItem[];
  onToggleExpand: (id: number) => void;
}

export default function UsageHistoryList({
  histories,
  onToggleExpand,
}: UsageHistoryListProps) {
  const noUsageHistoryText = LocalizedText("ua_g421_nousagehistory");

  if (histories.length == null || histories.length == 0) {
    return (
      <Box
        sx={{
          pt: "48px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
          {noUsageHistoryText}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      {histories.map((item, index) => (
        <UsageHistoryCell
          key={index}
          history={item}
          isExpanded={item.isExpanded ?? false}
          onToggleExpand={onToggleExpand}
        />
      ))}
    </Box>
  );
}
