import { Box, Typography } from "@mui/material";

interface PinMarkerProps {
  image?: string;
  batteryCount?: number;
  type: "count" | "noCount" | "click" | undefined;
}

const PinMarker = ({ image, batteryCount, type }: PinMarkerProps) => {
  return (
    <>
      {type == "count" && (
        <Box sx={{ width: 46, height: 46 }}>
          <Box sx={{ pt: 2, pl: 0.5 }}>
            <img src={image} style={{ width: 46, height: 46 }} />
            <Typography
              sx={{
                position: "absolute",
                top: "45%",
                left: "63%",
                fontFamily: "unset",
                pointerEvents: "none",
                fontSize: 14,
                color: "#5D38E5",
              }}
            >
              {batteryCount}
            </Typography>
          </Box>
        </Box>
      )}
      {type == "noCount" && (
        <Box sx={{ width: 40, height: 40 }}>
          <Box sx={{ pt: 2, pl: 0.5 }}>
            <img src={image} style={{ width: 40, height: 40 }} />
          </Box>
        </Box>
      )}
      {type == "click" && (
        <Box sx={{ width: 50, height: 50 }}>
          <Box sx={{ pt: 1, pl: 0 }}>
            <img src={image} style={{ width: 50, height: 50 }} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PinMarker;
