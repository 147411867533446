import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { fontStyle } from "../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { color } from "../../../theme/Color";
import {
  APP_LOGO,
  ICON_SNS_FACEBOOK_2,
  ICON_SNS_GOOGLE,
  ICON_SNS_APPLE,
} from "../../../constants/appImagePath";
import CommonButton from "../../../component/CommonButton";
import { ExistingAccountPageSideEffect } from "./ExistingAccountPageSideEffect";
import { ExistingAccountPageUiState } from "./ExistingAccountPageUiState";
import { ExistingAccountPageUiEvent } from "./ExistingAccountPageUiEvent";

interface ExistingAccountRenderProps {
  sideEffect: ExistingAccountPageSideEffect;
  uiState: ExistingAccountPageUiState;
  uiEvent: ExistingAccountPageUiEvent;
}

const maskEmail = (email: string) => {
  const [localPart, domain] = email.split("@");
  const maskedLocalPart = localPart
    .split("")
    .map((char, index) => (index < 3 ? char : "*"))
    .join("");
  return `${maskedLocalPart}@${domain}`;
};

const ExistingAccountRender = ({
  sideEffect,
  uiState,
  uiEvent,
}: ExistingAccountRenderProps) => {

  // 이메일을 마스킹 처리
  const maskedEmail = maskEmail(uiState.email);

  // snsType에 따라 아이콘 변경
  const snsIcon = (snsType: string) => {
    switch (snsType) {
      case "GOOGLE":
        return ICON_SNS_GOOGLE;
      case "APPLE":
        return ICON_SNS_APPLE;
      case "FACEBOOK":
        return ICON_SNS_FACEBOOK_2;
      default:
        break;
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", 
          alignItems: "center", 
          width: "100vw", 
          height: "100vh", 
          justifyContent: "space-between",
          backgroundColor: "white", 
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 40px)",
            textAlign: "left",
          }}
        >
          <VerticalSpacer height={44} />

          {/* 로고 */}
          <img
            src={APP_LOGO}
            alt="logo"
            style={{ width: "80px", height: "20px", marginBottom: "30px" }}
          />

          {/* 타이틀 */}
          <Typography fontStyle={fontStyle.headingS}>
            This is the account that <br /> matches your information.
          </Typography>

          <VerticalSpacer height={16} />

          {/* 중간 요소 */}
          <Box
            sx={{
              textAlign: "left",
              borderRadius: "10px",
              padding: "16px",
              border: `1px solid ${color.gray}`,
            }}
          >
            <Typography fontStyle={fontStyle.bodyMr}>
              <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                E-mail:
                <img
                  src={snsIcon(uiState.snsType)}
                  alt="SNS Logo"
                  style={{
                    width: "16px",
                    height: "16px",

                    marginLeft: "4px",
                    marginRight: "4px",
                  }}
                />
                {maskedEmail} 
              </Box>
            </Typography>

            <VerticalSpacer height={10} />

            <Typography fontStyle={fontStyle.bodyMr}>
              Joined on: {uiState.joinDate}
            </Typography>
          </Box>

          <VerticalSpacer height={40} />
        </Box>

        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title="Back to log in"
            textColor="white"
            onClick={() => sideEffect.navigate("")}
            sx={{
              mx: "20px",
              mb: "14px",
              textTransform: "none",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ExistingAccountRender;
