import { Box } from "@mui/material";
import { Text } from "../../../component/Text";
import Toolbar from "../../../component/Toolbar";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import LanguageButton from "./LanguageButton";
import VerticalSpacer from "../../../component/Spacer";
import { LanguageButtonModel } from "./model/LanguageButtonModel";

interface LanguagePageRenderProps {
  selectableLanguage: LanguageButtonModel[];
  toolbarTitle: string;
  pageTitle: string;
  onLanguageChange: (languageCode: string) => void;
}

export default function LanguagePageRender({
  selectableLanguage,
  toolbarTitle,
  pageTitle,
  onLanguageChange,
}: LanguagePageRenderProps) {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        onBack={() => {
          window.history.back();
        }}
        title={toolbarTitle}
      />
      <Box
        sx={{
          px: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <VerticalSpacer height={20} />
        <Text
          sx={{
            fontStyle: fontStyle.headingS,
            color: color.default,
            whiteSpace: "pre-line",
          }}
          text={pageTitle}
        />
        <VerticalSpacer height={20} />
        {selectableLanguage &&
          selectableLanguage.map((it) => (
            <Box key={it.languageCode}>
              <LanguageButton
                title={it.title}
                image={it.image}
                isSelected={it.isSelected}
                onClick={() => {
                  onLanguageChange(it.languageCode);
                }}
              />
              <VerticalSpacer height={16} />
            </Box>
          ))}
      </Box>
    </Box>
  );
}
