import { SxProps } from "@mui/system";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { color } from "../theme/Color";

interface TextProps {
  sx?: SxProps<Theme>;
  text: string;
  onClick?: () => void;
}

export function Text({ sx, text, onClick }: TextProps) {
  return (
    <Typography
      sx={{
        whiteSpace: "nowrap", // 기본적으로 한 줄로 표시
        overflow: "hidden", // 넘치는 부분은 숨김
        textOverflow: "ellipsis", // 넘치는 텍스트는 ...으로 표시
        color: color.default,
        ...sx, // 추가로 전달된 스타일
      }}
      onClick={onClick}
    >
      {text}
    </Typography>
  );
}
