import { useEffect, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import Header from "./Header/Header";
// import LoginHeader from "./Header/LoginHeader";
import SideBar from "./SideNav/SideBar";
import LoginRoutes from "../../../routes/LoginRoutes";
import MainRoutes from "../../../routes/MainRoutes";
import { rootState } from "../../../store";
import { login, logout } from "../../../store/auth";

const ClippedDrawer = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: rootState) => state.auth.isLoggedIn);

  useEffect(() => {
    console.log("isLoggedIn is  : ", isLoggedIn);
  }, [isLoggedIn]);

  // 세션 스토리지에서 로그인 상태 가져오기
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    if (storedIsLoggedIn === "true") {
      dispatch(login());
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <>
      {isLoggedIn ? (
        <Box sx={{ backgroundColor: "#F2F2F2" }}>
          <Box
            sx={{
              display: "flex",
              // backgroundColor: "#F2F2F2",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              overflowY: "scroll",
            }}
          >
            <CssBaseline />
            <Header />
            <SideBar />

            <Box
              sx={{
                // overflow: "hidden",
                // paddingLeft: 5,
                // paddingRight: 5,
                ml: 7,
                mr: 5,
                width: 1100,
              }}
            >
              <Toolbar />
              <MainRoutes />
            </Box>
          </Box>
        </Box>
      ) : (
        <LoginRoutes />
      )}
    </>
  );
};

export default ClippedDrawer;
