import { TableRow } from "@mui/material";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { ProductionManagementCellData } from "../model/ProductionManagementCellData";
import { SpecificationHistoryData } from "./model/SpecificationHistoryData";
import { SpecificationHistoryTableCell } from "./SpecificationHistoryTableCell";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

interface SpecificationHistoryTableRowProps {
  row: SpecificationHistoryData;
}

export function SpecificationHistoryTableRow({
  row,
}: SpecificationHistoryTableRowProps) {
  const spcificationHistoryCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData(`${row.no}`, "left", "36px", "24px"),
    new ProductionManagementCellData(`${row.parent}`, "left", "94px"),
    new ProductionManagementCellData(`${row.category}`, "left", "61px"),
    new ProductionManagementCellData(`${row.partsCode}`, "left", "94px"),
    new ProductionManagementCellData(`${row.productName}`, "left"),
    new ProductionManagementCellData(`${row.spec}`, "left", "129px"),
    new ProductionManagementCellData(`${row.company}`, "left", "68px"),
    new ProductionManagementCellData(`${row.updater}`, "left"),
    new ProductionManagementCellData(
      LocalizedDate({
        date: row.lastUpdateDate,
        type: DateFormatType.AdminType,
      }),
      "left",
      "120px"
    ),
  ];

  return (
    <TableRow>
      {spcificationHistoryCellData.map((data: ProductionManagementCellData) => (
        <SpecificationHistoryTableCell
          key={data.value}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={data.value}
            />
          }
        />
      ))}
    </TableRow>
  );
}
