import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { useEffect } from "react";

import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { ICON_CLOCK } from "../../../constants/appImagePath";

interface EditPhoneNumberFieldProps {
  type?: string;
  authCode: string;
  setAuthCode: (code: string) => void;
  authCodeError: boolean;
  timeRemaining: number;
  setTimeRemaining: (time: number) => void;
  localNumberError: boolean;
  phoneNumberError: boolean;
  localNumber: string;
  phoneNumber: string;
  handleLocaleNumberChange: (value: string) => void;
  handlePhoneNumberChange: (value: string) => void;
  onClickSendButton: () => void;
  changeLocalNumberError: (state: boolean) => void;
  changePhoneNumberError: (state: boolean) => void;
  isTimerInProgress: boolean;
}

const EditPhoneNumberField = ({
  type,
  authCode,
  setAuthCode,
  authCodeError,
  timeRemaining,
  setTimeRemaining,
  localNumberError,
  phoneNumberError,
  localNumber,
  phoneNumber,
  handleLocaleNumberChange,
  handlePhoneNumberChange,
  onClickSendButton,
  changeLocalNumberError,
  changePhoneNumberError,
  isTimerInProgress,
}: EditPhoneNumberFieldProps) => {
  const errorText = LocalizedText("ua_g210_enterphonenumber");
  const errorAuthText = LocalizedText("ua_b123_digitcode");
  const ua_b123_receivemessage = LocalizedText("ua_b123_receivemessage");

  const formatTime = (milliseconds: number) => {
    const minutes = Math.floor(milliseconds / (60 * 1000));
    const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  // 매초마다 카운트다운하는 useEffect 추가
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isTimerInProgress && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining(Math.max(timeRemaining - 1000, 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerInProgress, timeRemaining]);

  useEffect(() => {
    console.log(formatTime(timeRemaining));
  }, [timeRemaining]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {type === "Phone" && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              alignItems: "center",
            }}
          >
            <Select
              disabled={isTimerInProgress}
              sx={{
                height: "50px",
                width: "70px",
                backgroundColor: "white",
                "&:before": {
                  borderColor: localNumberError ? color.error : color.gray400, // 호버 시 테두리 색상
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderColor: localNumberError
                    ? color.error
                    : color.primary500, // 호버 시 테두리 색상
                },
                "&.Mui-focused:after": {
                  borderColor: localNumberError
                    ? color.error
                    : color.primary500, // 포커스 시 테두리 색상
                },
                "&:after": {
                  borderBottomColor: localNumberError
                    ? color.error
                    : color.primary500, // 포커스가 풀린 후 기본 색상
                },
              }}
              renderValue={(value) => {
                return value;
              }}
              variant="standard"
              value={localNumber}
              onChange={(event) => {
                handleLocaleNumberChange(event.target.value);
              }}
            >
              <MenuItem value="+82">+82(대한민국)</MenuItem>
              <MenuItem value="+855">+855(កម្ពុជា។)</MenuItem>
            </Select>
            <HorizontalSpacer width={8} />
            <TextField
              disabled={isTimerInProgress}
              variant="standard"
              size="medium"
              value={phoneNumber}
              autoComplete="off"
              onChange={(event) => {
                handlePhoneNumberChange(event.target.value);
              }}
              inputProps={{
                style: {
                  height: "50px",
                  padding: "0px 0px",
                },
              }}
              sx={{
                flexGrow: 1,
                "& .MuiInput-root": {
                  "&:before": {
                    borderBottomColor: phoneNumberError
                      ? color.error
                      : color.gray400, // 기본 테두리 색상
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: color.primary500, // 호버 시 테두리 색상
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                  },
                  "&:after": {
                    borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
                  },
                },
              }}
            />
            <HorizontalSpacer width={4} />
            <Button
              sx={{
                maxWidth: "88px",
                height: "36px",
                mt: 1.5,
                color: isTimerInProgress ? color.gray500 : color.primary500,
                border: 1,
                borderColor: isTimerInProgress
                  ? color.gray500
                  : color.primary500,
                fontStyle: fontStyle.titleS,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50px",
              }}
              onClick={() => {
                if (localNumber == "") {
                  changeLocalNumberError(true);
                }
                if (phoneNumber == "") {
                  changePhoneNumberError(true);
                }

                if (localNumber != "" || phoneNumber != "") {
                  if (localNumber != "") {
                    changeLocalNumberError(false);
                  }
                  if (phoneNumber != "") {
                    changePhoneNumberError(false);
                  }
                  if (localNumber != "" && phoneNumber != "") {
                    onClickSendButton();
                  }
                }
              }}
            >
              {isTimerInProgress
                ? LocalizedText("ua_b123_resend")
                : LocalizedText("ua_b123_verify")}
            </Button>
          </Box>
        )}
        {phoneNumberError && localNumberError ? (
          <></>
        ) : !phoneNumberError && !localNumberError && isTimerInProgress ? (
          <>
            <TextField
              variant="standard"
              size="medium"
              value={authCode}
              autoComplete="off"
              onChange={(event) => {
                setAuthCode(event.target.value);
              }}
              error={authCodeError}
              inputProps={{
                style: {
                  height: "50px",
                  padding: "0px 0px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ mt: 1 }}>
                    <Box
                      sx={{
                        width: "74px",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                      }}
                    >
                      <Box component="img" src={ICON_CLOCK} />
                      <HorizontalSpacer width={4.6} />
                      <Typography
                        fontStyle={fontStyle.subTitleM}
                        color={
                          timeRemaining == 0 ? color.gray600 : color.primary500
                        }
                      >
                        {formatTime(timeRemaining)}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              sx={{
                mt: "20px",
                width: "100%",
                // flexGrow: 1,
                "& .MuiInput-root": {
                  "&:before": {
                    // borderBottomColor: color.gray300, // 기본 테두리 색상
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: color.primary500, // 호버 시 테두리 색상
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                  },
                },
              }}
            />
            {authCodeError && (
              <>
                <Typography
                  fontStyle={fontStyle.bodySr}
                  color={"#FF4C4C"}
                  sx={{ mt: "8.75px" }}
                >
                  {errorAuthText}
                </Typography>
              </>
            )}
            <VerticalSpacer height={20} />
            <Box
              sx={{
                bgcolor: color.gray50,
                width: "100%",
                borderRadius: "10px",
                color: color.gray800,
                fontStyle: fontStyle.bodyMr,
              }}
            >
              <Box sx={{ px: "16px", py: "12px" }}>
                {ua_b123_receivemessage}
              </Box>
            </Box>
          </>
        ) : phoneNumberError || localNumberError ? (
          <>
            <Typography
              fontStyle={fontStyle.bodySr}
              color={"#FF4C4C"}
              sx={{ mt: "8.75px" }}
            >
              {errorText}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default EditPhoneNumberField;
