import { IMAGE_DODO_LEVEL5 } from "../../../constants/appImagePath";

export class NFT {
  no: number;
  img: string;
  nftCharactor: string;
  reword: string;
  writer: string;
  distributeDate: number;
  lastModifiedDate: number;
  registrationDate: number;

  constructor(
    no: number,
    img: string,
    nftCharactor: string,
    reword: string,
    writer: string,
    distributeDate: number,
    lastModifiedDate: number,
    registrationDate: number
  ) {
    this.no = no;
    this.img = img;
    this.nftCharactor = nftCharactor;
    this.reword = reword;
    this.writer = writer;
    this.distributeDate = distributeDate;
    this.lastModifiedDate = lastModifiedDate;
    this.registrationDate = registrationDate;
  }
}

export const mockNFTData: NFT[] = [
  new NFT(
    1,
    IMAGE_DODO_LEVEL5,
    "DODO",
    "aevel1: $2 discount coupon (14d)\naevel1: $2 discount coupon (14d)\naevel1: $2 discount coupon (14d)\naevel1: $2 discount coupon (14d)\n",
    "HJI",
    1716499200,
    1672076400,
    1672076400
  ),
  new NFT(
    2,
    IMAGE_DODO_LEVEL5,
    "KIKI",
    "Level3: Free shipping (10d)",
    "JYK",
    1717099200,
    1673076400,
    1672076500
  ),
  new NFT(
    3,
    IMAGE_DODO_LEVEL5,
    "MOMO",
    "Level3: Free shipping (10d)",
    "LSJ",
    1716599200,
    1672076800,
    1672076600
  ),
  new NFT(
    4,
    IMAGE_DODO_LEVEL5,
    "NANA",
    "Level1: $3 discount coupon (14d)",
    "PQR",
    1716199200,
    1675076400,
    1673076400
  ),
  new NFT(
    5,
    IMAGE_DODO_LEVEL5,
    "LULU",
    "Level2: 10% discount (5d)",
    "LMN",
    1717199200,
    1676076400,
    1674076400
  ),
  new NFT(
    6,
    IMAGE_DODO_LEVEL5,
    "TOTO",
    "Level3: Buy 1 Get 1 Free (3d)",
    "OPQ",
    1716499500,
    1672076401,
    1672076700
  ),
  new NFT(
    7,
    IMAGE_DODO_LEVEL5,
    "RARA",
    "Level1: Free gift (20d)",
    "STU",
    1716499600,
    1672076402,
    1672076800
  ),
  new NFT(
    8,
    IMAGE_DODO_LEVEL5,
    "BEBE",
    "Level2: $5 discount coupon (12d)",
    "XYZ",
    1716499700,
    1672076403,
    1672076900
  ),
  new NFT(
    9,
    IMAGE_DODO_LEVEL5,
    "COCO",
    "Level4: $7 discount coupon (10d)",
    "ABC",
    1717599200, // Unix timestamp for 2024-06-06
    1677076400, // Unix timestamp for 2023-02-22
    1675076400 // Unix timestamp for 2023-01-30
  ),

  new NFT(
    10,
    IMAGE_DODO_LEVEL5,
    "DODO",
    "Level3: Free returns (15d)",
    "DEF",
    1718099200, // Unix timestamp for 2024-06-12
    1678076400, // Unix timestamp for 2023-03-05
    1676076400 // Unix timestamp for 2023-02-11
  ),

  new NFT(
    11,
    IMAGE_DODO_LEVEL5,
    "KAKA",
    "Level5: 20% discount (7d)",
    "GHI",
    1718599200, // Unix timestamp for 2024-06-18
    1679076400, // Unix timestamp for 2023-03-17
    1677076400 // Unix timestamp for 2023-02-22
  ),

  new NFT(
    12,
    IMAGE_DODO_LEVEL5,
    "TATA",
    "Level2: $10 discount coupon (3d)",
    "JKL",
    1719099200, // Unix timestamp for 2024-06-24
    1680076400, // Unix timestamp for 2023-04-10
    1678076400 // Unix timestamp for 2023-03-05
  ),

  new NFT(
    13,
    IMAGE_DODO_LEVEL5,
    "ZAZA",
    "Level1: $1 discount (2d)",
    "MNO",
    1719599200, // Unix timestamp for 2024-06-30
    1681076400, // Unix timestamp for 2023-04-10
    1679076400 // Unix timestamp for 2023-03-17
  ),
];
