import { Box } from "@mui/material";
import { Notice } from "../model/Notice";
import NoticeItem from "./NoticeItem";
import { Text } from "../../../../component/Text";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import VerticalSpacer from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import { HorizontalGrayDivider } from "../../../../component/Divider";

interface NoticeListProps {
  noticeList: Notice[]; // noticeList는 배열이여야 한다
  onClick: (id: string) => void;
}

export function NoticeList({ noticeList = [], onClick }: NoticeListProps) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      {noticeList.length > 0 ? (
        noticeList.map((notice, index) => (
          <Box key={notice.id}>
            <VerticalSpacer height={index === 0 ? 4 : 8} />
            <NoticeItem
              key={notice.id}
              title={notice.title}
              date={LocalizedDate({
                date: notice.registrationDate,
                type: DateFormatType.YearMonthDay,
              })}
              onClick={() => onClick(notice.id)}
            />
            <VerticalSpacer height={8} />
            <HorizontalGrayDivider />
          </Box>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Text
            sx={{
              fontStyle: fontStyle.titleL,
              color: color.default,
              whiteSpace: "pre-line",
            }}
            text={LocalizedText("ua_f510_nonotice_title")}
          />
          <VerticalSpacer height={4} />
          <Text
            sx={{
              fontStyle: fontStyle.bodyMr,
              color: color.gray600,
              whiteSpace: "pre-line",
            }}
            text={LocalizedText("ua_f510_nonotice_message")}
          />
        </Box>
      )}
    </Box>
  );
}
