import { WalletNFT } from "../model/WalletNFT";
import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import NFTCard from "./NFTCard";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface NFTSectionProps {
  nfts: WalletNFT;
  onNavigate: (route: string, state: any) => void;
}

const NFTSection = ({ nfts, onNavigate }: NFTSectionProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "white",
        flex: 1,
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {nfts.characters.length === 0 &&
      nfts.batteries.length === 0 &&
      nfts.scooters.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            aspectRatio: "1",
          }}
        >
          <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
            There is no NFTs
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingTop: "16px",
            width: "calc(100% - 40px)",
            margin: "0 auto",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {nfts.characters.map((character) => (
            <NFTCard
              key={character.tokenId}
              image={character.imageURI}
              title={character.tokenId}
              description={LocalizedText("ua_g500_characternft")}
              onClick={() => {
                onNavigate("NFTDetail", {
                  state: {
                    tokenId: character.tokenId,
                    type: "characters",
                  },
                });
              }}
            />
          ))}
          {nfts.batteries.map((battery) => (
            <NFTCard
              key={battery.tokenId}
              image={battery.imageURI}
              title={battery.tokenId}
              description={LocalizedText("ua_g500_batterynft")} //다국어 누락, 이후 수정 필요
              onClick={() => {
                onNavigate("NFTDetail", {
                  state: { tokenId: battery.tokenId, type: "batteries" },
                });
              }}
            />
          ))}
          {nfts.scooters.map((scooter) => (
            <NFTCard
              key={scooter.tokenId}
              image={scooter.imageURI}
              title={scooter.tokenId}
              description={LocalizedText("ua_g500_scooternft")}
              onClick={() => {
                onNavigate("NFTDetail", {
                  state: { tokenId: scooter.tokenId, type: "scooters" },
                });
                console.log("ScooterState", scooter);
              }}
            />
          ))}
        </Box>
      )}
      <VerticalSpacer height={36} />
    </Box>
  );
};

export default NFTSection;
