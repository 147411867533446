import { Box } from "@mui/material";
import { APP_LOGO } from "../../constants/appImagePath";
import VerticalSpacer from "../../component/Spacer";
import CommonButton from "../../component/CommonButton";
import { Text } from "../../component/Text";
import { useLocation } from "react-router-dom";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import LocalizedDate, { DateFormatType } from "../../component/LocalizedDate";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { useEffect } from "react";
import {clearUserDataInLocalStorage} from "../../utils/LocalStorageUtil";

export default function WithdrawalInfoPage() {
  const location = useLocation();
  let withdrawalTimeMillisecond: number;
  let email: string;

  if (location?.state) {
    withdrawalTimeMillisecond = location.state.withdrawalTimeMillisecond;
    email = location.state.email;
  } else {
    withdrawalTimeMillisecond = 0; // 기본값
    email = "unknown@example.com"; // 기본 이메일
  }

  function replacePlaceholders(template: string, ...values: string[]): string {
    let index = 0;
    return template.replace(/%s/g, () => values[index++] || "");
  }

  function getReSignupDate(): number {
    const date = new Date(withdrawalTimeMillisecond);
    date.setDate(date.getDate() + 30);
    const resignupdateTimeMillisecond = date.getTime();
    return resignupdateTimeMillisecond;
  }

  useEffect(() => {
    clearUserDataInLocalStorage();
  }, []);

  // 사용 예시
  const title = replacePlaceholders(
    LocalizedText("ua_b130_title"),
    LocalizedDate({
      date: getReSignupDate(),
      type: DateFormatType.YearMonthDay,
    }),
  );

  useAndroidBackHandler(() => {
    window.history.back();
  });

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          marginLeft: "20px",
          marginTop: "12px",
          marginBottom: "32px",
          width: "80px",
          height: "20px",
        }}
        src={APP_LOGO}
        alt={""}
      />

      <Box
        sx={{
          px: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text
          sx={{
            whiteSpace: "pre-line",
            fontStyle: fontStyle.titleXL,
            color: color.default,
          }}
          text={title}
        />
        <VerticalSpacer height={8} />
        <Text
          sx={{
            whiteSpace: "pre-line",
            fontStyle: fontStyle.bodyLr,
            color: color.gray800,
          }}
          text={LocalizedText("ua_b130_message")}
        />
        <VerticalSpacer height={16} />
        <Box
          sx={{
            p: "16px",
            borderRadius: "10px",
            border: `1px solid ${color.gray300}`,
          }}
        >
          <Text
            sx={{
              whiteSpace: "pre-line",
              fontStyle: fontStyle.bodyMr,
              color: color.default,
            }}
            text={`${LocalizedText("ua_b130_email")} ${email}`}
          />
          <VerticalSpacer height={12} />
          <Text
            sx={{
              whiteSpace: "pre-line",
              fontStyle: fontStyle.bodyMr,
              color: color.default,
            }}
            text={`${LocalizedText("ua_b130_deleted")} ${LocalizedDate({
              date: withdrawalTimeMillisecond,
              type: DateFormatType.YearMonthDay,
            })}`}
          />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ px: "20px" }}>
        <CommonButton
          title={LocalizedText("ua_b130_backtologin_button")}
          onClick={() => {
            window.history.back();
          }}
        />
      </Box>

      <VerticalSpacer height={34} />
    </Box>
  );
}
