export class NotificationUiState {
  isActivatedServiceNotification: boolean;
  isActivatedEventingNotification: boolean;

  constructor(
    isActivatedServiceNotification: boolean,
    isActivatedEventingNotification: boolean,
  ) {
    this.isActivatedServiceNotification = isActivatedServiceNotification;
    this.isActivatedEventingNotification = isActivatedEventingNotification;
  }
}
