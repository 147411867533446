import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PWUserAppMapPage from "../pagesMobile/MobileMap/PWUserAppMapPage";
import MobileTest from "../pagesMobile/MobileTest";
import MyRidePage from "../pagesMobile/MobileMyRide/MyRidePage";
import MyRideWatingPage from "../pagesMobile/MobileMyRide/MyRideWatingPage";
import RidingReportScreen from "../pagesMobile/MobileMyRide/section/RidingReportScreen";
import WarrantyScreen from "../pagesMobile/MobileMyRide/section/WarrantyScreen";
import SupportPage from "../pagesMobile/MobileSupport/SupportPage";
import MorePage from "../pagesMobile/MobileMore/MorePage";
import SettingPage from "../pagesMobile/MobileSetting/SettingPage";

import MoreChargingMembershipPage from "../pagesMobile/MobileChargingMembership/MoreChargingMembershipPage";
import MoreCarbonReductionNFTPage from "../pagesMobile/MobileCarbonReductionNFT/MoreCarbonReductionNFTPage";
import MoreWalletPage from "../pagesMobile/MobileWallet/MoreWalletPage";
import MorePartnersPage from "../pagesMobile/MobilePartners/list/MorePartnersPage";
import MoreProfilePage from "../pagesMobile/MobileProfile/MoreProfilePage";
import MoreProfileEditPage from "../pagesMobile/MobileProfileEdit/MoreProfileEditPage";
import FAQListPage from "../pagesMobile/MobileSupport/faq/list/FAQListPage";
import FAQDetailPage from "../pagesMobile/MobileSupport/faq/detail/FAQDetailPage";
import MobileProfileWithdrawalPage from "../pagesMobile/MobileProfileWithdrawal/MobileProfileWithdrawalPage";
import MobileProfileWithdrawalFinishPage from "../pagesMobile/MobileProfileWithdrawal/MobileProfileWithdrawalFinishPage";
import InquiryPage from "../pagesMobile/MobileInquiry/InquiryPage";
import MyScooterContractListScreen from "../pagesMobile/MobileMyRide/section/MyScooterContractListScreen";
import NFTGradePage from "../pagesMobile/MobileCarbonReductionNFT/NFTGradePage/NFTGradePage";

import ExistingAccountPage from "../pagesMobile/MobileLogin/ExistingAccount/ExistingAccountPage";
import NFTDetailPage from "../pagesMobile/MobileWallet/nftdetail/NFTDetailPage";
import IssuedHistoryPage from "../pagesMobile/MobileWallet/issuedhistory/IssuedHistoryPage";
import InquiryDetailPage from "../pagesMobile/MobileInquiry/detail/InquiryDetailPage";
import BatteryPage from "../pagesMobile/MobileBattery/BatteryPage";
import LanguagePage from "../pagesMobile/MobileSetting/language/LanguagePage";
import { ROUTE_PATHS } from "../constants/RouteConstants";
import PayQRPage from "../pagesMobile/MobileChargingMembership/payQR/PayQRPage";
import PrivacyPolicyPage from "../pagesMobile/MobileSetting/document/PrivacyPolicyPage";
import TermsOfUsePage from "../pagesMobile/MobileSetting/document/TermsOfUsePage";
import NotificationPage from "../pagesMobile/MobileSetting/notification/NotificationPage";
import NoticePage from "../pagesMobile/MobileSupport/notice/NoticePage";
import NoticeDetailPage from "../pagesMobile/MobileSupport/notice/detail/NoticeDetailPage";
import MembershipPurchasePage from "../pagesMobile/MobileChargingMembership/membershipPurchase/MembershipPurchasePage";
import InquiryRegistrationPage from "../pagesMobile/MobileInquiry/registration/InquiryRegistrationPage";
import PurchaseHistoryPage from "../pagesMobile/MobileChargingMembership/purchaseHistory/PurchaseHistoryPage";
import UsageHistoryPage from "../pagesMobile/MobileChargingMembership/usageHistory/UsageHistoryPage";
import MorePartnersDetail from "../pagesMobile/MobilePartners/detail/MorePartnersDetail";
import CSListPage from "../pagesMobile/MobileCustomerSupport/list/CSListPage";
import CustomerSupportRegistrationPage from "../pagesMobile/MobileCustomerSupport/registration/CustomerSupportRegistrationPage";
import MoreProfilePhoneAuth from "../pagesMobile/MobileProfileEdit/MoreProfilePhoneAuth";
import LoginLanguagePage from "../pagesMobile/MobileLogin/LoginLanguagePage";
import LogoutMobilePage from "../pagesMobile/MobileLogin/LogoutMobilePage";
import LoginTermsOfUsePage from "../pagesMobile/MobileLogin/LoginTermsOfUsePage";
import LoginTermsOfUsePrivacyDetails from "../pagesMobile/MobileLogin/LoginTermsOfUsePrivacyDetails";
import LoginTermsOfUseDetails from "../pagesMobile/MobileLogin/LoginTermsOfUseDetails";
import LoginDetailInfo from "../pagesMobile/MobileLogin/LoginDetailInfo";
import LoginFinishPage from "../pagesMobile/MobileLogin/LoginFinishPage";
import WithdrawalInfoPage from "../pagesMobile/MobileWithdrawalInfo/WithdrawalInfoPage";
import LoginPage from "../pagesMobile/Login/LoginPage";
import PayRestrictPage from "../pagesMobile/MobileChargingMembership/payRestrictPage/PayRestrictPage";
import CouponHistory from "../pagesMobile/MobileCarbonReductionNFT/CouponHistoryPage/CouponHistory";
import CustomerSupportDetailPage from "../pagesMobile/MobileCustomerSupport/detail/CustomerSupportDetailPage";
import MaintenancePage from "../pagesMobile/Maintenance/MaintenancePage";

import { SERVER_CONFIG } from "../constants/ServerConfig";
import LocationTermsOfUsePage from "../pagesMobile/MobileSetting/document/LocationTermsOfUsePage";
import BatteryTermsOfUsePage from "../pagesMobile/MobileSetting/document/BatteryTermsOfUsePage";
import PhoneAuthPage from "../pagesMobile/MobileSignup/PhoneAuth/PhoneAuthPage";

const MobileRoutes = () => {
  const navigate = useNavigate();
  var lowCaseUrl = window.location.pathname.toLowerCase();

  useEffect(() => {
    if (
      SERVER_CONFIG.ON_MAINTENANCE_APP &&
      -1 === lowCaseUrl.indexOf("/maintenance")
    ) {
      navigate("/Maintenance");
    }
  });

  return (
    <Routes>
      {/* ============ Login ============ */}
      <Route
        path={ROUTE_PATHS.LOGIN_LANGUAGE}
        element={<LoginLanguagePage />}
      />
      {/* <Route path={ROUTE_PATHS.LOGIN_MOBILE} element={<LoginMobilePage />} /> */}
      <Route path={ROUTE_PATHS.LOGIN_MOBILE} element={<LoginPage />} />
      <Route path={ROUTE_PATHS.LOGIN} element={<ExistingAccountPage />} />
      <Route
        path={ROUTE_PATHS.LOGIN_TERMS_OF_USE}
        element={<LoginTermsOfUsePage />}
      />
      <Route
        path={ROUTE_PATHS.LOGIN_TERMS_OF_USE_PRIVACY_DETAILS}
        element={<LoginTermsOfUsePrivacyDetails />}
      />
      <Route
        path={ROUTE_PATHS.LOGIN_TERMS_OF_USE_DETAILS}
        element={<LoginTermsOfUseDetails />}
      />
      <Route path={ROUTE_PATHS.PHONE_AUTH} element={<PhoneAuthPage />} />
      <Route
        path={ROUTE_PATHS.LOGIN_DETAIL_INFO}
        element={<LoginDetailInfo />}
      />
      <Route
        path={ROUTE_PATHS.LOGIN_FINISH_PAGE}
        element={<LoginFinishPage />}
      />
      <Route
        path={ROUTE_PATHS.WITHDRAWAL_INFO}
        element={<WithdrawalInfoPage />}
      />

      {/* ============ Logout ============ */}
      <Route path={ROUTE_PATHS.LOGOUT_MOBILE} element={<LogoutMobilePage />} />
      <Route
        path={ROUTE_PATHS.LOGOUT_MOBILE_CALLBACK}
        element={<LogoutMobilePage />}
      />

      {/* ============ MyRide ============ */}
      <Route path={ROUTE_PATHS.MY_RIDE} element={<MyRidePage />} />
      <Route
        path={ROUTE_PATHS.SCOOTER_WAITING}
        element={<MyRideWatingPage />}
      />
      <Route
        path={ROUTE_PATHS.RIDING_REPORT}
        element={<RidingReportScreen />}
      />
      <Route path={ROUTE_PATHS.WARRANTY} element={<WarrantyScreen />} />
      <Route
        path={ROUTE_PATHS.MY_SCOOTER_CONTRACT_LIST}
        element={<MyScooterContractListScreen />}
      />

      {/* ============ Battery ============ */}
      <Route path={ROUTE_PATHS.BATTERY} element={<BatteryPage />} />

      {/* ============ Map ============ */}
      <Route path={ROUTE_PATHS.MAP} element={<PWUserAppMapPage />} />

      {/* ============ Support ============ */}
      <Route path={ROUTE_PATHS.SUPPORT} element={<SupportPage />} />
      <Route path={ROUTE_PATHS.FAQ_LIST} element={<FAQListPage />} />
      <Route path={ROUTE_PATHS.FAQ_DETAIL} element={<FAQDetailPage />} />
      <Route path={ROUTE_PATHS.INQUIRY_PAGE} element={<InquiryPage />} />
      <Route
        path={ROUTE_PATHS.INQUIRY_DETAIL}
        element={<InquiryDetailPage />}
      />
      <Route
        path={ROUTE_PATHS.INQUIRY_REGISTRATION}
        element={<InquiryRegistrationPage />}
      />
      <Route path={ROUTE_PATHS.NOTICE} element={<NoticePage />} />
      <Route path={ROUTE_PATHS.NOTICE_DETAIL} element={<NoticeDetailPage />} />
      <Route
        path={ROUTE_PATHS.CUSTOMER_SUPPORT_REGISTRATION}
        element={<CustomerSupportRegistrationPage />}
      />
      <Route path={ROUTE_PATHS.CS_LIST} element={<CSListPage />} />
      <Route
        path={ROUTE_PATHS.CUSTOMER_SUPPORT_DETAIL}
        element={<CustomerSupportDetailPage />}
      />

      {/* ============ More ============ */}
      <Route path={ROUTE_PATHS.MORE_PAGE} element={<MorePage />} />
      <Route path={ROUTE_PATHS.PROFILE} element={<MoreProfilePage />} />
      <Route
        path={ROUTE_PATHS.EDIT_PROFILE}
        element={<MoreProfileEditPage />}
      />
      <Route
        path={ROUTE_PATHS.EDIT_PROFILE_PHONE_AUTH}
        element={<MoreProfilePhoneAuth />}
      />

      <Route
        path={ROUTE_PATHS.WITHDRAWAL}
        element={<MobileProfileWithdrawalPage />}
      />
      <Route
        path={ROUTE_PATHS.WITHDRAWAL_FINISH}
        element={<MobileProfileWithdrawalFinishPage />}
      />
      <Route path={ROUTE_PATHS.PARTNERS} element={<MorePartnersPage />} />
      <Route
        path={ROUTE_PATHS.PARTNERS_DETAIL}
        element={<MorePartnersDetail />}
      />
      <Route
        path={ROUTE_PATHS.CARBON_REDUCTION_NFT}
        element={<MoreCarbonReductionNFTPage />}
      />

      <Route path={ROUTE_PATHS.NFT_GRADE_PAGE} element={<NFTGradePage />} />
      <Route path={ROUTE_PATHS.COUPON_HIS_PAGE} element={<CouponHistory />} />

      <Route
        path={ROUTE_PATHS.CHARGING_MEMBERSHIP}
        element={<MoreChargingMembershipPage />}
      />
      <Route path={ROUTE_PATHS.PAY_QR} element={<PayQRPage />} />
      <Route
        path={ROUTE_PATHS.PAY_RESTRICT_PAGE}
        element={<PayRestrictPage />}
      />
      <Route
        path={ROUTE_PATHS.MEMBERSHIP_PURCHASE}
        element={<MembershipPurchasePage />}
      />
      <Route
        path={ROUTE_PATHS.PURCHASE_HISTORY}
        element={<PurchaseHistoryPage />}
      />
      <Route path={ROUTE_PATHS.USAGE_HISTORY} element={<UsageHistoryPage />} />

      {/* ============ Wallet ============ */}
      <Route path={ROUTE_PATHS.WALLET} element={<MoreWalletPage />} />
      <Route path={ROUTE_PATHS.NFT_DETAIL} element={<NFTDetailPage />} />
      <Route
        path={ROUTE_PATHS.ISSUED_HISTORY}
        element={<IssuedHistoryPage />}
      />
      {/* ============ Setting ============ */}
      <Route path={ROUTE_PATHS.SETTING} element={<SettingPage />} />
      <Route path={ROUTE_PATHS.NOTIFICATION} element={<NotificationPage />} />
      <Route path={ROUTE_PATHS.LANGUAGE} element={<LanguagePage />} />
      <Route
        path={ROUTE_PATHS.PRIVACY_POLICY}
        element={<PrivacyPolicyPage />}
      />
      <Route path={ROUTE_PATHS.TERMS_OF_SERVICE} element={<TermsOfUsePage />} />
      <Route
        path={ROUTE_PATHS.LOCATION_TERMS_OF_SERVICE}
        element={<LocationTermsOfUsePage />}
      />
      <Route
        path={ROUTE_PATHS.BATTERY_TERMS_OF_SERVICE}
        element={<BatteryTermsOfUsePage />}
      />

      {/* ============ 테스트 화면 ============  */}
      <Route path={ROUTE_PATHS.MOBILE_TEST} element={<MobileTest />} />

      <Route path={ROUTE_PATHS.MAINTENANCE} element={<MaintenancePage />} />
    </Routes>
  );
};

export default MobileRoutes;
