export class NoticeDetailUiState {
  title: string;
  content: string;
  imageUrls: string[];
  time: string;

  constructor(
    title: string,
    content: string,
    imageUrls: string[],
    time: string,
  ) {
    this.title = title;
    this.content = content;
    this.imageUrls = imageUrls;
    this.time = time;
  }
}
