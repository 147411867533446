import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PurchaseHistoryUiEvent } from "./PurchaseHistoryUiEvent";
import { PurchaseHistoryUiState } from "./PurchaseHistoryUiState";
import { PurchaseHistorySideEffect } from "./PurchaseHistorySideEffect";
import PurchaseHistoryRender from "./PurchaseHistoryRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import ProgressBar from "../../../component/ProgressBar";
import { ApiService } from "../../../restAPI/ApiService";
import { CalendarType } from "../../../component/InquiryPeriod/CalendarDrawer";
import { PeriodButtonType } from "../../../component/InquiryPeriod/PeriodButton";

const PurchaseHistoryPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); //나중에 true로 변경
  const [uiState, setUiState] = useState(new PurchaseHistoryUiState());

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    getAllPurchaseHistory(
      1,
      "Membership",
      localStorage.getItem("uuid") || ""
    ).then((r) => {});
  }, []);

  const uiEventHandler: PurchaseHistoryUiEvent = {
    HandleTabChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          currentTab: event,
        };
      });

      sideEffectHandler.requestPurchaseHistory(
        event,
        uiState.startDate == 0 ? undefined : uiState.startDate,
        uiState.endDate == 0 ? new Date().getTime() : uiState.endDate,
        uiState.periodDuration
      );
    },
    SetPeriodDuration: (type: PeriodButtonType) => {
      let monthsToSubtract: number | undefined = 0;
      switch (type) {
        case PeriodButtonType.ONE_MONTH:
          monthsToSubtract = 1;
          break;
        case PeriodButtonType.THREE_MONTH:
          monthsToSubtract = 3;
          break;
        case PeriodButtonType.ALL:
          monthsToSubtract = 100;
          break;
        case PeriodButtonType.CUSTOM:
          monthsToSubtract = undefined;
          break;
      }
      setUiState((prev) => {
        return {
          ...prev,
          periodDuration: monthsToSubtract,
        };
      });
    },
    OpenDatePicker: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDataPicker: true,
        };
      });
    },
    CloseDatePicker: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDataPicker: false,
        };
      });
    },
    OpenCalendar: (type) => {
      setUiState((prev) => {
        return {
          ...prev,
          isCalendarOpen: true,
          calendarType: type,
        };
      });
    },
    CloseCalendar: (type, date) => {
      setUiState((prev) => {
        return {
          ...prev,
          isCalendarOpen: false,
          startDate: type === CalendarType.START ? date : prev.startDate,
          endDate: type === CalendarType.END ? date : prev.endDate,
          selectedDate: 0,
        };
      });
    },
    SelectDate: (date) => {
      setUiState((prev) => {
        return {
          ...prev,
          selectedDate: date,
          startDate:
            prev.calendarType === CalendarType.START ? date : prev.startDate,
          endDate: prev.calendarType === CalendarType.END ? date : prev.endDate,
        };
      });
    },
    ShowDateAlert: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isDateAlertOpen: true,
        };
      });
    },
    HideDateAlert: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isDateAlertOpen: false,
        };
      });
    },
  };

  const sideEffectHandler: PurchaseHistorySideEffect = {
    back: () => {
      window.history.back();
    },
    navigate: (path: string, state?: any) => {
      navigate(path, { state: { id: state.id } });
    },
    requestPurchaseHistory(type, startDate, endDate, monthsToSubtract) {
      getPurchaseHistory(type, startDate, endDate, monthsToSubtract);
    },
  };

  async function getAllPurchaseHistory(
    page: number,
    type: string,
    uuid: string
  ) {
    const requestBody = {
      page: page,
      type: type,
      uuid: uuid,
    };
    try {
      const response = await ApiService.MobilePost(
        "/pw/ua/product/MV/purchaseList",
        requestBody
      );
      const data = response?.data?.body;

      if (data) {
        setUiState((prev) => {
          return {
            ...prev,
            historyList: data,
          };
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function getPurchaseHistory(
    type: string,
    start: number | undefined,
    end: number,
    monthsToSubtract: number | undefined
  ) {
    const targetDate = calculateDateFromInputDate(end, monthsToSubtract ?? 0);
    const body = {
      endDate: end,
      page: 1,
      type: type,
      uuid: localStorage.getItem("uuid") || "",
      startDate: start ?? targetDate,
    };

    try {
      const response = await ApiService.MobilePost(
        "/pw/ua/product/MV/purchaseFilter",
        body
      );
      const data = response?.data?.body;

      if (data) {
        setUiState((prev) => {
          return {
            ...prev,
            historyList: data,
          };
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  function calculateDateFromInputDate(end: number, monthsToSubtract: number) {
    const targetDate = new Date(end);
    targetDate.setMonth(targetDate.getMonth() - monthsToSubtract);
    return targetDate.getTime();
  }

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <>
      <PurchaseHistoryRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandler}
      />
    </>
  );
};

export default PurchaseHistoryPage;
