import { SERVER_CONFIG } from "../constants/ServerConfig";
import { ApiService } from "./ApiService";

export const ApiKeycloak = {
  async handleGetTokenFromKeycloak() {
    const config = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    const params = {
      client_id: "admin-cli",
      username: SERVER_CONFIG.KC_NAME,
      password: SERVER_CONFIG.KC_PW,
      grant_type: "password",
    };

    try {
      const res = await ApiService.keycloakPost(
        "/realms/master/protocol/openid-connect/token",
        params,
        config
      );

      if (res?.data?.access_token) {
        return res.data.access_token;
      } else {
        throw new Error("Failed to get access token");
      }
    } catch (error) {
      console.error("Error fetching token", error);
      throw error;
    }
  },
  async handleGetUserIdFromKeycloak(token: string) {
    const config = {
      headers: { Authorization: "Bearer " + token },
    };
    const username = localStorage.getItem("username");

    try {
      const res = await ApiService.keycloakGet(
        "/admin/realms/pw_user_app/users",
        username,
        config
      );

      if (res?.data[0]?.id) {
        return res.data[0].id;
      } else {
        throw new Error("Failed to get user ID");
      }
    } catch (error) {
      console.error("Error fetching user ID", error);
      throw error;
    }
  },

  async handleGetUserId() {
    try {
      const token = await this.handleGetTokenFromKeycloak();
      const userId = await this.handleGetUserIdFromKeycloak(token);

      localStorage.setItem("useruuid", userId);
      console.log("User ID saved:", userId);
    } catch (error) {
      console.error("Error in handleGetUserId", error);
    }
  },

  handleDeleteUserFromKeycloak(token: String, id: String, callback: Function) {
    var config = {
      headers: { Authorization: "Bearer " + token },
    };

    ApiService.keycloakDelete(
      "/admin/realms/pw_user_app/users",
      id,
      config
    ).then((res) => {
      if (204 == res?.status) {
        localStorage.clear();
        callback();
      } else {
        // can not delete account
      }
    });
  },
};
