import { Box, Divider } from "@mui/material";
import { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

export enum ProductionManagementTabType {
  PRODUCTION = "production",
  MATERIALS = "materials",
  PRODUCTION_HISTORY = "productionHistory",
  MATERIAL_HISTORY = "materialHistory",
  SPECIFICATION_HISTORY = "specificationHistory",
}

export interface ProductionManagementTabProps {
  currentTab: ProductionManagementTabType;
  changeTab: (tab: ProductionManagementTabType) => void;
}

export function ProductionManagementTab({
  currentTab,
  changeTab,
}: ProductionManagementTabProps) {
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={12} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == ProductionManagementTabType.PRODUCTION
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(ProductionManagementTabType.PRODUCTION);
        }}
      >
        생산
      </Box>
      <HorizontalSpacer width={16} />
      <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == ProductionManagementTabType.MATERIALS
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(ProductionManagementTabType.MATERIALS);
        }}
      >
        자재
      </Box>
      <HorizontalSpacer width={16} />
      <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == ProductionManagementTabType.PRODUCTION_HISTORY
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(ProductionManagementTabType.PRODUCTION_HISTORY);
        }}
      >
        생산 이력
      </Box>
      <HorizontalSpacer width={16} />
      <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == ProductionManagementTabType.MATERIAL_HISTORY
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(ProductionManagementTabType.MATERIAL_HISTORY);
        }}
      >
        자재 이력
      </Box>
      <HorizontalSpacer width={16} />
      <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == ProductionManagementTabType.SPECIFICATION_HISTORY
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(ProductionManagementTabType.SPECIFICATION_HISTORY);
        }}
      >
        명세 이력
      </Box>
    </Box>
  );
}
