import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
  SelectChangeEvent,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VerticalSpacer from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ApiService } from "../../../restAPI/ApiService";
import CommonDialog from "../../../component/CommonDialog";
import { CommonSelect } from "../../../component/CommonSelect";
import { ICON_SEARCH } from "../../../constants/appImagePath";

interface NfcCreateGridProps {
  createDialogOpen: any;
  setCreateDialogOpen: any;
}

const NfcCreateGrid = ({
  createDialogOpen,
  setCreateDialogOpen,
}: NfcCreateGridProps) => {
  const navigate = useNavigate();

  // id 관련
  const [id, setId] = useState("");
  const [idError, setIdError] = useState(false);
  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setId(event.target.value);
  };

  // type 관련
  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState(false);
  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  // valid 관련
  const [valid, setValid] = useState("");
  const [validError, setValidError] = useState(false);
  const handleValidChange = (event: SelectChangeEvent) => {
    setValid(event.target.value as string);
    // setReason("");
  };

  // owner 관련
  const [search, setSearch] = useState("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  // reason 관련
  const [reason, setReason] = useState("");

  const handleSearchKeyDown = (event: any) => {
    if ("Enter" !== event.key) return;
    console.log("search : ", search);
    ApiService.WebPost("/pw/backend/api/nfccard/searchOwner", {
      name: search,
    }).then((res) => {
      setOwner("");
      setOwnerList([]);
      setSlctOwnerDisabled(true);

      if (0 === res?.data.body.length) return;

      setOwnerList(res?.data.body);
      setSlctOwnerDisabled(false);
      setSearch("");
    });
  };

  const [owner, setOwner] = useState("");
  const [ownerUuid, setOwnerUuid] = useState("");
  const [ownerList, setOwnerList] = useState([]);
  const [slctOwnerError, setSlctOwnerError] = useState(false);
  const [slctOwnerDisabled, setSlctOwnerDisabled] = useState(true);
  const [existDialogOpen, setExistDialogOpen] = useState(false);
  const handleOwnerChange = (event: any) => {
    setOwner(event.target.value.name + " (" + event.target.value.email + ")");
    setOwnerUuid(event.target.value.uuid);
  };

  // showHide 관련
  const [showHide, setShowHide] = useState<boolean>(true);
  const handleChange = (event: boolean) => {
    setShowHide(event);
  };

  useEffect(() => {}, []);

  // 다이얼로그 닫기
  const handleClose = () => {
    setCreateDialogOpen(false);
  };

  const handleExistOk = () => {
    setExistDialogOpen(false);
  };

  // 데이터 검증 및 API 호출
  const handleOk = () => {
    let hasError = false;

    // 각 필드에 대한 데이터 검증
    if (id.length <= 0) {
      setIdError(true);
      hasError = true;
    } else {
      setIdError(false);
    }

    if (type === "") {
      setTypeError(true);
      hasError = true;
    } else {
      setTypeError(false);
    }

    if (valid === "") {
      setValidError(true);
      hasError = true;
    } else {
      setValidError(false);
    }

    if (hasError) {
      setCreateDialogOpen(false);
    } else {
      console.log(
        "nfcCardId : " +
          id +
          "\ntype : " +
          type +
          "\nvalid : " +
          valid +
          "\nowner : " +
          (ownerUuid === "" ? null : ownerUuid) +
          "\nregister : " +
          localStorage.getItem("user_uuid")
      );
      ApiService.WebPost("/pw/backend/api/nfccard/create", {
        nfcCardId: id,
        type: type,
        valid: valid,
        owner: ownerUuid === "" ? null : ownerUuid,
        register: localStorage.getItem("user_uuid"),
      }).then((res) => {
        if (res?.data.message === "this nfc card already exist") {
          setExistDialogOpen(true);
          setCreateDialogOpen(false);
        } else {
          console.log(res?.data);
          setCreateDialogOpen(false);
        } // 다이얼로그 닫기
        if (res?.data?.createdId) {
          navigate("/nfcCardManagement?" + res?.data?.createdId);
        }
      });
    }
  };

  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={6}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              padding: "0px",
            }}
          >
            <Box>
              <Typography
                component="span"
                fontStyle={fontStyle.boldXS}
                color={color.gray600}
              >
                ID
              </Typography>
              <VerticalSpacer height={8} />
              <TextField
                sx={{ width: "100%" }}
                value={id}
                error={idError === true}
                onChange={handleIdChange}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                inputProps={{
                  sx: {
                    px: "16px",
                    py: "0px",
                    minHeight: "48px",
                  },
                }}
              />
              {idError == true && (
                <>
                  <VerticalSpacer height={4} />
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Invalid ID
                  </Typography>
                </>
              )}
            </Box>
            <VerticalSpacer height={40} />
            <Box>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Type
              </Typography>
              <Select
                value={type}
                error={typeError === true}
                sx={{
                  width: "100%",
                  mt: 1,
                  borderRadius: "12px",
                }}
                onChange={handleTypeChange}
                renderValue={(v) => {
                  return v?.length ? v : null;
                }}
              >
                <MenuItem
                  value="ISO14443A"
                  sx={{
                    fontStyle: fontStyle.semiboldXS,
                    color: color.gray600,
                  }}
                >
                  ISO14443A
                </MenuItem>
                <MenuItem
                  value="ISO14443B"
                  sx={{
                    fontStyle: fontStyle.semiboldXS,
                    color: color.gray600,
                  }}
                >
                  ISO14443B
                </MenuItem>
                <MenuItem
                  value="ISO15693"
                  sx={{
                    fontStyle: fontStyle.semiboldXS,
                    color: color.gray600,
                  }}
                >
                  ISO15693
                </MenuItem>
                <MenuItem
                  value="FELICA"
                  sx={{
                    fontStyle: fontStyle.semiboldXS,
                    color: color.gray600,
                  }}
                >
                  FELICA
                </MenuItem>
                <MenuItem
                  value="ULTRALIGHT"
                  sx={{
                    fontStyle: fontStyle.semiboldXS,
                    color: color.gray600,
                  }}
                >
                  ULTRALIGHT
                </MenuItem>
              </Select>
              {typeError == true && (
                <>
                  <VerticalSpacer height={4} />
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Select NFC card Type
                  </Typography>
                </>
              )}
            </Box>
            <VerticalSpacer height={40} />
            <Box>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Valid
              </Typography>
              <Select
                value={valid}
                error={validError === true}
                sx={{
                  width: "100%",
                  mt: 1,
                  borderRadius: "12px",
                }}
                onChange={handleValidChange}
                renderValue={(v) => {
                  return v?.length ? v : null;
                }}
              >
                <MenuItem
                  value="Active"
                  sx={{
                    fontStyle: fontStyle.semiboldXS,
                    color: color.gray600,
                  }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  value="Inactive"
                  sx={{
                    fontStyle: fontStyle.semiboldXS,
                    color: color.gray600,
                  }}
                >
                  Inactive
                </MenuItem>
              </Select>
              {validError == true && (
                <>
                  <VerticalSpacer height={4} />
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Select Valid option
                  </Typography>
                </>
              )}
              <VerticalSpacer height={40} />
              {/* <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Reason
                </Typography>
              </Box>
              <VerticalSpacer height={8} />
              <Box
                sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
              >
                <CommonSelect
                  paddingBottom={14}
                  paddingTop={14}
                  height={20}
                  sx={{
                    minHeight: "48px",
                    flexGrow: 1,
                    fontStyle: fontStyle.regularS,
                  }}
                  itemSx={fontStyle.regularS}
                  menuitems={
                    valid == "Active"
                      ? [
                          "Regist(New)",
                          "Regist(Lost)",
                          "Regist(Defect)",
                          "Regist(Request)",
                          "Regist(etc)",
                        ]
                      : [
                          "No Regist",
                          "Inactive(Lost)",
                          "Inactive(Defect)",
                          "Inactive(Request)",
                          "Inactive(etc)",
                        ]
                  }
                  selectValue={reason}
                  onChange={(event) => {
                    setReason(event.target.value as string);
                  }}
                  placeholder="Select"
                  disabled={valid == "" ? true : false}
                />
              </Box> */}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            padding: "24px",
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              padding: "0px",
            }}
          >
            <Box>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Owner
              </Typography>
              <VerticalSpacer height={8} />
              <TextField
                sx={{ width: "100%" }}
                value={search}
                onKeyDown={handleSearchKeyDown}
                onChange={handleSearchChange}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },

                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={ICON_SEARCH} width="18px" height="18px" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  sx: {
                    py: "0px",
                    minHeight: "48px",
                  },
                }}
              ></TextField>
              <Select
                disabled={slctOwnerDisabled}
                value={owner}
                sx={{
                  backgroundColor: `${
                    slctOwnerDisabled ? color.disableColor : ""
                  }`,
                  borderRadius: "12px",
                  mt: 2,
                  width: "100%",
                }}
                onChange={handleOwnerChange}
                renderValue={(v) => {
                  return v?.length ? v : null;
                }}
              >
                {ownerList &&
                  ownerList.map((item: any) => {
                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        sx={{
                          fontStyle: fontStyle.semiboldXS,
                          color: color.gray600,
                        }}
                      >
                        {item.name} ({item.email})
                      </MenuItem>
                    );
                  })}
              </Select>
              {slctOwnerError == true && (
                <>
                  <VerticalSpacer height={4} />
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Select Owner option
                  </Typography>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <CommonDialog
        open={createDialogOpen}
        handleClose={handleClose}
        handleOk={handleOk}
      >
        <Typography fontStyle={fontStyle.regularS} color={color.gray800}>
          Will you
        </Typography>
        <Typography fontStyle={fontStyle.regularS} color={color.primaryWeb500}>
          &nbsp;Add&nbsp;
        </Typography>
        <Typography fontStyle={fontStyle.regularS} color={color.gray800}>
          NFC Card?
        </Typography>
      </CommonDialog>
      <CommonDialog
        open={existDialogOpen}
        handleOk={handleExistOk}
        cancleButton={false}
      >
        <Typography fontStyle={fontStyle.regularS} color={color.gray800}>
          This card is already registered.
        </Typography>
      </CommonDialog>
    </Grid>
  );
};

export default NfcCreateGrid;
