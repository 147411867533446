import { CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer from "../../../../../component/Spacer";
import { ProductBOMTreeTable } from "./ProductBOMTreeTable";
import { HeadCell } from "../../../../../component/CommonTable";
import { useEffect, useState } from "react";
import { ProductBOMData, ProductData, ProductList } from "../model/ProductData";

interface ProductBOMTreeProps {
  selectValue: string;
}

export function ProductBOMTree({ selectValue }: ProductBOMTreeProps) {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(6);
  const [rows, setRows] = useState<ProductData[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const filteredData = ProductList.filter((item) => {
      return searchContents
        ? item.category.toLowerCase().includes(searchContents.toLowerCase()) ||
            item.partsCode.toLowerCase().includes(searchContents.toLowerCase())
        : true;
    });
    const startData = page * rowsPerPage;
    const endData = startData + rowsPerPage;
    const pagedata = filteredData.slice(startData, endData);
    setRows(pagedata);
    setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
    setDocCount(filteredData.length);
  }, [page, rowsPerPage, searchContents]);

  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>
        Bill of Material Tree
      </Typography>
      <VerticalSpacer height={16} />
      <ProductBOMTreeTable
        headCells={productBOMTreeHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        productList={
          ProductList.find((product) => product.partsCode === selectValue)
            ?.productBOMData || []
        }
      ></ProductBOMTreeTable>
    </CardContent>
  );
}

const productBOMTreeHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  {
    disablePadding: false,
    align: "left",
    id: "necessary",
    label: "Necessary",
  },
  {
    disablePadding: false,
    align: "left",
    id: "amount",
    label: "Amount",
  },
  {
    disablePadding: false,
    align: "left",
    id: "company",
    label: "Company",
  },
];
