import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import VerticalSpacer from "../../../component/Spacer";
import UserScooterRender from "../component/userScooterInfo/UserScooterRender";
import { UserDetailsUiState } from "../UserDetailsUiState";
import { UserDetailsUiEvent } from "../UserDetailsUiEvent";
import UserScooterEmptyRender from "../component/userScooterInfo/UserScooterEmptyRender";
import { ApiService } from "../../../restAPI/ApiService";

interface UserScooterInfoGridProps {
  uiState: UserDetailsUiState;
  uiEvent: UserDetailsUiEvent;
}

const UserScooterInfoGrid = ({
  uiState,
  uiEvent,
}: UserScooterInfoGridProps) => {
  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/scooterAgreement/agreementNumberList", {
      uuid: uiState.uuid,
    }).then((res) => {
      console.log(res?.data.body);
      if (res?.data.body) {
        uiEvent.HandleUiStateChange("ScooterSheet", res?.data.body || []);
      }
    });
  }, []);
  useEffect(() => {
    if (uiState.ScooterSheet.length <= 0) {
      uiEvent.HandleUiStateChange("AddScooter", false);
    } else {
      uiEvent.HandleUiStateChange("AddScooter", true);
    }
  }, [uiState.ScooterSheet]);

  return (
    <Grid container columnSpacing={2.75} sx={{ pt: "16px" }}>
      {uiState.AddScooter == false && (
        <UserScooterEmptyRender uiEvent={uiEvent} uiState={uiState} />
      )}
      {uiState.IdCardImageFileName == undefined ? null : uiState.AddScooter ==
        true ? (
        <UserScooterRender uiState={uiState} uiEvent={uiEvent} />
      ) : null}
    </Grid>
  );
};

export default UserScooterInfoGrid;
