import { ParamType } from '../store/paramType';


const translations: Record<ParamType, string> = {
  [ParamType.BASELINE]: "베이스라인",
  [ParamType.PROJECT]: "프로젝트",
  [ParamType.FUEL_EFFICIENCY]: "연비",
  [ParamType.EMISSION_FACTOR]: "배출계수",
  [ParamType.NET_CALORIC_VALUE]: "순비열량",
  [ParamType.MJ_I]: "MJ/I",
  [ParamType.KG_CO2_EQ_KM]: "kg CO2/KM",
  [ParamType.CHANGE]: "변동",
  [ParamType.FIXED]: "고정",
  [ParamType.RESULT]: "결과",
  [ParamType.FINAL_REDUCTION]: "최종 감축량"
 
};




export const translate = (key: ParamType): string => {
  return translations[key] || key;
};

