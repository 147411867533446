import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  APP_LOGO,
  APP_LANGUAGE_BACKGROUND,
  IMAGE_KHMER,
  IMAGE_LOGIN_CHARACTER,
  IMAGE_US,
} from "../../constants/appImagePath";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { LanguageButtonModel } from "../MobileSetting/language/model/LanguageButtonModel";
import CommonButton from "../../component/CommonButton";

const MaintenancePage = () => {
  const startDate = "2024-NOV-15 PM 03:00";
  const endDate = "2024-NOV-16 PM 12:00";

  useEffect(() => {
    localStorage.removeItem("LOCALE_STORAGE_KEY");
  }, []);

  return (
    <Box
      sx={{
        backgroundImage:  `url(${APP_LANGUAGE_BACKGROUND})`,
        display: "flex",
        position: "fixed",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "block",
          padding: "30px 15px",
          position: "relative",
          width: "calc(100% - 30px)",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "10%",
          }}
        >
          <img
            src={APP_LOGO}
            alt="Login Character"
            style={{
              width: "auto",
              height: "30px",
            }}
          />
        </Box>
        <Box
          sx={{
            position: "relative",
            textAlign: "center",
            textShadow: "1px 1px 1px black",
            zIndex: "1",
            marginTop: "40%",
            width: "100%",
            height: "70%",
          }}
        >
          <Typography
            fontStyle={fontStyle.headingS}
            sx={{
              color: color.white,
              width: "100%",
            }}
          >
            POPLE World server<br/>
            maintenance in progress
          </Typography>
          <br/>
          <Typography
            fontStyle={fontStyle.regularS}
            sx={{
              color: color.white,
              width: "100%",
            }}
          >
            We apologize for the inconvenience.<br/>
            We are currently performing<br/>
            maintenance to provide better service.
          </Typography>
          <br/>
          <Typography
            fontStyle={fontStyle.regularS}
            sx={{
              color: color.white,
              width: "100%",
            }}
          >
            {startDate && (`Start : ${startDate}`)}
            <br/>
            {endDate && (`End : ${endDate}`)}
          </Typography>
          <br/>
        </Box>
        <img
          src={IMAGE_LOGIN_CHARACTER}
          alt="Login Character"
          style={{
            position: "absolute",
            zIndex: "0",
            left: "0",
            bottom: "-150px",
            width: "100%",
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
};
export default MaintenancePage;