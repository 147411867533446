import { IssuedHistorySideEffect } from "./IssuedHistorySideEffect";
import { IssuedHistoryUiState } from "./IssuedHistoryUiState";
import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import Toolbar from "../../../component/Toolbar";
import { HorizontalGrayDivider } from "../../../component/Divider";
import VerticalSpacer from "../../../component/Spacer";

interface IssuedHistoryRenderProps {
  sideEffect: IssuedHistorySideEffect;
  uiState: IssuedHistoryUiState;
}

const IssuedHistoryRender = ({
  sideEffect,
  uiState,
}: IssuedHistoryRenderProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          backgroundColor: color.white,
        }}
      >
        {/* Toolbar */}
        <Toolbar
          title="Issued History"
          onBack={() => {
            sideEffect.navigate("Wallet");
          }}
        />

        {/* Issued History */}
        <Box
          sx={{
            width: "calc(100vw - 40px)",
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {uiState.certificates.map((certificate, index) => (
            <Box key={index} sx={{ pb: "8px" }}>
              <VerticalSpacer height={16} />
              <Typography
                fontStyle={fontStyle.titleM}
                color={color.default}
                sx={{
                  pb: "4px",
                }}
              >
                [{certificate.status}] {certificate.title}
              </Typography>
              {certificate.vin && (
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                  VIN: {certificate.vin}
                </Typography>
              )}

              {certificate.email && (
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                  E-mail: {certificate.email}
                </Typography>
              )}

              <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                {certificate.date}
              </Typography>

              <VerticalSpacer height={16} />

              <HorizontalGrayDivider />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default IssuedHistoryRender;
