import { Category } from "./model/Category";
import { FAQ } from "./model/FAQ";

export class FAQListUiState {
  categoryItems: Category[] = [];
  questions: FAQ[] = [];

  constructor(categoryItems: Category[], questionItemList: FAQ[]) {
    this.categoryItems = categoryItems;
    this.questions = questionItemList;
  }
}
