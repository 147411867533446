import {
  Box,
  Card,
  CardContent,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { useState } from "react";
import { Height } from "@mui/icons-material";

interface CustomSelectProps {
  sx?: SxProps<Theme>;
}

export function CustomSelect({ sx }: CustomSelectProps) {
  const [select, setSelect] = useState("Select");
  const onSelectChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as string);
  };
  return (
    <Select
      value={select}
      sx={{ height: "48px", ...sx }}
      onChange={onSelectChange}
      renderValue={(v) => {
        return v?.length ? v : null;
      }}
    ></Select>
  );
}
interface CustomTextFieldProps {
  variant?: "outlined" | "standard" | "filled";
  placeholder?: string;
}

export function CustomTextField({
  variant,
  placeholder,
}: CustomTextFieldProps) {
  return (
    <TextField
      variant={variant}
      placeholder={placeholder}
      fullWidth
      inputProps={{
        sx: {
          px: "16px",
          py: "14px",
          fontStyle: fontStyle.regularS,
          height: "20px",
        },
      }}
    ></TextField>
  );
}

export function NFTInformationCard() {
  const [select, setSelect] = useState("select");
  const onSelectChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as string);
  };
  return (
    <Grid item lg={12}>
      <Card
        sx={{
          borderRadius: 3,
          width: "100%",
          // display: "flex",
        }}
      >
        <CardContent
        // sx={{
        //   width: "50%",
        //   height: "50%",
        //   // padding: "0px",
        //   // "&:last-child": {
        //   //   paddingBottom: 0,
        //   // },
        // }}
        >
          <Box>
            <Typography fontStyle={fontStyle.semiboldL} color={color.default}>
              Information
            </Typography>
          </Box>
        </CardContent>
        <CardContent>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                NFT Character name
              </Typography>
              <VerticalSpacer height={8} />
              <CustomTextField />
            </Box>
            <HorizontalSpacer width={28} />
            {/* <Box
              // sx={{
              //   mx: "24px",
              //   mt: "24px",
              //   mb: "40px",
              //   display: "flex",
              //   flexDirection: "column",
              // }}
            ></Box> */}
            <Box sx={{ width: "50%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Distribute Date
              </Typography>
              <VerticalSpacer height={8} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <CustomSelect sx={{ flexGrow: 1, marginRight: "12px" }} />
                <CustomSelect sx={{ flexGrow: 1, marginRight: "12px" }} />
                <CustomSelect sx={{ flexGrow: 1 }} />
              </Box>
            </Box>
          </Box>
          {/* <Box
              sx={{
                mx: "24px",
                mt: "24px",
                mb: "40px",
                display: "flex",
                flexDirection: "column",
              }}
            ></Box> */}
        </CardContent>
      </Card>
    </Grid>
  );
}

interface NFTLevelCardProps {
  level: number;
}

export function NFTLevelCard({ level }: NFTLevelCardProps) {
  return (
    <Grid item xs={6}>
      <Card
        sx={{
          borderRadius: 3,
          width: "100%",
          padding: "24px",
          // display: "flex",
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Box>
            <Typography fontStyle={fontStyle.semiboldL} color={color.default}>
              Level {level}
            </Typography>
            <VerticalSpacer height={16} />
          </Box>
        </CardContent>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            // "&:last-child": {
            //   paddingBottom: 0,
            // },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                paddingRight: "24px",
              }}
            >
              <img style={{ width: "188px", height: "188px" }} src="d" alt="" />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Level name
                </Typography>
                <VerticalSpacer height={8} />
                <CustomTextField />
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Max Exp
                </Typography>
                <VerticalSpacer height={8} />
                <CustomTextField />
              </Box>
            </Box>
          </Box>
          <VerticalSpacer height={40} />
          <Box
            sx={{
              // mx: "24px",
              // mt: "24px",
              // mb: "40px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Reward
            </Typography>
            <VerticalSpacer height={8} />
            <CustomSelect></CustomSelect>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

const NFTCreateGrid = () => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      <NFTInformationCard />
      <NFTLevelCard level={1} />
      <NFTLevelCard level={2} />
      <NFTLevelCard level={3} />
      <NFTLevelCard level={4} />
      <NFTLevelCard level={5} />
    </Grid>
  );
};

export default NFTCreateGrid;
