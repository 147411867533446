export class ProductData {
  id: number;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  productBOMData?: ProductBOMData[];
  constructor(
    id: number,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    productBOMData?: ProductBOMData[]
  ) {
    this.id = id;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.productBOMData = productBOMData;
  }
}

export enum AdminType {
  NOTMODIFIED = "NOTMODIFIED",
  WORK = "WORK",
  MANAGER = "MANAGER",
}

export class ProductBOMData {
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  quantities: string;
  necessary: number = 0;
  amount: number = 0;
  company: string;
  constructor(
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    quantities: string,
    necessary: number = 0,
    amount: number = 0,
    company: string
  ) {
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.quantities = quantities;
    this.necessary = necessary;
    this.amount = amount;
    this.company = company;
  }
}

export const ProductList: ProductData[] = [
  new ProductData(
    1,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (360*120*122)",
    [
      new ProductBOMData(
        "SEMI ASSY",
        "VW42-00004A",
        "ASSY CELL PACK",
        "",
        "SET",
        7,
        100,
        "Verywords"
      ),
      new ProductBOMData(
        "ELECTTRIC",
        "VW42-00004A",
        "CELL PACK",
        "W-1058P-M50L",
        "EA",
        7,
        100,
        "Verywords"
      ),
      new ProductBOMData(
        "HARNESS",
        "VW42-00004A",
        "HARNESS_CELL_ASSY",
        "51163-05P, 4Φring teminal,1333#22AWG(흑,황 210mm), (...",
        "EA",
        7,
        100,
        "디에스테크"
      ),
    ]
  ),
  new ProductData(
    2,
    "SEMI ASSY",
    "VW90-00001A",
    "ASSY CELL PACK",
    "ASSY BATTERY SET (360*120*122)",
    [
      new ProductBOMData(
        "SEMI ASSY",
        "VW42-00004A",
        "ASSY CELL PACK",
        "",
        "SET",
        7,
        100,
        "Verywords"
      ),
    ]
  ),
  new ProductData(
    3,
    "SEMI ASSY",
    "VW90-00002A",
    "ASSY BOTTOM",
    "ASSY BATTERY SET (360*120*122)",
    [
      new ProductBOMData(
        "SEMI ASSY",
        "VW42-00004A",
        "ASSY CELL PACK",
        "",
        "SET",
        7,
        0,
        "Verywords"
      ),
    ]
  ),
  new ProductData(
    4,
    "SEMI ASSY",
    "VW90-00003A",
    "ASSY HANDLE",
    "ASSY BATTERY SET (360*120*122)",
  ),
];
