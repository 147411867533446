import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomerSupportDetailUiState } from "./CustomerSupportDetailUiState";
import { CustomerSupportDetailUiEvent } from "./CustomerSupportDetailUiEvent";
import { CustomerSupportDetailSideEffect } from "./CustomerSupportDetailSideEffect";
import CustomerSupportDetailRender from "./CustomerSupportDetailRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import ProgressBar from "../../../component/ProgressBar";
import { ApiService } from "../../../restAPI/ApiService";
import { toCSDetailModel } from "./model/CSDetailModel";
import { toCSReceipt } from "./model/CSReceipt";

const CustomerSupportDetailPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [uiState, setUiState] = useState(new CustomerSupportDetailUiState());
  const location = useLocation();
  const csDetailID = location.state as string;

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    console.log("csDetailID :", csDetailID);
    if (csDetailID) {
      getCSDetail(csDetailID).then((r) => {});
    }
  }, []);

  const uiEvent: CustomerSupportDetailUiEvent = {
    openReceipt: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isReceiptOpen: true,
        };
      });
    },
    closeReceipt: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isReceiptOpen: false,
        };
      });
    },
    openToast: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isToastOpen: true,
        };
      });
    },
    closeToast: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isToastOpen: false,
        };
      });
    },
    openDeleteDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isDeleteDialogOpen: true,
        };
      });
    },
    closeDeleteDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isDeleteDialogOpen: false,
        };
      });
    },
  };

  const sideEffect: CustomerSupportDetailSideEffect = {
    navigate: (target: string) => {
      navigate(target);
    },
    deleteCSPost: () => {
      deleteCSPost();
    },
  };

  async function getCSDetail(id: string) {
    const requestBody = {
      id: id,
    };
    try {
      const response = await ApiService.MobilePost(
        "/pw/ua/contents/cs/detail",
        requestBody
      );
      console.log("CS Detail Response", response);
      const data = response?.data?.body;
      if (data) {
        const csDetailData = toCSDetailModel(data);
        const csReceipt = toCSReceipt(data);

        setUiState((prev) => ({
          ...prev,
          csDetailData: csDetailData,
          csReceipt: csReceipt,
        }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function deleteCSPost() {
    const url = "/pw/ua/contents/cs/delete";
    const requestBody = {
      id: csDetailID,
    };
    setLoading(true);
    await ApiService.MobilePost(url, requestBody)
      .then((res) => {
        if (res != null) {
          if (res.data.result == true) {
            console.log("deleteCSPost success");
            window.history.back();
          }
        } else {
          console.log("deleteCSPost failed");
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <>
      <CustomerSupportDetailRender
        uiState={uiState}
        uiEvent={uiEvent}
        sideEffect={sideEffect}
      />
    </>
  );
};

export default CustomerSupportDetailPage;
