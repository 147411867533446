import { Box, LinearProgress } from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import CommonSearch from "../../../component/CommonSearch";
import { useEffect, useState } from "react";
import PaymentManagementTable from "./CouponHistoryTable";
import { HeadCell } from "../../../component/CommonTable";
import { ApiService } from "../../../restAPI/ApiService";
import { CommonSelect } from "../../../component/CommonSelect";
import { color } from "../../../theme/Color";

const CouponHistory = () => {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(1);
  const [type, setType] = useState("");
  const [valid, setValid] = useState("");
  // const [loading, setLoading] = useState(true);
  
  // 쿠폰 이력 필터링 조회
  useEffect(() => {
    console.log("검색 : ", searchContents);
    const selectTypeAll = type === "All" ? "" : type;

    // setLoading(true);
    ApiService.WebPost("/pw/aw/product/coupon/issuedFilter", {
      searchContents: searchContents,
      type: selectTypeAll,
      valid: valid,
      page: page + 1,
      size: rowsPerPage,
      uuid: localStorage.getItem("user_uuid"),
    }).then((res) => {
      setRows(res?.data.body);
      setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
      setDocCount(res?.data.docCount);
      console.log("uuid :", localStorage.getItem("user_uuid"));
    });
    // .finally(() => {
    //   setLoading(false);
    // });
  }, [rowsPerPage, page, searchContents, type, valid]);

  useEffect(() => {
    setPage(0);
  }, [searchContents]);

  // if (loading) {
  //   return (
  //     <Box sx={{ width: "100%" }}>
  //       <LinearProgress
  //         sx={{
  //           bgcolor: color.primary200,
  //           "& .MuiLinearProgress-bar": {
  //             bgcolor: color.primary500, // 진행 상태 색상
  //           },
  //         }}
  //       />
  //     </Box>
  //   );
  // }

  console.log(
    "데이터 : ",
    "현재페이지 : ",
    page,
    " 페이지당 데이터 :",
    rowsPerPage,
    "검색 내용 :",
    searchContents,
    "데이터: ",
    rows
  );

  return (
    <>
      <CommonSearch
        placeHolder={"Coupon Name, Use Date, Expired Date"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              minWidth: "84px",
              maxWidth: "155px",
              height: "42px",
            }}
            menuitems={["All", "NFT Character"]}
            selectValue={type}
            onChange={(event) => {
              setType(event.target.value as string);
              setPage(0);
            }}
            placeholder="Type"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{
              minWidth: "85px",
              height: "42px",
            }}
            selectValue={valid}
            menuitems={["All", "Yes", "No"]}
            onChange={(event) => {
              setValid(event.target.value as string);
              setPage(0);
            }}
            placeholder="Valid"
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <PaymentManagementTable
        headCells={couponHistoryHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rows={rows}
      />
    </>
  );
};

export default CouponHistory;

const couponHistoryHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "couponName",
    label: "Coupon Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "type",
    label: "Type",
  },
  // {
  //   disablePadding: false,
  //   align: "left",
  //   id: "vaild",
  //   label: "Vaild",
  // },
  {
    disablePadding: false,
    align: "left",
    id: "owner",
    label: "Owner",
  },
  {
    disablePadding: false,
    align: "left",
    id: "useDate",
    label: "Use Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "expiredDate",
    label: "Expired Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registrationDate",
    label: "Registration Date",
  },
];
