import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import {
  ICON_ANSWER,
  ICON_HIDE,
  ICON_QUESTION,
  ICON_SHOW,
  ICON_TRASH,
} from "../../../constants/imagePath";
import { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import CommonDialog from "../../../component/CommonDialog";
import { color } from "../../../theme/Color";
import { ApiService } from "../../../restAPI/ApiService";
import { useNavigate } from "react-router-dom";

interface FAQMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
    });
  }, []);

  const handleGetCountry = (country: string) => {
    for (let i = 0; i < countryList.length; ++i) {
      if (country == countryList[i]["code"]) {
        country = countryList[i]["label"];
      }
    }
    return country;
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOk = (id: any) => {
    ApiService.WebPost(`/pw/aw/contents/faq/delete`, {
      id: id,
    }).then((res) => {
      console.log(res?.data);
      if (res?.data.message == "delete complete") {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <TableRow
        onClick={() => {
          navigate(`/FAQMng/FAQDetails/${row.id}`);
        }}
      >
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box component="img" src={ICON_QUESTION} />
            <HorizontalSpacer width={4} />
            <Typography fontStyle={fontStyle.regularXS}>{row.title}</Typography>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(event) => {
                event.stopPropagation(); // 이벤트 전파를 막음
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {handleGetCountry(row.country)}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.category}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registrationDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          {row.showHide == true ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box component="img" src={ICON_SHOW} />
              <HorizontalSpacer width={4} />
              <Typography fontStyle={fontStyle.regularXS}>Show</Typography>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box component="img" src={ICON_HIDE} />
              <HorizontalSpacer width={4} />
              <Typography fontStyle={fontStyle.regularXS}>Hide</Typography>
            </Box>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0, px: 0 }} />
        <TableCell sx={{ py: 0, px: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  my: "16px",

                  alignItems: "flex-start",
                }}
              >
                <HorizontalSpacer width={16} />
                <Box component="img" src={ICON_ANSWER} />
                <HorizontalSpacer width={4} />
                <Typography
                  sx={{ whiteSpace: "pre-line" }}
                  fontStyle={fontStyle.regularXS}
                >
                  {row.content}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  my: "16px",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  <Box component="img" src={ICON_TRASH} />
                </IconButton>
                <CommonDialog
                  open={dialogOpen}
                  handleClose={handleClose}
                  handleOk={() => {
                    handleOk(row.id);
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.regularS}
                    color={color.gray800}
                  >
                    Would you like to delete this FAQ?
                  </Typography>
                </CommonDialog>
                <HorizontalSpacer width={68.09} />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const FAQMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: FAQMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },
    {
      id: "title",
      align: "left",
      disablePadding: false,
      label: "Title",
    },

    {
      id: "country",
      align: "left",
      disablePadding: false,
      label: "Country",
    },
    {
      id: "category",
      align: "left",
      disablePadding: false,
      label: "Category",
    },

    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Registration Date",
    },

    {
      id: "showHide",
      align: "left",
      disablePadding: false,
      label: "Show/Hide",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((row: any, index: any) => (
        <Row row={row} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default FAQMngTable;
