import { Box, Typography } from "@mui/material";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import { Text } from "../Text";

export interface PeriodButtonModel {
  title: string;
  isSelected: boolean;
  type: PeriodButtonType;
}

interface PeriodButtonProps {
  model: PeriodButtonModel;
  onClick: (type: PeriodButtonType) => void;
}

export function PeriodButton({ model, onClick }: PeriodButtonProps) {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: model.isSelected ? color.primary500 : color.gray400,
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "38px",
        padding: "8px 12px",
        boxSizing: "border-box",
      }}
      onClick={() => onClick(model.type)}
    >
      <Text
        sx={{
          fontStyle: fontStyle.bodyMr,
          color: model.isSelected ? color.primary500 : color.default,
        }}
        text={model.title}
      />
    </Box>
  );
}

export enum PeriodButtonType {
  ALL,
  ONE_MONTH,
  THREE_MONTH,
  CUSTOM,
}
