export enum MembershipStatus {
  ActiveWithinRenewal = 1, // 멤버십이 유효하고, 연장 가능한 상태
  ActiveOutsideRenewal = 2, // 멤버십이 유효하고, 연장 불가능한 상태
  Expired = 3, // 멤버십이 만료된 상태(멤버십 결제 필요)
}

interface MembershipInfo {
  id: string; // 멤버십 ID
  status: boolean; // 멤버십 상태
  voucherKm: number; // 바우처잔여주행거리
  totalAllowance: number; // 총 잔여주행거리
  productId: string; // 멤버십 상품 ID

  // status가 true일 때
  name?: string; // 멤버십 이름
  membershipAllowanceKm?: number; // 멤버십 잔여주행거리
  membershipKm?: number; // 본래 주행거리
  startDate?: number; // 시작일 (timestamp)
  endDate?: number; // 종료일 (timestamp)
  extendStartDate?: number; // 연장 시작일 (timestamp)
  extendEndDate?: number; // 연장 종료일 (timestamp)

  // status가 false일 때
  expiredName?: string; // 만료된 멤버십의 이름
  expiredKm?: number; // 만료된 멤버십의 제공주행거리
}

export default MembershipInfo;
