const EVMS = "EVMS" as const;
const AS = "AS" as const;

export const setEvms = () => ({ type: EVMS });
export const setAs = () => ({ type: AS });

type AuthAction = ReturnType<typeof setEvms> | ReturnType<typeof setAs>;
type AuthState = { adminType: string };

const initialState: AuthState = { adminType: EVMS };

function adminType(state: AuthState = initialState, action: AuthAction) {
  switch (action.type) {
    case EVMS:
      return { adminType: (state.adminType = EVMS) };
    case AS:
      return { adminType: (state.adminType = AS) };
    default:
      return state;
  }
}

export default adminType;
