import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";

interface InputFieldProps {
  type?: string;
  subContent?: string;
  content?: string;
}

const InputField = ({ type, subContent, content }: InputFieldProps) => {
  return (
    <>
      <Typography fontStyle={fontStyle.titleS}>
        {type}
        <Box sx={{ display: "flex" }}>
          {subContent && (
            <Typography
              fontStyle={fontStyle.bodyLr}
              color={"#C7C7C7"}
              sx={{ my: "8px", width: "70px", mr: "8px" }}
            >
              {subContent}
            </Typography>
          )}

          <Typography
            fontStyle={fontStyle.bodyLr}
            color={"#C7C7C7"}
            sx={{ my: "8px" }}
          >
            {content}
          </Typography>
        </Box>
      </Typography>
    </>
  );
};

export default InputField;
