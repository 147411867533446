import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import CommonButton from "../../component/CommonButton";
import { color } from "../../theme/Color";
import VerticalSpacer from "../../component/Spacer";
import Toolbar from "../../component/Toolbar";
import { fontStyle } from "../../theme/Style";
import { MobileProfileWithdrawalPageSideEffect } from "./MobileProfileWithdrawalPageSideEffect";
import { MobileProfileWithdrawalPageUiState } from "./MobileProfileWithdrawalPageUiState";
import { MobileProfileWithdrawalPageUiEvent } from "./MobileProfileWithdrawalPageUiEvent";
import { ICON_X_CIRCLE } from "../../constants/appImagePath";
import IconTextDialog, {
  IconTextDialogType,
} from "../../component/dialog/IconTextDialog";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { ApiService } from "../../restAPI/ApiService";
import { ApiKeycloak } from "../../restAPI/ApiKeycloak";
import { SERVER_CONFIG } from "../../constants/ServerConfig";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: "4px 8px",
  borderRadius: "10px",
  maxHeight: "38px",
  "&.Mui-selected": {
    backgroundColor: `${color.primary100} !important`, // 선택된 항목 배경색 변경
  },
  "&.Mui-selected:hover": {
    backgroundColor: `${color.primary100} !important`, // 선택된 항목 호버 시 배경색 변경
  },
}));

interface MobileProfileWithdrawalRenderProps {
  sideEffect: MobileProfileWithdrawalPageSideEffect;
  uiState: MobileProfileWithdrawalPageUiState;
  uiEvent: MobileProfileWithdrawalPageUiEvent;
}

const MobileProfileWithdrawalRender = ({
  sideEffect,
  uiState,
  uiEvent,
}: MobileProfileWithdrawalRenderProps) => {
  const handleReasonForWithdrawalChange = (event: SelectChangeEvent) => {
    uiEvent.HandleReasonForWithdrawalChange(event.target.value);
  };

  const handleReasonForWithdrawalDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    uiEvent.HandleReasonForWithdrawalDetailsChange(event.target.value);
  };

  const handleReasonForWithdrawalDetailsDelete = () => {
    uiEvent.HandleReasonForWithdrawalDetailsChange("");
  };
  useAndroidBackHandler(() => {
    if (window.Android) {
      window.history.back();
    }
  }, []);

  const handleDeleteUserFromKeycloak = (token: string, id: string) => {
    ApiKeycloak.handleDeleteUserFromKeycloak(token, id, () => {
      sideEffect.navigate("WithdrawalFinish");
    });
  };

  const handleGetUserIdFromKeycloak = (token: string) => {
    ApiKeycloak.handleGetUserIdFromKeycloak(token).then(function (id) {
      handleDeleteUserFromKeycloak(token, id);
    });
  };

  const handleGetTokenFromKeycloak = () => {
    try {
      ApiKeycloak.handleGetTokenFromKeycloak().then(function (token) {
        handleGetUserIdFromKeycloak(token);
      });
    } catch (e) {
      console.log("에러", e);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g220_deleteaccount")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        <VerticalSpacer height={30} />
        {/* 중간 요소 */}
        <Box
          sx={{
            width: "calc(100% - 40px)",
            // height:
            // flexGrow: 1,
          }}
        >
          <Box>
            <Typography fontStyle={fontStyle.headingS}>
              {LocalizedText("ua_g220_leavepople_title")}
            </Typography>
            <VerticalSpacer height={8} />
            <Typography fontStyle={fontStyle.bodyLr}>
              {LocalizedText("ua_g220_leavepople_message")}
            </Typography>
          </Box>
          <VerticalSpacer height={60} />
          <Box>
            <Typography fontStyle={fontStyle.titleL}>
              {LocalizedText("ua_g220_deletereason")}
            </Typography>
            <VerticalSpacer height={8.54} />
            <Select
              sx={{
                minWidth: "100%",
                height: "38px",
                bgcolor: "white",
                "& .MuiSelect-select": {
                  backgroundColor: "white", // 여기서 원하는 색깔로 변경
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${color.primary500}`, // 여기서 desiredColor를 원하는 색상으로 바꿔주세요.
                },
                "& .MuiMenuItem-root": {
                  "&.Mui-selected": {
                    backgroundColor: `${color.primary500}`, // 선택된 항목 배경색 변경
                    color: "red",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: `${color.primary500}`, // 선택된 항목 호버 시 배경색 변경
                    color: "red",
                  },
                },
                borderRadius: "6px",
              }}
              // error={!!countryError}
              placeholder="탈퇴 사유 선택"
              variant="outlined"
              value={uiState.reasonForWithdrawal}
              onChange={handleReasonForWithdrawalChange}
            >
              <StyledMenuItem value="none" disabled>
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray500}>
                  {LocalizedText("ua_g220_selectreasons")}
                </Typography>
              </StyledMenuItem>
              <StyledMenuItem value="PersonalInfo">
                {LocalizedText("ua_g220_privacyconcerns")}
              </StyledMenuItem>
              <StyledMenuItem value="Rejoin">
                {LocalizedText("ua_g220_changeid")}
              </StyledMenuItem>
              <StyledMenuItem value="LackService">
                {LocalizedText("ua_g220_insufficientservice")}
              </StyledMenuItem>
              <StyledMenuItem value="LackBenefits">
                {LocalizedText("ua_g220_insufficientbenefit")}
              </StyledMenuItem>
              <StyledMenuItem value="Others">
                {LocalizedText("ua_g220_others")}
              </StyledMenuItem>
            </Select>
          </Box>
          <VerticalSpacer height={8} />
          {uiState.reasonForWithdrawal == "Others" && (
            <>
              <TextField
                sx={{
                  width: "100%",

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: color.gray300, // 기본 테두리 색상
                    },
                    "&:hover fieldset": {
                      borderColor: color.primary500, // 호버 시 테두리 색상
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: color.primary500, // 포커스 시 테두리 색상
                    },
                    "& textarea": {
                      paddingRight: "20px", // 텍스트 영역의 내부 패딩을 조정
                    },
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "6px",
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ position: "absolute", right: 5, bottom: 100 }}
                    >
                      {uiState.reasonForWithdrawalDetails == "" ? null : (
                        <IconButton
                          onClick={handleReasonForWithdrawalDetailsDelete}
                        >
                          <Box component="img" src={ICON_X_CIRCLE} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                onChange={handleReasonForWithdrawalDetailsChange}
                value={uiState.reasonForWithdrawalDetails}
                inputProps={{ maxLength: 150 }} // 최대 문자 수 제한
                multiline
                rows={4}
              />
            </>
          )}
        </Box>
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_g220_deleteaccount_button")}
            textColor={color.gray700}
            buttonColor="white"
            onClick={() => {
              console.log("팝업 노출");
              uiEvent.ShowAlertDialog();
            }}
            sx={{
              mx: "20px",
              mb: "14px",
              border: 1,
              borderColor: `${color.gray700}`,
            }}
          />
        </Box>
        <IconTextDialog
          title={LocalizedText("ua_g220_alert_delete_title")}
          content={LocalizedText("ua_g220_alert_delete_message")}
          type={IconTextDialogType.WARNING}
          positiveText={LocalizedText("common_alert_confirm_button")}
          negativeText={LocalizedText("common_alert_cancel_button")}
          isOpen={uiState.isShowAlertDialog}
          onPositive={() => {
            uiEvent.HideAlertDialog();
            sideEffect.deleteAccount();
          }}
          onNegative={() => uiEvent.HideAlertDialog()}
          onClose={() => uiEvent.HideAlertDialog()}
        />
      </Box>
    </>
  );
};

export default MobileProfileWithdrawalRender;
