import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { useState } from "react";
import VerticalSpacer from "../../../component/Spacer";
// import { MoreProfileEditPageUiEvent } from "../MoreProfileEditPageUiEvent";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { LoginDetailUiEvent } from "../LoginDetailUiEvent";
import { color } from "../../../theme/Color";

interface EditAddressFieldProps {
  type?: string;
  title?: string;
  country?: string;
  cityCountyDistrict?: string;
  cityDetail?: string;
  countryError?: string;
  cityCountyDistrictError?: string;
  cityDetailError?: string;
  uiEvent?: LoginDetailUiEvent;
}

const EditAddressField = ({
  type,
  title,
  country,
  cityCountyDistrict,
  cityDetail,
  countryError,
  cityCountyDistrictError,
  cityDetailError,
  uiEvent,
}: // ,
EditAddressFieldProps) => {
  // 국가
  // 시/군/구
  const handleCountriesChange = (event: SelectChangeEvent) => {
    // setCountriess(event.target.value);
    uiEvent?.HandleCountryChange(event.target.value);
  };

  const handleCityCountyDistrictChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    uiEvent?.HandleCityCountyDistrictChange(event.target.value);
  };

  const handleCityDetailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    uiEvent?.HandleCityDetailChange(event.target.value);
  };

  const ua_g210_selectcountry = LocalizedText("ua_g210_selectcountry");
  const ua_g210_enterstatecity = LocalizedText("ua_g210_enterstatecity");
  const ua_g210_enteraddress = LocalizedText("ua_g210_enteraddress");

  return (
    <>
      <Typography
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <Typography fontStyle={fontStyle.titleS} color={color.error}>
          *
        </Typography>
        <Typography fontStyle={fontStyle.titleS}> {title}</Typography>
      </Typography>
      <Box>
        <Select
          sx={{
            minWidth: "100%",
            height: "50px",
            bgcolor: "white",
            "&:before": {},
            "&:hover:not(.Mui-disabled):before": {
              borderColor: color.primary500, // 호버 시 테두리 색상
            },
            "&.Mui-focused:after": {
              borderColor: color.primary500, // 포커스 시 테두리 색상
            },
          }}
          error={!!countryError}
          variant="standard"
          value={country}
          onChange={handleCountriesChange}
        >
          <MenuItem value="none">
            <Typography color={color.gray400}>
              {LocalizedText("ua_b123_selectcountry")}
            </Typography>
          </MenuItem>
          <MenuItem value="United States of America">United States of America</MenuItem>
          <MenuItem value="Republic of Korea">Republic of Korea</MenuItem>
          <MenuItem value="Cambodia">Cambodia</MenuItem>
        </Select>
        {countryError === "error" && (
          <>
            <Typography
              fontStyle={fontStyle.bodySr}
              color={"#FF4C4C"}
              sx={{ mt: "8.75px" }}
            >
              {ua_g210_selectcountry}
            </Typography>
          </>
        )}
        <VerticalSpacer height={14} />
        <TextField
          variant="standard"
          size="medium"
          placeholder={LocalizedText("ua_b123_citystate")}
          value={cityCountyDistrict}
          error={!!cityCountyDistrictError}
          onChange={handleCityCountyDistrictChange}
          inputProps={{
            style: {
              height: "50px",
              padding: "0px 0px",
            },
          }}
          sx={{
            width: "100%",
            flexGrow: 1,
            "& .MuiInput-root": {
              "&:before": {
                // borderBottomColor: color.gray300, // 기본 테두리 색상
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: color.primary500, // 호버 시 테두리 색상
              },
              "&.Mui-focused:after": {
                borderBottomColor: color.primary500, // 포커스 시 테두리 색상
              },
              "&:after": {
                borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
              },
            },
          }}
        />

        {cityCountyDistrictError === "error" && (
          <>
            <Typography
              fontStyle={fontStyle.bodySr}
              color={"#FF4C4C"}
              sx={{ mt: "8.75px" }}
            >
              {ua_g210_enterstatecity}
            </Typography>
          </>
        )}
        <VerticalSpacer height={14} />
        <TextField
          variant="standard"
          size="medium"
          placeholder={LocalizedText("ua_b123_enterdetail")}
          value={cityDetail}
          error={!!cityDetailError}
          onChange={handleCityDetailChange}
          inputProps={{
            style: {
              height: "50px",
              padding: "0px 0px",
            },
          }}
          sx={{
            width: "100%",
            flexGrow: 1,
            "& .MuiInput-root": {
              "&:before": {
                // borderBottomColor: color.gray300, // 기본 테두리 색상
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: color.primary500, // 호버 시 테두리 색상
              },
              "&.Mui-focused:after": {
                borderBottomColor: color.primary500, // 포커스 시 테두리 색상
              },
              "&:after": {
                borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
              },
            },
          }}
        />

        {cityDetailError === "error" && (
          <>
            <Typography
              fontStyle={fontStyle.bodySr}
              color={"#FF4C4C"}
              sx={{ mt: "8.75px" }}
            >
              {ua_g210_enteraddress}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default EditAddressField;
