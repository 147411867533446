import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  linearProgressClasses,
  LinearProgress,
  styled,
  Button,
} from "@mui/material";
import {
  AxisConfig,
  BarChart,
  ChartsXAxisProps,
  axisClasses,
  barElementClasses,
  barLabelClasses,
  chartsGridClasses,
} from "@mui/x-charts";
import { ReactNode, useEffect } from "react";

interface CustomBandAxisConfig
  extends Omit<AxisConfig<"band", any, ChartsXAxisProps>, "id"> {
  position: "top" | "bottom" | undefined;
}

const xAxisConfig: CustomBandAxisConfig = {
  categoryGapRatio: 0.8,
  data: [
    "Jan",
    "Fev",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  position: "bottom", // Adjust position as per your requirement
  scaleType: "band",
};

const BarChartCard = () => {
  let barHeight = 0;
  let labelHeight = 0;
  useEffect(() => {
    console.log("barHeight : ", barHeight);
  }, [barHeight]);
  return (
    <Card
      sx={{
        borderRadius: 3,
        width: "100%",
        height: 450,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CardContent sx={{ width: "95%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CardHeader
            title="Reduction History"
            titleTypographyProps={{ fontWeight: "bold", fontSize: 19, ml: -2 }}
          />
          <Box>
            <Button
              sx={{
                height: 25,
                backgroundColor: "white",
                border: 1,
                borderRadius: 1.5,
                color: "#5D38E5",
                minWidth: 70,
              }}
            >
              <Typography fontSize={12} sx={{ textTransform: "none" }}>
                Year
              </Typography>
            </Button>
            <Button
              sx={{
                ml: 1,
                height: 25,
                backgroundColor: "white",
                border: 1,
                borderRadius: 1.5,
                color: "#5D38E5",
                minWidth: 70,
              }}
            >
              <Typography fontSize={12} sx={{ textTransform: "none" }}>
                Month
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            border: 1,
            borderRadius: 3,
            borderColor: "#C4C4C4",
            mt: 1,
          }}
        >
          <BarChart
            // barLabel={(item, context) => {
            //   barHeight = context.bar.height;
            //   // console.log(barHeight);

            //   return item.value?.toString();
            // }}
            sx={{
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  strokeWidth: 0, // y축 눈금과 선 숨기기
                },
              },
              [`.${axisClasses.directionY}`]: {
                [`.${axisClasses.tickLabel}`]: {
                  visibility: "hidden",
                },
              },
            }}
            xAxis={[xAxisConfig]}
            series={[
              {
                data: [1550, 7000, 3000, 15000, 20000],
              },
            ]}
            slotProps={{
              bar: {
                clipPath: `inset(0px round 15px 15px 0px 0px)`,
                style: {
                  transform: "translate3d(0px, 0px, 0px)",
                },
              },
            }}
            height={340}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === "light" ? "#514689" : "#308fe8",
  },
}));

const UserCarbonReductionGrid = () => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: 0 }}>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="NFT"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box sx={{ display: "flex" }}>
              <Grid item lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    border: 1,
                    borderColor: "#B0AFB3",
                    ml: 2,
                    height: 330,
                    borderRadius: 3,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>NFT 캐릭터 이미지</Typography>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <Box
                  sx={{
                    height: 330,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 1,
                      borderColor: "#B0AFB3",
                      ml: 3,
                      mr: 2,
                      borderRadius: 3,
                      height: 100,
                      backgroundColor: "#E9E9EA",
                    }}
                  >
                    <Typography sx={{ fontSize: 20, color: "#B0AFB3" }}>
                      Pople 아메리카노 1잔 무료 쿠폰 (사용 완료)
                    </Typography>
                    <Typography sx={{ fontSize: 15, color: "#B0AFB3" }}>
                      2024-06-04 12:30:23
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 1,
                      borderColor: "#B0AFB3",
                      ml: 3,
                      mr: 2,
                      borderRadius: 3,
                      height: 100,
                    }}
                  >
                    <Typography sx={{ fontSize: 20, color: "#B0AFB3" }}>
                      $5 할인 쿠폰
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 1,
                      borderColor: "#B0AFB3",
                      ml: 3,
                      mr: 2,
                      borderRadius: 3,
                      height: 100,
                    }}
                  >
                    <Typography sx={{ fontSize: 20, color: "#B0AFB3" }}>
                      제휴 업체 10% 할인 쿠폰
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Grid item lg={4}>
                <Typography sx={{ ml: 2, mt: 2 }}>aaaa</Typography>
              </Grid>
              <Grid item lg={8}>
                <Box sx={{ ml: 3, mt: 2, mr: 2 }}>
                  <Typography sx={{ width: "100%" }}>Email ID</Typography>
                  <TextField
                    sx={{
                      width: "100%",
                      mt: 2,
                      // // "& .MuiOutlinedInput-root": {
                      // //   "& fieldset": {
                      // //     borderColor: "red", // 기본 테두리 색상
                      // //   },
                      // //   "&:hover fieldset": {
                      // //     borderColor: "#E9E9EA", // 호버 시 테두리 색상
                      // //   },
                      // //   "&.Mui-focused fieldset": {
                      // //     borderColor: "#E9E9EA", // 포커스 시 테두리 색상
                      // //   },
                      // // },
                      // "& .MuiOutlinedInput-root.Mui-disabled": {
                      //   "& .MuiOutlinedInput-notchedOutline": {
                      //     borderColor: "#E9E9EA", // disabled 상태일 때 테두리 색상
                      //   },
                      // },
                    }}
                    size="small"
                    disabled
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        backgroundColor: "#E9E9EA",
                      },
                    }}
                    value={"qkrwodbs43@gmail.com"}
                  />
                </Box>
              </Grid>
            </Box>
            <Box sx={{ m: 2, mt: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  fontSize={13}
                  sx={{ fontWeight: "bold", color: "grey" }}
                >
                  Carbon Reduction
                </Typography>
                <Typography
                  fontSize={13}
                  sx={{ fontWeight: "bold", color: "grey" }}
                >
                  148 / 296
                </Typography>
              </Box>
              <BorderLinearProgress
                variant="determinate"
                value={50}
                sx={{ mt: 1 }}
              />
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ mt: 4 }}>
          <BarChartCard />
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserCarbonReductionGrid;
