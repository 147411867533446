import { Box, Divider } from "@mui/material";
import { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

export enum RegionAndServiceManagementTabType {
  REGION_MANAGEMENT,
  SERVICE_MANAGEMENT,
  REGION_HISTORY,
  SERVICE_HISTORY,
}

export interface RegionAndServiceManagementTabProps {
  currentTab: RegionAndServiceManagementTabType;
  changeTab: (tab: RegionAndServiceManagementTabType) => void;
}

export function RegionAndServiceManagementTab({
  currentTab,
  changeTab,
}: RegionAndServiceManagementTabProps) {
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={12} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == RegionAndServiceManagementTabType.REGION_MANAGEMENT
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(RegionAndServiceManagementTabType.REGION_MANAGEMENT);
        }}
      >
        Area
      </Box>
      <HorizontalSpacer width={16} />
      <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == RegionAndServiceManagementTabType.SERVICE_MANAGEMENT
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(RegionAndServiceManagementTabType.SERVICE_MANAGEMENT);
        }}
      >
        Service
      </Box>
      <HorizontalSpacer width={16} />
      {/* <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == RegionAndServiceManagementTabType.REGION_HISTORY
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(RegionAndServiceManagementTabType.REGION_HISTORY);
        }}
      >
        Area History
      </Box>
      <HorizontalSpacer width={16} />
      <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == RegionAndServiceManagementTabType.SERVICE_HISTORY
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(RegionAndServiceManagementTabType.SERVICE_HISTORY);
        }}
      >
        Service History
      </Box> */}
    </Box>
  );
}
