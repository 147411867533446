import { Box, Typography } from "@mui/material";
import React from "react";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { formatTimeMilliseconds } from "../../../../utils/DateUtil";
import { getLanguageCode } from "../../../../customHook/useLocale";

interface AdminAnswerCardProps {
  answerer: string;
  answerDate: number;
  answerContent: string;
  answerImageUrls: string[];
}

export default function AdminAnswerCard({
  answerer,
  answerDate,
  answerContent,
  answerImageUrls,
}: AdminAnswerCardProps) {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-fraction-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-bullet-horizontal-gap"?: string;
    height?: string;
  };

  // TODO indicator bullet color와 위치 변경
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "#fff",
    "--swiper-pagination-top": "90%",
    width: "100%",
    height: "100%", // Swiper가 Box의 크기를 채우도록 설정
  };
  const language = getLanguageCode();

  return (
    <Box
      sx={{
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: color.primary50,
      }}
    >
      <Box
        sx={{
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontStyle: fontStyle.titleL,
              color: color.primary500,
            }}
          >
            {answerer}
          </Typography>
          <HorizontalSpacer width={8} />
          <Typography
            sx={{
              fontStyle: fontStyle.bodySr,
              color: color.gray600,
            }}
          >
            {formatTimeMilliseconds(language, answerDate)}
          </Typography>
        </Box>
        <VerticalSpacer height={8} />
        <Typography
          sx={{
            fontStyle: fontStyle.bodyLr,
            color: color.default,
          }}
        >
          {answerContent}
        </Typography>

        {answerImageUrls && answerImageUrls.length > 0 && (
          <VerticalSpacer height={10} />
        )}
        {answerImageUrls && answerImageUrls.length > 0 && (
          <Box
            sx={{
              borderRadius: "10px",
              width: "100%",
              aspectRatio: "1/1",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <Swiper
              pagination={{
                type: "bullets",
                clickable: true,
              }}
              style={swiperStyles}
              modules={[Pagination]}
            >
              {answerImageUrls.map((imageUrl, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img
                      src={imageUrl}
                      alt="Example Icon"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // 이미지가 박스 크기에 맞게 조정
                        objectPosition: "center", // 이미지가 박스 내 중앙에 위치
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        )}
      </Box>
    </Box>
  );
}
