import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CommonList from "../../component/CommonList";
import FAQCreateGrid from "./section/FAQCreateGrid";

const FAQCreatePage = () => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const navigate = useNavigate();

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
          textTransform: "none"
        }}
        onClick={() => {
          setCreateDialogOpen(true);
        }}
      >
        Create
      </Button>
    );
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/FAQMng");
    window.location.reload();
  };
  return (
    <CommonList
      title="FAQ Registration"
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            width: 450,
            height: 170,
            // mt: -1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: 15,
              color: "black",
            }}
          >
            Do you want to stop editing and move? <br />
            The current content will not be saved.
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mt: 1.5,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                textTransform: "none",
                height: 40,
                //   backgroundColor: "#5D38E5",
                color: "#5D38E5",
                borderRadius: 5,
                border: 1,
                borderColor: "#5D38E5",
                width: 95,
                ":hover": {
                  backgroundColor: "#E9E9E9",
                },
              }}
            >
              Cancle
            </Button>
            <Button
              onClick={handleOk}
              sx={{
                textTransform: "none",
                height: 40,
                backgroundColor: "#5D38E5",
                color: "white",
                borderRadius: 5,
                width: 75,
                ml: 2,
                ":hover": {
                  backgroundColor: "#A38BFC",
                },
              }}
              autoFocus
            >
              OK
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <FAQCreateGrid
        createDialogOpen={createDialogOpen}
        setCreateDialogOpen={setCreateDialogOpen}
      />
    </CommonList>
  );
};

export default FAQCreatePage;
