import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import {
  RequestHistoryData,
  RequestHistoryList,
} from "./model/RequestHistoryData";
import { RequestHistoryTableRow } from "./RequestHistoryTableRow";

interface RequestHistoryTableProps {
  headCells: HeadCell[];
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pageCount: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  docCount: number;
}

export function RequestHistoryTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: RequestHistoryTableProps) {
  const { order, orderBy, handleChangePage, handleRequestSort, visibleRows } =
    useVisibleRows(RequestHistoryList, rowsPerPage || 10, setPage, page || 0);

  const pagedata: RequestHistoryData[] = visibleRows
    .slice(
      (page || 0) * (rowsPerPage || 10),
      ((page || 0) + 1) * (rowsPerPage || 10)
    )
    .map((row) => {
      return new RequestHistoryData(
        Number(row.no),
        row.requestBranch as string,
        row.branch as string,
        row.requester as string,
        row.updater as string,
        row.status as string,
        Number(row.disiredPickupDate),
        Number(row.registrationDate)
      );
    });

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      rowsPerPage={rowsPerPage}
      pageCount={pageCount}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {pagedata.map((data: RequestHistoryData) => (
        <RequestHistoryTableRow row={data} key={data.no} />
      ))}
    </CommonTable>
  );
}
