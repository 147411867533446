import { Box, Typography } from "@mui/material";
import { ICON_RIGHT_ARROW } from "../../../constants/appImagePath";
import { fontStyle } from "../../../theme/Style";

interface MorePageMenuPartProps {
  iconType?: any;
  content?: string;
  showDiolog?: any;
  onClick?: () => void;
}

const MorePageMenuPart = ({
  iconType,
  content,
  showDiolog,
  onClick,
}: MorePageMenuPartProps) => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            mx: "16px",
            my: "8.5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={onClick}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              component="img"
              src={iconType}
              sx={{ width: "24px", height: "24px" }}
            />
            <Typography fontStyle={fontStyle.titleL} sx={{ mx: "20px" }}>
              {content}
            </Typography>
          </Box>
          <Box
            component="img"
            src={ICON_RIGHT_ARROW}
            sx={{ width: "24px", height: "24px" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default MorePageMenuPart;
