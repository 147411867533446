import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import PushNotiCreateModal from "./section/PushNotiCreateModal";
import PushNotiSetHisDetails from "./section/PushNotiSetHisDetails";
import { HorizontalSpacer } from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";

const PushNotiMngListPage = () => {
  const [tabVelue, setTabVelue] = useState("mng");

  const [createModal, setCreateModal] = useState(false);
  const handleCreateModalClose = () => {
    setCreateModal(false);
  };

  const [setHisModal, setSetHisModal] = useState(false);

  const handleSetHisModalClose = () => {
    setSetHisModal(false);
  };

  // const toggleSchedule = (id: string) => {
  //   setMngRows((prevMngRows) =>
  //     prevMngRows.map((row) =>
  //       row.id === id ? { ...row, schedule: !row.schedule } : row
  //     )
  //   );
  // };

  const [schedule, setSchedule] = useState("10");
  const [usrStatus, setUsrStatus] = useState("10");

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          setCreateModal(true);
        }}
      >
        <AddIcon fontSize="small" />
        등록
      </Button>
    );
  };

  const TabButton = () => {
    return (
      <Box sx={{ display: "flex", height: "24px" }}>
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "mng" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("mng");
          }}
        >
          관리
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "settingHis" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("settingHis");
          }}
        >
          설정 이력
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color:
              tabVelue == "executionHis" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("executionHis");
          }}
        >
          실행 이력
        </Box>
        <HorizontalSpacer width={16} />
      </Box>
    );
  };

  return (
    <CommonList
      title="푸시 알림 관리"
      headerButton={tabVelue == "mng" && HeaderButton()}
      tabButton={TabButton()}
    >
      {tabVelue == "mng" ? (
        <CommonSearch
          placeHolder={"푸시 이름, 제목, 내용, 작성자, 실행 일시 검색"}
        >
          {/* <Select
            value={showHide}
            onChange={handleShowHideChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Show/Hide`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={""}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              all
            </MenuItem>
            <MenuItem
              value={"show"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              show
            </MenuItem>
            <MenuItem
              value={"hide"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              hide
            </MenuItem>
          </Select> */}
          {null}
        </CommonSearch>
      ) : tabVelue == "settingHis" ? (
        <CommonSearch placeHolder={"검색"}>{null}</CommonSearch>
      ) : tabVelue == "executionHis" ? (
        <CommonSearch placeHolder={"검색"}>{null}</CommonSearch>
      ) : null}

      <Box sx={{ mt: 3 }}>
        {/* {tabVelue == "mng" && (
          <PushNotiMngTable
            rowsPerPage={rowsPerPage}
            mngRows={mngRows}
            toggleSchedule={toggleSchedule}
          />
        )}
        {tabVelue == "settingHis" && (
          <PushNotiSetHisTable
            rowsPerPage={rowsPerPage}
            mngRows={settingRows}
            handleSetHisTableRowClick={handleSetHisTableRowClick}
          />
        )} */}
        {tabVelue == "executionHis" && null}
      </Box>
      <PushNotiCreateModal
        createModal={createModal}
        handleClose={handleCreateModalClose}
      />
      <PushNotiSetHisDetails
        setHisModal={setHisModal}
        handleClose={handleSetHisModalClose}
      />
    </CommonList>
  );
};

export default PushNotiMngListPage;
