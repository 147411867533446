import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import {
  PaymentManagementTab,
  PaymentManagementTabType,
} from "./PaymentManagementTab";
import { useLocation, useNavigate } from "react-router-dom";
import CouponHistory from "./couponhistory/CouponHistory";
import { MembershipVoucher } from "./membershipvoucher/MembershipVoucher";
import { CommonWebHeaderButton } from "../../component/CommonWebHeaderButton";
import { ICON_QRSCAN } from "../../constants/imagePath";

const PaymentManagement = () => {
  const [currentTab, setCurrentTab] = useState<PaymentManagementTabType>(
    PaymentManagementTabType.COUPONHISTORY
  );

  const navigate = useNavigate();
  const location = useLocation();

  const onClick= () => {
    navigate("/couponvalidation")
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");

    switch (tabParam) {
      case PaymentManagementTabType.COUPONHISTORY:
        setCurrentTab(PaymentManagementTabType.COUPONHISTORY);
        break;
      case PaymentManagementTabType.MEMBERSHIP_VOUCHER:
        setCurrentTab(PaymentManagementTabType.MEMBERSHIP_VOUCHER);
        break;
    }
  }, [location.search]);
  const handleChangeTab = (tab: PaymentManagementTabType) => {
    setCurrentTab(tab);
    navigate(`/paymentManagement?tab=${tab}`);
  };

  return (
    <CommonList
      title="Payment"
      tabButton={
        <PaymentManagementTab
          currentTab={currentTab}
          changeTab={handleChangeTab}
        />
      }
      headerButton={
        currentTab == PaymentManagementTabType.COUPONHISTORY && (
          <CommonWebHeaderButton
            icon={<img src={ICON_QRSCAN}></img>}
            buttonContent="Verify Coupon"
            onClick={onClick}
          />
        )
      }
    >
      {currentTab == PaymentManagementTabType.COUPONHISTORY && (
        <CouponHistory />
      )}
      {currentTab == PaymentManagementTabType.MEMBERSHIP_VOUCHER && (
        <MembershipVoucher />
      )}
    </CommonList>
  );
};

export default PaymentManagement;
