import { Box, Typography } from "@mui/material";
import CommonButton from "../../../component/CommonButton";
import {
  ICON_SCOOTER,
  IMAGE_PONYA_HELLO_2_SHADOW,
} from "../../../constants/appImagePath";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { useNavigate } from "react-router-dom";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import BeforeSelectNFTScreen from "./section/BeforeSelectNFTScreen";
import { useState } from "react";
import { HasScooterUiState } from "./HasScooterUiState";
import { HasScooterUiEvent } from "./HasScooterUiEvent";
import PickNFTCharacter from "./section/PickNFTCharacter";
import HasNFTPage from "./section/hasNFTPage/HasNFTPage";
import { MoreCarbonReductionNFTUiState } from "../MoreCarbonReductionNFTUiState";
import { MoreCarbonReductionNFTUiEvent } from "../MoreCarbonReductionNFTUiEvent";

interface PickNFTPageProps {
  parentUiState: MoreCarbonReductionNFTUiState;
  parentUiEvent: MoreCarbonReductionNFTUiEvent;
  lastPath: string;
}

const PickNFTPage = ({
  parentUiState,
  parentUiEvent,
  lastPath,
}: PickNFTPageProps) => {
  const [uiState, setUiState] = useState(new HasScooterUiState());
  const uiEvent: HasScooterUiEvent = {
    SetwWhatScreen: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          whatScreen: event,
        };
      });
    },
  };

  console.log("lastPath is : ", lastPath);
  return (
    <>
      {uiState.whatScreen == "BeforeSelectNFTScreen" && (
        <BeforeSelectNFTScreen uiState={uiState} uiEvent={uiEvent} />
      )}
      {uiState.whatScreen == "PickNFTScreen" && (
        <PickNFTCharacter
          uiState={uiState}
          uiEvent={uiEvent}
          lastPath={lastPath}
        />
      )}
      {uiState.whatScreen == "HasNFTChar" && lastPath == "MorePage" && (
        <HasNFTPage
          parentUiState={parentUiState}
          parentUiEvent={parentUiEvent}
        />
      )}
    </>
  );
};

export default PickNFTPage;
