import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import InquiryRegistrationRender from "./InquiryRegistrationRender";
import { ApiService } from "../../../restAPI/ApiService";
import { Category } from "../../MobileSupport/faq/list/model/Category";
import React, { useEffect, useState } from "react";
import { InquiryRegistrationUiState } from "./model/InquiryRegistrationUiState";
import { InquiryRegistrationUiEvent } from "./model/InquiryRegistrationUiEvent";
import { InquiryRegistrationSideEffect } from "./model/InquiryRegistrationSideEffect";
import axios from "axios";
import { getCountryCode } from "../../../customHook/useLocale";
import { InquiryResponse } from "./model/InquiryResponse";

export default function InquiryRegistrationPage() {
  const [progress, setProgress] = useState(false);
  const [uiState, setUiState] = useState<InquiryRegistrationUiState>({
    categoryItems: [],
    categoryItemsError: false,
    inputTitle: "",
    inputTitleError: false,
    inputContent: "",
    titleMaxLength: 45,
    contentMaxLength: 1000,
    uploadedImageUrls: [],
  });

  useAndroidBackHandler(() => {
    if (!progress) window.history.back();
  }, [progress]);
  useEffect(() => {
    getCategoryList().then((r) => {});
  }, []);

  const uiEvent: InquiryRegistrationUiEvent = {
    onClearTitleText(): void {
      setUiState((prev) => {
        return {
          ...prev,
          inputTitle: "",
        };
      });
    },
    onChangeTitleTextValue(input: string): void {
      setUiState((prev) => {
        return {
          ...prev,
          inputTitle:
            input.length <= uiState.titleMaxLength
              ? input
              : input.substring(0, uiState.titleMaxLength),
        };
      });
    },

    onClearContentText(): void {
      setUiState((prev) => {
        return {
          ...prev,
          inputContent: "",
        };
      });
    },
    onChangeContentTextValue(input: string): void {
      setUiState((prev) => {
        return {
          ...prev,
          inputContent:
            input.length <= uiState.contentMaxLength
              ? input
              : input.substring(0, uiState.contentMaxLength),
        };
      });
    },
    addImageUrl: (url: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          uploadedImageUrls: [...prev.uploadedImageUrls, url],
        };
      });
    },
    removeImageUrl: (index: number) => {
      setUiState((prev) => {
        return {
          ...prev,
          uploadedImageUrls: prev.uploadedImageUrls.filter(
            (_, i) => i !== index,
          ),
        };
      });
    },
    onClickSubmitButton: () => {
      let hasError = false;
      const newUiState = { ...uiState };
      const selectedCategory = uiState.categoryItems.find(
        (category) => category.isSelected,
      );

      if (!selectedCategory) {
        hasError = true;
        newUiState.categoryItemsError = true;
      } else {
        newUiState.categoryItemsError = false;
      }

      if (uiState.inputTitle.trim() === "") {
        hasError = true;
        newUiState.inputTitleError = true;
      } else {
        newUiState.inputTitleError = false;
      }

      setUiState(newUiState);

      if (hasError) {
        return;
      } else {
        sideEffect.postInquiry();
      }
    },
    onSelectCategory: (category: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          categoryItems: prev.categoryItems.map((it) => {
            return {
              ...it,
              isSelected: it.label === category,
            };
          }),
        };
      });
    },
  };

  const sideEffect: InquiryRegistrationSideEffect = {
    openCamera: function (): void {
      if (window.Android) {
        window.Android.openCameraEvent();
      }
      if (window.webkit) {
        window.webkit.messageHandlers.openCamera.postMessage("openCamera");
      }
    },
    postInquiry: () => {
      setProgress(true);
      postInquiry(
        uiState.inputTitle,
        uiState.categoryItems.find((it) => it.isSelected)?.label ?? "",
        uiState.inputContent,
      )
        .then((res) => {
          if (res.result) {
            window.history.back();
          } else {
            // TODO 실패 처리
            console.log("failed to post inquiry");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setProgress(false);
        });
    },
  };

  async function getCategoryList() {
    const url = "/pw/ua/contents/personal/getCategory";
    try {
      await ApiService.MobilePost(url).then((res) => {
        let categories = res?.data.body;
        let categoryList: Category[] = [];
        if (categories && categories.lenght !== 0) {
          for (let i = 0; i < categories.length; i++) {
            categoryList.push({
              label: categories[i],
              isSelected: false,
            });
          }
        }
        setUiState((prev) => {
          return {
            ...prev,
            categoryItems: categoryList,
          };
        });
      });
    } catch (e) {
      console.log("failed to get category list", e);
    }
  }

  async function postInquiry(
    title: string,
    category: string,
    content: string,
  ): Promise<InquiryResponse> {
    const url = "/mobile/pw/ua/contents/personal/create";
    const IMAGE_URL_KEY = "imageUrls";
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const uuid = localStorage.getItem("uuid") ?? "";

    const body = {
      country: getCountryCode(),
      title: title,
      category: category,
      uuid: uuid,
      content: content,
    };

    try {
      const formData = new FormData();
      formData.append(
        "body",
        new Blob([JSON.stringify(body)], { type: "application/json" }),
      );

      if (uiState.uploadedImageUrls.length === 0) {
        formData.append(IMAGE_URL_KEY, "");
      } else {
        uiState.uploadedImageUrls.forEach((image, index) => {
          const imageFileExtension = "jpeg";
          const imageFileName = `inquiry_image_${index}_${uuid}.${imageFileExtension}`;
          const base64Data = image.replace(/^data:image\/\w+;base64,/, "");
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: `image/${imageFileExtension}`,
          });

          formData.append(IMAGE_URL_KEY, blob, imageFileName);
        });
      }

      const request = await axios.post(url, formData, { headers });
      return request.data as InquiryResponse;
    } catch (e) {
      throw e;
    }
  }

  return (
    <InquiryRegistrationRender
      uiState={uiState}
      uiEvent={uiEvent}
      sideEffect={sideEffect}
      progress={progress}
    />
  );
}
