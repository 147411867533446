import React, { useState } from "react";
import { SideBarSideEffect } from "./model/SideBarSideEffect";
import { SideBarUiEvent } from "./model/SideBarUiEvent";
import { SideBarUiState } from "./model/SideBarUiState";
import SideBarRender from "./SideBarRender";

export default function SideBar() {
  const [uiState, setUiState] = useState<SideBarUiState>({
    statistics: [],
    userManagement: [],
    content: [],
    merchandise: [],
    productionMaterial: [],
    operations: [],
    carbonReduction: [],
    blockchain: [],
    system: [],
  });

  const uiEvent: SideBarUiEvent = {
    onChangeStatistics: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          statistics: value || [],
        }
      });
    },
    onChangeUserManagement: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          userManagement: value || [],
        }
      });
    },
    onChangeContent: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          content: value || [],
        }
      });
    },
    onChangeMerchandise: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          merchandise: value || [],
        }
      });
    },
    onChangeProductionMaterial: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          productionMaterial: value || [],
        }
      });
    },
    onChangeOperations: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          operations: value || [],
        }
      });
    },
    onChangeCarbonReduction: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          carbonReduction: value || [],
        }
      });
    },
    onChangeBlockchain: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          blockchain: value || [],
        }
      });
    },
    onChangeSystem: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          system: value || [],
        }
      });
    },
    onGetStatistics: (): string[] => {
      return uiState.statistics;
    },
    onGetUserManagement: (): string[] => {
      return uiState.userManagement;
    },
    onGetContent: (): string[] => {
      return uiState.content;
    },
    onGetMerchandise: (): string[] => {
      return uiState.merchandise;
    },
    onGetProductionMaterial: (): string[] => {
      return uiState.productionMaterial;
    },
    onGetOperations: (): string[] => {
      return uiState.operations;
    },
    onGetCarbonReduction: (): string[] => {
      return uiState.carbonReduction;
    },
    onGetBlockchain: (): string[] => {
      return uiState.blockchain;
    },
    onGetSystem: (): string[] => {
      return uiState.system;
    },
  }

  const sideEffect: SideBarSideEffect = {
    handelGetList: (key): string[] => {
      let list:string[] = [];
      if("statistics" === key){
        list = uiEvent.onGetStatistics();
      }else if("userManagement" === key){
        list = uiEvent.onGetUserManagement();
      }else if("content" === key){
        list = uiEvent.onGetContent();
      }else if("merchandise" === key){
        list = uiEvent.onGetMerchandise();
      }else if("productionMaterial" === key){
        list = uiEvent.onGetProductionMaterial();
      }else if("operations" === key){
        list = uiEvent.onGetOperations();
      }else if("carbonReduction" === key){
        list = uiEvent.onGetCarbonReduction();
      }else if("blockchain" === key){
        list = uiEvent.onGetBlockchain();
      }else if("system" === key){
        list = uiEvent.onGetSystem();
      }
      return list;
    },
    handelGetFirstPage: (list): any => {
      const menu = {collapseTitle: "", itemTitle: ""};

      if(0 < list["statistics"]?.length){
        menu.collapseTitle = "statistics";
        menu.itemTitle = list["statistics"][0];
      }else if(0 < list["userManagement"]?.length){
        menu.collapseTitle = "userManagement";
        menu.itemTitle = list["userManagement"][0];
      }else if(0 < list["content"]?.length){
        menu.collapseTitle = "content";
        menu.itemTitle = list["content"][0];
      }else if(0 < list["merchandise"]?.length){
        menu.collapseTitle = "merchandise";
        menu.itemTitle = list["merchandise"][0];
      }else if(0 < list["productionMaterial"]?.length){
        menu.collapseTitle = "productionMaterial";
        menu.itemTitle = list["productionMaterial"][0];
      }else if(0 < list["operations"]?.length){
        menu.collapseTitle = "operations";
        menu.itemTitle = list["operations"][0];
      }else if(0 < list["carbonReduction"]?.length){
        menu.collapseTitle = "carbonReduction";
        menu.itemTitle = list["carbonReduction"][0];
      }else if(0 < list["blockchain"]?.length){
        menu.collapseTitle = "blockchain";
        menu.itemTitle = list["blockchain"][0];
      }else if(0 < list["system"]?.length){
        menu.collapseTitle = "system";
        menu.itemTitle = list["system"][0];
      }
      return menu;
    },
  }

  return (
    <SideBarRender
      uiState={uiState}
      uiEvent={uiEvent}
      sideEffect={sideEffect}
    />
  );
}
