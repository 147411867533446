import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import Toolbar from "../../component/Toolbar";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import VerticalSpacer from "../../component/Spacer";
import CommonButton from "../../component/CommonButton";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import { useState } from "react";

import { ICON_CHECK2, ICON_UNCHECK } from "../../constants/appImagePath";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

const LoginTermsOfUseRender = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState([false, false, false, false]);
  const [isLegalAge, setIsLegalAge] = useState(false);

  // useAndroidBackHandler(() => {
  //   window.history.back();
  // }, []);

  const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
    ]);
  };

  const handleChangePrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, checked[1], checked[2], checked[3]]);
  };

  const handleChangeTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], event.target.checked, checked[2], checked[3]]);
  };

  const handleChangeServiceNoti = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], checked[1], event.target.checked, checked[3]]);
    localStorage.setItem("isServiceConsent", event.target.checked.toString());
  }

  const handleChangeMarketingNoti = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], checked[1], checked[2], event.target.checked]);
    localStorage.setItem("isMarketingConsent", event.target.checked.toString());
  };

  const handleIsLegalAge = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLegalAge(event.target.checked);
    localStorage.setItem("isMinor", event.target.checked.toString());
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 0 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormControlLabel
          label={
            <Typography
              sx={{ fontStyle: fontStyle.bodyMr }}
              color={color.gray800}
            >
              {/* 괄호 전의 텍스트 */}
              {LocalizedText("ua_b120_policy").split("(")[0]}

              {/* 괄호와 그 안의 텍스트 */}
              <Box component="span" sx={{ color: color.primary500 }}>
                ({LocalizedText("ua_b120_policy").match(/\((.*?)\)/)?.[1]})
              </Box>

              {/* 괄호 뒤의 텍스트가 있는 경우 */}
              {LocalizedText("ua_b120_policy").split(")").length > 1 &&
                LocalizedText("ua_b120_policy").split(")")[1]}
            </Typography>
          }
          control={
            <Checkbox
              sx={{ p: 0, px: "8px" }}
              checked={checked[0]}
              onChange={handleChangePrivacy}
              icon={<img src={ICON_UNCHECK} alt="unchecked" />}
              checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
            />
          }
        />
        <Typography
          sx={{ borderBottom: 1, lineHeight: 1.1 }}
          fontStyle={fontStyle.bodyMr}
          color={color.gray600}
          onClick={() => {
            navigate(ROUTE_PATHS.PRIVACY_POLICY);
          }}
        >
          {LocalizedText("ua_b120_more")}
        </Typography>
      </Box>
      <VerticalSpacer height={8} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormControlLabel
          label={
            <Typography
              sx={{ fontStyle: fontStyle.bodyMr }}
              color={color.gray800}
            >
              {/* 괄호 전의 텍스트 */}
              {LocalizedText("ua_b120_terms").split("(")[0]}

              {/* 괄호와 그 안의 텍스트 */}
              <Box component="span" sx={{ color: color.primary500 }}>
                ({LocalizedText("ua_b120_terms").match(/\((.*?)\)/)?.[1]})
              </Box>

              {/* 괄호 뒤의 텍스트가 있는 경우 */}
              {LocalizedText("ua_b120_terms").split(")").length > 1 &&
                LocalizedText("ua_b120_terms").split(")")[1]}
            </Typography>
          }
          control={
            <Checkbox
              sx={{ p: 0, px: "8px" }}
              checked={checked[1]}
              onChange={handleChangeTerms}
              icon={<img src={ICON_UNCHECK} alt="unchecked" />}
              checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
            />
          }
        />
        <Typography
          sx={{ borderBottom: 1, lineHeight: 1.1 }}
          fontStyle={fontStyle.bodyMr}
          color={color.gray600}
          onClick={() => {
            navigate(ROUTE_PATHS.TERMS_OF_SERVICE);
          }}
        >
          {LocalizedText("ua_b120_more")}
        </Typography>
      </Box>
      <VerticalSpacer height={8} />
      <FormControlLabel
        label={
          <Typography
            sx={{ fontStyle: fontStyle.bodyMr }}
            color={color.gray800}
          >
            {LocalizedText("ua_b120_service")}
          </Typography>
        }
        control={
          <Checkbox
            sx={{ p: 0, px: "8px" }}
            checked={checked[2]}
            onChange={handleChangeServiceNoti}
            icon={<img src={ICON_UNCHECK} alt="unchecked" />}
            checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
          />
        }
      />
      <VerticalSpacer height={8} />
      <FormControlLabel
        label={
          <Typography
            sx={{ fontStyle: fontStyle.bodyMr }}
            color={color.gray800}
          >
            {LocalizedText("ua_b120_marketing")}
          </Typography>
        }
        control={
          <Checkbox
            sx={{ p: 0, px: "8px" }}
            checked={checked[3]}
            onChange={handleChangeMarketingNoti}
            icon={<img src={ICON_UNCHECK} alt="unchecked" />}
            checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
          />
        }
      />
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title=""
            onBack={() => {
              window.history.back();
            }}
          />
        </Box> */}
        <VerticalSpacer height={30} />
        {/* 중간 요소 */}
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "calc(100vh - 40px)",
            display: "flex",
            flexDirection: "column",

            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography fontStyle={fontStyle.headingS}>
              {LocalizedText("ua_b120_agree_title")}
            </Typography>
            <VerticalSpacer height={24} />
            <Box
              sx={{
                width: "100%",
                backgroundColor: color.primary50,
                minHeight: 100,
                borderRadius: "10px",
              }}
            >
              <Box sx={{ p: "16px" }}>
                <FormControlLabel
                  label={
                    <Typography sx={{ fontStyle: fontStyle.titleL }}>
                      {LocalizedText("ua_b120_accept")}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      sx={{ p: 0, px: "8px" }}
                      checked={checked[0] && checked[1] && checked[2] && checked[3]}
                      // indeterminate={checked[0] !== checked[1]}
                      onChange={handleChangeAll}
                      icon={<img src={ICON_UNCHECK} alt="unchecked" />}
                      indeterminateIcon={
                        <img src={ICON_UNCHECK} alt="unchecked" />
                      }
                      checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
                    />
                  }
                />
                <VerticalSpacer height={16} />
                {children}
              </Box>
            </Box>
            <VerticalSpacer height={16} />
            <Box
              sx={{
                width: "100%",
                backgroundColor: color.primary50,
                borderRadius: "10px",
                // maxHeight: "10px",
              }}
            >
              <Box sx={{ py: "16px", px: "13px", display: "flex" }}>
                <Checkbox
                  sx={{ p: 0, pr: "8px" }}
                  checked={isLegalAge}
                  onChange={handleIsLegalAge}
                  icon={<img src={ICON_UNCHECK} alt="unchecked" />}
                  checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
                />
                <Typography
                  sx={{
                    fontStyle: fontStyle.bodyMr,
                    color: color.gray800,
                    whiteSpace: "pre-line",
                  }}
                >
                  {LocalizedText("ua_b120_legalage")}
                </Typography>
              </Box>
            </Box>
            <VerticalSpacer height={8} />
            <Typography
              sx={{ mb: "82px", textAlign: "center" }}
              fontStyle={fontStyle.bodyMr}
              color={color.gray600}
            >
              {LocalizedText("ua_b120_explain")}
            </Typography>
          </Box>

          <Typography
            sx={{ mb: "82px", textAlign: "center" }}
            fontStyle={fontStyle.bodyMr}
          >
            {LocalizedText("ua_b120_useservice")}
          </Typography>
        </Box>
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_b120_next_button")}
            textColor="white"
            // buttonColor="white"
            onClick={() => {
              navigate(ROUTE_PATHS.PHONE_AUTH);
            }}
            sx={{
              mx: "20px",
              mb: "14px",
              // border: 1,
              // borderColor: `${color.gray700}`,
              bgcolor:
                checked[0] == true && checked[1] == true
                  ? color.primary500
                  : color.gray500,
            }}
            disabled={checked[0] != true && checked[1] != true}
          />
        </Box>
      </Box>
    </>
  );
};

export default LoginTermsOfUseRender;
