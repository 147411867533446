import { Box, Divider, IconButton, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MyRideCalendar from "../component/MyRideCalendar";
import { useNavigate } from "react-router-dom";
import {
  ICON_RIGHT_ARROW,
  ICON_SPANNER,
  IMAGE_PONYA_HELLO_2,
} from "../../../constants/appImagePath";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { useEffect, useState } from "react";
import { color } from "../../../theme/Color";
import { ApiService } from "../../../restAPI/ApiService";

const RidingReportScreen = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [csData, setCsData] = useState({
    totalFee: 0,
    docCount: 0,
    docList: [],
  });
  useEffect(() => {
    requestTotalHistory();
    requestCSMonthHistory();
  }, []);

  const convertMillisecondsToTime = (milliseconds: number) => {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60)); // 시간 계산
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60)); // 분 계산

    return { hours, minutes }; // 객체로 시간과 분을 반환
  };

  async function requestTotalHistory() {
    const url = "/pw/backend/api/riding/totalHistory";
    const requestBody = {
      uuid: localStorage.getItem("uuid") || "",
    };
    try {
      const res = await ApiService.MobilePost(url, requestBody);
      console.log("TOTAL HISTORY RESPONSE", res?.data);
      if (res) {
        setData(res.data.body);
      } else {
        console.log(`requestTotalHistory failed ${res}`);
      }
    } catch (error) {
      console.error("Error requestTotalHistory:", error);
    }
  }

  async function requestCSMonthHistory() {
    const url = "/pw/ua/contents/cs/monthData";
    const requestBody = {
      country: localStorage.getItem("COUNTRY_CODE_STORAGE_KEY"),
      uuid: localStorage.getItem("uuid"),
    };

    try {
      const res = await ApiService.MobilePost(url, requestBody);
      console.log("CS MONTH HISTORY RESPONSE", res?.data);
      if (res) {
        setCsData({
          totalFee: res.data.totalFee,
          docCount: res.data.docCount,
          docList: res.data.body,
        });
      } else {
        console.log(`requestCSMonthHistory failed ${res}`);
      }
    } catch (error) {
      console.error("Error requestCSMonthHistory:", error);
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   justifyContent: "space-between",
          backgroundColor: "#F2F2F2",
          width: "100vw",
          height: "calc(100vh)",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            // borderBottomRightRadius: "20px",
            // borderBottomLeftRadius: "20px",
            // boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
          }}
        >
          <VerticalSpacer height={14} />
          <Box
            sx={{
              width: "calc(100% - 40px)",
              height: "100%",
              display: "flex",
              // bgcolor: "red",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* 상단 Riding Report 및 back 버튼*/}
            <Typography
              fontStyle={fontStyle.titleL}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative", // position을 relative로 설정
                width: "100%",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute", // position을 absolute로 설정
                  left: -8, // 왼쪽에 위치하도록 설정
                }}
                onClick={() => {
                  window.history.back();
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              {LocalizedText("ua_e320_ridingreport")}
            </Typography>

            {/* 상단 문구 */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                // backgroundColor: "blue",
                position: "relative", // 부모 요소에 상대적 위치 설정
                flexDirection: "column",
                mt: 4,
                zIndex: 1200,
                mb: 2,
              }}
            >
              <Typography
                fontStyle={fontStyle.headingS}
                sx={{ zIndex: 1200, whiteSpace: "pre-line" }}
              >
                {LocalizedText(
                  "ua_e320_ridingreport_title",
                  `${localStorage.getItem("name") || "User"}`
                )}
              </Typography>
              <Box
                sx={{
                  width: "167.81px",
                  position: "absolute",
                  right: -18,
                  top: -45,
                  zIndex: 100,
                }}
                component="img"
                src={IMAGE_PONYA_HELLO_2}
              />
            </Box>

            {/* Remaining Mileage 박스 */}
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                minHeight: 40,
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                display: "flex",
                zIndex: 1200,
                mb: 2.5,
              }}
            >
              <Box sx={{ m: 2.5, mb: 1.5, mt: 1.7, width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    navigate("/ChargingMembership");
                  }}
                >
                  <Box>
                    <Box sx={{ fontStyle: fontStyle.titleM }}>
                      {LocalizedText("ua_e320_remaining")}
                    </Box>
                    <VerticalSpacer height={4} />
                    <Box
                      fontStyle={fontStyle.headingS}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "space-between",
                        color: "#5D38E5",
                      }}
                    >
                      {data?.totalMileage.toFixed(2)}
                      <Typography
                        fontStyle={fontStyle.titleM}
                        sx={{ color: color.gray600, ml: "4px" }}
                      >
                        km
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      left: 10,
                      top: 3,
                      color: "black",
                    }}
                  >
                    <Box component="img" src={ICON_RIGHT_ARROW} />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Carbon Reduction 박스 */}
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                minHeight: 40,
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                display: "flex",
                zIndex: 1200,
                mb: 2.5,
              }}
            >
              <Box sx={{ m: 2.5, mb: 1.5, mt: 1.7, width: "100%" }}>
                <Box
                  fontStyle={fontStyle.titleM}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    navigate("/CarbonReductionNFT", {
                      state: {
                        state: {
                          lastPath: "MorePage",
                        },
                      },
                    });
                  }}
                >
                  <Box>
                    <Box sx={{ fontStyle: fontStyle.titleM }}>
                      {LocalizedText("ua_e320_carbonreduction")}
                    </Box>
                    <VerticalSpacer height={4} />
                    <Box
                      fontStyle={fontStyle.headingS}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: color.secondary600,
                      }}
                    >
                      998.2
                      <Typography
                        fontStyle={fontStyle.titleM}
                        sx={{ color: "#999999", ml: 0.7 }}
                      >
                        kgCO₂-eq
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      position: "relative",
                      left: 10,
                      top: 3,
                      color: "black",
                    }}
                  >
                    <Box component="img" src={ICON_RIGHT_ARROW} />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Dashboard 상단 박스 */}
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                minHeight: 40,
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                display: "flex",
                zIndex: 1200,
                mb: 2.5,
              }}
            >
              <Box sx={{ width: "100%", p: 1, display: "flex" }}>
                {/* Odometer */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.subTitleM}
                    sx={{ color: "#676665" }}
                  >
                    {LocalizedText("ua_e320_mileage")}
                  </Typography>
                  <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
                    {data?.totalMileage.toFixed(2)}
                    <Typography
                      fontStyle={fontStyle.titleL}
                      sx={{ color: "#999999", ml: 0.7 }}
                    >
                      km
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ backgroundColor: "#EBEBEB" }}
                />
                {/* Power Usage */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.subTitleM}
                    sx={{ color: "#676665" }}
                  >
                    {LocalizedText("ua_e320_powerusage")}
                  </Typography>
                  <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
                    {data?.totalPowerUsage.toFixed(2)}
                    <Typography
                      fontStyle={fontStyle.titleL}
                      sx={{ color: "#999999", ml: 0.7 }}
                    >
                      kwh
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ backgroundColor: "#EBEBEB" }}
                />
                {/* Riding Time */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.subTitleM}
                    sx={{ color: "#676665" }}
                  >
                    {LocalizedText("ua_e320_ridingtime")}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
                      {convertMillisecondsToTime(data?.ridingTime).hours}
                      <Typography
                        fontStyle={fontStyle.titleL}
                        sx={{ color: "#999999", ml: 0.7 }}
                      >
                        h
                      </Typography>
                    </Box>
                    <Box
                      fontStyle={fontStyle.titleL}
                      sx={{ display: "flex", ml: 0.7 }}
                    >
                      {convertMillisecondsToTime(data?.ridingTime).minutes}
                      <Typography
                        fontStyle={fontStyle.titleL}
                        sx={{ color: "#999999", ml: 0.7 }}
                      >
                        m
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Dashboard 하단 박스 */}
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                minHeight: 40,
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                display: "flex",
                zIndex: 1200,
                mb: 2.5,
              }}
            >
              <Box sx={{ width: "100%", p: 1, display: "flex" }}>
                {/* Average Speed */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.subTitleM}
                    sx={{ color: "#676665" }}
                  >
                    {LocalizedText("ua_e320_averagespeed")}
                  </Typography>
                  <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
                    {data?.averageSpeed.toFixed(2)}
                    <Typography
                      fontStyle={fontStyle.titleL}
                      sx={{ color: "#999999", ml: 0.7 }}
                    >
                      km
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ backgroundColor: "#EBEBEB" }}
                />
                {/* Max Speed */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.subTitleM}
                    sx={{ color: "#676665" }}
                  >
                    {LocalizedText("ua_e320_maxspeed")}
                  </Typography>
                  <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
                    {data?.maxSpeed.toFixed(2)}
                    <Typography
                      fontStyle={fontStyle.titleL}
                      sx={{ color: "#999999", ml: 0.7 }}
                    >
                      km
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* 캘린더 */}

            <MyRideCalendar
              totalMileage={data?.totalMileage.toFixed(2)}
              totalPowerUsage={data?.totalPowerUsage.toFixed(2)}
              hours={convertMillisecondsToTime(data?.ridingTime).hours}
              minutes={convertMillisecondsToTime(data?.ridingTime).minutes}
            />
            {/* 정비내역 */}
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                minHeight: 40,
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                zIndex: 1200,
                mb: 2.5,
              }}
              onClick={() => {
                navigate("/cs_list");
              }}
            >
              <Box
                sx={{
                  ml: 2.5, // left margin
                  mr: 2.5, // right margin
                  mb: 1.5, // bottom margin
                  mt: 1.7, // top margin
                  width: "calc(100% - 40px)", // 전체 너비에서 마진을 뺀 너비

                  display: "flex",
                }}
              >
                <Box
                  fontStyle={fontStyle.titleL}
                  sx={{
                    display: "flex",
                    width: "100%",
                    // bgcolor: "red",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box component="img" src={ICON_SPANNER} sx={{ mr: 0.7 }} />

                    {LocalizedText("ua_e320_monthlymaintenance")}
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      left: 10,
                      top: 3,
                      color: "black",
                    }}
                  >
                    <Box component="img" src={ICON_RIGHT_ARROW} />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "calc(100%-40px)",

                  mx: 2.5,
                  mb: 1.5,
                  mt: -1.3,
                }}
              >
                <Typography fontStyle={fontStyle.headingS}>
                  {LocalizedText("ua_e320_spent", `${csData.totalFee}`)}
                </Typography>
                <Box sx={{ width: "100%", my: 1, display: "flex" }}>
                  {/* CS Count */}
                  <Box
                    sx={{
                      // flex: 1,
                      width: "37%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      my: 1,
                      mr: 2,
                    }}
                  >
                    <Typography
                      fontStyle={fontStyle.bodyMr}
                      sx={{ color: "#676665" }}
                    >
                      {LocalizedText("ua_e320_cscount")}
                    </Typography>
                    <Typography
                      fontStyle={fontStyle.titleL}
                      sx={{ display: "flex" }}
                    >
                      {csData.docCount} {LocalizedText("ua_e320_cases")}
                    </Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ backgroundColor: "#EBEBEB" }}
                  />
                  {/* CS Details */}
                  <Box
                    sx={{
                      // flex: 2,
                      display: "flex",
                      width: "63%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      my: 1,
                      ml: 2,
                    }}
                  >
                    <Typography
                      fontStyle={fontStyle.bodyMr}
                      sx={{ color: "#676665" }}
                    >
                      {LocalizedText("ua_e320_csdetail")}
                    </Typography>
                    {/* TODO 기획상 CS Title List 애니메이션 추가 필요 */}
                    <Typography
                      fontStyle={fontStyle.titleL}
                      sx={{
                        width: "100%",
                        display: "inline-block", // 텍스트를 한 줄로 유지
                        textOverflow: "ellipsis", // ... 표시
                        overflow: "hidden", // 내용이 넘칠 때 숨김
                        whiteSpace: "nowrap", // 줄바꿈 방지
                        textAlign: "center",
                      }}
                    >
                      {csData.docList[0] || "No CS History"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RidingReportScreen;
