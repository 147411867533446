import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FAQDetailUiState } from "./FAQDetailUiState";
import FAQDetailRender from "./FAQDetailRender";
import { FAQDetailSideEffect } from "./FAQDetailSideEffect";
import { ApiService } from "../../../../restAPI/ApiService";
import { FAQDetailModel } from "./model/FAQDetailModel";
import useAndroidBackHandler from "../../../../customHook/useAndroidBackHandler";
import ProgressBar from "../../../../component/ProgressBar";

export default function FAQListPage() {
  const [uiState, setUiState] = useState(new FAQDetailUiState("", ""));
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState(false);
  let requestBody = location.state as { id: string; uuid: string };

  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  useEffect(() => {
    (async () => {
      await updateFAQDetail(requestBody.id, requestBody.uuid);
    })();
  }, [requestBody]);

  const sideEffect: FAQDetailSideEffect = {
    back: () => {
      window.history.back();
    },
    navigate: (path) => {
      navigate(path);
    },
  };

  async function updateFAQDetail(id: string, uuid: string) {
    setProgress(true);
    const faqList = await getFAQDetail(id, uuid);
    if (faqList == null) {
      // 에러 발생시 뒤로가기
      window.history.back();
    } else {
      setUiState((prev) => ({
        ...prev,
        title: faqList.title,
        content: faqList.content,
      }));
    }
  }

  async function getFAQDetail(
    id: string,
    uuid: string,
  ): Promise<FAQDetailModel | null> {
    const requestBody = {
      id: id,
      uuid: uuid,
    };

    try {
      let faqDetail: FAQDetailModel | null = null;
      await ApiService.MobilePost(
        "/pw/ua/contents/faq/detail",
        requestBody,
      ).then((res) => {
        faqDetail = res?.data.body;
      });
      return faqDetail;
    } catch (e) {
      return null;
    } finally {
      setProgress(false);
    }
  }

  if (progress) return <ProgressBar />;
  return <FAQDetailRender uiState={uiState} sideEffect={sideEffect} />;
}
