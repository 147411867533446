import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SxProps, Theme } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { color } from "../theme/Color";

interface CommonTimePickerProps {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onChange?: (newDate: Dayjs | null) => void;
  value: Dayjs | null;
}

const CommonTimePicker = ({
  disabled,
  sx,
  onChange,
  value,
}: CommonTimePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        value={value}
        onChange={onChange}
        disabled={disabled}
        slotProps={{
          textField: {
            variant: "outlined",
            InputProps: {
              sx: {
                borderRadius: "10px",
                backgroundColor: disabled ? color.gray200 : "white",
                ...sx,
              },
            },
            sx: {
              "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: `${color.gray300} !important`, // disabled 상태에서 borderColor 강제 적용 todo 보더 색상 변경해야함
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CommonTimePicker;
