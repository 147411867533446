export class Notice {
  id: string;
  title: string;
  registrationDate: number;

  constructor(id: string, title: string, date: number) {
    this.id = id;
    this.title = title;
    this.registrationDate = date;
  }
}
