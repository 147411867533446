import { Box, Typography, Button } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import MembershipInfo, { MembershipStatus } from "../../model/MembershipInfo";
import CommonButton from "../../../../component/CommonButton";
import { ICON_RIGHT_ARROW_GRAY600 } from "../../../../constants/appImagePath";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";

interface InfoCardProps {
  membershipItem: MembershipInfo;
  status: MembershipStatus;
  onClickVoucher: () => void;
  onClickExtend: () => void;
  onClickPurchaseHistory: () => void;
}

const InfoCard = ({
  membershipItem,
  status,
  onClickVoucher,
  onClickExtend,
  onClickPurchaseHistory,
}: InfoCardProps) => {
  const purchaseVoucherText = LocalizedText("ua_g420_purchasevoucher");
  const extendMembershipText = LocalizedText("ua_g420_extendmembership");
  const needExtendText = LocalizedText("ua_g420_needextend")

  const membershipRemainingMileage =
    status === MembershipStatus.Expired ? 0 : membershipItem.membershipAllowanceKm;
  const membershipIssueDate = LocalizedDate({
    date: membershipItem.startDate ?? 0,
    type: DateFormatType.YearMonthDay,
  });
  const membershipExpirationDate = LocalizedDate({
    date: membershipItem.endDate ?? 0,
    type: DateFormatType.YearMonthDay,
  });

  const formatMileage = (mileage: number) => {
    if (mileage >= 2_000_000) {
      return "Unlimited";
    }
    return `${parseFloat(mileage.toFixed(1)).toLocaleString()} km`;
  };

  const renderAddVoucherButton = () => {
    return (
      <CommonButton
        title={purchaseVoucherText}
        buttonColor={color.primary50}
        textColor={color.primary500}
        height={46}
        sx={{
          width: "calc(50% - 4px)",
        }}
        onClick={onClickVoucher}
      />
    );
  };

  const renderExtendButton = (isDisabled: boolean) => {
    return (
      <CommonButton
        title={extendMembershipText}
        buttonColor={color.primary500}
        disabledButtonColor={color.gray300}
        textColor={color.white}
        disableTextColor={color.white}
        height={46}
        disabled={isDisabled}
        sx={{
          width:
            status === MembershipStatus.Expired ? "100%" : "calc(50% - 4px)",
        }}
        onClick={onClickExtend}
      />
    );
  };

  const renderMembershipDetails = () => {
    if (status === MembershipStatus.Expired) {
      return (
        <>
          <Typography fontStyle={fontStyle.titleL} color={color.gray800}>
            {membershipItem.expiredName} (
            {formatMileage(membershipItem.expiredKm ?? 0)})
          </Typography>
          <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
            {needExtendText}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography fontStyle={fontStyle.titleL} color={color.gray800}>
            {membershipItem.name} (
            {formatMileage(membershipItem.membershipKm ?? 0)})
          </Typography>
          <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
            {membershipIssueDate} ~ {membershipExpirationDate}
          </Typography>
        </>
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "1px 1px 20px 0px rgba(27,25,24,0.15)",
        bgcolor: "white",
        borderRadius: "10px",
        width: "100%",
        height: "auto",
        p: "20px 16px 10px 16px",
        boxSizing: "border-box",
      }}
    >
      {/* Membership */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "8px",
        }}
      >
        <Typography fontStyle={fontStyle.titleXL} color={color.default}>
          {LocalizedText("ua_g420_membership")}
        </Typography>
        <Typography fontStyle={fontStyle.titleXL} color={color.default}>
          {formatMileage(membershipRemainingMileage ?? 0)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            bgcolor: color.primary200,
            width: "4px",
            borderRadius: "4px",
            position: "absolute",
            left: 0,
            top: 5,
            bottom: 5,
          }}
        />
        <Box sx={{ ml: "12px" }}>{renderMembershipDetails()}</Box>
      </Box>

      <VerticalSpacer height={20} />

      {/* Voucher */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Typography fontStyle={fontStyle.titleXL} color={color.default}>
          {LocalizedText("ua_g420_voucher")}
        </Typography>
        <Typography fontStyle={fontStyle.titleXL} color={color.default}>
          {formatMileage(membershipItem.voucherKm ?? 0)}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent:
            status === MembershipStatus.Expired ? "center" : "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {status !== MembershipStatus.Expired && renderAddVoucherButton()}
        {renderExtendButton(status === MembershipStatus.ActiveOutsideRenewal)}
      </Box>

      <VerticalSpacer height={20} />

      {/* Divider */}
      <Box
        sx={{
          bgcolor: color.gray200,
          height: "1px",
          width: "calc(100% + 32px)",
          position: "relative",
          left: "-16px",
        }}
      />

      <VerticalSpacer height={10} />

      {/* Purchasing History */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "auto",
          width: "80%",
        }}
        onClick={onClickPurchaseHistory}
      >
        <Typography fontStyle={fontStyle.subTitleM} color={color.gray900}>
          {LocalizedText("ua_g420_purchasehistory")}
        </Typography>
        <img
          src={ICON_RIGHT_ARROW_GRAY600}
          alt="Arrow"
          style={{
            width: "24px",
            height: "24px",
          }}
        />
      </Box>
    </Box>
  );
};

export default InfoCard;
