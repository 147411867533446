import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import CommonDialog from "../../../component/CommonDialog";
import { useNavigate } from "react-router-dom";

interface FAQDetailGridProps {
  faqDetailsData: any;
  editDialogOpen: any;
  setEditDialogOpen: any;
}

const FAQDetailGrid = ({
  faqDetailsData,
  editDialogOpen,
  setEditDialogOpen,
}: FAQDetailGridProps) => {
  const navigate = useNavigate();
  // row Id
  const [rowId, setRowId] = useState("");
  // title 관련
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  // content 관련
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);
  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  // country관련
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };
  // category관련
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  // showHide 관련
  const [showHide, setShowHide] = useState<boolean>();
  const handleChange = (event: boolean) => {
    setShowHide(event);
  };

  const handleClose = () => {
    setEditDialogOpen(false);
  };

  const handleOk = () => {
    // ApiService.WebDelete(`/pw/aw/contents/faq/delete/${id}`).then((res) => {
    //   console.log(res?.data);
    //   if (res?.data.message == "delete complete") {
    //     window.location.reload();
    //   }
    // });
    let hasError = false;
    // 각 필드에 대한 데이터 검증
    if (title.length <= 0) {
      setTitleError(true);
      setEditDialogOpen(false);
      hasError = true;
    } else {
      setTitleError(false);
    }

    if (content.length <= 0) {
      setContentError(true);
      setEditDialogOpen(false);
      hasError = true;
    } else {
      setContentError(false);
    }

    if (!hasError) {
      ApiService.WebPost("/pw/aw/contents/faq/update", {
        id: rowId,
        country: countryCode,
        showHide: showHide,
        category: category,
        title: title,
        uuid: "gJtIDJEBTozLt71O9iDr",
        content: content,
      })
        .then((res) => {
          console.log(res?.data);
          //   setCreateDialogOpen(false); // 다이얼로그 닫기
        })
        .then(() => {
          navigate("/FAQMng");
          window.location.reload();
        });
    }
  };

  // 각 select 데이터 가져옴
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
    });
    ApiService.WebPost("/pw/aw/contents/faq/getCategory").then((res) => {
      setCategoryList(res?.data.body);
    });
  }, []);

  // useState는 비동기 처리라 초기 값이 없을 수 있음 아래로 대처해줌
  useEffect(() => {
    if (faqDetailsData) {
      for (let i = 0; i < countryList.length; ++i) {
        if (faqDetailsData?.country == countryList[i]["code"]) {
          setCountry(countryList[i]["label"]);
        }
      }
      setTitle(faqDetailsData.title);
      setCategory(faqDetailsData.category);
      setShowHide(faqDetailsData.showHide);
      setContent(faqDetailsData?.content);
      setCountryCode(faqDetailsData?.country);
      setRowId(faqDetailsData?.id);
    }
  }, [faqDetailsData]);

  return (
    <Grid container columnSpacing={"20px"}>
      {/* 첫번째 카드 */}
      <Grid item lg={8}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              padding: "0px",
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Box
              sx={{
                mx: "24px",
                mt: "24px",
                mb: "40px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Title
                </Typography>
                <VerticalSpacer height={8} />
                <TextField
                  // name="title"
                  sx={{ width: "100%" }}
                  //   size="small"
                  value={title}
                  error={titleError === true}
                  onChange={handleTitleChange}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      px: "16px",
                      py: "0px",
                      minHeight: "48px",
                    },
                  }}
                />
                {titleError == true && (
                  <>
                    <VerticalSpacer height={4} />
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please enter a title
                    </Typography>
                  </>
                )}
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Contents
                </Typography>
                <VerticalSpacer height={8} />
                <TextField
                  // name="title"
                  sx={{ width: "100%" }}
                  //   size="small"
                  value={content}
                  error={contentError === true}
                  onChange={handleContentChange}
                  multiline
                  rows={11}
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                      px: "14px",
                      py: "12px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      px: "0px",
                      py: "0px",
                      //   minHeight: "48px",
                    },
                    maxLength: 1000,
                  }}
                />
                {contentError == true && (
                  <>
                    <VerticalSpacer height={4} />
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please enter up to 1000 characters.
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={4}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              p: "24px",
              "&:last-child": {
                paddingBottom: "40px",
              },
            }}
          >
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
              sx={{ p: 0 }}
            />

            <VerticalSpacer height={16} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Country
                </Typography>
                {countryCode && (
                  <Select
                    value={country}
                    sx={{
                      width: "100%",
                      mt: 1,
                      borderRadius: "12px",
                      // backgroundColor: "#E9E9EA",
                    }}
                    onChange={handleCountryChange}
                    renderValue={(v) => {
                      return v?.length ? v : `Country`;
                    }}
                  >
                    {countryList.map((data: any, index: any) => {
                      return (
                        <MenuItem
                          value={[data.label, data.code]}
                          key={index}
                          sx={{
                            fontStyle: fontStyle.semiboldXS,
                            color: color.gray600,
                          }}
                        >
                          {data.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Category
                </Typography>
                {category && (
                  <Select
                    value={category}
                    sx={{
                      width: "100%",
                      mt: 1,
                      borderRadius: "12px",
                      // backgroundColor: "#E9E9EA",
                    }}
                    onChange={handleCategoryChange}
                    renderValue={(v) => {
                      return v?.length ? v : null;
                    }}
                  >
                    {categoryList.map((data: any, index: any) => {
                      return (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Show/Hide
                </Typography>
                {showHide !== undefined && (
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      <Radio
                        checked={showHide}
                        onChange={() => {
                          handleChange(true);
                        }}
                        value={true}
                        sx={{
                          fontStyle: fontStyle.regularM,
                        }}
                      />
                      Show
                    </Box>
                    <Box sx={{ ml: 5 }}>
                      <Radio
                        checked={!showHide}
                        onChange={() => {
                          handleChange(false);
                        }}
                        value={false}
                        sx={{
                          fontStyle: fontStyle.regularM,
                        }}
                      />
                      Hide
                    </Box>
                  </Box>
                )}
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontWeight={"bold"} color={"#99989D"}>
                  Writer
                </Typography>
                <TextField
                  name="description"
                  sx={{ width: "100%", mt: 1 }}
                  value={"Very Kim"}
                  // onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "7px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <CommonDialog
        open={editDialogOpen}
        handleClose={handleClose}
        handleOk={handleOk}
      >
        <Typography fontStyle={fontStyle.regularS} color={color.gray800}>
          Would you like to update the FAQ?
        </Typography>
      </CommonDialog>
    </Grid>
  );
};

export default FAQDetailGrid;
