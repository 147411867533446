import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import CommonList from "../../component/CommonList";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { useEffect, useState } from "react";
import { ApiService } from "../../restAPI/ApiService";
import BlockChainTable from "./section/BlockChainTable";

interface TotalCardProps {
  title: string;
  totalCount: number;
}

const TotalCard = ({ title, totalCount }: TotalCardProps) => {
  return (
    <Card
      sx={{
        borderRadius: "15px",
        border: 1,
        borderColor: color.gray200,
        minHeight: 60,
        width: "100%",
        display: "flex",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          width: "100%",
          p: "20px",
          "&:last-child": {
            paddingBottom: "20px",
          },
        }}
      >
        <Typography fontStyle={fontStyle.semiboldM} color={color.gray800}>
          {title}
        </Typography>
        <VerticalSpacer height={4} />
        <Typography fontStyle={fontStyle.displayM}>{totalCount}</Typography>
      </CardContent>
    </Card>
  );
};

const BlockchainMonitoringPage = () => {
  // totalBlock 관련 변수
  const [totalBlock, setTotalBlock] = useState<any>([]);
  const [totalBlockRowsPerPage, setTotalBlockRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [totalBlockPage, setTotalBlockPage] = useState<number>(0); // 현재 페이지의 번호
  const [totalBlockDocCount, setTotalBlockDocCount] = useState<number>(0); // 총 row의 수
  const [totalBlockPageCount, setTotalBlockPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수

  // totalTransactions 관련 변수
  const [totalTransactions, setTotalTransactions] = useState<any>([]);
  const [totalTransactionsRowsPerPage, setTotalTransactionsRowsPerPage] =
    useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [totalTransactionsPage, setTotalTransactionsPage] = useState<number>(0); // 현재 페이지의 번호
  const [totalTransactionsDocCount, setTotalTransactionsDocCount] =
    useState<number>(0); // 총 row의 수
  const [totalTransactionsPageCount, setTotalTransactionsPageCount] =
    useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수

  const [totalAddress, setTotalAddress] = useState<any>({});
  const [totalNFT, setTotalNFT] = useState<any>({});

  useEffect(() => {
    ApiService.BlockchainPost("/nft/totalBlock", {
      page: totalBlockPage,
      size: totalBlockRowsPerPage,
    }).then((res) => {
      setTotalBlock(res?.data.body.blockInfos);
      setTotalBlockPageCount(
        Math.ceil(res?.data.body.total / totalBlockRowsPerPage)
      );
      setTotalBlockDocCount(res?.data.body.total);
    });
    ApiService.BlockchainPost("/nft/totalTransactions", {
      page: 0,
      size: 25,
    }).then((res) => {
      console.log("2 : ", res?.data);
    });
    ApiService.BlockchainPost("/wallet/totalWallet").then((res) => {
      //   console.log("3 : ", res?.data);
    });
    ApiService.BlockchainPost("/nft/echoPlatform/totalNFT").then((res) => {
      //   console.log("4 : ", res?.data);
    });
  }, [totalBlockRowsPerPage, totalBlockPage]);

  return (
    <CommonList title="블록체인 모니터링" setModal={null}>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item lg={3}>
          <TotalCard title="Total Block" totalCount={totalBlockDocCount} />
        </Grid>
        <Grid item lg={3}>
          <TotalCard title="Total Transactions" totalCount={0} />
        </Grid>
        <Grid item lg={3}>
          <TotalCard title="Total Address" totalCount={0} />
        </Grid>
        <Grid item lg={3}>
          <TotalCard title="Total NFT" totalCount={0} />
        </Grid>
      </Grid>
      <VerticalSpacer height={20} />
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item lg={6}>
          <Card
            sx={{
              borderRadius: "15px",
              border: 1,
              borderColor: color.gray200,
              minHeight: 60,
              width: "100%",
              display: "flex",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                width: "100%",
                pt: "24px",
                px: 0,
                "&:last-child": {
                  paddingBottom: "0px",
                },
              }}
            >
              <Box
                fontStyle={fontStyle.semiboldL}
                // color={color.gray800}
                sx={{ display: "flex" }}
              >
                <HorizontalSpacer width={24} />
                Blocks
              </Box>
              <VerticalSpacer height={8} />
              <BlockChainTable
                mngRows={totalBlock}
                rowsPerPage={totalBlockRowsPerPage}
                setRowsPerPage={setTotalBlockRowsPerPage}
                docCount={totalBlockDocCount}
                page={totalBlockPage}
                setPage={setTotalBlockPage}
                pageCount={totalBlockPageCount}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6}>
          <Card
            sx={{
              borderRadius: "15px",
              border: 1,
              borderColor: color.gray200,
              minHeight: 60,
              width: "100%",
              display: "flex",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                width: "100%",
                pt: "24px",
                px: 0,
                "&:last-child": {
                  paddingBottom: "0px",
                },
              }}
            >
              <Box
                fontStyle={fontStyle.semiboldL}
                // color={color.gray800}
                sx={{ display: "flex" }}
              >
                <HorizontalSpacer width={24} />
                Blocks
              </Box>
              <VerticalSpacer height={8} />
              <BlockChainTable
                mngRows={totalBlock}
                rowsPerPage={totalBlockRowsPerPage}
                setRowsPerPage={setTotalBlockRowsPerPage}
                docCount={totalBlockDocCount}
                page={totalBlockPage}
                setPage={setTotalBlockPage}
                pageCount={totalBlockPageCount}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CommonList>
  );
};

export default BlockchainMonitoringPage;
