import MorePageRender from "./MorePageRender";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { MorePageUiState } from "./MorePageUiState";
import { MorePageUiEvent } from "./MorePageUiEvent";
import { MorePageSideEffect } from "./MorePageSideEffect";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import {clearUserDataInLocalStorage} from "../../utils/LocalStorageUtil";

const MorePage = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new MorePageUiState());

  useAndroidBackHandler(() => {
    window.Android.onBackStackCleared();
  });

  const uiEventHandler: MorePageUiEvent = {
    ShowAlertDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowAlertDialog: true,
        };
      });
    },
    HideAlertDialog: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShowAlertDialog: false,
        };
      });
    },
  };

  const sideEffectHandlers: MorePageSideEffect = {
    navigate: function (target: string): void {
      console.log("navigate", target);
      navigate(`/${target}`);
    },
    logout: function (): void {
      clearUserDataInLocalStorage();
      if (window.Android) {
        window.Android.onLogoutEvent();
      }
      if (window.webkit) {
        window.webkit.messageHandlers.onLogoutEvent.postMessage("logout");
      }
    },
  };

  return (
    <>
      <MorePageRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandlers}
      />
    </>
  );
};

export default MorePage;
