import { Box, SxProps, TableCell, Theme } from "@mui/material";

interface RegionManagementTableCellProps {
    childrenContainerSx?: SxProps<Theme>;
    children?: JSX.Element;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    sx?: SxProps<Theme>;
}

export function RegionManagementTableCell({
    childrenContainerSx,
    children,
    align,
    sx,
  }: RegionManagementTableCellProps) {
    return (
      <TableCell
        sx={{
          py: "4px",
          px: "12px",
          ...sx,
        }}
        align={align}
      >
        <Box
          sx={{
            py: 0,
            display: "flex", // flexbox로 변경
            alignItems: "center", // 수직 중앙 정렬
            minHeight: "26px",
            ...childrenContainerSx,
          }}
        >
          {children}
        </Box>
      </TableCell>
    );
    
}