import { useNavigate } from "react-router-dom";
import MoreCarbonReductionNFTRender from "./MoreCarbonReductionNFTRender";
import { MoreCarbonReductionNFTSideEffect } from "./MoreCarbonReductionNFTSideEffect";

const MoreCarbonReductionNFTPage = () => {
  const navigate = useNavigate();
  const sideEffectHandlers: MoreCarbonReductionNFTSideEffect = {
    navigate: function (target: string): void {
      console.log("navigate", target);
      navigate(`/${target}`);
    },
  };
  return (
    <>
      <MoreCarbonReductionNFTRender sideEffect={sideEffectHandlers} />
    </>
  );
};

export default MoreCarbonReductionNFTPage;
