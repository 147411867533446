import { Box, Card, Grid } from "@mui/material";
import CommonList from "../../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../../component/CommonWebHeaderButton";
import { color } from "../../../../../../theme/Color";
import { DetailsBillOfMaterialTree } from "./DetailsBillOfMaterialTree";
import VerticalSpacer from "../../../../../../component/Spacer";
import { DetailsFindParts } from "./DetailsFindParts";
import { RegisterBillOfMaterialDate } from "./BillOfMaterialDetailDate";

export function BillOfMaterialsDetails() {
  return (
    <CommonList
      title="자재 명세 상세"
      headerBackButton={true}
      navigateURL="/materialDetails"
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Update"
          height="45px"
          sx={{ display: "flex", flexDirection: "column" }}
        />
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <DetailsFindParts />
          <VerticalSpacer height={24} />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CommonWebHeaderButton
              buttonContent="Add to List"
              addIcon
              height="36px"
              iconFontSize="18px"
            />
          </Box>
          <VerticalSpacer height={40} />
          <DetailsBillOfMaterialTree />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <RegisterBillOfMaterialDate />
        </Card>
      </Grid>
    </CommonList>
  );
}
