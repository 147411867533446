import { Box, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../../component/Spacer";
import { fontStyle } from "../../../../../../theme/Style";
import { color } from "../../../../../../theme/Color";
import { ICON_QRCODE } from "../../../../../../constants/appImagePath";
import { LocalizedText } from "../../../../../../di/LanguageRepositoryProvider";
import { ApiService } from "../../../../../../restAPI/ApiService";

interface CouponCardProps {
  useWhere?: string;
  content?: string;
  date?: string;
  handleDrawerOpenChange?: any;
  couponId: string;
  setCouponId?: any;
}

const CouponCard = ({
  useWhere,
  content,
  date,
  handleDrawerOpenChange,
  couponId,
  setCouponId,
}: CouponCardProps) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.03)",
          bgcolor: "white",
          minHeight: 30,
          display: "flex",
        }}
      >
        <HorizontalSpacer width={20} />
        <Box sx={{ width: "100%" }}>
          <VerticalSpacer height={27} />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography fontStyle={fontStyle.bodyMr}>{useWhere}</Typography>
              <Typography
                fontStyle={fontStyle.titleXL}
                color={color.primary500}
              >
                {content}
              </Typography>
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                {date}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "48px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => {
                handleDrawerOpenChange(true);
                setCouponId(couponId);
              }}
            >
              <Box component="img" src={ICON_QRCODE} />
              <VerticalSpacer height={4} />
              <Typography fontStyle={fontStyle.titleS} color={color.gray700}>
                {LocalizedText("ua_g320_use")}
              </Typography>
            </Box>
          </Box>

          <VerticalSpacer height={27} />
        </Box>

        <HorizontalSpacer width={20} />
      </Box>
    </>
  );
};

export default CouponCard;
