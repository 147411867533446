import { Box, Button, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { useEffect, useState } from "react";
import { color } from "../../../theme/Color";
import axios from "axios";

function setVersionInfo(currentVersion: string) {
  const event = new CustomEvent("handleVersionInfo", {
    detail: { currentVersion },
  });
  window.dispatchEvent(event);
}

(window as any).handleVersionInfo = setVersionInfo;

const MorePageBottomVersion = () => {
  const [version, setVersion] = useState<string | undefined>();
  const [isLatestVersion, setIsLatestVersion] = useState<boolean>(true);

  useEffect(() => {
    const eventHandler = (event: Event) => {
      const { currentVersion } = (
        event as CustomEvent<{ currentVersion: string }>
      ).detail;
      setVersion(currentVersion);
    };
    window.addEventListener("handleVersionInfo", eventHandler);

    if (window.webkit) {
      window.webkit.messageHandlers.reactReady.postMessage("");
    }
    if (window.Android) {
      window.Android.reactReady();
    }
    return () => {
      window.removeEventListener("handleVersionInfo", eventHandler);
    };
  }, []);

  useEffect(() => {
    if (version === undefined) return;

    const fetchVersionInfo = async () => {
      try {
        const url = "/pw/app/version/latest";
        const requestBody = {
          os: window.Android ? "android" : "ios",
        };
        const headers = {};
        const responseType = "json";
        const response = await axios.post("/mobile" + url, requestBody, {
          headers,
          responseType,
        });
        if (response?.status === 200) {
          const recentVersion = response.data.version;
          if (recentVersion && typeof recentVersion === "string") {
            setIsLatestVersion(version === recentVersion);
          } else {
            console.warn(
              "Received invalid or empty version data:",
              recentVersion,
            );
            setIsLatestVersion(true);
          }
        } else {
          console.warn("Unexpected response status:", response?.status);
          setIsLatestVersion(true);
        }
      } catch (error) {
        console.error("Failed to fetch version info:", error);
        setIsLatestVersion(true);
      }
    };

    fetchVersionInfo().then((r) => {});
  }, [version]);

  function compareVersions(current: string, target: string): boolean {
    const [currentMajor, currentMinor, currentPatch] = current
      .split(".")
      .map(Number);
    const [targetMajor, targetMinor, targetPatch] = target
      .split(".")
      .map(Number);

    if (targetMajor > currentMajor) return true;
    if (targetMajor === currentMajor && targetMinor > currentMinor) return true;
    return (
      targetMajor === currentMajor &&
      targetMinor === currentMinor &&
      targetPatch > currentPatch
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "42px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: "16px",
          my: "10px",
        }}
      >
        <Typography
          sx={{
            visibility: version ? "visible" : "hidden",
          }}
          fontStyle={fontStyle.bodyMr}
          color={color.gray600}
        >
          App version v{version}
        </Typography>
        {!isLatestVersion && ( // 최신 버전이 아닐 때 버튼 표시
          <Button
            sx={{
              bgcolor: "#5D38E5",
              color: "white",
              display: "flex",
              height: "26px",
              width: "81px",
              borderRadius: "30px",
              "&:hover": {
                bgcolor: "#5D38E5", // hover 시 배경색
              },
            }}
            onClick={() => {
              if (window.webkit) {
                window.webkit.messageHandlers.openAppStore.postMessage("");
              } else if (window.Android) {
                window.Android.openAppStore();
              }
            }}
          >
            <Typography
              fontStyle={fontStyle.titleS}
              sx={{ textTransform: "none" }}
            >
              Update
            </Typography>
          </Button>
        )}
      </Box>
    </>
  );
};

export default MorePageBottomVersion;
