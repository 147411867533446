import { Box, Divider, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { color } from "../../../../theme/Color";

interface GradeCardProps {
  title?: string;
  fontColor?: string;
  criteria?: string;
  distance?: string;
  content?: string;
}

const GradeCard = ({
  title,
  fontColor,
  criteria,
  distance,
  content,
}: GradeCardProps) => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <HorizontalSpacer width={8} />
          <Box sx={{ width: "100%" }}>
            <Typography fontStyle={fontStyle.headingS} color={fontColor}>
              {title}
            </Typography>
            <VerticalSpacer height={8} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography fontStyle={fontStyle.titleM} color={color.gray700}>
                Carbon Reduction
              </Typography>
              <Typography fontStyle={fontStyle.bodyMr} sx={{ display: "flex" }}>
                <Typography fontStyle={fontStyle.titleM} sx={{ mr: "2px" }}>
                  {criteria}
                </Typography>
                kgCO₂-eq
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                {distance}
              </Typography>
            </Box>
            <VerticalSpacer height={12} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography fontStyle={fontStyle.titleM} color={color.gray700}>
                Max Exp. Benefit
              </Typography>
              <Typography fontStyle={fontStyle.bodyMr} sx={{ display: "flex" }}>
                {content}
              </Typography>
            </Box>
          </Box>
          <HorizontalSpacer width={8} />
        </Box>
        <VerticalSpacer height={20} />
        <Divider />
        <VerticalSpacer height={20} />
      </Box>
    </>
  );
};

export default GradeCard;
