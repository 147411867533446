import { Box, Typography } from "@mui/material";
import VerticalSpacer from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import CommonButton from "../../component/CommonButton";
import { color } from "../../theme/Color";
import { IMAGE_PETER_FIGHTING } from "../../constants/appImagePath";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { SERVER_CONFIG } from "../../constants/ServerConfig";

const MobileProfileWithdrawalFinishPage = () => {
  function handleLogout() {
    if (window.Android) {
      console.log("[Success Logout][Android]");
      window.Android.onRestartApp();
    } else if (window.webkit) {
      console.log("[Success Logout][iPhone]");
      window.webkit.messageHandlers.onRestartApp.postMessage("restartApp");
    } else {
      console.log("[Success Logout][Web]");
      window.location.href = SERVER_CONFIG.FRONT_URL + "/loginMobile";
    }
  }

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          position: "relative",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 */}
        <Box
          sx={{
            width: "100%",
            height: "52px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Toolbar title="Withdrawal" onBack={() => {}} /> */}
          <Typography fontStyle={fontStyle.titleL}>
            {LocalizedText("ua_g221_deleteaccount")}
          </Typography>
        </Box>
        <VerticalSpacer height={30} />
        <Box
          sx={{
            width: "calc(100% - 40px)",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontStyle={fontStyle.headingS}>
            {LocalizedText("ua_g221_complete")}
          </Typography>
          <VerticalSpacer height={8} />
          <Typography fontStyle={fontStyle.bodyLr}>
            {LocalizedText("ua_g221_thankyou")}
          </Typography>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={IMAGE_PETER_FIGHTING}
              sx={{ width: "312px" }}
            />
          </Box>
        </Box>
        <VerticalSpacer height={66} />
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_g221_backtologin_button")}
            textColor="white"
            onClick={() => {
              handleLogout();
            }}
            sx={{
              mx: "20px",
              mb: "14px",
              border: 1,
              borderColor: `${color.gray700}`,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default MobileProfileWithdrawalFinishPage;
