import { Box, Typography } from "@mui/material";
import VerticalSpacer from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import CommonButton from "../../component/CommonButton";
import { IMAGE_PETER_FIGHTING2 } from "../../constants/appImagePath";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { useNavigate } from "react-router-dom";
import { SERVER_CONFIG } from "../../constants/ServerConfig";

const LoginFinishPage = () => {
  const navigate = useNavigate();

  // function onSuccessLogin() {
  //   if (window.Android) {
  //     console.log("[Success Login][Android]");
  //     window.Android.onLoginSuccess();
  //   } else if (window.webkit) {
  //     console.log("[Success Login][iPhone]");
  //     window.webkit.messageHandlers.onLoginSuccess.postMessage(
  //       "onLoginSuccess"
  //     );
  //   } else {
  //     console.log("[Success Login][Web]");
  //     window.location.href = SERVER_CONFIG.FRONT_URL + "/PWUserAppMap";
  //   }
  // }

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          position: "relative",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 40px)",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <VerticalSpacer height={72} />
          <Typography fontStyle={fontStyle.headingS}>
            {LocalizedText("ua_b123_signup_title")}
          </Typography>
          <VerticalSpacer height={8} />
          <Typography fontStyle={fontStyle.bodyLr}>
            {LocalizedText("ua_b123_signup")}
          </Typography>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={IMAGE_PETER_FIGHTING2}
              sx={{ width: "312px" }}
            />
          </Box>
        </Box>
        <VerticalSpacer height={66} />
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_b123_signup_button")}
            textColor="white"
            onClick={() => {
              localStorage.setItem("isLoggedIn", "true");
              // onSuccessLogin();
              navigate("/CarbonReductionNFT", {
                state: {
                  state: {
                    lastPath: "LoginFinishPage",
                  },
                }, // 상태 전달
              });
              //window.location.href = window.location.origin + "/PWUserAppMap";
            }}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default LoginFinishPage;
