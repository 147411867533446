const LOGIN_BASE_PATH = `${process.env.PUBLIC_URL}/page/login`;
const IMAGE_BASE_PATH = `${process.env.PUBLIC_URL}/page/image`;
const ICON_BASE_PATH = `${process.env.PUBLIC_URL}/page/icon`;

// Icon
export const ICON_ANSWER = `${ICON_BASE_PATH}/ic_answer.svg`;
export const ICON_ARROW = `${ICON_BASE_PATH}/ic_arrow.svg`;
export const ICON_CASH = `${ICON_BASE_PATH}/ic_cash.svg`;
export const ICON_CLOSE = `${ICON_BASE_PATH}/ic_close.svg`;
export const ICON_DOWNLOAD = `${ICON_BASE_PATH}/ic_download.svg`;
export const ICON_HIDE = `${ICON_BASE_PATH}/ic_hide.svg`;
export const ICON_NO = `${ICON_BASE_PATH}/ic_no.svg`;
export const ICON_NOIMAGE = `${ICON_BASE_PATH}/ic_noimage.svg`;
export const ICON_LOGO_WHITE = `${ICON_BASE_PATH}/ic_logo_white.svg`;
export const ICON_LOGO_PURPLE = `${ICON_BASE_PATH}/ic_logo_purple.svg`;
export const ICON_SHOW = `${ICON_BASE_PATH}/ic_show.svg`;
export const ICON_SHOW_WHITE = `${ICON_BASE_PATH}/ic_show_white.svg`;
export const ICON_QUESTION = `${ICON_BASE_PATH}/ic_question.svg`;
export const ICON_TRASH = `${ICON_BASE_PATH}/ic_trash.svg`;
export const ICON_QRSCAN = `${ICON_BASE_PATH}/ic_qrscan.svg`;
export const ICON_ADDIMAGE = `${ICON_BASE_PATH}/ic_addimage.svg`;
export const ICON_CLIP = `${ICON_BASE_PATH}/ic_clip.svg`;

// menu
export const ICON_MENU1 = `${ICON_BASE_PATH}/ic_menu1.svg`;
export const ICON_MENU2 = `${ICON_BASE_PATH}/ic_menu2.svg`;
export const ICON_MENU3 = `${ICON_BASE_PATH}/ic_menu3.svg`;
export const ICON_BLOCKCHAIN = `${ICON_BASE_PATH}/ic_blockchain.svg`;
export const ICON_CARBON = `${ICON_BASE_PATH}/ic_carbon.svg`;
export const ICON_CHAT = `${ICON_BASE_PATH}/ic_chat.svg`;
export const ICON_CONTENTS = `${ICON_BASE_PATH}/ic_contents.svg`;
export const ICON_PACKAGE = `${ICON_BASE_PATH}/ic_package.svg`;
export const ICON_PROFILE = `${ICON_BASE_PATH}/ic_profile.svg`;
export const ICON_STATISTICS = `${ICON_BASE_PATH}/ic_statistics.svg`;
export const ICON_SYSTEM = `${ICON_BASE_PATH}/ic_system.svg`;
export const ICON_QRSCANNER = `${ICON_BASE_PATH}/ic_qrscanner.svg`;
export const ICON_SELECT = `${ICON_BASE_PATH}/ic_select.svg`;

export const ICON_EDIT = `${ICON_BASE_PATH}/ic_edit.svg`;
export const ICON_EDIT_BLUE = `${ICON_BASE_PATH}/ic_edit_blue.svg`;
export const ICON_COPY = `${ICON_BASE_PATH}/ic_copy.svg`;
export const ICON_TRASH2 = `${ICON_BASE_PATH}/ic_trash2.svg`;
export const ICON_ADD = `${ICON_BASE_PATH}/ic_add.svg`;

export const ICON_CHECK = `${ICON_BASE_PATH}/ic_check.svg`;
export const ICON_INDETERMINATE = `${ICON_BASE_PATH}/ic_indeterminate.svg`;

// Image
export const IMAGE_BG_LOGIN = `${LOGIN_BASE_PATH}/bg_login.png`;
export const IMAGE_ADD_PHOTO = `${IMAGE_BASE_PATH}/img_add_photo.png`;

// login
export const WEB_LOGIN_BG = `${LOGIN_BASE_PATH}/img_web_login_bg.png`;
export const WEB_LOGIN_LOGO = `${LOGIN_BASE_PATH}/img_web_login_logo.png`;
export const WEB_LOGIN_SLIDE_IMG1 = `${LOGIN_BASE_PATH}/img_web_login_slide_img1.png`;
