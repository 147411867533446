const LOGIN = "auth/LOGIN" as const;
const LOGOUT = "auth/LOGOUT" as const;

export const login = () => ({ type: LOGIN });
export const logout = () => ({ type: LOGOUT });

type AuthAction = ReturnType<typeof login> | ReturnType<typeof logout>;
type AuthState = { isLoggedIn: boolean };

const initialState: AuthState = { isLoggedIn: false };

function auth(state: AuthState = initialState, action: AuthAction) {
  switch (action.type) {
    case LOGIN:
      return { isLoggedIn: (state.isLoggedIn = true) };
    case LOGOUT:
      return { isLoggedIn: (state.isLoggedIn = false) };
    default:
      return state;
  }
}

export default auth;
