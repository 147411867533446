import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  IMAGE_EMPTY_IMAGE,
  IMAGE_EMPTY_IMAGE_CS,
  IMAGE_EMPTY_IMAGE_CHARGE,
  IMAGE_EMPTY_IMAGE_CAFE,
  IMAGE_EMPTY_IMAGE_PARTNERS,
  IMAGE_STATION_01,
} from "../../../constants/appImagePath";
import { APP_CONFIG } from "../../../constants/AppConfig";

interface SwiperPhotosProps {
  imgData: any;
  type: string;
}

const SwiperPhotos = ({ imgData, type }: SwiperPhotosProps) => {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-color"?: string;
    "--swiper-pagination-top"?: string;
    height?: string;
  };

  // Define the custom styles
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-color": "#5D38E5",
    "--swiper-pagination-top": "83%",
    height: "115px",
  };

  const handelEmptyImage = (type: string) => {
    switch (type) {
      case(APP_CONFIG.CAFE):
        return IMAGE_EMPTY_IMAGE_CAFE;
      case(APP_CONFIG.CHARGE):
        return IMAGE_EMPTY_IMAGE_CHARGE;
      case(APP_CONFIG.CS):
        return IMAGE_EMPTY_IMAGE_CS;
      case(APP_CONFIG.PARTNERS):
        return IMAGE_EMPTY_IMAGE_PARTNERS;
      default:
        return IMAGE_EMPTY_IMAGE;
    }
  }

  return (
    <>
      <Swiper
        style={swiperStyles}
        slidesPerView={1}
        height={100}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {imgData ? (
          imgData.map((data: any) => (
            <SwiperSlide>
              <img
                src={data}
                loading="lazy"
                style={{
                  width: "101px",
                  height: "91.19px",
                  borderRadius: 15
                }}
              />
            </SwiperSlide>
          ))
        ) : (
          <>
            <SwiperSlide>
              <img
                src={handelEmptyImage(type)}
                loading="lazy"
                style={{
                  width: "101px",
                  height: "91.19px",
                  borderRadius: 15
                }}
              />
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </>
  );
};
export default SwiperPhotos;