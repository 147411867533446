import { CouponManagementGrid } from "./component/CouponManagementGrid";
import { CouponManagementUiEvent } from "./model/CouponManagementUiEvent";
import { CouponManagementUiState } from "./model/CouponManagementUiState";

interface CouponManagementRenderProps {
    uiState: CouponManagementUiState;
    uiEvent: CouponManagementUiEvent;
}

const CouponManagementRender = ({uiState, uiEvent,}:CouponManagementRenderProps) => {
    return (
        <CouponManagementGrid 
        rows={uiState.couponDataRows}
        countryLists={uiState.countryList}
        uiState={uiState}
        uiEvent={uiEvent}
        />
    )
}

export default CouponManagementRender;