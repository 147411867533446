import React, { useState } from "react";
import { Box, Chip, InputBase, Paper, Stack } from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { PWUserAppMapUiEvent } from "../PWUserAppMapUiEvent";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { fontStyle } from "../../../theme/Style";
import {
  ICON_BELL,
  ICON_CAFE,
  ICON_CHARGE,
  ICON_CHARGE2,
  ICON_CHARGING_CLICK,
  ICON_CHARGING_COMMON,
  ICON_CS,
  ICON_PARTNERS2,
  ICON_QRSCAN,
} from "../../../constants/appImagePath";
import { color } from "../../../theme/Color";
import { PWUserAppMapUiState } from "../PWUserAppMapUiState";

interface PWUserAppMapSearchBoxProps {
  uiState: PWUserAppMapUiState;
  uiEvent: PWUserAppMapUiEvent;
}

const PWUserAppMapSearchBox = ({
  uiState,
  uiEvent,
}: PWUserAppMapSearchBoxProps) => {
  // const [selectedChip, setSelectedChip] = useState<string | null>(null);

  const handleChipClick = (label: string) => {
    // 이미 선택된 Chip을 다시 클릭하면 원상태로 초기화
    if (uiState.selectedChip === label) {
      uiEvent.SetSelectedChip("");
      uiEvent.SetSvcFilterType([]);
    } else {
      if (label == "Charging") {
        uiEvent.SetSelectedChip(label);
        uiEvent.SetSvcFilterType(["Station"]);
      } else if (label == "Customer Service") {
        uiEvent.SetSelectedChip(label);
        uiEvent.SetSvcFilterType(["CS Center"]);
      } else if (label == "Partners") {
        uiEvent.SetSelectedChip(label);
        uiEvent.SetSvcFilterType([
          "Hotel",
          "Restaurant",
          "Hot Spot",
          "Partners",
        ]);
      } else {
        uiEvent.SetSelectedChip(label);
        uiEvent.SetSvcFilterType([label]);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "fixed",
        zIndex: 10,
        width: "100%",
      }}
    >
      <VerticalSpacer height={44} />
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "calc(100% - 40px)",
          height: "52px",
          boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.2)",
          borderRadius: 2,
        }}
        elevation={1}
      >
        <InputBase
          sx={{ ml: 3, flex: 1 }}
          placeholder={LocalizedText("ua_c200_searchstation")}
          onClick={() => {
            uiEvent.SetIsClickSearchStation(true);
          }}
        />
      </Paper>

      <Box
        sx={{
          width: "100%",
          height: "60px",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none", // IE and Edge
          "scrollbar-width": "none", // Firefox
        }}
      >
        <Stack direction="row" spacing={1} sx={{ pl: "20px", mt: 1.5 }}>
          {["Charging", "Customer Service", "Cafe", "Partners"].map((label) => (
            <Paper
              key={label}
              elevation={1}
              sx={{
                borderRadius: "50px",
                height: "36px",
                boxShadow:
                  "0px 4px 4px 0px rgba(0,0,0,0.1), 0px 1px 2px 0px rgba(0,0,0,0.2)",
                border: uiState.selectedChip === label ? 1 : 0,
                borderColor: color.primary500,
                color:
                  uiState.selectedChip === label ? color.primary500 : "black",
              }}
            >
              <Box
                sx={{
                  mx: "16px",
                  height: "36px",
                  fontStyle: fontStyle.bodySr,
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap", // 줄바꿈 방지
                }}
                onClick={() => {
                  handleChipClick(label);
                }}
              >
                {label == "Charging" && (
                  <Box component="img" src={ICON_CHARGE2} />
                )}
                {label == "Customer Service" && (
                  <Box component="img" src={ICON_CS} />
                )}
                {label == "Cafe" && <Box component="img" src={ICON_CAFE} />}
                {label == "Partners" && (
                  <Box component="img" src={ICON_PARTNERS2} />
                )}
                <HorizontalSpacer width={2} />
                {label}
              </Box>
            </Paper>
          ))}
          <HorizontalSpacer width={12} />
        </Stack>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          px: "20px",
          boxSizing: "border-box",
        }}
      >
        {/* TODO: QRSCAN */}
        {/* <Paper
          elevation={3}
          sx={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow:
              "0px 4px 4px 0px rgba(0,0,0,0.1), 0px 1px 2px 0px rgba(0,0,0,0.2)",
            backgroundColor: color.white,
          }}
          onClick={() => {
            if (window.Android) {
              window.Android.openQRCamera();
            } else if (window.webkit) {
              window.webkit.messageHandlers.openQRCamera.postMessage(
                "openQRCamera"
              );
            } else {
              console.log("QRSCAN");
            }
          }}
        >
          <Box
            component="img"
            src={ICON_QRSCAN}
            alt="QR ICON"
            sx={{ width: "22px", height: "22px" }}
          />
        </Paper> */}
      </Box>
    </Box>
  );
};

export default PWUserAppMapSearchBox;
