import { Box, Typography } from "@mui/material";

interface ServiceProvideCardProps {
  content?: string;
  backGroundColor?: string;
  fontColor?: string;
}

const ServiceProvideCard = ({
  content,
  backGroundColor,
  fontColor,
}: ServiceProvideCardProps) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: backGroundColor,
          width: "auto",
          height: "18px",
          px: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
        }}
      >
        <Typography color={fontColor} fontSize={"10px"}>
          {content}
        </Typography>
      </Box>
    </>
  );
};

export default ServiceProvideCard;
