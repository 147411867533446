import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { ICON_QUESTION } from "../../../constants/imagePath";
import { HorizontalSpacer } from "../../../component/Spacer";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { ApiService } from "../../../restAPI/ApiService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { color } from "../../../theme/Color";

interface RowData {
  no: number;
  id: string;
  status: string;
  category: string;
  title: string;
  country: string;
  answerer: string;
  writer: string;
  answerDate: number;
  registrationDate: string;
}

interface RowProps {
  row: RowData;
  index: number;
}

const Row = ({ row, index }: RowProps) => {
  const [countryList, setCountryList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
    });
  }, []);

  const handleGetCountry = (country: string) => {
    for (let i = 0; i < countryList.length; ++i) {
      if (country == countryList[i]["code"]) {
        country = countryList[i]["label"];
      }
    }
    return country;
  };

  return (
    <>
      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/inquiryMng/inquiryDetails/${row.id}`);
        }}
      >
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Box fontStyle={fontStyle.regularXXS}>
            {row.status == "Waiting" && (
              <Box
                sx={{
                  width: "55px",
                  height: "18px",
                  borderRadius: "5px",
                  bgcolor: color.backgroundWarning2,
                  color: color.warning2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.status}
              </Box>
            )}
            {row.status == "Done" && (
              <Box
                sx={{
                  width: "43px",
                  height: "18px",
                  borderRadius: "5px",
                  bgcolor: color.backgroundSuccess,
                  color: color.success,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.status}
              </Box>
            )}
            {row.status == "Deleted" && (
              <Box
                sx={{
                  width: "49spx",
                  height: "18px",
                  borderRadius: "5px",
                  bgcolor: color.gray200,
                  color: color.gray700,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.status}
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.category}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.title}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {handleGetCountry(row.country)}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.answerer}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.writer}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.answerDate == 0 ? (
              <></>
            ) : (
              <>
                {LocalizedDate({
                  date: row.answerDate,
                  type: DateFormatType.AdminType,
                })}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registrationDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface InquiryMngTableProps {
  mngRows?: [];
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

const InquiryMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: InquiryMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },

    {
      id: "status",
      align: "left",
      disablePadding: false,
      label: "Status",
    },

    {
      id: "category",
      align: "left",
      disablePadding: false,
      label: "Category",
    },

    {
      id: "title",
      align: "left",
      disablePadding: false,
      label: "Title",
    },

    {
      id: "country",
      align: "left",
      disablePadding: false,
      label: "Country",
    },
    {
      id: "answerer",
      align: "left",
      disablePadding: false,
      label: "Answerer",
    },
    {
      id: "writer",
      align: "left",
      disablePadding: false,
      label: "Writer",
    },
    {
      id: "answerDate",
      align: "left",
      disablePadding: false,
      label: "Answer Date",
    },
    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Registration Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows || [], rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((row: { [x: string]: string | number; }, index: any) => (
        <Row row={row as unknown as RowData} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default InquiryMngTable;
