import { Box } from "@mui/material";
import { color } from "../theme/Color";
import { Text } from "./Text";
import CommonButton from "./CommonButton";
import { fontStyle } from "../theme/Style";
import { LocalizedText } from "../di/LanguageRepositoryProvider";
import { BannerModel } from "../pagesMobile/MobilePartners/model/BannerModel";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination } from "swiper/modules";
import { useState } from "react";

interface PopupAdvertisementDialogProps {
  data: BannerModel[];
  onPositive: (index: number) => void;
  onNegative: () => void;
  onClickSkipToday: () => void;
}

export const POPUP_ADVERTISEMENT_DIALOG_SKIP_TIME =
  "POPUP_ADVERTISEMENT_DIALOG_SKIP_TIME";

export default function PopupAdvertisementDialog({
  data,
  onPositive,
  onNegative,
  onClickSkipToday,
}: PopupAdvertisementDialogProps) {
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      {/* Dimmed background */}
      <Box
        sx={{
          position: "absolute",
          backgroundColor: color.black,
          opacity: 0.4,
          width: "100%",
          height: "100%",
        }}
      />

      {/* Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* '오늘 하루 보지 않기' text */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginBottom: "4px",
            zIndex: 1, // 이미지 위에 표시되도록 설정
          }}
          onClick={() => {
            localStorage.setItem(
              POPUP_ADVERTISEMENT_DIALOG_SKIP_TIME,
              Date.now().toString(),
            );
            onClickSkipToday();
          }}
        >
          <Text
            text={LocalizedText("ua_c100_popup_donotshow")}
            sx={{
              marginRight: "8px",
              fontStyle: fontStyle.titleS,
              color: color.gray100,
            }}
          />
        </Box>

        <Box
          sx={{
            position: "relative",
            width: "310px",
            borderRadius: "10px",
            overflow: "hidden",
            backgroundColor: color.white,
          }}
        >
          <Swiper
            navigation={false}
            style={{
              width: "310px",
              height: "310px",
            }}
            pagination={{
              type: "fraction",
              renderFraction: (currentClass: any, totalClass: any) => {
                return `<div style="
                            position: absolute;
                            right: 17px;
                            bottom: 8px;
                            height: 20px;
                            width:fit-content;
                            justify-content: center;
                            align-content: center;                             
                            background-color: ${color.black};
                            opacity: 0.4;
                            border-radius: 50px;
                            padding: 2px 8px;                           
                            font-style: ${fontStyle.bodySr};
                            color: ${color.gray300};
                          ">
                        <span class="${currentClass}"></span> / <span class="${totalClass}"></span>
                      </div>
                    `;
              },
            }}
            modules={[Pagination]}
            onSlideChange={(swiper: any) =>
              setCurrentVisibleIndex(swiper.activeIndex)
            }
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  src={item.image}
                  alt={`Promotion ${index + 1}`}
                  style={{
                    width: "310px",
                    height: "310px",
                    objectFit: "cover",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
              padding: "10px",
            }}
          >
            <CommonButton
              title={LocalizedText("ua_c100_popup_close_button")}
              buttonColor={color.gray300}
              textColor={color.white}
              radius={5}
              height={40}
              onClick={() => onNegative()}
            />
            <CommonButton
              title={LocalizedText("ua_c100_popup_viewdetail_button")}
              textColor={color.white}
              radius={5}
              height={40}
              onClick={() => onPositive(currentVisibleIndex)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
