import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Global, keyframes } from "@emotion/react";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { ICON_NAV_ARROW, IMAGE_STATION_01 } from "../../../constants/appImagePath";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination } from "swiper/modules";
import PWUserAppMapPhotoDetails from "../PWUserAppMapPhotoDetails";
import ServiceProvideCard from "../component/ServiceProvideCard";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import DrawerListCSMS from "../component/DrawerListCSMS";
import { PWUserAppMapUiState } from "../PWUserAppMapUiState";
import DrawerListCSCenter from "../component/DrawerListCSCenter";
import DrawerListOthers from "../component/DrawerListOthers";
import { PWUserAppMapUiEvent } from "../PWUserAppMapUiEvent";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import SimpleDialog from "../../../component/dialog/SimpleDialog";

interface PWUserBottomDrawerProps {
  open?: any;
  uiState: PWUserAppMapUiState;
  uiEvent: PWUserAppMapUiEvent;
}

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const slideUp = keyframes`
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  animation: `${slideUp} 0.4s ease-in-out`,
}));

const Puller = styled("div")(({ theme }) => ({
  width: 40,
  height: 4,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 4,
  left: "calc(50% - 20px)",
}));

const PWUserBottomDrawer = ({ uiEvent, uiState }: PWUserBottomDrawerProps) => {
  const [drawerBleeding, setDrawerBleeding] = useState(
    window.innerHeight * 0.4
  );
  const [isClicked, setIsClicked] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const svcData: any = uiState.drawerData;
  const svcType = svcData?.result || [];

  const svcTypeOrder: { [key: string]: number } = {
    "Charging Station": 1,
    "CS Center": 2,
    Cafe: 3,
    Partners: 4,
  };

  // 정렬된 svcData
  const sortedSvcData = svcType.sort((a: any, b: any) => {
    return (svcTypeOrder[a.svcType] || 0) - (svcTypeOrder[b.svcType] || 0);
  });

  // 중복을 제거한 새로운 배열 생성
  const uniqueSortedSvcData = sortedSvcData.reduce(
    (acc: any[], current: any) => {
      // 현재 항목의 svcType이 acc 배열에 존재하는지 확인
      if (!acc.some((item) => item.svcType === current.svcType)) {
        acc.push(current); // 중복이 없으면 추가
      }
      return acc;
    },
    []
  );

  // console.log("uniqueSortedSvcData is : ", uniqueSortedSvcData);

  const handleTouchStart = () => {
    timerRef.current = setTimeout(() => {
      setIsClicked(true);
    }, 500); // 2초 후에 isClicked를 true로 설정
  };

  const handleTouchEnd = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setIsClicked(false);
  };
  // This is used only for the example
  const container: HTMLElement | undefined = window !== undefined ? window.document.body : undefined;
  // ############################################## 딥링크 ##############################################
  const [deepLinkDialog, setDeepLinkDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let deepLinkLaunchTime = 0;
  let appCheckTimeout: any;
  let appOpened = false;

  function exeDeepLink() {
    setIsLoading(true);
    deepLinkLaunchTime = Date.now();
    appOpened = false;

    const ua = navigator.userAgent.toLowerCase();
    const url =
      ua.indexOf("android") > -1
        ? //saddr 내위치, daddr 도착위치, directionsmode 교통 수단 saddr=11.54759712794108, 104.92206649845805&
          `intent://maps.google.com/maps?daddr=${uiState.finalPosition.lat}, ${uiState.finalPosition.lng}&directionsmode=driving#Intent;scheme=http;package=com.google.android.apps.maps;end`
        : //saddr 내위치, daddr 도착위치, directionsmode 교통 수단 saddr=11.54759712794108, 104.92206649845805&
          `comgooglemaps://?daddr=${uiState.finalPosition.lat}, ${uiState.finalPosition.lng}&directionsmode=driving`;

    // 딥링크 실행
    window.location.href = url;

    // 타이머를 사용해 앱이 설치되지 않았을 경우 다이얼로그를 띄움
    appCheckTimeout = setTimeout(() => {
      const elapsed = Date.now() - deepLinkLaunchTime;
      if (!appOpened && elapsed >= 2000) {
        setIsLoading(false);
        setDeepLinkDialog(true); // 다이얼로그 표시
      }
    }, 2000);
  }

  // 앱이 열렸을 때 호출될 함수
  function onAppOpened() {
    appOpened = true; // 앱이 열렸음을 표시
    clearTimeout(appCheckTimeout); // 타이머 해제
    setIsLoading(false); // 로딩 종료
  }

  // visibilitychange 이벤트 리스너 추가
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      onAppOpened(); // 앱이 열린 것으로 간주
    } else {
      const elapsed = Date.now() - deepLinkLaunchTime;
      if (elapsed < 2000 && !appOpened) {
        setDeepLinkDialog(false); // 다이얼로그 숨김 (앱에서 돌아온 경우)
        setIsLoading(false); // 로딩 종료
      }
    }
  });

  // 스토어로 이동하는 함수
  function redirectToStore(isAndroid: any) {
    const url = isAndroid
      ? "market://details?id=com.google.android.apps.maps"
      : "https://apps.apple.com/kr/app/google-maps/id585027354";

    window.location.href = url;
  }
  // ############################################## 딥링크 ##############################################
  return (
    <Root sx={{}}>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: drawerBleeding * 0.85,
            // height: drawerBleeding * 2,
            overflow: "visible",
            bottom: "9%",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={uiState.drawerSwipeOpen}
        onClose={() => {
          uiEvent.HandleDrawerSwipeOpenChange(false);
        }}
        onOpen={() => {
          uiEvent.HandleDrawerSwipeOpenChange(true);
        }}
        swipeAreaWidth={drawerBleeding}
        hysteresis={0}
        disableSwipeToOpen={false}
        // allowSwipeInChildren={true}
        ModalProps={{
          // keepMounted: true,
          BackdropProps: {
            style: {
              backgroundColor: "transparent",
            },
          },
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Box
            sx={{
              mx: "16px",
              // mr: 1.5,
              mt: "18px",
              mb: 0,
              height: drawerBleeding * 1.77,
              // bgcolor: "blue",
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            onClick={() => {}}
          >
            <Box sx={{ display: "flex" }}>
              {uniqueSortedSvcData.map((data: any, index: any) => {
                if (data.svcType == "Station") {
                  return (
                    <Box key={index} sx={{ display: "flex" }}>
                      <ServiceProvideCard
                        key={data.svcId}
                        content="Charge"
                        fontColor={color.primary500}
                        backGroundColor={color.primary200}
                      />
                      <HorizontalSpacer width={4} />
                    </Box>
                  );
                }
                if (data.svcType == "CS Center") {
                  return (
                    <Box key={index} sx={{ display: "flex" }}>
                      <ServiceProvideCard
                        key={data.svcId}
                        content="CS"
                        fontColor={color.error}
                        backGroundColor={color.backgroundError}
                      />
                      <HorizontalSpacer width={4} />
                    </Box>
                  );
                }
                if (data.svcType == "Cafe") {
                  return (
                    <Box key={index} sx={{ display: "flex" }}>
                      <ServiceProvideCard
                        key={data.svcId}
                        content="Cafe"
                        fontColor={color.warning2}
                        backGroundColor={color.backgroundWarning2}
                      />
                      <HorizontalSpacer width={4} />
                    </Box>
                  );
                }
                if (
                  data.svcType == "Partners" ||
                  "hotSpot" ||
                  "hotel" ||
                  "restaurant"
                ) {
                  return (
                    <Box key={index} sx={{ display: "flex" }}>
                      <ServiceProvideCard
                        key={data.svcId}
                        content="Partners"
                        fontColor={color.progress}
                        backGroundColor={color.backgroundProgress}
                      />
                      <HorizontalSpacer width={4} />
                    </Box>
                  );
                }
              })}
            </Box>
            <VerticalSpacer height={3.5} />

            <Box>
              <Typography fontStyle={fontStyle.titleL}>
                {svcData.svcAreaNm}
              </Typography>
              <VerticalSpacer height={2} />
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "90%" }}>
                  <Typography fontSize={13} color={"#B3B3B3"}>
                    <Collapse in={isExpanded} timeout="auto">
                      {svcData.svcAreaLocation}
                    </Collapse>
                    {isExpanded == false && "Sangkat Slorkram (500m)"}
                  </Typography>
                </Box>
                <IconButton
                  sx={{ position: "absolute", right: 0, top: -10 }}
                  onClick={() => setIsExpanded(!isExpanded)}
                  disableRipple
                >
                  <KeyboardArrowDownOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#B3B3B3" }}
                    style={{
                      transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)", // 아이콘 회전 애니메이션
                    }}
                  />
                </IconButton>
              </Box>
              <VerticalSpacer height={10} />
              <Divider />
              <Box>
                {sortedSvcData.map((data: any, index: any) => {
                  if (data.svcType == "Station") {
                    return (
                      <DrawerListCSMS
                        data={data}
                        key={index}
                        setDetailsOpen={uiEvent.SetDetailsOpen}
                        uiEvent={uiEvent}
                      />
                    );
                  } else if (data.svcType == "CS Center") {
                    return (
                      <DrawerListCSCenter
                        data={data}
                        key={index}
                        setDetailsOpen={uiEvent.SetDetailsOpen}
                        uiEvent={uiEvent}
                      />
                    );
                  } else if (data.svcType == "Cafe") {
                    return (
                      <DrawerListOthers
                        data={data}
                        key={index}
                        setDetailsOpen={uiEvent.SetDetailsOpen}
                        uiEvent={uiEvent}
                      />
                    );
                  } else if (
                    data.svcType == "Partners" ||
                    "Hot Spot" ||
                    "Hotel" ||
                    "Restaurant"
                  ) {
                    return (
                      <DrawerListOthers
                        data={data}
                        key={index}
                        setDetailsOpen={uiEvent.SetDetailsOpen}
                        uiEvent={uiEvent}
                      />
                    );
                  }
                })}
              </Box>
            </Box>
          </Box>
        </StyledBox>
      </SwipeableDrawer>
      {/* 버튼 숨김 처리 */}
      <Box
        sx={{
          backgroundColor: "white",
          height: "9%",
          width: "100%",
          position: "fixed",
          zIndex: 1300,
          top: "91%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#5D38E5",
            borderRadius: 3,
            width: "92%",
            height: "70%",
            "&:hover": {
              backgroundColor: isClicked ? "#A38BFC" : "#5D38E5", // hover 시 배경색
            },
          }}
          onClick={() => {
            exeDeepLink();
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          disableRipple
        >
          <img src={ICON_NAV_ARROW} />
          <Typography
            id="12341234"
            sx={{
              color: "white",
              fontWeight: "500",
              ml: 1,
              textTransform: "none",
            }}
          >
            {LocalizedText("ua_d100_directions")}
          </Typography>
        </Button>
      </Box>
      {uiState.detailsOpen == true ? (
        <PWUserAppMapPhotoDetails
          setDetailsOpen={uiEvent.SetDetailsOpen}
          data={uiState.detailsSvcImageData}
        />
      ) : null}
      <SimpleDialog
        children={
          "Can not find Google Map application in your phone.\nGo to the Store on your phone?"
        }
        isOpen={deepLinkDialog}
        positiveText="OK"
        onPositive={() => {
          const ua = navigator.userAgent.toLowerCase();
          const isAndroid = ua.indexOf("android") > -1;

          redirectToStore(isAndroid); // OK 버튼을 누르면 스토어로 이동
        }}
        onNagative={() => {
          setDeepLinkDialog(false);
        }}
        onClose={() => {
          setDeepLinkDialog(false);
        }}
      />
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Root>
  );
};

export default PWUserBottomDrawer;
