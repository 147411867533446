// AppConfig.js

export const ADMIN_CONFIG = {
  MENU_TYPE: {
    COLLAPSE: "collapse",
    ITEM: "item",
    GROUP: "group",
  },

  // 통계
  MENU_STATISTICS: {
    TITLE: "Statistics",
    KEY: "statistics",
    URL: "",
    SHOW: true,
  },

  MENU_STATISTICS_STATISTICS: {
    TITLE: "Statistics",
    KEY: "statistics",
    URL: "/dashboard",
    SHOW: true,
  },

  // 사용자 관리
  MENU_USER_MANAGEMENT: {
    TITLE: "User Management",
    KEY: "userManagement",
    URL: "",
    SHOW: true,
  },

  // 회원 관리
  MENU_USER_MANAGEMENT_MEMBER: {
    TITLE: "Member",
    KEY: "member",
    URL: "/usrMng",
    SHOW: true,
  },

  // 관리자 관리
  MENU_USER_MANAGEMENT_ADMINISTRATOR: {
    TITLE: "Administrator",
    KEY: "administrator",
    URL: "/adminMng",
    SHOW: true,
  },

  // 그룹 관리
  MENU_USER_MANAGEMENT_GROUP: {
    TITLE: "Group",
    KEY: "group",
    URL: "/grpMng",
    SHOW: false,
  },

  // 컨텐츠 관리
  MENU_CONTENT: {
    TITLE: "Content",
    KEY: "content",
    URL: "",
    SHOW: true,
  },

  // FAQ 관리
  MENU_CONTENT_FAQ: {
    TITLE: "FAQ",
    KEY: "faq",
    URL: "/FAQMng",
    SHOW: true,
  },

  // 1:1 문의 관리
  MENU_CONTENT_INQUIRY: {
    TITLE: "1:1 Inquiry",
    KEY: "inquiry",
    URL: "/inquiryMng",
    SHOW: true,
  },

  // CS 관리
  MENU_CONTENT_CS: {
    TITLE: "CS",
    KEY: "cs",
    URL: "/csMng",
    SHOW: true,
  },

  // 공지 사항 관리
  MENU_CONTENT_NOTICE: {
    TITLE: "Notice",
    KEY: "notice",
    URL: "/announcement",
    SHOW: true,
  },

  // 푸시 알람 관리
  MENU_CONTENT_PUSH_NOTIFICATION: {
    TITLE: "Push Notification",
    KEY: "pushNotification",
    URL: "/pushMng",
    SHOW: false,
  },

  // 팝업 관리
  MENU_CONTENT_POPUP_BANNER: {
    TITLE: "Popup/Banner",
    KEY: "popupBanner",
    URL: "/popupMng",
    SHOW: true,
  },

  // 상품 관리
  MENU_MERCHANDISE: {
    TITLE: "Merchandise",
    KEY: "merchandise",
    URL: "/",
    SHOW: true,
  },

  // 멤버십 바우처 설정
  MENU_MERCHANDISE_MEMBERSHIP_VOUCHER_SETTINGS: {
    TITLE: "Membership Voucher Settings",
    KEY: "membershipVoucher",
    URL: "/membershipVoucherSetting",
    SHOW: true,
  },

  // NFT 설정
  MENU_MERCHANDISE_NFT_SETTINGS: {
    TITLE: "NFT Settings",
    KEY: "nft",
    URL: "/NFTManagement",
    SHOW: true,
  },

  // 쿠폰 설정
  MENU_MERCHANDISE_COUPON_SETTINGS: {
    TITLE: "Coupon Settings",
    KEY: "coupon",
    URL: "/couponManagement",
    SHOW: true,
  },

  // 결제 관리
  MENU_MERCHANDISE_PAYMENT: {
    TITLE: "Payment",
    KEY: "payment",
    URL: "/paymentManagement",
    SHOW: true,
  },

  // Production
  MENU_PRODUCTION_MATERIAL: {
    TITLE: "Production Material",
    KEY: "productionMaterial",
    URL: "",
    SHOW: true,
  },

  // 생산 관리
  MENU_PRODUCTION_MATERIAL_PRODUCTION: {
    TITLE: "Production",
    KEY: "production",
    URL: "/productionManagement",
    SHOW: true,
  },

  // 재고 관리
  MENU_PRODUCTION_MATERIAL_INVENTORY: {
    TITLE: "Inventory",
    KEY: "inventory",
    URL: "/inventoryManagement",
    SHOW: true,
  },

  // 요청 관리
  MENU_PRODUCTION_MATERIAL_REQUEST: {
    TITLE: "Request",
    KEY: "request",
    URL: "/requestManagement",
    SHOW: false,
  },

  // 모델 관리
  MENU_PRODUCTION_MATERIAL_PRODUCT_NAME: {
    TITLE: "Product Name",
    KEY: "productName",
    URL: "/modelManagement",
    SHOW: false,
  },

  // 운영 관리
  MENU_OPERATIONS: {
    TITLE: "Operations",
    KEY: "operations",
    URL: "",
    SHOW: true,
  },

  // 지역 및 서비스 관리
  MENU_OPERATIONS_AREA_AND_SERVICE: {
    TITLE: "Area and Service",
    KEY: "regionService",
    URL: "/regionAndServiceManagement",
    SHOW: true,
  },

  // 스쿠터 관리
  MENU_OPERATIONS_SCOOTER: {
    TITLE: "Scooter",
    KEY: "scooter",
    URL: "/vehicleMng",
    SHOW: true,
  },

  // 스테이션 관리
  MENU_OPERATIONS_STATION: {
    TITLE: "Station",
    KEY: "station",
    URL: "/stationMng",
    SHOW: true,
  },

  // 배터리 관리
  MENU_OPERATIONS_BATTERY: {
    TITLE: "Battery",
    KEY: "battery",
    URL: "/batteryMng",
    SHOW: true,
  },

  // NFC 카드 관리
  MENU_OPERATIONS_NFC_CARD: {
    TITLE: "NFC Card",
    KEY: "nfcCard",
    URL: "/nfcCardManagement",
    SHOW: true,
  },

  // 탄소감축량관리
  MENU_CARBON_REDUCTION: {
    TITLE: "Carbon Reduction",
    KEY: "carbonReduction",
    URL: "",
    SHOW: true,
  },

  // 방법론 관리
  MENU_CARBON_REDUCTION_METHODOLOGY: {
    TITLE: "Methodology",
    KEY: "methodology",
    URL: "/methodologyMng",
    SHOW: true,
  },

  // 프로젝트 관리
  MENU_CARBON_REDUCTION_PROJECT: {
    TITLE: "Project",
    KEY: "project",
    URL: "/projectMng",
    SHOW: true,
  },

  // 데이터/인자 관리
  MENU_CARBON_REDUCTION_DATA_PARAMETER: {
    TITLE: "Data/Parameter",
    KEY: "dataParameter",
    URL: "/paramDataMng",
    SHOW: true,
  },

  // 모니터링 관리
  MENU_CARBON_REDUCTION_MONITORING: {
    TITLE: "Monitoring",
    KEY: "monitoring",
    URL: "/monitoringMng",
    SHOW: true,
  },

  // 블록체인 관리
  MENU_BLOCKCHAIN: {
    TITLE: "Blockchain",
    KEY: "blockchain",
    URL: "",
    SHOW: true,
  },

  // 블록체인 모니터링
  MENU_BLOCKCHAIN_BLOCKCHAIN_MONITORING: {
    TITLE: "Blockchain Monitoring",
    KEY: "monitoring",
    URL: "/blockchainMonitoring",
    SHOW: true,
  },

  // 에코플랫폼 월렛
  MENU_BLOCKCHAIN_ECO_PLATFORM_WALLET: {
    TITLE: "Eco-Platform Wallet",
    KEY: "wallet",
    URL: "/wallet",
    SHOW: true,
  },

  // 시스템 관리
  MENU_SYSTEM_SETTING: {
    TITLE: "System Setting",
    KEY: "system",
    URL: "",
    SHOW: true,
  },

  // 알림 설정
  MENU_SYSTEM_SETTING_NOTIFICATION: {
    TITLE: "Notification",
    KEY: "notification",
    URL: "/notiMng",
    SHOW: false,
  },

  // 다국어 설정
  MENU_SYSTEM_SETTING_MULTI_LANGUAGE: {
    TITLE: "Multi-language",
    KEY: "multiLanguage",
    URL: "/multilingual",
    SHOW: true,
  },

  // 이력 관리
  MENU_SYSTEM_SETTING_HISTORY_MANAGEMENT: {
    TITLE: "History Management",
    KEY: "history",
    URL: "/historyMng",
    SHOW: false,
  },

  // 연계/배치 관리
  MENU_SYSTEM_SETTING_BATCH: {
    TITLE: "Batch",
    KEY: "batch",
    URL: "/linkBatchMng",
    SHOW: false,
  },

  // MQTT
  MENU_SYSTEM_SETTING_MQTT: {
    TITLE: "MQTT",
    KEY: "mqtt",
    URL: "",
    SHOW: false,
  },
};
