import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import CommonTextField from "../../../../component/CommonTextField";
import VerticalSpacer from "../../../../component/Spacer";
import { CommonSelect } from "../../../../component/CommonSelect";
import CommonIndeterminateCheckBox from "../../../../component/CommonIndeterminateCheckBox";
import LocalizedDate, { DateFormatType } from "../../../../component/LocalizedDate";
import CommonDatePicker from "../../../../component/CommonDatePicker";
import { MenuList } from "../../../../menu-items/MenuItems";
import { ADMIN_CONFIG } from "../../../../constants/AdminConfig";
import dayjs, { Dayjs } from "dayjs";

interface AdminDetailGridProps {
  adminDetailData: any;
  updateAdminDetailData: any;
}

const AdminDetailGrid = ({
  adminDetailData,
  updateAdminDetailData,
}: AdminDetailGridProps) => {
  const statisticsChildren = [ADMIN_CONFIG.MENU_STATISTICS_STATISTICS.KEY];
  const [statisticsChecked, setStatisticsChecked] = useState<boolean[]>(
    Array(statisticsChildren.length).fill(false)
  );

  const userManagementChildren = [
    ADMIN_CONFIG.MENU_USER_MANAGEMENT_MEMBER.KEY,
    ADMIN_CONFIG.MENU_USER_MANAGEMENT_ADMINISTRATOR.KEY,
//     ADMIN_CONFIG.MENU_USER_MANAGEMENT_GROUP.KEY,
  ];
  const [userManagementChecked, setUserManagementChecked] = useState<boolean[]>(
    Array(userManagementChildren.length).fill(false)
  );

  const contentChildren = [
    ADMIN_CONFIG.MENU_CONTENT_FAQ.KEY,
    ADMIN_CONFIG.MENU_CONTENT_INQUIRY.KEY,
    ADMIN_CONFIG.MENU_CONTENT_CS.KEY,
    ADMIN_CONFIG.MENU_CONTENT_NOTICE.KEY,
//     ADMIN_CONFIG.MENU_CONTENT_PUSH_NOTIFICATION.KEY,
    ADMIN_CONFIG.MENU_CONTENT_POPUP_BANNER.KEY,
  ];
  const [contentChecked, setContentChecked] = useState<boolean[]>(
    Array(contentChildren.length).fill(false)
  );

  const merchandiseChildren = [
    ADMIN_CONFIG.MENU_MERCHANDISE_MEMBERSHIP_VOUCHER_SETTINGS.KEY,
    ADMIN_CONFIG.MENU_MERCHANDISE_NFT_SETTINGS.KEY,
    ADMIN_CONFIG.MENU_MERCHANDISE_COUPON_SETTINGS.KEY,
    ADMIN_CONFIG.MENU_MERCHANDISE_PAYMENT.KEY,
  ];
  const [merchandiseChecked, setMerchandiseChecked] = useState<boolean[]>(
    Array(merchandiseChildren.length).fill(false)
  );

  const productionMaterialChildren = [
    ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCTION.KEY,
    ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_INVENTORY.KEY,
    ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_REQUEST.KEY,
    ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCT_NAME.KEY,
  ];
  const [productionMaterialChecked, setProductionMaterialChecked] = useState<
    boolean[]
  >(Array(productionMaterialChildren.length).fill(false));

  const operationsChildren = [
    ADMIN_CONFIG.MENU_OPERATIONS_AREA_AND_SERVICE.KEY,
    ADMIN_CONFIG.MENU_OPERATIONS_SCOOTER.KEY,
    ADMIN_CONFIG.MENU_OPERATIONS_STATION.KEY,
    ADMIN_CONFIG.MENU_OPERATIONS_BATTERY.KEY,
    ADMIN_CONFIG.MENU_OPERATIONS_NFC_CARD.KEY,
  ];
  const [operationsChecked, setOperationsChecked] = useState<boolean[]>(
    Array(operationsChildren.length).fill(false)
  );

  const carbonReductionChildren = [
    ADMIN_CONFIG.MENU_CARBON_REDUCTION_METHODOLOGY.KEY,
    ADMIN_CONFIG.MENU_CARBON_REDUCTION_PROJECT.KEY,
//     ADMIN_CONFIG.MENU_CARBON_REDUCTION_DATA_PARAMETER.KEY,
//     ADMIN_CONFIG.MENU_CARBON_REDUCTION_MONITORING.KEY,
  ];
  const [carbonReductionChecked, setCarbonReductionsChecked] = useState<
    boolean[]
  >(Array(carbonReductionChildren.length).fill(false));

  const blockchainChildren = [
    ADMIN_CONFIG.MENU_BLOCKCHAIN_BLOCKCHAIN_MONITORING.KEY,
    ADMIN_CONFIG.MENU_BLOCKCHAIN_ECO_PLATFORM_WALLET.KEY,
  ];
  const [blockchainChecked, setBlockchainChecked] = useState<boolean[]>(
    Array(blockchainChildren.length).fill(false)
  );

  const systemSettingChildren = [
//     ADMIN_CONFIG.MENU_SYSTEM_SETTING_NOTIFICATION.KEY,
    ADMIN_CONFIG.MENU_SYSTEM_SETTING_MULTI_LANGUAGE.KEY,
//     ADMIN_CONFIG.MENU_SYSTEM_SETTING_HISTORY_MANAGEMENT.KEY,
//     ADMIN_CONFIG.MENU_SYSTEM_SETTING_BATCH.KEY,
//     ADMIN_CONFIG.MENU_SYSTEM_SETTING_MQTT.KEY,
  ];
  const [systemSettingChecked, setSystemSettingChecked] = useState<boolean[]>(
    Array(systemSettingChildren.length).fill(false)
  );

  useEffect(() => {
    handleCheck(
      ADMIN_CONFIG.MENU_STATISTICS.KEY,
      statisticsChildren,
      statisticsChecked,
      setStatisticsChecked
    );
    handleCheck(
      ADMIN_CONFIG.MENU_USER_MANAGEMENT.KEY,
      userManagementChildren,
      userManagementChecked,
      setUserManagementChecked
    );
    handleCheck(
      ADMIN_CONFIG.MENU_CONTENT.KEY,
      contentChildren,
      contentChecked,
      setContentChecked
    );
    handleCheck(
      ADMIN_CONFIG.MENU_MERCHANDISE.KEY,
      merchandiseChildren,
      merchandiseChecked,
      setMerchandiseChecked
    );
    handleCheck(
      ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL.KEY,
      productionMaterialChildren,
      productionMaterialChecked,
      setProductionMaterialChecked
    );
    handleCheck(
      ADMIN_CONFIG.MENU_OPERATIONS.KEY,
      operationsChildren,
      operationsChecked,
      setOperationsChecked
    );
    handleCheck(
      ADMIN_CONFIG.MENU_CARBON_REDUCTION.KEY,
      carbonReductionChildren,
      carbonReductionChecked,
      setCarbonReductionsChecked
    );
    handleCheck(
      ADMIN_CONFIG.MENU_BLOCKCHAIN.KEY,
      blockchainChildren,
      blockchainChecked,
      setBlockchainChecked
    );
    handleCheck(
      ADMIN_CONFIG.MENU_SYSTEM_SETTING.KEY,
      systemSettingChildren,
      systemSettingChecked,
      setSystemSettingChecked
    );
  }, [adminDetailData]);

  // 상태가 실제로 바뀔 때만 setChecked 호출
  const handleCheck = (
    field: string,
    children: any[],
    currentChecked: boolean[],
    setChecked: React.Dispatch<React.SetStateAction<boolean[]>>
  ) => {
    if (adminDetailData?.[field]) {
      const newChecked = [...currentChecked];
      children.forEach((obj, index) => {
        if (adminDetailData[field].includes(obj)) {
          if (!newChecked[index]) {
            newChecked[index] = true; // 상태가 바뀌어야만 업데이트
          }
        } else {
          if (newChecked[index]) {
            newChecked[index] = false; // 상태가 바뀌어야만 업데이트
          }
        }
      });
      // 상태가 바뀐 경우에만 setChecked 호출
      if (
        !newChecked.every((value, index) => value === currentChecked[index])
      ) {
        setChecked(newChecked);
      }
    }
  };

  useEffect(() => {
    const statistics = statisticsChildren.filter(
      (label, index) => statisticsChecked[index]
    );
    const userManagement = userManagementChildren.filter(
      (label, index) => userManagementChecked[index]
    );
    const content = contentChildren.filter(
      (label, index) => contentChecked[index]
    );
    const merchandise = merchandiseChildren.filter(
      (label, index) => merchandiseChecked[index]
    );
    const productionMaterial = productionMaterialChildren.filter(
      (label, index) => productionMaterialChecked[index]
    );
    const operations = operationsChildren.filter(
      (label, index) => operationsChecked[index]
    );
    const carbonReduction = carbonReductionChildren.filter(
      (label, index) => carbonReductionChecked[index]
    );
    const blockchain = blockchainChildren.filter(
      (label, index) => blockchainChecked[index]
    );
    const systemSetting = systemSettingChildren.filter(
      (label, index) => systemSettingChecked[index]
    );

    updateAdminDetailData(ADMIN_CONFIG.MENU_STATISTICS.KEY, statistics);
    updateAdminDetailData(ADMIN_CONFIG.MENU_USER_MANAGEMENT.KEY, userManagement);
    updateAdminDetailData(ADMIN_CONFIG.MENU_CONTENT.KEY, content);
    updateAdminDetailData(ADMIN_CONFIG.MENU_MERCHANDISE.KEY, merchandise);
    updateAdminDetailData(ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL.KEY, productionMaterial);
    updateAdminDetailData(ADMIN_CONFIG.MENU_OPERATIONS.KEY, operations);
    updateAdminDetailData(ADMIN_CONFIG.MENU_CARBON_REDUCTION.KEY, carbonReduction);
    updateAdminDetailData(ADMIN_CONFIG.MENU_BLOCKCHAIN.KEY, blockchain);
    updateAdminDetailData(ADMIN_CONFIG.MENU_SYSTEM_SETTING.KEY, systemSetting);
  }, [
    statisticsChecked,
    userManagementChecked,
    contentChecked,
    merchandiseChecked,
    productionMaterialChecked,
    operationsChecked,
    carbonReductionChecked,
    blockchainChecked,
    systemSettingChecked,
  ]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
          key="left"
        >
          <CardContent sx={{ width: "100%", m: 2 }} key="left">
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Name
                </Typography>
                <CommonTextField
                  value={adminDetailData?.name || ""}
                  onChange={(event) => {
                    updateAdminDetailData("name", event.target.value);
                  }}
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Email ID
                </Typography>
                <CommonTextField
                  value={adminDetailData?.email || ""}
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  onChange={(event) => {
                    updateAdminDetailData("email", event.target.value);
                  }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Phone Number
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <CommonSelect
                    selectValue={adminDetailData?.countryNumber || ""}
                    menuitems={["1", "82", "855"]}
                    onChange={(event) => {
                      updateAdminDetailData(
                        "countryNumber",
                        event.target.value
                      );
                    }}
                    sx={{
                      height: "48px",
                      width: "18.5%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />

                  <CommonTextField
                    value={adminDetailData?.phoneNumber || ""}
                    onChange={(event) => {
                      updateAdminDetailData("phoneNumber", event.target.value);
                    }}
                    sx={{ width: "78.5%", height: "48px" }}
                    InputProps={{
                      style: {
                        height: "48px",
                      },
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={16} />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6}>
        {/* 상단 오른쪽 카드 */}
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
          }}
          key="right"
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Status
                </Typography>
                <CommonSelect
                  key={adminDetailData.status}
                  selectValue={adminDetailData.status}
                  menuitems={["Active", "Blocked", "Deleted"]}
                  onChange={(event) => {
                    updateAdminDetailData("status", event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  // disabled
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Type
                  </Typography>

                  <CommonSelect
                    key={adminDetailData.type}
                    selectValue={adminDetailData.type}
                    menuitems={["Administrator", "Partner"]}
                    onChange={(event) => {
                      updateAdminDetailData("type", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                      mt: "8px",
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Role
                  </Typography>

                  <CommonSelect
                    key={adminDetailData.role}
                    selectValue={adminDetailData.role}
                    menuitems={["Normal", "Lead"]}
                    onChange={(event) => {
                      updateAdminDetailData("role", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                      mt: "8px",
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
              </Box>
              {/* 2024/11/19 숨김처리 추후 기획 office와 group 정해지면 적용 */}
              {/* <VerticalSpacer height={16} />
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Office
                </Typography>

                <CommonSelect
                  key={adminDetailData?.office}
                  selectValue={adminDetailData?.office}
                  menuitems={[]}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "LocalNumber",
                    //   event.target.value
                    // );
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Group
                </Typography>

                <CommonSelect
                  key={adminDetailData?.group}
                  selectValue={adminDetailData?.group}
                  menuitems={[]}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "LocalNumber",
                    //   event.target.value
                    // );
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box> */}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 140,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title="Role"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Statistics"
                  childLabels={statisticsChildren}
                  checked={statisticsChecked}
                  setChecked={setStatisticsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="User Management"
                  childLabels={userManagementChildren}
                  checked={userManagementChecked}
                  setChecked={setUserManagementChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel={ADMIN_CONFIG.MENU_CONTENT.TITLE}
                  childLabels={contentChildren}
                  checked={contentChecked}
                  setChecked={setContentChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Merchandise"
                  childLabels={merchandiseChildren}
                  checked={merchandiseChecked}
                  setChecked={setMerchandiseChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Production Material"
                  childLabels={productionMaterialChildren}
                  checked={productionMaterialChecked}
                  setChecked={setProductionMaterialChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Operations"
                  childLabels={operationsChildren}
                  checked={operationsChecked}
                  setChecked={setOperationsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Carbon Reduction"
                  childLabels={carbonReductionChildren}
                  checked={carbonReductionChecked}
                  setChecked={setCarbonReductionsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Blockchain"
                  childLabels={blockchainChildren}
                  checked={blockchainChecked}
                  setChecked={setBlockchainChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="System Setting"
                  childLabels={systemSettingChildren}
                  checked={systemSettingChecked}
                  setChecked={setSystemSettingChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title="Details"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Birth of Date</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonDatePicker
                      value={
                        adminDetailData?.birthOfDate !== null
                          ? dayjs(adminDetailData.birthOfDate)
                          : null
                      }
                      onChange={(newDate: Dayjs | null) => {
                        updateAdminDetailData(
                          "birthOfDate",
                          newDate?.valueOf()
                        );
                      }}
                      views={["year", "month", "day"]}
                      textFieldSx={{ width: "100%" }}
                      InputPropsSx={{ height: "48px" }}
                      disabled={adminDetailData.status == "Deleted"}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Gender</Typography>
                  <CommonSelect
                    selectValue={adminDetailData?.gender || ""}
                    menuitems={["Male", "Female"]}
                    onChange={(event) => {
                      updateAdminDetailData("gender", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Address</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonSelect
                      selectValue={adminDetailData?.addressCountry}
                      menuitems={["Cambodia"]}
                      onChange={(event) => {
                        updateAdminDetailData(
                          "addressCountry",
                          event.target.value
                        );
                      }}
                      sx={{
                        height: "48px",
                        width: "48.5%",
                        mt: "8px",
                        borderRadius: "10px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                      disabled={adminDetailData.status == "Deleted"}
                    />

                    <CommonSelect
                      selectValue={adminDetailData?.addressProvince}
                      menuitems={[
                        "Siem Reap",
                        "Battambang",
                        "Banteay Meanchey",
                        "Phnom Penh",
                        "Kandal",
                        "Sihanoukville",
                        "Oddar Meanchey",
                        "Pursat",
                        "Kampong Thom",
                        "Kampong Speu",
                        "Svay Rieng",
                        "Takéo",
                        "Svay Rieng",
                        "Kampong Chhnang",
                        "Kampong Cham",
                        "Pailin",
                        "Prey Veng",
                        "Tboung Khmum",
                        "Kampot",
                        "Ratanakiri",
                        "Koh Kong",
                        "Kratié",
                        "Preah Vihear",
                        "Mondulkiri",
                        "Kep",
                        "Sihanoukville",
                        "other",
                      ]}
                      onChange={(event) => {
                        updateAdminDetailData(
                          "addressProvince",
                          event.target.value
                        );
                      }}
                      sx={{
                        height: "48px",
                        width: "48.5%",
                        mt: "8px",
                        borderRadius: "10px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                      disabled={adminDetailData.status == "Deleted"}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "48.5%",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <CommonTextField
                    value={adminDetailData?.addressDetail}
                    onChange={(event) => {
                      updateAdminDetailData(
                        "addressDetail",
                        event.target.value
                      );
                    }}
                    sx={{
                      width: "100%",
                      mt: "8px",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                      },
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
              </Box>

              <VerticalSpacer height={40} />

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Registration Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: adminDetailData?.registrationDt,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Last Modified Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: adminDetailData?.lastModifiedDt,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AdminDetailGrid;
