import { Box, Card, Grid } from "@mui/material";
import CommonList from "../../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../../component/CommonWebHeaderButton";
import { color } from "../../../../../../theme/Color";
import { RegisterFindParts } from "./RegisterFindParts";
import { RegisterBillOfMaterialTree } from "./RegisterBillOfMaterialTree";
import VerticalSpacer from "../../../../../../component/Spacer";

export function RegisterBillOfMaterials() {
  return (
    <CommonList
      title="자재 명세 등록"
      headerBackButton={true}
      navigateURL="/materialRegistration"
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Register"
          height="45px"
          sx={{ display: "flex", flexDirection: "column" }}
        />
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <RegisterFindParts />
          <VerticalSpacer height={24} />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CommonWebHeaderButton
              buttonContent="Add to List"
              addIcon
              height="36px"
              iconFontSize="18px"
            />
          </Box>
          <VerticalSpacer height={40} />
          <RegisterBillOfMaterialTree />
        </Card>
      </Grid>
    </CommonList>
  );
}
