import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MoreChargingMembershipUiState } from "./MoreChargingMembershipUiState";
import MoreChargingMembershipRender from "./MoreChargingMembershipRender";
import { MoreChargingMembershipSideEffect } from "./MoreChargingMembershipSideEffect";
import { ApiService } from "../../restAPI/ApiService";
import ProgressBar from "../../component/ProgressBar";

const MoreChargingMembershipPage = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new MoreChargingMembershipUiState());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setUiState((prev) => {
      return {
        ...prev,
        userUUID: localStorage.getItem("uuid") || "",
      };
    });
  }, []);

  useEffect(() => {
    if (uiState.userUUID !== "") {
      (async () => {
        try {
          await checkMembership(uiState.userUUID);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [uiState.userUUID]);

  const sideEffectHandlers: MoreChargingMembershipSideEffect = {
    navigate: function (target: string, options?: { state: any }): void {
      console.log("navigate", target);
      navigate(target, options);
    },
  };

  {
    /* 멤버십 존재 여부 확인 */
  }

  async function checkMembership(uuid: string) {
    const url = "/pw/ua/product/MV/isValid";
    const requestBody = {
      uuid: uuid,
    };
    try {
      await ApiService.MobilePost(url, requestBody).then((res) => {
        console.log("checkMembership response", res);
        if (res?.data) {
          setUiState((prev) => {
            return {
              ...prev,
              hasMembership: !res.data.isFirst,
            };
          });
        }
      });
    } catch (e) {
      console.log("failed to get membership", e);
    }
  }

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <>
      <MoreChargingMembershipRender
        sideEffect={sideEffectHandlers}
        uiState={uiState}
      />
    </>
  );
};

export default MoreChargingMembershipPage;
