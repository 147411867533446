import { Box, Card, Grid } from "@mui/material";
import CommonList from "../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";
import { ProductConfirmationList } from "./ProductConfirmationList";
import VerticalSpacer from "../../../../../component/Spacer";
import { ProductBOMTree } from "./ProductBOMTree";
import { useState } from "react";
import { SelectedProduct } from "./SelectedProduct";

const ProductionRegistration = () => {
  const [selectValue, setSelectValue] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectValue(event.target.value);
  };
  return (
    <CommonList
      title="생산 등록"
      headerBackButton={true}
      navigateURL="/productionManagement"
      headerButton={
        <CommonWebHeaderButton
          width="96px"
          height="45px"
          buttonContent="Register"
          sx={{ display: "flex", flexDirection: "column" }}
        />
      }
    >
      <VerticalSpacer height={16} />
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <ProductConfirmationList
            selectValue={selectValue}
            onchange={handleChange}
          />
          <ProductBOMTree selectValue={selectValue} />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <SelectedProduct/>
        </Card>
      </Grid>
    </CommonList>
  );
};

export default ProductionRegistration;
