import { LinearProgress, LinearProgressProps, styled } from "@mui/material";
import { color } from "../theme/Color";

interface LinearProgressBarProps extends LinearProgressProps {
  value: number; // 현재 진행 값
  max?: number; // 최대 값
  height?: number; // progressbar의 높이
  borderRadius?: number; // progressbar의 border radius
  backgroundColor?: string; // progressbar의 배경색
  barColor?: string; // progressbar의 색
}

export default function LinearProgressBar({
  value,
  max = 100,
  height = 8,
  borderRadius = 8,
  backgroundColor = color.gray200,
  barColor = "white",
  ...props
}: LinearProgressBarProps) {

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height,
    borderRadius,
    backgroundColor,
    [`& .MuiLinearProgress-bar`]: {
      borderRadius,
      backgroundColor: barColor,
    },
  }));

  return (
    <BorderLinearProgress
      variant="determinate"
      value={(value / max) * 100}
      {...props}
    />
  );
}
