export class CustomerSupportStationUiModel {
  preferredStationId: number;
  serviceAreaName: string;
  openTime: string;
  closeTime: string;

  constructor(
    preferredStationId: number,
    serviceAreaName: string,
    openTime: string,
    closeTime: string,
  ) {
    this.preferredStationId = preferredStationId;
    this.serviceAreaName = serviceAreaName;
    this.openTime = openTime;
    this.closeTime = closeTime;
  }
}
