export class RegionManagementData {
  no: number;
  name: string;
  stateProvinceRegion: string;
  city: string;
  country: string;
  service: RegionManagementServiceType[];
  lastUpdateDate: number;
  registerDate: number;

  constructor(
    no: number,
    name: string,
    stateProvinceRegion: string,
    city: string,
    country: string,
    service: RegionManagementServiceType[],
    lastUpdateDate: number,
    registerDate: number
  ) {
    this.no = no;
    this.name = name;
    this.stateProvinceRegion = stateProvinceRegion;
    this.city = city;
    this.country = country;
    this.service = service;
    this.lastUpdateDate = lastUpdateDate;
    this.registerDate = registerDate;
  }
}

export const enum RegionManagementServiceType {
  CHARGING = "Station",
  CS_CENTER = "CS Center",
  CAFE = "Cafe",
  RESTAURANT = "Restaurant",
  HOTSPOT = "Hot Spot",
  HOTEL = "Hotel",
  CLOSURE = "Closure",
}
