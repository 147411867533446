import { useLocation } from "react-router-dom";
import useAndroidBackHandler from "../../../../customHook/useAndroidBackHandler";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../../restAPI/ApiService";
import { NoticeDetail } from "./model/NoticeDetail";
import NoticeDetailRender from "./NoticeDetailRender";
import { NoticeDetailUiState } from "./model/NoticeDetailUiState";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import ProgressBar from "../../../../component/ProgressBar";

export default function NoticeDetailPage() {
  const [uiState, setUiState] = useState(
    new NoticeDetailUiState("", "", [], ""),
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [noticeDetail, setNoticeDetail] = useState<NoticeDetail | undefined>(
    undefined,
  );
  const location = useLocation();
  const requestBody = location.state as { id: string };

  useEffect(() => {
    const convertTimeToString = LocalizedDate({
      date: noticeDetail?.registrationDate || 0,
      type: DateFormatType.YearMonthDay,
    });
    setUiState((prev) => {
      return {
        ...prev,
        title: noticeDetail?.title || "",
        content: noticeDetail?.content || "",
        imageUrls: noticeDetail?.imageUrls || [],
        time: convertTimeToString,
      };
    });
  }, [noticeDetail]);

  useEffect(() => {
    const body = {
      id: requestBody.id, // 동적으로 받아온 id를 사용
      uuid: localStorage.getItem("uuid") ?? "",
    };

    console.log("requestBody.id :", requestBody.id);

    // API 요청
    const fetchData = async () => {
      try {
        const response = await ApiService.MobilePost(
          "/pw/ua/contents/announce/detail",
          body,
        );
        const data = response?.data?.body;
        // 에러 던짐
        if (data) {
          const noticeDetails = data as NoticeDetail;
          setNoticeDetail(noticeDetails);
          setLoading(false);
        }
      } catch (e) {
        setError("Failed to load data.");
        console.error(e);
      }
    };

    fetchData();
  }, [requestBody.id]);

  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  if (loading) {
    return <ProgressBar />;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return <NoticeDetailRender uiState={uiState} />;
}
