import { Box } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { InquiryHistoryStatus } from "../model/InquiryHistoryStatus";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { Text } from "../../../component/Text";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { CSHistoryStatus } from "../../MobileCustomerSupport/list/model/CSHistoryStatus";

interface InquiryHistoryItemProps {
  status: InquiryHistoryStatus | CSHistoryStatus;
  title: string;
  category: string;
  registrationDate: number;
  onClick: () => void;
}

export default function InquiryHistoryItem({
  status,
  title,
  category,
  registrationDate,
  onClick,
}: InquiryHistoryItemProps) {
  const statusColor = isInquiryHistoryStatus(status)
    ? getInquiryStatusColor(status)
    : getCSStatusColor(status);

  return (
    <Box sx={{}} onClick={onClick}>
      <Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          <Text
            sx={{
              fontStyle: fontStyle.titleS,
              color: statusColor,
              flexShrink: 0,
            }}
            text={status.toString()}
          />

          <HorizontalSpacer width={8} />
          <Box
            sx={{
              minWidth: "1px",
              height: "100%",
              py: "4px",
              backgroundColor: color.gray300,
            }}
          />
          <HorizontalSpacer width={8} />
          <Text
            sx={{
              fontStyle: fontStyle.bodySr,
              color: color.gray700,
            }}
            text={category}
          />
          <Box
            sx={{
              flexGrow: 1,
            }}
          />
          <HorizontalSpacer width={8} />
          <Text
            sx={{
              fontStyle: fontStyle.bodySr,
              color: color.gray600,
              flexShrink: 0,
            }}
            text={LocalizedDate({
              date: registrationDate,
              type: DateFormatType.YearMonthDay,
            })}
          />
        </Box>
        <HorizontalSpacer width={8} />
      </Box>
      <VerticalSpacer height={8} />
      <Text
        sx={{
          fontStyle: fontStyle.titleL,
          color: color.default,
        }}
        text={title}
      />
    </Box>
  );
}

function isInquiryHistoryStatus(
  status: InquiryHistoryStatus | CSHistoryStatus,
): status is InquiryHistoryStatus {
  return Object.values(InquiryHistoryStatus).includes(
    status as InquiryHistoryStatus,
  );
}

export function getInquiryStatusColor(status: InquiryHistoryStatus) {
  switch (status) {
    case InquiryHistoryStatus.WAITING:
      return color.warning2;
    case InquiryHistoryStatus.DONE:
      return color.success;
    default:
      return color.default;
  }
}

export function getCSStatusColor(status: CSHistoryStatus) {
  switch (status) {
    case CSHistoryStatus.COMPLETE:
      return color.success;
    case CSHistoryStatus.RECEIPT:
      return color.progress;
    case CSHistoryStatus.RESERVATION:
      return color.warning2;
    default:
      return color.default;
  }
}
