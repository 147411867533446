import { Box, Typography } from "@mui/material";
import Toolbar from "../../../component/Toolbar";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { useState } from "react";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import VerticalSpacer from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import EditPhoneNumberField from "../../MobileLogin/component/EditPhoneNumberField";
import CommonButton from "../../../component/CommonButton";
import { color } from "../../../theme/Color";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import { PhoneAuthPageUiEvent } from "./model/PhoneAuthPageUiEvent";
import { PhoneAuthPageUiState } from "./model/PhoneAuthPageUiState";
import { PhoneAuthPageSideEffect } from "./model/PhoneAuthPageSideEffect";
import ToastAlert from "../../../component/ToastAlert";

interface PhoneAuthPageRenderProps {
  uiState: PhoneAuthPageUiState;
  uiEvent: PhoneAuthPageUiEvent;
  sideEffect: PhoneAuthPageSideEffect;
}

const PhoneAuthPageRender = ({
  uiState,
  uiEvent,
  sideEffect,
}: PhoneAuthPageRenderProps) => {
  const [timeOverDialogOpen, setTimeOverDialogOpen] = useState(false);

  useAndroidBackHandler(() => {
    console.log("android Back!");
    if (uiState.isShownExitPageDialog) {
      uiEvent.changeExitPageDialog(false);
    } else if (uiState.isShownAuthFailDialog) {
      uiEvent.changeAuthCodeRequestFailDialogState(false);
    } else if (uiState.isShownAuthCodeValidateFailDialog) {
      uiEvent.changeAuthCodeValidateFailDialogState(false);
    } else {
      uiEvent.changeExitPageDialog(true);
    }
  }, [
    uiState.isShownExitPageDialog,
    uiState.isShownAuthFailDialog,
    uiState.isShownAuthCodeValidateFailDialog,
  ]);

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title=""
            onBack={() => {
              uiEvent.changeExitPageDialog(true);
            }}
          />
        </Box>
        <VerticalSpacer height={20} />
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "calc(100vh - 40px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontStyle={fontStyle.headingS}>
            {LocalizedText("ua_b123_phonenumber")}
          </Typography>
          <VerticalSpacer height={16} />
          <EditPhoneNumberField
            type="Phone"
            authCode={uiState.inputAuthNumber}
            setAuthCode={(it) => {
              uiEvent.changeAuthText(it);
            }}
            authCodeError={uiState.authCodeError}
            timeRemaining={uiState.timer}
            setTimeRemaining={(it) => {
              uiEvent.setTimer(it);
            }}
            localNumberError={uiState.localNumberError}
            phoneNumberError={uiState.phoneNumberError}
            localNumber={uiState.localNumber}
            phoneNumber={uiState.inputPhoneNumber}
            handleLocaleNumberChange={(value) => {
              uiEvent.changeLocalNumber(value);
            }}
            handlePhoneNumberChange={(it) => {
              uiEvent.changePhoneNumber(it);
            }}
            onClickSendButton={() => {
              if (!uiState.isTimerInProgress) {
                uiEvent.onClickSendButton();
              }
            }}
            changeLocalNumberError={(it) => {
              uiEvent.changeLocalNumberError(it);
            }}
            changePhoneNumberError={(it) => {
              uiEvent.changePhoneNumberError(it);
            }}
            isTimerInProgress={uiState.isTimerInProgress}
          />
        </Box>

        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            sx={{
              mx: "20px",
              mb: "14px",
            }}
            disabled={!uiState.isTimerInProgress}
            title={LocalizedText("ua_b120_next_button")}
            textColor={color.white}
            onClick={() => {
              if (uiState.localNumber == "") {
                uiEvent.changeLocalNumberError(true);
              }
              if (uiState.inputPhoneNumber == "") {
                uiEvent.changePhoneNumberError(true);
              }

              if (uiState.localNumber != "" || uiState.inputPhoneNumber != "") {
                if (uiState.localNumber != "") {
                  uiEvent.changeLocalNumberError(false);
                }
                if (uiState.inputPhoneNumber != "") {
                  uiEvent.changePhoneNumberError(false);
                }
                if (
                  uiState.localNumber != "" &&
                  uiState.inputPhoneNumber != ""
                ) {
                  if (uiState.isTimerInProgress) {
                    if (uiState.inputAuthNumber == "") {
                      uiEvent.changeAuthCodeNumberError(true);
                    } else {
                      uiEvent.changeAuthCodeNumberError(false);
                      if (uiState.timer == 0) {
                        setTimeOverDialogOpen(true);
                      } else {
                        sideEffect.validateAuthCode(
                          uiState.localNumber,
                          uiState.inputPhoneNumber,
                          uiState.inputAuthNumber,
                        );
                      }
                    }
                  }
                }
              }
            }}
          />
        </Box>
      </Box>
      <SimpleDialog
        children={LocalizedText("common_alert_goprevious")}
        isOpen={uiState.isShownExitPageDialog}
        positiveText="OK"
        onPositive={() => {
          window.history.back();
        }}
        onNagative={() => {
          uiEvent.changeExitPageDialog(false);
        }}
        onClose={() => {
          uiEvent.changeExitPageDialog(false);
        }}
      />
      <SimpleDialog
        children={LocalizedText("ua_b123_newcode")}
        isOpen={timeOverDialogOpen}
        positiveText="OK"
        onPositive={() => {
          setTimeOverDialogOpen(false);
        }}
        onClose={() => {
          setTimeOverDialogOpen(false);
        }}
      />

      <SimpleDialog
        children={"Please check the Phone Number."}
        isOpen={uiState.isShownAuthFailDialog}
        positiveText="OK"
        onPositive={() => {
          uiEvent.changeAuthCodeRequestFailDialogState(false);
        }}
        onClose={() => {
          uiEvent.changeAuthCodeRequestFailDialogState(false);
        }}
      />

      <SimpleDialog
        children={LocalizedText("ua_b123_alert_checkcode")}
        isOpen={uiState.isShownAuthCodeValidateFailDialog}
        positiveText="OK"
        onPositive={() => {
          uiEvent.changeAuthCodeValidateFailDialogState(false);
        }}
        onClose={() => {
          uiEvent.changeAuthCodeValidateFailDialogState(false);
        }}
      />

      <ToastAlert
        open={uiState.isShownAuthCodeSuccessRequestToast}
        message={LocalizedText("ua_b123_toast_codesent")}
        onClose={() => {
          uiEvent.showAndHideToast(false);
        }}
        mainColor={color.success}
        bottomOffset={75}
      />
    </>
  );
};

export default PhoneAuthPageRender;
