import React, { useState } from "react";
import { Box, Button, Dialog } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { color } from "../theme/Color";
import { ICON_X_CIRCLE_GRAY } from "../constants/appImagePath";
import { fontStyle } from "../theme/Style";
import VerticalSpacer from "./Spacer";
import { Text } from "./Text";

// TODO 파일 최대 사이즈 제한기능 추가 필요
interface ImageUploadProps {
  imageUrls: string[];
  maxImages: number;
  maxFileSizeMB: number;
  onClickAddImage?: () => void;
  onClickAddImageFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeImage: (index: number) => void;
}

export default function ImageUpload({
  imageUrls,
  maxImages,
  maxFileSizeMB,
  onClickAddImage,
  onClickAddImageFile,
  removeImage,
}: ImageUploadProps) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleAddImageClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
      {imageUrls.map((image, index) => (
        <Box
          key={index}
          sx={{
            position: "relative",
            width: "60px",
            height: "60px",
            borderRadius: "5px",
            border: `1px solid ${color.gray200}`,
            overflow: "hidden",
          }}
        >
          <img
            src={`data:image/png;base64,${image}`}
            alt={`Uploaded ${index}`}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />

          <img
            src={ICON_X_CIRCLE_GRAY}
            alt="Remove"
            style={{
              position: "absolute",
              top: "1px",
              right: "1px",
              width: "16px",
              height: "16px",
            }}
            onClick={() => {
              removeImage(index);
            }}
          />
        </Box>
      ))}

      {imageUrls.length < maxImages && (
        <Box
          sx={{
            width: "60px",
            height: "60px",
            borderRadius: "5px",
            border: `1px solid ${color.gray200}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: color.gray50,
          }}
          onClick={() => {
            console.log("클릭");
            if (window.webkit) {
              if (onClickAddImage) {
                onClickAddImage();
              }
            } else {
              handleAddImageClick();
            }
          }}
        >
          <AddAPhotoIcon sx={{ color: color.gray500 }} />
        </Box>
      )}
      <div>
        <ImageUploadDialog
          isOpen={isDialogOpen}
          onClose={handleDialogClose}
          onCameraClick={(e) => {
            if (window.Android) {
              if (onClickAddImage) {
                onClickAddImage();
              }
            } else if (!window.Android && !window.webkit) {
              if (onClickAddImageFile) {
                onClickAddImageFile(e);
              }
            }
            handleDialogClose();
          }}
          onAlbumClick={() => {
            window.Android.openGallery();
          }}
        />
      </div>
    </Box>
  );
}

interface ImageUploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCameraClick: (e: any) => void;
  onAlbumClick: () => void;
}

const ImageUploadDialog: React.FC<ImageUploadDialogProps> = ({
  isOpen,
  onClose,
  onCameraClick,
  onAlbumClick,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "280px",
          padding: "24px 20px",
          borderRadius: "12px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text
          sx={{
            fontStyle: fontStyle.titleL,
            color: color.default,
          }}
          text="Upload Photo"
        />
        <VerticalSpacer height={20} />
        <Button
          variant="outlined"
          sx={{
            height: "49px",
            fontStyle: fontStyle.titleL,
            color: color.primary500,
            borderColor: color.primary500,
            borderRadius: "10px",
            padding: "12px",
          }}
          onClick={() => {
            if (window.Android) {
              // Android 환경에서는 카메라 기능 호출
              onCameraClick(undefined);
              onClose();
            }
          }}
          component={window.Android ? "button" : "label"} // Android가 아니면 파일 선택 기능을 위한 label로 사용
        >
          {window.Android ? "camera" : "file"}
          {!window.Android && (
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                onCameraClick(e);
                onClose();
              }}
              hidden
            />
          )}
        </Button>
        {/*  TODO 현재 안드로이드에서 앨범 선택 기능 구현되지 않아, false 값으로 지정합니다. */}
        {window.Android ? <VerticalSpacer height={8} /> : null}
        {window.Android ? (
          <Button
            variant="outlined"
            sx={{
              height: "49px",
              fontStyle: fontStyle.titleL,
              color: color.primary500,
              borderColor: color.primary500,
              borderRadius: "10px",
              padding: "12px",
            }}
            onClick={() => {
              onAlbumClick();
              onClose();
            }}
          >
            Album
          </Button>
        ) : null}
      </Box>
      <VerticalSpacer height={20} />
      <Button
        onClick={onClose}
        sx={{
          fontStyle: fontStyle.titleL,
          color: color.default,
        }}
      >
        Cancel
      </Button>
    </Dialog>
  );
};
