import MoreProfileRender from "./MoreProfileRender";
import { MoreProfilePageSideEffect } from "./MoreProfilePageSideEffect";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../restAPI/ApiService";
import { useEffect, useState } from "react";
import ProgressBar from "../../component/ProgressBar";
import { UserProfile } from "./model/UserProfile";
import { ProfilePageUiState } from "./model/ProfilePageUiState";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";

const MoreProfilePage = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(true);
  const [uiState, setUiState] = useState<ProfilePageUiState>(
    new ProfilePageUiState()
  );
  const sideEffectHandlers: MoreProfilePageSideEffect = {
    navigate: function (target: string): void {
      navigate(`/${target}`);
    },
  };

  useAndroidBackHandler(() => {
    if (window.Android) {
      window.history.back();
    }
  }, []);

  useEffect(() => {
    requestDetailProfile()
      .then((res) => {
        if (res) {
          setUiState((prev) => {
            return {
              ...prev,
              name: res.name,
              email: res.email,
              phoneNumber: res.phoneNumber,
              birthday: res.birthday,
              gender: res.gender?.toLowerCase() ?? "",
              address: `${res.addressCountry} ${res.addressProvince} ${res.addressDetail}`, // TODO 수정 필요
              countryNumber: res.countryNumber ?? "855",
              tribeCategory: res.tribeCategory ?? "",
              tribeName: res.tribeName ?? "",
            };
          });
        }
      })
      .catch((error) => {
        console.error("Error deleting account:", error);
      });
  }, []);

  async function requestDetailProfile(): Promise<UserProfile | undefined> {
    const url = "/pw/users/detailProfile";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };
    try {
      const res = await ApiService.MobilePost(url, requestBody);
      if (res) {
        return res.data.body as UserProfile;
      } else {
        console.log(`requestDetailProfile failed ${res}`);
        window.history.back();
      }
    } catch (error) {
      window.history.back();
      console.error("Error requestDetailProfile:", error);
    } finally {
      setProgress(false);
    }
  }

  if (progress) return <ProgressBar />;

  return (
    <MoreProfileRender uiState={uiState} sideEffect={sideEffectHandlers} />
  );
};

export default MoreProfilePage;
