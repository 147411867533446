import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MembershipPurchaseSideEffect } from "./MembershipPurchaseSideEffect";
import { MembershipPurchaseUiEvent } from "./MembershipPurchaseUiEvent";
import { MembershipPurchaseUiState } from "./MembershipPurchaseUiState";
import MembershipPurchaseRender from "./MembershipPurchaseRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import ProgressBar from "../../../component/ProgressBar";
import ProductItem from "../model/ProductItem";
import { ApiService } from "../../../restAPI/ApiService";
import { getCountryCode } from "../../../customHook/useLocale";

const MembershipPurchasePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false); //나중에 true로 변경
  const [uiState, setUiState] = useState(new MembershipPurchaseUiState());

  const locationData = location.state as {
    userUUID: string; // 미사용
    hidePurchaseButton: boolean;
  };

  useAndroidBackHandler(() => {
    if (uiState.isMembershipDrawerOpen) {
      uiEventHandler.HideMembershipDrawer();
    } else {
      window.history.back();
    }
  }, [uiState.isMembershipDrawerOpen]);

  useEffect(() => {
    if (locationData) {
      setUiState((prev) => {
        return {
          ...prev,
          isPurchaseButtonHidden: locationData.hidePurchaseButton ?? false,
        };
      });
    }

    (async () => {
      try {
        await updateProductList("Membership");
        await updateProductList("Voucher");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const uiEventHandler: MembershipPurchaseUiEvent = {
    HandleProductTypeChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          productType: event,
        };
      });
    },

    SelectMembership: (event: ProductItem) => {
      setUiState((prev) => {
        return {
          ...prev,
          selectedMembership: event,
        };
      });
    },

    ShowMembershipDrawer: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isMembershipDrawerOpen: true,
        };
      });
    },

    HideMembershipDrawer: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isMembershipDrawerOpen: false,
        };
      });
    },
  };

  const sideEffectHandlers: MembershipPurchaseSideEffect = {
    navigate: function (target: string, options?: { state: any }): void {
      console.log("navigate", target);
      navigate(target, options);
    },
  };

  //상품 조회

  async function getProductList(type: string): Promise<ProductItem[]> {
    let productList: ProductItem[] = [];
    const requestBody = {
      country: getCountryCode(),
      type: type,
    };
    try {
      await ApiService.MobilePost("/pw/ua/product/MV/list", requestBody).then(
        (res) => {
          productList = res?.data.body;
          console.log("productList response", productList);
        }
      );
      return productList;
    } catch (e) {
      console.log("productList error", e);
      return [];
    }
  }

  async function updateProductList(type: "Membership" | "Voucher") {
    const productList = await getProductList(type);

    if (productList) {
      setUiState((prev) => ({
        ...prev,
        [type === "Membership" ? "membershipItems" : "voucherItems"]:
          productList,
      }));
    }
  }

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <>
      <MembershipPurchaseRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandlers}
      />
    </>
  );
};

export default MembershipPurchasePage;
