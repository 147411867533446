import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  UserCredential,
  updateProfile,
  User,
  updateCurrentUser,
} from "firebase/auth";

import { AuthRepository, CurrentUser } from "../AuthRepository";
import {
  appleProvider,
  auth,
  facebookProvider,
  googleProvider,
} from "../../firebase";

export class AuthRepositoryImpl implements AuthRepository {
  originalUser: User | null = null;
  user: CurrentUser | null = null;

  private static instance: AuthRepository;

  public static getInstance(): AuthRepository {
    if (!this.instance) {
      this.instance = new AuthRepositoryImpl();
    }
    return this.instance;
  }

  setUser(user: CurrentUser | null): void {
    this.user = user;
  }

  async googleLogin(): Promise<void> {
    try {
      if (window.webkit) {
        // TODO 아직 테스트안된 코드. 테스트 후 수정 필요
        const authDomain = "pople-b5a48.firebaseapp.com";
        const redirectUrl = `https://${authDomain}/__/auth/handler?provider=google.com`;
        window.location.href = redirectUrl;
      } else if (window.Android) {
        await setPersistence(auth, browserLocalPersistence).then(() => {
          return signInWithRedirect(auth, googleProvider);
        });
      } else {
        // const redirectUrl = `https://pople-b5a48.firebaseapp.com/__/auth/handler?provider=google.com`;
        // window.location.href = redirectUrl;
        signInWithPopup(auth, googleProvider);
        // await setPersistence(auth, browserLocalPersistence).then(() => {
        //
        // });
      }
    } catch (error) {
      console.error("Google login error:", error);
    }
  }

  async facebookLogin(): Promise<void> {
    try {
      if (window.webkit) {
        // TODO 아직 테스트안된 코드. 테스트 후 수정 필요
        const authDomain = "pople-b5a48.firebaseapp.com";
        const redirectUrl = `https://${authDomain}/__/auth/handler?provider=facebook.com`;
        window.location.href = redirectUrl;
      } else if (window.Android) {
        await setPersistence(auth, browserLocalPersistence).then(() => {
          return signInWithRedirect(auth, facebookProvider);
        });
      } else {
        await setPersistence(auth, browserLocalPersistence).then(() => {
          return signInWithPopup(auth, facebookProvider);
        });
      }
    } catch (error) {
      console.error("faceboock login error:", error);
    }
  }

  async appleLogin(): Promise<void> {
    try {
      if (window.webkit) {
        // TODO 아직 테스트안된 코드. 테스트 후 수정 필요
        const authDomain = "pople-b5a48.firebaseapp.com";
        const redirectUrl = `https://${authDomain}/__/auth/handler?provider=apple.com`;
        window.location.href = redirectUrl;
      } else if (window.Android) {
        await setPersistence(auth, browserLocalPersistence).then(() => {
          return signInWithRedirect(auth, appleProvider);
        });
      } else {
        await setPersistence(auth, browserLocalPersistence).then(() => {
          return signInWithPopup(auth, appleProvider);
        });
      }
    } catch (error) {
      console.error("apple login error:", error);
    }
  }

  // 이메일 로그인
  async login(
    email: string,
    password: string
  ): Promise<UserCredential | undefined> {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("Logged in:", userCredential.user);
      return userCredential;
    } catch (error) {
      console.error("Error logging in:", error);
      return undefined;
    }
  }

  // 관리자 이메일 생성 함수
  // 이메일 생성 후 기존 이메일로 로그인
  async signUpWithOutLogin(
    email: string,
    password: string
  ): Promise<UserCredential | undefined> {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // if (userCredential.user) {
      //   await updateProfile(userCredential.user, {
      //     displayName: "테스트유저",
      //   });
      // }

      auth.updateCurrentUser(this.originalUser);

      return userCredential;
    } catch (error) {
      console.error("Error signing up:", error);
      return undefined;
    }
  }

  // 이메일 회원가입
  async signUp(
    email: string,
    password: string
  ): Promise<UserCredential | undefined> {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // if (userCredential.user) {
      //   await updateProfile(userCredential.user, {
      //     displayName: "테스트유저",
      //   });
      // }

      console.log("Signed up:", userCredential.user);

      return userCredential;
    } catch (error) {
      console.error("Error signing up:", error);
      return undefined;
    }
  }

  async logout(): Promise<void> {
    try {
      await signOut(auth);
      this.setUser(null); // 모바일용
      console.log("Logged out");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  async deleteAccount(): Promise<void> {
    try {
      console.log("Deleting account...");
      const user = auth.currentUser;
      if (user) {
        await user.delete();
        console.log("Account deleted");
      } else {
        // 로그인하지 않은 상태
        console.log("No user signed in.");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  }
}
