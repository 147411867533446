import dayjs, { Dayjs } from "dayjs";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers/PickersCalendarHeader";
import {
  Badge,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandLessSharpIcon from "@mui/icons-material/ExpandLessSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useEffect, useState } from "react";
import { fontStyle } from "../../../theme/Style";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import {
  ICON_CALENDAR_SCOOTER,
  ICON_CHECK,
} from "../../../constants/appImagePath";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { HorizontalSpacer } from "../../../component/Spacer";

const CustomCalendarHeaderRoot = styled("div")({
  display: "flex",
  // justifyContent: "space-between",
  flexDirection: "column",
  marginTop: "10px",
  marginBottom: 20,
  //   padding: "8px 16px",
  alignItems: "center",
});

const DenseTable = () => {
  const createData = (
    start: string,
    end: string,
    mileage: string,
    power: string,
    time: string
  ) => {
    return { start, end, mileage, power, time };
  };
  const rows: any = [];

  // const rows: any = [
  //   createData("05:19", "05:24", "800m", "0.98kwh", "5m"),
  //   createData("05:19", "05:24", "800m", "1.72kwh", "5m"),
  //   createData("05:19", "05:24", "800m", "1.72kwh", "5m"),
  // ];
  return (
    <TableContainer sx={{ width: "100%", display: "flex" }}>
      <Table size="small" aria-label="a dense table" sx={{ width: "100%" }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F7F6FD" }}>
            {[
              LocalizedText("ua_e320_starttime"),
              LocalizedText("ua_e320_endtime"),
              LocalizedText("ua_e320_mileage2"),
              LocalizedText("ua_e320_powerusage2"),
              LocalizedText("ua_e320_ridingtime2"),
            ].map((header) => (
              <TableCell
                key={header}
                align="center"
                sx={{
                  px: 0, // padding 조절
                  py: 1.5,
                  wordWrap: "break-word", // 줄바꿈 적용
                  maxWidth: "20%", // 최대 너비 조절
                }}
              >
                <Typography color="#8F78E2" fontStyle={fontStyle.bodySr}>
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row: any, index: any) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{
                    px: 0, // padding 조절
                    wordWrap: "break-word", // 줄바꿈 적용
                    maxWidth: "20%", // 최대 너비 조절
                  }}
                >
                  {row.start}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 0, // padding 조절
                    wordWrap: "break-word", // 줄바꿈 적용
                    maxWidth: "20%", // 최대 너비 조절
                  }}
                >
                  {row.end}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 0, // padding 조절
                    wordWrap: "break-word", // 줄바꿈 적용
                    maxWidth: "20%", // 최대 너비 조절
                  }}
                >
                  {row.mileage}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 0, // padding 조절
                    wordWrap: "break-word", // 줄바꿈 적용
                    maxWidth: "20%", // 최대 너비 조절
                  }}
                >
                  {row.power}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 0, // padding 조절
                    wordWrap: "break-word", // 줄바꿈 적용
                    maxWidth: "20%", // 최대 너비 조절
                  }}
                >
                  {row.time}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                align="center"
                component="th"
                scope="row"
                sx={{
                  px: 0, // padding 조절
                  wordWrap: "break-word", // 줄바꿈 적용
                  maxWidth: "20%", // 최대 너비 조절
                }}
                colSpan={5}
              >
                {LocalizedText("ua_e320_nohistory")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface MyRideCalendarProps {
  totalMileage: number;
  totalPowerUsage: number;
  hours: number;
  minutes: number;
}

const MyRideCalendar = ({
  totalMileage,
  totalPowerUsage,
  hours,
  minutes,
}: MyRideCalendarProps) => {
  const [calendarDisplay, setCalendarDisplay] = useState("");
  const handleCalendarDisplay = () => {
    if (calendarDisplay == "") {
      setCalendarDisplay("none");
    } else {
      setCalendarDisplay("");
    }
  };
  const [value, setValue] = useState<Dayjs | null>(dayjs());
  const [dateData, setDateData] = useState<string[]>([]);
  const [dateMap, setDateMap] = useState<Dayjs[]>([]);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const today = dayjs();
  const oneYearAgo: Dayjs = today.subtract(1, "year");

  useEffect(() => {
    const generatedDateData: string[] = [];
    const generatedDateMap: Dayjs[] = [];
    const today = dayjs();
    const oneYearAgo = today.subtract(1, "year").startOf("month"); // 작년 10월 1일로 시작

    for (
      let date = today.startOf("month");
      date.isAfter(oneYearAgo) || date.isSame(oneYearAgo, "month");
      date = date.subtract(1, "month")
    ) {
      generatedDateData.push(
        LocalizedDate({
          date: date.valueOf(),
          type: DateFormatType.YearMonth,
        })
      );
      generatedDateMap.push(date.startOf("month"));
    }

    setDateData(generatedDateData);
    setDateMap(generatedDateMap);
  }, []);

  useEffect(() => {
    console.log("value is : ", value);
    console.log("dateMap is : ", dateMap);
    console.log("dateData is : ", dateData);
  }, [value]);

  const CustomCalendarHeader = (props: PickersCalendarHeaderProps<Dayjs>) => {
    const { currentMonth, onMonthChange } = props;
    const [open, setOpen] = useState(false);

    useAndroidBackHandler(() => {
      if (open == true) {
        setOpen(false);
      } else {
        window.history.back();
      }
    }, [open]);

    // 현재 날짜와 1년 전 날짜를 기준으로 날짜 목록 생성
    const today = dayjs();
    const oneYearAgo = today.subtract(1, "year").subtract(1, "month");

    const toggleDrawer = (open: boolean) => () => {
      console.log("toggleDrawer is : ", open);
      setOpen(open);
    };

    const handleDateSelect = (index: number) => {
      setCurrentDateIndex(index);
      onMonthChange(dateMap[index], "left");

      toggleDrawer(false)(); // 드로어 닫기
    };

    const selectNextMonth = () => {
      if (currentMonth.isBefore(today, "month")) {
        setCurrentDateIndex(currentDateIndex - 1);
        onMonthChange(currentMonth.add(1, "month"), "left");
      }
    };

    const selectPreviousMonth = () => {
      if (currentMonth.isAfter(oneYearAgo, "month")) {
        setCurrentDateIndex(currentDateIndex + 1);
        onMonthChange(currentMonth.subtract(1, "month"), "right");
      }
    };

    const DrawerList = (
      <div>
        <style>
          {`
        div::-webkit-scrollbar {
          display: none;
        }
      `}
        </style>
        <Box
          sx={{
            width: "100%",
            height: "51vh",
          }}
        >
          <ListSubheader
            sx={{
              height: 50,
              display: "flex",
              alignItems: "center",
              pt: 1,
              justifyContent: "space-between",
            }}
          >
            <Typography color={"black"} fontWeight={"bold"}>
              {LocalizedText("ua_e320_setperiod")}
            </Typography>
            <Box style={{ marginTop: 12 }} onClick={toggleDrawer(false)}>
              <ClearOutlinedIcon />
            </Box>
          </ListSubheader>
          <List>
            {dateData.map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleDateSelect(index);
                    setValue(null);
                  }}
                >
                  <ListItemText primary={text} />
                  {currentDateIndex == index && (
                    <Box component="img" src={ICON_CHECK} />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </div>
    );

    return (
      <CustomCalendarHeaderRoot>
        <Typography sx={{ position: "relative" }}></Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Stack spacing={1} direction="row">
            <IconButton
              onClick={selectPreviousMonth}
              title="Previous month"
              disabled={currentMonth.isSame(
                oneYearAgo.subtract(-1, "month"),
                "month"
              )}
              disableRipple
            >
              <ChevronLeft />
            </IconButton>
          </Stack>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box component="img" src={ICON_CALENDAR_SCOOTER} />
            <Typography
              sx={{
                ml: 0.8,
                borderBottom: 1,
                lineHeight: "1.2",
                cursor: "pointer",
                color: "#676665",
                pb: -1,
              }}
              fontStyle={fontStyle.titleL}
              onClick={toggleDrawer(true)} // 클릭 시 드로어 열기
            >
              {LocalizedDate({
                date: currentMonth.valueOf(),
                type: DateFormatType.YearMonth,
              })}
            </Typography>
          </Box>
          <Stack spacing={1} direction="row">
            <IconButton
              onClick={selectNextMonth}
              title="Next month"
              disabled={currentMonth.isSame(today, "month")}
              disableRipple
            >
              <ChevronRight />
            </IconButton>
          </Stack>
        </Box>
        {/* Carbon Reduction */}
        <Box
          sx={{
            borderRadius: "10px",
            border: 1,
            mt: 2,
            borderColor: "#A2F6EA",
            bgcolor: "#F6FEFD",
            width: "92%",
          }}
        >
          <Box
            sx={{
              p: 1,
              px: 2.5,
              // display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>{LocalizedText("ua_e320_carbonreduction")}</Typography>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                color: "#14C3AA",
              }}
            >
              <Typography fontStyle={fontStyle.titleXL}>134.2</Typography>
              <HorizontalSpacer width={4} />
              kgCO₂-eq
            </Box>
          </Box>
        </Box>

        {/* DashBoard성 요소 3개 */}
        <Box sx={{ width: "100%", p: 1, display: "flex", my: 1 }}>
          {/* Odometer */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography
              fontStyle={fontStyle.subTitleM}
              sx={{ color: "#676665" }}
            >
              {LocalizedText("ua_e320_mileage")}
            </Typography>
            <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
              {totalMileage}
              <Typography
                fontStyle={fontStyle.titleL}
                sx={{ color: "#999999", ml: 0.7 }}
              >
                km
              </Typography>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ backgroundColor: "#EBEBEB" }}
          />
          {/* Power Usage */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography
              fontStyle={fontStyle.subTitleM}
              sx={{ color: "#676665" }}
            >
              {LocalizedText("ua_e320_powerusage")}
            </Typography>
            <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
              {totalPowerUsage}
              <Typography
                fontStyle={fontStyle.titleL}
                sx={{ color: "#999999", ml: 0.7 }}
              >
                kwh
              </Typography>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ backgroundColor: "#EBEBEB" }}
          />
          {/* Riding Time */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography
              fontStyle={fontStyle.subTitleM}
              sx={{ color: "#676665" }}
            >
              {LocalizedText("ua_e320_ridingtime")}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
                {hours}
                <Typography
                  fontStyle={fontStyle.titleL}
                  sx={{ color: "#999999", ml: 0.7 }}
                >
                  h
                </Typography>
              </Box>
              <Box
                fontStyle={fontStyle.titleL}
                sx={{ display: "flex", ml: 0.7 }}
              >
                {minutes}
                <Typography
                  fontStyle={fontStyle.titleL}
                  sx={{ color: "#999999", ml: 0.7 }}
                >
                  m
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ width: "100%" }} />
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 0.3,
          }}
          fontStyle={fontStyle.subTitleM}
          onClick={handleCalendarDisplay}
        >
          {calendarDisplay == "" ? (
            <>{LocalizedText("ua_e320_fold")}</>
          ) : (
            <>{LocalizedText("ua_e320_expand")}</>
          )}

          <IconButton>
            {calendarDisplay == "" && (
              <ExpandMoreSharpIcon fontSize="small" sx={{ width: "16px" }} />
            )}
            {calendarDisplay == "none" && (
              <ExpandLessSharpIcon fontSize="small" sx={{ width: "16px" }} />
            )}
          </IconButton>
        </Typography>

        {/* Drawer 컴포넌트 */}
        <Drawer
          open={open}
          onClose={toggleDrawer(false)}
          anchor={"bottom"}
          PaperProps={{
            sx: {
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            },
          }}
          aria-hidden={!open}
        >
          {DrawerList}
        </Drawer>
      </CustomCalendarHeaderRoot>
    );
  };

  // 강조 표시할 날짜 정의
  const highlightedDays: number[] = [
    dayjs().startOf("month").add(0, "days").date(),
    dayjs().startOf("month").add(1, "days").date(),
    dayjs().startOf("month").add(2, "days").date(),
    dayjs().startOf("month").add(5, "days").date(),
    dayjs().startOf("month").add(7, "days").date(),
    dayjs().startOf("month").add(8, "days").date(),
    dayjs().startOf("month").add(9, "days").date(),
    dayjs().startOf("month").add(10, "days").date(),
  ];

  const customBadge = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#C7C6FB",
          width: 4,
          height: 4,
          mr: 3.5,
          mb: 0.5,
          borderRadius: 10,
        }}
      />
    );
  };

  function ServerDay(
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
  ) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? customBadge() : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        // height: 900,
        backgroundColor: "white",
        minHeight: 40,
        boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1200,
        mb: 2.5,
      }}
    >
      <Box
        sx={{
          mb: 2,
          // bgcolor: "red",
          "& > div": {
            minWidth: 256,
            // minHeight: 700,
          },
          "& > div > div, & > div > div > div, & .MuiCalendarPicker-root": {
            width: "88vw",
            maxHeight: 1000,
            // bgcolor: "red",
          },
          "& .MuiTypography-caption": {
            // width: 500,
            // margin: 1,
          },
          // "& .PrivatePickersSlideTransition": {
          //   minHeight: 600,
          // },
          "& .MuiPickersDay-dayWithMargin": {
            // margin: 1,
          },
          "& .MuiPickersDay-root": {
            // width: 500,
            // height: 700,
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateCalendar"]}>
            <DateCalendar
              slots={{ calendarHeader: CustomCalendarHeader, day: ServerDay }}
              slotProps={{
                day: { highlightedDays } as any,
              }}
              defaultValue={value}
              value={value} // value를 명시적으로 전달
              // shouldDisableDate={(date) => date.isAfter(today)} // 오늘 이후 날짜 비활성화
              minDate={oneYearAgo} // 1년 전 날짜
              maxDate={today}
              onChange={(newValue) => {
                if (value?.isSame(newValue)) {
                  setValue(null); // 같은 날짜 클릭 시 선택 해제
                } else {
                  setValue(newValue); // 다른 날짜 선택 시 해당 날짜로 업데이트
                }
              }}
              sx={{
                height: "auto",
                "& .MuiDateCalendar-viewTransitionContainer": {
                  "& div[role=presentation]": {},
                  "& div[role=grid]": {
                    // minHeight: 600,
                    // height: 0,
                    display: calendarDisplay,
                  },
                  "& button[role=gridcell]": {
                    // minHeight: 600,
                    // height: 0,
                    // bgcolor: "red",
                    zIndex: 1000,
                    border: "0px !important",
                  },
                  "& div[role=row]": {
                    // paddingLeft: 0,
                    // paddingRight: 0,
                    // paddingBottom: 0,
                    // border: 1,
                    // height: 40,
                    // backgroundColor: "red",

                    justifyContent: "space-between !important",
                  },
                  "& .Mui-selected": {
                    color: "#5D38E5",
                    fontWeight: "bold",

                    backgroundColor: "#C7C6FB !important", // 보라색 배경
                    "&:hover": {
                      backgroundColor: "#C7C6FB !important", // 호버 시에도 보라색 유지
                    },
                  },
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        <Divider
          sx={
            calendarDisplay == "none"
              ? {
                  mt: -2,
                }
              : { mt: 0 }
          }
        />
      </Box>
      <Box
        sx={{ width: "93%", mb: 2, display: "flex", flexDirection: "column" }}
      >
        <Typography fontStyle={fontStyle.bodyMr}>
          {LocalizedDate({
            date: value?.valueOf(),
            type: DateFormatType.MonthDayWeek,
          })}
        </Typography>
        <Box sx={{ width: "100%", mt: 1 }}>{DenseTable()}</Box>
      </Box>
    </Box>
  );
};

export default MyRideCalendar;
