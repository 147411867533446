export class RequestData {
  no: number;
  requestBranch: string;
  branch: string;
  requester: string;
  status: string;
  desiredPickupDate: number;
  registerDate: number;

  constructor(
    no: number,
    requestBranch: string,
    branch: string,
    requester: string,
    status: string,
    desiredPickupDate: number,
    registerDate: number
  ) {
    this.no = no;
    this.requestBranch = requestBranch;
    this.branch = branch;
    this.requester = requester;
    this.status = status;
    this.desiredPickupDate = desiredPickupDate;
    this.registerDate = registerDate;
  }
}

export const enum ProductionStatusType {
  CONFIRM = "Confirm",
  REJECT = "Reject",
  UPDATE = "Update",
}

export const RequestList: RequestData[] = [
  new RequestData(
    1,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Request",
    1716499200,
    1716499200
  ),
  new RequestData(
    2,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Checked",
    1716499200,
    1716499200
  ),
  new RequestData(
    3,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Approval",
    1716499200,
    1716499200
  ),
  new RequestData(
    4,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    5,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    6,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    7,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    8,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    9,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    10,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Approval",
    1716499200,
    1716499200
  ),
  new RequestData(
    11,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Request",
    1716499200,
    1716499200
  ),
  new RequestData(
    12,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Checked",
    1716499200,
    1716499200
  ),
  new RequestData(
    13,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Approval",
    1716499200,
    1716499200
  ),
  new RequestData(
    14,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    15,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Checked",
    1716499200,
    1716499200
  ),
  new RequestData(
    16,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Request",
    1716499200,
    1716499200
  ),
  new RequestData(
    17,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Approval",
    1716499200,
    1716499200
  ),
  new RequestData(
    18,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    19,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Request",
    1716499200,
    1716499200
  ),
  new RequestData(
    20,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Checked",
    1716499200,
    1716499200
  ),
  new RequestData(
    21,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Approval",
    1716499200,
    1716499200
  ),
  new RequestData(
    22,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    23,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Checked",
    1716499200,
    1716499200
  ),
  new RequestData(
    24,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    25,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Approval",
    1716499200,
    1716499200
  ),
  new RequestData(
    26,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Request",
    1716499200,
    1716499200
  ),
  new RequestData(
    27,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Checked",
    1716499200,
    1716499200
  ),
  new RequestData(
    28,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestData(
    29,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Approval",
    1716499200,
    1716499200
  ),
  new RequestData(
    30,
    "Gwang-Ju",
    "Yong-In",
    "HJI(hji@verywords.com",
    "Checked",
    1716499200,
    1716499200
  ),
];
