import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import CommonTextField from "../../../../../component/CommonTextField";

export function ProductionDetailsDateCardImpossible() {
  return (
    <CardContent
      sx={{
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{display:"flex", flexDirection: "column" }} flex={1}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Regist Date
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
            placeholder="30 May 2024 00:00"
            disabled
          />
        </Box>
        <HorizontalSpacer width={24} />
        <Box sx={{display:"flex", flexDirection: "column" }} flex={1}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Confirm Date
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
            placeholder="-"
            disabled
          />
        </Box>
        <HorizontalSpacer width={24} />
        <Box sx={{display:"flex", flexDirection: "column" }} flex={1}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Last Update Date
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
            placeholder="30 May 2024 00:00"
            disabled
          />
        </Box>
      </Box>
    </CardContent>
  );
}
