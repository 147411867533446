export class ReceiveDeliverData {
  no: number;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  company: string;
  quantities: string;
  branch: string;
  status: ReceiveDeliverDataType;
  amount: number;
  registerDate: number;

  constructor(
    no: number,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    company: string,
    quantities: string,
    branch: string,
    amount: number,
    status: ReceiveDeliverDataType,
    registerDate: number
  ) {
    this.no = no;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.company = company;
    this.quantities = quantities;
    this.branch = branch;
    this.amount = amount;
    this.status = status;
    this.registerDate = registerDate;
  }
}

export enum ReceiveDeliverDataType {
  RECIEVE = "Recieve",
  DELIVER = "Deliver",
}

export const RecieveDeliverList: ReceiveDeliverData[] = [
  new ReceiveDeliverData(
    1,
    "ELECTRIC",
    "VW90-00001A",
    "CELL PACK",
    "W-10S8P-M50L",
    "신흥 SEC",
    "EA",
    "YONG-IN",
    200,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    2,
    "HARNESS",
    "VW90-00001A",
    "HARNESS_TEMP_3,4",
    "W-10S8P-M50L",
    "디에스테크",
    "EA",
    "YONG-IN",
    200,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    3,
    "HARNESS",
    "VW90-00001A",
    "HARNESS_TEMP_3,4",
    "M$_12_Trust bolt",
    "디에스테크",
    "EA",
    "YONG-IN",
    200,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    4,
    "ETC",
    "VW90-00001A",
    "SCREW",
    "51163-04P, LNTK103F(0)-34",
    "신흥 SEC",
    "SET",
    "YONG-IN",
    200,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    5,
    "MOTOR",
    "AB12-00034B",
    "MOTOR_ASSEMBLY",
    "M-10D5L",
    "대덕전자",
    "EA",
    "SEOUL",
    150,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    6,
    "BATTERY",
    "BD67-12345C",
    "BATTERY_PACK",
    "B-12V5A",
    "삼성SDI",
    "EA",
    "BUSAN",
    120,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    7,
    "CHIP",
    "CH76-01234D",
    "IC_CHIP",
    "IC-8942",
    "하이닉스",
    "EA",
    "INCHEON",
    500,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    8,
    "SENSOR",
    "SE34-98765E",
    "TEMP_SENSOR",
    "S-5T20",
    "LG이노텍",
    "EA",
    "DAEJEON",
    300,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    9,
    "CONNECTOR",
    "CN45-87654F",
    "CONNECTOR_SET",
    "C-12_34",
    "디에스테크",
    "SET",
    "YONG-IN",
    100,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    10,
    "PCB",
    "PCB-90876G",
    "CONTROL_BOARD",
    "CB-V12_08",
    "삼성전자",
    "EA",
    "SUWON",
    80,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    11,
    "RESISTOR",
    "RS12-34567H",
    "RESISTOR_SET",
    "R-10K_OHM",
    "대덕전자",
    "SET",
    "SEOUL",
    250,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    12,
    "CAPACITOR",
    "CP67-12345I",
    "CAPACITOR_10F",
    "C-10F_25V",
    "하이닉스",
    "EA",
    "INCHEON",
    400,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    13,
    "SWITCH",
    "SW12-98765J",
    "SWITCH_TOGGLE",
    "S-T1234",
    "삼성SDI",
    "EA",
    "BUSAN",
    60,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    14,
    "TRANSISTOR",
    "TR34-87654K",
    "TRANSISTOR_SET",
    "T-NPN_2",
    "LG이노텍",
    "SET",
    "DAEJEON",
    180,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    15,
    "DIODE",
    "DI12-12345L",
    "DIODE_SET",
    "D-200V_1A",
    "대덕전자",
    "EA",
    "SEOUL",
    90,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    16,
    "LED",
    "LD67-98765M",
    "LED_LIGHT",
    "L-BLU_05W",
    "삼성전자",
    "EA",
    "SUWON",
    110,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    17,
    "COIL",
    "CL45-87654N",
    "INDUCTOR_COIL",
    "C-12H",
    "LG이노텍",
    "EA",
    "DAEJEON",
    170,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    18,
    "FILTER",
    "FL12-34567O",
    "AIR_FILTER",
    "F-HEPA_13",
    "삼성SDI",
    "EA",
    "BUSAN",
    140,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    19,
    "FUSE",
    "FS67-12345P",
    "FUSE_SET",
    "F-10A_250V",
    "하이닉스",
    "SET",
    "INCHEON",
    130,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    20,
    "RELAY",
    "RL45-98765Q",
    "POWER_RELAY",
    "R-50A_250V",
    "대덕전자",
    "EA",
    "SEOUL",
    200,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    21,
    "TRANSFORMER",
    "TF12-87654R",
    "POWER_TRANSFORMER",
    "T-220V_10A",
    "삼성전자",
    "EA",
    "SUWON",
    90,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    22,
    "COOLER",
    "CR67-12345S",
    "COOLING_FAN",
    "C-FAN_12V",
    "LG이노텍",
    "EA",
    "DAEJEON",
    70,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    23,
    "CABLE",
    "CB34-34567T",
    "POWER_CABLE",
    "P-CB_220V",
    "삼성SDI",
    "EA",
    "BUSAN",
    85,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    24,
    "ANTENNA",
    "AT45-98765U",
    "SIGNAL_ANTENNA",
    "A-SIG_5G",
    "하이닉스",
    "EA",
    "INCHEON",
    60,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    25,
    "MEMORY",
    "MM67-87654V",
    "RAM_CHIP",
    "M-DDR4_8GB",
    "삼성전자",
    "EA",
    "SUWON",
    500,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    26,
    "STORAGE",
    "ST12-12345W",
    "SSD_DRIVE",
    "S-256GB",
    "LG이노텍",
    "EA",
    "DAEJEON",
    450,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    27,
    "SCREEN",
    "SC34-34567X",
    "LCD_DISPLAY",
    "L-15.6FHD",
    "삼성SDI",
    "EA",
    "BUSAN",
    210,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    28,
    "CAMERA",
    "CM45-98765Y",
    "CAMERA_MODULE",
    "C-12MP",
    "하이닉스",
    "EA",
    "INCHEON",
    95,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    29,
    "TOUCHPAD",
    "TP67-87654Z",
    "TOUCHPAD",
    "T-MULTI_10",
    "삼성전자",
    "EA",
    "SUWON",
    70,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
  new ReceiveDeliverData(
    30,
    "SPEAKER",
    "SP12-12345AA",
    "SPEAKER_MODULE",
    "S-5W_ST",
    "LG이노텍",
    "EA",
    "DAEJEON",
    65,
    ReceiveDeliverDataType.RECIEVE,
    1716499200
  ),
];
