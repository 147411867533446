import { Box, TablePaginationProps } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridPagination,
  GridRowsProp,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import MuiPagination from "@mui/material/Pagination";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface GrpMngListGridProps {
  rows: GridRowsProp;
  setRowsPerPage: any;
  rowsPerPage: any;
}

const GrpMngListGrid = ({
  rows,
  setRowsPerPage,
  rowsPerPage,
}: GrpMngListGridProps) => {
  const [page, setPage] = useState(0); // 페이지 번호를 관리할 상태
  const navigate = useNavigate();

  const Pagination = ({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        shape="rounded"
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  const handlePageChange = (newPaginationModel: any) => {
    console.log(newPaginationModel);
    setPage(newPaginationModel.page);
  };

  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      editable: true,
    },
    {
      field: "groupName",
      headerName: "Group Name",
      headerAlign: "left",
      flex: 1,
      editable: true,
    },

    {
      field: "description",
      headerName: "Description",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "permission",
      headerName: "Permission",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "writer",
      headerName: "Writer",
      type: "string",

      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "lastUpdateDate",
      headerName: "Last Update Date",
      type: "string",

      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },

    {
      field: "registrationDate",
      headerName: "Registration Date",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
  ];

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    console.log(params);
    navigate(`/grpMng/grpDetails/${params.id}`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        mt: 3,
        borderRadius: 3,
        overflowY: "hidden",
      }}
    >
      <DataGrid
        checkboxSelection
        autoHeight
        onRowClick={handleRowClick}
        rows={rows}
        columns={columns}
        paginationModel={{ pageSize: rowsPerPage, page: page }}
        onPaginationModelChange={handlePageChange} // 페이지 변경 핸들러 추가
        initialState={{
          sorting: {
            sortModel: [{ field: "registrationDate", sort: "desc" }],
          },
        }}
        pagination
        slots={{
          pagination: CustomPagination,
        }}
        disableRowSelectionOnClick
        sx={{
          backgroundColor: "white",
          border: 0,
          borderRadius: 4,
          "& .MuiDataGrid-main": {
            borderTopLeftRadius: 18,
            borderTopRightRadius: 18,
          },
          ":hover": { cursor: "pointer" },
        }}
      />
    </Box>
  );
};

export default GrpMngListGrid;
