import { CheckboxState } from "../../../../component/CommonCheckbox";

export class HistoryUiState {
  filters: HistoryFilter[];
  allCheckboxState: HistoryFilter;

  constructor(filter: HistoryFilter[], allCheckboxState: HistoryFilter) {
    this.filters = filter;
    this.allCheckboxState = allCheckboxState;
  }
}

export class HistoryFilter {
  title: string;
  checkState: CheckboxState;

  constructor(title: string, checkState: CheckboxState) {
    this.title = title;
    this.checkState = checkState;
  }
}
