export class RequestHistoryData {
  no: number;
  requestBranch: string;
  branch: string;
  requester: string;
  updater: string;
  status: string;
  desiredPickupDate: number;
  registerDate: number;

  constructor(
    no: number,
    requestBranch: string,
    branch: string,
    requester: string,
    updater: string,
    status: string,
    desiredPickupDate: number,
    registerDate: number
  ) {
    this.no = no;
    this.requestBranch = requestBranch;
    this.branch = branch;
    this.requester = requester;
    this.updater = updater;
    this.status = status;
    this.desiredPickupDate = desiredPickupDate;
    this.registerDate = registerDate;
  }
}

export const RequestHistoryList: RequestHistoryData[] = [
  new RequestHistoryData(
    1,
    "Gwang-Ju",
    "Yong-In",
    "HJI (hji@verywords.com",
    "HJI (hji@verywords.com",
    "Request",
    1716499200,
    1716499200
  ),
  new RequestHistoryData(
    2,
    "Gwang-Ju",
    "Yong-In",
    "HJI (hji@verywords.com",
    "HJI (hji@verywords.com",
    "Checked",
    1716499200,
    1716499200
  ),
  new RequestHistoryData(
    3,
    "Gwang-Ju",
    "Yong-In",
    "HJI (hji@verywords.com",
    "HJI (hji@verywords.com",
    "Approval",
    1716499200,
    1716499200
  ),
  new RequestHistoryData(
    4,
    "Gwang-Ju",
    "Yong-In",
    "HJI (hji@verywords.com",
    "HJI (hji@verywords.com",
    "Reject",
    1716499200,
    1716499200
  ),
  new RequestHistoryData(
    5,
    "Gwang-Ju",
    "Yong-In",
    "HJI (hji@verywords.com",
    "HJI (hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestHistoryData(
    6,
    "Gwang-Ju",
    "Yong-In",
    "HJI (hji@verywords.com",
    "HJI (hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
  new RequestHistoryData(
    7,
    "Gwang-Ju",
    "Yong-In",
    "HJI (hji@verywords.com",
    "HJI (hji@verywords.com",
    "Done",
    1716499200,
    1716499200
  ),
];
