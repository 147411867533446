import { IssuedCertificateItem } from "./IssuedCertificateItem";

export class IssuedHistoryUiState {
  certificates: IssuedCertificateItem[] = [
    {
      status: "Disposed",
      title: "Scooter Rental Certificate",
      vin: "VSC240314010001",
      date: "22 Feb 2024 12:31",
    },
    {
      status: "Expired",
      title: "Scooter Rental Certificate",
      vin: "VSC240314010001",
      date: "12 Jan 2023 12:31",
    },
    {
      status: "Issued",
      title: "Scooter Rental Certificate",
      vin: "VSC240314010001",
      date: "12 Jan 2023 12:31",
    },
    {
      status: "Issued",
      title: "Scooter Owner Certificate",
      vin: "VSC240341001001",
      date: "22 May 2024 12:31",
    },
    {
      status: "Issued",
      title: "Member Certificate",
      email: "verykim@verywords.com",
      date: "22 May 2024 12:31",
    },
    {
      status: "Expired",
      title: "Scooter Rental Certificate",
      vin: "VSC240314010001",
      date: "12 Jan 2023 12:31",
    },
    {
      status: "Issued",
      title: "Scooter Rental Certificate",
      vin: "VSC240314010001",
      date: "12 Jan 2023 12:31",
    },
    {
      status: "Issued",
      title: "Scooter Owner Certificate",
      vin: "VSC240341001001",
      date: "22 May 2024 12:31",
    },
    {
      status: "Issued",
      title: "Member Certificate",
      email: "verykim@verywords.com",
      date: "22 May 2024 12:31",
    }
  ];
}
