import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import VerticalSpacer from "../../../component/Spacer";
import { color } from "../../../theme/Color";
import { useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import CommonTextField from "../../../component/CommonTextField";
import { CouponAlert } from "./CouponAlert";
import CommonButton from "../../../component/CommonButton";

interface CouponCodeCardProps {
  coupon: boolean;
  setCoupon: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CouponCodeCard({ coupon, setCoupon }: CouponCodeCardProps) {
  const [inputValue, setInputValue] = useState<string>("");
  const [result, setResult] = useState<boolean>();
  const [message, setMessage] = useState<string>();
  const [timestamp, setTimestamp] = useState<Number>();
  const [couponName, setCouponName] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value); // 입력값을 상태에 저장
  };

  const buttonClick = () => {
    setCoupon(false);
  };

  const handleCouponCheck = async () => {
    setDisabled(true);
    ApiService.WebPost(`/pw/aw/product/coupon/use`, {
      id: inputValue,
      uuid: localStorage.getItem("user_uuid"),
    })
      .then((res) => {
        setResult(res?.data.result);
        setMessage(res?.data.message);
        setTimestamp(res?.data.timestamp);
        setCouponName(res?.data.couponName);
        setDisabled(false);
        if (res?.data.result === true) {
          setCoupon(true);
          console.log(
            "쿠폰 result : ",
            result,
            "message : ",
            message,
            "timestamp : ",
            timestamp,
            "couponName : ",
            couponName
          );
        } else {
          setCoupon(true);
          setMessage("invalid");

          console.log(`실패:${res?.data.message}`);
        }
      })
      .catch((error) => {
        console.error("API 호출 오류:", error);
        console.error("상세 오류 메시지:", error.response?.data); // 서버 응답에서 제공하는 상세 오류 메시지
        setCoupon(true);
        setMessage("error"); // 에러 메시지 설정
      });
  };

  return (
    <Grid item xs={6}>
      <Card
        sx={{
          borderRadius: 3,
          width: "100%",
          padding: "24px",
          boxShadow: 0,
          border: "1px solid #E9E9EA",
          minWidth: "506px",
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Box>
            <Typography fontStyle={fontStyle.semiboldL} color={color.default}>
              Coupon
            </Typography>
            <VerticalSpacer height={16} />
          </Box>
        </CardContent>
        <CardContent
          sx={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Box textAlign="center">
            <Box>
              <Typography
                component="span"
                fontStyle={fontStyle.regularM}
                color={color.default}
              >
                Please scan the coupon QR code
              </Typography>
            </Box>
            <Box>
              <Typography
                component="span"
                fontStyle={fontStyle.regularM}
                color={color.default}
              >
                or enter the coupon code.
              </Typography>
            </Box>
            {/* 한글이라 주석처리 */}
            {/* <Typography 
              component="span"
              fontStyle={fontStyle.semiboldM}
              color={color.proceed}
            >
              쿠폰 QR 코드
            </Typography>
            <Typography
              component="span"
              fontStyle={fontStyle.regularM}
              color={color.default}
            >
              를 스캐너에 입력
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography
              component="span"
              fontStyle={fontStyle.regularM}
              color={color.default}
            >
              혹은&nbsp;
            </Typography>
            <Typography
              component="span"
              fontStyle={fontStyle.semiboldM}
              color={color.proceed}
            >
              쿠폰 코드
            </Typography>
            <Typography
              component="span"
              fontStyle={fontStyle.regularM}
              color={color.default}
            >
              를 입력해 주세요.
            </Typography> */}
          </Box>
        </CardContent>
        <CardContent
          sx={{
            width: "100%",
            px: 0,
          }}
        >
          <Box>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Coupon Code
            </Typography>
          </Box>
          <VerticalSpacer height={8} />
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <CommonTextField
              value={inputValue}
              onChange={handleChange}
              inputProps={{ style: { padding: "14px 16px" } }}
            />
          </Box>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommonButton
            onClick={handleCouponCheck}
            sx={{
              height: "36px",
              width: "60px",
              px: "20px",
              py: "8px",
              ml: "0px",
            }}
            title={"OK"}
            disabled={disabled}
          />
          <CouponAlert
            message={message}
            buttonClick={buttonClick}
            coupon={coupon}
            couponName={couponName}
          />
        </Box>
      </Card>
    </Grid>
  );
}
