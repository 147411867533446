import CommonTable, { useVisibleRows } from "../../../../component/CommonTable";
import ServiceMngTableRow from "./ServiceMngTableRow";

interface ServiceMngTableProps {
  mngRows?: any;
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: any;
  docCount?: any;
}

const ServiceMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: ServiceMngTableProps) => {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, 10, setPage, page);
  return (
    <CommonTable
      headCells={RegionManagementHeadCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((data: any, index: any) => (
        <ServiceMngTableRow row={data} key={index} />
      ))}
    </CommonTable>
  );
};

const RegionManagementHeadCells = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "svcType",
    label: "Service",
  },
  {
    disablePadding: false,
    align: "left",
    id: "svcNm",
    label: "Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "svcAreaNm",
    label: "Area Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "status",
    label: "Status",
  },
  {
    disablePadding: false,
    align: "left",
    id: "manager",
    label: "Manager",
  },
  {
    disablePadding: false,
    align: "left",
    id: "ctryCd",
    label: "Country",
  },

  {
    disablePadding: false,
    align: "left",
    id: "lastUptDt",
    label: "Last Update Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registerDt",
    label: "Register Date",
  },
];

export default ServiceMngTable;
