import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";

interface SNSLoginButtonProps {
  imageSrc: string;
  text: string;
  fontColor: string;
  backgroundColor: string;
  onClick: () => void;
}

const SNSLoginButton: React.FC<SNSLoginButtonProps> = ({
  imageSrc,
  text,
  fontColor,
  backgroundColor,
  onClick,
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "100%", // 버튼이 화면의 너비를 채우도록 설정
        height: "52px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: backgroundColor,
        borderRadius: "10px",
      }}
    >
      {/* 이미지 표시 */}
      <Box
        component="img"
        src={imageSrc}
        alt=""
        sx={{
          width: "16px",
          height: "16px",
          marginRight: "8px", // 텍스트와 이미지 사이 간격
        }}
      />
      {/* 텍스트 표시 */}
      <Text
        sx={{
          fontStyle: fontStyle.bodyLb,
          color: fontColor,
        }}
        text={text}
      />
    </Box>
  );
};

export default SNSLoginButton;
