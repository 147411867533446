import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Divider,
  Radio,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import CommonTextField from "../../../component/CommonTextField";
import { AnnounceCreateUiEvent } from "../model/AnnounceCreateUiEvent";
import { AnnounceCreateUiState } from "../model/AnnounceCreateUiState";
import { CommonSelect } from "../../../component/CommonSelect";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import dayjs from "dayjs";
import {
  ICON_ADD,
  ICON_CLIP,
  ICON_INDETERMINATE,
  ICON_TRASH,
  ICON_TRASH2,
} from "../../../constants/imagePath";

export interface AnnounceRow {
  id: string;
  title: string;
  content: string;
  country: string;
  showHide: boolean;
  openDate: number;
  writer: string;
  imageUrls: [];
  registrationDate: number;
  updateDate: number;
  deleteDate: number;
}

interface AnnounceDetailsGridProps {
  uiEvent: AnnounceCreateUiEvent;
  uiState: AnnounceCreateUiState;
  inValidDataCheck: {
    title: boolean;
    context: boolean;
    date: boolean;
    time: boolean;
  };
  fileUrls: string[];
  setFileUrls: Dispatch<SetStateAction<string[]>>;
  createData: {
    title: string | undefined;
    context: string | undefined;
    date: number | undefined;
    time: number | undefined;
    showHide: boolean | undefined;
    country: string;
  };
  handleDetailDataChange: (
    key: "title" | "context" | "date" | "time" | "showHide" | "country",
    value: string | number | boolean
  ) => void;
  rows?: AnnounceRow | null;
  countryCodeMap: { [key: string]: string };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AnnounceDetailsGrid = ({
  uiEvent,
  uiState,
  inValidDataCheck,
  createData,
  fileUrls,
  setFileUrls,
  handleDetailDataChange,
  rows,
  countryCodeMap,
}: AnnounceDetailsGridProps) => {
  const getCountryName = (code: string) => {
    if (!code) return "";
    return (
      Object.keys(countryCodeMap).find((key) => countryCodeMap[key] === code) ||
      code
    );
  };

  // useEffect(() => {
  //   if (rows?.title) {
  //     setTitle(rows.title);
  //   }

  //   if (rows?.content) {
  //     setContent(rows.content);
  //   }
  // }, [rows?.title, setTitle, rows?.content, setContent]);

  const getTime = (): string | null => {
    if (rows?.openDate) {
      const Time = dayjs(rows.openDate);

      // 시간을 HH:mm 형식으로 반환
      return Time.format("HH:mm");
    }
    return null;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        uiEvent.HandleUiStateChange("image", uploadedFile); // 파일 정보 저장
        const previewUrl = URL.createObjectURL(uploadedFile);
        setFileUrls((prev) => [...prev, previewUrl]); // 미리보기 URL 설정
      } else {
        alert("Only PNG, JPG, and JPEG files are allowed.");
      }
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  // 버튼클릭하면 handleFileUpload 불러오기위해 만든 함수
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      {/* 첫번째 카드 */}
      <Grid item lg={8}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            padding: "24px",
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ width: "100%", padding: "0px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Title
                </Typography>
                <VerticalSpacer height={8} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CommonTextField
                    placeholder="Enter Title"
                    value={createData.title}
                    onChange={(event) => {
                      handleDetailDataChange("title", event.target.value);
                    }}
                    error={inValidDataCheck.title}
                  />
                  {inValidDataCheck.title && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please enter a title
                    </Typography>
                  )}
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Context
                </Typography>
                <VerticalSpacer height={8} />
                <Box
                  sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
                >
                  <CommonTextField
                    placeholder="Please enter a description"
                    value={createData.context}
                    multiline={true}
                    inputProps={{ style: { height: "220px" } }}
                    onChange={(event) => {
                      handleDetailDataChange("context", event.target.value);
                    }}
                    error={inValidDataCheck.context}
                  />
                  {inValidDataCheck.context && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please enter a content
                    </Typography>
                  )}
                </Box>
                <Box></Box>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Attach Image
                </Typography>
                <VerticalSpacer height={8} />
                <Box sx={{ display: "flex", gap: "20px" }}>
                  {fileUrls.map((fileUrl, index) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex",
                          height: "108px",
                          width: "108px",
                          backgroundColor: color.gray200,
                          borderRadius: "10px",
                          border: "1px solid",
                          borderColor: color.gray300,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyItems: "center",
                            flexDirection: "row-reverse",
                            alignItems: "flex-end",
                          }}
                        ></Box>
                        <Box
                          component="img"
                          src={fileUrl}
                          alt={`미리보기 이미지 ${index + 1}`}
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "-5px", // 아래로 살짝 이동
                            right: "-5px", // 오른쪽으로 살짝 이동
                            cursor: "pointer",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            height: "22px",
                            width: "22px",
                            borderRadius: "50%",
                            backgroundColor: color.gray800,
                            marginLeft: "4px",
                          }}
                          onClick={() => {
                            setFileUrls(fileUrls.filter((_, i) => i !== index)); // 해당 파일 제거
                          }}
                        >
                          <img
                            src={ICON_INDETERMINATE}
                            style={{ width: "16px", height: "16px" }}
                          ></img>
                        </Box>
                      </Box>
                      <HorizontalSpacer width={20} />
                    </Box>
                  ))}
                  {fileUrls.length < 5 && (
                    <>
                      <Box
                        sx={{
                          height: "108px",
                          width: "108px",
                          backgroundColor: color.gray200,
                          cursor: "pointer",
                          borderRadius: "10px",
                          border: "1px solid",
                          borderColor: color.gray300,
                          position: "relative", // 상대 위치 지정
                        }}
                        onClick={handleButtonClick}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            width: "22px", // 원의 크기 설정
                            height: "22px", // 원의 크기 설정
                            borderRadius: "50%", // 원 모양 만들기
                            backgroundColor: color.primary500,
                            bottom: "-5px",
                            right: "-5px",
                            display: "flex",
                            alignItems: "center", // 수직 중앙 정렬
                            justifyContent: "center", // 수평 중앙 정렬
                            fontSize: "24px",
                            color: color.white,
                          }}
                        >
                          +
                        </Box>
                      </Box>
                    </>
                  )}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    hidden
                    multiple
                  />
                </Box>
                <VerticalSpacer height={24} />
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  You can upload up to 5 attachments, with each image file up to
                  10MB.
                </Typography>
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  Supported image formats: jpg, jpeg, gif, png.
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={4}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            padding: "24px",
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ width: "100%", padding: "0px" }}>
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              sx={{ padding: "0px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Country
                </Typography>
                <VerticalSpacer height={8} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CommonSelect
                    selectValue={getCountryName(createData.country)}
                    sx={{
                      minHeight: "48px",
                      flexGrow: 1,
                      fontStyle: fontStyle.regularS,
                      color: color.default,
                    }}
                    onChange={(event) => {
                      handleDetailDataChange("country", event.target.value);
                    }}
                    menuitems={Object.keys(countryCodeMap)}
                    placeholder={rows?.country}
                  />
                </Box>
                <VerticalSpacer height={40} />
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Show/Hide
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Radio
                      checked={createData.showHide === true}
                      onChange={() => {
                        handleDetailDataChange("showHide", true);
                      }}
                      value={true}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    Show
                  </Box>
                  <Box sx={{ ml: 5 }}>
                    <Radio
                      checked={createData.showHide === false}
                      onChange={() => {
                        handleDetailDataChange("showHide", false);
                      }}
                      value={false}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                    Hide
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Open Date
                </Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  value={LocalizedDate({
                    date: rows?.openDate,
                    type: DateFormatType.numeric,
                  })}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Open Time
                </Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  value={getTime()}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Writer
                </Typography>
                <TextField
                  name="description"
                  sx={{ width: "100%", mt: 1 }}
                  value={rows?.writer}
                  // onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
              </Box>
              <VerticalSpacer height={40} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  URL
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box component="img" src={ICON_CLIP}></Box>
                  <Box>
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.gray800}
                      sx={{
                        textDecoration: "underline",
                        ml: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        uiEvent.copyClipboard(`notice://${rows?.id}`)
                      }
                    >
                      Copy Link
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <CommonTextField
                value={`notice://${rows?.id}`}
                sx={{ mt: "8px" }}
                disabled
              />
              <Divider sx={{ mt: 3, mb: 3 }} />
              <Box>
                <Box
                  sx={{
                    display: "flex",

                    color: "#99989D",
                  }}
                >
                  <Typography fontSize={14}>등록 일시</Typography>
                  <Typography sx={{ ml: 3 }} fontSize={14}>
                    {LocalizedDate({
                      date: rows?.registrationDate,
                      type: DateFormatType.AdminType,
                    })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    color: "#99989D",
                    mt: 1,
                  }}
                >
                  <Typography fontSize={14}>수정 일시</Typography>
                  <Typography sx={{ ml: 3 }} fontSize={14}>
                    {LocalizedDate({
                      date: rows?.updateDate || "",
                      type: DateFormatType.AdminType,
                    })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    color: "#99989D",
                    mt: 1,
                  }}
                >
                  <Typography fontSize={14}>삭제 일시</Typography>
                  <Typography sx={{ ml: 3 }} fontSize={14}>
                    {LocalizedDate({
                      date: rows?.deleteDate || "",
                      type: DateFormatType.AdminType,
                    })}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AnnounceDetailsGrid;
