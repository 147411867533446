import MembershipInfo, { MembershipStatus } from "../model/MembershipInfo";
import ProductItem from "../model/ProductItem";

export class MembershipOverviewUiState {
  //Voucher Drawer
  voucherItems: ProductItem[] = [];
  selectedVoucher: ProductItem | null = null;
  isVoucherDrawerOpen: boolean = false;

  //Change Membership Drawer
  isChangeMembershipDialogOpen: boolean = false;
  changeMembershipItems: ProductItem[] = [];
  selectedMembership: ProductItem | null = null;
  isChangeMembershipDrawerOpen: boolean = false;

  //Membership Overview
  membershipInfo: MembershipInfo = {
    id: "0",
    status: true,
    voucherKm: 0,
    totalAllowance: 0,
    productId: "",
  };

  membershipStatus: MembershipStatus = MembershipStatus.ActiveWithinRenewal;
}
