import DocumentPageRender from "./DocumentPageRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { getLanguageCode, getLocale } from "../../../customHook/useLocale";
import { useEffect, useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import ProgressBar from "../../../component/ProgressBar";

export default function PrivacyPolicyPage() {
  const [privacyPolicyText, setPrivacyPolicyText] = useState("");
  const toolbarTitle = LocalizedText("ua_g732_policy");
  const [progress, setProgress] = useState(true);
  const language = getLanguageCode();

  useEffect(() => {
    if ("km" === language) {
      setPrivacyPolicyText(
        "បច្ចុប្បន្នមិនមានលក្ខខណ្ឌនៃការប្រើប្រាស់ដែលបានរៀបចំទេ។",
      );
    } else {
      setPrivacyPolicyText("There are currently no terms of use prepared.");
    }
  }, []);

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    setProgress(true);
    fetchPrivacyPolicy().then((r) => {});
  }, []);

  const fetchPrivacyPolicy = async () => {
    const url = "/pw/system/termsAndConditions/findOne";
    const requestBody = {
      language: language,
      type: "개인정보처리방침",
    };
    try {
      const res = await ApiService.MobilePost(url, requestBody);
      if (res?.data?.body) {
        setPrivacyPolicyText(res.data.body as string);
      }
    } catch (e) {
      console.log("PrivacyPolicyPage error:", e);
    } finally {
      setProgress(false);
    }
  };

  if (progress) {
    return <ProgressBar />;
  } else {
    return (
      <DocumentPageRender
        toolbarTitle={toolbarTitle}
        documentText={privacyPolicyText}
        scale={0.5}
      />
    );
  }
}
