import { Box, Typography } from "@mui/material";
import Toolbar from "../../../component/Toolbar";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { color } from "../../../theme/Color";
import { useEffect, useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import CouponHisCard from "./CouponHisCard";
import { fontStyle } from "../../../theme/Style";

const CouponHistoryRender = () => {
  const [couponData, setCouponData] = useState([]);
  useEffect(() => {
    ApiService.MobilePost("/pw/ua/product/coupon/used", {
      uuid: localStorage.getItem("uuid"),
    }).then((res) => {
      // console.log(res?.data.body);
      console.log(res?.data.body);
      setCouponData(res?.data.body);
    });
  }, []);
  const ua_g322_nocoupon = LocalizedText("ua_g322_nocoupon");
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
          bgcolor: color.gray50,
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g322_couponhistory")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        <VerticalSpacer height={17} />
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            width: "calc(100% - 40px)",
            scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
            "&::-webkit-scrollbar": {
              display: "none", // Webkit 브라우저에서    스크롤바 숨기기
            },
          }}
        >
          {couponData.length > 0 ? (
            couponData?.map((data: any, key: any) => (
              <Box key={key}>
                <CouponHisCard
                  state={data.state}
                  useWhere={data.name}
                  content={data.description}
                  couponId={data.id}
                  issuedDate={LocalizedDate({
                    date: data.issuedDate,
                    type: DateFormatType.YearMonthDay,
                  })}
                  expiredOrUsedDate={
                    data.state == "Used"
                      ? LocalizedDate({
                          date: data.usedDate,
                          type: DateFormatType.YearMonthDay,
                        })
                      : LocalizedDate({
                          date: data.expiredDate,
                          type: DateFormatType.YearMonthDay,
                        })
                  }
                />
                <VerticalSpacer height={12} />
              </Box>
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                {ua_g322_nocoupon}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CouponHistoryRender;
