import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MonitoringFormData } from "../../../store/monitoringType";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import TrashIcon from "../assets/icon/TrashIcon.svg";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  TextField,
  Input,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Grid
} from "@mui/material";
import { Divider } from "antd";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommonList from "../../../component/CommonList";
import CreateMonitoringGridPage from "./CreateMonitoringGridPage";
import TextEditor from "../../../component/TextEditor";
import MonitoringReductionTable from "../section/MonitoringReductionTable";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import MonitoringCalcPage from "./components/MonitoringCalcPage";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import axios from "axios";
import { MonitoringContext } from "../../../di/MonitoringProvider";

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "48px",
  padding: "12px 16px",
  gap: "2px",
  borderRadius: "10px 0px 0px 0px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "0",
  "& .MuiInputBase-root": {
    height: "48px"
  }
}));
interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}

const API_URL = SERVER_CONFIG.CARBON_SERVER + "/monitoring";
const CreateMonitoringPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isNew = !id;

  const [formData, setFormData] = useState<MonitoringFormData>({
    projectId: 0,
    methodologyId: 0,
    projectContent: "",
    businessOwnership: "",
    businessAssignment: "",
    prePostProcess: "",
    businessPerformanceStatus: "",
    changeAfterBusinessApproval: "",
    isVersionUpdate: false,
    monitoringMethodology: "",
    monitoringOrganization: "",
    monitoringResources: "",
    dataArgumentValueIdList: [],
    formulaDescriptionBaseline: "",
    formulaDescriptionProject: "",
    startDate: "",
    endDate: "",
    formulaDescriptionReduction: "",
    verificationAgencyName: "",
    verificationStatementFile: "",
    verificationReductionAmount: 0,
    formulaBaseline: "",
    formulaProject: ""

    // degree: 0,
    // version: 0
  });
  const [version, setVersion] = useState("1.0"); // Initial version
  const [checked, setChecked] = useState<boolean>(formData.isVersionUpdate);
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<"delete" | "navigate" | null>(
    null
  );
  const [error, setError] = useState<string | undefined>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [tabIndex, setTabIndex] = useState(0);
  const [projectId, setProjectId] = useState(0);
  const { setTmpBaseline, setTmpProject } = useContext(MonitoringContext);
  const [fileName, setFileName] = useState<string | null>(null);

  const [previousMonitoringPeriod, setPreviousMonitoringPeriod] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: ""
  });
  useEffect(() => {
    if (formData.verificationStatementFile) {
      if (typeof formData.verificationStatementFile === "string") {
        const fileNameFromUrl =
          formData.verificationStatementFile.split("/").pop() || "Unknown File";
        setFileName(fileNameFromUrl);
      } else {
        setFileName(formData.verificationStatementFile.name);
      }
    }
  }, [formData.verificationStatementFile]);
  useEffect(() => {
    const getMonitoringData = async () => {
      setLoading(true);
      const res = await axios.get(
        SERVER_CONFIG.CARBON_SERVER + `/monitoring/${id}`
      );

      const { data } = res;
      setFormData({
        ...data,
        dataArgumentValueIdList: {
          baseline: data.dataArgumentValueList.baseline.map(
            (el: any) => el?.id
          ),
          project: data.dataArgumentValueList.project.map((el: any) => el?.id),
        },
      });
      setProjectId(data.projectId);
    };
    if (id) {
      getMonitoringData();
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    return () => {
      setTmpBaseline([]);
      setTmpProject([]);
    };
  }, []);

  const isPeriodDifferent =
    formData.startDate !== previousMonitoringPeriod.startDate ||
    formData.endDate !== previousMonitoringPeriod.endDate;

  const handleSave = async () => {
    setLoading(true);
    const dataToSend = id
      ? {
          projectId: projectId,
          projectContent: formData.projectContent,
          businessOwnership: formData.businessOwnership,
          businessAssignment: formData.businessAssignment,
          prePostProcess: formData.prePostProcess,
          businessPerformanceStatus: formData.businessPerformanceStatus,
          changeAfterBusinessApproval: formData.changeAfterBusinessApproval,
          isVersionUpdate: checked ? true : false,
          monitoringMethodology: formData.monitoringMethodology,
          monitoringOrganization: formData.monitoringOrganization,
          monitoringResources: formData.monitoringResources,
          dataArgumentValueIdList: formData?.dataArgumentValueIdList,
          formulaDescriptionBaseline: formData.formulaDescriptionBaseline,
          formulaDescriptionProject: formData.formulaDescriptionProject,
          startDate: formData.startDate,
          endDate: formData.endDate,
          formulaDescriptionReduction: formData.formulaDescriptionReduction,
          verificationAgencyName: formData.verificationAgencyName,
          verificationStatementFile: formData.verificationStatementFile,
          verificationReductionAmount: formData.verificationReductionAmount,
          formulaBaseline: formData.formulaBaseline,
          formulaProject: formData.formulaProject
        }
      : {
          ...formData,
          dataArgumentValueIdList: formData?.dataArgumentValueIdList,
          version: checked ? formData.version : 0,
          verificationStatementFile: selectedFile
            ? selectedFile.name
            : formData.verificationStatementFile
        };

    try {
      let uploadedFileUrl = formData.verificationStatementFile;

      if (formData.verificationStatementFile instanceof File) {
        const formDataToUpload = new FormData();
        formDataToUpload.append("file", formData.verificationStatementFile);

        const uploadResponse = await fetch(
          "https://api-kisa.greenery.im/greenery/api/bucket/upload",
          {
            method: "POST",
            body: formDataToUpload
          }
        );

        if (!uploadResponse.ok) {
          throw new Error(
            `File upload failed: ${uploadResponse.statusText} (${uploadResponse.status})`
          );
        }

        const uploadResult = await uploadResponse.json();

        if (uploadResult.result) {
          uploadedFileUrl = uploadResult.objectUrl;
        } else {
          throw new Error("File upload failed.");
        }
      } else {
        uploadedFileUrl = formData.verificationStatementFile
      }

      const apiUrl = isNew
        ? SERVER_CONFIG.CARBON_SERVER + "/monitoring"
        : SERVER_CONFIG.CARBON_SERVER + `/monitoring/${id}`;

      const method = id ? "PUT" : "POST";

      await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...dataToSend,
          verificationStatementFile: uploadedFileUrl
        })
      })
        .then((res) => {
          console.log("response:", res);
          if (res.ok) {
            setTmpBaseline([]);
            setTmpProject([]);

            setIsSaved(true);
            navigate("/monitoringMng");
          } else {
            throw new Error("Failed to save data");
          }
        })
        .catch((e) => {
          throw new Error("Failed to save data");
        });
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = useCallback(async () => {
    const response = await fetch(`${API_URL}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error("Failed to save data");
    }
    setTmpBaseline([]);
    setTmpProject([]);
    navigate("/monitoringMng");
  }, [id]);

  const handleConfirmNavigate = () => {
    // Your navigate confirmation logic here
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (name === "verificationReductionAmount") {
      const numericValue = parseFloat(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        verificationReductionAmount: isNaN(numericValue) ? 0 : numericValue
      }));
    } else if (name === "version") {
      const numericValue = parseFloat(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        version: isNaN(numericValue) ? 0 : numericValue
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const handleTextFieldChangeReduction = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleTextChange = (fieldName: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value
    }));
  };

  const handleClickOpenDialog = (type: "delete" | "navigate") => {
    setDialogType(type);
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
  };

  useEffect(() => {
    setChecked(formData.isVersionUpdate);
  }, [formData.isVersionUpdate, isNew]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setChecked(checked);
    setFormData((prevData) => ({
      ...prevData,
      isVersionUpdate: checked,
      version: checked ? prevData.version : 0
    }));
  };
  const shouldDisableCheckbox = isNew;
  const handleUploadClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const HeaderButton = () => (
    <form action="" style={{ display: "flex", alignItems: "center" }}>
      <Button
        sx={{
          width: "113px",
          height: "45px",
          borderRadius: "40px",
          padding: "20px, 0px, 20px, 0",
          background: "#5D38E5",
          color: "#FFFFFF",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textWeight: "600",
          textSize: "14px",
          lineHeight: "20px",
          "&:hover": {
            background: " #5a3acb"
          }
        }}
        onClick={handleSave}
        disabled={loading}
      >
        {!isNew ? "Update" : "Create"}
      </Button>
      {!isNew && (
        <>
          <Button
            sx={{
              width: "113px",
              height: "45px",
              borderRadius: "40px",
              padding: "20px, 0px, 20px, 0",
              textTransform: "none",
              backgroundColor: "white",
              color: "red",
              ":hover": {
                backgroundColor: "red",
                color: "white"
              },
              marginLeft: "10px"
            }}
            onClick={() => handleClickOpenDialog("delete")}
            disabled={loading}
          >
            Delete
          </Button>
        </>
      )}
    </form>
  );
  const HeaderBackButton = () => (
    <IconButton
      sx={{ ":hover": { backgroundColor: "#F2F2F2" }, mb: 0.5, mr: 1 }}
      onClick={() => handleNavigate("/monitoringMng")}
    >
      <ArrowBackIosNewIcon fontSize="medium" />
    </IconButton>
  );

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    console.log("file name ------>", fileName);
    if (file) {
      const newFileName = file.name;
      setFileName(newFileName);
      console.log("FILE NAME: ", file.name);
      setFormData((prevData) => ({
        ...prevData,
        verificationStatementFile: file
      }));
    }
  };

  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,
          lineHeight: "18px",
          fontSize: "14px",
          fontWeight: 700,
          marginLeft: "25px"
        }}
      >
        {children}
      </Typography>
    );
  };
  return (
    <CommonList
      title={id ? "모니터링 보고서" : "모니터링 보고서 등록"}
      setModal={null}
      headerButton={<HeaderButton />}
      headerBackButton={<HeaderBackButton />}
    >
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        sx={{
          marginBottom: 1,
          "& .MuiTabs-indicator": {
            backgroundColor: "#5D28E5",
            fontWeight: "bold",
          },
          "& .MuiTab-root": {
            color: "black",
            fontSize: "1.2rem",
            fontWeight: "bold",
          },
          "& .Mui-selected": {
            color: "black",
            fontSize: "1.2rem",
          },
        }}
        textColor="inherit"
      >
        <Tab label="프로젝트 정보" />
        <Tab label="모니터링 정보" />
        <Tab label="감축량 계산" />
        <Tab label="검증 정보" />
      </Tabs>
      {tabIndex === 0 && (
        <CreateMonitoringGridPage
          formData={formData}
          setFormData={setFormData}
          isNew={!id}
          error={error}
          handleTextChange={handleTextChange}
          handlePageChange={() => {}}
        />
      )}
      {tabIndex === 1 && (
        <>
          <Card
            sx={{
              marginTop: "40px",
              borderRadius: "20px",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    gap: 1,
                  }}
                >
                  <CustomTypography>모니터링 버전</CustomTypography>
                  {formData.isVersionUpdate ? (
                    <CustomTextField
                      value={formData.version?.toString() || "0"}
                      onChange={handleTextFieldChange}
                      name="version"
                      disabled={!checked}
                    />
                  ) : (
                    <CustomTypography
                      sx={{
                        mt: 1,
                        borderRadius: "10px",
                        fontSize: "20px",
                        padding: "8px",
                        border: "1px solid #ccc",
                        minHeight: "42px",
                      }}
                    >
                      {formData.version ? `v${formData.version}` : "v1"}
                    </CustomTypography>
                  )}

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 16,
                            },
                          }}
                          disabled={shouldDisableCheckbox}
                        />
                      }
                      label="버전 업데이트"
                      sx={{ marginLeft: "20px" }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    mt: "10px",
                    borderRadius: "10px",
                    minHeight: "42px",
                  }}
                >
                  <CustomTypography>모니터링 차수</CustomTypography>
                  <CustomTextField
                    sx={{
                      minHeight: "42px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                    value={formData.degree ? formData.degree.toString() : "1차"}
                    size="small"
                    name="degree"
                    onChange={handleTextFieldChange}
                    disabled={!checked}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              marginTop: "20px",
              borderRadius: "20px",
            }}
          >
            <CardContent sx={{ mt: 2 }}>
              <Box>
                <Box sx={{ width: "100%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    모니터링 방법론
                  </CustomTypography>
                  <Box
                    sx={{
                      mt: 2,
                      maxWidth: "100%",
                      width: "1018px",
                      marginLeft: "25px",
                    }}
                  >
                    <TextEditor
                      setData={(val: string) => {
                        handleTextChange("monitoringMethodology", val);
                      }}
                      minHeight={120}
                      data={formData.monitoringMethodology}
                    />
                  </Box>

                  <CustomTypography sx={{ fontWeight: "bold", mt: 2 }}>
                    모니터링 조직
                  </CustomTypography>
                  <Box
                    sx={{
                      mt: 2,
                      maxWidth: "100%",
                      width: "1018px",
                      marginLeft: "25px",
                    }}
                  >
                    <TextEditor
                      setData={(val: string) => {
                        handleTextChange("monitoringOrganization", val);
                      }}
                      minHeight={120}
                      data={formData.monitoringOrganization}
                    />
                  </Box>

                  <CustomTypography sx={{ fontWeight: "bold", mt: 2 }}>
                    관련자료 수집 및 보관
                  </CustomTypography>
                  <Box
                    sx={{
                      mt: 2,
                      maxWidth: "100%",
                      width: "1018px",
                      marginLeft: "25px",
                      mb: 4,
                    }}
                  >
                    <TextEditor
                      setData={(val: string) => {
                        handleTextChange("monitoringResources", val);
                      }}
                      minHeight={120}
                      data={formData.monitoringResources}
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </>
      )}
      {tabIndex === 2 && (
        <MonitoringCalcPage
          id={id}
          formData={formData}
          handleTextChange={handleTextChange}
          setFormData={setFormData}
        />
      )}
      {tabIndex === 3 && (
        <>
          {/* <p>{JSON.stringify(formData)}</p> */}
          <Card
            sx={{
              borderRadius: 3,
              minHeight: 240,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <CardContent sx={{ width: "100%", height: "100%" }}>
              <CardHeader
                title={"검증 정보"}
                titleTypographyProps={{ fontWeight: "bold", fontSize: 20 }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {/* 첫번째 줄 */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "100%" }}>
                    <CustomTypography sx={{ fontWeight: "bold" }}>
                      3자 검증 기관명
                    </CustomTypography>
                    <CustomTextField
                      size="small"
                      value={formData?.verificationAgencyName}
                      name="verificationAgencyName"
                      onChange={handleTextFieldChange}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  mt: 2,
                }}
              >
                {/* 첫번째 줄 */}
                <Box
                  sx={{
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <CustomTypography sx={{ fontWeight: "bold" }}>
                      검증 의견서 등록
                    </CustomTypography>
                    <Typography
                      component="span"
                      onClick={handleUploadClick}
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        textDecoration: "underline",
                        marginRight: "20px",
                        cursor: "pointer",
                        "&:hover": {
                          color: "primary.dark",
                        },
                      }}
                    >
                      Upload File
                    </Typography>
                  </Box>
                  <input
                    type="file"
                    id="fileInput"
                    name="uploadedFile"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <CustomTextField
                    size="small"
                    value={fileName}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    aria-readonly
                    onChange={handleTextFieldChangeReduction}
                    name="verificationStatementFile"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  mt: 2,
                }}
              >
                {/* 첫번째 줄 */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "100%" }}>
                    <CustomTypography sx={{ fontWeight: "bold" }}>
                      검증 후 온실가스 감축량
                    </CustomTypography>
                    <CustomTextField
                      size="small"
                      type="number"
                      value={formData.verificationReductionAmount}
                      name="verificationReductionAmount"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={handleTextFieldChange}
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </>
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            maxWidth: "400px",
            width: "90%",
            borderRadius: "25px",
            backgroundColor: "#ffffff",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "1rem", sm: "1.5rem" },
            color: "black",
            textAlign: "center",
            fontSize: { xs: "0.875rem", sm: "1rem" },
          }}
        >
          {dialogType === "delete"
            ? "삭제한 방법론은 복구할 수 없습니다. 방법론을 삭제 하시겠습니까?"
            : "작성하던 것을 중단하고 이동하시겠습니까? 해당 사항은 저장되지 않습니다."}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "1rem",
            backgroundColor: "#ffffff",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleCloseDialog}
            sx={{
              width: "120px",
              height: "40px",
              borderRadius: 5,
              border: "1px solid #E1E1E1",
              color: "text.primary",
              ":hover": {
                backgroundColor: "#F2F2F2",
              },
            }}
          >
            Cancel
          </Button>
          {dialogType === "delete" && (
            <Button
              onClick={handleDelete}
              color="error"
              sx={{
                width: "120px",
                height: "40px",
                borderRadius: 5,
                backgroundColor: "#5D38E5",
                color: "white",
                ":hover": {
                  backgroundColor: "#5f359f",
                },
              }}
            >
              Delete
            </Button>
          )}
          {dialogType === "navigate" && (
            <Button onClick={handleConfirmNavigate}>OK</Button>
          )}
        </DialogActions>
      </Dialog>
    </CommonList>
  );
};

export default CreateMonitoringPage;
