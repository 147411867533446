import { MoreChargingMembershipSideEffect } from "./MoreChargingMembershipSideEffect";
import { ROUTE_PATHS } from "../../constants/RouteConstants";
import { MoreChargingMembershipUiState } from "./MoreChargingMembershipUiState";
import { Box } from "@mui/system";
import Toolbar from "../../component/Toolbar";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import MembershipOverviewPage from "./membershipOverview/MembershipOverviewPage";
import MembershipPurchasePage from "./membershipPurchase/MembershipPurchasePage";

interface MoreChargingMembershipRenderProps {
  uiState: MoreChargingMembershipUiState;
  sideEffect: MoreChargingMembershipSideEffect;
}

const MoreChargingMembershipRender = ({
  uiState,
  sideEffect,
}: MoreChargingMembershipRenderProps) => {
  return (
    <>
        {uiState.hasMembership ? (
          <MembershipOverviewPage userUUID={uiState.userUUID} />
        ) : (
          <MembershipPurchasePage />
        )}
    </>
  );
};

export default MoreChargingMembershipRender;
