export class ProfilePageUiState {
  name: string;
  email: string;
  phoneNumber: string;
  birthday: number;
  gender: string;
  address: string;
  countryNumber: string;
  tribeCategory: string;
  tribeName: string;

  constructor(
    name: string = "",
    email: string = "",
    phoneNumber: string = "",
    birthday: number = 0,
    gender: string = "",
    address: string = "",
    countryNumber: string = "",
    tribeCategory: string = "",
    tribeName: string = ""
  ) {
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.birthday = birthday;
    this.gender = gender;
    this.address = address;
    this.countryNumber = countryNumber;
    this.tribeCategory = tribeCategory;
    this.tribeName = tribeName;
  }
}
