import { Box, Button, Typography } from "@mui/material";
import CommonCardDialog from "../../../component/dialog/CommonCardDialog";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import CommonTextField from "../../../component/CommonTextField";
import { MultilingualUiState } from "../model/MultilingualUiState";
import { MultilingualUiEvent } from "../model/MultilingualUiEvent";
import { CommonSelect } from "../../../component/CommonSelect";
import CommonErrorText from "../../../component/CommonErrorText";
import { ApiService } from "../../../restAPI/ApiService";

interface CreateModalProps {
  uiState: MultilingualUiState;
  uiEvent: MultilingualUiEvent;
}

const CreateModal = ({ uiState, uiEvent }: CreateModalProps) => {
  return (
    <CommonCardDialog
      isOpen={uiState.CreateDialogOpen}
      onClose={() => {
        uiEvent.HandleUiStateChange("CreateDialogOpen", false);
        uiEvent.HandleUiStateChange("Code", "");
        uiEvent.HandleUiStateChange("Description", "");
        uiEvent.HandleUiStateChange("Language", "");
        uiEvent.HandleUiStateChange("State", "");
        uiEvent.HandleUiStateChange("Translation", "");
        uiEvent.HandleUiStateChange("Type", "");
        uiEvent.HandleUiStateChange("CodeError", false);
        uiEvent.HandleUiStateChange("LanguageError", false);
        uiEvent.HandleUiStateChange("TranslationError", false);
        uiEvent.HandleUiStateChange("TypeError", false);
        uiEvent.HandleUiStateChange("StateError", false);
        uiEvent.HandleUiStateChange("DescriptionError", false);
      }}
      children={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "510px",
          }}
        >
          <Typography sx={{ fontStyle: fontStyle.semiboldL }}>
            Create
          </Typography>
          <VerticalSpacer height={16} />
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Typography
              fontStyle={fontStyle.boldXS}
              color={color.gray600}
              sx={{ width: "80px" }}
            >
              Code
            </Typography>
            <HorizontalSpacer width={16} />

            <CommonTextField
              value={uiState.Code}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("Code", event.target.value);
              }}
              sx={{ width: "100%", height: "40px" }}
              InputProps={{
                sx: {
                  height: "40px",
                },
              }}
              error={uiState.CodeError}
            />
          </Box>
          {uiState.CodeError && (
            <CommonErrorText
              value="Please enter the code."
              sx={{ ml: "92px", mt: "4px" }}
            />
          )}
          <VerticalSpacer height={40} />
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Typography
              fontStyle={fontStyle.boldXS}
              color={color.gray600}
              sx={{ width: "80px" }}
            >
              Language
            </Typography>
            <HorizontalSpacer width={16} />
            <CommonSelect
              sx={{
                height: "40px",
                width: "100%",
              }}
              menuitems={[
                "Cambodian(km_KH)",
                "English(en_US)",
                "Korean(ko_KR)",
                "Vietnamese(vi_VN)",
              ]}
              selectValue={uiState.Language}
              onChange={(event) => {
                if (event.target.value == "Cambodian(km_KH)") {
                  uiEvent.HandleUiStateChange("Language", "km");
                } else if (event.target.value == "English(en_US)") {
                  uiEvent.HandleUiStateChange("Language", "en");
                } else if (event.target.value == "Korean(ko_KR)") {
                  uiEvent.HandleUiStateChange("Language", "ko");
                } else if (event.target.value == "Vietnamese(vi_VN)") {
                  uiEvent.HandleUiStateChange("Language", "vi");
                }
              }}
              placeholder="Select"
              error={uiState.LanguageError}
            />
          </Box>
          {uiState.LanguageError && (
            <CommonErrorText
              value="Please select a language."
              sx={{ ml: "92px", mt: "4px" }}
            />
          )}
          <VerticalSpacer height={40} />
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Typography
              fontStyle={fontStyle.boldXS}
              color={color.gray600}
              sx={{ width: "80px" }}
            >
              Translation
            </Typography>
            <HorizontalSpacer width={16} />
            <CommonTextField
              value={uiState.Translation}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("Translation", event.target.value);
              }}
              sx={{ width: "100%", height: "40px" }}
              InputProps={{
                sx: {
                  height: "40px",
                },
              }}
              error={uiState.TranslationError}
            />
          </Box>
          {uiState.TranslationError && (
            <CommonErrorText
              value="Please enter a translation."
              sx={{ ml: "92px", mt: "4px" }}
            />
          )}
          <VerticalSpacer height={40} />
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Typography
              fontStyle={fontStyle.boldXS}
              color={color.gray600}
              sx={{ width: "80px" }}
            >
              Type
            </Typography>
            <HorizontalSpacer width={16} />
            <CommonSelect
              sx={{
                height: "40px",
                width: "100%",
              }}
              menuitems={[
                "page",
                "alert",
                "system_alert",
                "dropdown",
                "modal",
                "infotip",
                "toast",
                "notification",
              ]}
              selectValue={uiState.Type}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("Type", event.target.value);
              }}
              placeholder="Select"
              error={uiState.TypeError}
            />
          </Box>
          {uiState.TypeError && (
            <CommonErrorText
              value="Please select a type."
              sx={{ ml: "92px", mt: "4px" }}
            />
          )}
          <VerticalSpacer height={40} />
          {/* <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Typography
              fontStyle={fontStyle.boldXS}
              color={color.gray600}
              sx={{ width: "80px" }}
            >
              State
            </Typography>
            <HorizontalSpacer width={16} />
            <CommonSelect
              sx={{
                height: "40px",
                width: "100%",
              }}
              menuitems={["Active", "Not Use", "Deleted"]}
              selectValue={uiState.State}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("State", event.target.value);
              }}
              placeholder="Select"
              error={uiState.StateError}
            />
          </Box>
          {uiState.StateError && (
            <CommonErrorText
              value="Please select a state."
              sx={{ ml: "92px", mt: "4px" }}
            />
          )}
          <VerticalSpacer height={40} /> */}
          <Box
            sx={{ display: "flex", width: "100%", alignItems: "flex-start" }}
          >
            <Typography
              fontStyle={fontStyle.boldXS}
              color={color.gray600}
              sx={{ width: "80px", mt: "4px" }}
            >
              Description
            </Typography>
            <HorizontalSpacer width={16} />
            <CommonTextField
              value={uiState.Description}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("Description", event.target.value);
              }}
              sx={{ width: "100%" }}
              rows={4}
              multiline
              //   InputProps={{
              //     sx: {
              //       height: "40px",
              //     },
              //   }}
            />
          </Box>
          <VerticalSpacer height={40} />
        </Box>
      }
      button={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            sx={{
              width: "auto",
              height: `40px`,
              borderRadius: `10px`,
              backgroundColor: "white",
              textTransform: "none",
              zIndex: 1200,
              color: color.gray600,
            }}
            onClick={() => {
              uiEvent.HandleUiStateChange("CreateDialogOpen", false);
              uiEvent.HandleUiStateChange("Code", "");
              uiEvent.HandleUiStateChange("Description", "");
              uiEvent.HandleUiStateChange("Language", "");
              uiEvent.HandleUiStateChange("State", "");
              uiEvent.HandleUiStateChange("Translation", "");
              uiEvent.HandleUiStateChange("Type", "");
              uiEvent.HandleUiStateChange("CodeError", false);
              uiEvent.HandleUiStateChange("LanguageError", false);
              uiEvent.HandleUiStateChange("TranslationError", false);
              uiEvent.HandleUiStateChange("TypeError", false);
              uiEvent.HandleUiStateChange("StateError", false);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: "70px",
              height: `40px`,
              borderRadius: `10px`,
              backgroundColor: color.primary500,
              textTransform: "none",
              ":hover": {
                backgroundColor: color.toolingPurple500Hover,
              },
              zIndex: 1200,
              color: "white",
            }}
            onClick={() => {
              // 데이터 유효성 검사
              const isCodeValid = uiState.Code.trim() !== "";
              const isLanguageValid = uiState.Language.trim() !== "";
              const isTranslationValid = uiState.Translation.trim() !== "";
              const isTypeValid = uiState.Type.trim() !== "";
              // const isStateValid = uiState.State.trim() !== "";

              // 오류 상태 업데이트
              uiEvent.HandleUiStateChange("CodeError", !isCodeValid);
              uiEvent.HandleUiStateChange("LanguageError", !isLanguageValid);
              uiEvent.HandleUiStateChange(
                "TranslationError",
                !isTranslationValid
              );
              uiEvent.HandleUiStateChange("TypeError", !isTypeValid);
              // uiEvent.HandleUiStateChange("StateError", !isStateValid);

              // 모든 필드가 유효한 경우에만 Post 처리
              if (
                isCodeValid &&
                isLanguageValid &&
                isTranslationValid &&
                isTypeValid
                // isStateValid
              ) {
                ApiService.WebPost("/pw/aw/system/multilingual/create", {
                  code: uiState.Code,
                  language: uiState.Language,
                  translate: uiState.Translation,
                  type: uiState.Type,
                  state: uiState.State,
                  description: uiState.Description,
                }).then((res) => {
                  if (res?.data.message == "create complete") {
                    window.location.reload();
                  }
                });
              } else {
                console.log("Invalid data");
              }
            }}
          >
            Post
          </Button>
        </Box>
      }
    />
  );
};

export default CreateModal;
