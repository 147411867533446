import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { CsDetailsSideEffect } from "../model/CsDetailsSideEffect";
import { CsDetailsUiEvent } from "../model/CsDetailsUiEvent";
import { CsDetailsUiState } from "../model/CsDetailsUiState";

function priceRow(count: number, unit: number) {
  return count * unit;
}

function createRow(desc: string, count: number, unit: number) {
  const price = priceRow(count, unit);
  return { desc, count, unit, price };
}

interface Row {
  desc: string;
  qty: number;
  unit: number;
  price: number;
}

function subtotal(items: readonly Row[]) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

interface SpanningTableProps {
  uiState: CsDetailsUiState;
  uiEvent: CsDetailsUiEvent;
  sideEffect: CsDetailsSideEffect;
}

export default function SpanningTable({
  uiState,
  uiEvent,
  sideEffect,
}: SpanningTableProps){
  return (
    <Box>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "35%" }} align="left">
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Name
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }} align="left">
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Count
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "25%" }} align="left">
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Price
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "25%" }} align="left">
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Amount
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            id="test"
          >
            {uiState?.status === "Receipt" && uiState?.repairFee?.map((row: any, index) => (
              <TableRow
              key={index}
                sx={{
                  ".MuiTableCell-root": {
                    border: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  },
                }}
              >
                <TableCell>
                  <TextField
                    disabled={index === 0}
                    sx={{
                      backgroundColor: `${index === 0 ? color.disableColor : ""}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    value={row?.name}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    onChange={(event) => {
                      uiEvent.onChangeRepairFeeLineName(event.target.value, index);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    disabled={index === 0}
                    sx={{
                      backgroundColor: `${index === 0 ? color.disableColor : ""}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{
                      min: 0,
                      maxLength: 3,
                      pattern: "/^[0-9]*$",
                      style: {
                        textAlign: "right",
                      }
                    }}
                    value={row?.count}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    onChange={(event) => {
                      uiEvent.onChangeRepairFeeLineCount(Number(event.target.value), index);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    sx={{
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{
                      min: 0,
                      maxLength: 10,
                      pattern: "/^[0-9]\.*$",
                      style: {
                        textAlign: "right",
                      }
                    }}
                    value={row?.price}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    onChange={(event) => {
                      uiEvent.onChangeRepairFeeLinePrice(parseFloat(event.target.value), index);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${index === 0 ? color.disableColor : ""}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{
                      min: 0,
                      maxLength: 50,
                      pattern: "/^[0-9]\.*$",
                      style: {
                        textAlign: "right",
                      }
                    }}
                    value={row?.amount}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {uiState?.status === "Complete" && uiState?.repairFee?.map((row: any) => (
              <TableRow
                key={row.name}
                sx={{
                  ".MuiTableCell-root": {
                    border: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  },
                }}
              >
                <TableCell>
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${color.disableColor}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    value={row.name}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${color.disableColor}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{ min: 0, style: { textAlign: "right" } }}
                    value={row.count}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${color.disableColor}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{ min: 0, style: { textAlign: "right" } }}
                    value={(row?.price).toLocaleString("en-US")}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${color.disableColor}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{ min: 0, style: { textAlign: "right" } }}
                    value={(row?.amount).toLocaleString("en-US")}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {uiState?.status === "Receipt" && (
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
        }}>
          <IconButton
            sx={{
              backgroundColor: "#53515A",
              color: "white",
              ":hover": { backgroundColor: "#8E8E8E" },
            }}
            onClick={() => {
              uiEvent.onAddNewRepairFeeRow();
            }}
          >
            <AddIcon fontSize={"large"} />
          </IconButton>
        </Box>
      )}

      <Divider sx={{ mt: 3 }} />
      <Box sx={{
        mt: 1,
        display: "flex",
        justifyContent: "space-between"
      }}>
        <Typography fontSize={30} fontWeight={"bold"}>
          Total Fee
        </Typography>
        <Typography fontSize={30} fontWeight={"bold"}>
          $ {(uiState?.totalFee).toLocaleString("en-US")}
        </Typography>
      </Box>
      <Divider sx={{ mt: 3 }} />
    </Box>
  );
};
