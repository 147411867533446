import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import CommonList from "../../../component/CommonList";
import CommonSearch from "../../../component/CommonSearch";
import { Methodology } from "../../../store/methodologyType";
import MethodologyTable from "../section/MethodologyTable";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import axios from "axios";

const MethodologyMngListPage = () => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<Methodology[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>("");
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [methodologyType, setMethodologyType] = useState<string>("ALL");
  const [standardFilter, setStandardFilter] = useState<string>("ALL");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const API_URL = SERVER_CONFIG.CARBON_SERVER + "/methodology";

  // Debounce the search query
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 900);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params: Record<string, any> = {
        page,
        size: rowsPerPage,
        sortField,
        sortDirection,
        ...(methodologyType !== "ALL" && { methodologyType }),
        ...(standardFilter !== "ALL" && { standard: standardFilter }),
        ...(debouncedSearchQuery && {
          searchValue: debouncedSearchQuery.trim()
        })
      };

      const response = await axios.get(API_URL, { params });

      if (response.data && response.data.content) {
        setData(response.data.content);
        setTotalCount(response.data.totalElements || 0);
      } else {
        throw new Error("Unexpected data format");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching data:", {
          message: error.message,
          response: error.response?.data,
          config: error.config
        });
        setError(`Error fetching data: ${error.message}`);
      } else {
        console.error("Unexpected error fetching data", error);
        setError("Unexpected error fetching data");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    rowsPerPage,
    methodologyType,
    standardFilter,
    sortField,
    sortDirection,
    debouncedSearchQuery
  ]);

  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleStandardFilterChange = (event: SelectChangeEvent<string>) => {
    setStandardFilter(event.target.value || "ALL");
    setPage(0);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const headerButton = () => (
    <Button
      sx={{
        width: "113px",
        height: "45px",
        borderRadius: "40px",
        padding: "20px, 0px, 20px, 0",
        background: "#5D38E5",
        color: "#FFFFFF",
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textWeight: "600",
        textSize: "14px",
        lineHeight: "20px",
        "&:hover": {
          background: " #5a3acb"
        }
      }}
      onClick={() => navigate("/methodologyMng/createMethodology")}
    >
      <AddIcon fontSize="small" />
      Create
    </Button>
  );

  return (
    <CommonList
      title="방법론 관리"
      headerButton={headerButton()}
      sx={{
        border: "none",
        outline: "none",
        borderBottom: "none",
        minHeight: "42px",
      }}
    >
      <CommonSearch
        setRowsPerPage={handleRowsPerPageChange}
        setSearchContents={(val: any) => setSearchQuery(val)}
        sx={{
          minWidth: "108px",
          maxWidth: "600px",
          minHeight: "42px",
          opacity: 1,
          backgroundColor: "#ffff",
          color: "#99989D",
          fontWeight: 600,
          fontSize: "12px",
        }}
      >
        <Select
          id="standardFilter"
          value={standardFilter}
          onChange={handleStandardFilterChange}
          size="small"
          IconComponent={(props) => (
            <KeyboardArrowDown
              {...props}
              sx={{ fontSize: "16px", fontColor: "#99989D" }}
            />
          )}
          sx={{
            width: "13%",
            ml: 2,
            backgroundColor: "white",
            maxWidth: "600px",
            minHeight: "42px",
            fontWeight: 800,
            fontSize: "12px",
            border: "0px solid #E9E9EA",

            "&:hover": {
              borderColor: "#F9F6FD",
            },
            "& > fieldset": {
              border: 0,
              outline: 0,
            },
          }}
          style={{
            borderRadius: "10px",
            fontWeight: 600,
            color: "#99989D",
            fontSize: "12px",
            border: "0px solid #E9E9EA",
            outline: 0,
          }}
        >
          <MenuItem
            value="ALL"
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            All
          </MenuItem>
          <MenuItem
            value="CDM"
            sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
          >
            CDM
          </MenuItem>
          <MenuItem
            value="DOMESTIC"
            sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
          >
            국내 방법론
          </MenuItem>
          <MenuItem
            value="INTERNATIONAL"
            sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
          >
            국외 방법론
          </MenuItem>
        </Select>
      </CommonSearch>
      <MethodologyTable
        rowsPerPage={rowsPerPage}
        rows={data}
        page={page}
        count={totalCount}
        onChangePage={handleChangePage}
        data={data}
      />
    </CommonList>
  );
};

export default MethodologyMngListPage;
