export interface Methodology {
  No: number;
  id: number;
  standard: string;
  title: string;
  methodologyIdName: string;
  version: string;
  startDate: string;
  createdAt: string;
  area?: string;
  subArea?: string;
}

export function getAreaType(area: any): string {
  switch (area) {
    case "REDUCTION":
      return "탄소제거";
    case "REMOVAL":
      return "탄소감축";
    default:
      return "-";
  }
}

export function getSubAreaType(subArea: any): string {
  switch (subArea) {
    case "FORESTRY":
      return "산림";
    case "BIOCHAR":
      return "바이오차";
    case "SOIL_CARBON":
      return "토양탄소";
    case "DAC":
      return "DAC";
    case "RENEWABLE_ENERGY":
      return "재생에너지";
    case "E_MOBILITY":
      return "e-mobility";
    case "RECYCLE":
      return "재활용";
    case "REPLACE_ELEMENT":
      return "대체물질";
    case "ENERGY_CONVERSION":
      return "에너지전환";
    case "CHEMICAL":
      return "화학공정";
    case "AGRICULTURE":
      return "농업";
    case "CCS":
      return "CCS";
    case "SECONDHAND":
      return "중고거래";
    case "REPLACE_VEHICLE":
      return "이동수단 대체";
    case "SERVICE":
      return "서비스";
    case "Etc":
      return "기타";
    default:
      return "-";
  }
}

export const areaOptions = [
  { value: "REDUCTION", label: "탄소제거" },
  { value: "REMOVAL", label: "탄소감축" },
];

export const subAreaOptions = [
  { value: "FORESTRY", label: "산림" },
  { value: "BIOCHAR", label: "바이오차" },
  { value: "SOIL_CARBON", label: "토양탄소" },
  { value: "DAC", label: "DAC" },
  { value: "RENEWABLE_ENERGY", label: "재생에너지" },
  { value: "E_MOBILITY", label: "e-mobility" },
  { value: "RECYCLE", label: "재활용" },
  { value: "REPLACE_ELEMENT", label: "대체물질" },
  { value: "ENERGY_CONVERSION", label: "에너지 전환" },
  { value: "CHEMICAL", label: "화학공정" },
  { value: "AGRICULTURE", label: "농업" },
  { value: "CCS", label: "CCS" },
  { value: "SECONDHAND", label: "중고거래" },
  { value: "REPLACE_VEHICLE", label: "이동수단 대체" },
  { value: "SERVICE", label: "서비스" },
  { value: "ETC", label: "기타" },
];


export function getStandardString(type: any): string {
  switch (type) {
    case "CDM":
      return "CDM";
    case "DOMESTIC":
      return "국내 방법론";
    case "INTERNATIONAL":
      return "국외 방법론";
    default:
      return "-";
  }
}
