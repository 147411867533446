import { Box, Button, DialogContent, DialogContentText } from "@mui/material";

interface DeleteButtonDiallogProps {
  handleDeleteClose: () => void;
  handleDeleteOk: () => void;
}

export function DeleteButtonDiallog({
  handleDeleteClose,
  handleDeleteOk,
}: DeleteButtonDiallogProps) {
  return (
    <DialogContent
      sx={{
        width: 450,
        height: 170,
        // mt: -1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <DialogContentText
        id="alert-dialog-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontSize: 15,
          color: "black",
        }}
      >
        Are you sure you want to delete the NFC
        <br />
        Card? It cannot be recovered once deleted.
      </DialogContentText>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          mt: 1.5,
        }}
      >
        <Button
          onClick={handleDeleteClose}
          sx={{
            textTransform: "none",
            height: 40,
            //   backgroundColor: "#5D38E5",
            color: "#5D38E5",
            borderRadius: 5,
            border: 1,
            borderColor: "#5D38E5",
            width: 95,
            ":hover": {
              backgroundColor: "#E9E9E9",
            },
          }}
        >
          Cancle
        </Button>
        <Button
          onClick={handleDeleteOk}
          sx={{
            textTransform: "none",
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            width: 75,
            ml: 2,
            ":hover": {
              backgroundColor: "#A38BFC",
            },
          }}
          autoFocus
        >
          OK
        </Button>
      </Box>
    </DialogContent>
  );
}
