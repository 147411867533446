import { useEffect, useState } from "react";
import CommonSearch from "../../../component/CommonSearch";
import { Box } from "@mui/material";
import { CommonSelect } from "../../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { InventoryTable } from "./InventoryTable";
import { HeadCell } from "../../../component/CommonTable";
import { InventoryData, InventoryList } from "./model/InventoryData";

export function Inventory() {
  const [searchContents, setSearchContents] = useState("");
  const [category, setCategory] = useState("");
  const [quantities, setQuantities] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<InventoryData[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const filteredData = InventoryList.filter((item) => {
      return searchContents
        ? item.partsCode.toLowerCase().includes(searchContents.toLowerCase()) ||
            item.partsCode
              .toLowerCase()
              .includes(searchContents.toLowerCase()) ||
            item.productName
              .toLowerCase()
              .includes(searchContents.toLowerCase()) ||
            item.company.toLowerCase().includes(searchContents.toLowerCase())
        : true;
    });
    const startData = page * rowsPerPage;
    const endData = startData + rowsPerPage;
    const pagedata = filteredData.slice(startData, endData);
    setRows(pagedata);
    setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
    setDocCount(filteredData.length);
  }, [page, rowsPerPage, searchContents]);

  return (
    <>
      <CommonSearch
        placeHolder={"Parts Code, Product Name, Spec, Company"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              width: "108px",
              height: "42px",
            }}
            menuitems={["All", "ASSY", "SEMI ASSY"]}
            selectValue={category}
            onChange={(event) => {
              setCategory(event.target.value);
            }}
            placeholder="Category"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{
              width: "117px",
              height: "42px",
            }}
            menuitems={["All", "SET", "EA", "g", "m"]}
            selectValue={quantities}
            onChange={(event) => {
              setQuantities(event.target.value);
            }}
            placeholder="Quantities"
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <InventoryTable
        headCells={inventoryHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      ></InventoryTable>
    </>
  );
}

const inventoryHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "company",
    label: "Company",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  {
    disablePadding: false,
    align: "left",
    id: "amount",
    label: "Amount",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registerDate",
    label: "Register Date",
  },
];
