import { Box } from "@mui/material";

interface NFTImageProps {
  src: string;
  alt: string;
  backgroundColor: string;
  onError: () => void;
  onLoad: () => void;
}

const NFTImage = ({
  src,
  alt,
  backgroundColor,
  onError,
  onLoad,
}: NFTImageProps) => {
  return (
    <Box
      sx={{
        backgroundColor,
        width: "100%",
        aspectRatio: "1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        src={src}
        alt={alt}
        onError={onError} //이미지 로드 실패 시 onError 호출
        onLoad={onLoad} //이미지 로드 성공 시 onLoad 호출
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default NFTImage;
