import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MoreWalletPageUiEvent } from "./MoreWalletPageUiEvent";
import { MoreWalletPageUiState } from "./MoreWalletPageUiState";
import { MoreWalletPageSideEffect } from "./MoreWalletPageSideEffect";
import MoreWalletRender from "./MoreWalletRender";
import WalletInfo from "./model/WalletInfo";
import { ApiService } from "../../restAPI/ApiService";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import CertificateItem from "./model/CertificateItem";
import { WalletNFT } from "./model/WalletNFT";
import ProgressBar from "../../component/ProgressBar";

const MoreWalletPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [uiState, setUiState] = useState(new MoreWalletPageUiState());

  useAndroidBackHandler(() => {
    if (uiState.isShowVCDialog) {
      uiEventHandler.HideVCDialog();
    } else {
      window.history.back();
    }
  }, [uiState.isShowVCDialog]);

  useEffect(() => {
    (async () => {
      try {
        const uuid = localStorage.getItem("uuid") || "";
        await updateWalletInfo(uuid);
        await updateVCInfo(uuid);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await updateNFTInfo(uiState.userWallet.address);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [uiState.userWallet]);

  const uiEventHandler: MoreWalletPageUiEvent = {
    HandleTabChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          currentTab: event,
        };
      });
    },

    CopyText: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          isToastOpen: true,
        };
      });
    },

    CloseToast: () => {
      setUiState((prev) => ({
        ...prev,
        isToastOpen: false,
      }));
    },

    ShowVCDialog: async (event: string) => {
      const vcDetail = await getVCDetail(event);

      if (window.webkit) {
        window.webkit.messageHandlers.isShownVCDialog.postMessage(true);
      }

      setUiState((prev) => {
        return {
          ...prev,
          isShowVCDialog: true,
          selectedVC: event,
          vcDetail: vcDetail,
        };
      });
    },

    HideVCDialog: () => {
      if (window.webkit) {
        window.webkit.messageHandlers.isShownVCDialog.postMessage(false);
      }

      setUiState((prev) => {
        return {
          ...prev,
          isShowVCDialog: false,
          selectedVC: "",
          vcDetail: null,
        };
      });
    },

    ShowVCErrorDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowErrorDialog: true,
        };
      });
    },

    HideVCErrorDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowErrorDialog: false,
        };
      });
    },
  };

  const sideEffectHandlers: MoreWalletPageSideEffect = {
    navigate: function (target: string, options?: { state: any }): void {
      console.log("navigate", target);
      navigate(`/${target}`, options);
    },
  };

  // Wallet 정보를 가져오는 함수
  async function getWalletInfo(uuid: string): Promise<WalletInfo | null> {
    try {
      let walletInfo: WalletInfo | null = null;

      const requestBody = {
        uuid: uuid,
      };
      await ApiService.BlockchainPost("/wallet/user", requestBody).then(
        (res) => {
          if (res?.data.body == null) {
            return null;
          } else {
            walletInfo = res?.data.body;
          }
          console.log("Wallet Info Response", walletInfo);
        }
      );
      return walletInfo;
    } catch (e) {
      console.log("Wallet Info Error", e);
      return null;
    }
  }

  async function updateWalletInfo(uuid: string) {
    const walletInfo = await getWalletInfo(uuid);
    if (
      walletInfo &&
      walletInfo.userDid &&
      walletInfo.userDid.did !== undefined
    ) {
      setUiState((prev) => ({
        ...prev,
        userWallet: walletInfo,
      }));
    }
  }

  // NFT 정보를 가져오는 함수
  async function getNFTInfo(address: string): Promise<WalletNFT | null> {
    try {
      let nfts: WalletNFT | null = null;

      const requestBody = {
        address: address,
      };
      await ApiService.BlockchainPost("/nft/getAllNft", requestBody).then(
        (res) => {
          nfts = res?.data.body;
          console.log("NFT Info Response", nfts);
        }
      );
      return nfts;
    } catch (e) {
      console.log("NFT Info Error", e);
      return null;
    }
  }

  async function updateNFTInfo(address: string) {
    const nfts = await getNFTInfo(address);
    if (nfts) {
      setUiState((prev) => ({
        ...prev,
        nfts: nfts,
      }));
    }
  }

  // VC List 정보를 가져오는 함수
  async function getVCInfo(uuid: string): Promise<CertificateItem[]> {
    try {
      let certificates: CertificateItem[] = [];

      const requestBody = {
        uuid: uuid,
      };
      await ApiService.BlockchainPost("/vc/userVcList", requestBody).then(
        (res) => {
          certificates = res?.data.body;
          console.log("Certificate Info Response", certificates);
        }
      );
      return certificates;
    } catch (e) {
      console.log("Certificate Info Error", e);
      return [];
    }
  }

  async function updateVCInfo(uuid: string) {
    const certificateItem = await getVCInfo(uuid);
    if (certificateItem) {
      setUiState((prev) => ({
        ...prev,
        certificates: certificateItem,
      }));
    } else {
      setUiState((prev) => ({
        ...prev,
      }));
    }
  }

  // VC 상세정보를 가져오는 함수
  async function getVCDetail(vc: string): Promise<CertificateItem | null> {
    try {
      let certificate: CertificateItem | null = null;

      const requestBody = {
        vc: vc,
      };
      await ApiService.BlockchainPost("/vc/detail", requestBody).then((res) => {
        certificate = res?.data.body;
        console.log("Certificate Detail Response: ", certificate);
      });

      return certificate;
    } catch (e) {
      console.log("Certificate Error: ", e);

      setUiState((prev) => ({
        ...prev,
        isShowErrorDialog: true,
      }));

      return null;
    }
  }

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <>
      <MoreWalletRender
        sideEffect={sideEffectHandlers}
        uiEvent={uiEventHandler}
        uiState={uiState}
      />
    </>
  );
};

export default MoreWalletPage;
