import { Box } from "@mui/material";
import Toolbar from "../../../component/Toolbar";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { CSListUiEvent } from "./CSListUiEvent";
import { CSListUiState } from "./CSListUiState";
import { CSListSideEffect } from "./CSListSideEffect";
import InquiryPeriod from "../../../component/InquiryPeriod/InquiryPeriod";
import { PeriodButtonType } from "../../../component/InquiryPeriod/PeriodButton";
import { HorizontalGrayDivider } from "../../../component/Divider";
import CalendarDrawer, {
  CalendarType,
} from "../../../component/InquiryPeriod/CalendarDrawer";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import CSHistoryList from "./section/CSHistoryList";
import CommonButton from "../../../component/CommonButton";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";

interface CSListRenderProps {
  uiState: CSListUiState;
  uiEvent: CSListUiEvent;
  sideEffect: CSListSideEffect;
}

const CSListRender = ({ uiState, uiEvent, sideEffect }: CSListRenderProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_f310_cs")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <HorizontalGrayDivider />
          <VerticalSpacer height={16} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: "20px",
            }}
          >
            <InquiryPeriod
              isSelectedCustom={uiState.isVisibleDataPicker}
              onPeriodChange={(type) => {
                if (type === PeriodButtonType.CUSTOM) {
                  uiEvent.openDatePicker();
                } else {
                  uiEvent.closeDatePicker();
                  const currentDate = new Date().getTime();
                  switch (type) {
                    case PeriodButtonType.ALL:
                      sideEffect.requestCSList(undefined, currentDate, 100);
                      break;
                    case PeriodButtonType.ONE_MONTH:
                      sideEffect.requestCSList(undefined, currentDate, 1);
                      break;
                    case PeriodButtonType.THREE_MONTH:
                      sideEffect.requestCSList(undefined, currentDate, 3);
                      break;
                  }
                }
              }}
              startDate={uiState.startDate}
              endDate={uiState.endDate}
              onClickStartDate={() => uiEvent.openCalendar(CalendarType.START)}
              onClickEndDate={() => uiEvent.openCalendar(CalendarType.END)}
              onClickSearch={() => {
                sideEffect.requestCSList(
                  uiState.startDate,
                  uiState.endDate,
                  undefined,
                );
              }}
            />
          </Box>
          <VerticalSpacer height={16} />
          <HorizontalGrayDivider />

          <Box
            sx={{
              px: "20px",
              pt: "16px",
              pb: "36px",
            }}
          >
            <CSHistoryList
              csHistories={uiState.historyList}
              onClick={(id) => {
                sideEffect.navigate(ROUTE_PATHS.CUSTOMER_SUPPORT_DETAIL, {
                  state: id,
                });
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            px: "20px",
            py: "20px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            boxSizing: "border-box",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_f310_registercs")}
            onClick={() => {
              sideEffect.navigate(ROUTE_PATHS.CUSTOMER_SUPPORT_REGISTRATION);
            }}
          />
        </Box>

        <CalendarDrawer
          title={LocalizedText("ua_g421_modal_selectdate")}
          isOpen={uiState.isCalendarOpen}
          onClose={() => {
            uiEvent.closeCalendar(uiState.calendarType, uiState.selectedDate);
          }}
          onSelectDate={(date) => {
            uiEvent.selectDate(date);
          }}
          onInvalidDate={uiEvent.showDateAlert}
          selectedDate={uiState.selectedDate}
          startDate={uiState.startDate}
          endDate={uiState.endDate}
          type={uiState.calendarType}
        />

        <SimpleDialog
          isOpen={uiState.isDateAlertOpen}
          onPositive={() => {
            uiEvent.closeCalendar(uiState.calendarType, 0);
            uiEvent.hideDateAlert();
          }}
          onClose={uiEvent.hideDateAlert}
          children={LocalizedText("ua_g421_alert_error_message")}
          positiveText="OK"
        />
      </Box>
    </>
  );
};

export default CSListRender;
