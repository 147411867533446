import NoticePageRender from "./NoticePageRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import { Notice } from "./model/Notice";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import { getCountryCode } from "../../../customHook/useLocale";
import ProgressBar from "../../../component/ProgressBar";

export default function NoticePage() {
  const [noticeList, setNoticeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  useAndroidBackHandler(() => {
    window.history.back();
  });

  // TODO 페이징 처리 추가
  useEffect(() => {
    const url = "/pw/ua/contents/announce/list";
    const requestBody = {
      country: getCountryCode(),
      page: 1,
    };
    try {
      const request = ApiService.MobilePost(url, requestBody);
      request.then((res) => {
        const noticeList = res?.data.body.map(
          (notice: Notice) =>
            new Notice(notice.id, notice.title, notice.registrationDate),
        );
        setNoticeList(noticeList);
      });
    } catch (e) {
      setError("failed to get notice list");
      console.log("failed to get notice list");
    } finally {
      setLoading(false);
    }
  }, []);

  function navigateToNoticeDetail(id: string) {
    navigate(ROUTE_PATHS.NOTICE_DETAIL, { state: { id } });
  }

  if (loading) {
    return <ProgressBar />;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <NoticePageRender
      noticeList={noticeList}
      onClick={navigateToNoticeDetail}
    />
  );
}
