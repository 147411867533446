import { TableRow } from "@mui/material";
import { PaymentManagementCellData } from "../model/PaymentManagementCellData";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { MembershipVoucherTableCell } from "./MembershipVoucherTableCell";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";

export interface MembershipVoucherRow {
  no: number;
  name: string;
  type: string;
  price: number;
  buyer: string;
  purchaseDate: number;
  availableDate: number;
  billingDate: number;
}

interface MembershipVoucherTableRowProps {
  row: MembershipVoucherRow;
  key: number;
  docCount?: number;
}

export function MembershipVoucherTableRow({
  row,
  docCount,
}: MembershipVoucherTableRowProps) {
  const membershipVoucherCellData: PaymentManagementCellData[] = [
    new PaymentManagementCellData(
      `${docCount ? docCount - row.no + 1 : ""}`,
      "left",
      "36px",
      "24px"
    ),
    new PaymentManagementCellData(`${row.name}`, "left"),
    new PaymentManagementCellData(`${row.type}`, "left", "100px"),
    new PaymentManagementCellData(`${row.price}`, "left", "70px"),
    new PaymentManagementCellData(`${row.buyer}`, "left", "100px"),
    new PaymentManagementCellData(
      row.purchaseDate == null
        ? ""
        : LocalizedDate({
            date: row.purchaseDate,
            type: DateFormatType.YearMonthDay,
          }),
      "left",
      "120px"
    ),
    new PaymentManagementCellData(
      row.availableDate == null
        ? ""
        : LocalizedDate({
            date: row.availableDate,
            type: DateFormatType.YearMonthDay,
          }),
      "left",
      "120px"
    ),
    new PaymentManagementCellData(
      row.billingDate == null
        ? ""
        : LocalizedDate({
            date: row.billingDate,
            type: DateFormatType.YearMonthDay,
          }),
      "left",
      "120px"
    ),
  ];
  return (
    <TableRow>
      {docCount == 0 ? (
        <Text
          sx={{
            fontStyle: fontStyle.regularXS,
            color: color.gray800,
          }}
          text={"no data"}
        />
      ) : (
        membershipVoucherCellData.map(
          (data: PaymentManagementCellData, index: number) => (
            <MembershipVoucherTableCell
              key={index}
              sx={{
                paddingLeft: data.paddingLeft,
                paddingRight: data.paddingRight,
                width: data.width || "auto",
                ...(index === PaymentManagementCellData.length - 6
                  ? { flex: 1, maxWidth: "66px" }
                  : {}),
              }}
              childrenContainerSx={{ width: data.width }}
              align={data.align}
              children={
                typeof data.value === "string" ? (
                  <Text
                    sx={{
                      fontStyle: fontStyle.regularXS,
                      color: color.gray800,
                    }}
                    text={data.value}
                  />
                ) : (
                  data.value
                )
              }
            />
          )
        )
      )}
    </TableRow>
  );
}
