import { Dialog } from "@mui/material";
import { Box, Typography, Toolbar, Button } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import {
  APP_LOGO_VC,
  ICON_SNS_FACEBOOK_2,
  ICON_SNS_APPLE,
  ICON_SNS_GOOGLE,
  ICON_X,
  IMAGE_VC_BACKGROUND,
  IMAGE_SCOOTER_01,
} from "../../../constants/appImagePath";
import VerticalSpacer from "../../../component/Spacer";
import { formatDate } from "../section/CertificateCard";
import CertificateItem from "../model/CertificateItem";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import WalletInfo from "../model/WalletInfo";

interface VCDetailDialogProps {
  userInfo: WalletInfo;
  isOpen: boolean;
  onClose: () => void;
  certificate: CertificateItem;
}

export default function VCDetailDialog({
  userInfo,
  isOpen,
  onClose,
  certificate,
}: VCDetailDialogProps) {
  const { credentialSubject, status, type } = certificate;
  const isDisabled = status === 0 || status === 3;

  const getCertificateTitle = () => {
    switch (type[1]) {
      case "snsCredential":
        return "POPLE World";
      case "scooterPurchaseCredential":
        return LocalizedText("ua_g520_ownervc");
      case "scooterRentCredential":
        return LocalizedText("ua_g520_rentalvc");
      default:
        return "Certificate";
    }
  };

  const getCertificateImage = () => {
    switch (type[1]) {
      case "snsCredential":
        return APP_LOGO_VC;
      case "scooterPurchaseCredential":
      case "scooterRentCredential":
        return certificate.credentialSubject.modelPicUrl || IMAGE_SCOOTER_01;
      default:
        return APP_LOGO_VC;
    }
  };

  const getImageStyles = () => {
    switch (type[1]) {
      case "snsCredential":
        return {
          width: "130px",
          height: "200px",
        };
      case "scooterPurchaseCredential":
      case "scooterRentCredential":
        return {
          width: "100%", // 너비와 높이 제한을 없애기
          height: "auto", // 비율에 맞춰 높이를 자동으로
        };
      default:
        return {
          width: "130px",
          height: "200px",
        };
    }
  };

  const getSnsIcon = () => {
    switch (credentialSubject.sns_type) {
      case "Facebook":
        return ICON_SNS_FACEBOOK_2;
      case "Apple":
        return ICON_SNS_APPLE;
      case "Google":
        return ICON_SNS_GOOGLE;
      default:
        return ICON_SNS_FACEBOOK_2;
    }
  };

  const getName = () => {
    if (credentialSubject.name) {
      return credentialSubject.name;
    } else if (credentialSubject.modelName) {
      return credentialSubject.modelName;
    }
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && onClose) {
          onClose();
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          backgroundColor: color.gray900,
          overflow: "hidden",
        }}
      >
        {/* Toolbar */}
        <Toolbar
          sx={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={onClose}
            sx={{
              paddingLeft: "20px",
              minWidth: "auto",
            }}
          >
            <Box
              component="img"
              src={ICON_X}
              alt="Close"
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          </Button>

          <Typography
            fontStyle={fontStyle.titleL}
            color={color.white}
            sx={{
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            {LocalizedText("ua_g520_cetificate")}
          </Typography>

          <Box sx={{ width: 44 }} />
        </Toolbar>

        {/* Certificate Card */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: color.white,
            borderRadius: "20px",
            width: "calc(100% - 80px)",
            maxWidth: "600px", // 최대 너비 제한
            overflow: "hidden",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              aspectRatio: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: "24px",
              backgroundImage: `url(${IMAGE_VC_BACKGROUND})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            <Typography fontStyle={fontStyle.subTitleL} color={color.gray600}>
              {getCertificateTitle()}
            </Typography>
            <Typography fontStyle={fontStyle.titleXL} color={color.default}>
              {getName() || "Very Kim"}
            </Typography>
            <Box
              component="img"
              src={getCertificateImage()}
              alt="Logo"
              sx={{
                position: "relative",
                alignSelf: "center",
                my: "16px",
                objectFit: "contain",
                zIndex: 1,
                ...getImageStyles(),
              }}
            />
          </Box>

          {isDisabled && (
            <Box
              sx={{
                position: "absolute",
                top: "38%",
                left: "50%",
                transform: "translate(-50%, -50%) rotate(-30deg)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                padding: "4px 12px",
                borderRadius: "10px",
                border: `2px solid ${color.primary500}`,
                zIndex: 2,
              }}
            >
              <Typography fontStyle={fontStyle.titleL} color={color.primary500}>
                {status === 3
                  ? LocalizedText("ua_g520_expired")
                  : LocalizedText("ua_g520_disposed")}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              padding: "20px",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginBottom: "6px",
              }}
            >
              <Typography fontStyle={fontStyle.subTitleS} color={color.gray600}>
                {LocalizedText("ua_g520_name")}
              </Typography>
              <Typography fontStyle={fontStyle.bodySr} color={color.gray800}>
                {credentialSubject.name || userInfo.name}
              </Typography>
            </Box>
            {credentialSubject.sns_id && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "6px",
                }}
              >
                <Typography
                  fontStyle={fontStyle.subTitleS}
                  color={color.gray600}
                >
                  {LocalizedText("ua_g520_email")}
                </Typography>
                <Box sx={{ display: "inline-flex" }}>
                  <img
                    src={getSnsIcon()}
                    alt="SNS"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "4px",
                    }}
                  />
                  <Typography
                    fontStyle={fontStyle.bodySr}
                    color={color.gray800}
                    sx={{
                      wordBreak: "break-all",
                      whiteSpace: "normal",
                      maxWidth: "calc(100% - 20px)",
                    }}
                  >
                    {credentialSubject.sns_id}
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginBottom: "6px",
              }}
            >
              <Typography fontStyle={fontStyle.subTitleS} color={color.gray600}>
                {LocalizedText("ua_g520_issuer")}
              </Typography>
              <Typography fontStyle={fontStyle.bodySr} color={color.gray800}>
                Verywords
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginBottom: "6px",
              }}
            >
              <Typography fontStyle={fontStyle.subTitleS} color={color.gray600}>
                {LocalizedText("ua_g520_issued")}
              </Typography>
              <Typography fontStyle={fontStyle.bodySr} color={color.gray800}>
                {formatDate(credentialSubject.issueTime)}
              </Typography>
            </Box>
            {credentialSubject.expireTime && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "6px",
                }}
              >
                <Typography
                  fontStyle={fontStyle.subTitleS}
                  color={color.gray600}
                >
                  {LocalizedText("ua_g520_expiration")}
                </Typography>
                <Typography fontStyle={fontStyle.bodySr} color={color.gray800}>
                  {formatDate(credentialSubject.issueTime)} ~{" "}
                  {formatDate(credentialSubject.expireTime)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
