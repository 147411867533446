import ProductItem from "../model/ProductItem";

export class MembershipPurchaseUiState {
  productType: string = "Membership";
  membershipItems: ProductItem[] = [];
  voucherItems: ProductItem[] = [];
  isMembershipDrawerOpen: boolean = false;
  selectedMembership: ProductItem | null = null;
  isPurchaseButtonHidden: boolean = false;
}
