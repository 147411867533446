import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import CommonList from "../../component/CommonList";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../component/ProgressBar";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import { WEB_LOGIN_BG, WEB_LOGIN_LOGO, WEB_LOGIN_SLIDE_IMG1 } from "../../constants/imagePath";
import { LoginSideEffect } from "./model/LoginSideEffect";
import { LoginUiEvent } from "./model/LoginUiEvent";
import { LoginUiState } from "./model/LoginUiState";

interface LoginRenderProps {
  sideEffect: LoginSideEffect;
  uiEvent: LoginUiEvent;
  uiState: LoginUiState;
}

export default function LoginRender({
  sideEffect,
  uiEvent,
  uiState,
}: LoginRenderProps){
  const iptPw = useRef();
  return (
    <>
      {/* Left Div */}
      <Box
        sx={{
          backgroundImage: `url(${WEB_LOGIN_BG})`,
          backgroundSize: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          width: "50%",
          height: "100%",
        }}
      >
        <Box>
          <img
            src={WEB_LOGIN_LOGO}
            alt="Pople Admin"
            style={{
              padding: "40px",
              width: "200px",
              height: "auto",
            }}
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <img
            src={WEB_LOGIN_SLIDE_IMG1}
            alt="Management for POPLE World"
            style={{
              paddingTop: "13%",
              width: "70%",
              height: "auto",
            }}
          />
          <Typography fontStyle={fontStyle.displayL} color={color.white}>
            Management for POPLE World
          </Typography>
          <br/>
          <Typography fontStyle={fontStyle.subTitleL} color={color.white}>
            POPLE World Admin allows you to easily manage users, content,
          </Typography>
          <Typography fontStyle={fontStyle.subTitleL} color={color.white}>
            products, production materials, carbon reduction, blockchain, etc.
          </Typography>
        </Box>
      </Box>
      {/* Right Div */}
      <Box
        sx={{
          backgroundColor: color.backgroundLogin,
          position: "absolute",
          top: 0,
          right: 0,
          width: "50%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "50%",
            backgroundColor: color.white,
            boxShadow: "10px 10px 20px rgba(0,0,0,0.1), 10px 10px 10px rgba(0,0,0,0.1)",
            borderRadius: "30px",
            margin: "auto",
            marginTop: "35%",
            p: "50px 40px",
          }}
        >
          <Typography
            fontStyle={fontStyle.titleBig}
            color={color.black}
            sx={{ marginBottom: "10px", }}
          >
            Log In
          </Typography>
          <Typography fontStyle={fontStyle.bodySr} color={color.gray}>
            Enter your ID and password to log in
          </Typography>
          <br/>
          <br/>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Email ID
          </Typography>
          <TextField
            id="email"
            type="text"
            placeholder="Email"
            sx={{
              borderRadius: "10px",
              width: "100%",
            }}
            value={uiState.email}
            InputProps={{
              style: {
                borderRadius: "10px",
                textAlign: "left",
              },
            }}
            onKeyDown={(e) => sideEffect.handelActiveEnter(e, iptPw)}
            onChange={(e) => uiEvent.setEmail(e.target.value.slice(0,50))}
          />
          <Typography
            fontStyle={fontStyle.regularXS}
            color={color.error}
            sx={{
               margin: "10px 10px 0px 10px",
               display: `${uiState.emailErrOpen ? "block" : "none" }`,
            }}
          >
            Invalid Email
          </Typography>
          <Typography
            fontStyle={fontStyle.boldXS}
            color={color.gray600}
            sx={{ marginTop: "30px", }}
          >
            Password
          </Typography>
          <TextField
            id="password"
            inputRef={iptPw}
            type="password"
            placeholder="Password"
            sx={{
              borderRadius: "10px",
              width: "100%",
            }}
            value={uiState.password}
            InputProps={{
              style: {
                borderRadius: "10px",
                textAlign: "left",
                border: "rgba(0, 0, 0, 0.87)",
              },
            }}
            onKeyDown={(e) => sideEffect.handelActiveEnter(e, null)}
            onChange={(e) => uiEvent.setPw(e.target.value.slice(0,30))}
          />
          <Typography
            fontStyle={fontStyle.regularXS}
            color={color.error}
            sx={{
               margin: "10px 10px 0px 10px",
               display: `${uiState.pwErrOpen ? "block" : "none" }`,
            }}
          >
            Invalid Password
          </Typography>
          <Button
            sx={{
              backgroundColor: color.ableButton,
              borderRadius: 5,
              color: color.white,
              marginTop: "30px",
              textTransform: "none",
              width: "100%",
              height: 40,
              ":hover": {
                backgroundColor: color.toolingPurple500Hover,
              },
            }}
            onClick={() => sideEffect.handelBtnLogin()}
          >
            Log In
          </Button>
          <Typography
            fontStyle={fontStyle.regularS}
            color={color.gray600}
            sx={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            @ 2022 <a href="https://verywords.com" target="_blank">Verywords</a>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
