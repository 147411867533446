import React, { ReactNode } from "react";
import { LanguageRepositoryProvider } from "./LanguageRepositoryProvider";
import { useLocale } from "../customHook/useLocale";
import ProgressBar from "../component/ProgressBar";

const CombinedProvider = ({ children }: { children: ReactNode }) => {
  const loading = useLocale();

  if (loading) {
    return <ProgressBar />;
  }

  return <LanguageRepositoryProvider>{children}</LanguageRepositoryProvider>;
};

export default CombinedProvider;
