import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { ICON_RIGHT_ARROW_GRAY600 } from "../../../../constants/appImagePath";

interface OtherChoiceButtonProps {
    text: string;
    buttonText: string;
    backgroundColor: string;
    borderColor: string;
    onClick: () => void;
}

const OtherChoiceButton = ({
    text,
    buttonText,
    backgroundColor,
    borderColor,
    onClick,
}: OtherChoiceButtonProps) => {
    return (
        <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              bgcolor: backgroundColor,
              borderRadius: "10px",
              border: `1px solid ${borderColor}`,
              p: "16px",
              boxSizing: "border-box",
            }}
            onClick={onClick}
          >
            <Typography
              fontStyle={fontStyle.bodyMr}
              color={color.default}
              style={{ alignSelf: "center" }}
            >
              {text}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontStyle={fontStyle.titleL} color={color.default}>
                {buttonText}
              </Typography>
              <img
                src={ICON_RIGHT_ARROW_GRAY600}
                alt="right-arrow"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </Box>
          </Box>
    );
};

export default OtherChoiceButton;