import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import CouponHistoryRender from "./CouponHistoryRender";

const CouponHistory = () => {
  useAndroidBackHandler(() => {
    if (window.Android) {
      window.history.back();
    }
  }, []);
  return <CouponHistoryRender />;
};

export default CouponHistory;
