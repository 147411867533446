import React, { forwardRef } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { ServiceMngCreateUiState } from "../model/ServiceMngCreateUiState";
import { ServiceMngCreateUiEvent } from "../model/ServiceMngCreateUiEvent";
import { color } from "../../../../../theme/Color";
import { CommonSelect } from "../../../../../component/CommonSelect";
import CommonTextField from "../../../../../component/CommonTextField";
import CommonMultiLineTextField from "../../../../../component/CommonMultiLineTextField";
import VerticalSpacer from "../../../../../component/Spacer";

interface PartnersInformationCardProps {
  uiState: ServiceMngCreateUiState;
  uiEvent: ServiceMngCreateUiEvent;
}

const PartnersInformationCard = forwardRef<
  HTMLDivElement,
  PartnersInformationCardProps
>(({ uiState, uiEvent }: PartnersInformationCardProps, ref) => {
  return (
    <Grid item lg={12} ref={ref}>
      <Card
        sx={{
          borderRadius: "15px",
          minHeight: 240,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent sx={{ width: "100%", height: "100%" }}>
          <CardHeader
            title={"Partners Information"}
            titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
          />
          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* 첫번째 줄 */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Partners Category
                </Typography>
                <CommonSelect
                  selectValue={""}
                  menuitems={[]}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "Country",
                    //   event.target.value
                    // );
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    borderRadius: "10px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                />
              </Box>
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Partners Subtitle
                </Typography>
                <CommonTextField
                  value={""}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange("Name", event.target.value);
                  }}
                  sx={{
                    mt: "8px",
                    width: "100%",
                    height: "48px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* 두번째 줄 */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Description
                </Typography>
                <VerticalSpacer height={8} />
                <CommonMultiLineTextField
                  placeholder="Please enter a description."
                  inputValue={""}
                  heightPx={164}
                  maxLength={1000}
                  onChange={(value) => {
                    // uiEvent.onChangeDescription(value);
                  }}
                  onClear={() => {
                    // uiEvent.onChangeDescription("");
                  }}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
});

export default PartnersInformationCard;
