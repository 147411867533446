import { CSHistoryStatus } from "../../list/model/CSHistoryStatus";
import { CSDetailResponse } from "./CSDetailResponse";

interface CSDetailModel {
  id: string;
  status: CSHistoryStatus;
  vin: string;
  preferredStationId: number;
  preferredStation: string;
  preferredStationAddress: string;
  preferredDate: number;
  registrationDate: number;
  title: string;
  content: string;
  imageUrls: string[];
}

export function toCSDetailModel(item: CSDetailResponse): CSDetailModel {
  return {
    id: item.id,
    status: item.status,
    vin: item.vin,
    preferredStationId: item.preferredStationId,
    preferredStation: item.preferredStation,
    preferredStationAddress: item.preferredStationAddress,
    preferredDate: item.preferredDate,
    registrationDate: item.registrationDate,
    title: item.title,
    content: item.content,
    imageUrls: item.imageUrls,
  };
}

export type { CSDetailModel };
