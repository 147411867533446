import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import { color } from "../../../../../theme/Color";
import { CommonRadio } from "../../../../../component/CommonRadio";
import { useState } from "react";
import { MaterialType } from "../../model/MaterialsData";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { ICON_NOIMAGE } from "../../../../../constants/imagePath";
import CommonTextField from "../../../../../component/CommonTextField";

interface Field {
  value?: MaterialType;
  label: string;
}

export function MaterialBOMTree() {
  const [type, setType] = useState<MaterialType>(MaterialType.NONE);
  const [quantities, setQuantities] = useState("");
  const [used, setUsed] = useState("");

  const textFields: Field[] = [
    { label: "Category" },
    { label: "Parts Code" },
    { label: "Product Name" },
    { label: "Company" },
    { label: "Spec" },
  ];

  const radioTypes: Field[] = [
    { label: "None", value: MaterialType.NONE },
    { label: "Serial Number", value: MaterialType.SERIAL_NUMBER },
    { label: "Manual Count", value: MaterialType.MANUAL_COUNT },
    { label: "Memo", value: MaterialType.MEMO },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Object.values(MaterialType).includes(value as MaterialType)) {
      setType(value as MaterialType);
      console.log("Selected Material Type:", value);
    }
  };
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>
        Bill of Material Tree
      </Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%" }}>
          {textFields.map((textField, index) => (
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              flex={1}
              key={index}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                {textField.label}
              </Typography>
              <VerticalSpacer height={8} />
              <CommonTextField
                inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
              />
              {index < textFields.length - 1 && <VerticalSpacer height={40} />}
            </Box>
          ))}
        </Box>
        <HorizontalSpacer width={28} />
        <Box sx={{ width: "50%" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Type
          </Typography>
          <VerticalSpacer height={8} />
          <Box sx={{ display: "flex", height: "48px", alignItems: "center" }}>
            {radioTypes.map((radioType, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <CommonRadio<MaterialType>
                  selectValue={type}
                  label={radioType.label}
                  value={radioType.value}
                  onchange={handleChange}
                  sx={{ width: "14.4px", height: "14.4px" }}
                />
                <HorizontalSpacer width={8} />
                <Typography fontStyle={fontStyle.semiboldXS} color={color.gray800}>
                  {radioType.label}
                </Typography>
                <HorizontalSpacer width={16} />
              </Box>
            ))}
          </Box>
          <VerticalSpacer height={40} />
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Quantities
              </Typography>
              <VerticalSpacer height={8} />
              <CommonSelect
                sx={{ minHeight: "48px", flexGrow: 1 }}
                selectValue={quantities}
                onChange={(event) => {
                  setQuantities(event.target.value);
                }}
                menuitems={["al", "ASSY", "ETC"]}
                placeholder="Select"
              />
            </Box>
            <HorizontalSpacer width={12} />
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Used
              </Typography>
              <VerticalSpacer height={8} />
              <CommonSelect
                sx={{
                  minHeight: "48px",
                  flexGrow: 1,
                  borderColor: color.gray300,
                  backgroundColor: color.gray200,
                }}
                selectValue={used}
                onChange={(event) => {
                  setUsed(event.target.value);
                }}
                menuitems={["al", "ASSY", "ETC"]}
                placeholder="-"
                disabled
              />
            </Box>
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Image
          </Typography>
          <VerticalSpacer height={8} />
          <Box
            sx={{
              paddingLeft: "181px",
              paddingRight: "180px",
              paddingTop: "127px",
              paddingBottom: "121px",
              borderRadius: "10px",
              height: "324px",
              background: color.gray200,
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "113px",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={ICON_NOIMAGE}
                sx={{ width: "40px", height: "40px", padding: "4px" }}
              ></Box>
              <Typography fontStyle={fontStyle.semiboldS} color={color.gray600}>
                No Image
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
}
