import { CalendarType } from "../../../component/InquiryPeriod/CalendarDrawer";
import UsageHistoryItem, { UsageType } from "../model/UsageHistoryItem";

export class UsageHistoryUiState {
  currentTab: string = "All";
  isVisibleDataPicker: boolean = false;
  calendarType: CalendarType = CalendarType.START;
  startDate: number = 0;
  endDate: number = 0;
  isCalendarOpen: boolean = false;
  selectedDate: number = 0;
  isDateAlertOpen: boolean = false;
  historyList: UsageHistoryItem[] = [];
  // [
  //   {
  //     id: 1,
  //     usageType: UsageType.CHARGE,
  //     title: "멤버십 충전",
  //     date: "16 May 2024",
  //     mileage: 100,
  //     power: 0.97,
  //   },
  //   {
  //     id: 9,
  //     usageType: UsageType.USE,
  //     title: "Daily Mileage",
  //     date: "11 Jun 2023",
  //     mileage: 400,
  //     power: 0.98,
  //     details: [
  //       { mileage: 100, startTime: "17:19", endTime: "17:24" },
  //       { mileage: 100, startTime: "11:15", endTime: "15:54" },
  //       { mileage: 100, startTime: "12:19", endTime: "12:24" },
  //       { mileage: 100, startTime: "05:19", endTime: "05:24" },
  //     ],
  //     isExpanded: false,
  //   },
  //   {
  //     id: 2,
  //     usageType: UsageType.CHARGE,
  //     title: "Charge - Membership",
  //     date: "16 May 2024, 23:24",
  //     mileage: 1000,
  //     power: 132.34,
  //   },
  //   {
  //     id: 3,
  //     usageType: UsageType.CHARGE,
  //     title: "Charge - Membership",
  //     date: "16 May 2024, 23:24",
  //     mileage: 1000,
  //     power: 132.34,
  //   },
  //   {
  //     id: 4,
  //     usageType: UsageType.USE,
  //     title: "Expire - Membership",
  //     date: "16 May 2024, 23:24",
  //     mileage: 800,
  //     power: 2.34,
  //   },
  //   {
  //     id: 5,
  //     usageType: UsageType.USE,
  //     title: "Daily Mileage",
  //     date: "21 Jun 2024",
  //     mileage: 400,
  //     power: 0.98,
  //     details: [
  //       { mileage: 50, startTime: "10:00", endTime: "10:05" },
  //       { mileage: 100, startTime: "11:30", endTime: "11:35" },
  //       { mileage: 50, startTime: "13:00", endTime: "13:10" },
  //       { mileage: 200, startTime: "14:00", endTime: "14:15" },
  //     ],
  //     isExpanded: false,
  //   },
  // ];
}
