import NFTGradeRender from "./NFTGradeRender";

const NFTGradePage = () => {
  return (
    <>
      <NFTGradeRender />
    </>
  );
};

export default NFTGradePage;
