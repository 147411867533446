import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ICON_CHECK, ICON_DOWN_ARROW } from "../../../constants/appImagePath";
import { useEffect, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import VerticalSpacer from "../../../component/Spacer";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { ApiService } from "../../../restAPI/ApiService";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { color } from "../../../theme/Color";

const WarrantyScreen = () => {
  const [scooterOpen, setScooterOpen] = useState(false);
  const [selectScooterId, setSelectScooterId] = useState("");
  useAndroidBackHandler(() => {
    if (scooterOpen) {
      setScooterOpen(false);
    } else {
      window.history.back();
    }
  }, [scooterOpen]);

  const toggleDrawer = (newOpen: any) => () => {
    setScooterOpen(newOpen);
  };

  // for auto select
  let sctr = window.location.search.split("sctrSN=")[1];

  useEffect(() => {
    const requestBody = {
      uuid: localStorage.getItem("uuid") || "",
    };
    ApiService.MobilePost("/pw/backend/api/scooter/userScoo", requestBody).then(
      (res) => {
        setScooterData(res?.data.body);
      }
    );
  }, []);

  const [scooterData, setScooterData] = useState<any>([]);
  const [selectScooterData, setSelectScooterData] = useState<any>();
  const [warrantyData, setWarrantyData] = useState<any>();

  useEffect(() => {
    for (let i = 0; i < scooterData.length; ++i) {
      if (sctr === scooterData[i].serialNumber) {
        setSelectScooterId(scooterData[i].id);
        setSelectScooterData(scooterData[i]);
        break;
      }
    }
  }, [scooterData]);

  useEffect(() => {
    // console.log(selectScooterData);
    ApiService.MobilePost("/pw/backend/api/scooter/detailWarranty", {
      serialNumber: selectScooterData?.serialNumber,
      id: selectScooterData?.id,
    }).then((res) => {
      console.log(res?.data);
      setWarrantyData(res?.data);
    });
  }, [selectScooterData]);

  const DrawerList = (
    <div>
      <style>
        {`
        div::-webkit-scrollbar {
          display: none;
        }
      `}
      </style>
      <Box
        sx={{
          width: "100%",
          maxHeight: "51vh",
        }}
      >
        <ListSubheader
          sx={{
            height: 50,
            display: "flex",
            alignItems: "center",
            pt: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography color={"black"} fontWeight={"bold"}>
            Select Scooter
          </Typography>
          <Box style={{ marginTop: 12 }} onClick={toggleDrawer(false)}>
            <ClearOutlinedIcon />
          </Box>
        </ListSubheader>
        <List>
          {scooterData.map((data: any, index: any) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(false)(); // 이 부분 수정
                  setSelectScooterId(data.id);
                  setSelectScooterData(data);
                }}
              >
                <ListItemText>
                  {data.productName} | {data.plateNumber}
                </ListItemText>
                {selectScooterId == data.id && (
                  <Box component="img" src={ICON_CHECK} />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   justifyContent: "space-between",
          backgroundColor: "#F2F2F2",
          width: "100vw",
          height: "100vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            // borderBottomRightRadius: "20px",
            // borderBottomLeftRadius: "20px",
            // boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
          }}
        >
          <Box
            sx={{
              width: "calc(100% - 40px)",
              height: "100%",
              display: "flex",
              // bgcolor: "red",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VerticalSpacer height={14} />
            {/* 상단 warranty 및 back 버튼*/}
            <Typography
              fontStyle={fontStyle.titleL}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative", // position을 relative로 설정
                width: "100%",
                mb: "14px",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute", // position을 absolute로 설정
                  left: -8, // 왼쪽에 위치하도록 설정
                }}
                onClick={() => {
                  window.history.back();
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              {LocalizedText("ua_e330_warranty")}
            </Typography>
            {/* 상단 스쿠터 박스 */}
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                minHeight: 40,
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                zIndex: 1200,
                mb: 2.5,
              }}
            >
              <Box
                sx={{
                  width: "calc(100% - 40px)",
                  my: "20px",
                }}
              >
                <Box sx={{ display: "flex" }} onClick={toggleDrawer(true)}>
                  <Box
                    component="img"
                    sx={{
                      width: "70px",
                      height: "70px",
                      objectFit: "cover", // 이미지가 박스에 맞게 잘리며 비율 유지
                    }}
                    src={selectScooterData?.modelPicUrl}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ ml: 1 }}>
                      <Typography fontStyle={fontStyle.titleL}>
                        {selectScooterData?.productName}
                      </Typography>
                      <Typography
                        fontStyle={fontStyle.bodyMr}
                        color={"#999999"}
                      >
                        {selectScooterData?.serialNumber}
                      </Typography>
                      <Typography
                        fontStyle={fontStyle.bodyMr}
                        color={"#999999"}
                      >
                        {LocalizedText("ua_e330_purchasedate")}:{" "}
                        {LocalizedDate({
                          date: selectScooterData?.issueTime,
                          type: DateFormatType.YearMonthDay,
                        })}
                      </Typography>
                    </Box>
                    <Box component="img" src={ICON_DOWN_ARROW} />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* 차량 부품별 보증기간 표시 */}
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                minHeight: 40,
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                zIndex: 1200,
                mb: 2.5,
              }}
            >
              <Box
                sx={{
                  width: "calc(100% - 40px)",
                  my: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* 브레이크 판넬(Front) */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // height: 20,
                    mb: 2,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.titleL}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {LocalizedText("ua_e330_brakepanelfront")}
                    <Typography fontStyle={fontStyle.bodyLr}>
                      ~
                      {warrantyData?.warrantyOfBreakPanelFront
                        ? LocalizedDate({
                            date: warrantyData?.warrantyOfBreakPanelFront,
                            type: DateFormatType.YearMonthDay,
                          })
                        : null}
                    </Typography>
                  </Typography>
                  <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                    {LocalizedText("ua_e330_brakepanelfront_warranty")}
                  </Typography>
                </Box>
                {/* 브레이크 판넬(Rear) */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // height: 20,
                    mb: 2,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.titleL}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {LocalizedText("ua_e330_brakepanelrear")}
                    <Typography fontStyle={fontStyle.bodyLr}>
                      ~
                      {warrantyData?.warrantyOfBreakPanelRear
                        ? LocalizedDate({
                            date: warrantyData?.warrantyOfBreakPanelRear,
                            type: DateFormatType.YearMonthDay,
                          })
                        : null}
                    </Typography>
                  </Typography>
                  <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                    {LocalizedText("ua_e330_brakepanelrear_warranty")}
                  </Typography>
                </Box>
                {/* 브레이크 오일 */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // height: 20,
                    mb: 2,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.titleL}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {LocalizedText("ua_e330_brakeoil")}
                    <Typography fontStyle={fontStyle.bodyLr}>
                      ~
                      {warrantyData?.warrantyOfBreakOil
                        ? LocalizedDate({
                            date: warrantyData?.warrantyOfBreakOil,
                            type: DateFormatType.YearMonthDay,
                          })
                        : null}
                    </Typography>
                  </Typography>
                  <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                    {LocalizedText("ua_e330_brakeoil_warranty")}
                  </Typography>
                </Box>
                {/* 타이어(Front) */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // height: 20,
                    mb: 2,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.titleL}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {LocalizedText("ua_e330_tirefront")}
                    <Typography fontStyle={fontStyle.bodyLr}>
                      ~
                      {warrantyData?.warrantyTireFront
                        ? LocalizedDate({
                            date: warrantyData?.warrantyTireFront,
                            type: DateFormatType.YearMonthDay,
                          })
                        : null}
                    </Typography>
                  </Typography>
                  <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                    {LocalizedText("ua_e330_tirefront_warranty")}
                  </Typography>
                </Box>
                {/* 타이어(Rear) */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // height: 20,
                    mb: 2,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.titleL}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {LocalizedText("ua_e330_tirerear")}
                    <Typography fontStyle={fontStyle.bodyLr}>
                      ~
                      {warrantyData?.warrantyTireRear
                        ? LocalizedDate({
                            date: warrantyData?.warrantyTireRear,
                            type: DateFormatType.YearMonthDay,
                          })
                        : null}
                    </Typography>
                  </Typography>
                  <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                    {LocalizedText("ua_e330_tirerear_warranty")}
                  </Typography>
                </Box>
                {/* 디스크 판넬(Front) */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    mb: 2,
                    // height: 20,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.titleL}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {LocalizedText("ua_e330_discfront")}
                    <Typography fontStyle={fontStyle.bodyLr}>
                      ~
                      {warrantyData?.warrantyDiskPanelFront
                        ? LocalizedDate({
                            date: warrantyData?.warrantyDiskPanelFront,
                            type: DateFormatType.YearMonthDay,
                          })
                        : null}
                    </Typography>
                  </Typography>
                  <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                    {LocalizedText("ua_e330_discpanel_warranty")}
                  </Typography>
                </Box>
                {/* 디스크 판넬(Rear) */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // height: 20,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.titleL}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {LocalizedText("ua_e330_discrear")}
                    <Typography fontStyle={fontStyle.bodyLr}>
                      ~
                      {warrantyData?.warrantyDiskPanelRear
                        ? LocalizedDate({
                            date: warrantyData?.warrantyDiskPanelRear,
                            type: DateFormatType.YearMonthDay,
                          })
                        : null}
                    </Typography>
                  </Typography>
                  <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                    {LocalizedText("ua_e330_discpanel_warranty")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Drawer */}
      <Drawer
        open={scooterOpen}
        onClose={toggleDrawer(false)}
        anchor={"bottom"}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </>
  );
};

export default WarrantyScreen;
