import { TableRow } from "@mui/material";
import { ProductionTableCell } from "./ProductionTableCell";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { Text } from "../../../component/Text";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { ProductionManagementCellData } from "../model/ProductionManagementCellData";
import { ProductionData } from "./model/ProductionData";
import { useNavigate } from "react-router-dom";

interface ProductionTableRowProps {
  row: ProductionData;
}

export function ProductionTableRow({ row }: ProductionTableRowProps) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/productionDetails");
  };
  const productCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData(`${row.no}`, "left", "36px", "24px"),
    new ProductionManagementCellData(`${row.category}`, "left", "74px"),
    new ProductionManagementCellData(`${row.partsCode}`, "left", "94px"),
    new ProductionManagementCellData(
      `${row.productName}`,
      "left",
      "107px",
      "",
      "",
      onClick
    ),
    new ProductionManagementCellData(`${row.spec}`, "left"),
    new ProductionManagementCellData(`${row.made}`, "left", "91px"),
    new ProductionManagementCellData(`${row.quantities}`, "left", "61px"),
    new ProductionManagementCellData(`${row.amount}`, "left", "49px"),
    new ProductionManagementCellData(`${row.status}`, "left", "70px"),
    new ProductionManagementCellData(
      LocalizedDate({
        date: row.registrationDate,
        type: DateFormatType.AdminType,
      }),
      "left",
      "120px",
      "12px",
      "24px"
    ),
  ];
  return (
    <TableRow>
      {productCellData.map(
        (data: ProductionManagementCellData, index: number) => (
          <ProductionTableCell
            key={data.value}
            sx={{
              paddingLeft: data.paddingLeft,
              paddingRight: data.paddingRight,
              width: data.width || "auto",
              ...(index === productCellData.length - 6
                ? { flex: 1, maxWidth: "66px" }
                : {}),
            }}
            childrenContainerSx={{ width: data.width }}
            align={data.align}
            onClick={data.onClick}
            children={
              <Text
                sx={{
                  fontStyle: fontStyle.regularXS,
                  color: color.gray800,
                  ...(data.onClick
                    ? {
                        cursor: "pointer",
                      }
                    : {}),
                }}
                text={data.value}
              />
            }
          />
        )
      )}
    </TableRow>
  );
}
