import { Box } from "@mui/material";
import EmptyScooterScreen from "./EmptyScooterScreen";
import ScooterContractListScreen from "./section/ScooterContractListScreen";
import { MyRideUiState } from "./model/MyRideUiState";
import ScooterInfoScreen from "./section/ScooterInfoScreen";
import { MyRideUiEvent } from "./model/MyRideUiEvent";

interface MyRideRenderProps {
  uiState: MyRideUiState;
  uiEvent: MyRideUiEvent;
}

const MyRideRender = ({ uiState, uiEvent }: MyRideRenderProps) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* 스쿠터가 있는지 먼저 판단 */}
      {uiState.hasScooter ? (
        // 있다면 ScooterInfoScreen 렌더링
        <ScooterInfoScreen
          uiState={uiState}
          // scooterListData={uiState.scooterList}
          uiEvent={uiEvent}
        />
      ) : // 없다면 스쿠터 계약 목록이 있는지 판단
      uiState.hasScooterContractList ? (
        // 스쿠터 계약 목록이 있다면 ScooterContractListScreen 렌더링
        <ScooterContractListScreen uiState={uiState} uiEvent={uiEvent} />
      ) : (
        // 스쿠터 계약 목록이 없을땐 펭귄 화면!
        <EmptyScooterScreen />
      )}
    </Box>
  );
};

export default MyRideRender;
