import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { ICON_RIGHT_ARROW } from "../../../../constants/appImagePath";
import { FAQ } from "./model/FAQ";
import { Text } from "../../../../component/Text";

interface FAQItemProps {
  questionItem: FAQ;
  onClick: (item: FAQ) => void;
}

export default function FAQItem({ questionItem, onClick }: FAQItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between", // 좌우로 정렬
        width: "100%",
        height: "42px",
      }}
      onClick={() => onClick(questionItem)}
    >
      <Text
        sx={{
          fontStyle: fontStyle.bodyLr,
          color: color.default,
        }}
        text={questionItem.title}
      />

      <img
        src={ICON_RIGHT_ARROW}
        alt=""
        style={{
          width: "24px",
          height: "24px",
          filter:
            "invert(59%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(95%) contrast(95%)", // 색상을 #999999로 변환하기 위한 필터
        }}
      />
    </Box>
  );
}
