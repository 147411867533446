import { useState } from "react";
import { Box, Typography, Button, Drawer, IconButton } from "@mui/material";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import { ICON_X_DEFAULT } from "../../constants/appImagePath";
import VerticalSpacer from "../Spacer";
import LocalizedDate, { DateFormatType } from "../LocalizedDate";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  LocalizationProvider,
  DateCalendar,
  PickersCalendarHeaderProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CommonButton from "../CommonButton";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";

interface CalendarDrawerProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSelectDate: (date: number) => void;
  onInvalidDate?: () => void;
  selectedDate?: number;
  startDate?: number;
  endDate?: number;
  type: CalendarType;
}

const CalendarDrawer = ({
  title,
  isOpen,
  onClose,
  onSelectDate,
  onInvalidDate,
  selectedDate,
  startDate = 0,
  endDate = 0,
  type,
}: CalendarDrawerProps) => {
  const today = dayjs();
  const [currentMonth, setCurrentMonth] = useState(
    selectedDate ? dayjs(selectedDate) : today
  );
  const [selected, setSelected] = useState(selectedDate ?? null);

  const handleDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate) {
      const timestamp = newDate.toDate().getTime();
      setSelected(timestamp);
      onSelectDate(timestamp);
      setCurrentMonth(newDate);
    }
  };

  const handleSelectClick = () => {
    if (selected) {
      if (
        (type === CalendarType.START && endDate !== 0 && selected > endDate) ||
        (type === CalendarType.END && startDate !== 0 && selected < startDate)
      ) {
        setSelected(null);
        onInvalidDate?.();
      } else {
        onSelectDate(selected);
      }
    }
    onClose();
  };

  const CustomCalendarHeader = (props: PickersCalendarHeaderProps<Dayjs>) => {
    const { currentMonth, onMonthChange, maxDate } = props; 
    const isNextDisabled = currentMonth.isSame(maxDate, "month");

    const handlePreviousMonth = () => {
      onMonthChange(currentMonth.subtract(1, "month"), "right");
    };

    const handleNextMonth = () => {
      onMonthChange(currentMonth.add(1, "month"), "left");
    };
   
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <IconButton
          onClick={handlePreviousMonth}
          sx={{
            color: color.default,
          }}
        >
          <ArrowBackIos
            fontSize="small"
            sx={{
              width: "16px",
            }}
          />
        </IconButton>
        <Typography fontStyle={fontStyle.titleL} color={color.default}>
          {LocalizedDate({
            date: currentMonth.toDate().getTime(),
            type: DateFormatType.YearMonth,
          })}
        </Typography>
        <IconButton
          onClick={handleNextMonth}
          disabled={isNextDisabled}
          sx={{
            color: isNextDisabled ? color.gray400 : color.default,
          }}
        >
          <ArrowForwardIos
            fontSize="small"
            sx={{
              width: "16px",
            }}
          />
        </IconButton>
      </Box>
    );
  };

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "auto",
          backgroundColor: color.white,
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "44px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: "20px",
          px: "20px",
          boxSizing: "border-box",
        }}
      >
        <Typography fontStyle={fontStyle.titleXL} color={color.default}>
          {title}
        </Typography>
        <Button
          onClick={onClose}
          sx={{
            paddingLeft: "20px",
            minWidth: "auto",
          }}
        >
          <Box
            component="img"
            src={ICON_X_DEFAULT}
            alt="close"
            sx={{
              width: "14px",
              height: "14px",
            }}
          />
        </Button>
      </Box>

      <VerticalSpacer height={8} />

      {/* Calendar */}
      <Box
        sx={{
          maxWidth: "100%",
          mx: 1.5,
          mb: 2,
          "& > div": {
            minWidth: "100%", // 너비를 화면 전체로 설정
          },
          "& .MuiCalendarPicker-root, & .MuiDateCalendar-root": {
            width: "100% !important",
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={selected ? dayjs(selected) : currentMonth}
            onChange={handleDateChange}
            slots={{ calendarHeader: CustomCalendarHeader }}
            maxDate={today}
            disableFuture
            showDaysOutsideCurrentMonth={false}
            sx={{
              height: "auto",
              width: "100%",
              "& .MuiPickersDay-root": {
                borderRadius: "50%",
                color: color.default,
                "&.Mui-selected": {
                  backgroundColor: color.primary200,
                  color: color.primary500,
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: color.primary200,
                  },
                },
                "&:disabled": {
                  color: color.gray400,
                },
              },
              "& .MuiDateCalendar-viewTransitionContainer": {
                "& button[role=gridcell]": {
                  border: "0px !important",
                },
                "& div[role=row]": {
                  padding: "2px",
                  justifyContent: "space-between !important",
                },
                "& .MuiTypography-caption": {
                  fontStyle: fontStyle.bodySr,
                  color: "rgba(60, 60, 67, 0.3)",
                },
              },
            }}
          />
        </LocalizationProvider>

        <CommonButton title={LocalizedText("common_select")} onClick={handleSelectClick} />
      </Box>

      <VerticalSpacer height={8} />
    </Drawer>
  );
};

export default CalendarDrawer;

export enum CalendarType {
  START,
  END,
}
