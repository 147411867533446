import { RegionDetailUiEvent } from "./model/RegionDetailUiEvent";
import { RegionDetailUiState } from "./model/RegionDetailUiState";
import RegionMngDetailGrid from "./section/RegionMngDetailGrid";

interface RegionDetailRenderProps {
  uiState: RegionDetailUiState;
  uiEvent: RegionDetailUiEvent;
}

const RegionDetailRender = ({ uiState, uiEvent }: RegionDetailRenderProps) => {
  return (
    <>
      <RegionMngDetailGrid uiState={uiState} uiEvent={uiEvent} />
    </>
  );
};

export default RegionDetailRender;
