export class ServiceMngCreateUiState {
  ManagerList: Array<Object> = [];
  ManagerEmail: string = "Select";
  ID: string = "";
  Name: string = "";
  LocalNumber: string = "";
  PhoneNumber: string = "";
  OpenTimeHour: string = "00";
  OpenTimeMin: string = "00";
  CloseTimeHour: string = "00";
  CloseTimeMin: string = "00";
  OpenDay: string = "XXXXXXX";
  DetailsFile: Array<File> = [];
  ServiceName: string = "";
  Service: string = "Select";
  AreaName: string = "Select";
  Linked: string = "Select";
  RegistrationDate: number = 0;
  LastModifiedDate: number = 0;
  PartnersCategory: string = "";
  PartnersSubtitle: string = "";
  PartnersDescription: string = "";
}
