import { CSDetailModel } from "./model/CSDetailModel";
import { CSDetailResponse } from "./model/CSDetailResponse";
import { CSReceipt } from "./model/CSReceipt";

export class CustomerSupportDetailUiState {
    isReceiptOpen: boolean = false;
    isToastOpen: boolean = false;
    isDeleteDialogOpen: boolean = false;
    csDetailData?: CSDetailModel;
    csReceipt?: CSReceipt;
}