import { Box, Typography } from "@mui/material";
import { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { SupportButtonModel } from "../model/SupportButtonModel";
import { Text } from "../../../component/Text";

export interface SupportButtonProps {
  supportButtonModel: SupportButtonModel;
  onClick: () => void;
}

export default function SupportButton({
  supportButtonModel: { icon, title },
  onClick,
}: SupportButtonProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "41px",
      }}
      onClick={onClick}
    >
      <img
        src={icon}
        alt=""
        style={{
          width: "20px",
          height: "20px",
        }}
      />
      <HorizontalSpacer width={16} />

      <Text
        sx={{
          fontStyle: fontStyle.bodyLr,
          color: color.gray900,
        }}
        text={title}
      />
    </Box>
  );
}
