import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { HorizontalSpacer } from "../../../component/Spacer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { color } from "../../../theme/Color";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  const navigate = useNavigate();
  return (
    <>
      <TableRow
        onClick={() => {
          navigate(`/csMng/csDetails/${row.id}`);
        }}
      >
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Box fontStyle={fontStyle.regularXXS}>
            {row.status == "Complete" && (
              <Box
                sx={{
                  width: "64px",
                  height: "18px",
                  borderRadius: "5px",
                  bgcolor: color.backgroundSuccess,
                  color: color.success,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.status}
              </Box>
            )}
            {row.status == "Receipt" && (
              <Box
                sx={{
                  width: "54px",
                  height: "18px",
                  borderRadius: "5px",
                  bgcolor: color.proceed,
                  color: color.proceedBg,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.status}
              </Box>
            )}

            {row.status == "Reservation" && (
              <Box
                sx={{
                  width: "76px",
                  height: "18px",
                  borderRadius: "5px",
                  bgcolor: color.backgroundWarning2,
                  color: color.warning2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.status}
              </Box>
            )}

            {row.status == "Deleted" && (
              <Box
                sx={{
                  width: "49px",
                  height: "18px",
                  borderRadius: "5px",
                  bgcolor: color.gray200,
                  color: color.gray700,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.status}
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.preferredStation}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.title}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.reporter}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.writer}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.completeDate == 0 ? (
              <></>
            ) : (
              <>
                {LocalizedDate({
                  date: row.completeDate,
                  type: DateFormatType.AdminType,
                })}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registrationDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface CsMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

const CsMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: CsMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },

    {
      id: "status",
      align: "left",
      disablePadding: false,
      label: "Status",
    },

    {
      id: "preferredCSStation",
      align: "left",
      disablePadding: false,
      label: "Preferred CS Station",
    },

    {
      id: "title",
      align: "left",
      disablePadding: false,
      label: "Title",
    },

    {
      id: "reporter",
      align: "left",
      disablePadding: false,
      label: "Reporter",
    },
    {
      id: "writer",
      align: "left",
      disablePadding: false,
      label: "Writer",
    },
    {
      id: "completeDate",
      align: "left",
      disablePadding: false,
      label: "Complete Date",
    },
    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Registration Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows || [], rowsPerPage, setPage, page);
  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows?.map((row: any, index: any) => (
        <Row row={row} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default CsMngTable;
