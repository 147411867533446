export async function getFileHash(file: File): Promise<string> {
  const arrayBuffer = await file.arrayBuffer(); // File을 ArrayBuffer로 변환
  const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer); // SHA-256 해시 계산
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // ArrayBuffer를 배열로 변환
  // 16진수로 변환
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

export const getFileName = (url: string) => {
  if (!url) return "";
  return url.substring(url.lastIndexOf("/") + 1);
};

export const downloadLink = async (url: string, fileName?: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("네트워크 응답이 좋지 않습니다.");
  }
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = url.split("/").pop() || fileName || "download";

  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};