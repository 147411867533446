import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { ReactNode } from "react";
import { SelectChangeEvent } from "@mui/material";
import React, { SyntheticEvent, ChangeEvent, useEffect, useState } from "react";
import { ArgumentDetailFormData, ValueListItem } from "../ParamDetailsPage";
import { ParamType } from "../../../../store/paramType";
import { Methodology } from "../../../../context/MethodologyContext";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { PurposeEnum } from "../../../../store/paramType";
import { error } from "console";
import { ProductionDetailsCardImpossible } from "../../../productionmanagement/production/section/productiondetails/ProductionDetailsCardImpossible";
interface ParamFixedProps {
  formData: ArgumentDetailFormData;
  setFormData: React.Dispatch<React.SetStateAction<ArgumentDetailFormData>>;
  methodologies: Methodology[];
  onHandleMethodologyChange: (event: SelectChangeEvent<number>) => void;
  errors: { [key: string]: string };
}
interface Country {
  translations: {
    kor: { common: string };
  };
}

interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "38px",
  padding: "12px 16px",
  borderRadius: "10px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "10px",
}));

const ParamFixed: React.FC<ParamFixedProps> = ({
  formData,
  setFormData,
  methodologies,
  onHandleMethodologyChange,
  errors,
}) => {
  const [valueList, setValueList] = useState<ValueListItem[] | []>([]);
  const [load, setLoad] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const { name, value } = event.target;
    setFormData((prev: ArgumentDetailFormData) => ({ ...prev, [name]: value }));
  };

  const handleValueInputChange = (event: any, idx: number) => {
    const { value, name } = event.target;
    setValueList((prev) => {
      return [...prev].map((el: any, index) => {
        return idx === index
          ? {
              ...el,
              [name]: value,
            }
          : el;
      });
    });
  };

  const handleCheckboxChange = (
    name: string,
    value: string,
    checked: boolean
  ) => {
    setFormData((prev) => {
      const prevList: PurposeEnum[] = Array.isArray(prev?.purpose)
        ? prev.purpose
        : [];
      const newValue = prevList.includes(value as PurposeEnum)
        ? prevList.filter((v: PurposeEnum) => v !== value)
        : [...prevList, value as PurposeEnum];

      return { ...prev, purpose: newValue };
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<ParamType>) => {
    const { name, value } = event.target;
    setFormData((prev: ArgumentDetailFormData) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("file.name", file.name);
      setFileName(file.name);
      setFormData((prevData) => ({
        ...prevData,
        relatedDocumentFile: file,
      }));
    }
  };

  const addItem = () => {
    setValueList([...valueList, { id: Date.now(), item: "", value: 0 }]);
  };

  const removeItem = (index: number) => {
    setValueList((prev) => prev.filter((_, idx) => idx !== index));
  };

  useEffect(() => {
    setFormData((prevFormData: ArgumentDetailFormData) => ({
      ...prevFormData,
      valueList: valueList,
    }));
  }, [valueList, setFormData]);
  useEffect(() => {
    if (!formData.valueList) {
      setValueList([]);
      setFormData((prevFormData: ArgumentDetailFormData) => ({
        ...prevFormData,
        valueList: [],
      }));
    } else {
      setValueList(formData.valueList);
    }
    setLoad(true);
  }, []);

  useEffect(() => {
    if (formData.valueList) {
      setValueList(formData.valueList);
    }
    setLoad(true);
  }, []);

  const [countries, setCountries] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();

        const countryNames = data.map(
          (country: Country) => country.translations.kor.common
        );

        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);
  const handleUploadClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };
  const filteredCountries = countries.filter((country) =>
    country.includes(searchTerm)
  );
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (formData.relatedDocumentFile) {
      if (typeof formData.relatedDocumentFile === "string") {
        const fileNameFromUrl =
          formData.relatedDocumentFile.split("/").pop() || "Unknown File";
        setFileName(fileNameFromUrl);
      } else {
        setFileName(formData.relatedDocumentFile.name);
      }
    }
  }, [formData.relatedDocumentFile]);
  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,
          lineHeight: "18px",
          fontSize: "14px",
          fontWeight: 700,
          width: "80px",
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box>
          <FormControl fullWidth error={!!errors.name} sx={{ marginBottom: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 5,
              }}
            >
              <CustomTypography
                sx={{
                  fontWeight: "bold",
                  mt: 2.5,
                }}
              >
                데이터/인자
              </CustomTypography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <CustomTextField
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: "40px",
                      width: "390px",
                      display: "flex",
                      marginLeft: 0,
                    },
                  }}
                  error={!!errors.name}
                />

                <FormHelperText
                  sx={{
                    color: "red",
                    mt: "-10px",
                    ml: 4,
                    fontSize: "12px",
                  }}
                >
                  {errors.name}
                </FormHelperText>
              </Box>
            </Box>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
              단위
            </CustomTypography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                name="unit"
                value={formData.unit || ""}
                onChange={handleInputChange}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    height: "40px",
                    width: "390px",
                    display: "flex",
                    marginLeft: 0,
                  },
                }}
                error={!!errors.unit}
              />
              <FormHelperText
                sx={{
                  color: "red",
                  mt: "-10px",
                  ml: 4,
                  fontSize: "12px",
                }}
              >
                {errors.unit}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
              정의
            </CustomTypography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                name="definition"
                value={formData.definition}
                onChange={handleInputChange}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    height: "40px",
                    width: "390px",
                    display: "flex",
                    marginLeft: 0,
                  },
                }}
                error={!!errors.definition}
              />

              <FormHelperText
                sx={{
                  color: "red",
                  mt: "-10px",
                  ml: 4,
                  fontSize: "12px",
                }}
              >
                {errors.purpose}
              </FormHelperText>
            </Box>
          </Box>

          <Box sx={{ mt: 4, mb: 6 }}>
            <CustomTypography sx={{ fontWeight: "bold", mt: 2.5, mb: 2 }}>
              적용된 값
            </CustomTypography>
            <Table>
              <TableRow>
                <TableCell>항목</TableCell>
                <TableCell>값</TableCell>
              </TableRow>
              {valueList?.map((el, idx) => (
                <TableRow key={`form-data-value-list-${idx}`}>
                  <TableCell sx={{ padding: "5px 4px", border: "none" }}>
                    <CustomTextField
                      name={"item"}
                      value={el.item}
                      onChange={(e) => {
                        handleValueInputChange(e, idx);
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "40px",
                        },
                      }}
                      inputProps={{ imeMode: "auto" }}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "2px 8px" }}>
                    <CustomTextField
                      name="value"
                      value={el.value}
                      onChange={(e) => handleValueInputChange(e, idx)}
                      sx={{
                        borderRadius: "10px",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          height: "40px",
                        },
                      }}
                      inputProps={{ imeMode: "auto" }}
                    />
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={{ width: "5%", padding: "4px", border: "none" }}
                  >
                    <IconButton onClick={() => removeItem(idx)}>
                      <DoNotDisturbOnIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                marginRight: "15px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={addItem}
                sx={{
                  mt: 2,
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  height: "35px",
                  background: "gray",
                }}
                startIcon={<AddIcon />}
              >
                Add to List
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 5 }}>
            방법론명
          </CustomTypography>
          <Select
            name="methodologyId"
            value={formData.methodologyId || ""}
            onChange={onHandleMethodologyChange}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              marginRight: "15px",
              height: "40px",
              width: "392px",
              minHeight: "40px",
              opacity: 1,
              borderRadius: "10px",
              mt: 4.2,
              gap: "2px",
              backgroundColor: "#ffff",
              "&:hover": {
                borderColor: "#F9F6FD",
              },
            }}
            style={{ borderRadius: "10px" }}
            renderValue={(val) => {
              const selected =
                methodologies.find((e: any) => e.id === val) || null;
              return selected ? `${selected?.title}` : `방법론 선택`;
            }}
          >
            {methodologies.length > 0 ? (
              methodologies.map((methodology) => (
                <MenuItem key={methodology.id} value={methodology.id}>
                  {methodology.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                조회 데이터가 없습니다.
              </MenuItem>
            )}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            데이터 목적
          </CustomTypography>

          <FormGroup row sx={{ width: "80%", mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.purpose?.includes(PurposeEnum.BASELINE)}
                  name="BASELINE"
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    checked: boolean
                  ) => handleCheckboxChange("purpose", "BASELINE", checked)}
                />
              }
              label={
                <Typography sx={{ fontSize: "13px" }}>베이스라인</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.purpose?.includes(PurposeEnum.PROJECT)}
                  name="PROJECT"
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    checked: boolean
                  ) => handleCheckboxChange("purpose", "PROJECT", checked)}
                />
              }
              label={
                <Typography sx={{ fontSize: "13px" }}>프로젝트</Typography>
              }
            />

            <FormHelperText
              sx={{
                color: "red",
                mt: 1,
                ml: 1,
                fontSize: "12px",
              }}
            >
              {errors.purpose}
            </FormHelperText>
          </FormGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            데이터 출처
          </CustomTypography>
          <CustomTextField
            name="output"
            value={formData.output}
            onChange={(e) =>
              setFormData({ ...formData, output: e.target.value })
            }
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            버전
          </CustomTypography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <CustomTextField
              name="version"
              value={formData.version}
              onChange={handleInputChange}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: "40px",
                  width: "390px",
                  display: "flex",
                  marginLeft: 0,
                },
              }}
              error={!!errors.version}
            />

            <FormHelperText
              sx={{
                color: "red",
                mt: "-10px",
                ml: 4,
                fontSize: "12px",
              }}
            >
              {errors.version}
            </FormHelperText>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            지역
          </CustomTypography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              mr: 2,
              mt: 2,
            }}
          >
            <Select
              name="region"
              onChange={handleSelectChange}
              value={formData.region as "" | undefined}
              style={{ borderRadius: "10px" }}
              renderValue={(val) => val || "선택하세요"}
              IconComponent={(props) => (
                <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
              )}
              error={!!errors.region}
              sx={{
                borderRadius: "10px",
                height: "40px",
                width: "390px",
                display: "flex",
                marginLeft: 0,
              }}
            >
              <CustomTextField
                placeholder="검색..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    height: "40px",
                    width: "390px",
                    display: "flex",
                    marginLeft: 0,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 1, ml: 1 }}
              />

              {filteredCountries.map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              sx={{
                color: "red",
                ml: 4,
                fontSize: "12px",
              }}
            >
              {errors.region}
            </FormHelperText>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            기타 의견
          </CustomTypography>
          <CustomTextField
            name="otherComments"
            value={formData.otherComments}
            onChange={handleInputChange}
            multiline
            minRows={4}
            maxRows={10}
            InputProps={{
              style: {
                borderRadius: "10px",
                resize: "vertical",
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
            관련 문서
          </CustomTypography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              position: "relative",
              width: "540px",
              marginRight: "10px",
            }}
          >
            <CustomTextField
              className="methodologyFile"
              variant="outlined"
              value={fileName}
              onChange={handleTextFieldChange}
              sx={{
                width: "140px",
                "& .MuiInputBase-root": {
                  borderRadius: 2,
                  height: "45px",
                },
              }}
              aria-readonly
            />
            <Typography
              component="span"
              onClick={handleUploadClick}
              sx={{
                position: "absolute",
                top: "-10px",
                marginRight: "10px",
                right: "0",
                fontWeight: "bold",
                fontSize: "12px",
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  color: "primary.dark",
                },
              }}
            >
              Upload File
            </Typography>
            <input
              type="file"
              id="fileInput"
              name="uploadedFile"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ParamFixed;
