import { Box, Typography } from "@mui/material";
import Toolbar from "../../../component/Toolbar";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import { NFTDetailSideEffect } from "./NFTDetailSideEffect";
import { NFTDetailUiState } from "./NFTDetailUiState";
import {
  EMOJI_SAD,
  EMOJI_SAD_GRAY600,
  IMAGE_PONYA_LEVEL4,
} from "../../../constants/appImagePath";
import { CharacterNFT, BatteryNFT, ScooterNFT } from "../model/WalletNFT";
import { get } from "http";
import { format } from "path";
import { LanguageRepository } from "../../../repository/LanguageRepository";
import NFTImage from "./NFTImage";
import { set } from "date-fns";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import ProgressBar from "../../../component/ProgressBar";

interface NFTDetailRenderProps {
  sideEffect: NFTDetailSideEffect;
  uiState: NFTDetailUiState;
  languageRepository: LanguageRepository;
  onImageLoadError: () => void;
  onImageLoad: () => void;
}

const NFTDetailRender = ({
  sideEffect,
  uiState,
  languageRepository,
  onImageLoadError,
  onImageLoad,
}: NFTDetailRenderProps) => {
  const getType = () => {
    if (isCharacterNFT(uiState.nftDetail)) {
      return LocalizedText("ua_g510_character");
    } else if (isBatteryNFT(uiState.nftDetail)) {
      return LocalizedText("ua_g510_battery");
    } else if (isScooterNFT(uiState.nftDetail)) {
      return LocalizedText("ua_g510_scooter");
    }
    return "";
  };

  const getSymbol = () => {
    if (isCharacterNFT(uiState.nftDetail)) {
      return "VRC";
    } else if (isBatteryNFT(uiState.nftDetail)) {
      return "VBP";
    } else if (isScooterNFT(uiState.nftDetail)) {
      return "VSC";
    }
    return "";
  };

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const getDescription = () => {
    if (isCharacterNFT(uiState.nftDetail)) {
      const characterName = uiState.nftDetail.tokenId
        .split("#")[0]
        .toLowerCase();
      switch (characterName) {
        case "ponya":
          return LocalizedText("ua_g310_ponyainfo");
        case "dodo":
          return LocalizedText("ua_g310_dodoinfo");
        case "peter":
          return LocalizedText("ua_g310_peterinfo");
      }
    }
    return "";
  };

  if (!uiState.nftDetail) {
    return <ProgressBar />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Toolbar
          title={LocalizedText("ua_g510_nft")}
          onBack={() => {
            sideEffect.back();
          }}
        />

        <Box
          sx={{
            width: "100%",
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {uiState.imageLoadError ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                aspectRatio: "1",
                backgroundColor: color.gray50,
              }}
            >
              <img
                src={EMOJI_SAD_GRAY600}
                alt="Sad face"
                style={{
                  width: "24px",
                  height: "24px",
                  color: color.gray600,
                }}
              />
              <VerticalSpacer height={12} />
              <Typography fontStyle={fontStyle.subTitleL} color={color.gray600}>
                {LocalizedText("ua_g510_failed")}
              </Typography>
            </Box>
          ) : (
            <NFTImage
              src={uiState.nftDetail.imageURI}
              alt="NFT Image"
              backgroundColor={color.gray50}
              onError={onImageLoadError}
              onLoad={onImageLoad}
            />
          )}

          <VerticalSpacer height={20} />

          <Box
            sx={{
              width: "calc(100% - 40px)",
              px: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                fontStyle={fontStyle.titleM}
                color={color.gray600}
                sx={{
                  marginRight: "8px",
                }}
              >
                {LocalizedText("ua_g510_nft")}
              </Typography>
              <Typography
                fontStyle={fontStyle.bodyMr}
                color={color.gray500}
                sx={{
                  marginRight: "8px",
                }}
              >
                |
              </Typography>
              <Typography fontStyle={fontStyle.titleM} color={color.gray600}>
                {getType()}
              </Typography>
            </Box>

            <Typography fontStyle={fontStyle.headingS} color={color.default}>
              {uiState.nftDetail.tokenId}
            </Typography>

            <VerticalSpacer height={12} />

            {/* NFT Metadata */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                py: "16px",
                gap: "4px",
                borderTop: `1px solid ${color.gray200}`,
                borderBottom: `1px solid ${color.gray200}`,
              }}
            >
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Typography
                  fontStyle={fontStyle.subTitleL}
                  color={color.gray600}
                >
                  {LocalizedText("ua_g510_creator")}
                </Typography>
                <Typography fontStyle={fontStyle.bodyLr} color={color.gray800}>
                  {uiState.nftDetail.issuer}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Typography
                  fontStyle={fontStyle.subTitleL}
                  color={color.gray600}
                >
                  {LocalizedText("ua_g510_symbol")}
                </Typography>
                <Typography fontStyle={fontStyle.bodyLr} color={color.gray800}>
                  {getSymbol()}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Typography
                  fontStyle={fontStyle.subTitleL}
                  color={color.gray600}
                >
                  {LocalizedText("ua_g510_issued")}
                </Typography>
                <Typography fontStyle={fontStyle.bodyLr} color={color.gray800}>
                  {formatDate(uiState.nftDetail.issuedAt)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Typography
                  fontStyle={fontStyle.subTitleL}
                  color={color.gray600}
                >
                  {LocalizedText("ua_g510_received")}
                </Typography>
                <Typography fontStyle={fontStyle.bodyLr} color={color.gray800}>
                  {formatDate(uiState.nftDetail.lastUpdatedTimestamp * 1000)}
                </Typography>
              </Box>
            </Box>

            <VerticalSpacer height={16} />

            {/* NFT Description */}
            {isCharacterNFT(uiState.nftDetail) && (
              <>
                <Box
                  sx={{
                    py: "16px",
                    borderBottom: `1px solid ${color.gray200}`,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.bodyMr}
                    color={color.gray800}
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {getDescription()}
                  </Typography>
                </Box>

                <VerticalSpacer height={16} />
              </>
            )}

            {/* TODO: 거래기록 화면 추가 필요 */}

            {/* <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontStyle: fontStyle.subTitleM,
                  color: color.gray600,
                  textAlign: "center",
                  position: "relative",
                  display: "inline-block", // 밑줄을 텍스트 너비에 맞추기 위해 사용
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: "1px", // 텍스트와 밑줄 사이의 간격 조정
                    height: "1px", // 밑줄 두께
                    backgroundColor: color.gray600, // 밑줄 색상
                  },
                }}
                onClick={() => sideEffect.navigate("")}
              >
                {LocalizedText("ua_g510_transaction")}
              </Typography>
            </Box> */}

            <VerticalSpacer height={36} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NFTDetailRender;

// Type Guards
function isCharacterNFT(nft: any): nft is CharacterNFT {
  return (nft as CharacterNFT).grade !== undefined;
}

function isBatteryNFT(nft: any): nft is BatteryNFT {
  return (nft as BatteryNFT).serialNumber !== undefined;
}

function isScooterNFT(nft: any): nft is ScooterNFT {
  return (nft as ScooterNFT).chassisNumber !== undefined;
}
