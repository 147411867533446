import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { ICON_MENU1, ICON_MENU2, ICON_MENU3 } from "../../constants/imagePath";
import StationMngTable from "./section/StationMngTable";
import { StationUiState } from "./model/StationUiState";
import { StationUiEvent } from "./model/StationUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import { CommonSelect } from "../../component/CommonSelect";

const StationMngPage = () => {
  const navigate = useNavigate();
  // 페이지 관련
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);

  // 검색어 관련
  const [searchContents, setSearchContents] = useState("");

  // country Name
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };

  // status
  const [status, setStatus] = useState("Status");
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  // menu
  const [menu, setMenu] = useState("table");

  const [uiState, setUiState] = useState(new StationUiState());
  const uiEvent: StationUiEvent = {
    HandleUiStateChange(key: keyof StationUiState, value: any) {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    HandleCountryChange: (value) => {
      setUiState((prevState) => ({
        ...prevState,
        country: value, // field는 "country", value는 선택된 code
      }));
      console.log("Selected country code1: ", value); // 선택된 code 출력
    },
  };
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
      uiEvent.HandleUiStateChange("countryList", res?.data.body);
      console.log("get countryList : ", uiState.countryList);
    });
  }, []);

  //TODO API 연동 필요
  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/station/searchCondition", {
      svcStt: uiState.status,
      ctry: uiState.country,
      searchCondition: searchContents,
      page: page,
      size: rowsPerPage,
    }).then((res) => {
      uiEvent.HandleUiStateChange("data", res?.data.body.serviceList);
      setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage));
      setDocCount(res?.data.body.docCount);
      console.log("uiState data : ", uiState.data);
      console.log("res data : ", res?.data.body.serviceList);
      console.log("countryList : ", uiState.countryList);
    });
  }, [searchContents, uiState.status, uiState.country, page]);
  return (
    <CommonList title="Station">
      <CommonSearch
        placeHolder={"Station ID, Station Code"}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            selectValue={
              // selectValue는 uiState.countryList에서 선택된 코드에 해당하는 label을 찾음
              uiState.countryList.find((item) => item.code === uiState.country)
                ?.label || ""
            }
            onChange={(event) => {
              const value = event.target.value;
              console.log("event.target.value : ", event.target.value);
              uiEvent.HandleCountryChange(value);
              console.log("Selected country code : ", value); // 선택된 country code 출력
            }}
            menuitems={[
              { label: "All", code: "" }, // "All" 항목에 대해 빈 값 설정
              ...uiState.countryList,
            ]}
            placeholder="Country"
          />

          <HorizontalSpacer width={12} />
          <CommonSelect
            selectValue={uiState.status}
            onChange={(event) => {
              uiEvent.HandleUiStateChange("status", event.target.value);
            }}
            menuitems={["All", "New", "PreParing", "Open", "Closed", "Closure"]}
            placeholder="Status"
          />

          <HorizontalSpacer width={12} />
          <Box
            sx={{
              height: "42px",
              bgcolor: "white",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <HorizontalSpacer width={16} />
            View
            <HorizontalSpacer width={12} />
            <Box sx={{ display: "flex" }}>
              <Box
                component="img"
                src={ICON_MENU1}
                sx={{
                  bgcolor: menu == "dashboard" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("dashboard");
                }}
              />
              <HorizontalSpacer width={12} />
              <Box
                component="img"
                src={ICON_MENU2}
                sx={{
                  bgcolor: menu == "card" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("card");
                }}
              />
              <HorizontalSpacer width={12} />
              <Box
                component="img"
                src={ICON_MENU3}
                sx={{
                  bgcolor: menu == "table" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("table");
                }}
              />
            </Box>
            <HorizontalSpacer width={16} />
          </Box>
        </Box>
      </CommonSearch>

      <VerticalSpacer height={16} />
      <StationMngTable
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        uiState={uiState}
      />
    </CommonList>
  );
};

export default StationMngPage;
