import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import CommonSearch from "../../../../../component/CommonSearch";
import { SetStateAction, useEffect, useState } from "react";
import { color } from "../../../../../theme/Color";
import VerticalSpacer from "../../../../../component/Spacer";
import { HeadCell } from "../../../../../component/CommonTable";
import { ProductData, ProductList } from "../model/ProductData";
import { ProductConfirmationListTable } from "./ProductConfirmationListTable";

interface ProductConfirmationListProps {
  selectValue: string;
  onchange: any;
}

export function ProductConfirmationList({
  selectValue,
  onchange
}: ProductConfirmationListProps) {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(4);
  const [rows, setRows] = useState<ProductData[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const filteredData = ProductList.filter((item) => {
      return searchContents
        ? item.category.toLowerCase().includes(searchContents.toLowerCase()) ||
            item.partsCode.toLowerCase().includes(searchContents.toLowerCase())
        : true;
    });
    const startData = page * rowsPerPage;
    const endData = startData + rowsPerPage;
    const pagedata = filteredData.slice(startData, endData);
    setRows(pagedata);
    setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
    setDocCount(filteredData.length);
  }, [page, rowsPerPage, searchContents]);

  return (
    <>
      <CardContent
        sx={{
          width: "100%",
          padding: "0px",
        }}
      >
        <Typography fontStyle={fontStyle.semiboldL}>Find in List</Typography>
        <VerticalSpacer height={16} />
      </CardContent>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <CommonSearch
          placeHolder="코드, 품명, 스펙, 업체명, 검색"
          placeholderSx={{ fontStyle: fontStyle.regularS }}
          searchContents={searchContents}
          setSearchContents={setSearchContents}
          sx={{
            width: "100%",
            borderRadius: "10px",
            border: "1px solid",
            borderColor: color.gray300,
            minHeight: "48px",
          }}
        />
      </Box>
      <VerticalSpacer height={24} />
      <ProductConfirmationListTable
        headCells={productConfirmationHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        productList={ProductList}
        selectValue={selectValue}
        onchange={onchange}
      ></ProductConfirmationListTable>
    </>
  );
}

const productConfirmationHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "radio",
    label: "",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
];
