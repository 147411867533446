import { Box, SxProps, TableCell, TableRow, Theme } from "@mui/material";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { mockNFTData } from "../model/NFT";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

interface NFTManagementTableProps {
  headCells?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: any;
}

interface RowProps {
  row: any;
}

interface CustomTableCellProps {
  childrenContainerSx?: SxProps<Theme>;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  children: JSX.Element;
  sx?: SxProps<Theme>;
}

export function CustomTableCell({
  childrenContainerSx,
  children,
  align,
  sx,
}: CustomTableCellProps) {
  return (
    <TableCell
      sx={{
        py: "4px",
        px: "8px",
        minHeight: "58px",
        ...sx,
      }}
      align={align}
    >
      <Box
        sx={{
          py: 0,
          display: "flex", // flexbox로 변경
          alignItems: "center", // 수직 중앙 정렬
          ...childrenContainerSx,
        }}
      >
        {children}
      </Box>
    </TableCell>
  );
}

const Row = ({ row }: RowProps) => {
  return (
    <>
      <TableRow>
        <CustomTableCell
          sx={{ paddingLeft: "24px" }}
          childrenContainerSx={{ width: "30px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
                whiteSpace: "pre-line",
              }}
              text={row.no}
            />
          }
          align={"center"}
        />

        <CustomTableCell
          childrenContainerSx={{ width: "50px" }}
          children={
            <img
              style={{ width: "50px", height: "50px" }}
              src={row.img}
              alt={""}
            />
          }
        />
        <CustomTableCell
          childrenContainerSx={{ width: "90px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={row.nftCharactor}
            />
          }
          align={"left"}
        />
        <CustomTableCell
          sx={{ whiteSpace: "pre-line" }}
          childrenContainerSx={{ width: "259px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
                whiteSpace: "pre-line",
              }}
              text={row.reword}
            />
          }
          align={"left"}
        />
        <CustomTableCell
          childrenContainerSx={{ width: "86px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={row.writer}
            />
          }
          align={"left"}
        />
        <CustomTableCell
          childrenContainerSx={{ width: "117px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={LocalizedDate({
                date: row.distributeDate,
                type: DateFormatType.AdminType,
              })}
            />
          }
          align={"left"}
        />
        <CustomTableCell
          childrenContainerSx={{ width: "120px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={LocalizedDate({
                date: row.lastModifiedDate,
                type: DateFormatType.AdminType,
              })}
            />
          }
        />
        <CustomTableCell
          sx={{ paddingRight: "24px" }}
          childrenContainerSx={{ whdth: "120px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={LocalizedDate({
                date: row.registrationDate,
                type: DateFormatType.AdminType,
              })}
            />
          }
        />
      </TableRow>
    </>
  );
};

const NFTTable = ({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: NFTManagementTableProps) => {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mockNFTData, rowsPerPage, setPage, page);

  const pagedata = visibleRows.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {pagedata.map((data: any, index: any) => (
        <Row row={data} key={index} />
      ))}
    </CommonTable>
  );
};

export default NFTTable;
