import ProgressBar from "../../component/ProgressBar";
import { SERVER_CONFIG } from "../../constants/ServerConfig";
import Keycloak from "keycloak-js";

const LogoutPage = () => {
  const initOptions = {
    url: SERVER_CONFIG.KEYCLOAK_URL,
    realm: SERVER_CONFIG.PW_ADMIN_WEB_REALM,
    clientId: SERVER_CONFIG.PW_ADMIN_WEB_CLIENT_ID,
    onLoad: SERVER_CONFIG.PW_ADMIN_WEB_ONLOAD,
  };
  var keycloak = new Keycloak(initOptions);

  keycloak
    .init({})
    .then(function (authenticated) {
      var path = window.location.pathname.toLowerCase();
      console.log("Logout start - path : " + path);

      if(0 === path.indexOf('/logout/logout')){
        console.log("go to login");
        localStorage.clear();
        window.location.href = SERVER_CONFIG.FRONT_URL + "/login";
      }else{
        console.log("go to logout");
        keycloak.logout({
          redirectUri: SERVER_CONFIG.FRONT_URL + "/logout/logout"
        });
      }
    })
    .catch(function () {
      console.log("Failed to initialize Keycloak");
      window.location.href = SERVER_CONFIG.FRONT_URL + "/login";
    });

  return (
    <div className="App">
      <ProgressBar />
    </div>
  );
};

export default LogoutPage;