import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
const UserAddModal = ({
  modal,
  setModal,
}: {
  modal: boolean;
  setModal: any;
}) => {
  const [usrType, setUsrType] = useState("1");
  return (
    <Dialog
      open={modal}
      // fullWidth={true}
      maxWidth="md"
      onClose={() => {
        setModal(false);
      }}
      PaperProps={{ sx: { borderRadius: "16px" } }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ backgroundColor: "#2A3547", color: "white" }}
      >
        {"회원관리"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setModal(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "100%" }}>
        <Box sx={{ width: 750, height: 780 }}>
          <Typography>Type</Typography>
          <Select
            value={usrType}
            size="small"
            sx={{ width: "100%" }}
            style={{ borderRadius: "7px" }}
          >
            <MenuItem value={1}>:: 선택 ::</MenuItem>
            <MenuItem value={2}>일반 사용자</MenuItem>
            <MenuItem value={3}>관리자</MenuItem>
          </Select>

          <Typography sx={{ mt: 2.5 }}>로그인 차단 여부</Typography>
          <Select
            value={usrType}
            size="small"
            sx={{ width: "100%" }}
            style={{ borderRadius: "7px" }}
          >
            <MenuItem value={1}>:: 선택 ::</MenuItem>
            <MenuItem value={2}>로그인가능</MenuItem>
            <MenuItem value={3}>로그인차단</MenuItem>
          </Select>

          <Typography sx={{ mt: 2.5 }}>가입 승인 여부</Typography>
          <Select
            value={usrType}
            size="small"
            sx={{ width: "100%" }}
            style={{ borderRadius: "7px" }}
          >
            <MenuItem value={1}>:: 선택 ::</MenuItem>
            <MenuItem value={2}>승인</MenuItem>
            <MenuItem value={3}>승인전</MenuItem>
          </Select>

          <Typography sx={{ mt: 8.5 }}>Name</Typography>
          <TextField
            sx={{ width: "100%" }}
            size="small"
            InputProps={{
              style: {
                borderRadius: "7px",
              },
            }}
          />

          <Typography sx={{ mt: 2.5 }}>Phone Number</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Select
              value={usrType}
              size="small"
              sx={{ width: "48.5%" }}
              style={{ borderRadius: "7px" }}
            >
              <MenuItem value={1}>:: 선택 ::</MenuItem>
              <MenuItem value={2}>일반 사용자</MenuItem>
              <MenuItem value={3}>관리자</MenuItem>
            </Select>

            <TextField
              sx={{ width: "48.5%" }}
              size="small"
              InputProps={{
                style: {
                  borderRadius: "7px",
                },
              }}
            />
          </Box>

          <Typography sx={{ mt: 8.5, fontSize: 20 }}>Details</Typography>

          <Typography sx={{ mt: 1.5 }}>Email ID</Typography>
          <TextField
            sx={{ width: "100%" }}
            size="small"
            InputProps={{
              style: {
                borderRadius: "7px",
              },
            }}
          />

          <Typography sx={{ mt: 2.5 }}>Password</Typography>
          <TextField
            sx={{ width: "100%" }}
            size="small"
            InputProps={{
              style: {
                borderRadius: "7px",
              },
            }}
          />
          <Box
            sx={{ mt: 4, mr: -1, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              sx={{
                height: 40,
                mr: 1.5,
                backgroundColor: "#EEF3FF",
                color: "black",
                borderRadius: 5,
                width: 65,
                ":hover": {
                  backgroundColor: "#5D87FF",
                },
              }}
              onClick={() => {
                setModal(false);
              }}
            >
              닫기
            </Button>
            <Button
              sx={{
                height: 40,
                backgroundColor: "#5D38E5",
                color: "white",
                borderRadius: 5,
                width: 65,
                ":hover": {
                  backgroundColor: "#A38BFC",
                },
              }}
            >
              등록
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UserAddModal;
