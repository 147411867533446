import { Console } from "console";
import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../../../component/CommonTable";
import { ProductBOMData } from "../model/ProductData";
import { ProductBOMTreeTableRow } from "./ProductBOMTreeTableRow";
import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";

interface ProductBOMTreeTableProps {
  headCells: HeadCell[];
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pageCount: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  docCount: number;
  productList: ProductBOMData[];
}

export function ProductBOMTreeTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
  productList,
}: ProductBOMTreeTableProps) {
  const { order, orderBy, handleChangePage, handleRequestSort, visibleRows } =
    useVisibleRows(productList, rowsPerPage || 6, setPage, page || 0);
  const pagedata: any = visibleRows;

  // 페이징 필요없으면 지울 것!
  // .slice(
  //   (page || 0) * (rowsPerPage || 6),
  //   ((page || 0) + 1) * (rowsPerPage || 6)
  // );
  // const isPagedataEmpty = Object.values(pagedata).every(
  //   (pagedata) => pagedata === "" || pagedata === null || pagedata === undefined
  // );
  // console.log(
  //   "pagedata : ",
  //   pagedata,
  //   "isPagedataEmpty :",
  //   isPagedataEmpty,
  //   "visibleRows : ",
  //   visibleRows
  // );

  return (
    <Box sx={{}}>
      <CommonTable
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        page={page}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        paginationEnabled={false}
      >
        {pagedata.map((data: ProductBOMData) => (
          <ProductBOMTreeTableRow row={data} key={data.category} />
        ))}
      </CommonTable>
      {pagedata?.length == 0 ? (
        <Typography
          sx={{
            textAlign: "center",
            fontStyle: fontStyle.regularXS,
            color: color.gray600,
          }}
        >
          No Data
        </Typography>
      ) : null}
    </Box>
  );
}
