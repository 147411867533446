import { Box, Typography } from "@mui/material";
import CommonDialog from "../../../component/CommonDialog";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

interface CouponAlertProps {
  message?: string;
  buttonClick?: () => void;
  coupon?: boolean;
  couponName: string;
}

export function CouponAlert({
  message,
  buttonClick,
  coupon,
  couponName,
}: CouponAlertProps) {
  return (
    <>
      {message === "success" ? (
        <Box>
          <CommonDialog
            open={coupon}
            handleOk={buttonClick}
            cancleButton={false}
            textSx={{ flexDirection: "column" }}
            sx={{ px: "54px", py: "32px" }}
            okButtonSx={{ ml: "0px", px: "20px", py: "8px", width: "60px" }}
            autoFocus={false}
          >
            <Typography
              component="span"
              fontStyle={fontStyle.regularS}
              color={color.gray800}
            >
              쿠폰을{" "}
              <Typography
                component="span"
                fontStyle={fontStyle.regularS}
                color={color.proceed}
              >
                사용
              </Typography>{" "}
              하였습니다.
            </Typography>
            <Typography
              component="span"
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              {couponName}
            </Typography>
          </CommonDialog>
        </Box>
      ) : message === "expired" ? (
        <Box>
          <CommonDialog
            open={coupon}
            handleOk={buttonClick}
            cancleButton={false}
            textSx={{ flexDirection: "column" }}
            sx={{ px: "54px", py: "32px" }}
            okButtonSx={{ ml: "0px", px: "20px", py: "8px", width: "60px" }}
            autoFocus={false}
          >
            <Typography
              component="span"
              fontStyle={fontStyle.regularS}
              color={color.gray800}
            >
              쿠폰을 사용할 수 없습니다.
            </Typography>
            <Typography
              component="span"
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              사용 기간 만료된 쿠폰
            </Typography>
          </CommonDialog>
        </Box>
      ) : message === "used" ? (
        <Box>
          <CommonDialog
            open={coupon}
            handleOk={buttonClick}
            cancleButton={false}
            textSx={{ flexDirection: "column" }}
            sx={{ px: "54px", py: "32px" }}
            okButtonSx={{ ml: "0px", px: "20px", py: "8px", width: "60px" }}
            autoFocus={false}
          >
            <Typography
              component="span"
              fontStyle={fontStyle.regularS}
              color={color.gray800}
            >
              쿠폰을 사용할 수 없습니다.
            </Typography>
            <Typography
              component="span"
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              사용 완료한 쿠폰
            </Typography>
          </CommonDialog>
        </Box>
      ) : message === "invalid" ? (
        <Box>
          <CommonDialog
            open={coupon}
            handleOk={buttonClick}
            cancleButton={false}
            textSx={{ flexDirection: "column" }}
            sx={{ px: "54px", py: "32px" }}
            okButtonSx={{ ml: "0px", px: "20px", py: "8px", width: "60px" }}
            autoFocus={false}
          >
            <Typography
              component="span"
              fontStyle={fontStyle.regularS}
              color={color.gray800}
            >
              쿠폰을 사용할 수 없습니다.
            </Typography>
            <Typography
              component="span"
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              유효하지 않은 쿠폰
            </Typography>
          </CommonDialog>
        </Box>
      ) : message === "error" ? (
        <Box>
          <CommonDialog
            open={coupon}
            handleOk={buttonClick}
            cancleButton={false}
            textSx={{ flexDirection: "column" }}
            sx={{ px: "54px", py: "32px" }}
            okButtonSx={{ ml: "0px", px: "20px", py: "8px", width: "60px" }}
            autoFocus={false}
          >
            <Typography
              component="span"
              fontStyle={fontStyle.regularS}
              color={color.gray800}
            >
              쿠폰을 사용할 수 없습니다.
            </Typography>
            <Typography
              component="span"
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              서버와 연결을 할 수 없습니다. 다시 시도해 보세요.
            </Typography>
          </CommonDialog>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
