export const NFTHeadCells = [
  {
    id: "no",
    align: "left",
    disablePadding: false,
    label: "No",
  },
  {
    id: "image",
    align: "left",
    disablePadding: false,
    label: "Image",
    sx: { width: "50px" },
  },
  {
    id: "nftCharactor",
    align: "left",
    disablePadding: false,
    label: "NFT Charactor",
  },
  {
    id: "reword",
    align: "left",
    disablePadding: false,
    label: "Reword",
  },
  {
    id: "writer",
    align: "left",
    disablePadding: false,
    label: "Writer",
  },
  {
    id: "distributeDate",
    align: "left",
    disablePadding: false,
    label: "Distribute Date",
  },
  {
    id: "lastModifiedDate",
    align: "left",
    disablePadding: false,
    label: "Last Modified Date",
  },
  {
    id: "registrationDate",
    align: "left",
    disablePadding: false,
    label: "Registration Date",
  },
];
