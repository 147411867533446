import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import { useLocation, useNavigate } from "react-router-dom";
import NfcMngPage from "./NfcMngPage";
import { CommonWebHeaderButton } from "../../component/CommonWebHeaderButton";
import { NfcCardManageMentTab, NfcCardManageMentTabType } from "./NfcCardManagementTab";

const NfcCardManamgement = () => {
  const [currentTab, setCurrentTab] = useState<NfcCardManageMentTabType>(
    NfcCardManageMentTabType.NFCMANAGEMENT
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");

    switch (tabParam) {
      case NfcCardManageMentTabType.NFCMANAGEMENT:
        setCurrentTab(NfcCardManageMentTabType.NFCMANAGEMENT);
        break;
      case NfcCardManageMentTabType.OWNER_HISTORY:
        setCurrentTab(NfcCardManageMentTabType.OWNER_HISTORY);
        break;
    }
  }, [location.search]);
  const handleChangeTab = (tab: NfcCardManageMentTabType) => {
    setCurrentTab(tab);
    navigate(`/nfcCardManagement?tab=${tab}`);
  };

  const onClick = () => {
    navigate("/NfcCreate");
  };

  return (
    <CommonList
      title="NFC Card"
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Create"
          addIcon
          onClick={onClick}
          iconFontSize="18px"
        />
      }
      tabButton={
        <NfcCardManageMentTab
          currentTab={currentTab}
          changeTab={handleChangeTab}
        />
      }
    >
      {currentTab == NfcCardManageMentTabType.NFCMANAGEMENT && <NfcMngPage />}
    </CommonList>
  );
};

export default NfcCardManamgement;
