import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import InquiryDetailPageRender from "./InquiryDetailPageRender";
import { ApiService } from "../../../restAPI/ApiService";
import { InquiryResponse } from "./model/InquiryResponse";
import { InquiryDetailPageUiState } from "./model/InquiryDetailPageUiState";
import { toInquiry } from "./mapper/InquiryMapper";
import { InquiryDetailPageSideEffect } from "./model/InquiryDetailPageSideEffect";
import { InquiryDetailPageUiEvent } from "./model/InquiryDetailPageUiEvent";
import { Box } from "@mui/material";
import ProgressBar from "../../../component/ProgressBar";

export default function InquiryDetailPage() {
  const [uiState, setUiState] = useState(
    new InquiryDetailPageUiState(undefined, undefined),
  );
  const location = useLocation();
  const receivedState = location.state as { id: string };
  const [progress, setProgress] = useState(true);

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    console.log("receivedState :", receivedState);
    fetchInquiryDetail(receivedState.id).then((r) => {});
  }, []);

  async function getInquiryDetail(
    inquiryId: string,
  ): Promise<InquiryResponse | null> {
    try {
      const requestBody = {
        id: inquiryId,
      };
      const res = await ApiService.MobilePost(
        `/pw/ua/contents/personal/detail`,
        requestBody,
      );
      const body = res?.data.body;
      return body as InquiryResponse;
    } catch (error) {
      return null; // 실패한 경우 null 반환
    } finally {
      setProgress(false);
    }
  }

  async function fetchInquiryDetail(id: string) {
    const inquiryResponse = await getInquiryDetail(id);
    console.log("inquiryResponse :", inquiryResponse);

    if (inquiryResponse) {
      const inquiry = toInquiry(inquiryResponse);

      setUiState((prev) => ({
        ...prev,
        inquiry: inquiry,
        inquiryAnswer: inquiryResponse.answer,
      }));
    }
  }

  const sideEffect: InquiryDetailPageSideEffect = {
    back: () => {
      window.history.back();
    },
    delete: () => {
      deleteInquiry();
      uiEvent.hideDeleteDialog();
    },
  };

  const uiEvent: InquiryDetailPageUiEvent = {
    showDeleteDialog: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDeleteDialog: true,
        };
      });
    },
    hideDeleteDialog: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDeleteDialog: false,
        };
      });
    },
  };

  async function deleteInquiry() {
    const url = "/pw/ua/contents/personal/delete";
    const requestBody = {
      id: receivedState.id,
    };
    setProgress(true);
    await ApiService.MobilePost(url, requestBody)
      .then((res) => {
        if (res != null) {
          if (res.data.result == true) {
            window.history.back();
          }
        } else {
          // TODO 실패에 대한 처리
          console.log("삭제 실패");
        }
      })
      .catch((e) => {})
      .finally(() => {
        setProgress(false);
      });
  }

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {progress ? <ProgressBar /> : null}
      <InquiryDetailPageRender
        uiState={uiState}
        sideEffect={sideEffect}
        uiEvent={uiEvent}
      />
    </Box>
  );
}
