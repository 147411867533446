import { InquiryHistoryStatus } from "./InquiryHistoryStatus";

export class InquiryHistoryModel {
  status: InquiryHistoryStatus;
  title: string;
  category: string;
  id: string;
  registrationDate: number;

  constructor(
    status: InquiryHistoryStatus,
    title: string,
    category: string,
    id: string,
    registrationDate: number,
  ) {
    this.status = status;
    this.title = title;
    this.category = category;
    this.id = id;
    this.registrationDate = registrationDate;
  }
}
