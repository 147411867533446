import { Box, Typography } from "@mui/material";
import { ICON_LEFT_ARROW } from "../constants/appImagePath";
import { HorizontalSpacer } from "./Spacer";
import { fontStyle } from "../theme/Style";
import { color } from "../theme/Color";

interface ToolbarProps {
  sx?: any;
  onBack: () => void;
  title: string;
  actionButtonList?: Array<ToolbarActionButton>;
}

export class ToolbarActionButton {
  iconPath: string;
  onClick: () => void;

  constructor(iconPath: string, onClick: () => void) {
    this.iconPath = iconPath;
    this.onClick = onClick;
  }
}

const Toolbar = ({ onBack, title, actionButtonList, sx }: ToolbarProps) => {
  return (
    <Box
      sx={{
        ...sx,
        position: "relative",
        alignItems: "center",
        minHeight: "52px",
        width: "100%",
        display: "flex", // 자식 요소들을 수평으로 배치
      }}
    >
      <img
        style={{
          left: 20,
          position: "absolute",
          display: "flex",
          width: "24px",
          height: "24px",
          zIndex: 1,
        }}
        src={ICON_LEFT_ARROW}
        alt="Back"
        onClick={onBack}
      />
      <Typography
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          fontStyle: fontStyle.titleL,
          textAlign: "center",
          alignItems: "center",
          width: "100%",
          color: color.default,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          position: "absolute",
          right: 20, // 오른쪽에 여백
          top: "50%",
          transform: "translateY(-50%)", // 세로 중앙 정렬
          display: "flex",
          alignItems: "center",
          gap: "8px", // 버튼 사이 간격
        }}
      >
        {actionButtonList &&
          actionButtonList.map((it, index) => (
            <img
              key={index}
              style={{
                width: "24px",
                height: "24px",
              }}
              src={it.iconPath}
              alt="Action"
              onClick={it.onClick}
            />
          ))}
      </Box>
      <HorizontalSpacer width={20} />
    </Box>
  );
};

export default Toolbar;
