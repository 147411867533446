import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import Toolbar from "../../../component/Toolbar";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import {
  ICON_ALERT_CIRCLE,
  ICON_X_DEFAULT,
  IMAGE_DODO_LEVEL1,
} from "../../../constants/appImagePath";
import VerticalSpacer from "../../../component/Spacer";
import CommonButton from "../../../component/CommonButton";

const PayRestrictPage = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        {/* Toolbar */}
        <Box
          sx={{
            position: "relative",
            alignItems: "center",
            minHeight: "52px",
            width: "100%",
            display: "flex",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              fontStyle: fontStyle.titleL,
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              color: color.default,
            }}
          >
            {LocalizedText("ua_g411_gnb")}
          </Typography>
        </Box>

        <VerticalSpacer height={200} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={ICON_ALERT_CIRCLE}
            alt="Alert"
            style={{
              width: "40px",
              height: "40px",
            }}
          />
          <VerticalSpacer height={8} />
          <Typography
            fontStyle={fontStyle.titleL}
            color={color.primary500}
            textAlign={"center"}
            sx={{
              whiteSpace: "pre-line",
              px: "10px"
            }}
          >
            {LocalizedText("ua_g411_limit_title")}
          </Typography>
          <Typography
            fontStyle={fontStyle.bodyMr}
            color={color.gray700}
            textAlign={"center"}
            sx={{
              whiteSpace: "pre-line",
              px: "35px",
            }}
          >
            {LocalizedText("ua_g411_limit_message")}
          </Typography>
          <VerticalSpacer height={36} />
        </Box>
        <Box
          sx={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CommonButton
            title={LocalizedText("common_alert_confirm_button")}
            onClick={() => {
                window.history.back();
            }}
            sx={{
              mx: "65px",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default PayRestrictPage;
