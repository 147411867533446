import { useEffect, useState } from "react";
import { RegionAndServiceUiEvent } from "../../model/RegionAndServiceUiEvent";
import { RegionAndServiceUiState } from "../../model/RegionAndServiceUiState";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ApiService } from "../../../../restAPI/ApiService";
import CommonSearch from "../../../../component/CommonSearch";
import { CommonSelect } from "../../../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ServiceMngTable from "./ServiceMngTable";

interface SeviceMngRenderProps {
  uiState: RegionAndServiceUiState;
  uiEvent: RegionAndServiceUiEvent;
}

const ServiceMngRender = ({ uiState, uiEvent }: SeviceMngRenderProps) => {
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");

  // 국가관련 변수
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };

  // 서비스 관련 변수
  const [service, setService] = useState("");

  // status관련 변수
  const [status, setStatus] = useState("");

  // 서버에 select 목록들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      uiEvent.HandleUiStateChange("CountryList", res?.data.body);
    });
  }, []);

  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/svc/searchCondition", {
      // ctry: countryCode,
      // searchCondition: searchContents,
      size: rowsPerPage,
      page: page,
      // category: category,
      // showHide: showHide,
    }).then((res) => {
      setRows(res?.data.body.serviceList);
      setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage));
      setDocCount(res?.data.body.docCount);
    });
  }, [page, rowsPerPage, service, countryCode, searchContents]);

  return (
    <>
      <CommonSearch
        placeHolder={"Search"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            menuitems={[
              "All",
              "Charging",
              "CS Center",
              "Cafe",
              "Partner",
              "Closure",
            ]}
            selectValue={service}
            onChange={(event) => {
              if (event.target.value == "All") {
                setService("");
              } else {
                setService(event.target.value);
              }
            }}
            placeholder="Service"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            menuitems={["All", "Preparing", "Close", "Open", "Closure"]}
            selectValue={status}
            onChange={(event) => {
              if (event.target.value == "All") {
                setStatus("");
              } else {
                setStatus(event.target.value);
              }
            }}
            placeholder="Status"
          />
          <HorizontalSpacer width={12} />
          <Select
            value={country}
            onChange={handleCountryChange}
            IconComponent={() => null}
            renderValue={(v) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "8px",
                  }}
                >
                  {v?.length ? v : `Country`}
                  <ExpandMoreRoundedIcon
                    style={{
                      fontSize: "16px",
                      color: color.gray600,
                    }}
                  />
                </Box>
              );
            }}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",

              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={["Country", ""]}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              All
            </MenuItem>
            {uiState.CountryList.map((data: any, index: any) => {
              return (
                <MenuItem
                  value={[data.label, data.code]}
                  key={index}
                  sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
                >
                  {data.label}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <ServiceMngTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default ServiceMngRender;
