import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MoreProfileEditPageSideEffect } from "./MoreProfileEditPageSideEffect";
import { MoreProfileEditPageUiEvent } from "./MoreProfileEditPageUiEvent";
import { MoreProfileEditPageUiState } from "./MoreProfileEditPageUiState";
import MoreProfileRender from "./MoreProfileEditRender";
import { UserProfile } from "../MobileProfile/model/UserProfile";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { ApiService } from "../../restAPI/ApiService";

const MoreProfileEditPage = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(true);
  const [uiState, setUiState] = useState<MoreProfileEditPageUiState>(
    new MoreProfileEditPageUiState(),
  );

  useEffect(() => {
    return () => {
      localStorage.removeItem("temp_user_phone_number");
      localStorage.removeItem("temp_user_country_number");
    };
  }, []);

  useEffect(() => {
    requestDetailProfile()
      .then((res) => {
        if (res) {
          setUiState((prev) => {
            return {
              ...prev,
              name: res.name,
              email: res.email,
              localNumber:
                localStorage?.getItem("temp_user_country_number") != null
                  ? getCountryNumber(
                      localStorage.getItem("temp_user_country_number")!!,
                    )
                  : getCountryNumber(res.countryNumber),
              phoneNumber:
                localStorage.getItem("temp_user_phone_number") ??
                res.phoneNumber ??
                "",
              day: getBirthDay(res.birthday).toString(),
              month: getBirthMonth(res.birthday).toString(),
              year: getBirthYear(res.birthday).toString(),
              gender: res.gender?.toLowerCase() ?? "",
              country: res.addressCountry,
              cityCountyDistrict: res.addressProvince,
              cityDetail: res.addressDetail,
              tribeCategory: res.tribeCategory,
              tribeName: res.tribeName,
            };
          });
        }
      })
      .catch((error) => {
        console.error("Error deleting account:", error);
      });
  }, []);

  useAndroidBackHandler(() => {
    if (window.Android) {
      window.history.back();
    }
  }, []);

  const uiEventHandler: MoreProfileEditPageUiEvent = {
    ShowAlertDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowAlertDialog: true,
        };
      });
    },
    HideAlertDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowAlertDialog: false,
        };
      });
    },

    SetIsDoEditCompleteDialog: (event: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          isDoEditCompleteDialog: event,
        };
      });
    },
    SetIsEditCompleteDialog: (event: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          isEditCompleteDialog: event,
        };
      });
    },

    HandleLocaleNumberChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          localNumber: event,
        };
      });
    },

    HandlePhoneNumberChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          phoneNumber: event,
        };
      });
    },

    HandleDayChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          day: event,
        };
      });
    },
    HandleMonthChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          month: event,
        };
      });
    },
    HandleYearChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          year: event,
        };
      });
    },
    HandleGenderChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          gender: event,
        };
      });
    },
    HandleCountryChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          country: event,
        };
      });
    },
    HandleCityCountyDistrictChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          cityCountyDistrict: event,
        };
      });
    },
    HandleCityDetailChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          cityDetail: event,
        };
      });
    },

    HandleTribeCategory: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          tribeCategory: event,
        };
      });
    },
    HandleTribeName: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          tribeName: event,
        };
      });
    },

    //수정 완료 버튼을 눌렀을 시 실행되는 데이터 verifying 함수
    VerifyingData: () => {
      let isValid = true;
      // local 번호 검증
      if (uiState.localNumber == "none") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          localNumberError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          localNumberError: "",
        }));
      }

      // // 전화번호 검증
      // if (uiState.phoneNumber == "") {
      //   isValid = false;
      //   setUiState((prev) => ({
      //     ...prev,
      //     phoneNumberError: "error",
      //   }));
      // } else {
      //   setUiState((prev) => ({
      //     ...prev,
      //     phoneNumberError: "",
      //   }));
      // }

      // 생년월일 검증
      // Day 검증
      if (uiState.day == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          dayError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          dayError: "",
        }));
      }
      // Month 검증
      if (uiState.month == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          monthError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          monthError: "",
        }));
      }
      // Year 검증
      if (uiState.year == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          yearError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          yearError: "",
        }));
      }

      // 주소 검증
      // Contry 검증
      if (uiState.country == "none") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          countryError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          countryError: "",
        }));
      }
      // CityCountyDistrict 검증
      if (uiState.cityCountyDistrict == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          cityCountyDistrictError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          cityCountyDistrictError: "",
        }));
      }
      // CityDetail
      if (uiState.cityDetail == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          cityDetailError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          cityDetailError: "",
        }));
      }

      if (isValid) {
        if (
          uiState.phoneNumber ==
            localStorage?.getItem("temp_user_phone_number") &&
          uiState.localNumber ==
            "+" + localStorage?.getItem("temp_user_country_number")
        ) {
          uiEventHandler.SetIsDoEditCompleteDialog(true);
        } else {
          uiEventHandler.ShowAndHidePhoneNumberVerificationDialog(true);
        }

        return null;
      }

      return "Invalid";
    },
    ShowAndHidePhoneNumberVerificationDialog: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownPhoneNumberVerificationDialog: state,
        };
      });
    },
  };

  const sideEffectHandlers: MoreProfileEditPageSideEffect = {
    navigate: function (target: string): void {
      console.log("navigate", target);
      window.history.back();
    },

    requestUpdateProfile: function (): void {
      requestEditProfile()
        .then(() => {
          console.log("requestUpdateProfile");
        })
        .catch((error) => {
          console.error("Error requestUpdateProfile:", error);
        });
    },
  };

  async function requestDetailProfile(): Promise<UserProfile | undefined> {
    const url = "/pw/users/detailProfile";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };
    try {
      const res = await ApiService.MobilePost(url, requestBody);
      if (res) {
        let userProfile = res.data.body as UserProfile;
        return res.data.body as UserProfile;
      } else {
        console.log(`requestDetailProfile failed ${res}`);
        window.history.back();
      }
    } catch (error) {
      window.history.back();
      console.error("Error requestDetailProfile:", error);
    } finally {
      setProgress(false);
    }
  }

  async function requestEditProfile(): Promise<void> {
    const url = "/pw/users/editProfile";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
      birthday: updateBirthDay(uiState.day, uiState.month, uiState.year),
      addressCountry: uiState.country,
      addressProvince: uiState.cityCountyDistrict,
      addressDetail: uiState.cityDetail,
      phoneNumber: localStorage.getItem("user_phone_number"),
      countryNumber: updateCountryNumber(uiState.localNumber),
      gender: updateGender(uiState.gender),
      tribeName: uiState.tribeName,
      tribeCategory: uiState.tribeCategory,
    };
    console.log("requestEditProfile", requestBody);

    try {
      const res = await ApiService.MobilePost(url, requestBody);
      console.log("PROFILE", res);
      if (res) {
        let userProfile = res.data.body as UserProfile;
        uiEventHandler.SetIsDoEditCompleteDialog(false);
        uiEventHandler.SetIsEditCompleteDialog(true);
      } else {
        console.log(`requestEditProfile failed ${res}`);
        uiEventHandler.SetIsDoEditCompleteDialog(false);
      }
    } catch (error) {
      console.error("Error requestEditProfile:", error);
      uiEventHandler.SetIsDoEditCompleteDialog(false);
    }
  }

  const getCountryNumber = (value: string) => {
    const countryCodes: { [key: string]: string } = {
      "855": "+855",
      "82": "+82",
      "1": "+1",
    };

    return countryCodes[value] || "+855";
  };

  const getBirthDay = (birth: number) => {
    const date = new Date(birth);
    return date.getDate();
  };

  const getBirthMonth = (birth: number) => {
    const date = new Date(birth);
    return date.getMonth() + 1;
  };

  const getBirthYear = (birth: number) => {
    const date = new Date(birth);
    return date.getFullYear();
  };

  const updateBirthDay = (day: string, month: string, year: string) => {
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    console.log("Birthday - Date", date);
    return date.getTime();
  };

  const updateGender = (gender: string) => {
    return gender == "male" ? "Male" : "Female";
  };

  const updateCountryNumber = (value: string) => {
    const countryCodes: { [key: string]: string } = {
      "+855": "855",
      "+82": "82",
    };

    return countryCodes[value] || "855";
  };

  return (
    <>
      <MoreProfileRender
        sideEffect={sideEffectHandlers}
        uiEvent={uiEventHandler}
        uiState={uiState}
      />
    </>
  );
};

export default MoreProfileEditPage;
