import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { MembershipStatus } from "../../model/MembershipInfo";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import { render } from "@testing-library/react";

interface StatusDescriptionProps {
  status: MembershipStatus;
  renewalStartDate?: string; // 멤버십 갱신 시작 날짜
  renewalEndDate?: string; // 멤버십 갱신 종료 날짜
}

const StatusDescription = ({
  status,
  renewalStartDate,
  renewalEndDate,
}: StatusDescriptionProps) => {
  const renderText = (text: string, isBold: boolean = false) => (
    <Typography
      fontStyle={isBold ? fontStyle.titleM : fontStyle.bodyMr}
      color={color.default}
      style={{ textAlign: "center", whiteSpace: "pre-line" }}
    >
      {text}
    </Typography>
  );

  const renderDescription = () => {
    switch (status) {
      case MembershipStatus.ActiveWithinRenewal:
        return (
          <>
            {renderText(LocalizedText("ua_g420_plzduring"))}
            {renderText(`${renewalStartDate} - ${renewalEndDate}`, true)}
          </>
        );
      case MembershipStatus.ActiveOutsideRenewal:
        return (
          <Typography
            fontStyle={fontStyle.bodyMr}
            color={color.default}
            style={{ textAlign: "center", whiteSpace: "pre-line" }}
          >
            {LocalizedText("ua_g420_purchasenew") + " "}
            <Typography
              component="span" 
              fontStyle={fontStyle.titleM} 
              color={color.default}
            >
              {renewalStartDate} ~ {renewalEndDate}
            </Typography>
          </Typography>
        );
      case MembershipStatus.Expired:
        return renderText(
          LocalizedText("ua_g420_stopmembership"),
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: "15px",
        boxSizing: "border-box",
      }}
    >
      {renderDescription()}
    </Box>
  );
};

export default StatusDescription;
