import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import { ProductionHistoryList } from "./model/ProductionHistoryData";
import { ProductionHistoryTableRow } from "./ProductionHistoryTableRow";

interface ProductionManagementTableProps {
  headCells?: HeadCell[];
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: any;
}

interface ProductionHistoryData {
  [x: string]: string | number;
}

export function ProductionHistoryTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: ProductionManagementTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(
      ProductionHistoryList,
      rowsPerPage || 10,
      setPage,
      page || 0
    );

  const pagedata: ProductionHistoryData[] = visibleRows.slice(
    (page || 0) * (rowsPerPage || 10),
    ((page || 0) + 1) * (rowsPerPage || 10)
  );

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {pagedata.map((data: ProductionHistoryData, index: number) => (
        <ProductionHistoryTableRow row={data} key={index} />
      ))}
    </CommonTable>
  );
}
