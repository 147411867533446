import { Snackbar, Alert, Typography, SxProps, Box } from "@mui/material";
import { color } from "../theme/Color";
import { fontStyle } from "../theme/Style";

interface ToastAlertProps {
  open: boolean;
  onClose: () => void;
  message: string;
  bottomOffset?: number; // bottom 위치 조정
  mainColor?: string;
}

export default function ToastAlert({
  open,
  onClose,
  message,
  bottomOffset = 0,
  mainColor = color.success,
}: ToastAlertProps) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000} //3초 후 자동으로 사라짐
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }} //토스트 위치
      sx={{
        transform: `translateY(-${bottomOffset}px)`,
      }}
    >
      <Alert
        sx={{
          maxWidth: "75%",
          display: "flex",
          alignItems: "center",
          backgroundColor: (mainColor == color.success) ? "rgba(235, 250, 243, 0.95)" : "rgba(255, 224, 224, 0.95)",
          color: mainColor,
          border: `1px solid ${mainColor}`,
          borderRadius: "6px",
          padding: "12px 16px",
          minWidth: "200px",
          height: "24px",
          boxShadow: "0px 1px 5px rgba(27, 25, 24, 0.1)",
        }}
        icon={false}
      >
        <Typography sx={{ fontStyle: fontStyle.bodySr }}>{message}</Typography>
      </Alert>
    </Snackbar>
  );
}
