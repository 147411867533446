export class ProductionData {
  no: number;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  made: string;
  quantities: string;
  amount: number;
  status: ProductionStatusType;
  registrationDate: number;

  constructor(
    no: number,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    made: string,
    quantities: string,
    amount: number,
    status: ProductionStatusType,
    registrationDate: number
  ) {
    this.no = no;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.made = made;
    this.quantities = quantities;
    this.amount = amount;
    this.status = status;
    this.registrationDate = registrationDate;
  }
}

export enum ProductionStatusType {
  CONFIRM = "Confirm",
  REJECT = "Reject",
  UPDATE = "Update",
}

export const ProductionList: ProductionData[] = [
  new ProductionData(
    1,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000",
    "Gwang-Ju",
    "SET",
    20,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    2,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000",
    "Gwang-Ju",
    "SET",
    20,
    ProductionStatusType.REJECT,
    1716499200
  ),
  new ProductionData(
    3,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000",
    "Manufacture",
    "SET",
    20,
    ProductionStatusType.UPDATE,
    1716499200
  ),
  new ProductionData(
    4,
    "SEMI ASSY",
    "VW90-00001A",
    "ASSY CELL PACK",
    "",
    "Manufacture",
    "SET",
    30,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    5,
    "SEMI ASSY",
    "VW90-00001A",
    "ASSY CELL PACK",
    "",
    "Manufacture",
    "PCS",
    30,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    6,
    "SEMI ASSY",
    "VW90-00001A",
    "ASSY CELL PACK",
    "",
    "Manufacture",
    "SET",
    30,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    7,
    "SEMI ASSY",
    "VW90-00001A",
    "ASSY CELL PACK",
    "",
    "Manufacture",
    "PCS",
    30,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    8,
    "SEMI ASSY",
    "VW90-00001A",
    "ASSY CELL PACK",
    "",
    "Manufacture",
    "PCS",
    30,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    9,
    "SEMI ASSY",
    "VW90-00001A",
    "ASSY CELL PACK",
    "ASSY BATTERY SET (3600000",
    "Manufacture",
    "PCS",
    30,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    10,
    "SEMI ASSY",
    "VW90-00001A",
    "ASSY CELL PACK",
    "",
    "Manufacture",
    "PCS",
    30,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    11,
    "FINISHED",
    "VBM-300RGP",
    "BATTERY MODULE",
    "ASSY BATTERY MODULE (4500000)",
    "Seoul",
    "MODULE",
    50,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    12,
    "FINISHED",
    "VBM-300RGP",
    "BATTERY MODULE",
    "ASSY BATTERY MODULE (4500000)",
    "Seoul",
    "MODULE",
    50,
    ProductionStatusType.REJECT,
    1716499200
  ),
  new ProductionData(
    13,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000)",
    "Busan",
    "SET",
    20,
    ProductionStatusType.UPDATE,
    1716499200
  ),
  new ProductionData(
    14,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000)",
    "Busan",
    "SET",
    20,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    15,
    "PARTS",
    "VCP-150XLG",
    "CELL PACK",
    "CELL PACK ASSEMBLY (150000)",
    "Incheon",
    "PCS",
    100,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    16,
    "PARTS",
    "VCP-150XLG",
    "CELL PACK",
    "CELL PACK ASSEMBLY (150000)",
    "Incheon",
    "PCS",
    100,
    ProductionStatusType.REJECT,
    1716499200
  ),
  new ProductionData(
    17,
    "FINISHED",
    "VBM-500RGP",
    "BATTERY MODULE",
    "ASSY BATTERY MODULE (5000000)",
    "Gwang-Ju",
    "MODULE",
    10,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    18,
    "FINISHED",
    "VBM-500RGP",
    "BATTERY MODULE",
    "ASSY BATTERY MODULE (5000000)",
    "Gwang-Ju",
    "MODULE",
    10,
    ProductionStatusType.REJECT,
    1716499200
  ),
  new ProductionData(
    19,
    "SEMI ASSY",
    "VSP-300LSP",
    "CELL STACK",
    "",
    "Gwang-Ju",
    "STACK",
    25,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    20,
    "SEMI ASSY",
    "VSP-300LSP",
    "CELL STACK",
    "",
    "Gwang-Ju",
    "STACK",
    25,
    ProductionStatusType.REJECT,
    1716499200
  ),
  new ProductionData(
    21,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000)",
    "Seoul",
    "SET",
    15,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    22,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000)",
    "Seoul",
    "SET",
    15,
    ProductionStatusType.UPDATE,
    1716499200
  ),
  new ProductionData(
    23,
    "FINISHED",
    "VBM-400RGP",
    "BATTERY MODULE",
    "ASSY BATTERY MODULE (4000000)",
    "Busan",
    "MODULE",
    40,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    24,
    "FINISHED",
    "VBM-400RGP",
    "BATTERY MODULE",
    "ASSY BATTERY MODULE (4000000)",
    "Busan",
    "MODULE",
    40,
    ProductionStatusType.REJECT,
    1716499200
  ),
  new ProductionData(
    25,
    "SEMI ASSY",
    "VSP-200LSP",
    "CELL STACK",
    "",
    "Manufacture",
    "STACK",
    20,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    26,
    "SEMI ASSY",
    "VSP-200LSP",
    "CELL STACK",
    "",
    "Manufacture",
    "STACK",
    20,
    ProductionStatusType.UPDATE,
    1716499200
  ),
  new ProductionData(
    27,
    "PARTS",
    "VCP-180XLG",
    "CELL PACK",
    "CELL PACK ASSEMBLY (180000)",
    "Seoul",
    "PCS",
    120,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    28,
    "PARTS",
    "VCP-180XLG",
    "CELL PACK",
    "CELL PACK ASSEMBLY (180000)",
    "Seoul",
    "PCS",
    120,
    ProductionStatusType.REJECT,
    1716499200
  ),
  new ProductionData(
    29,
    "FINISHED",
    "VBM-600RGP",
    "BATTERY MODULE",
    "ASSY BATTERY MODULE (6000000)",
    "Incheon",
    "MODULE",
    35,
    ProductionStatusType.CONFIRM,
    1716499200
  ),
  new ProductionData(
    30,
    "FINISHED",
    "VBM-600RGP",
    "BATTERY MODULE",
    "ASSY BATTERY MODULE (6000000)",
    "Incheon",
    "MODULE",
    35,
    ProductionStatusType.REJECT,
    1716499200
  ),
];
