import CertificateItem from "./model/CertificateItem";
import WalletInfo from "./model/WalletInfo";
import { WalletNFT } from "./model/WalletNFT";

export class MoreWalletPageUiState {
  userWallet: WalletInfo = {
    address: "",
    name: "",
    uuid: "",
    userDid: {
      private_key: "",
      did: "",
    },
  };
  nfts: WalletNFT = {
    characters: [],
    batteries: [],
    scooters: [],
    address: "",
  };
  certificates: CertificateItem[] = [];
  currentTab: string = "NFTs"; // NFTs, VC
  isToastOpen: boolean = false; //ToastAlert 상태
  isShowVCDialog: boolean = false; //VC 상세정보 다이얼로그 상태
  selectedVC: string = ""; //선택된 VC ID
  vcDetail: CertificateItem | null = null; //VC 상세정보
  isShowErrorDialog: boolean = false; //VC 정보 로드 에러
}
