import { TableRow } from "@mui/material";
import { InventoryCellData } from "../../inventory/model/InventoryCellData";
import { ReceiveDeliverData } from "../model/RecieveDeliverData";
import {
  CheckboxState,
  CommonCheckbox,
} from "../../../../component/CommonCheckbox";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { Text } from "../../../../component/Text";
import { MaterialRegistrationFindPartsTableCell } from "./MaterialRegistrationFindPartsTableCell";

interface MaterialRegistrationFindPartsTableRowProps {
  row: ReceiveDeliverData;
}

export function MaterialRegistrationFindPartsTableRow({
  row,
}: MaterialRegistrationFindPartsTableRowProps) {
  const inventoryCellData: InventoryCellData[] = [
    new InventoryCellData("", "left", "20px", "4px"),
    new InventoryCellData(`${row.partsCode}`, "left", "120px"),
    new InventoryCellData(`${row.productName}`, "left", "123px"),
    new InventoryCellData(`${row.spec}`, "left"),
    new InventoryCellData(`${row.company}`, "left", "120px"),
    new InventoryCellData(`${row.category}`, "left", "120px", "", "4px"),
  ];
  return (
    <TableRow>
      {inventoryCellData.map((data: InventoryCellData, index: number) => (
        <MaterialRegistrationFindPartsTableCell
          key={data.value}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            width: data.width || "auto",
            ...(index === inventoryCellData.length - 3
              ? { flex: 1, maxWidth: "232px" }
              : {}),
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          children={
            index === 0 ? (
              <CommonCheckbox
                checkboxState={CheckboxState.Unchecked} // 초기 상태
                checkboxName=""
                onClick={(state) => console.log(data.value, state)} // 개별 체크박스 클릭 핸들러
                sx={{ height: "16px", width: "16px", margin: 0 }}
              />
            ) : (
              <Text
                sx={{
                  fontStyle: fontStyle.regularXS,
                  color: color.gray800,
                }}
                text={data.value}
              />
            )
          }
        />
      ))}
    </TableRow>
  );
}
