import { CalendarType } from "../../component/InquiryPeriod/CalendarDrawer";
import { InquiryHistoryModel } from "./model/InquiryHistoryModel";

export class InquiryPageUiState {
  historyList: InquiryHistoryModel[];
  startDate: number;
  endDate: number;
  selectedDate: number;
  calendarType: CalendarType;
  isVisibleDataPicker: boolean;
  isCalendarOpen: boolean;
  isDateAlertOpen: boolean;

  constructor(
    historyList: InquiryHistoryModel[],
    startDateText: number,
    endDateText: number,
    selectedDateText: number,
    calendarType: CalendarType,
    isVisibleDataPicker: boolean,
    isCalendarOpen: boolean,
    isDateAlertOpen: boolean,
  ) {
    {
      this.historyList = historyList;
      this.startDate = startDateText;
      this.endDate = endDateText;
      this.selectedDate = selectedDateText;
      this.calendarType = calendarType;
      this.isVisibleDataPicker = isVisibleDataPicker;
      this.isCalendarOpen = isCalendarOpen;
      this.isDateAlertOpen = isDateAlertOpen;
    }
  }
}

