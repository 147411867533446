import MyRideRender from "./MyRideRender";
import { MyRideUiState } from "./model/MyRideUiState";
import { useEffect, useState } from "react";
import { ApiService } from "../../restAPI/ApiService";
import ProgressBar from "../../component/ProgressBar";
import { MyRideUiEvent } from "./model/MyRideUiEvent";

const MyRidePage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [uiState, setUiState] = useState(new MyRideUiState());

  useEffect(() => {}, []);

  useEffect(() => {
    const requestBody = {
      uuid: localStorage.getItem("uuid") || "",
    };
    const init = async () => {
      await ApiService.MobilePost(
        "/pw/backend/api/scooter/userScoo",
        requestBody,
      )
        .then(async (res) => {
          if (res?.data.body.length <= 0) {
            setUiState((prev) => {
              return {
                ...prev,
                hasScooter: false,
              };
            });
            // console.log("스쿠터 데이터의 길이는 0입니다.");

            await ApiService.MobilePost(
              "/pw/backend/api/scooterAgreement/list",
              requestBody,
            )
              .then((res) => {
                const data = res?.data.body.handOverList;
                if (data.length > 0) {
                  setUiState((prev) => {
                    return {
                      ...prev,
                      hasScooterContractList: true,
                      scooterContractList: data,
                    };
                  });
                } else {
                  setUiState((prev) => {
                    return {
                      ...prev,
                      hasScooterContractList: false,
                    };
                  });
                }
                setLoading(false);
              })
              .catch((e) => {
                setError(`Failed to load scooter contract. ${e}`);
              });
          } else {
            setUiState((prev) => {
              return {
                ...prev,
                hasScooter: true,
                scooterList: res?.data.body,
              };
            });
            setLoading(false);
          }
        })
        .finally(() => {
          uiEventHandler.SetRefreshAPI(false);
        });
    };

    init().then((r) => {});
  }, [uiState.refreshAPI]);

  // useEffect(() => {
  //   console.log("uiState.refreshAPI is : ", uiState.refreshAPI);
  // }, [uiState.refreshAPI]);
  // useEffect(() => {
  //   if (hasQr == true) {
  //     setUiState((prev) => {
  //       return {
  //         ...prev,
  //         hasScooterTest: true,
  //       };
  //     });
  //   } else {
  //     setUiState((prev) => {
  //       return {
  //         ...prev,
  //         hasScooterTest: false,
  //       };
  //     });
  //   }
  // }, []);

  const uiEventHandler: MyRideUiEvent = {
    SetContractList: (event: any) => {
      setUiState((prev) => {
        return {
          ...prev,
          scooterContractList: event,
        };
      });
    },
    SetRefreshAPI: (event: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          refreshAPI: event,
        };
      });
    },
  };

  if (loading) {
    return <ProgressBar />;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return <MyRideRender uiState={uiState} uiEvent={uiEventHandler} />;
};

export default MyRidePage;
