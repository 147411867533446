import { Box } from "@mui/material";
import { Text } from "../../../component/Text";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { NotificationSwitch } from "./NotificationSwitch";

interface NotificationCardProps {
  title: string;
  content: string;
  isChecked: boolean;
  onClick: () => void;
}

export default function NotificationCard({
  title,
  content,
  isChecked,
  onClick,
}: NotificationCardProps) {
  return (
    <Box>
      <Box
        sx={{
          px: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text
            sx={{
              fontStyle: fontStyle.titleL,
              color: color.default,
              flexGrow: 1,
              whiteSpace: "pre-line",
            }}
            text={title}
          />
          <HorizontalSpacer width={10} />
          <NotificationSwitch isChecked={isChecked} onChange={onClick} />
        </Box>
        <VerticalSpacer height={8} />
        <Text
          sx={{
            fontStyle: fontStyle.bodyLr,
            color: color.gray600,
            whiteSpace: "pre-line",
          }}
          text={content}
        />
      </Box>
    </Box>
  );
}
