export class CouponManagementUiState {
  couponDataRows: Array<any> = [];
  countryList: {label: string; value: string;}[] = [];
  editCardId: string = "";
  name: string = "";
  availableDays: string = "";
  description: string = "";
  country: string = "";
  type: string = "";
  id: string = "";
  inValidDataCheck: {
    country: boolean;
    name: boolean;
    type: boolean;
    availableDays: boolean;
    description: boolean;
  };
  constructor(
    couponDataRows: Array<any> = [],
    countryList: {label: string; value: string;}[] = [],
    editCardId: string = "",
    name: string = "",
    availableDays: string = "",
    description: string = "",
    country: string = "",
    type: string = "",
    id: string = "",
    inValidDataCheck: { country: boolean; name: boolean; type: boolean; availableDays: boolean; description: boolean } = {
      country: false,
      name: false,
      type: false,
      availableDays: false,
      description: false,
    }
  ) {
    this.couponDataRows = couponDataRows;
    this.countryList = countryList;
    this.editCardId = editCardId;
    this.name = name;
    this.availableDays = availableDays;
    this.description = description;
    this.country = country;
    this.type = type;
    this.id = id;
    this.inValidDataCheck = inValidDataCheck;
  }
}

interface Country {
  label: string;
  value: string;
}