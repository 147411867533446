import { useState } from "react";
import CommonList from "../../component/CommonList";
import {
  RequestManagementTab,
  RequestManagementTabType,
} from "./section/RequestManagementTab";
import { RequestManagementColtrol } from "./requestmanagementcontrol/RequestManagementControl";
import { CommonWebHeaderButton } from "../../component/CommonWebHeaderButton";
import { RequestHistory } from "./requesthistory/RequestHistory";

const RequestManagement = () => {
  const [currentTab, setCurrentTab] = useState<RequestManagementTabType>(
    RequestManagementTabType.REQUEST_MANAGEMENT_CONTROL
  );
  return (
    <CommonList
      title="요청 관리"
      tabButton={
        <RequestManagementTab
          currentTab={currentTab}
          changeTab={(tab) => setCurrentTab(tab)}
        />
      }
      headerButton={
        currentTab == RequestManagementTabType.REQUEST_MANAGEMENT_CONTROL && (
          <CommonWebHeaderButton
            width="122px"
            height="45px"
            buttonContent="Register"
            addIcon={true}
          />
        )
      }
    >
      {currentTab == RequestManagementTabType.REQUEST_MANAGEMENT_CONTROL && (
        <RequestManagementColtrol />
      )}
      {currentTab == RequestManagementTabType.REQUEST_HISTORY && (
        <RequestHistory />
      )}
    </CommonList>
  );
};

export default RequestManagement;
