import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import CommonTextField from "../../../../../component/CommonTextField";

export function MaterialDetailsDate() {
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Regist Date
          </Typography>
          <VerticalSpacer height={8} />

          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
            disabled
          />
        </Box>
        <HorizontalSpacer width={28} />
        <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Last Update Date
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
            disabled
          />
        </Box>
      </Box>
    </CardContent>
  );
}
