import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import {
  ICON_CHECK2,
  ICON_UNCHECK,
} from "../../../../../constants/appImagePath";
import CommonTextField from "../../../../../component/CommonTextField";
import { UserDetailsUiState } from "../../../UserDetailsUiState";
import { UserDetailsUiEvent } from "../../../UserDetailsUiEvent";
import CommonDatePicker from "../../../../../component/CommonDatePicker";
import VerticalSpacer from "../../../../../component/Spacer";
import { CommonSelect } from "../../../../../component/CommonSelect";
import CommonTimePicker from "../../../../../component/CommonTimePicker";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { ApiService } from "../../../../../restAPI/ApiService";
import CommonDialog from "../../../../../component/CommonDialog";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

interface UserScooterRentalCardProps {
  uiState: UserDetailsUiState;
  uiEvent: UserDetailsUiEvent;
}

const UserScooterRentalCard = ({
  uiState,
  uiEvent,
}: UserScooterRentalCardProps) => {
  const [loading, setLoading] = useState(false); // 로딩 상태 추가

  const [rentalStartDate, setRentalStartDate] = useState<Dayjs | null>(null);
  const [rentalStartTime, setRentalStartTime] = useState<Dayjs | null>(null);
  const [finalRentalStartDate, setFinalRentalStartDate] =
    useState<Dayjs | null>(null);

  const handleRentalStartDateChange = (newDate: Dayjs | null) => {
    setRentalStartDate(newDate);
  };
  const handleRentalStartTimeChange = (newTime: Dayjs | null) => {
    setRentalStartTime(newTime);
  };

  const [rentalEndDate, setRentalEndDate] = useState<Dayjs | null>(null);
  const [rentalEndTime, setRentalEndTime] = useState<Dayjs | null>(null);
  const [finalRentalEndDate, setFinalRentalEndDate] = useState<Dayjs | null>(
    null
  );

  const handleRentalEndDateChange = (newDate: Dayjs | null) => {
    setRentalEndDate(newDate);
  };

  const handleRentalEndTimeChange = (newTime: Dayjs | null) => {
    setRentalEndTime(newTime);
  };
  const [returnDateCheckOkModalOpen, setReturnDateCheckOkModalOpen] =
    useState(false);
  const handleReturnDateCheck = () => {
    setReturnDateCheckOkModalOpen(true);
  };

  // rentalStartDate와 rentalStartTime을 병합하여 finalRentalStartDate 설정
  useEffect(() => {
    if (rentalStartDate && rentalStartTime) {
      const mergedDateTime = rentalStartDate
        .hour(rentalStartTime.hour())
        .minute(rentalStartTime.minute())
        .second(0);
      setFinalRentalStartDate(mergedDateTime);
    }
  }, [rentalStartDate, rentalStartTime]);

  // rentalEndDate와 rentalEndTime 변경 시 finalRentalEndDate 업데이트
  useEffect(() => {
    if (rentalEndDate && rentalEndTime) {
      const combinedDateTime = rentalEndDate
        .hour(rentalEndTime.hour())
        .minute(rentalEndTime.minute());
      setFinalRentalEndDate(combinedDateTime);
    }
  }, [rentalEndDate, rentalEndTime]);

  const updateScooterContractSheet = () => {
    if (uiState.ReturnDate) {
      const formData = new FormData();
      console.log(uiState.selectScooterSN);
      const userData = {
        agreementNumber: uiState.SelectedScooterContractSheet,
        returnDt: uiState.ReturnDate,
      };
      // Blob으로 JSON을 감싸서 추가, Content-Type 명시
      const blob = new Blob([JSON.stringify(userData)], {
        type: "application/json",
      });

      formData.append("userData", blob);
      ApiService.WebPost(
        "/pw/backend/api/scooterAgreement/updateScooterAgreementInfo",
        formData,
        { "Content-Type": "multipart/form-data" } // 헤더에 Content-Type 명시
      ).then((res) => {
        console.log(res?.data);
      });
    } else {
      // FormData 객체 생성

      // console.log(scooterInfoSheetData);
      const formData = new FormData();

      if (!rentalStartDate || !rentalEndDate) {
        if (!rentalStartDate) {
          alert("Rental Start Date의 날짜를 선택해주세요");
        } else if (!rentalEndDate) {
          alert("Rental End Date의 날짜를 선택해주세요");
        }
      } else if (!rentalStartTime || !rentalEndTime) {
        if (!rentalStartTime) {
          alert("Rental Start Date의 시간을 선택해주세요");
        } else if (!rentalEndTime) {
          alert("Rental End Date의 시간을 선택해주세요");
        }
      }

      if (finalRentalStartDate && finalRentalEndDate) {
        // userData 객체 생성
        const userData = {
          agreementNumber: uiState.SelectedScooterContractSheet,
          productName: uiState.ScooterProductName,
          ownerUuid: uiState.OwnerUUid,
          receiptStartDt: finalRentalStartDate.valueOf(),
          receiptEndDt: finalRentalEndDate.valueOf(),
        };

        // Blob으로 JSON을 감싸서 추가, Content-Type 명시
        const blob = new Blob([JSON.stringify(userData)], {
          type: "application/json",
        });

        formData.append("userData", blob);
        setLoading(true);
        ApiService.WebPost(
          "/pw/backend/api/scooterAgreement/updateScooterAgreementInfo",
          formData,
          { "Content-Type": "multipart/form-data" } // 헤더에 Content-Type 명시
        )
          .then((res) => {
            console.log(res?.data);
            if (res?.data.body == "Owner does not own this type of scooter.") {
              alert(
                "이 Owner 는 " + uiState.ScooterProductName + " 이 없습니다."
              );
            }
            // 성공 처리 로직 추가
          })
          .then(() => {
            ApiService.WebPost(
              "/pw/backend/api/scooterAgreement/agreementNumberList",
              {
                uuid: uiState.uuid,
              }
            ).then((res) => {
              uiEvent.HandleUiStateChange("ScooterSheet", res?.data.body);
              uiEvent.HandleUiStateChange(
                "SelectedScooterContractSheet",
                res?.data.body[res?.data.body.length - 1]
              );
            });
          })
          .catch((error) => {
            console.error("서버 요청 중 오류 발생:", error);
            // 에러 처리 로직 추가
          })
          .finally(() => {
            setLoading(false);
            uiEvent.HandleUiStateChange("addLoadingState", true);
          });
      }
    }
  };

  useEffect(() => {
    if (uiState.RentalStartDate) {
      const tempDate = dayjs(uiState.RentalStartDate);
      setRentalStartDate(tempDate.startOf("day")); // 날짜 부분만 설정
      setRentalStartTime(
        tempDate.hour(tempDate.hour()).minute(tempDate.minute())
      ); // 시간 부분만 설정
    }
    if (uiState.RentalEndDate) {
      const tempDate = dayjs(uiState.RentalEndDate);
      setRentalEndDate(tempDate.startOf("day")); // 날짜 부분만 설정
      setRentalEndTime(
        tempDate.hour(tempDate.hour()).minute(tempDate.minute())
      ); // 시간 부분만 설정
    }
  }, [uiState.RentalEndDate, uiState.RentalStartDate]);

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: "140px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0)", // 배경 흐리게
            zIndex: 1000, // 다른 요소들 위에 배치
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Grid item lg={12} sx={{ mt: "16px" }}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            p: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CardHeader
              title="Rental"
              titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
              sx={{ p: "0px" }}
            />
            <Button
              sx={{
                textTransform: "none",
                width: "90px",
                height: "36px",
                color: color.primary500,
                border: 1,
                borderRadius: "40px",
                borderColor: color.primary500,
                fontStyle: fontStyle.semiboldS,
                "&.Mui-disabled": {
                  borderColor: color.gray300, // disabled 상태에서의 테두리 색상
                },
              }}
              onClick={updateScooterContractSheet}
              disabled={uiState.ReturnDateCheck}
            >
              Update
            </Button>
          </Box>
          {/* 첫번째 줄 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "48.5%",
                }}
              >
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Owner
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <CommonSelect
                    selectValue={uiState.OwnerNameEmail}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    menuitems={uiState.OwnerList.map(
                      (manager: any) =>
                        manager.name + " (" + manager.email + ")"
                    )}
                    onChange={(event) => {
                      // uiEvent.HandleUiStateChange(
                      //   "OwnerUUid",
                      //   event.target.value
                      // );
                      const selectedEmail = event.target.value;
                      uiEvent.HandleUiStateChange(
                        "OwnerNameEmail",
                        selectedEmail
                      );
                      const selectedOwner: any = uiState.OwnerList.find(
                        (manager: any) =>
                          manager.name + " (" + manager.email + ")" ===
                          selectedEmail
                      );
                      // console.log("selectedManager is : ", selectedManager);
                      if (selectedOwner) {
                        uiEvent.HandleUiStateChange(
                          "OwnerUUid",
                          selectedOwner.uuid
                        ); // uuid 업데이트
                      }
                    }}
                    disabled={uiState.RentalStartDate ? true : false}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "48.5%",
                }}
              >
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Return Date
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    border: 1,
                    borderColor: color.gray300,
                    bgcolor: uiState.isHandOver
                      ? uiState.ReturnDate
                        ? color.gray200
                        : "white"
                      : color.gray200,
                  }}
                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{ fontStyle: fontStyle.bodyMr }}
                        color={color.gray800}
                      >
                        {uiState.ReturnDate ? (
                          <>
                            {LocalizedDate({
                              date: uiState.ReturnDate,
                              type: DateFormatType.AdminType,
                            })}
                          </>
                        ) : (
                          <>Verified</>
                        )}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        sx={{ px: "8px", pl: "24px" }}
                        checked={uiState.ReturnDate != 0}
                        onChange={handleReturnDateCheck}
                        icon={<img src={ICON_UNCHECK} alt="unchecked" />}
                        checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
                      />
                    }
                    disabled={
                      uiState.isHandOver
                        ? uiState.ReturnDate
                          ? true
                          : false
                        : true
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* 두번째 줄 */}
          <VerticalSpacer height={16} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "48.5%",
              }}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Rental Start Date
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  mt: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CommonDatePicker
                    value={rentalStartDate}
                    onChange={handleRentalStartDateChange}
                    InputPropsSx={{ height: "48px" }}
                    disabled={uiState.RentalStartDate ? true : false}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CommonTimePicker
                    value={rentalStartTime}
                    onChange={handleRentalStartTimeChange}
                    sx={{ height: "48px" }}
                    disabled={uiState.RentalStartDate ? true : false}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "48.5%",
              }}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Rental End Date
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  mt: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CommonDatePicker
                    value={rentalEndDate}
                    onChange={handleRentalEndDateChange}
                    InputPropsSx={{ height: "48px" }}
                    disabled={uiState.RentalEndDate ? true : false}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CommonTimePicker
                    value={rentalEndTime}
                    onChange={handleRentalEndTimeChange}
                    sx={{ height: "48px" }}
                    disabled={uiState.RentalEndDate ? true : false}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>
      {/* firstResidentCheck 모달 */}
      <CommonDialog
        open={returnDateCheckOkModalOpen}
        handleClose={() => {
          setReturnDateCheckOkModalOpen(false);
        }}
        handleOk={() => {
          // handleUpdateScooterInfo("ReturnDateCheck", true);
          // uiEvent.HandleUiStateChange("ReturnDateCheck", true);

          let date = new Date().getTime();
          uiEvent.HandleUiStateChange("ReturnDate", date);
          setReturnDateCheckOkModalOpen(false);
        }}
      >
        <Typography
          fontStyle={fontStyle.regularS}
          color={color.gray800}
          sx={{ display: "flex", alignItems: "center" }}
        >
          I confirm the scooter return.
        </Typography>
      </CommonDialog>
    </>
  );
};

export default UserScooterRentalCard;
