import {
  Box,
  Card,
  CardContent,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import CommonList from "../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../component/CommonWebHeaderButton";
import { color } from "../../../theme/Color";
import { ICON_TRASH } from "../../../constants/imagePath";
import { HorizontalSpacer } from "../../../component/Spacer";
import { NfcDetailIdCard, NfcRow } from "./NfcDetailIdCard";
import { useEffect, useState } from "react";
import { NfcDetailOwnerCard } from "./NfcDetailOwnerCard";
import { ApiService } from "../../../restAPI/ApiService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackButtonDiallog } from "./BackButtonDiallog";
import { DeleteButtonDiallog } from "./DeleteButtonDiallog";
import CommonDialog from "../../../component/CommonDialog";
import { fontStyle } from "../../../theme/Style";

//TODO Reason 관련 부분 추후 구현필요

export function NfcDetails({}) {
  //Type 관련
  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState(false);

  //Valid 관련
  const [valid, setValid] = useState("");
  const [validError, setValidError] = useState(false);

  //Reason 관련
  // const [reason, setReason] = useState("");

  //Owner 관련
  const [owner, setOwner] = useState("");
  const [ownerUuid, setOwnerUuid] = useState("");

  //body의 데이터들

  //nfcCardId 관련
  const [nfcCardId, setNfcCardId] = useState("");
  const [nfcCardIdError, setNfcCardIdError] = useState(false);
  const [register, setRegister] = useState("");

  const location = useLocation();
  const [rows, setRows] = useState<NfcRow>();

  //diallog 관련
  const [open, setOpen] = useState(false);
  const [deleteDiallogOpen, setDeleteDiallogOpen] = useState(false);
  const [existDialogOpen, setExistDialogOpen] = useState(false);

  // URL에서 마지막 부분을 추출
  const pathname = location.pathname;
  const id = pathname.split("/").pop();

  const navigate = useNavigate();
  useEffect(() => {
    if (!id) return;
    ApiService.WebPost("/pw/backend/api/nfccard/detail", {
      id: id,
    }).then((res) => {
      setRows(res?.data.body);
    });
  }, [id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/nfcCardManagement?tab=nfcManagement");
    window.location.reload();
  };

  const handleDeleteClose = () => {
    setDeleteDiallogOpen(false);
  };

  const handleExistOk = () => {
    setExistDialogOpen(false);
  };

  const updateOnClick = () => {
    let hasError = false;

    // 각 필드에 대한 데이터 검증
    if (nfcCardId.length <= 0) {
      setNfcCardIdError(true);
      hasError = true;
    } else {
      setNfcCardIdError(false);
    }

    if (type === "") {
      setTypeError(true);
      hasError = true;
    } else {
      setTypeError(false);
    }

    if (valid === "") {
      setValidError(true);
      hasError = true;
    } else {
      setValidError(false);
    }
    if (hasError) {
    } else {
      ApiService.WebPost("/pw/backend/api/nfccard/update", {
        id: id,
        nfcCardId: nfcCardId,
        type: type,
        valid: valid,
        owner: ownerUuid === "" ? null : ownerUuid,
        register: register,
      }).then((res) => {
        if (res?.data.message === "This nfc card already exist") {
          setExistDialogOpen(true);
        } else {
          window.location.href = `/NfcDetails/${id}`;
        }
      });
    }
  };

  //delete 버튼 누를 시 실행
  const deleteOnClick = () => {
    setDeleteDiallogOpen(true);
  };

  //delete버튼 누르고 ok 버튼 누를 시 실행
  const handleDeleteOk = () => {
    ApiService.WebDelete("/pw/backend/api/nfccard/delete", {
      id: id,
      uuid: localStorage.getItem("user_uuid"),
    }).then((res) => {
      window.location.href = `/nfcCardManagement`;
    });
  };

  return (
    <CommonList
      title="NFC Card Details"
      headerBackButton={true}
      backbuttonOnclick={handleClickOpen}
      headerButton={
        <Box sx={{ display: "flex" }}>
          <CommonWebHeaderButton
            buttonContent="Delete"
            sx={{
              backgroundColor: color.white,
              fontStyle: color.error,
              ":hover": {
                backgroundColor: color.red01,
              },
            }}
            icon={<img src={ICON_TRASH}></img>}
            height="45px"
            textColor={color.error}
            onClick={deleteOnClick}
          />
          <HorizontalSpacer width={12} />
          <CommonWebHeaderButton
            width="90px"
            height="45px"
            buttonContent="Update"
            sx={{ display: "flex", flexDirection: "column" }}
            onClick={updateOnClick}
          />
        </Box>
      }
    >
      <Dialog open={open} onClose={handleClose}>
        <BackButtonDiallog handleClose={handleClose} handleOk={handleOk} />
      </Dialog>

      <Dialog open={deleteDiallogOpen} onClose={handleClose}>
        <DeleteButtonDiallog
          handleDeleteClose={handleDeleteClose}
          handleDeleteOk={handleDeleteOk}
        />
      </Dialog>
      <Grid container spacing={"20px"}>
        <Grid item lg={12} sx={{ display: "flex" }}>
          <Card
            sx={{
              width: "50%",
              minWidth: "506px",
              borderRadius: "15px",
              padding: "24px",
              paddingBottom: "40px",
              boxShadow: 0,
              border: "1px solid",
              borderColor: color.gray300,
            }}
          >
            <NfcDetailIdCard
              type={type}
              setType={setType}
              valid={valid}
              setValid={setValid}
              rows={rows}
              nfcCardId={nfcCardId}
              setNfcCardId={setNfcCardId}
              nfcCardIdError={nfcCardIdError}
              validError={validError}
              typeError={typeError}
              // reason={reason}
              // setReason={setReason}
            />
          </Card>
          <HorizontalSpacer width={20} />
          <Card
            sx={{
              width: "50%",
              minWidth: "506px",
              eight: "312px",
              borderRadius: "15px",
              padding: "24px",
              paddingBottom: "40px",
              boxShadow: 0,
              border: "1px solid",
              borderColor: color.gray300,
            }}
          >
            <NfcDetailOwnerCard
              owner={owner}
              setOwner={setOwner}
              ownerUuid={ownerUuid}
              setOwnerUuid={setOwnerUuid}
              rows={rows}
            />
          </Card>
        </Grid>
        <CommonDialog
          open={existDialogOpen}
          handleOk={handleExistOk}
          cancleButton={false}
        >
          <Typography fontStyle={fontStyle.regularS} color={color.gray800}>
            This card is already registered.
          </Typography>
        </CommonDialog>
      </Grid>
    </CommonList>
  );
}
