import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Button, CardHeader, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fontStyle } from "../theme/Style";

interface CommonListProps {
  title: string;
  setModal?: any;
  children?: ReactNode;
  headerButton?: ReactNode;
  headerBackButton?: ReactNode;
  tabButton?: ReactNode;
  navigateURL?: string;
  backbuttonOnclick?: () => void;
  sx?: React.CSSProperties;
}

const CommonList = ({
  title,
  children,
  headerButton,
  headerBackButton,
  tabButton,
  navigateURL,
  backbuttonOnclick,
}: CommonListProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const handleClickBackButton = () => {
    if (navigateURL) navigate(navigateURL);
    if (backbuttonOnclick) backbuttonOnclick();
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickBackButton();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  return (
    <Box sx={{ mt: 3, pb: 3, width: isSmallScreen ? "100%" : 1100 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
        {headerBackButton == true ? HeaderBackButton() : headerBackButton}
          <CardHeader
            title={title}
            titleTypographyProps={{ fontStyle: fontStyle.displayM }}
            sx={{ ml: -2, mb: 0 }}
          />
          {tabButton}
        </Box>
        {headerButton}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CommonList;
