import { Box } from "@mui/material";
import Toolbar from "../../../component/Toolbar";

interface DocumentPageRenderProps {
  toolbarTitle: string;
  documentText: string;
  scale?: number;
}

export default function DocumentPageRender({
  toolbarTitle,
  documentText,
  scale,
}: DocumentPageRenderProps) {
  const updatedDocumentText = documentText.replace(
    /margin-(top|left|right|bottom):\s*72.0pt;/g,
    "margin-top: 16pt; margin-bottom: 16pt; margin-left: 20pt; margin-right: 20pt;",
  );
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        onBack={() => {
          window.history.back();
        }}
        title={toolbarTitle}
      />
      {/*  TODO 배너 UI 추가 */}
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    height: "fit-content",*/}
      {/*    flexDirection: "column",*/}
      {/*    overflowY: "scroll",*/}
      {/*    px: "20px",*/}
      {/*    overflowX: "hidden",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <VerticalSpacer height={16} />*/}
      {/*  <DocumentHeader toolbarTitle={toolbarTitle} />*/}
      {/*</Box>*/}
      <Box
        sx={{
          transform: `scale(${scale})`,
          transformOrigin: "top left", // 왼쪽 상단 기준으로 축소
          display: "flex",
          boxSizing: "border-box",
          width: "100%", // 화면 너비에 맞추기 위한 조정
        }}
        dangerouslySetInnerHTML={{ __html: updatedDocumentText }}
      />
    </Box>
  );
}
