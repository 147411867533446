export function formatTimeMilliseconds(
  languageCode: string,
  timeMilliseconds: number,
): string {
  const date = new Date(timeMilliseconds);

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  // 포맷팅된 날짜 문자열 반환 (예: "2 Jan 2023 18:00")
  return date.toLocaleDateString(languageCode, options).replace(",", "");
}
