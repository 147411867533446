import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MailIcon from "@mui/icons-material/Mail";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  Divider,
  Drawer,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useAuthRepository } from "../../../../di/AuthRepositoryProvider";
import { ICON_LOGO_WHITE, ICON_ARROW } from "../../../../constants/imagePath";
import { color } from "../../../../theme/Color";
import { logout } from "../../../../store/auth";

const settings = [/*"Profile", "Account", "Dashboard",*/ "Logout"];

const notiData = [
  {
    id: "aasdccxaqsd1sd12",
    date: "2024-05-03 09:18:00",
    title: "사고로 인한 스쿠터 고장",
    receiver: "Very Kim",
    detail:
      "2024년 5월 3일 사고로 인해 스쿠터의 일부분 작동이 잘 되지 않습니다. 확인 부탁드립니다.",
    check: false,
  },
  {
    id: "aasdccxaqsd1sd13",
    date: "2024-04-28 12:18:00",
    title: "스쿠터 정기 정검",
    receiver: "Very Kim",
    detail: "2024년 4월 28일 스쿠터 정기 정검 있습니다. 확인 부탁드립니다.",
    check: false,
  },
  {
    id: "aasdccxaqsd1sd14",
    date: "2024-04-21 19:26:00",
    title: "사고로 인한 스쿠터 고장",
    receiver: "Very Kim",
    detail:
      "2024년 4월 21일 사고로 인해 스쿠터의 일부분 작동이 잘 되지 않습니다. 확인 부탁드립니다.",
    check: false,
  },
  {
    id: "aasdccxaqsd1sd15",
    date: "2024-04-16 16:34:00",
    title: "사고로 인한 스쿠터 고장",
    receiver: "Very Kim",
    detail:
      "2024년 4월 16일 사고로 인해 스쿠터의 일부분 작동이 잘 되지 않습니다. 확인 부탁드립니다.",
    check: true,
  },
  {
    id: "aasdccxaqsd1sd16",
    date: "2024-04-13 13:58:00",
    title: "스쿠터 부품 교체",
    receiver: "Very Kim",
    detail:
      "2024년 4월 13일 부품 노후로 인해 스쿠터의 부품 교체 요청이 왔습니다. 확인 부탁드립니다.",
    check: false,
  },
  {
    id: "aasdccxaqsd1sd17",
    date: "2024-04-09 18:34:00",
    title: "사고로 인한 스쿠터 고장",
    receiver: "Very Kim",
    detail:
      "2024년 4월 9일 사고로 인해 스쿠터의 일부분 작동이 잘 되지 않습니다. 확인 부탁드립니다.",
    check: true,
  },
];

export default function Header() {
  const [state, setState] = useState(false);
  const [value, setValue] = useState("one");
  const navigate = useNavigate();

  const authRepository = useAuthRepository();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const onLogout = () => {
    localStorage.setItem("isLoggedIn", "false");
    dispatch(logout());
  };

  const userName = localStorage.getItem("userName");
  const userRoleName = localStorage.getItem("userRoleName");

  const lastPath = location.pathname;

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (setting: string) => {
    switch (setting) {
      /*
      case "Profile":
        break;
      case "Account":
        break;
      case "Dashboard":
        break;
      //*/
      case "Logout":
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user_uuid");
        localStorage.removeItem("user_type");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_email");
        localStorage.removeItem("user_phone_number");
        localStorage.removeItem("collapseTitle");
        localStorage.removeItem("itemTitle");
        authRepository.logout();
        window.location.href = "/login";
        break;
    }
    handleCloseUserMenu(); // 메뉴 닫기
  };

  const toggleDrawer = (open: boolean) => {
    // ( open: boolean) =>
    // (event: React.KeyboardEvent | React.MouseEvent) => {
    //   if (
    //     event.type === 'keydown' &&
    //     ((event as React.KeyboardEvent).key === 'Tab' ||
    //       (event as React.KeyboardEvent).key === 'Shift')
    //   ) {
    //     return;
    //   }
    setState(open);
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: color.navBg,
        height: 70,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{
            width: 222,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            href={lastPath}
          >
            <img src={ICON_LOGO_WHITE}/>
            <div
              style={{
                width: 50,
                height: 20,
                backgroundColor: "#E9E9EA",
                borderRadius: 10,
                marginTop: 15,
                marginLeft: -10,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <span
                style={{
                  color: color.navLogoText,
                  fontSize: 11,
                  marginTop: 2,
                }}
              >
                {/*localStorage.getItem("adminType")*/ true ? "Admin" : "World"}
              </span>
            </div>
          </a>
        </Box>

        <Drawer anchor="right" open={state} onClose={() => toggleDrawer(false)}>
          <Box sx={{ width: 450 }}>
            <Toolbar />
            <Box sx={{ m: 2, mt: 3 }}>
              <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                알림
              </Typography>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#000000",
                  },
                }}
                sx={{ mt: 1 }}
              >
                <Tab
                  value="one"
                  label={
                    <Typography
                      sx={{
                        fontSize: "0.9rem", // 폰트 크기 조정
                        fontWeight: "bold",
                      }}
                    >
                      미확인
                    </Typography>
                  }
                  sx={{
                    minWidth: 0,
                    minHeight: 0,
                    height: "35px",
                    pl: 0.7,
                    pr: 0.7,
                    mt: 2.7,
                  }} // 최소 너비 설정
                />
                <Tab
                  value="two"
                  label={
                    <Typography
                      sx={{
                        fontSize: "0.9rem", // 폰트 크기 조정
                        fontWeight: "bold",
                      }}
                    >
                      전체
                    </Typography>
                  }
                  sx={{
                    minWidth: 0,
                    minHeight: 0,
                    height: "35px",
                    pl: 1.5,
                    pr: 1.5,
                    mt: 2.7,
                    ml: 2.5,
                  }} // 최소 너비 설정
                />
              </Tabs>
              <TextField
                id="margin-none"
                variant="standard"
                placeholder="Search"
                size="medium"
                sx={{
                  width: "100%",
                  mt: 2,

                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#B2BAC2",
                  },
                  "& .MuiInputBase-input": {
                    paddingTop: "14px",
                    paddingBottom: "14px",
                  },
                }}
                InputProps={{
                  style: {
                    borderColor: "black",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {value == "one" && (
              <List>
                {notiData.map((notiData, index) => (
                  <ListItem key={index} disablePadding>
                    {notiData.check == false ? (
                      <ListItemButton
                        sx={
                          index == 0
                            ? {
                                borderTop: 1,
                                borderBottom: 1,
                                borderColor: "#E9E9EA",
                                backgroundColor: "#EEF3FF",
                              }
                            : {
                                borderBottom: 1,
                                borderColor: "#E9E9EA",
                                backgroundColor: "#EEF3FF",
                              }
                        }
                      >
                        <Box sx={{ height: 110 }}>
                          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                            {notiData.receiver}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }}>
                            제목: {notiData.title}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }}>
                            내용:{" "}
                            {notiData.detail.length > 20
                              ? `${notiData.detail.substring(0, 35)}...`
                              : notiData.detail}
                          </Typography>
                        </Box>
                      </ListItemButton>
                    ) : null}
                  </ListItem>
                ))}
              </List>
            )}
            {value == "two" && (
              <List>
                {notiData.map((notiData, index) => (
                  <ListItem key={index} disablePadding>
                    {notiData.check == false ? (
                      <ListItemButton
                        sx={
                          index == 0
                            ? {
                                borderTop: 1,
                                borderBottom: 1,
                                borderColor: "#E9E9EA",
                                backgroundColor: "#EEF3FF",
                              }
                            : {
                                borderBottom: 1,
                                borderColor: "#E9E9EA",
                                backgroundColor: "#EEF3FF",
                              }
                        }
                      >
                        <Box sx={{ height: 110 }}>
                          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                            {notiData.receiver}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }}>
                            제목: {notiData.title}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }}>
                            내용:{" "}
                            {notiData.detail.length > 20
                              ? `${notiData.detail.substring(0, 35)}...`
                              : notiData.detail}
                          </Typography>
                        </Box>
                      </ListItemButton>
                    ) : (
                      <ListItemButton
                        sx={
                          index == 0
                            ? {
                                borderTop: 1,
                                borderBottom: 1,
                                borderColor: "#E9E9EA",
                              }
                            : {
                                borderBottom: 1,
                                borderColor: "#E9E9EA",
                              }
                        }
                      >
                        <Box sx={{ height: 110 }}>
                          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                            {notiData.receiver}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }}>
                            제목: {notiData.title}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }}>
                            내용:{" "}
                            {notiData.detail.length > 20
                              ? `${notiData.detail.substring(0, 35)}...`
                              : notiData.detail}
                          </Typography>
                        </Box>
                      </ListItemButton>
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Drawer>
        <Box>
          <IconButton
            disabled={true}
            sx={{
              p: 0,
              backgroundColor: "#8B898F",
              borderRadius: 2,
              color: "white",
              display: "none",
              ":hover": {
                backgroundColor: "#BDBDBD",
              },
              mr: 2.5,
              width: 45,
              height: 45,
            }}
            onClick={() => toggleDrawer(!state)}
          >
            <NotificationsOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{
              borderRadius: 2,
              backgroundColor: color.navBtnUserInfo,
              textAlign: "center",
              ":hover": {
                backgroundColor: color.toolingPurple500Hover,
              },
              p: 0,
              width: "140px",
              height: "45px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: "10px",
                width: "100%",
              }}
            >
              <Typography sx={{ color: "white", fontWeight: "700" }}>
                {localStorage.getItem("user_name")}
              </Typography>
              <Typography sx={{ fontSize: 11, color: "#E9E9EA" }}>
                {localStorage.getItem("user_name")}
              </Typography>
            </Box>
            <img src={ICON_ARROW}
              style={{
                padding: "10px",
              }}
            />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => handleMenuItemClick(setting)}
              >
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
