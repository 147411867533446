import { useEffect, useState } from "react";
import CommonSearch from "../../../component/CommonSearch";
import { Box } from "@mui/material";
import { CommonSelect } from "../../../component/CommonSelect";
import VerticalSpacer from "../../../component/Spacer";
import {
  HistoryFilter,
  HistoryUiState,
} from "../../productionmanagement/productionhistory/model/ProductionHistoryUiState";
import { CheckboxState } from "../../../component/CommonCheckbox";
import { RequestHistoryFilter } from "./RequestHistoryFilter";
import { RequestHistoryTable } from "./RequestHistoryTable";
import { HeadCell } from "../../../component/CommonTable";
import {
  RequestHistoryData,
  RequestHistoryList,
} from "./model/RequestHistoryData";

const HistoryFilters: HistoryFilter[] = [
  new HistoryFilter("Request", CheckboxState.Checked),
  new HistoryFilter("Branch", CheckboxState.Checked),
  new HistoryFilter("Requester", CheckboxState.Checked),
  new HistoryFilter("Request Comment", CheckboxState.Checked),
  new HistoryFilter("Receive No", CheckboxState.Checked),
  new HistoryFilter("Receive Date", CheckboxState.Checked),
  new HistoryFilter("Desired Pickup Date", CheckboxState.Checked),
  new HistoryFilter("Approval", CheckboxState.Checked),
  new HistoryFilter("Deliver No", CheckboxState.Checked),
  new HistoryFilter("Checked Date", CheckboxState.Checked),
  new HistoryFilter("Response Date", CheckboxState.Checked),
  new HistoryFilter("Response Comment", CheckboxState.Checked),
  new HistoryFilter("Register", CheckboxState.Checked),
  new HistoryFilter("Updater", CheckboxState.Checked),
  new HistoryFilter("Register Date", CheckboxState.Checked),
  new HistoryFilter("Last Update Date", CheckboxState.Checked),
];

export function RequestHistory() {
  const [uiState, setUiState] = useState(
    new HistoryUiState(
      HistoryFilters,
      new HistoryFilter("All", CheckboxState.Checked)
    )
  );
  const [searchContents, setSearchContents] = useState("");
  const [status, setStatus] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<RequestHistoryData[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const filteredData = RequestHistoryList.filter((item) => {
      return searchContents
        ? item.requestBranch
            .toLowerCase()
            .includes(searchContents.toLowerCase()) ||
            item.branch.toLowerCase().includes(searchContents.toLowerCase())
        : true;
    });
    const startData = page * rowsPerPage;
    const endData = startData + rowsPerPage;
    const pagedata = filteredData.slice(startData, endData);
    setRows(pagedata);
    setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
    setDocCount(filteredData.length);
  }, [page, rowsPerPage, searchContents]);

  function getAllCheckboxState(filters: HistoryFilter[]): CheckboxState {
    const allChecked = filters.every(
      (filter) => filter.checkState === CheckboxState.Checked
    );
    const noneChecked = filters.every(
      (filter) => filter.checkState === CheckboxState.Unchecked
    );

    const allCheckboxState = allChecked
      ? CheckboxState.Checked
      : noneChecked
      ? CheckboxState.Unchecked
      : CheckboxState.Indeterminate;

    return allCheckboxState;
  }

  return (
    <>
      <CommonSearch
        placeHolder={"search"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              height: "42px",
              width: "93px",
            }}
            menuitems={[
              "All",
              "Request",
              "Checked",
              "Approval",
              "Reject",
              "Done",
            ]} // 디자인나오면 수정
            selectValue={status}
            onChange={(event) => {
              setStatus(event.target.value);
            }}
            placeholder="Status"
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <RequestHistoryFilter
        filters={uiState.filters}
        allCheckboxState={uiState.allCheckboxState.checkState}
        onClickAllCheckbox={() => {
          setUiState((prev) => {
            if (prev.allCheckboxState.checkState === CheckboxState.Unchecked) {
              const newFilters = prev.filters.map((it) => {
                return new HistoryFilter(it.title, CheckboxState.Checked);
              });

              return {
                ...prev,
                filters: newFilters,
                allCheckboxState: new HistoryFilter(
                  prev.allCheckboxState.title,
                  CheckboxState.Checked
                ),
              };
            } else {
              const newFilters = prev.filters.map((it) => {
                return new HistoryFilter(it.title, CheckboxState.Unchecked);
              });

              return {
                ...prev,
                filters: newFilters,
                allCheckboxState: new HistoryFilter(
                  prev.allCheckboxState.title,
                  CheckboxState.Unchecked
                ),
              };
            }
          });
        }}
        onClickCheckbox={(filter: HistoryFilter) => {
          setUiState((prev) => {
            const updatedFilters = prev.filters.map((f) => {
              // 클릭한 filter와 동일한 title을 가진 filter를 찾아 체크 상태 변경
              if (f === filter) {
                return new HistoryFilter(
                  f.title,
                  f.checkState === CheckboxState.Checked
                    ? CheckboxState.Unchecked
                    : CheckboxState.Checked
                );
              }
              return f; // 다른 filter는 그대로 유지
            });

            // 상태 업데이트

            return {
              ...prev,
              allCheckboxState: new HistoryFilter(
                prev.allCheckboxState.title,
                getAllCheckboxState(updatedFilters)
              ),
              filters: updatedFilters, // 업데이트된 filters 배열로 상태 변경
            };
          });
        }}
      />
      <VerticalSpacer height={16} />
      <RequestHistoryTable
        headCells={RequestHistoryHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      ></RequestHistoryTable>
    </>
  );
}

const RequestHistoryHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "requestBranch",
    label: "Request Branch",
  },
  {
    disablePadding: false,
    align: "left",
    id: "branch",
    label: "Branch",
  },
  {
    disablePadding: false,
    align: "left",
    id: "requester",
    label: "Requester",
  },
  {
    disablePadding: false,
    align: "left",
    id: "updater",
    label: "Updater",
  },
  {
    disablePadding: false,
    align: "left",
    id: "status",
    label: "Status",
  },
  {
    disablePadding: false,
    align: "left",
    id: "desiredPickupDate",
    label: "Desired Pickup Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registerDate",
    label: "Register Date",
  },
];
