export class PopupManagementUiState {
  countryList: { label: string; value: string }[] = [];
  image: File | string | undefined = undefined;
  name: string = "";
  country: string = "";
  status: string = "";
  startDate: number = 0;
  startDay: number = 0;
  startTime: number = 0;
  startTimes: number = 0;
  endDate: number = 0;
  endDay: number = 0;
  endTime: number = 0;
  endTimes: number = 0;
  url: string = "";
  id: string = "";
  createDialogOpen: boolean = false;
  cancelDialogOpen: boolean = false;
  createCard: boolean = false;
  editCard: boolean = false;
  editCardId: string = "";
  updateDialogOpen: boolean = false;
  updateCancel: boolean = false;
  deleteDialogOpen: boolean = false;
  editData: {
    [x: string]: string | number | [];
    country: [];
    endDate: number;
    id: string;
    image: string;
    link: string;
    startDate: number;
    state: string;
    title: string;
  };
  data: {
    [x: string]: string | number | [];
    country: [];
    endDate: number;
    id: string;
    image: string;
    link: string;
    startDate: number;
    state: string;
    title: string;
  };
  inValidCheck: {
    name: boolean;
    country: boolean;
    status: boolean;
    startDate: boolean;
    startDay:boolean;
    endDate: boolean;
    endDay: boolean;
    endTime: boolean;
    url: boolean;
    image: boolean;
    startTime:boolean;
  };
  constructor(
    editData: {
      country: [];
      endDate: number;
      id: string;
      image: string;
      link: string;
      startDate: number;
      state: string;
      title: string;
    } = {
      country: [],
      endDate: 0,
      id: "",
      image: "",
      link: "",
      startDate: 0,
      state: "",
      title: "",
    },
    data: {
      country: [];
      endDate: number;
      id: string;
      image: string;
      link: string;
      startDate: number;
      state: string;
      title: string;
    } = {
      country: [],
      endDate: 0,
      id: "",
      image: "",
      link: "",
      startDate: 0,
      state: "",
      title: "",
    },
    inValidCheck: {
      name: boolean;
      country: boolean;
      status: boolean;
      startDate: boolean;
      startTime:boolean;
      startDay:boolean;
      endDate: boolean;
      endDay: boolean;
      endTime: boolean;
      url: boolean;
      image: boolean;
    } = {
      name: false,
      country: false,
      status: false,
      startDate: false,
      startTime: false,
      startDay: false,
      endDate: false,
      endDay: false,
      endTime: false,
      url: false,
      image: false,
    },
    countryList: { label: string; value: string }[] = [],
    image?: File | string | undefined,
    name: string = "",
    country: string = "",
    status: string = "",
    startDate: number = 0,
    startDay: number = 0,
    startTime: number = 0,
    startTimes: number = 0,
    endDate: number = 0,
    endDay: number = 0,
    endTime: number = 0,
    endTimes: number = 0,
    url: string = "",
    id: string = "",
    createDialogOpen: boolean = false,
    cancelDialogOpen: boolean = false,
    createCard: boolean = false,
    editCard: boolean = false,
    editCardId: string = "",
    updateDialogOpen: boolean = false,
    updateCancel: boolean = false,
    deleteDialogOpen: boolean = false
  ) {
    this.editData = editData;
    this.data = data;
    this.countryList = countryList;
    this.image = image;
    this.name = name;
    this.country = country;
    this.status = status;
    this.startDate = startDate;
    this.startDay = startDay;
    this.startTime = startTime;
    this.startTimes = startTimes;
    this.endDate = endDate;
    this.endDay = endDay;
    this.endTime = endTime;
    this.endTimes = endTimes;
    this.url = url;
    this.id = id;
    this.createDialogOpen = createDialogOpen;
    this.cancelDialogOpen = cancelDialogOpen;
    this.createCard = createCard;
    this.editCard = editCard;
    this.editCardId = editCardId;
    this.updateDialogOpen = updateDialogOpen;
    this.updateCancel = updateCancel;
    this.deleteDialogOpen = deleteDialogOpen;
    this.inValidCheck = inValidCheck;
  }
}
