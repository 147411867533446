import { Box, Icon, IconButton, Typography } from "@mui/material";
import UsageHistoryItem, { UsageType } from "../../model/UsageHistoryItem";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Text } from "../../../../component/Text";

interface UsageHistoryCellProps {
  history: UsageHistoryItem;
  isExpanded: boolean;
  onToggleExpand: (id: number) => void;
}

export default function UsageHistoryCell({
  history,
  isExpanded,
  onToggleExpand,
}: UsageHistoryCellProps) {
  const isDailyMileage = (history.details?.length ?? 0) > 0;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: "8px",
        py: "16px",
        borderBottom: `1px solid ${color.gray200}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography fontStyle={fontStyle.bodyLr} color={color.default}>
              {history.title}
            </Typography>
            {isDailyMileage && (
              <IconButton
                onClick={() => onToggleExpand(history.id)}
                size="small"
              >
                {isExpanded ? (
                  <ExpandLess
                    sx={{ color: color.gray500, fontSize: "1.33rem" }}
                  />
                ) : (
                  <ExpandMore
                    sx={{ color: color.gray500, fontSize: "1.33rem" }}
                  />
                )}
              </IconButton>
            )}
          </Box>

          {isDailyMileage && isExpanded && (
            <Box
              sx={{
                ml: "2px",
                my: "4px",
              }}
            >
              {history.details?.map((detail, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "4px",
                    pl: "8px",
                    borderLeft: `2px solid ${color.primary200}`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignSelf: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <Text
                      sx={{
                        fontStyle: fontStyle.subTitleM,
                        color: color.primary500,
                      }}
                      text={`${detail.mileage}m`}
                    />
                    <Text
                      sx={{
                        fontStyle: fontStyle.bodyMr,
                        color: color.gray600,
                        flexGrow: 1,
                      }}
                      text={`${detail.startTime} - ${detail.endTime}`}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
            {history.date}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Text
            sx={{
              fontStyle: fontStyle.titleL,
              color:
                history.usageType === UsageType.CHARGE
                  ? color.secondary500
                  : color.primary500,
            }}
            text={
              (history.usageType === UsageType.CHARGE ? "+ " : "- ") +
              history.mileage +
              " m"
            }
          />
          <Text
            sx={{
              fontStyle: fontStyle.bodyMr,
              color: color.gray600,
            }}
            text={
              (history.usageType === UsageType.CHARGE ? "Use " : "Charge ") +
              history.power +
              " kWh"
            }
          />
        </Box>
      </Box>
    </Box>
  );
}
