import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CommonSwitch from "../../../component/CommonSwitch";

interface PushNotiSetHisDetailsProps {
  setHisModal?: any;
  handleClose?: any;
}

const PushNotiSetHisDetails = ({
  setHisModal,
  handleClose,
}: PushNotiSetHisDetailsProps) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={setHisModal}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px" } }}
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>Create</DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -2 }}>
          <Grid item lg={6}>
            <Box sx={{ mr: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 50,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Push Name</Typography>
                <TextField
                  size="small"
                  sx={{ width: "80%" }}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 50,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Title</Typography>
                <TextField
                  size="small"
                  sx={{ width: "80%" }}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold", mt: 5 }}>
                Content
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  multiline
                  rows={20}
                  sx={{ width: "100%" }}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: 50,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Schedule</Typography>
              <CommonSwitch sx={{ ml: 3 }} />
            </Box>
            <Box sx={{ mt: 2.5 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Execution Date
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 4,
                }}
              >
                <Box sx={{ width: "32%" }}>
                  <Typography sx={{ color: "#99989D", fontSize: 12 }}>
                    Year
                  </Typography>
                  <Select
                    value={"Select"}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                  </Select>
                </Box>
                <Box sx={{ width: "32%" }}>
                  <Typography sx={{ color: "#99989D", fontSize: 12 }}>
                    Month
                  </Typography>
                  <Select
                    value={"Select"}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                  </Select>
                </Box>
                <Box sx={{ width: "32%" }}>
                  <Typography sx={{ color: "#99989D", fontSize: 12 }}>
                    Date
                  </Typography>
                  <Select
                    value={"Select"}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 4,
                }}
              >
                <Box sx={{ width: "32%" }}>
                  <Typography sx={{ color: "#99989D", fontSize: 12 }}>
                    Hour
                  </Typography>
                  <Select
                    value={"Select"}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                  </Select>
                </Box>
                <Box sx={{ width: "32%" }}>
                  <Typography sx={{ color: "#99989D", fontSize: 12 }}>
                    Minute
                  </Typography>
                  <Select
                    value={"Select"}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                  </Select>
                </Box>
                <Box sx={{ width: "32%" }}>
                  <Typography sx={{ color: "#99989D", fontSize: 12 }}>
                    Second
                  </Typography>
                  <Select
                    value={"Select"}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 7 }}>
              <Typography sx={{ fontWeight: "bold" }}>List for send</Typography>
              <Box sx={{ width: "100%", mt: 3 }}>
                <Typography sx={{ color: "#99989D", fontSize: 12 }}>
                  Add
                </Typography>
                <Select
                  value={"Select"}
                  size="small"
                  sx={{ width: "100%", mt: 1 }}
                  style={{ borderRadius: "12px" }}
                >
                  <MenuItem value={"Select"}>Select</MenuItem>
                </Select>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
          mb: 2,
        }}
      >
        <Button>aaa</Button>
        <Button>bbb</Button>
      </Box>
    </Dialog>
  );
};

export default PushNotiSetHisDetails;
