import { Box } from "@mui/material";
import { Text } from "../../component/Text";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { HorizontalSpacer } from "../../component/Spacer";
import { ICON_RIGHT_ARROW } from "../../constants/appImagePath";

interface SettingButtonProps {
  title: string;
  onClick: () => void;
}

export default function SettingButton({ title, onClick }: SettingButtonProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "41px",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Text
          sx={{
            fontStyle: fontStyle.subTitleL,
            color: color.default,
            flexGrow: 1,
          }}
          text={title}
        />
        <HorizontalSpacer width={20} />
        <img
          src={ICON_RIGHT_ARROW}
          alt="arrow_right"
          style={{ width: "24px", height: "24px" }}
        />
      </Box>
    </Box>
  );
}
