import { Box, Typography } from "@mui/material";
import CommonDialog from "../../../component/CommonDialog";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

interface AnnounceDialogProps {
  createDialogOpen: boolean;
  handleClose: () => void;
  handleOk: () => void;
}

export function AnnounceDialog({
  createDialogOpen,
  handleClose,
  handleOk,
}: AnnounceDialogProps) {
  return (
    <CommonDialog
      open={createDialogOpen}
      handleClose={handleClose}
      handleOk={handleOk}
      textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
    >
      Would you like to register the notice?
    </CommonDialog>
  );
}
