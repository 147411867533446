import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { HorizontalSpacer } from "../../../component/Spacer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { color } from "../../../theme/Color";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { NfcDetails } from "./NfcDetails";

interface RowProps {
  row: any;
  index: any;
  docCount: number;
}

const Row = ({ row, index, docCount }: RowProps) => {
  const navigate = useNavigate();

  return (
    <>
      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={() => {
        
          navigate(`/NfcDetails/${row.id}`);
        }}
      >
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {docCount ? docCount - row.no + 1 : ""}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.nfcCardId}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.type}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.valid}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.owner}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.register}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.lastUpdateDate === 0 ? (
              ""
            ) : (
              <>
                {LocalizedDate({
                  date: row.lastUpdateDate,
                  type: DateFormatType.numericTime,
                })}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.userRegistrationDate === 0
              ? ""
              : LocalizedDate({
                  date: row.userRegistrationDate,
                  type: DateFormatType.numericTime,
                })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface NfcMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount: number;
}

const NfcMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: NfcMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },

    {
      id: "id",
      align: "left",
      disablePadding: false,
      label: "ID",
    },

    {
      id: "type",
      align: "left",
      disablePadding: false,
      label: "Type",
    },

    {
      id: "valid",
      align: "left",
      disablePadding: false,
      label: "Valid",
    },

    {
      id: "owner",
      align: "left",
      disablePadding: false,
      label: "Owner",
    },
    {
      id: "register",
      align: "left",
      disablePadding: false,
      label: "Register",
    },
    {
      id: "lastUpdateDate",
      align: "left",
      disablePadding: false,
      label: "Last Update Date",
    },
    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Registration Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows || [], rowsPerPage, setPage, page);

  return (
    <>
      <CommonTable
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        page={page}
        docCount={docCount}
        pageCount={pageCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        setPage={setPage}
      >
        {visibleRows &&
          visibleRows.map((row: any, index: any) => (
            <Row row={row} index={index} key={index} docCount={docCount} />
          ))}
      </CommonTable>
    </>
  );
};

export default NfcMngTable;
