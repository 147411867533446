import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../restAPI/ApiService";
import { TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { VehicleDataType, VehicleUiState } from "../model/VehicleUiState";
interface VehicleMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
  uiState: VehicleUiState;
}

interface RowProps {
  row: VehicleDataType;
  index: number;
  page: number;
  rowsPerPage: number;
}
const Row = ({ row, page, index, rowsPerPage }: RowProps) => {
  return (
    <>
      <TableRow>
        <TableCell align="center" sx={{ height: "52px", py: 0, px: "12px" }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {page * rowsPerPage + index + 1}{" "}
          </Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0, px: "12px" }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.serialNumber}
          </Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0, px: "12px" }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.productName}
          </Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0, px: "12px" }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.plateNumber}
          </Typography>
        </TableCell>

        <TableCell
          align="center"
          sx={{ height: "52px", maxWidth: "64px", py: 0, px: "12px" }}
        >
          <Typography
            fontStyle={fontStyle.regularXS}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {row.owner}
          </Typography>
        </TableCell>

        <TableCell
          align="center"
          sx={{
            maxWidth: "64px",
            width: "64px",
            height: "52px",
            py: 0,
            px: "12px",
          }}
        >
          <Typography
            fontStyle={fontStyle.regularXS}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {row.renter}
          </Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0, px: "12px" }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.updater}</Typography>
        </TableCell>

        <TableCell
          align="left"
          sx={{ height: "52px", maxWidth: "64px", py: 0, px: "12px" }}
        >
          <Typography
            fontStyle={fontStyle.regularXS}
            sx={{
              textOverflow: "ellipsis",
              maxWidth: "64px",
              overflow: "hidden",
            }}
          >
            {row.location.lat} <br />
            {row.location.lon}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0, px: "12px" }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.lastUpdateDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0, px: "12px" }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registerDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const VehicleMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
  uiState,
}: VehicleMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },
    {
      id: "scooterSN",
      align: "left",
      disablePadding: false,
      label: "Scooter S/N",
    },

    {
      id: "productName",
      align: "left",
      disablePadding: false,
      label: "Product Name",
    },
    {
      id: "plateNumber",
      align: "left",
      disablePadding: false,
      label: "Plate Number",
    },

    {
      id: "owner",
      align: "left",
      disablePadding: false,
      label: "Owner",
    },

    {
      id: "renter",
      align: "left",
      disablePadding: false,
      label: "Renter",
    },
    {
      id: "updater",
      align: "left",
      disablePadding: false,
      label: "Updater",
    },
    {
      id: "location",
      align: "left",
      disablePadding: false,
      label: "Location",
    },
    {
      id: "lastUpdateDate",
      align: "left",
      disablePadding: false,
      label: "Last Update Date",
    },
    {
      id: "registerDate",
      align: "left",
      disablePadding: false,
      label: "Register Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {Array.isArray(uiState.data) &&
        uiState.data?.map((row: VehicleDataType, index: any) => (
          <Row
            row={row}
            index={index}
            key={index}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        ))}
    </CommonTable>
  );
};

export default VehicleMngTable;
