import { Box, Typography } from "@mui/material";
import { HorizontalSpacer } from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

interface BenefitCardProps {
  icon: string;
  description: string;
}

const BenefitCard = ({
  icon,
  description,
}: BenefitCardProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <img
        src={icon}
        alt="Benefit Icon"
        style={{
          width: "48px",
          height: "48px",
          padding: "14px",
        }}
      />

      <HorizontalSpacer width={20} />
      <Typography
        fontStyle={fontStyle.subTitleM}
        color={color.default}
        sx={{
          whiteSpace: "pre-line",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default BenefitCard;
