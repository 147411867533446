export class ProductionHistoryData {
    no: number;
    category: string;
    partsCode: string;
    productName: string;
    spec: string;
    made: string;
    quantities: string;
    amount: number;
    updater: string;
    registrationDate: number;
  
    constructor(
      no: number,
      category: string,
      partsCode: string,
      productName: string,
      spec: string,
      made: string,
      quantities: string,
      amount: number,
      updater: string,
      registrationDate: number
    ) {
      this.no = no;
      this.category = category;
      this.partsCode = partsCode;
      this.productName = productName;
      this.spec = spec;
      this.made = made;
      this.quantities = quantities;
      this.amount = amount;
      this.updater = updater;
      this.registrationDate = registrationDate;
    }
  }
  
  export const ProductionHistoryList: ProductionHistoryData[] = [
    new ProductionHistoryData(
      1,
      "ASSY",
      "VBP-220RGP",
      "BATTERY PACK",
      "ASSY BATTERY SET (3600000",
      "Gwang-Ju",
      "SET",
      20,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      2,
      "ASSY",
      "VBP-220RGP",
      "BATTERY PACK",
      "ASSY BATTERY SET (3600000",
      "Gwang-Ju",
      "SET",
      20,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      3,
      "ASSY",
      "VBP-220RGP",
      "BATTERY PACK",
      "ASSY BATTERY SET (3600000",
      "Manufacture",
      "SET",
      20,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      4,
      "SEMI ASSY",
      "VW90-00001A",
      "ASSY CELL PACK",
      "",
      "Manufacture",
      "SET",
      30,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      5,
      "SEMI ASSY",
      "VW90-00001A",
      "ASSY CELL PACK",
      "",
      "Manufacture",
      "PCS",
      30,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      6,
      "SEMI ASSY",
      "VW90-00001A",
      "ASSY CELL PACK",
      "",
      "Manufacture",
      "SET",
      30,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      7,
      "SEMI ASSY",
      "VW90-00001A",
      "ASSY CELL PACK",
      "",
      "Manufacture",
      "PCS",
      30,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      8,
      "SEMI ASSY",
      "VW90-00001A",
      "ASSY CELL PACK",
      "",
      "Manufacture",
      "PCS",
      30,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      9,
      "SEMI ASSY",
      "VW90-00001A",
      "ASSY CELL PACK",
      "ASSY BATTERY SET (3600000",
      "Manufacture",
      "PCS",
      30,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      10,
      "SEMI ASSY",
      "VW90-00001A",
      "ASSY CELL PACK",
      "",
      "Manufacture",
      "PCS",
      30,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      11,
      "FINISHED",
      "VBM-300RGP",
      "BATTERY MODULE",
      "ASSY BATTERY MODULE (4500000)",
      "Seoul",
      "MODULE",
      50,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      12,
      "FINISHED",
      "VBM-300RGP",
      "BATTERY MODULE",
      "ASSY BATTERY MODULE (4500000)",
      "Seoul",
      "MODULE",
      50,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      13,
      "ASSY",
      "VBP-220RGP",
      "BATTERY PACK",
      "ASSY BATTERY SET (3600000)",
      "Busan",
      "SET",
      20,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      14,
      "ASSY",
      "VBP-220RGP",
      "BATTERY PACK",
      "ASSY BATTERY SET (3600000)",
      "Busan",
      "SET",
      20,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      15,
      "PARTS",
      "VCP-150XLG",
      "CELL PACK",
      "CELL PACK ASSEMBLY (150000)",
      "Incheon",
      "PCS",
      100,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      16,
      "PARTS",
      "VCP-150XLG",
      "CELL PACK",
      "CELL PACK ASSEMBLY (150000)",
      "Incheon",
      "PCS",
      100,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      17,
      "FINISHED",
      "VBM-500RGP",
      "BATTERY MODULE",
      "ASSY BATTERY MODULE (5000000)",
      "Gwang-Ju",
      "MODULE",
      10,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      18,
      "FINISHED",
      "VBM-500RGP",
      "BATTERY MODULE",
      "ASSY BATTERY MODULE (5000000)",
      "Gwang-Ju",
      "MODULE",
      10,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      19,
      "SEMI ASSY",
      "VSP-300LSP",
      "CELL STACK",
      "",
      "Gwang-Ju",
      "STACK",
      25,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      20,
      "SEMI ASSY",
      "VSP-300LSP",
      "CELL STACK",
      "",
      "Gwang-Ju",
      "STACK",
      25,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      21,
      "ASSY",
      "VBP-220RGP",
      "BATTERY PACK",
      "ASSY BATTERY SET (3600000)",
      "Seoul",
      "SET",
      15,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      22,
      "ASSY",
      "VBP-220RGP",
      "BATTERY PACK",
      "ASSY BATTERY SET (3600000)",
      "Seoul",
      "SET",
      15,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      23,
      "FINISHED",
      "VBM-400RGP",
      "BATTERY MODULE",
      "ASSY BATTERY MODULE (4000000)",
      "Busan",
      "MODULE",
      40,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      24,
      "FINISHED",
      "VBM-400RGP",
      "BATTERY MODULE",
      "ASSY BATTERY MODULE (4000000)",
      "Busan",
      "MODULE",
      40,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      25,
      "SEMI ASSY",
      "VSP-200LSP",
      "CELL STACK",
      "",
      "Manufacture",
      "STACK",
      20,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      26,
      "SEMI ASSY",
      "VSP-200LSP",
      "CELL STACK",
      "",
      "Manufacture",
      "STACK",
      20,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      27,
      "PARTS",
      "VCP-180XLG",
      "CELL PACK",
      "CELL PACK ASSEMBLY (180000)",
      "Seoul",
      "PCS",
      120,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      28,
      "PARTS",
      "VCP-180XLG",
      "CELL PACK",
      "CELL PACK ASSEMBLY (180000)",
      "Seoul",
      "PCS",
      120,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      29,
      "FINISHED",
      "VBM-600RGP",
      "BATTERY MODULE",
      "ASSY BATTERY MODULE (6000000)",
      "Incheon",
      "MODULE",
      35,
      "HJI (hji@verywords.com)",
      1716499200
    ),
    new ProductionHistoryData(
      30,
      "FINISHED",
      "VBM-600RGP",
      "BATTERY MODULE",
      "ASSY BATTERY MODULE (6000000)",
      "Incheon",
      "MODULE",
      35,
      "HJI (hji@verywords.com)",
      1716499200
    ),
  ];
  