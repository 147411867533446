import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import EmergencyCard from "./section/EmergencyCard";
import SupportButton from "./section/SupportButton";
import SupportButtonHeader from "./section/SupportButtonHeader";
import SupportMenuButton from "./section/SupportMenuButton";
import { ICON_INQUIRY, ICON_SPANNER } from "../../constants/appImagePath";
import EmergencyDialog from "./section/EmergencyDialog";
import { SupportPageSideEffect } from "./SupportPageSideEffect";
import { SupportPageUiState } from "./SupportPageUiState";
import { SupportPageUiEvent } from "./SupportPageUiEvent";
import { color } from "../../theme/Color";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { Text } from "../../component/Text";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

interface SupportRenderProps {
  uiState: SupportPageUiState;
  uiEvent: SupportPageUiEvent;
  sideEffect: SupportPageSideEffect;
}

const SupportRender = ({
  uiState,
  uiEvent,
  sideEffect,
}: SupportRenderProps) => {
  let emergencyNumber: string = "+855 069 878 689";
  const faqEmptyText = LocalizedText("ua_f100_nofaq");
  const noticeEmptyText = LocalizedText("ua_f100_nonotice");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        overflowY: "scroll",
        alignItems: "center",
        scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
        "&::-webkit-scrollbar": {
          display: "none", // Webkit 브라우저에서    스크롤바 숨기기
        },
      }}
    >
      <Box
        sx={{
          width: "calc(100vw - 40px)",
          height: "100vh",
          backgroundColor: "white",
        }}
      >
        <VerticalSpacer height={29} />
        <Typography fontStyle={fontStyle.headingS}>
          {LocalizedText("ua_f100_cs")}
        </Typography>
        <VerticalSpacer height={8} />
        <Typography fontStyle={fontStyle.bodyLr} color={color.default}>
          {LocalizedText("ua_f100_needhelp")}
        </Typography>
        <VerticalSpacer height={12} />

        <EmergencyCard
          title={LocalizedText("ua_f100_roadside_title")}
          content={LocalizedText("ua_f100_roadside_message")}
          onClick={() => uiEvent.ShowEmergencyDialog()}
        />

        <VerticalSpacer height={16} />
        <Box
          sx={{
            display: "flex",
          }}
        >
          <SupportMenuButton
            iconPath={ICON_INQUIRY}
            title={LocalizedText("ua_f100_inquiry")}
            onClick={() => {
              sideEffect.navigate(ROUTE_PATHS.INQUIRY_PAGE);
            }}
          />
          <HorizontalSpacer width={16} />
          <SupportMenuButton
            iconPath={ICON_SPANNER}
            title={LocalizedText("ua_f100_csapply")}
            onClick={() => sideEffect.navigate(ROUTE_PATHS.CS_LIST)}
          />
        </Box>
        <VerticalSpacer height={30} />

        <SupportButtonHeader
          title={LocalizedText("ua_f100_faq")}
          subTitle={LocalizedText("ua_f100_more")}
          onClick={() => sideEffect.navigate(ROUTE_PATHS.FAQ_LIST)}
        />
        <VerticalSpacer height={20} />

        {uiState.faqTop4List && uiState.faqTop4List.length > 0 ? (
          uiState.faqTop4List.map((button, index) => (
            <Box sx={{ display: "flex", flexDirection: "column" }} key={index}>
              <SupportButton
                supportButtonModel={button}
                onClick={() => {
                  sideEffect.moveToFAQDetail(
                    button.id,
                    localStorage.getItem("uuid") ?? "",
                  );
                }}
              />
              <VerticalSpacer height={20} />
            </Box>
          ))
        ) : (
          <Box>
            <Text
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.bodyMr,
                color: color.gray600,
              }}
              text={faqEmptyText}
            />
          </Box>
        )}

        <VerticalSpacer height={10} />

        <SupportButtonHeader
          title={LocalizedText("ua_f100_notice")}
          subTitle={LocalizedText("ua_f100_more")}
          onClick={() => sideEffect.navigate(ROUTE_PATHS.NOTICE)}
        />
        <VerticalSpacer height={20} />

        {uiState.noticeList && uiState.noticeList.length > 0 ? (
          uiState.noticeList.map((button, index) => (
            <Box sx={{ display: "flex", flexDirection: "column" }} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "41px",
                }}
              >
                <Text
                  sx={{
                    fontStyle: fontStyle.bodyLr,
                    color: color.gray900,
                  }}
                  onClick={() => {
                    sideEffect.navigate(ROUTE_PATHS.NOTICE_DETAIL, {
                      state: { id: button.id },
                    });
                  }}
                  text={button.title}
                />
              </Box>
              <VerticalSpacer height={20} />
            </Box>
          ))
        ) : (
          <Box>
            <Text
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.bodyMr,
                color: color.gray600,
              }}
              text={noticeEmptyText}
            />
          </Box>
        )}
        <VerticalSpacer height={16} />
      </Box>

      <EmergencyDialog
        isOpen={uiState.isShowEmergencyDialog}
        title={LocalizedText("common_alert_roadside_subtitle")}
        subTitle={LocalizedText("common_alert_roadside_title")}
        content={LocalizedText("common_alert_roadside_message")}
        iconText={LocalizedText("common_alert_roadside_inquiry_button")}
        buttonText={emergencyNumber} /// TODO
        onClick={() => {
          sideEffect.callEmergency(emergencyNumber);
          uiEvent.HideEmergencyDialog();
        }}
        onClose={() => uiEvent.HideEmergencyDialog()}
      />
    </Box>
  );
};

export default SupportRender;
