import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface GrpCreateGridProps {
  formData: any;
  handleChange: any;
  // (
  //   e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  // ) => void;
  errorText: any;
}

const GrpCreateGrid = ({
  formData,
  handleChange,
  errorText,
}: GrpCreateGridProps) => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: 0 }}>
      {/* 첫번째 카드 */}
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ mt: 0 }}>
                <Typography>Group Name</Typography>
                {errorText.groupName == true && (
                  <TextField
                    name="groupName"
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.groupName}
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                )}
                {errorText.groupName == false && (
                  <TextField
                    error
                    helperText="Group Name 을 입력해 주세요."
                    name="groupName"
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.groupName}
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                )}
              </Box>

              <Box sx={errorText.groupName == true ? { mt: 3 } : { mt: 0 }}>
                <Typography>Description</Typography>
                {errorText.description == true && (
                  <TextField
                    name="description"
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.description}
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                )}
                {errorText.description == false && (
                  <TextField
                    error
                    helperText="Description 을 입력해 주세요."
                    name="description"
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.description}
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                )}
              </Box>

              <Box sx={errorText.description == true ? { mt: 3 } : { mt: 0 }}>
                <Typography>Permission</Typography>
                {errorText.permission == true && (
                  <Select
                    name="permission"
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    style={{ borderRadius: "7px" }}
                    value={formData.permission}
                    onChange={handleChange}
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                  </Select>
                )}
                {errorText.permission == false && (
                  <Box>
                    <Select
                      error
                      name="permission"
                      size="small"
                      sx={{ width: "100%", mt: 1 }}
                      style={{ borderRadius: "7px" }}
                      value={formData.permission}
                      onChange={handleChange}
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                    </Select>
                    <Typography
                      fontSize={12}
                      sx={{ ml: 1.75, mt: 0.5 }}
                      color={"#d32f2f"}
                    >
                      Permission 을 선택해 주세요.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Member"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="margin-none"
                variant="outlined"
                size="small"
                sx={{
                  width: "100%",
                  mt: 2,

                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#B2BAC2",
                  },
                  //   "& .MuiInputBase-input": {
                  //     paddingTop: "14px",
                  //     paddingBottom: "14px",
                  //   },
                }}
                InputProps={{
                  style: {
                    borderColor: "black",
                    borderRadius: "7px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
              <Select
                size="small"
                value={"none"}
                sx={{
                  width: "100%",
                  mt: 1,
                  borderRadius: "7px",
                  backgroundColor: "#E9E9EA",
                }}
                disabled
              >
                <MenuItem value="none">Select</MenuItem>
              </Select>
              <TableContainer component={Paper} elevation={0} sx={{ mt: 4 }}>
                <Table
                  sx={{ width: "100%" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width={100}>Email</TableCell>
                      <TableCell width={70} align="right">
                        Name
                      </TableCell>
                      <TableCell width={40} align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GrpCreateGrid;
