import { Card, CardContent, Grid, Typography } from "@mui/material";
import {
  CheckboxState,
  CommonCheckbox,
} from "../../../component/CommonCheckbox";
import VerticalSpacer from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { HistoryFilter } from "../../productionmanagement/productionhistory/model/ProductionHistoryUiState";

interface RequestHistoryFilterProps {
  filters: HistoryFilter[];
  allCheckboxState: CheckboxState;
  onClickAllCheckbox: (newState: CheckboxState) => void;
  onClickCheckbox: (filter: HistoryFilter, newState: CheckboxState) => void;
}

export function RequestHistoryFilter({
  filters,
  allCheckboxState,
  onClickAllCheckbox,
  onClickCheckbox,
}: RequestHistoryFilterProps) {
  return (
    <Grid item lg={12}>
      <Card
        sx={{
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          boxShadow: 0,
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Typography fontStyle={fontStyle.semiboldS}>Filter</Typography>
        </CardContent>
        <VerticalSpacer height={12} />
        <CommonCheckbox
          checkboxName={"All"}
          checkboxState={allCheckboxState}
          onClick={(newState) => {
            onClickAllCheckbox(newState);
          }}
        />
        <VerticalSpacer height={12} />
        <Grid container spacing={"12px"}>
          {filters?.map((it, i) => (
            <Grid item xs={3} key={i}>
              <CommonCheckbox
                checkboxName={it.title}
                checkboxState={it.checkState}
                onClick={(newState) => {
                  onClickCheckbox(it, newState);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Card>
    </Grid>
  );
}
