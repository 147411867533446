import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  SxProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";

interface CommonDialogProps {
  children?: ReactNode;
  open?: any;
  handleClose?: any;
  handleOk?: any;
  cancleButton?: boolean;
  textSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  okButtonSx?: SxProps<Theme>;
  autoFocus?: boolean;
}

const CommonDialog = ({
  children,
  open,
  handleClose,
  handleOk,
  cancleButton = true,
  textSx,
  sx,
  okButtonSx,
  autoFocus = true,
}: CommonDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        sx={{
          width: 450,
          height: 170,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          ...sx
        }}
      >
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontSize: 15,
            color: "black",
            ...textSx
          }}
        >
          {children}
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            mt: 2,
          }}
        >
          {cancleButton === true ? (
            <Button
              onClick={handleClose}
              sx={{
                textTransform: "none",
                height: 40,
                //   backgroundColor: "#5D38E5",
                color: "#5D38E5",
                borderRadius: 5,
                border: 1,
                borderColor: "#5D38E5",
                width: 95,
                ":hover": {
                  backgroundColor: "#E9E9E9",
                },
              }}
            >
              Cancel
            </Button>
          ) : (
            ""
          )}
          <Button
            onClick={handleOk}
            sx={{
              textTransform: "none",
              height: 40,
              backgroundColor: "#5D38E5",
              color: "white",
              borderRadius: 5,
              width: 75,
              ml: 2,
              ":hover": {
                backgroundColor: "#A38BFC",
              },
              ...okButtonSx
            }}
            autoFocus={autoFocus}
          >
            OK
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CommonDialog;
