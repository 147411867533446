export class LoginPageUiState {
  title: string;
  subTitle: string;
  googleLoginText: string;
  appleLoginText: string;
  facebookLoginText: string;

  constructor(
    title: string,
    subTitle: string,
    googleLoginText: string,
    appleLoginText: string,
    facebookLoginText: string,
  ) {
    this.title = title;
    this.subTitle = subTitle;
    this.googleLoginText = googleLoginText;
    this.appleLoginText = appleLoginText;
    this.facebookLoginText = facebookLoginText;
  }
}
