import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { color } from "../../../../theme/Color";
import { ReceiveDeliverDataType } from "../model/RecieveDeliverData";
import { useState } from "react";
import { CommonRadio } from "../../../../component/CommonRadio";
import { CommonSelect } from "../../../../component/CommonSelect";
import CommonTextField from "../../../../component/CommonTextField";

interface Field {
  value: ReceiveDeliverDataType;
  label: string;
}
export function MaterialRegistrationInspector() {
  const [type, setType] = useState<ReceiveDeliverDataType>(
    ReceiveDeliverDataType.RECIEVE
  );
  const [branch, setBranch] = useState("");
  const [time, setTime] = useState("");

  const radioTypes: Field[] = [
    { label: "Receive", value: ReceiveDeliverDataType.RECIEVE },
    { label: "Deliver", value: ReceiveDeliverDataType.DELIVER },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (
      Object.values(ReceiveDeliverDataType).includes(
        value as ReceiveDeliverDataType
      )
    ) {
      setType(value as ReceiveDeliverDataType);
      console.log("Selected Material Type:", value);
    }
  };

  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Inspector</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Type
          </Typography>
          <VerticalSpacer height={8} />
          <Box sx={{ display: "flex", height: "48px", alignItems: "center" }}>
            {radioTypes.map((radioType, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <CommonRadio<ReceiveDeliverDataType>
                  selectValue={type}
                  label={radioType.label}
                  value={radioType.value}
                  onchange={handleChange}
                  sx={{ width: "14.4px", height: "14.4px" }}
                />
                <HorizontalSpacer width={8} />
                <Typography fontStyle={fontStyle.regularS}>
                  {radioType.label}
                </Typography>
                <HorizontalSpacer width={28} />
              </Box>
            ))}
          </Box>
        </Box>
        <HorizontalSpacer width={28} />
        <Box sx={{ display: "flex", width: "50%" }}>
          <Box sx={{ width: "50%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Register Date
            </Typography>
          </Box>
          <HorizontalSpacer width={16} />
          <Box sx={{ width: "50%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Register Time
            </Typography>
            <VerticalSpacer height={8} />
            <Box sx={{ display: "flex" }}>
              <CommonSelect
                sx={{ minHeight: "48px", flexGrow: 1 }}
                selectValue={time}
                menuitems={["11:00", "12:00"]}
                onChange={(event) => {
                  setTime(event.target.value);
                }}
                placeholder="Select"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <VerticalSpacer height={40} />
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Branch
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            sx={{ minHeight: "48px", flexGrow: 1 }}
            selectValue={branch}
            menuitems={["das", "dasads"]}
            onChange={(event) => {
              setBranch(event.target.value);
            }}
            placeholder="Select"
          />
        </Box>
        <HorizontalSpacer width={28} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Branch
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            sx={{ minHeight: "48px", flexGrow: 1 }}
            selectValue={branch}
            menuitems={["das", "dasads"]}
            onChange={(event) => {
              setBranch(event.target.value);
            }}
            placeholder="Select"
          />
        </Box>
      </Box>
      <VerticalSpacer height={40} />
      <Box>
        <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
          Commnet
        </Typography>
        <VerticalSpacer height={8} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CommonTextField
            multiline
            InputProps={{ style: { padding:"0px" } }}
            inputProps={{ style: { height: "80px", padding: "12px 16px" } }}
          />
        </Box>
      </Box>
    </CardContent>
  );
}
