export default function VerticalSpacer({height} : {
    height: number
}) {
    return (<div style={{
            minHeight: `${height}px`
        }}/>);
}

export function HorizontalSpacer({width} : {
    width: number
}) {
    return (
        <div
            style={{
                minWidth: `${width}px`
            }}/>
    );
}