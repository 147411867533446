import { styled } from "@mui/material";
import Switch from "@mui/material/Switch";

const CommonSwitch = styled(Switch)(({ theme }) => ({
  width: 48, // 너비 조정
  height: 24, // 높이 조정
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 20, // 활성화 상태일 때 thumb 너비
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(24px)", // 활성화 상태일 때 이동 거리
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(24px)", // 활성화 상태일 때 thumb 이동 거리
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#5D38E5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20, // thumb 너비
    height: 20, // thumb 높이
    borderRadius: 10, // thumb 모서리 반경
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 12, // track 모서리 반경
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default CommonSwitch;
