import { Box, Card, Grid } from "@mui/material";
import CommonList from "../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";

import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import { color } from "../../../../../theme/Color";
import { ICON_TRASH } from "../../../../../constants/imagePath";
import { AdminType } from "../model/ProductData";
import { useState } from "react";
import { ProductionDetailsCardPossible } from "./ProductionDetailsCardPossible";
import { ProductionDetailsCardImpossible } from "./ProductionDetailsCardImpossible";
import { ProductionDetailsDateCardPossible } from "./ProductionDetailsDateCardPossible";
import { ProductionDetailsDateCardImpossible } from "./ProductionDetailsDateCardImpossible";

export function ProductionDetails() {
  // TODO 이미지 추가 필요
  const [adminType, setAdminType] = useState<AdminType>(AdminType.NOTMODIFIED); // TODO adminType 지정 필요
  return (
    <CommonList
      title="생산 상세"
      headerBackButton={true}
      navigateURL={`/productionManagement?tab=production`}
      headerButton={
        adminType === AdminType.MANAGER || adminType === AdminType.WORK ? (
          <Box sx={{ display: "flex" }}>
            <CommonWebHeaderButton
              buttonContent="Delete"
              sx={{
                backgroundColor: color.white,
                fontStyle: color.error,
                ":hover": {
                  backgroundColor: color.red01,
                },
              }}
              icon={<img src={ICON_TRASH}></img>}
              height="45px"
              textColor={color.error}
            />
            <HorizontalSpacer width={12} />
            <CommonWebHeaderButton
              width="90px"
              height="45px"
              buttonContent="Update"
              sx={{ display: "flex", flexDirection: "column" }}
            />
          </Box>
        ) : null
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          {adminType === AdminType.WORK || adminType === AdminType.MANAGER ? (
            <ProductionDetailsCardPossible />
          ) : (
            <ProductionDetailsCardImpossible />
          )}
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            padding: "24px",
            boxShadow: 0,
          }}
        >
          {adminType === AdminType.WORK ? (
            <ProductionDetailsDateCardImpossible />
          ) : adminType === AdminType.MANAGER ? (
            <ProductionDetailsDateCardPossible />
          ) : (
            <ProductionDetailsDateCardImpossible />
          )}
        </Card>
      </Grid>
    </CommonList>
  );
}
