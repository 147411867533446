import { useEffect, useState } from "react";
import { getLocale } from "../../../../customHook/useLocale";
import { Box, MenuItem, Select, styled } from "@mui/material";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";

interface CustomTimePickerProps {
  onSelectTime: (time: number) => void;
  selectedTime: number | undefined;
  selectValueError?: boolean;
  openTime: string;
  closeTime: string;
}

const CustomStyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: "4px 8px",
  borderRadius: "10px",
  maxHeight: "38px",
  "&.Mui-selected": {
    backgroundColor: `${color.primary50} !important`, // 선택된 항목 배경색 변경
  },
}));

export default function CustomTimePicker({
  onSelectTime,
  selectedTime,
  selectValueError = false,
  openTime,
  closeTime,
}: CustomTimePickerProps) {
  const placeHolderText = LocalizedText("ua_f311_selecttime");
  const [isFocused, setIsFocused] = useState(false);
  const [currentSelectedTime, setCurrentSelectedTime] = useState<
    number | undefined
  >(selectedTime);
  const borderStyle = `1px solid ${
    isFocused
      ? color.primary500
      : selectValueError
        ? color.error
        : color.gray300
  }`;

  useEffect(() => {
    setCurrentSelectedTime(selectedTime);
  }, [selectedTime]);

  const formatLocalizedTime = (date: Date) => {
    const locale = getLocale();
    console.log("LOCALE", locale);
    return new Intl.DateTimeFormat(locale, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };

  const generateTimeSlots = () => {
    const times = [];
    const [openHour, openMinute] = openTime.split(":").map(Number);
    const [closeHour, closeMinute] = closeTime.split(":").map(Number);

    let startDate = new Date(0, 0, 0, openHour, openMinute);
    const endDate = new Date(0, 0, 0, closeHour, closeMinute);

    while (startDate <= endDate) {
      times.push({
        label: formatLocalizedTime(startDate),
        value: startDate.getHours() * 60 + startDate.getMinutes(),
      });
      // 30분 추가
      startDate = new Date(startDate.getTime() + 30 * 60 * 1000);
    }
    return times;
  };

  const timeSlots = generateTimeSlots();

  const handleTimeChange = (event: any) => {
    const selectedHour = event.target.value;
    onSelectTime(selectedHour);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Select
        sx={{
          width: "100%",
          height: "38px",
          "& .MuiInputBase-input": {
            fontStyle: fontStyle.bodyMr,
            color: selectedTime === undefined ? color.gray500 : color.gray800,
            border: `0px`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: borderStyle,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: borderStyle,
          },
        }}
        value={currentSelectedTime ?? ""}
        variant="outlined"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleTimeChange}
        displayEmpty={true}
        renderValue={(selected) => {
          if (selected === undefined || selected === 0) return placeHolderText;
          const foundSlot = timeSlots.find((it) => it.value === selected);
          return foundSlot ? foundSlot.label : placeHolderText;
        }}
      >
        {timeSlots?.map((it, index) => (
          <CustomStyledMenuItem key={index} value={it.value}>
            {it.label}
          </CustomStyledMenuItem>
        ))}
      </Select>
    </Box>
  );
}
