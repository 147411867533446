import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { PayQRUiEvent } from "./PayQRUiEvent";
import { PayQRUiState } from "./PayQRUiState";
import { PayQRSideEffect } from "./PayQRSideEffect";
import PayQRRender from "./PayQRRender";
import ProgressBar from "../../../component/ProgressBar";
import { ApiService } from "../../../restAPI/ApiService";

function onImageDownloadSuccess(): void {
  const event = new CustomEvent("onImageDownloadSuccess");
  window.dispatchEvent(event);
}

(window as any).onImageDownloadSuccess = onImageDownloadSuccess;

const PayQRPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [uiState, setUiState] = useState(new PayQRUiState());

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    const handleDownloadSuccess = (event: Event) => {
      const customEvent = event as CustomEvent;
      uiEventHandler.OpenToast();
    };

    window.addEventListener("onImageDownloadSuccess", handleDownloadSuccess);
    return () => {
      window.removeEventListener(
        "onImageDownloadSuccess",
        handleDownloadSuccess
      );
    };
  }, []);

  useEffect(() => {
    const locationData = location.state as {
      productId: string;
      userUUID: string;
    };

    setUiState((prev) => ({
      ...prev,
      productId: locationData.productId,
    }));

    (async () => {
      try {
        if (locationData) {
          updateQRCode(
            localStorage.getItem("uuid") || "",
            locationData.productId ?? ""
          );
        }
      } catch (error) {
        console.error("Failed to update QR code :", error);
      }
    })();
  }, [location.state]);

  const uiEventHandler: PayQRUiEvent = {
    OpenToast: function (): void {
      setUiState((prev) => ({
        ...prev,
        isToastOpen: true,
      }));
    },

    CloseToast: function (): void {
      setUiState((prev) => ({
        ...prev,
        isToastOpen: false,
      }));
    },
  };

  const sideEffectHandlers: PayQRSideEffect = {
    navigate: function (target: string, options?: { state: any }): void {
      console.log("navigate", target);
      navigate(target, options);
    },
    downloadQrCode: function (): void {
      fetch(uiState.qrcodeUrl)
        .then((response) => response.blob())
        .then((blob) => convertBlobToBase64(blob))
        .then((qr) => {
          if (window.Android) {
            window.Android.downloadQr(qr);
          }
          if (window.webkit) {
            window.webkit.messageHandlers.downloadQr.postMessage(qr);
          }
        })
        .catch((error) => {
          console.error("Failed to download QR code :", error);
        });
    },
  };

  // 멤버십, 바우처 구매 QR 생성

  async function getQRCode(uuid: string, productId: string) {
    const requestBody = {
      id: productId,
      uuid: uuid,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      const response = await ApiService.MobilePost(
        "/pw/ua/infoplus/createQrFEV",
        requestBody,
        undefined,
        "blob"
      );

      if (response) {
        const qrcodeUrl = URL.createObjectURL(response.data);
        const headers = response.headers;

        return {
          qrcodeUrl,
          productName: headers.name,
          productDescription: headers.description,
          productPrice: headers.price,
        };
      }
    } catch (error) {
      console.error("Failed to get QR code :", error);
    }
  }

  async function updateQRCode(uuid: string, productId: string) {
    try {
      const qrCodeData = await getQRCode(uuid, productId);

      if (qrCodeData) {
        const { qrcodeUrl, productName, productDescription, productPrice } =
          qrCodeData;

        setUiState((prev) => ({
          ...prev,
          qrcodeUrl,
          productName,
          productDescription,
          productPrice,
        }));
      }
    } catch (error) {
      console.error("Failed to update QR code :", error);
    } finally {
      setLoading(false);
    }
  }

  function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;

        const base64String = base64Data ? base64Data.split(",")[1] : ""; // remove the prefix(data:image/png;base64,)
        resolve(base64String);
      };
      reader.onerror = (error) => {
        console.error("Failed to convert blob to base64 :", error);
      };

      reader.readAsDataURL(blob);
    });
  }

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <>
      <PayQRRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandlers}
      />
    </>
  );
};

export default PayQRPage;
