import React, {
  useState,
  useEffect,
  ChangeEventHandler,
  MouseEventHandler,
} from "react";
import {
  Pagination,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { Param } from "../../../store/paramType";
import { translate } from "../../../locales/translation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
export interface ParamDataTableProps {
  data: Param[];
  rowsPerPage: number;
  page: number;
  onDelete: (id: number) => Promise<void>;
  onRowClick: (row: Param) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage?: (newPageSize: number) => void;
  count: number;
}

const CustomCell = ({
  title,
  align,
  onSort,
}: {
  title: string;
  align: "left" | "center" | "right";
  onSort?: () => void;
}) => (
  <TableCell
    sx={{
      backgroundColor: "white",
      color: "#99989D",
      fontWeight: 600,
      fontFamily: "var(--bs-font-sans-serif)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      position: "relative",
    }}
    align={align}
  >
    {title}

    {onSort && (
      <div
        onClick={onSort}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          right: 0,
          top: 9,
          fontSize: "1.0rem",
        }}
      >
        <UnfoldMoreIcon
          style={{
            marginTop: "10px",
            marginRight: "18px",
            fontSize: "medium",
            color: "#1D1B26",
          }}
        />
      </div>
    )}
  </TableCell>
);

const ParamDataTable: React.FC<ParamDataTableProps> = ({
  data,
  rowsPerPage,
  onRowClick,
  onDelete,
  page,
  setPage,
  setRowsPerPage,
  count,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedData, setSortedData] = useState<any[]>([...data]);
  const [openedRow, setOpenedRow] = useState<any>(null);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleSort = () => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    const sorted = [...data].sort((a, b) => {
      const dateA = new Date(a.createdAt || "");
      const dateB = new Date(b.createdAt || "");
      return newOrder === "asc"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });

    setSortedData(sorted);
    setSortOrder(newOrder);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        border: 1,
        borderColor: "#F3F3F4",
        borderRadius: 3,
        mt: 3,
      }}
      elevation={0}
    >
      <TableContainer style={{ borderRadius: "15px" }}>
        <Table
          sx={{
            width: "100%",

            border: "0px solid #E9E9EA",
            "&th": {
              border: "1px solid #E9E9EA",
            },

            borderBottom: "1px solid #E9E9EA",
          }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <CustomCell title="No" align="center" />
              <CustomCell title="" align="center" />
              <CustomCell title="구분" align="center" />
              <CustomCell title="데이터/인자" align="center" />
              <CustomCell title="항목" align="center" />
              <CustomCell title="Value" align="center" />
              <CustomCell title="단위" align="center" />
              <CustomCell title="정의" align="center" />
              <CustomCell title="방법론 ID" align="center" />
              <CustomCell title="버전" align="center" />
              <CustomCell title="생성일" align="center" onSort={handleSort} />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length > 0 ? (
              sortedData.map((row, index) => (
                <>
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      ":hover": {
                        backgroundColor: "#F9F6FD",
                        cursor: "pointer",
                      },
                      "& td": {
                        color: "#53515A",
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                      },
                    }}
                    onClick={() => onRowClick(row)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      sx={{ color: "#53515A", fontWeight: 400 }}
                    >
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(event: any) => {
                          event.stopPropagation();
                          setOpenedRow((prev: any) =>
                            prev === row.id ? null : row.id
                          );
                        }}
                      >
                        {openedRow === row.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" sx={{ width: "10%" }}>
                      {translate(row.standard)}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">
                      <Box>
                        {openedRow === row.id ? (
                          row?.valueList.map((el: any) => (
                            <Box key={el.id}>{el.item}</Box>
                          ))
                        ) : row?.valueList?.length > 1 ? (
                          <Box
                            gap={1}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Box
                              sx={{
                                minWidth: "40px",
                                maxWidth: "120px",
                                minHeight: "20px",
                                display: "flex",
                              }}
                            >
                              {row?.valueList[0]?.item}
                            </Box>
                            <Box
                              sx={{
                                background: "#5D38E5",
                                padding: "0 6px",
                                borderRadius: "30px",
                                width: "40px",
                                minHeight: "20px",
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "11px",
                                lineHeight: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                wrap: "nowrap",
                              }}
                            >
                              +{row?.valueList?.length - 1}
                            </Box>
                          </Box>
                        ) : (
                          <Box display={"flex"} alignItems={"flex-start"}>
                            {row?.valueList[0]?.item || "-"}
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {openedRow === row.id
                        ? row?.valueList.map((el: any) => (
                            <>
                              <Box key={el.id}>{el.value}</Box>
                            </>
                          ))
                        : row?.valueList[0]?.value || "-"}
                    </TableCell>
                    <TableCell align="center">{row.unit || "-"}</TableCell>
                    <TableCell align="center">
                      {row.definition || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row.methodologyIdName || "-"}
                    </TableCell>
                    <TableCell align="center">{row.version || "-"}</TableCell>
                    <TableCell align="center">
                      {row.createdAt
                        ? format(parseISO(row.createdAt), "yyyy/MM/dd HH:mm")
                        : "Invalid date"}
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  조회 결과 없습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{
          mt: 2,
          ml: 1,
          mb: 2,
          display: "flex",
          justifyContent: "flex-start",
          size: "medium",
          gap: "10px",
          opacity: 1,
          "& .MuiPaginationItem-root": {
            color: "blue",
            backgroundColor: "white",
            borderRadius: "50%",
            minWidth: "28px",
            height: "28px",
            padding: "4px",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
            "&.Mui-selected": {
              backgroundColor: "#EFECFD",
              color: "#5D38E5",
              shape: "circular",
            },
          },
        }}
        count={Math.ceil(count / rowsPerPage)}
        shape="rounded"
        page={page + 1}
        color="primary"
        onChange={handleChangePage}
      />
    </Paper>
  );
};

export default ParamDataTable;
