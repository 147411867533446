import { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  PickersCalendarHeaderProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { ArrowBackIos, ArrowForwardIos, Desk } from "@mui/icons-material";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";

interface CustomDatePickerProps {
  onSelectDate: (date: number) => void;
  selectedDate?: number;
  selectValueError?: boolean;
}

export default function CustomDatePicker({
  onSelectDate,
  selectedDate,
  selectValueError = false,
}: CustomDatePickerProps) {
  const [selected, setSelected] = useState(selectedDate ?? null);
  const [isFocused, setIsFocused] = useState(false);
  const borderStyle = `1px solid ${
    isFocused
      ? color.primary500
      : selectValueError
      ? color.error
      : color.gray300
  }`;

  const handleDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate) {
      const timestamp = newDate.toDate().getTime();
      setSelected(timestamp);
      onSelectDate(timestamp);
    }
  };

  const handleOpenPicker = () => {
    setIsFocused(true);
  };

  const handleClosePicker = () => {
    setIsFocused(false);
  };

  const CustomCalendarHeader = (props: PickersCalendarHeaderProps<Dayjs>) => {
    const { currentMonth, onMonthChange, maxDate } = props;
    const isNextDisabled = currentMonth.isSame(maxDate, "month");
    const isPrevDisabled = currentMonth.isSame(dayjs(), "month");

    const handlePreviousMonth = () => {
      onMonthChange(currentMonth.subtract(1, "month"), "right");
    };

    const handleNextMonth = () => {
      onMonthChange(currentMonth.add(1, "month"), "left");
    };

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <IconButton
          onClick={handlePreviousMonth}
          disabled={isPrevDisabled}
          sx={{
            color: isPrevDisabled ? color.gray400 : color.default,
          }}
        >
          <ArrowBackIos
            fontSize="small"
            sx={{
              width: "16px",
            }}
          />
        </IconButton>
        <Typography fontStyle={fontStyle.titleL} color={color.default}>
          {LocalizedDate({
            date: currentMonth.toDate().getTime(),
            type: DateFormatType.YearMonth,
          })}
        </Typography>
        <IconButton
          onClick={handleNextMonth}
          disabled={isNextDisabled}
          sx={{
            color: isNextDisabled ? color.gray400 : color.default,
          }}
        >
          <ArrowForwardIos
            fontSize="small"
            sx={{
              width: "16px",
            }}
          />
        </IconButton>
      </Box>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: "100%" }}>
        <DesktopDatePicker
          value={selected ? dayjs(selected) : null}
          open={isFocused}
          onOpen={handleOpenPicker}
          onClose={handleClosePicker}
          onChange={handleDateChange}
          slots={{ calendarHeader: CustomCalendarHeader }}
          slotProps={{
            textField: {
              // onBlur: () => setIsFocused(false),
              // onFocus: () => setIsFocused(true),
              placeholder: `${LocalizedText("ua_f311_selectdate")}`,
              sx: {
                width: "100%",
                height: "38px",
                "& .MuiInputBase-input": {
                  fontStyle: fontStyle.bodyMr,
                  color: color.gray800,
                  border: `0px`,
                  padding: "9px",
                },
                "& .MuiInputBase-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: borderStyle,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: borderStyle,
                  },
                },
              },
              InputProps: {
                readOnly: true,
              },
            },
            layout: {
              sx: {
                "& .MuiButtonBase-root": {
                  "&.MuiPickersDay-root": {
                    "&.Mui-selected": {
                      backgroundColor: color.primary200,
                      color: color.primary500,
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: color.primary200,
                      },
                    },
                  },
                },
                "& .MuiPickersDay-root": {
                  borderRadius: "50%",
                  color: color.default,
                  "&:disabled": {
                    color: color.gray400,
                  },
                },
                "& .MuiDateCalendar-viewTransitionContainer": {
                  "& button[role=gridcell]": {
                    border: "0px !important",
                  },
                  "& div[role=row]": {
                    padding: "2px",
                    justifyContent: "space-between !important",
                  },
                  "& .MuiTypography-caption": {
                    fontStyle: fontStyle.bodySr,
                    color: "rgba(60, 60, 67, 0.3)",
                  },
                },
              },
            },
          }}
          disablePast
          views={["year", "month", "day"]}
        />
      </Box>
    </LocalizationProvider>
  );
}
