import { Box, Typography } from '@mui/material';
import { LocalizedText } from '../../../di/LanguageRepositoryProvider';
import { fontStyle } from '../../../theme/Style';
import { color } from '../../../theme/Color';

const BenefitButton = ({sx}:{sx?:object}) => (
    <Box
        sx={{
            
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: color.primary200,  
            borderRadius: '10px', // 둥근 모서리
            px:'8px',
            // py:'1px',
            height:'19px',
            ... sx,
        }}
    >

        <Typography
            sx={{
                color:color.primary500,
                fontStyle:fontStyle.titleXs
            }}
        >
            {LocalizedText("ua_g600_benefit")}
        </Typography>
    </Box >
);

export default BenefitButton;