import Toolbar from "../../../component/Toolbar";
import { Box } from "@mui/material";
import React from "react";
import { NotificationUiState } from "./model/NotificationUiState";
import NotificationCard from "./NotificationCard";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { Divider } from "../../../component/Divider";
import VerticalSpacer from "../../../component/Spacer";

interface NotificationPageRenderProps {
  uiState: NotificationUiState;
  onClickServiceSwitch: () => void;
  onClickEventSwitch: () => void;
}

export default function NotificationPageRender({
  uiState,
  onClickServiceSwitch,
  onClickEventSwitch,
}: NotificationPageRenderProps) {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        onBack={() => {
          window.history.back();
        }}
        title={LocalizedText("ua_g710_notisetting")}
      />
      <VerticalSpacer height={20} />
      <NotificationCard
        title={LocalizedText("ua_g710_servicenoti_title")}
        content={LocalizedText("ua_g710_servicenoti_message")}
        isChecked={uiState.isActivatedServiceNotification}
        onClick={onClickServiceSwitch}
      />
      <VerticalSpacer height={20} />
      <Divider />
      <VerticalSpacer height={20} />
      <NotificationCard
        title={LocalizedText("ua_g710_eventnoti_title")}
        content={LocalizedText("ua_g710_eventnoti_message")}
        isChecked={uiState.isActivatedEventingNotification}
        onClick={onClickEventSwitch}
      />

      <Box
        sx={{
          height: "100%",
          px: "20px",
          flexDirection: "column",
          overflowY: "scroll",
        }}
      ></Box>
    </Box>
  );
}
