import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Radio,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ApiService } from "../../../restAPI/ApiService";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { CsDetailsSideEffect } from "../model/CsDetailsSideEffect";
import { CsDetailsUiEvent } from "../model/CsDetailsUiEvent";
import { CsDetailsUiState } from "../model/CsDetailsUiState";
import SpanningTable from "./FeeTable";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CommonTextField from "../../../component/CommonTextField";
import CommonDialog from "../../../component/CommonDialog";

interface CsMngDetailData {
  id: string;
  status: string;
  writer: string;
  email: string;
  phoneNumber: string;
  vin: string;
  preferredCSStationId: number;
  preferredCSStation: string;
  preferredCSDate: number;
  registrationDate: number;
  receiptDate: number;
  completeDate: number;
  deleteDate: number;
  title: string;
  content: string;
  reporter: string;
  imageUrls: string[];
  correctiveAction: string;
  problemDescription: string;
}

interface CsMngDetailsGridProps {
  uiState: CsDetailsUiState;
  uiEvent: CsDetailsUiEvent;
  sideEffect: CsDetailsSideEffect;
}

export default function CsMngDetailsGrid({
  uiState,
  uiEvent,
  sideEffect,
}: CsMngDetailsGridProps) {
  const pathname = useLocation().pathname;
  const inquiryId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출
  const [receiveDialogOpen, setReceiveDialogOpen] = useState(false);
  const [data, setData] = useState<CsMngDetailData>({
    id: "",
    status: "",
    writer: "",
    email: "",
    phoneNumber: "",
    vin: "",
    preferredCSStationId: 0,
    preferredCSStation: "",
    preferredCSDate: 0,
    registrationDate: 0,
    receiptDate: 0,
    completeDate: 0,
    deleteDate: 0,
    title: "",
    content: "",
    reporter: "",
    imageUrls: [],
    correctiveAction: "",
    problemDescription: "",
  });
  const [reportOpen, setReportOpen] = useState<any>();

  useEffect(() => {
    ApiService.WebPost(`/pw/aw/contents/cs/detail`, {
      id: inquiryId,
    }).then((res) => {
      setData(res?.data.body || data);
      uiEvent.onChangeData(res?.data.body || data);
    });
  }, [inquiryId]);

  const handleReceiveOk = () => {
    setReceiveDialogOpen(false);
    if (inquiryId) {
      sideEffect.handelBtnReceive(inquiryId);
    }
  };

  const handleReceiveClose = () => {
    setReceiveDialogOpen(false);
  };

  return (
    <Grid container columnSpacing={"20px"}>
      {/* 첫번째 카드 */}
      <Grid item lg={3.5}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              p: "24px",
              "&:last-child": {
                paddingBottom: "40px",
              },
            }}
          >
            <CardHeader
              title="CS Apply Information"
              titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
              sx={{ p: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Status
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.status}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Writer
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.writer}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  E-mail
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.email}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Phone Number
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.phoneNumber}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  VIN
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.vin}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Preferred CS Station
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.preferredCSStation}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Preferred CS date
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {LocalizedDate({
                    date: data?.preferredCSDate,
                    type: DateFormatType.YearMonthDayTime,
                  })}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Divider />
              <VerticalSpacer height={40} />
              <Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.regularS}
                    color={color.gray600}
                  >
                    Registered
                  </Typography>
                  <HorizontalSpacer width={12} />
                  <Typography fontStyle={fontStyle.regularS}>
                    {LocalizedDate({
                      date: data?.registrationDate,
                      type: DateFormatType.YearMonthDayTime,
                    })}
                  </Typography>
                </Box>
                {data?.receiptDate !== 0 && (
                  <>
                    <VerticalSpacer height={8} />
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontStyle={fontStyle.regularS}
                        color={color.gray600}
                      >
                        Received
                      </Typography>
                      <HorizontalSpacer width={12} />
                      <Typography fontStyle={fontStyle.regularS}>
                        {LocalizedDate({
                          date: data?.receiptDate,
                          type: DateFormatType.YearMonthDayTime,
                        })}
                      </Typography>
                    </Box>
                  </>
                )}
                {data?.completeDate !== 0 && (
                  <>
                    <VerticalSpacer height={8} />
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontStyle={fontStyle.regularS}
                        color={color.gray600}
                      >
                        Completed
                      </Typography>
                      <HorizontalSpacer width={12} />
                      <Typography fontStyle={fontStyle.regularS}>
                        {LocalizedDate({
                          date: data?.completeDate,
                          type: DateFormatType.YearMonthDayTime,
                        })}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>

              {uiState.reportOpen === false && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: -3,
                  }}
                >
                  <Button
                    sx={{
                      textTransform: "none",
                      height: 40,
                      backgroundColor: `${color.toolingPurple500}`,
                      color: `${color.white}`,
                      borderRadius: 5,
                      width: 125,
                      ":hover": {
                        backgroundColor: `${color.toolingPurple500Hover}`,
                      },
                    }}
                    onClick={() => {
                      setReceiveDialogOpen(true);
                    }}
                  >
                    Receive
                  </Button>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={8.5}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              p: "24px",
              "&:last-child": {
                paddingBottom: "40px",
              },
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.semiboldL}>
                  {data?.title}
                </Typography>
                <Box
                  sx={{
                    border: 2,
                    borderColor: `${color.gray100}`,
                    borderRadius: 3,
                    mt: 3,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.regularS}
                    sx={{
                      whiteSpace: "pre-line",
                      m: 2,
                    }}
                  >
                    {data?.content}
                  </Typography>
                  <Swiper
                    pagination={{
                      type: "bullets",
                      clickable: true,
                    }}
                    modules={[Pagination]}
                  >
                    {data?.imageUrls !== null ? (
                      <>
                        {data?.imageUrls.map(
                          (imageUrl: string, index: number) => {
                            return (
                              <SwiperSlide key={index}>
                                <img
                                  src={imageUrl}
                                  alt="Example Icon"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                  }}
                                />
                              </SwiperSlide>
                            );
                          }
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Swiper>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
        {/* Report */}
        {uiState.reportOpen === true && (
          <Card
            sx={{
              borderRadius: 3,
              mt: 4,
              width: "100%",
              display: "flex",
            }}
          >
            <CardContent sx={{ width: "100%" }}>
              <Box
                sx={{
                  m: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ mt: 0 }}>
                  <Typography fontWeight={"bold"} fontSize={21}>
                    Report
                  </Typography>
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Typography
                      fontStyle={fontStyle.boldXS}
                      color={color.gray600}
                    >
                      Identified Problem Description
                    </Typography>
                    <CommonTextField
                      multiline
                      rows={3}
                      sx={{
                        backgroundColor: `${data?.status === "Complete" ? color.disableColor : ""}`,
                        borderRadius: "12px",
                        whiteSpace: "pre-line",
                        mt: 1,
                        width: "100%",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                      value={
                        data.problemDescription || uiState.problemDescription
                      }
                      disabled={data?.status === "Complete" ? true : false}
                      onChange={(event) => {
                        uiEvent.onChangeProblemDescription(event.target.value);
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Typography
                      fontStyle={fontStyle.boldXS}
                      color={color.gray600}
                    >
                      Action to Prevent Recurrence
                    </Typography>
                    <CommonTextField
                      multiline
                      rows={3}
                      sx={{
                        backgroundColor: `${data?.status === "Complete" ? color.disableColor : ""}`,
                        borderRadius: "12px",
                        whiteSpace: "pre-line",
                        mt: 1,
                        width: "100%",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                      value={data.correctiveAction || uiState.correctiveAction}
                      disabled={data?.status === "Complete" ? true : false}
                      onChange={(event) => {
                        uiEvent.onChangeCorrectiveAction(event.target.value);
                      }}
                    />
                  </Box>
                  <Divider sx={{ mt: 5, mb: 5 }} />
                  <Typography
                    fontSize={14}
                    sx={{ color: "#99989D" }}
                    fontWeight={"bold"}
                  >
                    Repair Fee
                  </Typography>
                  <SpanningTable
                    uiState={uiState}
                    uiEvent={uiEvent}
                    sideEffect={sideEffect}
                  />
                  {uiState?.status === "Receipt" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 3,
                        mb: -3,
                      }}
                    >
                      <Button
                        disabled={!uiState.onBtnComplete}
                        sx={{
                          backgroundColor: `${uiState.onBtnComplete ? color.ableButton : color.disableButton}`,
                          borderRadius: 5,
                          color: `${color.white}`,
                          textTransform: "none",
                          width: 125,
                          height: 40,
                          ":hover": {
                            backgroundColor: `${color.toolingPurple500Hover}`,
                          },
                        }}
                        onClick={() => {
                          sideEffect.onClickCompleteButton();
                        }}
                      >
                        Complete
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
      </Grid>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={receiveDialogOpen}
        handleOk={handleReceiveOk}
        handleClose={handleReceiveClose}
      >
        Do you want to receive CS requests?
        <br />
        Click OK, and the status will change to
        <br />
        'Receipt'.
      </CommonDialog>
    </Grid>
  );
}
