import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Collapse, Typography } from "@mui/material";
import SwiperPhotos from "./SwiperPhotos";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import {
  ICON_BATTERY2,
  ICON_CALENDAR,
  ICON_CALENDAR_GRAY,
  ICON_DOWN_ARROW,
  ICON_PHONE2,
} from "../../../constants/appImagePath";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { ApiService } from "../../../restAPI/ApiService";
import IconTextDialog, { IconTextDialogType } from "../../../component/dialog/IconTextDialog";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { PWUserAppMapUiEvent } from "../PWUserAppMapUiEvent";
import { APP_CONFIG } from "../../../constants/AppConfig";

interface DrawerListCSMSProps {
  data?: any;
  setDetailsOpen?: any;
  uiEvent: PWUserAppMapUiEvent;
}

const DrawerListCSMS = ({
  data,
  setDetailsOpen,
  uiEvent,
}: DrawerListCSMSProps) => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [simpleCompleteDialogOpen, setSimpleCompleteDialogOpen] =
    useState(false);
  const [
    simpleAlreadyReservationDialogOpen,
    setSimpleAlreadyReservationDialogOpen,
  ] = useState(false);
  const [simpleNoBatteryDialogOpen, setSimpleNoBatteryDialogOpen] =
    useState(false);
  const [simpleTimeLimitDialogOpen, setSimpleTimeLimitDialogOpen] =
    useState(false);

  const handleDimEvent = (event: boolean) => {
    if (window.Android) {
      window.Android.isShownDialog(event);
    }

    if (window.webkit) {
      window.webkit.messageHandlers.isShownDialog.postMessage(event);
    }
  };

  const [collapseOpen, setCollapseOpen] = useState(false);

  const toggleSetCollapseOpen = () => {
    setCollapseOpen(!collapseOpen);
  };

  const dayMapping: any = {
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
  };

  // const shortenedDays = data.dayOff
  //   .map((day: any) => dayMapping[day]) // 요일 이름을 매핑 테이블에서 찾아서 변환
  //   .join(", "); // 콤마와 공백으로 연결

  // const week = [
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];

  // function arrayDifference(arr: string[]): string {
  //   let res: string = " ";
  //   for (let i = 0; i < arr.length; ++i) {
  //     if ("X" === arr[i]) {
  //       if (0 < i) res += " ";
  //       res += week[i];
  //     }
  //   }
  //   return res;
  // }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <VerticalSpacer height={12} />
      <Box sx={{ display: "flex" }}>
        <div
          style={{ width: "101px" }}
          onClick={() => {
            setDetailsOpen(true);
            if (data.svcImage) {
              uiEvent.SetDetailsSvcImageData(data.svcImage);
            } else {
              uiEvent.SetDetailsOpen(false);
              uiEvent.SetDetailsSvcImageData(null);
            }
          }}
        >
          <SwiperPhotos
            imgData={data.svcImage}
            type={APP_CONFIG.CHARGE}
          />
        </div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            ml: 1,
          }}
        >
          <Typography fontStyle={fontStyle.titleL}>{data.svcNm}</Typography>
          <Box
            fontStyle={fontStyle.bodyMr}
            color={color.gray500}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              fontStyle={fontStyle.bodyMr}
              color={data.svcStt == "OPEN" ? color.progress : color.gray700}
            >
              {data.svcStt === "OPEN" && <>{LocalizedText("ua_c300_open")}</>}
              {data.svcStt !== "OPEN" && <>{LocalizedText("ua_c300_close")}</>}
            </Typography>
            &nbsp;|&nbsp;{data.openTime}-{data.closeTime}
            <Box
              component="img"
              src={ICON_DOWN_ARROW}
              onClick={toggleSetCollapseOpen}
              style={{
                transform: collapseOpen ? "rotate(180deg)" : "rotate(0deg)", // 아이콘 회전 애니메이션
              }}
            />
          </Box>
          <Collapse in={collapseOpen} timeout="auto">
            <Box sx={{ display: "flex" }}>
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray500}>
                Holiday
              </Typography>
              <HorizontalSpacer width={6} />
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                {/* arrayDifference(data.dayOff) */}
                {/* {shortenedDays} */}
              </Typography>
            </Box>
          </Collapse>
          <Box
            fontStyle={fontStyle.bodyMr}
            color={color.gray700}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box component="img" src={ICON_PHONE2} />
            <HorizontalSpacer width={4} />
            {data.contact}
            <HorizontalSpacer width={8} />
            <Box
              onClick={() => {
                window.location.href = `tel:${data.contact}`; // 해당 CSMS로 전화
              }}
              color={color.primary500}
            >
              {LocalizedText("ua_c300_call")}
            </Box>
          </Box>
          {/* <Typography fontSize={14} color={"black"} sx={{ display: "flex" }}>
            <Box component="img" src={ICON_BATTERY2} />
            {LocalizedText("ua_c300_availablebattery")}
          </Typography>
          <Box sx={{ display: "flex", mt: 0.5 }}>
            <Box
              sx={{
                backgroundColor: "#EBFAF3",
                width: "auto",
                pl: 0.8,
                pr: 0.8,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
              }}
            >
              <Typography fontStyle={fontStyle.bodyMr} color={color.success}>
                Now
              </Typography>

              <Typography
                fontSize={14}
                color={"#2BCA86"}
                fontWeight={"bold"}
                sx={{ ml: 0.5 }}
              >
                {data.batterySet.now}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#E0E6FF",
                width: "auto",
                // height: 18,
                ml: 0.5,
                pl: 0.8,
                pr: 0.8,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1.5,
              }}
            >
              <Typography fontSize={14} color={"#4C6FFF"} fontWeight={"100"}>
                Soon(within 1h)
              </Typography>

              <Typography
                fontSize={14}
                color={"#4C6FFF"}
                fontWeight={"bold"}
                sx={{ ml: 0.7 }}
              >
                {data.batterySet.soon}
              </Typography>
            </Box>
          </Box>
          <Button
            sx={{
              textTransform: "none",
              mt: 1,
              border: 1,
              borderRadius: "5px",
              py: "6px",
              color: "#5D38E5",
            }}
            onClick={() => {
              handleDimEvent(true);
              setDialogOpen(true);
            }}
            // disabled={data.svcStt === "CLOSE"} //임시처리
            disabled
          >
            <Box
              fontStyle={fontStyle.titleL}
              color={
                // data.svcStt === "CLOSE" ? color.gray500 : color.primary500 //임시처리
                color.gray500
              }
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                component="img"
                src={
                  // data.svcStt === "CLOSE" ? ICON_CALENDAR_GRAY : ICON_CALENDAR //임시처리
                  ICON_CALENDAR_GRAY
                }
              />
              <HorizontalSpacer width={6} />

              {LocalizedText("ua_c300_reserve")}
            </Box>
          </Button> */}
        </Box>
      </Box>
      <VerticalSpacer height={12} />

      {/* 배터리 예약 버튼 눌렀을때 첫번째로 나오는 dialog */}
      <IconTextDialog
        title={LocalizedText("ua_c500_alert_reserve_title")}
        content={LocalizedText("ua_c500_alert_reserve_message")}
        type={IconTextDialogType.BATTERY}
        positiveText="OK"
        negativeText="Cancel"
        isOpen={dialogOpen}
        onPositive={() => {
          const rentData = {
            station: data.svcId,
            userId: "lhd2346",
          };
          ApiService.MobilePost("/pw/backend/api/battery/rent", rentData)
            .then((res: any) => {
              if (res.data.result == true) {
                handleDimEvent(true);
                setSimpleCompleteDialogOpen(true);
              } else if (res.data.body == "Already Reservation") {
                handleDimEvent(true);
                setSimpleAlreadyReservationDialogOpen(true);
              } else if (res.data.body == "No Battery in Station") {
                handleDimEvent(true);
                setSimpleNoBatteryDialogOpen(true);
              } else if (res.data.body == "Time Limit") {
                handleDimEvent(true);
                setSimpleTimeLimitDialogOpen(true);
              }
            })
            .then(() => {
              setDialogOpen(false);
            });
        }}
        onNegative={() => {
          handleDimEvent(false);
          setDialogOpen(false);
        }}
        onClose={() => {
          handleDimEvent(false);
          setDialogOpen(false);
        }}
      />

      {/* 배터리 예약 완료 시 나오는 dialog */}
      <SimpleDialog
        children={LocalizedText("ua_c500_alert_complete_message")}
        isOpen={simpleCompleteDialogOpen}
        positiveText="OK"
        onPositive={() => {
          if (window.Android) {
            window.Android.navigate("Battery");
          }

          if (window.webkit) {
            window.webkit.messageHandlers.navigate.postMessage("Battery");
          }

          handleDimEvent(false);
          setSimpleCompleteDialogOpen(false);
        }}
        onNagative={() => {
          handleDimEvent(false);
          setSimpleCompleteDialogOpen(false);
        }}
        onClose={() => {
          handleDimEvent(false);
          setSimpleCompleteDialogOpen(false);
        }}
      />

      {/* 이미 예약된 배터리가 있을 시 나오는 dialog */}
      <SimpleDialog
        children={LocalizedText("ua_c500_alert_reserveagian_message")}
        isOpen={simpleAlreadyReservationDialogOpen}
        positiveText="OK"
        onPositive={() => {
          if (window.Android) {
            window.Android.navigate("Battery");
          }

          if (window.webkit) {
            window.webkit.messageHandlers.navigate.postMessage("Battery");
          }

          handleDimEvent(false);
          setSimpleAlreadyReservationDialogOpen(false);
        }}
        onNagative={() => {
          handleDimEvent(false);
          setSimpleAlreadyReservationDialogOpen(false);
        }}
        onClose={() => {
          handleDimEvent(false);
          setSimpleAlreadyReservationDialogOpen(false);
        }}
      />
      {/* 예약 가능한 배터리가 없을시 나오는 dialog */}
      <SimpleDialog
        children={LocalizedText("ua_c500_alert_noavailable_message")}
        isOpen={simpleNoBatteryDialogOpen}
        positiveText="OK"
        onPositive={() => {
          handleDimEvent(false);
          setSimpleNoBatteryDialogOpen(false);
        }}
        onClose={() => {
          handleDimEvent(false);
          setSimpleNoBatteryDialogOpen(false);
        }}
      />
      {/* 배터리 예약 취소로 5분 패널티가 적용됐을때 나오는 dialog */}
      <SimpleDialog
        children={LocalizedText("ua_c500_alert_cannot_message")}
        isOpen={simpleTimeLimitDialogOpen}
        positiveText="OK"
        onPositive={() => {
          handleDimEvent(false);
          setSimpleTimeLimitDialogOpen(false);
        }}
        onClose={() => {
          handleDimEvent(false);
          setSimpleTimeLimitDialogOpen(false);
        }}
      />
    </Box>
  );
};

export default DrawerListCSMS;
