import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExistingAccountPageUiEvent } from "./ExistingAccountPageUiEvent";
import { ExistingAccountPageUiState } from "./ExistingAccountPageUiState";
import { ExistingAccountPageSideEffect } from "./ExistingAccountPageSideEffect";
import ExistingAccountRender from "./ExistingAccountRender";
import { userInfo } from "os";

const ExistingAccountPage = () => {
    const navigate = useNavigate();
    const [uiState, setUiState] = useState(new ExistingAccountPageUiState());

    const uiEventHandler: ExistingAccountPageUiEvent = {
    };

    const sideEffectHandlers: ExistingAccountPageSideEffect = {
        navigate: function (target: string): void {
            console.log("navigate", target);
            navigate(`/${target}`);
        },
    };

    return (
        <>
          <ExistingAccountRender
            sideEffect={sideEffectHandlers}
            uiEvent={uiEventHandler}
            uiState={uiState}
          />
        </>
    );
};

export default ExistingAccountPage;