import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import { ICON_RIGHT_ARROW_PRIMARY200 } from "../../../../constants/appImagePath";
import LinearProgressBar from "../../../../component/LinearProgressBar";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";

interface MileageSectionProps {
  remainingMileage: number;
  maxMileage: number;
  onClick: () => void;
}

const MileageSection = ({
  remainingMileage,
  maxMileage,
  onClick,
}: MileageSectionProps) => {

  const formatMileage = (mileage: number) => {
    return `${parseFloat(mileage.toFixed(1)).toLocaleString()} km`;
  };

  const isUnlimited = maxMileage >= 2_000_000;
  const displayMaxMileage = isUnlimited ? "Unlimited" : formatMileage(maxMileage);
  const displayRemainingMileage = isUnlimited ? "Unlimited" : formatMileage(remainingMileage);
  const progressValue = isUnlimited ? 100 : (remainingMileage / maxMileage) * 100;

  return (
    <Box>
      <Typography fontStyle={fontStyle.subTitleL} color={color.white}>
        {LocalizedText("ua_g420_remaining")}
      </Typography>
      <VerticalSpacer height={4} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={onClick}
        >
          <Typography fontStyle={fontStyle.headingS} color={color.white}>
            {displayRemainingMileage}
          </Typography>
          <img
            src={ICON_RIGHT_ARROW_PRIMARY200}
            alt="Arrow"
            style={{
              width: "32px",
              height: "32px",
            }}
          />
        </Box>
        <Typography fontStyle={fontStyle.bodyMr} color={color.white}>
          {displayMaxMileage}
        </Typography>
      </Box>
      <VerticalSpacer height={8} />
      <LinearProgressBar
        value={progressValue}
        height={8}
        borderRadius={8}
        backgroundColor={color.primary300}
        barColor={color.white}
      />
    </Box>
  );
};

export default MileageSection;
