import { Box, Typography } from "@mui/material";
import Toolbar from "../../../component/Toolbar";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { UsageHistoryUiState } from "./UsageHistoryUiState";
import { UsageHistoryUiEvent } from "./UsageHistoryUiEvent";
import { UsageHistorySideEffect } from "./UsageHistorySideEffect";
import CommonTabs from "../../../component/CommonTabs";
import InquiryPeriod from "../../../component/InquiryPeriod/InquiryPeriod";
import { PeriodButtonType } from "../../../component/InquiryPeriod/PeriodButton";
import CalendarDrawer, {
  CalendarType,
} from "../../../component/InquiryPeriod/CalendarDrawer";
import { HorizontalGrayDivider } from "../../../component/Divider";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import UsageHistoryList from "./section/UsageHistoryList";

interface UsageHistoryRenderProps {
  uiState: UsageHistoryUiState;
  uiEvent: UsageHistoryUiEvent;
  sideEffect: UsageHistorySideEffect;
}

const UsageHistoryRender = ({
  uiState,
  uiEvent,
  sideEffect,
}: UsageHistoryRenderProps) => {
  const tabs = [
    { value: "All", label: LocalizedText("ua_g421_taball") },
    { value: "Use", label: LocalizedText("ua_g421_tabuse") },
    { value: "Charge", label: LocalizedText("ua_g421_tabcharge") },
  ];
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          backgroundColor: color.white,
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g421_usagehistory")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>

        {/* Tabs */}
        <CommonTabs
          currentTab={uiState.currentTab}
          tabs={tabs}
          onChange={uiEvent.HandleTabChange}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <VerticalSpacer height={16} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: "20px",
            }}
          >
            <InquiryPeriod
              isSelectedCustom={uiState.isVisibleDataPicker}
              onPeriodChange={(type) => {
                if (type === PeriodButtonType.CUSTOM) {
                  uiEvent.OpenDatePicker();
                } else {
                  uiEvent.CloseDatePicker();
                  const currentDate = new Date().getTime();
                  switch (type) {
                    case PeriodButtonType.ALL:
                      sideEffect.requestUsageHistory(
                        undefined,
                        currentDate,
                        100
                      );
                      break;
                    case PeriodButtonType.ONE_MONTH:
                      sideEffect.requestUsageHistory(undefined, currentDate, 0);
                      break;
                    case PeriodButtonType.THREE_MONTH:
                      sideEffect.requestUsageHistory(undefined, currentDate, 3);
                      break;
                  }
                }
              }}
              startDate={uiState.startDate}
              endDate={uiState.endDate}
              onClickStartDate={() => uiEvent.OpenCalendar(CalendarType.START)}
              onClickEndDate={() => uiEvent.OpenCalendar(CalendarType.END)}
              onClickSearch={() => {
                sideEffect.requestUsageHistory(
                  uiState.startDate,
                  uiState.endDate,
                  undefined
                );
              }}
            />
          </Box>
          <VerticalSpacer height={16} />
          <HorizontalGrayDivider />
          <Box
            sx={{
              px: "20px",
              pb: "16px",
            }}
          >
            <UsageHistoryList histories={uiState.historyList} onToggleExpand={(id) => {
                uiEvent.ToggleHistoryDetail(id);
            }} />
          </Box>
        </Box>

        {/* Calendar Drawer */}
        <CalendarDrawer
          title={LocalizedText("ua_g421_modal_selectdate")}
          isOpen={uiState.isCalendarOpen}
          onClose={() =>
            uiEvent.CloseCalendar(uiState.calendarType, uiState.selectedDate)
          }
          onSelectDate={(date) => uiEvent.SelectDate(date)}
          onInvalidDate={uiEvent.ShowDateAlert}
          selectedDate={uiState.selectedDate}
          startDate={uiState.startDate}
          endDate={uiState.endDate}
          type={uiState.calendarType}
        />

        {/* Date Alert */}
        <SimpleDialog
          isOpen={uiState.isDateAlertOpen}
          onPositive={() => {
            uiEvent.CloseCalendar(uiState.calendarType, 0);
            uiEvent.HideDateAlert();
          }}
          onClose={uiEvent.HideDateAlert}
          children={LocalizedText("ua_g421_alert_error_message")}
          positiveText="OK"
        />
      </Box>
    </>
  );
};

export default UsageHistoryRender;
