import { useNavigate } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { MorePageUiState } from "./MorePageUiState";
import { MorePageUiEvent } from "./MorePageUiEvent";
import { MorePageSideEffect } from "./MorePageSideEffect";
import {
  ICON_BUILDING,
  ICON_LOGOUT,
  ICON_PERSON,
  ICON_WALLET,
} from "../../constants/appImagePath";
import MorePageTopToolBar from "./section/MorePageTopToolBar";
import VerticalSpacer from "../../component/Spacer";

import MorePageMenuPart from "./section/MorePageMenuPart";
import MorePageButton from "./section/MorePageButton";
import MorePageBottomVersion from "./section/MorePageBottomVersion";
import IconTextDialog, {
  IconTextDialogType,
} from "../../component/dialog/IconTextDialog";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";

interface MorePageRenderProps {
  uiState: MorePageUiState;
  uiEvent: MorePageUiEvent;
  sideEffect: MorePageSideEffect;
}

const MorePageRender = ({
  uiState,
  uiEvent,
  sideEffect,
}: MorePageRenderProps) => {
  const navigate = useNavigate();
  var logoutUrl = window.location.origin + "/LogoutMobile";
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <MorePageTopToolBar
              onClick={() => {
                sideEffect.navigate("Setting");
              }}
            />
            <VerticalSpacer height={30} />
            <MorePageMenuPart
              iconType={ICON_PERSON}
              content={localStorage.getItem("name") || ""}
              onClick={() => {
                sideEffect.navigate("Profile");
              }}
            />
            <VerticalSpacer height={16} />
            <MorePageButton
              onCarbonReductionNFTClick={() => {
                navigate("/CarbonReductionNFT", {
                  state: {
                    state: {
                      lastPath: "MorePage",
                    },
                  }, // 상태 전달
                });
              }}
              onChargingMembershipClick={() => {
                sideEffect.navigate("ChargingMembership");
              }}
            />
            <VerticalSpacer height={16} />
            <MorePageMenuPart
              iconType={ICON_WALLET}
              content={LocalizedText("ua_g100_wallet")}
              onClick={() => {
                sideEffect.navigate("Wallet");
              }}
            />
            <VerticalSpacer height={16} />
            <Divider />
            <VerticalSpacer height={16} />
            <MorePageMenuPart
              iconType={ICON_BUILDING}
              content={LocalizedText("ua_g100_partners")}
              onClick={() => {
                sideEffect.navigate("Partners");
              }}
            />
            <VerticalSpacer height={16} />
            <MorePageMenuPart
              iconType={ICON_LOGOUT}
              content={LocalizedText("ua_g100_logout")}
              onClick={function () {
                uiEvent.ShowAlertDialog();
              }}
            />
          </Box>
          <Box>
            <MorePageBottomVersion />
            <VerticalSpacer height={16} />
          </Box>
        </Box>
        <IconTextDialog
          title={LocalizedText("ua_g100_alert_logout_title")}
          content={LocalizedText("ua_g100_alert_logout_message")}
          type={IconTextDialogType.WARNING}
          positiveText={LocalizedText("common_alert_confirm_button")}
          negativeText={LocalizedText("common_alert_cancel_button")}
          isOpen={uiState.isShowAlertDialog}
          onPositive={() => {
            console.log("클릭!");
            uiEvent.HideAlertDialog();
            sideEffect.logout();
          }}
          onNegative={() => uiEvent.HideAlertDialog()}
          onClose={() => uiEvent.HideAlertDialog()}
        />
      </Box>
    </>
  );
};

export default MorePageRender;
