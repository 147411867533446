export class VehicleUiState {
  productName: string;
  data: VehicleDataType[];
  modelList: ModelListType[];
  constructor(
    productName: string = "",
    data: VehicleDataType[] = [],
    modelList: ModelListType[] = [],
  ) {
    this.productName = productName;
    this.data = data;
    this.modelList = modelList;
  }
}

export type VehicleDataType = {
  owner: string;
  serialNumber: string;
  lastUpdateDate: number;
  location: locationType;
  plateNumber: string | null;
  renter: string;
  productName: string;
  updater: string | null;
  registerDate: number;
};

export type locationType = {
  lat: number;
  lon: number;
  geohash: string;
  fragment: boolean;
};

export type ModelListType = {
  modelName: string;
};
