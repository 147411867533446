import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMAGE_SCOOTER_02 } from "../../../constants/appImagePath";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { fontStyle } from "../../../theme/Style";
import { MyRideUiEvent } from "../model/MyRideUiEvent";
import { MyRideUiState } from "../model/MyRideUiState";
import { useEffect } from "react";

interface ScooterContractListItemProps {
  contractListItem: any;
  uiEvent?: MyRideUiEvent;
  uiState?: MyRideUiState;
}

const ScooterContractListItem = ({
  contractListItem,
  uiEvent,
  uiState,
}: ScooterContractListItemProps) => {
  const navigate = useNavigate();

  // console.log(contractListItem);

  useEffect(() => {}, [uiState?.refreshAPI]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexFlow: 1,
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src={contractListItem.modelPicUrl}
            style={{
              backgroundColor: "#F2F2F2",
              borderRadius: 10,
              height: "70px",
              width: "70px",
              objectFit: "cover", // 이미지가 박스에 맞게 잘리며 비율 유지
            }}
          />
          <Box
            sx={{
              display: "flex",
              ml: 2,
              flexDirection: "column",
              width: "10%",
              flexGrow: 1,
            }}
          >
            <Typography
              fontStyle={fontStyle.titleL}
              sx={{
                overflow: "hidden",
                width: "100%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis", // 글자가 닿으면 "..."으로 표시
              }}
            >
              {contractListItem.productName}
            </Typography>
            <Box
              color={"#676665"}
              fontWeight={"bold"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
              fontSize={14}
            >
              {LocalizedText("ua_e200_contract")}
              <Typography sx={{}}>
                {LocalizedDate({
                  date: contractListItem.agreementDt,
                  type: DateFormatType.YearMonthDay,
                })}
              </Typography>
            </Box>
          </Box>
          <Button
            sx={{
              color: "#5D38E5",
              minWidth: "18vw",
              backgroundColor: "#F7F6FD",
              "&:hover": {
                backgroundColor: "#F7F6FD", // hover 시 배경색
              },
              borderRadius: 3,
              pl: 1,
              pr: 1,
            }}
            onClick={() => {
              navigate(`/ScooterWating`, {
                state: {
                  state: {
                    productName: contractListItem.productName,
                    agreementDt: LocalizedDate({
                      date: contractListItem.agreementDt,
                      type: DateFormatType.YearMonthDay,
                    }),
                    agreementNumber: contractListItem.agreementNumber,
                    modelPicUrl: contractListItem.modelPicUrl,
                    receiptConfirmation: contractListItem.receiptConfirmation,
                  },
                }, // 상태 전달
              });
            }}
          >
            <Typography fontSize={14} fontWeight={"bold"}>
              {LocalizedText("ua_e200_pickup_button")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ScooterContractListItem;
