import {
  Box,
  ClickAwayListener,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
import { Padding } from "@mui/icons-material";
import VerticalSpacer from "../../component/Spacer";
import HasBatteryReservation from "./section/HasBatteryReservation";
import NoBatteryReservation from "./section/NoBatteryReservation";
import BatteryUsageHistory from "./section/BatteryUsageHistory";
import { ApiService } from "../../restAPI/ApiService";
import dayjs from "dayjs"; // 날짜 처리를 위해 dayjs 사용
import { color } from "../../theme/Color";
import ProgressBar from "../../component/ProgressBar";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import LocalizedDate, { DateFormatType } from "../../component/LocalizedDate";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { fontStyle } from "../../theme/Style";
import CommonTooltip from "../../component/CommonTooltip";

function registerRentedEvent(): void {
  const event = new CustomEvent("dispatchRentedData");
  window.dispatchEvent(event);
}

// window.onload = registerRentedEvent;
// 전역 스코프에 함수 노출
(window as any).dispatchRentedData = registerRentedEvent;

const PWUserAppBatteryRender = () => {
  const [reservationList, setReservationList] = useState<any>([]);
  const [batteryHisData, setBatteryHisData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [progressBar, setProgressBar] = useState(true);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [currentDateIndex, setCurrentDateIndex] = useState(0); // 현재 인덱스 상태 관리
  const [isOverTime, setIsOverTime] = useState(true);
  const handleDimEvent = (event: boolean) => {
    if (window.Android) {
      window.Android.isShownDialog(event);
    }

    if (window.webkit) {
      window.webkit.messageHandlers.isShownDialog.postMessage(event);
    }
  };

  const [user, setUser] = useState({
    userId: localStorage.getItem("uuid") ?? "",
  });
  useAndroidBackHandler(() => {
    if (open == true) {
      setOpen(false);
    } else {
      window.Android.onBackStackCleared();
    }
  }, [open]);
  useEffect(() => {
    const handleRentedApi = () => {
      ApiService.MobilePost("/pw/backend/api/battery/rented", user).then(
        (res: any) => {
          console.log(res.data);
          if (res.data.body == "Not Yet") {
            setIsOverTime(true);
          } else {
            setIsOverTime(false);
            setReservationList(res.data.body);
          }
        },
      );
    };

    window.addEventListener("dispatchRentedData", handleRentedApi);
    return () => {
      window.removeEventListener("dispatchRentedData", handleRentedApi);
    };
  }, []);

  useEffect(() => {
    registerRentedEvent();
  }, []);

  const toggleDrawer = (newOpen: any) => () => {
    setOpen(newOpen);
    // handleDimEvent(newOpen);
  };

  const handleTooltipClose = () => {
    setToolTipOpen(false);
  };

  const handleTooltipOpen = () => {
    setToolTipOpen(true);
  };

  const generateDateData = () => {
    const now = dayjs(); // 현재 날짜
    const dateData = [];

    for (let i = 0; i <= 11; i++) {
      const date = now.subtract(i, "month");
      const startDate = date.startOf("month").valueOf(); // 해당 월의 첫 날 0시 0분 0초
      const endDate = date.endOf("month").valueOf(); // 해당 월의 마지막 날 23시 59분 59초
      dateData.push({
        data: LocalizedDate({
          date: date.valueOf(),
          type: DateFormatType.YearMonth,
        }), // "2023년 5월" 형식으로 출력
        startDate: startDate,
        endDate: endDate,
      });
    }

    return dateData;
  };

  const dateData = generateDateData(); // dateData를 현재 날짜 기준으로 생성

  const DrawerList = (
    <div>
      <style>
        {`
        div::-webkit-scrollbar {
          display: none;
        }
      `}
      </style>
      <Box
        sx={{
          width: "100%",
          height: "51vh",
        }}
      >
        <ListSubheader
          sx={{
            height: 50,
            display: "flex",
            alignItems: "center",
            pt: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography color={"black"} fontWeight={"bold"}>
            {LocalizedText("ua_d200_modal_setperiod")}
          </Typography>
          <Box style={{ marginTop: 12 }} onClick={toggleDrawer(false)}>
            <ClearOutlinedIcon />
          </Box>
        </ListSubheader>
        <List>
          {dateData.map((item, index) => (
            <ListItem key={item.data} disablePadding>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(false)(); // 이 부분 수정
                  setCurrentDateIndex(index);
                }}
              >
                <ListItemText primary={item.data} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );

  const handleNextDate = () => {
    if (currentDateIndex > 0) {
      setCurrentDateIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handlePrevDate = () => {
    if (currentDateIndex < dateData.length - 1) {
      setCurrentDateIndex((prevIndex) => prevIndex + 1);
    }
  };

  useEffect(() => {
    const requestBody = {
      endDate: dateData[currentDateIndex].endDate,
      user: user.userId,
      startDate: dateData[currentDateIndex].startDate,
    };
    setProgressBar(true);
    ApiService.MobilePost("/pw/backend/api/battery/userRentalList", requestBody)
      .then((res: any) => {
        if (res.data.body) {
          setBatteryHisData(res.data.body);
        }
      })
      .then(() => {
        setProgressBar(false);
      });
  }, [currentDateIndex]);

  // console.log("batteryHisData.length is : ", );

  const noHistoryMessage = LocalizedText("ua_d200_nohistory");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {/* 교환 예약 컴포넌트 */}
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "auto",
          }}
        >
          {/* 배터리 교환 예약 판단 */}
          {isOverTime == true ? (
            <NoBatteryReservation />
          ) : (
            <HasBatteryReservation
              reservationList={reservationList}
              setIsOverTime={setIsOverTime}
            />
          )}
        </Box>
        {/* Divider */}
        <Box
          sx={{
            backgroundColor: "#F2F2F2",
            width: "100%",
            height: "1%",
          }}
        >
          &nbsp;
        </Box>
        {/* 이용내역 리스트 */}
        <Box
          sx={{
            width: "91.7%",
            height: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mt: "2vh",
              height: "6vh",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography color={"#999999"} fontSize={15}>
              {LocalizedText("ua_d100_batteryhistory")}
            </Typography>
            <Box sx={{ mt: 0.3, display: "flex", alignItems: "center" }}>
              <ArrowBackIosNewSharpIcon
                fontSize="small"
                sx={{
                  width: 12,
                  color: currentDateIndex === 11 ? "#D3D3D3" : "#676665",
                  cursor: currentDateIndex === 11 ? "default" : "pointer",
                }}
                onClick={handlePrevDate}
              />
              <Typography
                sx={{
                  ml: 1,
                  mr: 1,
                  borderBottom: 1,
                  color: "#676665",
                  height: 1,
                  cursor: "pointer",
                }}
                onClick={toggleDrawer(true)}
              >
                {dateData[currentDateIndex].data}
              </Typography>
              <ArrowForwardIosSharpIcon
                fontSize="small"
                sx={{
                  width: 12,
                  color: currentDateIndex === 0 ? "#D3D3D3" : "#676665",
                  cursor: currentDateIndex === 0 ? "default" : "pointer",
                }}
                onClick={handleNextDate}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mt: -5.5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Box>
                <CommonTooltip
                  title={LocalizedText("ua_d100_infotip_availablehistory")}
                  open={toolTipOpen}
                  onClose={handleTooltipClose}
                >
                  <IconButton onClick={handleTooltipOpen}>
                    <ErrorOutlineSharpIcon sx={{ color: "#999999" }} />
                  </IconButton>
                </CommonTooltip>
              </Box>
            </ClickAwayListener>
          </Box>
          <Box
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            <Box>
              {progressBar ? (
                <Box sx={{ mt: -20 }}>
                  <ProgressBar />
                </Box>
              ) : (
                <Box>
                  {batteryHisData.length > 0 ? (
                    batteryHisData.map((data: any, index: number) => (
                      <BatteryUsageHistory
                        key={index}
                        BatteryUsageHistoryData={data}
                      />
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        fontStyle: fontStyle.bodyMr,
                        color: color.gray400,
                      }}
                    >
                      <VerticalSpacer height={32} />
                      {noHistoryMessage}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Drawer
          open={open}
          onClose={toggleDrawer(false)}
          anchor={"bottom"}
          PaperProps={{
            sx: {
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            },
          }}
        >
          {DrawerList}
        </Drawer>
      </Box>
    </>
  );
};

export default PWUserAppBatteryRender;
