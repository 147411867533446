import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

interface ProductCardProps {
  productType: string;
  title: string;
  price: string;
  description: string;
  tag: string[];
}

const ProductCard = ({
  productType,
  title,
  price,
  description,
  tag,
}: ProductCardProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: color.white,
        borderRadius: "10px",
        border: `1px solid ${color.gray100}`,
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography fontStyle={fontStyle.titleXL} color={color.default}>
          {title}
        </Typography>
        <Typography
          fontStyle={fontStyle.titleXL}
          color={
            productType === "Membership" ? color.primary500 : color.secondary600
          }
        >
          {price}
        </Typography>
      </Box>

      <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
        {description}
      </Typography>

      <Box sx={{ mt: "8px" }}>
        {tag.map((tag, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              borderLeft: `2px solid ${
                productType === "Membership"
                  ? color.primary200
                  : color.secondary400
              }`,
              pl: "8px",
              gap: "4px",
            }}
          >
            <Typography
              fontStyle={fontStyle.subTitleS}
              color={color.gray800}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {tag}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProductCard;
