import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InquiryDetailsRender from "./InquiryDetailsRender"
import { InquiryDetailsUiState } from "./model/InquiryDetailsUiState";
import { InquiryDetailsUiEvent } from "./model/InquiryDetailsUiEvent";
import { InquiryDetailsSideEffect } from "./model/InquiryDetailsSideEffect";
import { InquiryResponse } from "./model/InquiryResponse";
import axios from "axios";

const InquiryDetailsPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>();
  const [uiState, setUiState] = useState<InquiryDetailsUiState>({
    id: "",
    description: "",
    contentMaxLength: 1000,
    uploadedImageUrls: [],
    contract: [],
  });

  useEffect(() => {
    console.log("uiState start");
    console.log(uiState);
    console.log("uiState end");
  }, []);

  const handleUpdateDetailsUiState = (
    key: keyof typeof uiState,
    value: any
  ) => {
    setUiState((prevData) => ({
      ...prevData,
      [key]: value, // 동적으로 속성 업데이트
    }));

    const fileReader = new FileReader();
    fileReader.readAsDataURL(value);
    fileReader.onload = function () {
      let url = (""+fileReader.result).split("base64,")[1];
      uiEvent.addImageUrl(url);
    };
  };

  const uiEvent: InquiryDetailsUiEvent = {
    addImageUrl: (url: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          uploadedImageUrls: [...prev.uploadedImageUrls, url],
        };
      });
    },
    removeImageUrl: (index: number) => {
      setUiState((prev) => {
        return {
          ...prev,
          uploadedImageUrls: prev.uploadedImageUrls.filter(
            (_, i) => i !== index,
          ),
        };
      });
    },
    onChangeId: (value): void =>{
      setUiState((prev) => {
        return {
          ...prev,
          id: value ? value : "",
        };
      });
    },
    onChangeDescription: (value: string): void => {
      setUiState((prev) => {
        return {
          ...prev,
          description: value ? value : "",
        };
      });
    },
  }

  const sideEffect: InquiryDetailsSideEffect = {
    openCamera: function (): void {
      if (window.Android) {
        window.Android.openCameraEvent();
      } else if (window.webkit) {
        window.webkit.messageHandlers.openCamera.postMessage("openCamera");
      } else {
        console.log("web");
      }
    },
    handelAddImageFile: function (event: React.ChangeEvent<HTMLInputElement>): void {
      const uploadedFile = event.target.files?.[0];
      if (uploadedFile) {
        const allowedExtensions = ["png", "jpg", "jpeg", "pdf"];
        const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
          handleUpdateDetailsUiState("contract", uploadedFile);
        } else {
          alert("Only PNG, JPG, and JPEG files are allowed.");
        }
      }
    },
    onClickSubmitButton: () => {
      postInquiry(
        uiState.description,
      )
        .then((res) => {
          if (res.result) {
            window.location.reload();
          } else {
            // TODO 실패 처리
            console.log("failed to post inquiry");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
        });
    },
  };

  async function postInquiry(
    description: string,
  ): Promise<InquiryResponse> {
    const url = "/web/pw/aw/contents/personal/updateAnswer";
    const IMAGE_URL_KEY = "answerImageUrls";
    const headers = {
      "Content-Type": "multipart/form-data;charset=UTF-8",
    };

    const body = {
      id: uiState.id,
      uuid: "123e4567-e89b-12d3-a456-426614174000", // 임시 적용
      answerContent: description,
    };

    try {
      const formData = new FormData();
      formData.append(
        "body",
        new Blob([JSON.stringify(body)], { type: "application/json" }),
      );

      if (uiState.uploadedImageUrls.length === 0) {
        formData.append(IMAGE_URL_KEY, "");
      } else {
        uiState.uploadedImageUrls.forEach((image, index) => {
          const imageFileExtension = "png";
          const imageFileName = `inquiry_image_${index}_${data?.user.id}.${imageFileExtension}`;
          const base64Data = image.replace(/^data:image\/\w+;base64,/, "");
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: `image/${imageFileExtension}`,
          });

          formData.append(IMAGE_URL_KEY, blob, imageFileName);
        });
      }

      const request = await axios.post(url, formData, { headers });
      return request.data as InquiryResponse;
    } catch (e) {
      throw e;
    }
  }

  return (
    <InquiryDetailsRender
      uiState={uiState}
      uiEvent={uiEvent}
      sideEffect={sideEffect}
    />
  );
};

export default InquiryDetailsPage;
