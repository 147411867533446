import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { fontStyle } from "../../../theme/Style";
import { MoreProfileEditPageUiEvent } from "../MoreProfileEditPageUiEvent";
import { color } from "../../../theme/Color";

interface EditBirthDateFieldProps {
  type?: string;
  title?: string;
  Day?: string;
  Month?: string;
  Year?: string;
  dayError?: string;
  monthError?: string;
  yearError?: string;
  uiEvent?: MoreProfileEditPageUiEvent;
}

const EditBirthDateField = ({
  type,
  title,
  Day,
  Month,
  Year,
  dayError,
  monthError,
  yearError,
  uiEvent,
}: EditBirthDateFieldProps) => {
  const [error, setError] = useState("");

  const handleDdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      const num = parseInt(value, 10);
      if (value === "" || (num >= 1 && num <= 31)) {
        uiEvent?.HandleDayChange(value);
        setError("");
      } else {
        setError("DD는 1부터 31 사이여야 합니다.");
      }
    } else {
      setError("숫자를 입력해주세요.");
    }
  };

  const handleMmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      const num = parseInt(value, 10);
      if (value === "" || (num >= 1 && num <= 12)) {
        uiEvent?.HandleMonthChange(value);
        setError("");
      } else {
        setError("MM은 1부터 12 사이여야 합니다.");
      }
    } else {
      setError("숫자를 입력해주세요.");
    }
  };

  const handleYyyyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      uiEvent?.HandleYearChange(value);
      setError(""); // 숫자 입력 시 오류 메시지 제거
    } else {
      setError("숫자를 입력해주세요."); // 숫자 외 입력 시 오류 메시지
    }
  };

  return (
    <>
      <Typography fontStyle={fontStyle.titleS}>{title}</Typography>
      <Box sx={{ display: "flex" }}>
        <Box>
          <Box sx={{ display: "flex" }}>
            <TextField
              variant="standard"
              size="medium"
              value={Day}
              autoComplete="off"
              error={!!dayError}
              onChange={handleDdChange}
              placeholder="DD"
              inputProps={{
                maxLength: 2, // 최대 2자리까지만 입력 가능
                style: { height: "50px", padding: "0px 0px" },
                type: "number", // 숫자만 입력 가능
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: color.primary500, // 호버 시 테두리 색상
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                  },
                  "&:after": {
                    borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
                  },
                },
              }}
            />
            <TextField
              variant="standard"
              size="medium"
              value={Month}
              autoComplete="off"
              error={!!monthError}
              onChange={handleMmChange}
              placeholder="MM"
              sx={{
                ml: "8px",

                "& .MuiInput-root": {
                  "&:before": {
                    // borderBottomColor: color.gray300, // 기본 테두리 색상
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: color.primary500, // 호버 시 테두리 색상
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                  },
                  "&:after": {
                    borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
                  },
                },
              }}
              inputProps={{
                maxLength: 2, // 최대 2자리까지만 입력 가능
                style: { height: "50px", padding: "0px 0px" },
                type: "number", // 숫자만 입력 가능
              }}
            />
            <TextField
              variant="standard"
              size="medium"
              value={Year}
              autoComplete="off"
              error={!!yearError}
              onChange={handleYyyyChange}
              placeholder="YYYY"
              sx={{ ml: "8px" }}
              inputProps={{
                maxLength: 4, // 최대 4자리까지만 입력 가능
                style: { height: "50px", padding: "0px 0px" },
                type: "number", // 숫자만 입력 가능
              }}
            />
          </Box>
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditBirthDateField;
