import CommonList from "../../component/CommonList";
import { CouponSettingsTab } from "./CouponSettingsTab";

const CouponSettingHistory = () => {
  return (
    <CommonList
      title="Coupon Settings"
      tabButton={<CouponSettingsTab />}
    ></CommonList>
  );
};

export default CouponSettingHistory;
