import { Box, Typography } from "@mui/material";
import Toolbar from "../../component/Toolbar";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import VerticalSpacer from "../../component/Spacer";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";

const LoginTermsOfUseDetails = () => {
  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_b121_gnb")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        <VerticalSpacer height={16} />
        <Box
          sx={{
            width: "calc(100% - 40px)",
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: color.primary50,
              borderRadius: "10px",
              minHeight: "46px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontStyle: fontStyle.titleL,
              color: color.primary500,
            }}
          >
            {LocalizedText("ua_g732_policy")}
          </Box>
          <VerticalSpacer height={16} />
          <Typography fontStyle={fontStyle.bodyLr}>
            VERYWORDS Co., Ltd. (hereinafter referred to as the “Company”) has
            established the following personal information processing policy to
            protect users’ personal information and handle complaints related
            thereto quickly and smoothly in accordance with Article 30 of the
            Personal Information Protection Act.
          </Typography>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.titleL}>
            Article 1 (Purpose of collection and use of personal information,
            collection items, collection method)
          </Typography>
          <VerticalSpacer height={16} />
          <Typography fontStyle={fontStyle.titleL}>
            1. Purpose and processing items of personal information
          </Typography>
          <VerticalSpacer height={16} />
          <Typography
            fontStyle={fontStyle.bodyLr}
            sx={{ whiteSpace: "pre-line" }}
          >
            that can identify an individual by name, resident registration
            number, etc. included in the information, or can be easily combined
            with other information even if the information alone cannot identify
            a specific individual. This refers to information that can be
            identified. The personal information the company collects from users
            is as follows. Each item processed by the company as follows is the
            minimum amount of personal information required for the purpose of
            processing personal information. The company does not process each
            item for purposes other than the following, and if the purpose of
            use changes, we will take necessary measures, such as obtaining
            separate consent in accordance with Article 18 of the Personal
            Information Protection Act.
          </Typography>
          <VerticalSpacer height={16} />
        </Box>
      </Box>
    </>
  );
};

export default LoginTermsOfUseDetails;
