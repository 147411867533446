import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import axios from "axios";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import UserScooterHeader from "./component/UserScooterHeader";
import UserScooterInfoCard from "./component/UserScooterInfoCard";
import UserScooterSelectCard from "./component/UserScooterSelectCard";
import { UserDetailsUiState } from "../../UserDetailsUiState";
import { UserDetailsUiEvent } from "../../UserDetailsUiEvent";
import UserScooterRentalCard from "./component/UserScooterRentalCard";
import { ApiService } from "../../../../restAPI/ApiService";
import UserScooterMaintenanceCard from "./component/UserScooterMaintenanceCard";

interface UserScooterRenderProps {
  uiState: UserDetailsUiState;
  uiEvent: UserDetailsUiEvent;
}

const UserScooterRender = ({ uiState, uiEvent }: UserScooterRenderProps) => {
  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/scooter/getOwner").then((res) => {
      uiEvent.HandleUiStateChange("OwnerList", res?.data.body);
    });
  }, []);
  return (
    <>
      <UserScooterHeader uiState={uiState} uiEvent={uiEvent} />

      <UserScooterInfoCard uiState={uiState} uiEvent={uiEvent} />
      {uiState.OwnershipType === "Rental" && (
        <>
          <UserScooterRentalCard uiState={uiState} uiEvent={uiEvent} />
          {uiState.RentalStartDate &&
            uiState.RentalEndDate &&
            uiState.ContractCheck == true &&
            uiState.ResidentCheck == true && (
              <UserScooterSelectCard uiState={uiState} uiEvent={uiEvent} />
            )}
        </>
      )}
      {uiState.OwnershipType == "Possession" &&
        uiState.ContractCheck == true &&
        uiState.ResidentCheck == true && (
          <UserScooterSelectCard uiState={uiState} uiEvent={uiEvent} />
        )}

      {uiState.isHandOver == true && (
        <UserScooterMaintenanceCard uiState={uiState} uiEvent={uiEvent} />
      )}
    </>
  );
};

export default UserScooterRender;
