import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { HorizontalSpacer } from "../../component/Spacer";

export enum PaymentManagementTabType {
  COUPONHISTORY = "couponHistory",
  MEMBERSHIP_VOUCHER = "membership_Voucher",
}

export interface PaymentManagementTabProps {
  currentTab: PaymentManagementTabType;
  changeTab: (tab: PaymentManagementTabType) => void;
}

export function PaymentManagementTab({
  currentTab,
  changeTab,
}: PaymentManagementTabProps) {
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == PaymentManagementTabType.COUPONHISTORY
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(PaymentManagementTabType.COUPONHISTORY);
        }}
      >
        Coupon History
      </Box>
      <HorizontalSpacer width={16} />
      {localStorage.getItem("user_type") == "Partner" ? (
        ""
      ) : (
        <>
          <Divider orientation="vertical" />
          <HorizontalSpacer width={16} />
          <Box
            sx={{
              fontStyle: fontStyle.semiboldM,
              cursor: "pointer",
              color:
                currentTab == PaymentManagementTabType.MEMBERSHIP_VOUCHER
                  ? color.primary500
                  : color.gray600,
            }}
            onClick={() => {
              changeTab(PaymentManagementTabType.MEMBERSHIP_VOUCHER);
            }}
          >
            Membership/Voucher History
          </Box>
        </>
      )}
    </Box>
  );
}
