import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import BatteryMngTable from "./section/BatteryMngTable";

const BatteryMngPage = () => {
  const navigate = useNavigate();
  // 페이지 관련
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);

  // 검색어 관련
  const [searchContents, setSearchContents] = useState("");

  // model Name
  const [model, setModel] = useState("Model");
  const handleModelChange = (event: SelectChangeEvent) => {
    setModel(event.target.value as string);
  };

  // model Name
  const [station, setStation] = useState("Station");
  const handleStationChange = (event: SelectChangeEvent) => {
    setStation(event.target.value as string);
  };

  // model Name
  const [status, setStatus] = useState("Status");
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };
  return (
    <CommonList title="배터리 관리">
      <CommonSearch
        placeHolder={"Scooter S/N, Plate Number, Owner, Renter, Location"}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <Select
            value={model}
            onChange={handleModelChange}
            renderValue={(v) => {
              return v?.length ? v : `Model`;
            }}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={"Model"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Model
            </MenuItem>
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={station}
            onChange={handleStationChange}
            renderValue={(v) => {
              return v?.length ? v : `Country`;
            }}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={"Station"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Station
            </MenuItem>
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={status}
            onChange={handleStatusChange}
            renderValue={(v) => {
              return v?.length ? v : `Country`;
            }}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={"Status"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Status
            </MenuItem>
          </Select>
        </Box>
      </CommonSearch>

      <VerticalSpacer height={16} />
      <BatteryMngTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default BatteryMngPage;
