import { useEffect, useState } from "react";
import CommonSearch from "../../../component/CommonSearch";
import { Box } from "@mui/material";
import { CommonSelect } from "../../../component/CommonSelect";
import VerticalSpacer from "../../../component/Spacer";
import { HeadCell } from "../../../component/CommonTable";
import { RequestManagementControlTable } from "./RequestManagementControlTable";
import { RequestData, RequestList } from "./model/RequestData";

export function RequestManagementColtrol() {
  const [searchContents, setSearchContents] = useState("");
  const [status, setStatus] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<RequestData[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const filteredData = RequestList.filter((item) => {
      return searchContents
        ? item.requestBranch
            .toLowerCase()
            .includes(searchContents.toLowerCase()) ||
            item.branch.toLowerCase().includes(searchContents.toLowerCase())
        : true;
    });
    const startData = page * rowsPerPage;
    const endData = startData + rowsPerPage;
    const pagedata = filteredData.slice(startData, endData);
    setRows(pagedata);
    setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
    setDocCount(filteredData.length);
  }, [page, rowsPerPage, searchContents]);

  return (
    <>
      <CommonSearch
        placeHolder={"search"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              width: "93px",
              height: "42px",
            }}
            menuitems={[
              "All",
              "Request",
              "Checked",
              "Approval",
              "Reject",
              "Done",
            ]}
            selectValue={status}
            onChange={(event) => {
              setStatus(event.target.value);
            }}
            placeholder="Status"
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <RequestManagementControlTable
        headCells={requestManagementControlHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
}

const requestManagementControlHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "requestBranch",
    label: "Request Branch",
  },
  {
    disablePadding: false,
    align: "left",
    id: "branch",
    label: "Branch",
  },
  {
    disablePadding: false,
    align: "left",
    id: "requester",
    label: "Requester",
  },
  {
    disablePadding: false,
    align: "left",
    id: "status",
    label: "Status",
  },
  {
    disablePadding: false,
    align: "left",
    id: "desiredPickupDate",
    label: "Desired Pickup Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registerDate",
    label: "Register Date",
  },
];
