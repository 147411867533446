import { Box } from "@mui/material";
import CommonSearch from "../../../component/CommonSearch";
import { CommonSelect } from "../../../component/CommonSelect";
import { useEffect, useState } from "react";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { ProductionHistoryFilter } from "./ProductionHistoryFilter";
import {
  HistoryFilter,
  HistoryUiState,
} from "./model/ProductionHistoryUiState";
import { CheckboxState } from "../../../component/CommonCheckbox";
import { ProductionHistoryTable } from "./ProductionHistoryTable";
import { HeadCell } from "../../../component/CommonTable";
import {
  ProductionHistoryData,
  ProductionHistoryList,
} from "./model/ProductionHistoryData";

const mockFilters: HistoryFilter[] = [
  new HistoryFilter("Category", CheckboxState.Checked),
  new HistoryFilter("Parts Code", CheckboxState.Checked),
  new HistoryFilter("Product Name", CheckboxState.Checked),
  new HistoryFilter("Spec", CheckboxState.Checked),
  new HistoryFilter("Made", CheckboxState.Checked),
  new HistoryFilter("Quantities", CheckboxState.Checked),
  new HistoryFilter("Amount", CheckboxState.Checked),
  new HistoryFilter("Register", CheckboxState.Checked),
  new HistoryFilter("Updater", CheckboxState.Checked),
  new HistoryFilter("Register Date", CheckboxState.Checked),
  new HistoryFilter("Last Update Date", CheckboxState.Checked),
];

export function ProductionHistory() {
  const [uiState, setUiState] = useState(
    new HistoryUiState(
      mockFilters,
      new HistoryFilter("All", CheckboxState.Checked)
    )
  );

  const [searchContents, setSearchContents] = useState("");
  const [category, setCategory] = useState("");
  const [quantities, setQuantities] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<ProductionHistoryData[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const filteredData = ProductionHistoryList.filter((item) => {
      return searchContents
        ? item.partsCode.toLowerCase().includes(searchContents.toLowerCase()) ||
            item.category.toLowerCase().includes(searchContents.toLowerCase())
        : true;
    });
    const startData = page * rowsPerPage;
    const endData = startData + rowsPerPage;
    const pagedata = filteredData.slice(startData, endData);
    setRows(pagedata);
    setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
    setDocCount(filteredData.length);
  }, [page, rowsPerPage, searchContents]);

  function getAllCheckboxState(filters: HistoryFilter[]): CheckboxState {
    const allChecked = filters.every(
      (filter) => filter.checkState === CheckboxState.Checked
    );
    const noneChecked = filters.every(
      (filter) => filter.checkState === CheckboxState.Unchecked
    );

    const allCheckboxState = allChecked
      ? CheckboxState.Checked
      : noneChecked
      ? CheckboxState.Unchecked
      : CheckboxState.Indeterminate;

    return allCheckboxState;
  }

  return (
    <>
      <CommonSearch
        placeHolder={"Search"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              width: "108px",
              height: "42px",
            }}
            menuitems={["All", "ASSY", "SEMI ASSY"]}
            selectValue={category}
            onChange={(event) => {
              setCategory(event.target.value);
            }}
            placeholder="Category"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{
              width: "117px",
              height: "42px",
            }}
            menuitems={["All", "SET", "EA", "g", "m"]}
            selectValue={quantities}
            onChange={(event) => {
              setQuantities(event.target.value);
            }}
            placeholder="Quantities"
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <ProductionHistoryFilter
        filters={uiState.filters}
        allCheckboxState={uiState.allCheckboxState.checkState}
        onClickAllCheckbox={() => {
          setUiState((prev) => {
            if (prev.allCheckboxState.checkState === CheckboxState.Unchecked) {
              const newFilters = prev.filters.map((it) => {
                return new HistoryFilter(it.title, CheckboxState.Checked);
              });

              return {
                ...prev,
                filters: newFilters,
                allCheckboxState: new HistoryFilter(
                  prev.allCheckboxState.title,
                  CheckboxState.Checked
                ),
              };
            } else {
              const newFilters = prev.filters.map((it) => {
                return new HistoryFilter(it.title, CheckboxState.Unchecked);
              });

              return {
                ...prev,
                filters: newFilters,
                allCheckboxState: new HistoryFilter(
                  prev.allCheckboxState.title,
                  CheckboxState.Unchecked
                ),
              };
            }
          });
        }}
        onClickCheckbox={(filter: HistoryFilter) => {
          setUiState((prev) => {
            const updatedFilters = prev.filters.map((f) => {
              // 클릭한 filter와 동일한 title을 가진 filter를 찾아 체크 상태 변경
              if (f === filter) {
                return new HistoryFilter(
                  f.title,
                  f.checkState === CheckboxState.Checked
                    ? CheckboxState.Unchecked
                    : CheckboxState.Checked
                );
              }
              return f; // 다른 filter는 그대로 유지
            });

            // 상태 업데이트

            return {
              ...prev,
              allCheckboxState: new HistoryFilter(
                prev.allCheckboxState.title,
                getAllCheckboxState(updatedFilters)
              ),
              filters: updatedFilters, // 업데이트된 filters 배열로 상태 변경
            };
          });
        }}
      />
      <VerticalSpacer height={16} />
      <ProductionHistoryTable
        headCells={productionHistoryHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
}

const productionHistoryHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product nave",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "made",
    label: "Made",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  {
    disablePadding: false,
    align: "left",
    id: "amount",
    label: "Amount",
  },
  {
    disablePadding: false,
    align: "left",
    id: "updater",
    label: "Updater",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registrationDate",
    label: "Registration Date",
  },
];
