import { TableRow } from "@mui/material";
import { ProductionManagementCellData } from "../../../model/ProductionManagementCellData";
import { ProductData } from "../model/ProductData";
import { ProductConfirmationListTableCell } from "./ProductConfirmationListTableCell";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import { Text } from "../../../../../component/Text";
import { CommonRadio } from "../../../../../component/CommonRadio";
import { ChangeEvent } from "react";

interface ProductConfirmationListTableRowProps {
  row: ProductData;
  selectValue: string;
  onchange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export function ProductConfirmationListTableRow({
  row,
  selectValue,
  onchange,
}: ProductConfirmationListTableRowProps) {
  const productCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData(`${row.category}`, "left", "120px"),
    new ProductionManagementCellData(`${row.partsCode}`, "left", "200px"),
    new ProductionManagementCellData(`${row.productName}`, "left", "200px"),
    new ProductionManagementCellData(`${row.spec}`, "left"),
  ];
  return (
    <TableRow>
      <ProductConfirmationListTableCell>
        <CommonRadio
          selectValue={selectValue}
          onchange={onchange}
          value={row.partsCode}
        />
      </ProductConfirmationListTableCell>
      {productCellData.map(
        (data: ProductionManagementCellData, index: number) => (
          <ProductConfirmationListTableCell
            key={`${row.partsCode}-${index}`}
            sx={{
              paddingLeft: data.paddingLeft,
              paddingRight: data.paddingRight,
              width: data.width || "auto",
              ...(index === productCellData.length - 1 ? { flex: 1 } : {}),
            }}
            childrenContainerSx={{ width: data.width }}
            align={data.align}
            children={
              <Text
                sx={{
                  fontStyle: fontStyle.regularXS,
                  color: color.gray800,
                }}
                text={data.value}
              />
            }
          />
        )
      )}
    </TableRow>
  );
}
