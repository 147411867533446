import { CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import CommonSearch from "../../../../component/CommonSearch";
import { useState } from "react";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import { MaterialRegistrationFindPartsTable } from "./MaterialRegistrationFindPartsTable";
import { ReceiveDeliverData, RecieveDeliverList } from "../model/RecieveDeliverData";
import { HeadCell } from "../../../../component/CommonTable";

export function MaterialRegistrationFindParts() {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(4);
  const [rows, setRows] = useState<ReceiveDeliverData[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Find Parts</Typography>
      <VerticalSpacer height={16} />
      <CommonSearch
        placeHolder="코드, 품명, 스펙, 업체명, 검색"
        placeholderSx={{ fontStyle: fontStyle.regularS }}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
        sx={{
          width: "100%",
          borderRadius: "10px",
          border: "1px solid",
          borderColor: color.gray300,
          minHeight: "48px",
        }}
      />
      <VerticalSpacer height={16} />
      <MaterialRegistrationFindPartsTable
        headCells={findPartsHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        recieveDeliverList={RecieveDeliverList}
      ></MaterialRegistrationFindPartsTable>
    </CardContent>
  );
}
const findPartsHeadCells: HeadCell[] = [
    {
      disablePadding: false,
      align: "left",
      id: "partsCode",
      label: "Parts Code",
    },
    {
      disablePadding: false,
      align: "left",
      id: "productName",
      label: "Product Name",
    },
    {
      disablePadding: false,
      align: "left",
      id: "spec",
      label: "Spec",
    },
    {
      disablePadding: false,
      align: "left",
      id: "company",
      label: "Company",
    },
    {
      disablePadding: false,
      align: "left",
      id: "category",
      label: "Category",
    },
  ];
  