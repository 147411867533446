import { Box, SxProps, TableCell, Theme } from "@mui/material";

interface MaterialRegistrationFindPartsTableCellProps {
  childrenContainerSx?: SxProps<Theme>;
  children?: JSX.Element;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

export function MaterialRegistrationFindPartsTableCell({
  childrenContainerSx,
  children,
  align,
  sx,
  onClick,
}: MaterialRegistrationFindPartsTableCellProps) {
  return (
    <TableCell
      sx={{
        py: "8px",
        px: "8px",
        ...sx,
      }}
      align={align}
    >
      <Box
        sx={{
          py: 0,
          display: "flex", // flexbox로 변경
          alignItems: "center", // 수직 중앙 정렬
          minHeight: "26px",
          ...childrenContainerSx,
        }}
        onClick={onClick}
      >
        {children}
      </Box>
    </TableCell>
  );
}
