import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import { Text } from "../../../../component/Text";

interface ServiceMngTableRowProps {
  row: any;
}

const ServiceMngTableRow = ({ row }: ServiceMngTableRowProps) => {
  const navigate = useNavigate();

  const serviceTypeStyles: {
    [key: string]: { bgcolor: string; color: string };
  } = {
    Cafe: {
      bgcolor: color.primary100,
      color: color.primary500,
    },
    Station: {
      bgcolor: color.backgroundSuccess,
      color: color.success,
    },
    "CS Center": {
      bgcolor: color.backgroundWarning2,
      color: color.warningWaiting2,
    },
    Hotel: {
      bgcolor: color.backgroundProgress,
      color: color.proceed,
    },
    "Hot Spot": {
      bgcolor: color.backgroundProgress,
      color: color.proceed,
    },
    Restaurant: {
      bgcolor: color.backgroundProgress,
      color: color.proceed,
    },
  };

  const serviceType = serviceTypeStyles[row.svcType];

  return (
    <TableRow
      sx={{ cursor: "pointer" }}
      onClick={() => {
        // navigate(`/inquiryMng/inquiryDetails/${row.id}`);
      }}
    >
      <TableCell align="center" sx={{ height: "52px", py: 0 }}>
        <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
      </TableCell>

      <TableCell align="left" sx={{ height: "52px", py: 0 }}>
        <Box fontStyle={fontStyle.regularXXS} sx={{ width: "auto" }}>
          {serviceType && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
                px: "4px",
              }}
            >
              <Box
                sx={{
                  height: "18px",
                  borderRadius: "5px",
                  bgcolor: serviceType.bgcolor,
                  color: serviceType.color,
                  pt: "1px",
                  px: "8px",
                }}
              >
                {row.svcType}
              </Box>
            </Box>
          )}
        </Box>
      </TableCell>

      <TableCell align="left" sx={{ height: "52px", py: 0 }}>
        <Typography fontStyle={fontStyle.regularXS} sx={{ maxWidth: "107px" }}>
          {row.svcNm}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ height: "52px", py: 0 }}>
        <Text
          sx={{ maxWidth: "107px", fontStyle: fontStyle.regularXS }}
          text={row.svcAreaNm}
        />
      </TableCell>

      <TableCell align="left" sx={{ height: "52px", py: 0 }}>
        <Typography fontStyle={fontStyle.regularXS}>{row.status}</Typography>
      </TableCell>

      <TableCell align="left" sx={{ height: "52px", py: 0 }}>
        <Typography fontStyle={fontStyle.regularXS}>{row.manager}</Typography>
      </TableCell>

      <TableCell align="left" sx={{ height: "52px", py: 0 }}>
        <Box fontStyle={fontStyle.regularXS}>
          {row.ctryCd == "KH" && (
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={"Cambodia"}
            />
          )}
          {row.ctryCd == "KR" && (
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={"South Korea"}
            />
          )}
          {row.ctryCd == "VN" && (
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={"Vietnam"}
            />
          )}
        </Box>
      </TableCell>

      <TableCell align="left" sx={{ height: "52px", py: 0 }}>
        <Box fontStyle={fontStyle.regularXS}>
          {row.lastUptDt == 0 ? (
            <></>
          ) : (
            <>
              {LocalizedDate({
                date: row.lastUptDt,
                type: DateFormatType.AdminType,
              })}
            </>
          )}
        </Box>
      </TableCell>

      <TableCell align="left" sx={{ height: "52px", py: 0 }}>
        <Box fontStyle={fontStyle.regularXS}>
          {LocalizedDate({
            date: row.registerDt,
            type: DateFormatType.AdminType,
          })}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ServiceMngTableRow;
