import * as React from "react";
import { useState } from "react";
import { Box, MenuItem, Select, styled } from "@mui/material";
import { Category } from "../../../MobileSupport/faq/list/model/Category";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";

interface CategorySelectProps {
  currentCategory: Category | undefined;
  categoryList: Category[];
  onChange: (category: string) => void;
  selectValueError?: boolean;
}

const CustomStyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: "4px 8px",
  borderRadius: "10px",
  maxHeight: "38px",
  "&.Mui-selected": {
    backgroundColor: `${color.primary50} !important`, // 선택된 항목 배경색 변경
  },
}));

export default function CategorySelect({
  currentCategory,
  categoryList,
  onChange,
  selectValueError = false,
}: CategorySelectProps) {
  const inputLabel = LocalizedText("ua_f211_select");
  const [isFocused, setIsFocused] = useState(false);
  const borderStyle = `1px solid ${isFocused ? color.primary500 : selectValueError ? color.error : color.gray300}`;

  return (
    <Box sx={{ width: "100%" }}>
      <Select
        sx={{
          width: "100%",
          height: "38px",
          "& .MuiInputBase-input": {
            fontStyle: fontStyle.bodyMr,
            color: color.gray800,
            border: `0px`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: borderStyle,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: borderStyle,
          },
        }}
        value={currentCategory?.label}
        variant="outlined"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        displayEmpty={true}
        renderValue={(selected) => {
          if (selected === undefined) return inputLabel;
          return selected;
        }}
      >
        {categoryList?.map((it, index) => (
          <CustomStyledMenuItem key={index} value={it.label}>
            {it.label}
          </CustomStyledMenuItem>
        ))}
      </Select>
    </Box>
  );
}
