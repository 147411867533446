import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";

interface CommonCardDialogProps {
  children?: ReactNode;
  button?: ReactNode;
  positiveText?: string;
  nagativeText?: string;
  isOpen: boolean;
  onPositive?: () => void;
  onNagative?: () => void;
  onClose: () => void;
  sx?: SxProps<Theme>;
}
const CommonCardDialog = ({
  children,
  button,
  positiveText = "Confirm",
  nagativeText = "Cancel",
  isOpen,
  onPositive,
  onNagative,
  onClose,
  sx,
}: CommonCardDialogProps) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "20px",
          px: "20px",
          pt: "20px",
          pb: "16px",
          // minWidth: {widthVal},
          minHeight: "11%",
          ...sx,
        },
      }}
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && onClose) {
          onClose();
        }
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          padding: "0px",
          pt: "16px",
        }}
      >
        {button}
      </DialogActions>
    </Dialog>
  );
};

export default CommonCardDialog;
