import { TableRow } from "@mui/material";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { Text } from "../../../component/Text";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { MaterialHistoryTableCell } from "./MaterialHistoryTableCell";
import { ProductionManagementCellData } from "../model/ProductionManagementCellData";
import { MaterialHistoryData } from "./model/MaterialHistoryData";

interface MaterialHistoryTableRowProps {
  row: MaterialHistoryData;
}

export function MaterialHistoryTableRow({ row }: MaterialHistoryTableRowProps) {
  const materialHistoryCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData(`${row.no}`, "left", "36px", "24px"),
    new ProductionManagementCellData(`${row.category}`, "left", "61px"),
    new ProductionManagementCellData(`${row.partsCode}`, "left", "94px"),
    new ProductionManagementCellData(`${row.productName}`, "left", "100px"),
    new ProductionManagementCellData(`${row.spec}`, "left", "130px"),
    new ProductionManagementCellData(`${row.quantities}`, "left", "53px"),
    new ProductionManagementCellData(`${row.company}`, "left", "68px"),
    new ProductionManagementCellData(`${row.updater}`, "left"),
    new ProductionManagementCellData(
      LocalizedDate({
        date: row.registrationDate,
        type: DateFormatType.AdminType,
      }),
      "left",
      "120px"
    ),
  ];

  return (
    <TableRow>
      {materialHistoryCellData.map((data: ProductionManagementCellData) => (
        <MaterialHistoryTableCell
          key={data.value}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={data.value}
            />
          }
        />
      ))}
    </TableRow>
  );
}
