import { Box } from "@mui/material";
import VerticalSpacer from "../../component/Spacer";
import Toolbar from "../../component/Toolbar";
import { MoreCarbonReductionNFTSideEffect } from "./MoreCarbonReductionNFTSideEffect";
import { useEffect, useState } from "react";

import NoNFTPage from "./noScooterPage/NoNFTPage";
import { useLocation } from "react-router-dom";
// import HasNFTPage from "./hasNFTPage/HasNFTPage";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import HasNFTPage from "./hasScooterPage/section/hasNFTPage/HasNFTPage";
import { HasNFTUiState } from "./hasScooterPage/section/hasNFTPage/HasNFTUiState";
import { MoreCarbonReductionNFTUiState } from "./MoreCarbonReductionNFTUiState";
import { MoreCarbonReductionNFTUiEvent } from "./MoreCarbonReductionNFTUiEvent";
import PickNFTPage from "./hasScooterPage/PickNFTPage";
import { ApiService } from "../../restAPI/ApiService";
interface MoreProfileRenderProps {
  sideEffect: MoreCarbonReductionNFTSideEffect;
}
const MoreCarbonReductionNFTRender = ({
  sideEffect,
}: MoreProfileRenderProps) => {
  const location = useLocation();
  const { state } = location.state || {}; // 전달된 상태를 받음
  const [maxLevel, setMaxLevel] = useState(false);

  const [uiState, setUiState] = useState(new MoreCarbonReductionNFTUiState());

  useEffect(() => {
    ApiService.BlockchainPost("/nft/getUsersCharacterNft", {
      uuid: localStorage.getItem("uuid"), //이걸로 바꿔야함
    }).then((res) => {
      uiEventHandler.HandleUiStateChange("NFTCharData", res?.data.body);
    });
  }, []);

  const uiEventHandler: MoreCarbonReductionNFTUiEvent = {
    HandleDrawerOpenChange: (event: boolean) => {
      if (window.Android) {
        window.Android.isShownDialog(event);
      }

      if (window.webkit) {
        window.webkit.messageHandlers.isShownDialog.postMessage(event);
      }
      setUiState((prev) => {
        return {
          ...prev,
          drawerOpen: event,
        };
      });
    },
    HandleUiStateChange: (
      key: keyof MoreCarbonReductionNFTUiState,
      value: any
    ) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };

  useAndroidBackHandler(() => {
    if (uiState.drawerOpen == true) {
      uiEventHandler.HandleDrawerOpenChange(false);
    } else {
      if (window.Android) {
        window.history.back();
      }
    }
  }, [uiState]);

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g300_carbonnft")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "scroll",
            scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
            "&::-webkit-scrollbar": {
              display: "none", // Webkit 브라우저에서    스크롤바 숨기기
            },
          }}
        >
          {state.lastPath == "LoginFinishPage" ? (
            <PickNFTPage
              parentUiState={uiState}
              parentUiEvent={uiEventHandler}
              lastPath={state.lastPath}
            />
          ) : // hasNFT가 false인 경우
          maxLevel ? (
            <PickNFTPage
              parentUiState={uiState}
              parentUiEvent={uiEventHandler}
              lastPath={state.lastPath}
            />
          ) : (
            // hasScooter가 false인 경우
            <HasNFTPage
              parentUiState={uiState}
              parentUiEvent={uiEventHandler}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default MoreCarbonReductionNFTRender;
// : hasNFT ? (
//   // hasNFT가 true인 경우 렌더링되는 내용
//   <HasNFTPage
//     parentUiState={uiState}
//     parentUiEvent={uiEventHandler}
//   />
// )
