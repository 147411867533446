import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ICON_RIGHT_ARROW } from "../../../constants/appImagePath";

interface SupportButtonHeaderProps {
  title: string;
  subTitle: string;
  onClick: () => void;
}

export default function SupportButtonHeader({
  title,
  subTitle,
  onClick,
}: SupportButtonHeaderProps) {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Typography
        sx={{
          flexGrow: 1,
          fontStyle: fontStyle.titleL,
          color: color.gray900,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <Typography
          sx={{
            fontStyle: fontStyle.subTitleM,
            color: color.gray700,
          }}
        >
          {subTitle}
        </Typography>
        <img
          src={ICON_RIGHT_ARROW}
          alt=""
          style={{
            width: "24px",
            height: "24px",
          }}
        />
      </Box>
    </Box>
  );
}
