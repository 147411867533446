import { Box, Typography, Tab, Tabs } from "@mui/material";
import Toolbar from "../../component/Toolbar";
import WalletCard from "./section/WalletCard";
import { useEffect, useState } from "react";
import { fontStyle } from "../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { color } from "../../theme/Color";
import { MoreWalletPageSideEffect } from "./MoreWalletPageSideEffect";
import { MoreWalletPageUiState } from "./MoreWalletPageUiState";
import { MoreWalletPageUiEvent } from "./MoreWalletPageUiEvent";
import ToastAlert from "../../component/ToastAlert";
import VCDetailDialog from "./section/VCDetailDialog";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import CommonTabs from "../../component/CommonTabs";
import NFTSection from "./section/NFTSection";
import VCSection from "./section/VCSection";

interface MoreWalletRenderProps {
  sideEffect: MoreWalletPageSideEffect;
  uiState: MoreWalletPageUiState;
  uiEvent: MoreWalletPageUiEvent;
}

const MoreWalletRender = ({
  sideEffect,
  uiState,
  uiEvent,
}: MoreWalletRenderProps) => {
  const tabs = [
    { value: "NFTs", label: LocalizedText("ua_g500_nfts") },
    { value: "VC", label: LocalizedText("ua_g500_vc") },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Toolbar
          title={LocalizedText("ua_g500_wallet")}
          onBack={() => {
            sideEffect.navigate("morepage");
          }}
        />

        <VerticalSpacer height={12} />

        {/* Wallet Card */}
        <Box
          sx={{
            width: "calc(100% - 40px)",
          }}
        >
          <WalletCard
            walletInfo={uiState.userWallet}
            onCopyAddress={uiEvent.CopyText}
          />
        </Box>

        {/* Tabs */}
        <CommonTabs
          currentTab={uiState.currentTab}
          tabs={tabs}
          onChange={uiEvent.HandleTabChange}
          width="100%"
        />

        {/* Tab Content - Scroll*/}

        {/** NFTs **/}
        {uiState.currentTab === "NFTs" && (
          <NFTSection nfts={uiState.nfts} onNavigate={sideEffect.navigate} />
        )}

        {/** VC **/}
        {uiState.currentTab === "VC" && (
          <VCSection
            certificates={uiState.certificates}
            onNavigate={sideEffect.navigate}
            onShowVCDialog={uiEvent.ShowVCDialog}
          />
        )}

        {/* Toast Alert */}
        <ToastAlert
          open={uiState.isToastOpen}
          onClose={uiEvent.CloseToast}
          message={LocalizedText("common_toast_copied")}
          bottomOffset={16}
        />

        {/* VC Detail Dialog */}
        {uiState.isShowErrorDialog && (
          <Box>
            <SimpleDialog
              children={LocalizedText("ua_g520_alert_failed_message")}
              positiveText="Try again"
              nagativeText="Cancel"
              isOpen={uiState.isShowErrorDialog}
              onPositive={() => {
                if (uiState.selectedVC) {
                  uiEvent.ShowVCDialog(uiState.selectedVC);
                }
              }}
              onNagative={uiEvent.HideVCErrorDialog}
              onClose={uiEvent.HideVCErrorDialog}
            />
          </Box>
        )}

        {uiState.vcDetail && (
          <VCDetailDialog
            userInfo={uiState.userWallet}
            isOpen={uiState.isShowVCDialog}
            onClose={uiEvent.HideVCDialog}
            certificate={uiState.vcDetail}
          />
        )}
      </Box>
    </>
  );
};

export default MoreWalletRender;
