import React, { createContext, useState, ReactNode } from "react";


export const MonitoringContext = createContext<any | null>(null);

const MonitoringProvider = ({ children }: { children: any }) => {

  const [tmpBaseline, setTmpBaseline] = useState<Array<any>>([]);
  const [tmpProject, setTmpProject] = useState<Array<any>>([]);

  return <MonitoringContext.Provider
      value={{
        tmpBaseline: tmpBaseline,
        tmpProject: tmpProject,
        setTmpBaseline: setTmpBaseline,
        setTmpProject: setTmpProject,
      }}
  >{children}</MonitoringContext.Provider>;
};

export default MonitoringProvider;
