import { TableRow } from "@mui/material";
import { RequestManagementControlTableCell } from "./RequestManagementControlTableCell";
import { Text } from "../../../component/Text";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";

interface RequestManagementTableRowProps {
  row?: any;
}

export function RequestManagementControlTableRow({
  row,
}: RequestManagementTableRowProps) {
  return (
    <TableRow>
      <RequestManagementControlTableCell
        sx={{ paddingLeft: "24px" }}
        childrenContainerSx={{ width: "36px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.no}
          />
        }
        align={"center"}
      />
      <RequestManagementControlTableCell
        childrenContainerSx={{ width: "161.67px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.requestBranch}
          />
        }
        align={"center"}
      />
      <RequestManagementControlTableCell
        childrenContainerSx={{ width: "161.67px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.branch}
          />
        }
        align={"center"}
      />
      <RequestManagementControlTableCell
        childrenContainerSx={{ width: "161.67px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.requester}
          />
        }
        align={"center"}
      />
      <RequestManagementControlTableCell
        childrenContainerSx={{ width: "79px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.status}
          />
        }
        align={"center"}
      />
      <RequestManagementControlTableCell
        childrenContainerSx={{ width: "120px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={LocalizedDate({
              date: row.desiredPickupDate,
              type: DateFormatType.AdminType,
            })}
          />
        }
      />
      <RequestManagementControlTableCell
        childrenContainerSx={{ width: "120px" }}
        sx={{ paddingRight: "24px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={LocalizedDate({
              date: row.registerDate,
              type: DateFormatType.AdminType,
            })}
          />
        }
      />
    </TableRow>
  );
}
