import { Box, Typography } from "@mui/material";
import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../../../../component/CommonTable";
import { MaterialsData } from "../../../model/MaterialsData";
import { fontStyle } from "../../../../../../theme/Style";
import { color } from "../../../../../../theme/Color";
import { DetailsFindPartsTableRow } from "./DetailsFindPartsTableRow";

interface DetailsFindPartsTableProps {
  headCells: HeadCell[];
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pageCount: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  docCount: number;
  materialsList: MaterialsData[];
}

export function DetailsFindPartsTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  setPage,
  materialsList,
}: DetailsFindPartsTableProps) {
  const { order, orderBy, handleChangePage, handleRequestSort, visibleRows } =
    useVisibleRows(materialsList, rowsPerPage || 6, setPage, page || 0);
  const pagedata: any = visibleRows;
  return (
    <Box>
      <CommonTable
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        page={page}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        paginationEnabled={false}
      >
        {pagedata.map((data: MaterialsData) => (
          <DetailsFindPartsTableRow row={data} key={data.partsCode} />
        ))}
        </CommonTable>
        {pagedata?.length == 0 ? (
          <Typography
            sx={{
              textAlign: "center",
              fontStyle: fontStyle.regularXS,
              color: color.gray600,
            }}
          >
            No Data
          </Typography>
        ) : null}
    </Box>
  );
}
