import ProgressBar from "../../component/ProgressBar";
import { SERVER_CONFIG } from "../../constants/ServerConfig";
import Keycloak from "keycloak-js";

const LoginMobilePage = () => {
  const initOptions = {
    url: SERVER_CONFIG.KEYCLOAK_URL,
    realm: SERVER_CONFIG.PW_USER_APP_REALM,
    clientId: SERVER_CONFIG.PW_USER_APP_CLIENT_ID,
    onLoad: SERVER_CONFIG.PW_USER_APP_ONLOAD,
  };
  var keycloak = new Keycloak(initOptions);

  keycloak
    .init({})
    .then(function (authenticated) {
      var path = window.location.pathname.toLowerCase();
      console.log("Logout start - path : " + path);

      if(0 === path.indexOf("/logoutmobile/logout")){
        console.log("go to loginMobile");

        const location = localStorage.getItem("LOCALE_STORAGE_KEY");
        localStorage.clear();
        if(location){
          localStorage.setItem("LOCALE_STORAGE_KEY", location);
        }
        handleLogout();
      }else{
        console.log("go to logoutMobile");
        keycloak.logout({
          redirectUri: SERVER_CONFIG.FRONT_URL + "/logoutMobile/logout"
        });
      }
    })
    .catch(function () {
      console.log("Failed to initialize Keycloak");
      window.location.href = SERVER_CONFIG.FRONT_URL + "/loginMobile";
    });

  function handleLogout(){
    if (window.Android) {
      console.log("[Success Logout][Android]");
      window.Android.onRestartApp();
    } else if (window.webkit) {
      console.log("[Success Logout][iPhone]");
      window.webkit.messageHandlers.onRestartApp.postMessage("restartApp");
    } else {
      console.log("[Success Logout][Web]");
      window.location.href = SERVER_CONFIG.FRONT_URL + "/loginMobile";
    }
  };

  return (
    <div className="App">
      <ProgressBar />
    </div>
  );
};

export default LoginMobilePage;
