import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import { RegionManagement } from "./section/RegionMng/RegionManagement";
import {
  RegionAndServiceManagementTab,
  RegionAndServiceManagementTabType,
} from "./section/RegionAndServiceManagementTab";
import { useEffect, useState } from "react";
import { RegionAndServiceUiState } from "./model/RegionAndServiceUiState";
import { RegionAndServiceUiEvent } from "./model/RegionAndServiceUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import ServiceMngRender from "./section/ServiceMng/ServiceMngRender";

const LocationAndServiceManagement = () => {
  const navigate = useNavigate();

  // localStorage에서 currentTab 값을 불러오기 (없으면 기본값 설정)
  const storedTab = sessionStorage.getItem("currentTab") || 0;

  const [currentTab, setCurrentTab] = useState<any>(storedTab);

  // currentTab 값이 변경될 때마다 localStorage에 문자열로 저장
  useEffect(() => {
    // 값 저장 전에 확인
    sessionStorage.setItem("currentTab", currentTab.toString()); // 문자열로 저장
  }, [currentTab]);

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 110,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          if (
            currentTab == RegionAndServiceManagementTabType.REGION_MANAGEMENT
          ) {
            navigate("/createRegionMng");
          } else if (
            currentTab == RegionAndServiceManagementTabType.SERVICE_MANAGEMENT
          ) {
            navigate("/createServiceMng");
          }
        }}
      >
        <AddIcon fontSize="small" />
        Create
      </Button>
    );
  };

  const [uiState, setUiState] = useState(new RegionAndServiceUiState());
  const uiEvent: RegionAndServiceUiEvent = {
    HandleUiStateChange: (key: keyof RegionAndServiceUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };

  return (
    <CommonList
      title="Area and Service"
      tabButton={
        <RegionAndServiceManagementTab
          currentTab={currentTab}
          changeTab={(tab) => setCurrentTab(tab)}
        />
      } //추후 tab에 맞는 table 추가 (서비스관리, 지역이력, 서비스이력)
      headerButton={HeaderButton()}
    >
      {currentTab == RegionAndServiceManagementTabType.REGION_MANAGEMENT && (
        <RegionManagement uiState={uiState} uiEvent={uiEvent} />
      )}
      {currentTab == RegionAndServiceManagementTabType.SERVICE_MANAGEMENT && (
        <ServiceMngRender uiState={uiState} uiEvent={uiEvent} />
        // <RegionManagement uiState={uiState} uiEvent={uiEvent} />
      )}
    </CommonList>
  );
};

export default LocationAndServiceManagement;
