import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import { color } from "../../../../../theme/Color";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { useState } from "react";
import { ICON_NOIMAGE } from "../../../../../constants/imagePath";
import CommonSingleLineTextField from "../../../../../component/CommonSingleLineTextField";
import { ProductData } from "../model/ProductData";
import CommonTextField from "../../../../../component/CommonTextField";

export function SelectedProduct() {
  const [select, setSelect] = useState("");
  // TODO adminType 지정 필요
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Product</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex", flexDirection: "columm" }}>
        <Box sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Serial Number
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height:"20px"} }}
            disabled
          />
        </Box>
        <HorizontalSpacer width={28} />
        <Box sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Made
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            paddingBottom={14}
            paddingTop={14}
            height={20}
            sx={{
              minHeight: "48px",
              flexGrow: 1,
            }}
            menuitems={["Select"]}
            selectValue={select}
            onChange={(event) => {
              setSelect(event.target.value);
            }}
            placeholder="Select"
          />
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Image
          </Typography>
          <VerticalSpacer height={8} />
          <Box
            sx={{
              paddingLeft: "181px",
              paddingRight: "180px",
              paddingTop: "127px",
              paddingBottom: "121px",
              borderRadius: "10px",
              height: "324px",
              background: color.gray200,
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "113px",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={ICON_NOIMAGE}
                sx={{ width: "40px", height: "40px", padding: "4px" }}
              ></Box>
              <Typography fontStyle={fontStyle.semiboldS} color={color.gray600}>
                No Image
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
}
