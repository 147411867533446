import { useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import SwiperPhotos from "./SwiperPhotos";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import {
  ICON_BATTERY2,
  ICON_CALENDAR,
  ICON_DOWN_ARROW,
  ICON_PEN,
  ICON_PHONE2,
  ICON_PHONE3,
} from "../../../constants/appImagePath";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { PWUserAppMapUiEvent } from "../PWUserAppMapUiEvent";
import { APP_CONFIG } from "../../../constants/AppConfig";

interface DrawerListCSCenterProps {
  data?: any;
  setDetailsOpen?: any;
  uiEvent: PWUserAppMapUiEvent;
}

const DrawerListCSCenter = ({
  data,
  setDetailsOpen,
  uiEvent,
}: DrawerListCSCenterProps) => {
  const navigate = useNavigate();

  const [collapseOpen, setCollapseOpen] = useState(false);

  const toggleSetCollapseOpen = () => {
    setCollapseOpen(!collapseOpen);
  };

  const dayMapping: any = {
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
  };

  // const shortenedDays = data.dayOff
  //   .map((day: any) => dayMapping[day]) // 요일 이름을 매핑 테이블에서 찾아서 변환
  //   .join(", "); // 콤마와 공백으로 연결

  const week = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const arrayDifference = (arr: string[]) => {
    let res: string = " ";
    for (let i = 0; i < arr.length; ++i) {
      if ("X" === arr[i]) {
        if (0 < i) res += " ";
        res += week[i];
      }
    }
    return res;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <VerticalSpacer height={12} />
      <Box sx={{ display: "flex" }}>
        <div
          style={{ width: "101px" }}
          onClick={() => {
            setDetailsOpen(true);
            if (data.svcImage) {
              uiEvent.SetDetailsSvcImageData(data.svcImage);
            } else {
              uiEvent.SetDetailsOpen(false);
              uiEvent.SetDetailsSvcImageData(null);
            }
          }}
        >
          <SwiperPhotos
            imgData={data.svcImage}
            type={APP_CONFIG.CS}
          />
        </div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            ml: 1,
          }}
        >
          <Typography fontStyle={fontStyle.titleL}>{data.svcNm}</Typography>
          <Box
            fontStyle={fontStyle.bodyMr}
            color={color.gray500}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              fontStyle={fontStyle.bodyMr}
              color={data.svcStt == "OPEN" ? color.progress : color.gray700}
            >
              {data.svcStt === "OPEN" && <>{LocalizedText("ua_c300_open")}</>}
              {data.svcStt !== "OPEN" && <>{LocalizedText("ua_c300_close")}</>}
            </Typography>
            &nbsp;|&nbsp;{data.openTime}-{data.closeTime}
            <Box
              component="img"
              src={ICON_DOWN_ARROW}
              onClick={toggleSetCollapseOpen}
              style={{
                transform: collapseOpen ? "rotate(180deg)" : "rotate(0deg)", // 아이콘 회전 애니메이션
              }}
            />
          </Box>
          <Collapse in={collapseOpen} timeout="auto">
            <Box sx={{ display: "flex" }}>
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray500}>
                Holiday
              </Typography>
              <HorizontalSpacer width={6} />
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                {/* arrayDifference(data.dayOff) */}
                {/* {shortenedDays} */}
              </Typography>
            </Box>
          </Collapse>
          <Box
            fontStyle={fontStyle.bodyMr}
            color={color.gray700}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              // justifyContent: "space-between",
            }}
          >
            <Box component="img" src={ICON_PHONE2} />
            <HorizontalSpacer width={4} />
            <Typography fontStyle={fontStyle.bodyMr}>{data.contact}</Typography>

            <HorizontalSpacer width={4} />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{
                width: "48.5%",
                backgroundColor: "#EBEBEB",
                borderRadius: 10,
                height: 30,
                "&:hover": {
                  backgroundColor: "#EBEBEB", // hover 시 배경색
                },
              }}
            >
              <Box
                fontSize={15}
                color={"#4D4C4C"}
                fontWeight={"bold"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textTransform: "none",
                }}
                onClick={() => {
                  window.location.href = `tel:${data.contact}`; // 해당 CS센터로 전화
                }}
              >
                <Box component="img" src={ICON_PHONE3} />
                <HorizontalSpacer width={4} />
                {LocalizedText("ua_c300_call")}
              </Box>
            </Button>
            <Button
              sx={{
                width: "48.5%",
                backgroundColor: "#EBEBEB",
                borderRadius: 10,
                height: 30,
                "&:hover": {
                  backgroundColor: "#EBEBEB", // hover 시 배경색
                },
              }}
            >
              <Box
                fontSize={15}
                color={"#4D4C4C"}
                fontWeight={"bold"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/cs_list");
                }}
              >
                <Box component="img" src={ICON_PEN} />
                <HorizontalSpacer width={4} />
                CS
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
      <VerticalSpacer height={12} />
    </Box>
  );
};

export default DrawerListCSCenter;
