import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import {
  SpecificationHistoryData,
  SpecificationHistoryList,
} from "./model/SpecificationHistoryData";
import { SpecificationHistoryTableRow } from "./SpecificationHistoryTableRow";

interface SpecificationHistoryTableProps {
  headCells: HeadCell[];
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pageCount: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  docCount: number;
}

export function SpecificationHistoryTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: SpecificationHistoryTableProps) {
  const { order, orderBy, handleChangePage, handleRequestSort, visibleRows } =
    useVisibleRows(
      SpecificationHistoryList,
      rowsPerPage || 10,
      setPage,
      page || 0
    );
  const pagedata: SpecificationHistoryData[] = visibleRows
    .slice(
      (page || 0) * (rowsPerPage || 10),
      ((page || 0) + 1) * (rowsPerPage || 10)
    )
    .map((row) => {
      return new SpecificationHistoryData(
        Number(row.no),
        row.parent as string,
        row.category as string,
        row.partsCode as string,
        row.productName as string,
        row.spec as string,
        row.company as string,
        row.updater as string,
        Number(row.lastUpdateDate)
      );
    });

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      rowsPerPage={rowsPerPage}
      pageCount={pageCount}
      setRowsPerPage={setRowsPerPage}
      handleChangePage={handleChangePage}
      handleRequestSort={handleRequestSort}
      setPage={setPage}
    >
      {pagedata.map((data: SpecificationHistoryData) => (
        <SpecificationHistoryTableRow row={data} key={data.no} />
      ))}
    </CommonTable>
  );
}
