import { Box, Grid } from "@mui/material";
import { color } from "../../../theme/Color";
import { ICON_ADD } from "../../../constants/imagePath";
import { CouponManagementCard } from "./CouponManagementCard";
import { CouponManagementUiState } from "../model/CouponManagementUiState";
import { CouponManagementUiEvent } from "../model/CouponManagementUiEvent";
import { useEffect, useState } from "react";
import CommonDialog from "../../../component/CommonDialog";
import { fontStyle } from "../../../theme/Style";

interface CouponManagementGridProps {
  rows: any;
  countryLists: any;
  uiState: CouponManagementUiState;
  uiEvent: CouponManagementUiEvent;
}

export function CouponManagementGrid({
  rows = [],
  countryLists = [],
  uiState,
  uiEvent,
}: CouponManagementGridProps) {
  const [createCard, setCreateCard] = useState(false);
  const [originalData, setOriginalData] = useState(rows || []);
  const [changeDialogOpen, setChangeDialogOpen] = useState(false);
  const handleCancel = () => {
    if (createCard) {
      // 생성 화면에서 생성 취소 -> 마지막으로 추가된 CouponDataRows 항목 삭제
      const updatedRows = uiState.couponDataRows.slice(0, -1);
      uiEvent.HandleUiStateChange("couponDataRows", updatedRows);
      uiEvent.HandleUiStateChange("inValidDataCheck", {
        name: false,
        country: false,
        type: false,
        availableDays: false,
        description: false,
      });
      setCreateCard(false);
    } else {
      // 수정 화면에서 수정 취소 -> EditCardId 초기화
      uiEvent.HandleUiStateChange("editCardId", ""); // 수정 모드 해제
      uiEvent.HandleUiStateChange("inValidDataCheck", {
        name: false,
        country: false,
        type: false,
        availableDays: false,
        description: false,
      });
    }
  };
  const countryCodeMap: {
    KR: string;
    KH: string;
    VN: string;
  } = {
    KR: "South Korea",
    KH: "Cambodia",
    VN: "Vietnam",
  };
  const handleEditCard = (id: string) => {
    // 기존에 새로 생성된 카드가 있으면 삭제
    const updatedRows = uiState.couponDataRows.filter((row) => row.id !== "");
    uiEvent.HandleUiStateChange("couponDataRows", updatedRows); // 기존 새로 생성된 카드 삭제

    // 수정할 카드의 id 설정
    uiEvent.HandleUiStateChange("editCardId", id);
    setCreateCard(false); // 새로운 카드 비활성화
  };

  const handleClose = () => {
    setChangeDialogOpen(false);
  };

  const handleOk = () => {
    setChangeDialogOpen(false);
    window.location.reload();
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {rows.map((data: any, index: any) => {
        return (
          <CouponManagementCard
            key={index}
            data={data}
            countryLists={countryLists}
            uiState={uiState}
            uiEvent={uiEvent}
            createCard={data.id === ""}
            handleCancel={handleCancel}
            countryCodeMap={countryCodeMap}
            handleEdit={handleEditCard}
            createState={createCard}
            originalData={originalData}
            setOriginalData={setOriginalData}
          />
        );
      })}
      <Grid item lg={4}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            minWidth: "330px",
            height: "100%",
            minHeight: "576px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "80px",
              height: "80px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: color.primary500,
              borderRadius: "80px",
              cursor: "pointer",
              ":hover": {
                backgroundColor: color.primary300,
                "& .hover-content": {
                  opacity: 1, // 호버 시 보이도록
                  visibility: "visible", // 호버 시 보이도록
                },
              },
            }}
            onClick={() => {
              if (uiState.editCardId === "") {
                setCreateCard(true);
                handleCancel();
                const isIdEmptyExists = uiState.couponDataRows.some(
                  (row) => row.id === ""
                );
                if (!isIdEmptyExists) {
                  uiEvent.HandleUiStateChange("couponDataRows", [
                    ...uiState.couponDataRows, // 기존 배열 데이터 유지
                    {
                      country: "Select",
                      name: "",
                      type: "Select",
                      availableDays: "",
                      description: "",
                      id: "",
                      uuid: localStorage.getItem("user_uuid"),
                    }, // 추가할 새로운 데이터
                  ]);
                } else {
                  alert("이전 카드를 Post 하십시오");
                }
              } else {
                setChangeDialogOpen(true);
              }
            }}
          >
            <Box component="img" src={ICON_ADD} />
          </Box>
        </Box>
      </Grid>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={changeDialogOpen}
        handleOk={handleOk}
        handleClose={handleClose}
      >
        Do you want to stop editing and move?
        <br />
        The current content will not be saved.
      </CommonDialog>
    </Grid>
  );
}
