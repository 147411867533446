import { CustomerSupportDetailUiState } from "./CustomerSupportDetailUiState";
import { CustomerSupportDetailUiEvent } from "./CustomerSupportDetailUiEvent";
import { CustomerSupportDetailSideEffect } from "./CustomerSupportDetailSideEffect";
import { Box, Drawer, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import {
  ICON_LEFT_ARROW,
  ICON_RECEIPT,
  ICON_RIGHT_ARROW_GRAY600,
  ICON_TRASH_GRAY,
} from "../../../constants/appImagePath";
import { CSDetailHeader } from "./section/CSDetailHeader";
import { HorizontalGrayDivider } from "../../../component/Divider";
import { CSDetailInfo } from "./section/CSDetailInfo";
import ReceiptDrawer from "./section/ReceiptDrawer";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { CSHistoryStatus } from "../list/model/CSHistoryStatus";
import IconTextDialog, {
  IconTextDialogType,
} from "../../../component/dialog/IconTextDialog";
import ToastAlert from "../../../component/ToastAlert";

interface CustomerSupportDetailRenderProps {
  uiState: CustomerSupportDetailUiState;
  uiEvent: CustomerSupportDetailUiEvent;
  sideEffect: CustomerSupportDetailSideEffect;
}

const CustomerSupportDetailRender = ({
  uiState,
  uiEvent,
  sideEffect,
}: CustomerSupportDetailRenderProps) => {
  let billingHistoryStr = LocalizedText("ua_f312_billinghistory");

  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-fraction-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-bullet-horizontal-gap"?: string;
    height?: string;
  };

  // TODO indicator bullet color와 위치 변경
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "#fff",
    "--swiper-pagination-top": "90%",
    width: "100%",
    height: "100%", // Swiper가 Box의 크기를 채우도록 설정
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
        }}
      >
        {/* Toolbar */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "52px",
            px: "20px",
            boxSizing: "border-box",
          }}
        >
          <img
            style={{
              display: "flex",
              width: "24px",
              height: "24px",
            }}
            src={ICON_LEFT_ARROW}
            alt="Back"
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontStyle={fontStyle.titleL}
            color={color.default}
            style={{
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {LocalizedText("ua_f310_cs")}
          </Typography>
          <img
            style={{
              display: "flex",
              width: "24px",
              height: "24px",
            }}
            src={ICON_TRASH_GRAY}
            alt="Delete"
            onClick={uiEvent.openDeleteDialog}
          />
        </Box>

        {/* Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            px: "20px",
            boxSizing: "border-box",
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <CSDetailHeader
            status={uiState.csDetailData?.status ?? CSHistoryStatus.RESERVATION}
            createdDate={uiState.csDetailData?.registrationDate ?? 0}
            title={uiState.csDetailData?.title ?? ""}
          />

          <VerticalSpacer height={8} />
          <HorizontalGrayDivider />
          <VerticalSpacer height={16} />

          <CSDetailInfo
            vin={uiState.csDetailData?.vin ?? ""}
            station={uiState.csDetailData?.preferredStation ?? ""}
            stationAddress={uiState.csDetailData?.preferredStationAddress ?? ""}
            date={uiState.csDetailData?.preferredDate ?? 0}
            openToast={uiEvent.openToast}
          />

          <VerticalSpacer height={16} />
          <HorizontalGrayDivider />
          <VerticalSpacer height={16} />

          {/* Billing History */}
          {uiState.csDetailData?.status === CSHistoryStatus.COMPLETE && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: color.primary50,
                py: "16px",
                pl: "16px",
                pr: "8px",
                borderRadius: "10px",
                boxSizing: "border-box",
              }}
              onClick={uiEvent.openReceipt}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <img
                  src={ICON_RECEIPT}
                  alt="Receipt"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Typography fontStyle={fontStyle.titleM} color={color.default}>
                  {billingHistoryStr}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography
                  fontStyle={fontStyle.titleL}
                  color={color.primary500}
                  textAlign="right"
                >
                  $ {(uiState.csReceipt?.totalFee ?? 0).toLocaleString()}
                </Typography>
                <img
                  src={ICON_RIGHT_ARROW_GRAY600}
                  alt="Arrow"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </Box>
            </Box>
          )}
          <VerticalSpacer height={16} />

          {/* Content */}
          <Typography
            sx={{
              fontStyle: fontStyle.bodyMr,
              color: color.default,
              whiteSpace: "pre-line",
            }}
          >
            {uiState.csDetailData?.content ?? ""}
          </Typography>

          <VerticalSpacer height={16} />

          {uiState.csDetailData?.imageUrls &&
            uiState.csDetailData.imageUrls.length > 0 && (
              <Box
                sx={{
                  borderRadius: "10px",
                  width: "100%",
                  aspectRatio: "1/1",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Swiper
                  pagination={{
                    type: "bullets",
                    clickable: true,
                  }}
                  style={swiperStyles}
                  modules={[Pagination]}
                >
                  {uiState.csDetailData?.imageUrls.map((imageUrl, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img
                          src={imageUrl}
                          alt="Example Icon"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // 이미지가 박스 크기에 맞게 조정
                            objectPosition: "center", // 이미지가 박스 내 중앙에 위치
                          }}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Box>
            )}
        </Box>
      </Box>

      {uiState.csReceipt && (
        <Drawer
          anchor="bottom"
          open={uiState.isReceiptOpen}
          onClose={uiEvent.closeReceipt}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "calc(100vh - 50px)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            },
          }}
        >
          <ReceiptDrawer
            receipt={uiState.csReceipt}
            closeDrawer={uiEvent.closeReceipt}
          />
        </Drawer>
      )}

      <IconTextDialog
        title={LocalizedText("ua_f312_alert_delete_title")}
        content={LocalizedText("ua_f312_alert_delete_message")}
        type={IconTextDialogType.SAD}
        isOpen={uiState.isDeleteDialogOpen}
        onPositive={() => {
          sideEffect.deleteCSPost();
        }}
        onNegative={uiEvent.closeDeleteDialog}
        onClose={uiEvent.closeDeleteDialog}
      />

      <ToastAlert
        open={uiState.isToastOpen}
        onClose={uiEvent.closeToast}
        message={LocalizedText("common_toast_copied")}
        bottomOffset={16}
      />
    </>
  );
};

export default CustomerSupportDetailRender;
