export class InventoryData {
  no: number;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  company: string;
  quantities: string;
  amount: number;
  registerDate: number;

  constructor(
    no: number,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    company: string,
    quantities: string,
    amount: number,
    registerDate: number
  ) {
    this.no = no;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.company = company;
    this.quantities = quantities;
    this.amount = amount;
    this.registerDate = registerDate;
  }
}

export const InventoryList: InventoryData[] = [
  new InventoryData(
    1,
    "ELECTRIC",
    "VW90-00001A",
    "CELL PACK",
    "W-10S8P-M50L",
    "신흥 SEC",
    "EA",
    200,
    1716499200
  ),
  new InventoryData(
    2,
    "MECHANICAL",
    "VW90-00002B",
    "BATTERY",
    "W-10S8P-M50L",
    "한일 전기",
    "EA",
    150,
    1716585600
  ),
  new InventoryData(
    3,
    "ELECTRIC",
    "VW90-00003C",
    "POWER SUPPLY",
    "W-20S8P-M50L",
    "삼성 전자",
    "EA",
    300,
    1716672000
  ),
  new InventoryData(
    4,
    "MECHANICAL",
    "VW90-00004D",
    "MOTOR",
    "W-30S8P-M50L",
    "LG 전자",
    "EA",
    100,
    1716758400
  ),
  new InventoryData(
    5,
    "ELECTRIC",
    "VW90-00005E",
    "CHARGER",
    "W-40S8P-M50L",
    "SK 하이닉스",
    "EA",
    50,
    1716844800
  ),
  new InventoryData(
    6,
    "ELECTRIC",
    "VW90-00006F",
    "INVERTER",
    "W-50S8P-M50L",
    "현대모비스",
    "EA",
    80,
    1716931200
  ),
  new InventoryData(
    7,
    "MECHANICAL",
    "VW90-00007G",
    "GENERATOR",
    "W-60S8P-M50L",
    "대우 전자",
    "EA",
    20,
    1717017600
  ),
  new InventoryData(
    8,
    "ELECTRIC",
    "VW90-00008H",
    "REGULATOR",
    "W-70S8P-M50L",
    "한양 전기",
    "EA",
    120,
    1717104000
  ),
  new InventoryData(
    9,
    "MECHANICAL",
    "VW90-00009I",
    "TRANSMITTER",
    "W-80S8P-M50L",
    "일진전기",
    "EA",
    90,
    1717190400
  ),
  new InventoryData(
    10,
    "ELECTRIC",
    "VW90-00010J",
    "RECEIVER",
    "W-90S8P-M50L",
    "포스코",
    "EA",
    60,
    1717276800
  ),
  new InventoryData(
    11,
    "MECHANICAL",
    "VW90-00011K",
    "SWITCH",
    "W-100S8P-M50L",
    "삼천리",
    "EA",
    200,
    1717363200
  ),
  new InventoryData(
    12,
    "ELECTRIC",
    "VW90-00012L",
    "TRANSFORMER",
    "W-110S8P-M50L",
    "대림산업",
    "EA",
    150,
    1717449600
  ),
  new InventoryData(
    13,
    "ELECTRIC",
    "VW90-00013M",
    "FUSE",
    "W-120S8P-M50L",
    "한화",
    "EA",
    300,
    1717536000
  ),
  new InventoryData(
    14,
    "MECHANICAL",
    "VW90-00014N",
    "VALVE",
    "W-130S8P-M50L",
    "영진전람",
    "EA",
    100,
    1717622400
  ),
  new InventoryData(
    15,
    "ELECTRIC",
    "VW90-00015O",
    "RELAY",
    "W-140S8P-M50L",
    "에이치엠",
    "EA",
    50,
    1717708800
  ),
  new InventoryData(
    16,
    "ELECTRIC",
    "VW90-00016P",
    "LED",
    "W-150S8P-M50L",
    "비츠로셀",
    "EA",
    80,
    1717795200
  ),
  new InventoryData(
    17,
    "MECHANICAL",
    "VW90-00017Q",
    "PUMP",
    "W-160S8P-M50L",
    "대우건설",
    "EA",
    20,
    1717881600
  ),
  new InventoryData(
    18,
    "ELECTRIC",
    "VW90-00018R",
    "CAPACITOR",
    "W-170S8P-M50L",
    "롯데전자",
    "EA",
    120,
    1717968000
  ),
  new InventoryData(
    19,
    "MECHANICAL",
    "VW90-00019S",
    "AXLE",
    "W-180S8P-M50L",
    "경남 하이테크",
    "EA",
    90,
    1718054400
  ),
  new InventoryData(
    20,
    "ELECTRIC",
    "VW90-00020T",
    "CIRCUIT BOARD",
    "W-190S8P-M50L",
    "LS산전",
    "EA",
    60,
    1718140800
  ),
  new InventoryData(
    21,
    "MECHANICAL",
    "VW90-00021U",
    "GEAR",
    "W-200S8P-M50L",
    "KT&G",
    "EA",
    200,
    1718227200
  ),
  new InventoryData(
    22,
    "ELECTRIC",
    "VW90-00022V",
    "SENSOR",
    "W-210S8P-M50L",
    "카카오",
    "EA",
    150,
    1718313600
  ),
  new InventoryData(
    23,
    "ELECTRIC",
    "VW90-00023W",
    "METER",
    "W-220S8P-M50L",
    "네이버",
    "EA",
    300,
    1718400000
  ),
  new InventoryData(
    24,
    "MECHANICAL",
    "VW90-00024X",
    "CHAIN",
    "W-230S8P-M50L",
    "신세계",
    "EA",
    100,
    1718486400
  ),
  new InventoryData(
    25,
    "ELECTRIC",
    "VW90-00025Y",
    "CONNECTOR",
    "W-240S8P-M50L",
    "이마트",
    "EA",
    50,
    1718572800
  ),
  new InventoryData(
    26,
    "ELECTRIC",
    "VW90-00026Z",
    "PLUG",
    "W-250S8P-M50L",
    "하나금융",
    "EA",
    80,
    1718659200
  ),
  new InventoryData(
    27,
    "MECHANICAL",
    "VW90-00027AA",
    "BOLT",
    "W-260S8P-M50L",
    "우리은행",
    "EA",
    20,
    1718745600
  ),
  new InventoryData(
    28,
    "ELECTRIC",
    "VW90-00028BB",
    "NUT",
    "W-270S8P-M50L",
    "하나은행",
    "EA",
    120,
    1718832000
  ),
  new InventoryData(
    29,
    "MECHANICAL",
    "VW90-00029CC",
    "WASHER",
    "W-280S8P-M50L",
    "농협",
    "EA",
    90,
    1718918400
  ),
  new InventoryData(
    30,
    "ELECTRIC",
    "VW90-00030DD",
    "RING",
    "W-290S8P-M50L",
    "신한은행",
    "EA",
    60,
    1719004800
  ),
];
