import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  PaperProps,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Children, ReactNode } from "react";

interface CommonWebModalProps {
  open?: any; // open 상태의 타입을 boolean으로 수정
  onClose?: () => void; // onClose의 타입을 함수로 수정
  title: string;
  children: ReactNode;
  PaperProps?: PaperProps; // PaperProps를 optional로 수정
  dialogContentSx?: SxProps<Theme>; // DialogContent의 sx 속성 추가
}

const CommonWebModal = ({
  open,
  onClose,
  title,
  children,
  PaperProps, // PaperProps 추가
  dialogContentSx,
}: CommonWebModalProps) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "12px" }, ...PaperProps }} // PaperProps 전달
    >
      {title == "" ? null : (
        <DialogTitle sx={{ fontWeight: "bold" }}>{title}</DialogTitle>
      )}

      <DialogContent sx={{ ...dialogContentSx }}>{children}</DialogContent>
      {/* dialogContentSx 전달 */}
      {/* footer */}
      {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ml: 2,
            mr: 2,
            mb: 2,
          }}
        >
          <Button>aaa</Button>
          <Button>bbb</Button>
        </Box> */}
    </Dialog>
  );
};

export default CommonWebModal;
