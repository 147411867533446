import { Box } from "@mui/material";
import InquiryHistoryItem from "../../../MobileInquiry/section/InquiryHistoryItem";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import { HorizontalGrayDivider } from "../../../../component/Divider";
import VerticalSpacer from "../../../../component/Spacer";
import CSHistoryModel from "../model/CSHistoryModel";

interface CSHistoryListProps {
  csHistories: CSHistoryModel[];
  onClick: (id: string) => void;
}

export default function CSHistoryList({
  csHistories,
  onClick,
}: CSHistoryListProps) {
  if (csHistories.length == null || csHistories.length == 0) {
    return (
      <Box
        sx={{
          pt: "48px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          sx={{
            fontStyle: fontStyle.bodyMr,
            color: color.gray600,
          }}
          text={LocalizedText("ua_f310_nohistory")}
        />
      </Box>
    );
  }
  return (
    <Box>
      {csHistories.map((item) => (
        <Box key={item.id}>
          <VerticalSpacer height={8} />
          <Box
            sx={{
              px: "8px",
            }}
          >
            <InquiryHistoryItem
              key={item.id}
              status={item.status}
              title={item.title}
              category={item.preferredStation}
              registrationDate={item.registrationDate}
              onClick={() => {
                onClick(item.id);
              }}
            />
          </Box>
          <VerticalSpacer height={8} />
          <HorizontalGrayDivider />
        </Box>
      ))}
    </Box>
  );
}
