import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import {
  ICON_BATTERY,
  ICON_BATTERY2,
  ICON_BATTERY_HORIZONTAL,
  ICON_BATTERY_HORIZONTAL2,
  ICON_PIN,
} from "../../../constants/appImagePath";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface BatteryUsageHistoryProps {
  BatteryUsageHistoryData?: any;
}
const BatteryUsageHistory = ({
  BatteryUsageHistoryData,
}: BatteryUsageHistoryProps) => {
  const formattedDate = (formattDate: any): string => {
    const date = new Date(formattDate);

    // 날짜를 포맷
    const formattedDatePart = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    });

    // 시간을 포맷
    const formattedTimePart = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    // 결과 합치기
    const formattedDate = `${formattedDatePart} ${formattedTimePart}`;
    return formattedDate;
  };
  return (
    <>
      <Box sx={{}}>
        <VerticalSpacer height={16} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box component="img" src={ICON_BATTERY_HORIZONTAL2} />
            <Typography fontStyle={fontStyle.titleM} sx={{ ml: 0.5 }}>
              {BatteryUsageHistoryData.soc}% used
            </Typography>
          </Typography>
          {BatteryUsageHistoryData.reservation == true && (
            <Box
              sx={{
                backgroundColor: color.backgroundProgress,
                px: "8px",
                py: "0px",
                borderRadius: "8px",
              }}
            >
              <Typography color={color.progress} fontStyle={fontStyle.titleS}>
                Reserved
              </Typography>
            </Box>
          )}
        </Box>

        <Box>
          <Box sx={{ display: "flex", mt: 1, alignItems: "center" }}>
            <Typography
              fontStyle={fontStyle.titleS}
              color={color.gray600}
              sx={{ mr: "8px", whiteSpace: "nowrap" }}
            >
              {LocalizedText("ua_d100_rentaldate")}
            </Typography>
            <Typography
              fontStyle={fontStyle.bodySr}
              sx={{ mr: "4px", whiteSpace: "nowrap" }}
            >
              {formattedDate(BatteryUsageHistoryData.rentDt)}
            </Typography>
            <Box component="img" src={ICON_PIN} sx={{ mb: "4px" }} />
            <Typography
              fontStyle={fontStyle.bodySr}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {BatteryUsageHistoryData.rentStation}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography
              fontStyle={fontStyle.titleS}
              color={color.gray600}
              sx={{ mr: "8px", whiteSpace: "nowrap" }}
            >
              {LocalizedText("ua_d100_returndate")}
            </Typography>
            <Typography
              fontStyle={fontStyle.bodySr}
              sx={{ mr: "4px", whiteSpace: "nowrap" }}
            >
              {formattedDate(BatteryUsageHistoryData.returnDt)}
            </Typography>
            <Box component="img" src={ICON_PIN} sx={{ mb: "4px" }} />
            <Typography
              fontStyle={fontStyle.bodySr}
              sx={{
                flexGrow: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {BatteryUsageHistoryData.returnStation}
            </Typography>
          </Box>
        </Box>
        <VerticalSpacer height={16} />
        <Divider />
      </Box>
    </>
  );
};

export default BatteryUsageHistory;
