import { SxProps, Theme, Typography } from "@mui/material";
import { fontStyle } from "../theme/Style";
import { color } from "../theme/Color";

interface CommonErrorTextProps {
  value: string;
  sx?: SxProps<Theme>;
}

const CommonErrorText = ({ value, sx }: CommonErrorTextProps) => {
  return (
    <Typography
      fontStyle={fontStyle.regularXXS}
      color={color.error}
      sx={{ ...sx }}
    >
      {value}
    </Typography>
  );
};

export default CommonErrorText;
