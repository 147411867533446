import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { IMAGE_PONYA_LEVEL1 } from "../../../constants/appImagePath";
import NFTImage from "../nftdetail/NFTImage";

interface NFTCardProps {
  image: string;
  title: string;
  description: string;
  onClick: () => void;
}

export default function NFTCard({
  image,
  title,
  description,
  onClick,
}: NFTCardProps) {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "calc(50% - 5px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        component="img"
        src={image}
        alt="NFT Image"
        loading="lazy"

        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "10px",
        }}
      />
      <Box sx={{ paddingTop: "8px" }}>
        <Typography fontStyle={fontStyle.titleM} color={color.default} sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}>
          {title}
        </Typography>
        <Typography fontStyle={fontStyle.subTitleS} color={color.gray600}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
}
