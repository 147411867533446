export class MaterialsData {
  no: number;
  level: number;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  quantities: string;
  amount: number;
  company: string;

  constructor(
    no: number,
    level: number,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    quantities: string,
    amount: number,
    company: string
  ) {
    this.no = no;
    this.level = level;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.quantities = quantities;
    this.amount = amount;
    this.company = company;
  }
}

export enum MaterialType {
  NONE = "None",
  SERIAL_NUMBER = "Serial Number",
  MANUAL_COUNT = "Manual Count",
  MEMO = "Memo",
}

export const MaterialBOMList: MaterialsData[] = [
  new MaterialsData(
    1,
    0,
    "ASSY",
    "VW42-00002A",
    "ASSY DISPENCER",
    "ASSY DISPENCER SET",
    "SET",
    20,
    "Verywords"
  )
]

export const MaterialsList: MaterialsData[] = [
  new MaterialsData(
    1,
    0,
    "ASSY",
    "VW42-00002A",
    "ASSY DISPENCER",
    "ASSY DISPENCER SET",
    "SET",
    20,
    "Verywords"
  ),
  new MaterialsData(
    2,
    0,
    "ASSY",
    "VW42-00002A",
    "BATTERY PACK",
    "ASSY BATTERY SET (360*120*122)",
    "SET",
    20,
    "Verywords"
  ),
  new MaterialsData(
    3,
    0,
    "ASSY",
    "VW42-00002A",
    "ASSY CELL PACK",
    "51163-04P, LNTK103F(0)-3435F, 1333#2, 1333#24AWG[흑, 1333#24AWG[흑4AWG[흑색(...",
    "SET",
    20,
    "Verywords"
  ),
  new MaterialsData(
    4,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "CELL PACK",
    "51163-04P, LNTK103F(0)-3435F, 1333#2, 1333#24AWG[흑, 1333#24AWG[흑4AWG[흑색(...",
    "SET",
    20,
    "Verywords"
  ),
  new MaterialsData(
    5,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_CELL_A",
    "51163-04P, LNTK103F(0)-3435F, 1333#2, 1333#24AWG[흑, 1333#24AWG[흑4AWG[흑색(...",
    "PCS",
    20,
    "디에스테크"
  ),
  new MaterialsData(
    6,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_TEMP_3,4",
    "51163-04P, LNTK103F(0)-3435F, 1333#2, 1333#24AWG[흑, 1333#24AWG[흑4AWG[흑색(...",
    "SET",
    20,
    "디에스테크"
  ),
  new MaterialsData(
    7,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "SCREW",
    "51163-04P, LNTK103F(0)-3435F, 1333#2, 1333#24AWG[흑, 1333#24AWG[흑4AWG[흑색(...",
    "PCS",
    20,
    "신흥 SEC"
  ),
  new MaterialsData(
    8,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_CELL_B",
    "51163-04P, LNTK103F(0)-3435F, 1333#2, 1333#24AWG[흑, 1333#24AWG[흑4AWG[흑색(...",
    "EA",
    20,
    "Verywords"
  ),
  new MaterialsData(
    9,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_TEMP_5,6",
    "51163-04P, LNTK103F(0)-3435F, 1333#2, 1333#24AWG[흑, 1333#24AWG[흑4AWG[흑색(...",
    "EA",
    20,
    "Verywords"
  ),
  new MaterialsData(
    10,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_TEMP_3,4",
    "51163-04P, LNTK103F(0)-3435F, 1333#2, 1333#24AWG[흑, 1333#24AWG[흑4AWG[흑색(...",
    "EA",
    20,
    "Verywords"
  ),
  new MaterialsData(
    11,
    1,
    "ASSY",
    "VW42-00002A",
    "HARNESS_A",
    "Description for HARNESS_A",
    "SET",
    15,
    "Company A"
  ),
  new MaterialsData(
    12,
    0,
    "ASSY",
    "VW42-00002A",
    "HARNESS_B",
    "Description for HARNESS_B",
    "PCS",
    25,
    "Company B"
  ),
  new MaterialsData(
    13,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_C",
    "Description for HARNESS_C",
    "EA",
    10,
    "Company C"
  ),
  new MaterialsData(
    14,
    0,
    "ASSY",
    "VW42-00002A",
    "HARNESS_D",
    "Description for HARNESS_D",
    "SET",
    30,
    "Company D"
  ),
  new MaterialsData(
    15,
    1,
    "ASSY",
    "VW42-00002A",
    "HARNESS_E",
    "Description for HARNESS_E",
    "PCS",
    5,
    "Company E"
  ),
  new MaterialsData(
    16,
    0,
    "ASSY",
    "VW42-00002A",
    "HARNESS_F",
    "Description for HARNESS_F",
    "EA",
    20,
    "Company F"
  ),
  new MaterialsData(
    17,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_G",
    "Description for HARNESS_G",
    "SET",
    8,
    "Company G"
  ),
  new MaterialsData(
    18,
    0,
    "ASSY",
    "VW42-00002A",
    "HARNESS_H",
    "Description for HARNESS_H",
    "PCS",
    12,
    "Company H"
  ),
  new MaterialsData(
    19,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_I",
    "Description for HARNESS_I",
    "EA",
    6,
    "Company I"
  ),
  new MaterialsData(
    20,
    0,
    "ASSY",
    "VW42-00002A",
    "HARNESS_J",
    "Description for HARNESS_J",
    "SET",
    18,
    "Company J"
  ),
  new MaterialsData(
    21,
    1,
    "ASSY",
    "VW42-00002A",
    "HARNESS_K",
    "Description for HARNESS_K",
    "PCS",
    22,
    "Company K"
  ),
  new MaterialsData(
    22,
    0,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_L",
    "Description for HARNESS_L",
    "EA",
    14,
    "Company L"
  ),
  new MaterialsData(
    23,
    1,
    "ASSY",
    "VW42-00002A",
    "HARNESS_M",
    "Description for HARNESS_M",
    "SET",
    7,
    "Company M"
  ),
  new MaterialsData(
    24,
    0,
    "ASSY",
    "VW42-00002A",
    "HARNESS_N",
    "Description for HARNESS_N",
    "PCS",
    26,
    "Company N"
  ),
  new MaterialsData(
    25,
    1,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_O",
    "Description for HARNESS_O",
    "EA",
    11,
    "Company O"
  ),
  new MaterialsData(
    26,
    0,
    "ASSY",
    "VW42-00002A",
    "HARNESS_P",
    "Description for HARNESS_P",
    "SET",
    19,
    "Company P"
  ),
  new MaterialsData(
    27,
    1,
    "ASSY",
    "VW42-00002A",
    "HARNESS_Q",
    "Description for HARNESS_Q",
    "PCS",
    17,
    "Company Q"
  ),
  new MaterialsData(
    28,
    0,
    "SEMI ASSY",
    "VW42-00002A",
    "HARNESS_R",
    "Description for HARNESS_R",
    "EA",
    23,
    "Company R"
  ),
  new MaterialsData(
    29,
    1,
    "ASSY",
    "VW42-00002A",
    "HARNESS_S",
    "Description for HARNESS_S",
    "SET",
    13,
    "Company S"
  ),
  new MaterialsData(
    30,
    0,
    "ASSY",
    "VW42-00002A",
    "HARNESS_T",
    "Description for HARNESS_T",
    "PCS",
    15,
    "Company T"
  ),
];
