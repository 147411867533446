import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UsageHistoryUiState } from "./UsageHistoryUiState";
import { UsageHistoryUiEvent } from "./UsageHistoryUiEvent";
import { UsageHistorySideEffect } from "./UsageHistorySideEffect";
import UsageHistoryRender from "./UsageHistoryRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { CalendarType } from "../../../component/InquiryPeriod/CalendarDrawer";

const UsageHistoryPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); //나중에 true로 변경
  const [uiState, setUiState] = useState(new UsageHistoryUiState());

  useAndroidBackHandler(() => {
    window.history.back();
  });

  const uiEventHandler: UsageHistoryUiEvent = {
    HandleTabChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          currentTab: event,
        };
      });
    },
    OpenDatePicker: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDataPicker: true,
        };
      });
    },
    CloseDatePicker: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDataPicker: false,
        };
      });
    },
    OpenCalendar: (type) => {
      setUiState((prev) => {
        return {
          ...prev,
          isCalendarOpen: true,
          calendarType: type,
        };
      });
    },
    CloseCalendar: (type, date) => {
      setUiState((prev) => {
        return {
          ...prev,
          isCalendarOpen: false,
          startDate: type === CalendarType.START ? date : prev.startDate,
          endDate: type === CalendarType.END ? date : prev.endDate,
          selectedDate: 0,
        };
      });
    },
    SelectDate: (date) => {
      setUiState((prev) => {
        return {
          ...prev,
          selectedDate: date,
          startDate:
            prev.calendarType === CalendarType.START ? date : prev.startDate,
          endDate: prev.calendarType === CalendarType.END ? date : prev.endDate,
        };
      });
    },
    ShowDateAlert: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isDateAlertOpen: true,
        };
      });
    },
    HideDateAlert: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isDateAlertOpen: false,
        };
      });
    },
    ToggleHistoryDetail: (id) => {
      setUiState((prev) => {
        return {
          ...prev,
          historyList: prev.historyList.map((history) => {
            if (history.id === id) {
              return {
                ...history,
                isExpanded: !history.isExpanded,
              };
            } else {
              return history;
            }
          }),
        };
      });
    },
  };

  const sideEffectHandler: UsageHistorySideEffect = {
    back: () => {
      window.history.back();
    },
    requestUsageHistory: function (
      startDate: number | undefined,
      endDate: number,
      monthsToSubtract: number | undefined
    ): void {
      console.log("requestUsageHistory");
    },
  };

  return (
    <>
      <UsageHistoryRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandler}
      />
    </>
  );
};

export default UsageHistoryPage;
