import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import AddIcon from "@mui/icons-material/Add";
import { UserDetailsUiEvent } from "../../UserDetailsUiEvent";
import { UserDetailsUiState } from "../../UserDetailsUiState";
import CommonDialog from "../../../../component/CommonDialog";
import { useState } from "react";

interface UserScooterEmptyRenderProps {
  uiEvent: UserDetailsUiEvent;
  uiState: UserDetailsUiState;
}

const UserScooterEmptyRender = ({
  uiEvent,
  uiState,
}: UserScooterEmptyRenderProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Grid item lg={12}>
      <Card
        sx={{
          borderRadius: 3,

          width: "100%",
          display: "flex",
        }}
      >
        <CardContent sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  width: "39%",
                  textAlign: "center",
                }}
                fontStyle={fontStyle.regularM}
              >
                신규 스쿠터 정보 등록이 필요한 경우 하단의&nbsp;
                <Typography
                  component="span" // inline 요소로 설정
                  fontStyle={fontStyle.semiboldM}
                  color={color.success}
                >
                  Add Scooter
                </Typography>
                &nbsp;버튼을 클릭하여 등록을 진행해 주세요.
              </Typography>
            </Box>
            <VerticalSpacer height={24} />
            <Button
              sx={{
                display: "flex",
                width: "148px",
                height: "45px",
                bgcolor: color.primary500,
                color: "white",
                ":hover": {
                  bgcolor: color.primary300,
                },
                textTransform: "none",
              }}
              onClick={() => {
                if (uiState.IdCardImageFileName == undefined) {
                  setDialogOpen(true);
                } else {
                  uiEvent.HandleUiStateChange("AddScooter", true);
                }
              }}
            >
              <AddIcon fontSize="small" sx={{ mr: "6px" }} />
              Add Scooter
            </Button>
          </Box>
        </CardContent>
      </Card>
      <CommonDialog
        open={dialogOpen}
        handleClose={() => {
          setDialogOpen(false);
        }}
        handleOk={() => {
          setDialogOpen(false);
        }}
      >
        <Typography
          fontStyle={fontStyle.regularS}
          color={color.gray800}
          sx={{ display: "flex", alignItems: "center" }}
        >
          IDCard(신분증)를/을 먼저 등록 하세요
        </Typography>
      </CommonDialog>
    </Grid>
  );
};

export default UserScooterEmptyRender;
