import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import {
  ICON_WALLET_PRIMARY500,
  IMAGE_PETER_FIGHTING,
} from "../../../constants/appImagePath";
import WalletInfo from "../model/WalletInfo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

export interface WalletCardProps {
  walletInfo: WalletInfo;
  onCopyAddress: (address: string) => void;
}

export default function WalletCard({
  walletInfo: { address, name, uuid, userDid },
  onCopyAddress,
}: WalletCardProps) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        backgroundColor: color.primary50,
        borderRadius: "10px",
        padding: "20px 20px 16px 20px",
        overflow: "hidden",
        minHeight: "100px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          zIndex: 1,
          paddingRight: "25%",
        }}
      >
        <Typography
          fontStyle={fontStyle.titleXL}
          color={color.gray800}
          sx={{
            display: "flex",
            alignItems: "center",
            wordBreak: "break-all",
          }}
        >
          <img
            src={ICON_WALLET_PRIMARY500}
            alt="Wallet"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "6px",
            }}
          />
          {name}
        </Typography>
        <CopyToClipboard text={address}>
          <Typography
            fontStyle={fontStyle.bodyLr}
            color={color.gray800}
            sx={{
              textDecoration: "underline",
              color: color.gray800,
              wordBreak: "break-all",
              marginBottom: "10px",
            }}
            onClick={() => onCopyAddress(address)} // 클릭 시 주소 복사
          >
            {address}
          </Typography>
        </CopyToClipboard>
        <Typography
          fontStyle={fontStyle.bodyLr}
          color={color.gray800}
          sx={{ wordBreak: "break-all" }}
        >
          <strong>{LocalizedText("ua_g500_did")}</strong> {userDid.did}
        </Typography>
      </Box>

      <Box
        component="img"
        src={IMAGE_PETER_FIGHTING}
        alt="Peter"
        sx={{
          position: "absolute",
          right: "-80px",
          top: -15,
          width: "70%",
          maxWidth: "200px",
          aspectRatio: "1",
          objectFit: "cover",
          transform: "rotate(-71deg)",
        }}
      />
    </Box>
  );
}
