import { TableCell, TableRow, Typography } from "@mui/material";
import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import { MembershipVoucherRow, MembershipVoucherTableRow } from "./MembershipVoucherTableRow";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

interface MembershipVoucherTableProps {
  headCells?: HeadCell[];
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: number | undefined;
  rows?: [];
}

export function MembershipVoucherTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
  rows,
}: MembershipVoucherTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(rows || [], rowsPerPage ?? 10, setPage, page ?? 10);
  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
        <TableCell
          colSpan={headCells?.length}
          align="center"
          sx={{
            minWidth: "1032px",
            px: "13px",
            py: "12px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontStyle: fontStyle.regularS,
              color: color.gray600,
            }}
          >
            No Data
          </Typography>
        </TableCell>
      </TableRow>
      ) : (
      visibleRows?.map(
        (row: { [x: string]: string | number }, index: number) => (
          <MembershipVoucherTableRow
            row={row as unknown as MembershipVoucherRow}
            key={index}
            docCount={docCount}
          />
        )
      )
      )}
    </CommonTable>
  );
}
