import { MembershipVoucherUiState } from "./model/MembershipVoucherUiState";
import { MembershipVoucherUiEvent } from "./model/MembershipVoucherUiEvent";
import MembershipVoucherGrid from "./component/MembershipVoucherGrid";

interface MembershipVoucherRenderProps {
  uiState: MembershipVoucherUiState;
  uiEvent: MembershipVoucherUiEvent;
}

const MembershipVoucherRender = ({
  uiState,
  uiEvent,
}: MembershipVoucherRenderProps) => {
  return (
    <>
      <MembershipVoucherGrid
        rows={uiState.MVDataRows}
        countryLists={uiState.CountryList}
        uiState={uiState}
        uiEvent={uiEvent}
      />
    </>
  );
};

export default MembershipVoucherRender;
