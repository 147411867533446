import { useNavigate } from "react-router-dom";
import MobileProfileWithdrawalRender from "./MobileProfileWithdrawalRender";
import { useEffect, useState } from "react";
import { MobileProfileWithdrawalPageUiState } from "./MobileProfileWithdrawalPageUiState";
import { MobileProfileWithdrawalPageSideEffect } from "./MobileProfileWithdrawalPageSideEffect";
import { MobileProfileWithdrawalPageUiEvent } from "./MobileProfileWithdrawalPageUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import ProgressBar from "../../component/ProgressBar";
import { Box } from "@mui/material";
import { ROUTE_PATHS } from "../../constants/RouteConstants";
import {clearUserDataInLocalStorage} from "../../utils/LocalStorageUtil";

function onSuccessDeleteAccount(): void {
  const event = new CustomEvent("onSuccessDeleteAccount");
  window.dispatchEvent(event);
}

// window.onload = registerRentedEvent;
// 전역 스코프에 함수 노출
(window as any).onSuccessDeleteAccount = onSuccessDeleteAccount;

const MobileProfileWithdrawalPage = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [uiState, setUiState] = useState(
    new MobileProfileWithdrawalPageUiState(),
  );

  useEffect(() => {
    const navigateFinishScreen = () => {
      setProgress(false);
      navigate(ROUTE_PATHS.WITHDRAWAL_FINISH);
    };
    window.addEventListener("onSuccessDeleteAccount", navigateFinishScreen);
    return () => {
      window.removeEventListener(
        "onSuccessDeleteAccount",
        navigateFinishScreen,
      );
    };
  }, []);

  const uiEventHandler: MobileProfileWithdrawalPageUiEvent = {
    ShowAlertDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowAlertDialog: true,
        };
      });
    },
    HideAlertDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowAlertDialog: false,
        };
      });
    },
    HandleReasonForWithdrawalChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          reasonForWithdrawal: event,
        };
      });
    },
    HandleReasonForWithdrawalDetailsChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          reasonForWithdrawalDetails: event,
        };
      });
    },
  };
  const sideEffectHandlers: MobileProfileWithdrawalPageSideEffect = {
    navigate: function (target: string): void {
      console.log("navigate", target);
      navigate(`/${target}`);
    },
    deleteAccount: function (): void {
      setProgress(true);
      requestDeleteAccount(
        localStorage.getItem("uuid") ?? "",
        localStorage.getItem("email") ?? "",
        uiState.reasonForWithdrawal ?? uiState.reasonForWithdrawalDetails ?? "", //TODO: 이부분 수정 필요
      ).then((r) => {
        clearUserDataInLocalStorage();
        if (window.webkit) {
          window.webkit.messageHandlers.deleteAccount.postMessage(
            "deleteAccount",
          );
        } else {
          window.Android.deleteAccount();
        }
      });
    },
  };

  async function requestDeleteAccount(
    uuid: string,
    email: string,
    reason?: string,
  ) {
    setProgress(true);
    const url = "/pw/backend/api/profile/withdrawal";
    const requestBody = {
      uuid: uuid,
      email: email,
      reason: reason,
    };
    await ApiService.MobilePost(url, requestBody).then((response) => {
      if (response) {
        console.log("탈퇴 성공");
      } else {
        console.log("탈퇴 실패");
      }
    });
  }

  return (
    <Box
      sx={{
        display: "absolute",
      }}
    >
      <MobileProfileWithdrawalRender
        sideEffect={sideEffectHandlers}
        uiEvent={uiEventHandler}
        uiState={uiState}
      />
      {progress ? <ProgressBar /> : null}
    </Box>
  );
};

export default MobileProfileWithdrawalPage;
