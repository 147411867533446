import { Box, Typography } from "@mui/material";
import PurchaseHistoryItem from "../../model/PurchaseHistoryItem";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import PurchaseHistoryCell from "./PurchaseHistoryCell";

interface PurchaseHistoryListProps {
  histories: PurchaseHistoryItem[];
}

export default function PurchaseHistoryList({
  histories,
}: PurchaseHistoryListProps) {
  const noPurchaseHistoryText = LocalizedText("ua_g421_nopurchasehistory");

  if (histories.length == null || histories.length == 0) {
    return (
      <Box
        sx={{
          pt: "48px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
          {noPurchaseHistoryText}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      {histories.map((item, index) => (
        <PurchaseHistoryCell key={index} item={item} />
      ))}
    </Box>
  );
}
