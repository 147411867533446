interface PurchaseHistoryItem {
  name: string;
  purchaseType: PurchaseType;
  billingDate: number;
  price: number;
}

export enum PurchaseType {
  PURCHASE = "구매",
  CHANGE = "변경",
}

export default PurchaseHistoryItem;
