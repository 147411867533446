import { Button, IconButton } from "@mui/material";
import { useState } from "react";
import CommonList from "../../component/CommonList";
import NFTCreateGrid from "./section/NFTCreateGrid";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

const NFTCreate = () => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          setCreateDialogOpen(true);
        }}
      >
        Create
      </Button>
    );
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickBackButton();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  // const [open, setOpen] = useState(false); 백버튼 눌렀을 때 알림창 기능 구현해야함
  const navigate = useNavigate();
  const handleClickBackButton = () => {
    navigate("/NFTManagement");
  };
  //   const handleClose = () => {
  //     setOpen(false);
  //   }
  return (
    <CommonList
      title="NFT 등록"
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <NFTCreateGrid />
    </CommonList>
  );
};

export default NFTCreate;
