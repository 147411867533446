import { Button, SxProps, Theme, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { fontStyle } from "../theme/Style";
import { color } from "../theme/Color";
import { HorizontalSpacer } from "./Spacer";
import { ReactNode } from "react";

interface CommonWebHeaderButtonProps {
  onClick?: () => void;
  height?: string;
  width?: string;
  buttonContent?: string;
  addIcon?: boolean;
  sx?: SxProps<Theme>;
  textColor?: string;
  icon?: ReactNode;
  iconFontSize?: string;
}

export function CommonWebHeaderButton({
  onClick,
  height,
  buttonContent,
  addIcon,
  sx,
  textColor,
  icon,
  iconFontSize,
}: CommonWebHeaderButtonProps) {
  return (
    <Button
      sx={{
        px: "20px",
        py: "12.5px",
        height: { height },
        backgroundColor: "#5D38E5",
        color: "white",
        borderRadius: "40px",
        width: "fit-content",
        ":hover": {
          backgroundColor: "#A38BFC",
        },

        ...sx,
      }}
      onClick={onClick}
    >
      {addIcon ? <AddIcon sx={{ fontSize: `${iconFontSize}` }} /> : null}
      {icon}
      <HorizontalSpacer width={8} />
      <Typography
        sx={{
          fontStyle: fontStyle.semiboldS,
          color: textColor || color.white,
          textTransform: "none",
        }}
      >
        {buttonContent}
      </Typography>
    </Button>
  );
}
