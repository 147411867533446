import { Box, SxProps, TableCell, Theme } from "@mui/material";

interface ProductBOMTreeTableCellProps {
  childrenContainerSx?: SxProps<Theme>;
  children?: JSX.Element;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  sx?: SxProps<Theme>;
}

export function ProductBOMTreeTableCell({
  childrenContainerSx,
  children,
  align,
  sx,
}: ProductBOMTreeTableCellProps) {
  return (
    <TableCell
      sx={{
        py: "8px",
        px: "8px",
        borderBottom: "0.8px",
        ...sx,
      }}
      align={align}
    >
      <Box
        sx={{
          py: 0,
          display: "flex",
          alignItems: "center",
          minHeight: "26px",
          ...childrenContainerSx,
        }}
      >
        {children}
      </Box>
    </TableCell>
  );
}
