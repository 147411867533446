import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { color } from "../../../theme/Color";

interface NotificationSwitchProps {
  isChecked: boolean;
  onChange: () => void;
}

export function NotificationSwitch({
  isChecked,
  onChange,
}: NotificationSwitchProps) {
  return <IosStyleSwitch checked={isChecked} onChange={onChange} />;
}

export const IosStyleSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 25,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 3,
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: color.white,
      "& + .MuiSwitch-track": {
        backgroundColor: color.primary500,
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: color.primary500,
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18.75,
    height: 18.75,
  },
  "& .MuiSwitch-track": {
    borderRadius: 25 / 2,
    backgroundColor: color.gray400,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: color.gray400,
    }),
  },
}));
