import { useEffect } from "react";
import UsrMngListPage from "../pages/usrMngList/UsrMngListPage";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import OperateDashBoardPage from "../pages/operate/OperateDashBoardPage";
import AdminMngListPage from "../pages/adminMngList/AdminMngListPage";
import CreateAdminPage from "../pages/adminMngList/section/AdminCreate/CreateAdminPage";
import FAQMngListPage from "../pages/FAQ/FAQMngListPage";
import Multilingual from "../pages/multilingual/Multilingual";
import UserDetails from "../pages/usrMngList/UsrDetails";
import AdminDetailPage from "../pages/adminMngList/section/AdminDetail/AdminDetailPage";
import GrpMngListPage from "../pages/grpMngList/GrpMngListPage";
import GrpDetailsPage from "../pages/grpMngList/GrpDetailsPage";
import GrpCreatePage from "../pages/grpMngList/GrpCreatePage";
import FAQCreatePage from "../pages/FAQ/FAQCreatePage";
import InquiryPage from "../pages/Inquiry/InquiryPage";
import InquiryDetailsPage from "../pages/Inquiry/InquiryDetailsPage";
import CsMngPage from "../pages/csMng/CsMngPage";
import CsDetailsPage from "../pages/csMng/CsDetailsPage";
import AnnouncementPage from "../pages/announcement/AnnouncementPage";
import AnnouncementCreatePage from "../pages/announcement/AnnouncementCreatePage";
import AnnouncementDetailsPage from "../pages/announcement/AnnouncementDetailsPage";
import PushNotiMngListPage from "../pages/pushNotiMng/PushNotiMngListPage";
import NFTCreatePage from "../pages/nftmanagement/NFTCreate";
import FAQDetailsPage from "../pages/FAQ/FAQDetailsPage";
import VehicleMngPage from "../pages/vehicleMng/VehicleMngPage";
import BatteryMngPage from "../pages/batteryMng/BatteryMngPage";
import StationMngPage from "../pages/stationMng/StationMngPage";
import NfcMngPage from "../pages/nfcmanagement/NfcMngPage";
import NfcCreatePage from "../pages/nfcmanagement/NfcCreatePage";
import PopupMngPage from "../pages/popupbanner/popup/PopupMngPage";
import CouponValidation from "../pages/paymentmanagement/couponhistory/CouponValidation";
import NFTManagement from "../pages/nftmanagement/NFTManagement";
import RegionAndServiceManagement from "../pages/regionandservicemanagement/RegionAndServiceManagement";
import ProductionManagement from "../pages/productionmanagement/ProductionManagement";
import BlockchainMonitoringPage from "../pages/blockchain/BlockchainMonitoringPage";
import InventoryManagement from "../pages/inventorymanagement/InventoryManagement";
import RequestManagement from "../pages/requestmanagement/RequestManagement";
import ModelManagement from "../pages/modelmanagement/ModelManagement";
import ProductionRegistration from "../pages/productionmanagement/production/section/productionregistration/ProductionRegistration";
import { ProductionDetails } from "../pages/productionmanagement/production/section/productiondetails/ProductionDetails";
import { MaterialRegistration } from "../pages/productionmanagement/materials/section/materialregistration/MaterialRegistration";
import { MaterialDetails } from "../pages/productionmanagement/materials/section/materialdetails/MaterialDetails";
import { RegisterBillOfMaterials } from "../pages/productionmanagement/materials/section/materialregistration/registerbillofmaterials/RegisterBillOfMaterials";
import { BillOfMaterialsDetails } from "../pages/productionmanagement/materials/section/materialdetails/billofmaterialsdetails/BillOfMaterialsDetails";
import { ReceiveDeliverRegistration } from "../pages/inventorymanagement/receive-deliver/receivedeliverregistration/ReceiveDeliverRegistration";
import PaymentManagement from "../pages/paymentmanagement/PaymentManagement";
import NFCCardManamgement from "../pages/nfcmanagement/NfcCardManagement";
import { NfcDetails } from "../pages/nfcmanagement/section/NfcDetails";
import MembershipVoucherPage from "../pages/membershipVoucher/MembershipVoucherPage";
import RegionMngCreatePage from "../pages/regionandservicemanagement/section/RegionMngCreate/RegionMngCreatePage";
import CreateMethodologyPage from "../pages/carbonReductionMng/methodology/CreateMethodologyPage";
import CreateMonitoringPage from "../pages/carbonReductionMng/monitoring/CreateMonitoringPage";
import { default as CreateProjectPage } from "../pages/carbonReductionMng/project/CreateProjectPage";
import MethodologyMngListPage from "../pages/carbonReductionMng/methodology/MethodologyMngListPage";
import MonitoringMngListPage from "../pages/carbonReductionMng/monitoring/MonitoringMngListPage";
import ParamDataMngListPage from "../pages/carbonReductionMng/paramData/ParamDataMngListPage";
import ProjectMngListPage from "../pages/carbonReductionMng/project/ProjectMngListPage";
import CouponManagement from "../pages/couponsettings/CouponManagement";
import CouponSettingHistory from "../pages/couponsettings/CouponSettingHistory";
import RegionDetailPage from "../pages/regionandservicemanagement/section/RegionDetail/RegionDetailPage";
import ServiceMngCreatePage from "../pages/regionandservicemanagement/section/ServiceMngCreate/ServiceMngCreatePage";
import BannerMngPage from "../pages/popupbanner/banner/BannerManagement";

const MainRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ua = navigator.userAgent.toLowerCase();

  useEffect(() => {
    // 새로고침 시 현재 경로를 저장합니다.
    const currentPath = location.pathname;
    if (currentPath == "/login") {
      sessionStorage.setItem("lastPath", "/dashboard");
    } else {
      sessionStorage.setItem("lastPath", currentPath);
    }
  }, [location.pathname]); // 경로가 변경될 때마다 실행됩니다.

  useEffect(() => {
    // 컴포넌트가 마운트될 때, 새로고침 시 마지막 경로로 이동합니다.
    const lastPath = sessionStorage.getItem("lastPath");
    if (lastPath) {
      navigate(lastPath);
    }
  }, []);

  return (
    <Routes>
      <Route path="/dashboard" element={<OperateDashBoardPage />} />

      {/* 회원관리 라우트 */}
      <Route path="/usrMng" element={<UsrMngListPage />} />
      <Route path="/usrMng/usrDetails/:userId" element={<UserDetails />} />

      {/* 관리자관리 라우트 */}
      <Route path="/adminMng" element={<AdminMngListPage />} />
      <Route
        path="/adminMng/adminDetails/:userId"
        element={<AdminDetailPage />}
      />
      <Route path="/adminMng/createAdmin" element={<CreateAdminPage />} />

      {/* 그룹관리 라우트 */}
      <Route path="/grpMng" element={<GrpMngListPage />} />
      <Route path="/grpMng/grpCreate" element={<GrpCreatePage />} />
      <Route path="/grpMng/grpDetails/:grpId" element={<GrpDetailsPage />} />

      {/* FAQ관리 라우트 */}
      <Route path="/FAQMng" element={<FAQMngListPage />} />
      <Route path="/FAQMng/FAQCreate" element={<FAQCreatePage />} />
      <Route path="/FAQMng/FAQDetails/:faqId" element={<FAQDetailsPage />} />

      {/* 1:1문의 관리 라우트 */}
      <Route path="/inquiryMng" element={<InquiryPage />} />
      <Route
        path="/inquiryMng/inquiryDetails/:inquiryId"
        element={<InquiryDetailsPage />}
      />

      {/* CS 관리 라우트 */}
      <Route path="/csMng" element={<CsMngPage />} />
      <Route path="/csMng/csDetails/:csId" element={<CsDetailsPage />} />

      {/* 공지사항 관리 라우트 */}
      <Route path="/announcement" element={<AnnouncementPage />} />
      <Route
        path="/announcement/createAnnounce"
        element={<AnnouncementCreatePage />}
      />
      <Route
        path="/announcement/announceDetails/:announceId"
        element={<AnnouncementDetailsPage />}
      />

      {/* 푸시 알람 관리 라우트 */}
      <Route path="/pushMng" element={<PushNotiMngListPage />} />

      {/* 팝업 관리 라우트 */}
      <Route path="/popupMng" element={<PopupMngPage />} />
      <Route path="/bannerManagement" element={<BannerMngPage />} />

      {/* Merchandise */}
      {/* 멤버십 바우처 설정 라우트 */}
      <Route
        path="/membershipVoucherSetting"
        element={<MembershipVoucherPage />}
      />

      {/* NFT 설정 라우트 */}
      <Route path="/NFTmanagement" element={<NFTManagement />} />
      <Route path="/NFTCreate" element={<NFTCreatePage />} />

      {/* 쿠폰 설정 설정 라우트 */}
      <Route path="/couponManagement" element={<CouponManagement />} />
      <Route path="/couponSettingHistory" element={<CouponSettingHistory />} />

      {/* 결제 관리 라우트 */}
      <Route path="/paymentManagement" element={<PaymentManagement />} />
      <Route path="/couponvalidation" element={<CouponValidation />} />

      {/* Production Material */}
      {/* 생산 자재 관리 라우트 */}
      <Route path="/productionManagement" element={<ProductionManagement />} />
      <Route
        path="/productionRegistration"
        element={<ProductionRegistration />}
      />
      <Route path="/productionDetails" element={<ProductionDetails />} />
      <Route path="/materialRegistration" element={<MaterialRegistration />} />
      <Route path="/materialDetails" element={<MaterialDetails />} />
      <Route
        path="/registerBillOfMaterials"
        element={<RegisterBillOfMaterials />}
      />
      <Route
        path="/billOfMaterialsDetails"
        element={<BillOfMaterialsDetails />}
      />
      <Route path="/inventoryManagement" element={<InventoryManagement />} />
      <Route
        path="/receivedeliverregistration"
        element={<ReceiveDeliverRegistration />}
      />
      <Route path="/requestManagement" element={<RequestManagement />} />
      <Route path="/modelManagement" element={<ModelManagement />} />

      {/* 지역 및 서비스 라우트 */}
      <Route
        path="/regionAndServiceManagement"
        element={<RegionAndServiceManagement />}
      />
      <Route path="/createRegionMng" element={<RegionMngCreatePage />} />
      <Route //지역 상세보기
        path="/regionAndServiceManagement/regionDetailPage/:regionDetailId"
        element={<RegionDetailPage />}
      />
      <Route path="/createServiceMng" element={<ServiceMngCreatePage />} />

      {/* 운영관리 라우트 */}
      <Route path="/vehicleMng" element={<VehicleMngPage />} />
      <Route path="/batteryMng" element={<BatteryMngPage />} />
      <Route path="/stationMng" element={<StationMngPage />} />
      <Route path="/nfcCardManagement" element={<NFCCardManamgement />} />
      <Route path="/NfcCreate" element={<NfcCreatePage />} />
      <Route path="/NfcDetails/:id" element={<NfcDetails />} />

      {/* 탄소감축량 관리 - 방법론 관리 라우트 */}
      <Route path="/methodologyMng" element={<MethodologyMngListPage />} />
      <Route
        path="/methodologyMng/createMethodology"
        element={<CreateMethodologyPage />}
      />
      <Route
        path="/methodologyMng/updateMethodology/:id"
        element={<CreateMethodologyPage />}
      />

      {/* 탄소감축량 관리 - 프로젝트 관리 라우트 */}
      <Route path="/projectMng" element={<ProjectMngListPage />} />
      <Route
        path="/projectMng/projectDetails"
        element={<CreateProjectPage />}
      />
      <Route
        path="/projectMng/projectDetails/:id"
        element={<CreateProjectPage />}
      />

      {/* 탄소감축량 관리 - 고정 데이터/인자 관리 라우트 */}
      <Route path="/paramDataMng" element={<ParamDataMngListPage />} />
      {/* <Route path="/paramDataMng/param-data" element={<ParamDetailsPage />} /> */}

      {/* 탄소감축량 관리 - 모니터링 관리 라우트 */}
      <Route path="/monitoringMng" element={<MonitoringMngListPage />} />
      <Route
        path="/monitoringMng/monitoringDetails"
        element={<CreateMonitoringPage />}
      />
      <Route
        path="/monitoringMng/monitoringDetails/:id"
        element={<CreateMonitoringPage />}
      />

      {/* 블록체인 관리 */}
      <Route
        path="/blockchainMonitoring"
        element={<BlockchainMonitoringPage />}
      />

      {/* 시스템 관리 */}
      <Route path="/notiMng" element={<></>} />
      <Route path="/multilingual" element={<Multilingual />} />
      <Route path="/commonCode" element={<></>} />
      <Route path="/historyMng" element={<></>} />
      <Route path="/linkBatchMng" element={<></>} />
      <Route path="/authFramework" element={<></>} />
    </Routes>
  );
};

export default MainRoutes;
