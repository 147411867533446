import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { HorizontalSpacer } from "../../../../component/Spacer";
import { formatTimeMilliseconds } from "../../../../utils/DateUtil";
import { getInquiryStatusColor } from "../../section/InquiryHistoryItem";
import { InquiryHistoryStatus } from "../../model/InquiryHistoryStatus";

interface InquiryDetailHeaderProps {
  status: InquiryHistoryStatus;
  category: string;
  createdDate: number;
}
export function InquiryDetailHeader({
  status,
  category,
  createdDate,
}: InquiryDetailHeaderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Typography
          sx={{
            fontStyle: fontStyle.titleS,
            color: getInquiryStatusColor(status),
          }}
        >
          {status.toString()}
        </Typography>
        <HorizontalSpacer width={8} />
        <Box
          sx={{
            minWidth: "1px",
            height: "100%",
            py: "4px",
            backgroundColor: color.gray300,
          }}
        />
        <HorizontalSpacer width={8} />
        <Typography
          sx={{
            fontStyle: fontStyle.bodySr,
            color: color.gray600,
          }}
        >
          {category}
        </Typography>
      </Box>
      <HorizontalSpacer width={8} />
      <Typography
        sx={{
          fontStyle: fontStyle.bodySr,
          color: color.gray600,
        }}
      >
        {formatTimeMilliseconds("en", createdDate)}
      </Typography>
    </Box>
  );
}
