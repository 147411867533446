import { Box, Typography } from "@mui/material";
import Toolbar from "../../../../component/Toolbar";
import VerticalSpacer from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

import { FAQListUiState } from "./FAQListUiState";
import { FAQListUiEvent } from "./FAQListUiEvent";

import { Divider } from "../../../../component/Divider";
import FAQItem from "./FAQItem";
import CategoryList from "./CategoryList";
import { Category } from "./model/Category";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import { ROUTE_PATHS } from "../../../../constants/RouteConstants";

interface FAQListRenderProps {
  uiState: FAQListUiState;
  uiEvent: FAQListUiEvent;
}

const FAQListRender = ({ uiState, uiEvent }: FAQListRenderProps) => {
  const emptyTex = LocalizedText("ua_f110_nofaq_title");
  const emptySubText = LocalizedText("ua_f110_nofaq_message");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <Toolbar
        title="FAQ"
        onBack={() => {
          window.history.back();
        }}
      />
      <VerticalSpacer height={8} />
      <Box
        sx={{
          px: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontStyle: fontStyle.bodyLr,
            color: color.gray900,
          }}
        >
          {LocalizedText("ua_f110_faq_message")}
        </Typography>
        <VerticalSpacer height={16} />
        <CategoryList
          categoryList={uiState.categoryItems}
          onClick={function (category: Category): void {
            uiEvent.selectCategory(category);
          }}
        />
        <VerticalSpacer height={17} />
      </Box>

      <Divider />

      <Box
        sx={{
          height: "100%",
          px: "20px",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
        }}
      >
        <VerticalSpacer height={12} />
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {(uiState?.questions.length === 0 || uiState.questions == null) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontStyle: fontStyle.titleL,
                  color: color.default,
                  textAlign: "center",
                  whiteSpace: "pre-line",
                }}
              >
                {emptyTex}
              </Typography>
              <VerticalSpacer height={4} />
              <Typography
                sx={{
                  fontStyle: fontStyle.bodyMr,
                  color: color.gray600,
                  textAlign: "center",
                  whiteSpace: "pre-line",
                }}
              >
                {emptySubText}
              </Typography>
            </Box>
          )}

          {uiState?.questions.map((item, index) => (
            <Box key={index}>
              <FAQItem
                questionItem={item}
                onClick={() => {
                  uiEvent.selectQuestion(item);
                }}
              />
              <VerticalSpacer height={16} />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: "1px",
              width: "100%",
              position: "relative",
              backgroundColor: color.gray300,
            }}
          />
          <VerticalSpacer height={20} />
          <Typography
            sx={{
              fontStyle: fontStyle.bodyMr,
              color: color.default,
              textAlign: "center",
            }}
          >
            {LocalizedText("ua_f110_cantfind")}
          </Typography>
          <VerticalSpacer height={4} />
          <Typography
            sx={{
              fontStyle: fontStyle.titleS,
              color: color.gray600,
              textAlign: "center",
              position: "relative",
              display: "inline-block", // 밑줄을 텍스트 너비에 맞추기 위해 사용
              "&::after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "1px", // 텍스트와 밑줄 사이의 간격 조정
                height: "1px", // 밑줄 두께
                backgroundColor: color.gray500, // 밑줄 색상
              },
            }}
            onClick={() => uiEvent.navigate(ROUTE_PATHS.INQUIRY_PAGE)}
          >
            {LocalizedText("ua_f110_inquiry")}
          </Typography>
          <VerticalSpacer height={33} />
        </Box>
      </Box>
    </Box>
  );
};

export default FAQListRender;
