export class MaterialHistoryData {
  no: number;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  quantities: string;
  company: string;
  updater: string;
  registrationDate: number;

  constructor(
    no: number,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    quantities: string,
    company: string,
    updater: string,
    registrationDate: number
  ) {
    this.no = no;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.quantities = quantities;
    this.company = company;
    this.updater = updater;
    this.registrationDate = registrationDate;
  }
}

export const MaterialHistoryList: MaterialHistoryData[] = [
  new MaterialHistoryData(
    1,
    "ASSY",
    "-",
    "ASSY DISPENCER",
    "ASSY DISPENSER SE",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    2,
    "ASSY",
    "VBP-22ORGP",
    "BATTERY PACK",
    "ASSY BATTERY SET",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    3,
    "COMPONENT",
    "VBP-11A",
    "WHEEL",
    "WHEEL ASSEMBLY",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    4,
    "COMPONENT",
    "VBP-12B",
    "AXLE",
    "AXLE ASSEMBLY",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    5,
    "COMPONENT",
    "VBP-13C",
    "FRAME",
    "FRAME STRUCTURE",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    6,
    "ASSY",
    "VBP-14D",
    "HANDLE",
    "HANDLE GRIP",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    7,
    "ASSY",
    "VBP-15E",
    "MOTOR",
    "ELECTRIC MOTOR",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    8,
    "COMPONENT",
    "VBP-16F",
    "BATTERY",
    "LITHIUM ION BATTERY",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    9,
    "ASSY",
    "VBP-17G",
    "CHARGER",
    "FAST CHARGER",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    10,
    "COMPONENT",
    "VBP-18H",
    "SCREEN",
    "LCD SCREEN",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    11,
    "ASSY",
    "VBP-19I",
    "BUTTON",
    "POWER BUTTON",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    12,
    "COMPONENT",
    "VBP-20J",
    "SENSOR",
    "PROXIMITY SENSOR",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    13,
    "ASSY",
    "VBP-21K",
    "LENS",
    "CAMERA LENS",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    14,
    "COMPONENT",
    "VBP-22L",
    "FRAME",
    "FRAME SUPPORT",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    15,
    "ASSY",
    "VBP-23M",
    "CABLE",
    "USB CABLE",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    16,
    "COMPONENT",
    "VBP-24N",
    "SPEAKER",
    "BLUETOOTH SPEAKER",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    17,
    "ASSY",
    "VBP-25O",
    "HARD DRIVE",
    "EXTERNAL HDD",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    18,
    "COMPONENT",
    "VBP-26P",
    "GRAPHIC CARD",
    "NVIDIA GRAPHIC CARD",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    19,
    "ASSY",
    "VBP-27Q",
    "COOLER",
    "CPU COOLER",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    20,
    "COMPONENT",
    "VBP-28R",
    "FAN",
    "CASE FAN",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    21,
    "ASSY",
    "VBP-29S",
    "POWER SUPPLY",
    "500W PSU",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    22,
    "COMPONENT",
    "VBP-30T",
    "KEYBOARD",
    "MECHANICAL KEYBOARD",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    23,
    "ASSY",
    "VBP-31U",
    "MOUSE",
    "GAMING MOUSE",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    24,
    "COMPONENT",
    "VBP-32V",
    "MONITOR",
    "27 INCH MONITOR",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    25,
    "ASSY",
    "VBP-33W",
    "PRINTER",
    "COLOR PRINTER",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    26,
    "COMPONENT",
    "VBP-34X",
    "SCANNER",
    "DOCUMENT SCANNER",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    27,
    "ASSY",
    "VBP-35Y",
    "RACK",
    "SERVER RACK",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    28,
    "COMPONENT",
    "VBP-36Z",
    "SWITCH",
    "NETWORK SWITCH",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    29,
    "ASSY",
    "VBP-37A",
    "ROUTER",
    "WIRELESS ROUTER",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new MaterialHistoryData(
    30,
    "COMPONENT",
    "VBP-38B",
    "MODULATOR",
    "HDMI MODULATOR",
    "SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
];
