export class SpecificationHistoryData {
  no: number;
  parent: string;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  company: string;
  updater: string;
  lastUpdateDate: number;

  constructor(
    no: number,
    parent: string,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    company: string,
    updater: string,
    lastUpdateDate: number
  ) {
    this.no = no;
    this.parent = parent;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.company = company;
    this.updater = updater;
    this.lastUpdateDate = lastUpdateDate;
  }
}

export const SpecificationHistoryList: SpecificationHistoryData[] = [
  new SpecificationHistoryData(
    1,
    "VBP-22ORGP",
    "ASSY",
    "-",
    "ASSY DISPENCER",
    "ASSY DISPENSER SE",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    2,
    "VBP-23ORGP",
    "ASSY",
    "A",
    "BATTERY PACK",
    "ASSY BATTERY SET",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    3,
    "VBP-24ORGP",
    "COMPONENT",
    "B",
    "WHEEL",
    "WHEEL ASSEMBLY",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    4,
    "VBP-25ORGP",
    "COMPONENT",
    "C",
    "AXLE",
    "AXLE ASSEMBLY",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    5,
    "VBP-26ORGP",
    "COMPONENT",
    "D",
    "FRAME",
    "FRAME STRUCTURE",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    6,
    "VBP-27ORGP",
    "ASSY",
    "E",
    "HANDLE",
    "HANDLE GRIP",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    7,
    "VBP-28ORGP",
    "ASSY",
    "F",
    "MOTOR",
    "ELECTRIC MOTOR",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    8,
    "VBP-29ORGP",
    "COMPONENT",
    "G",
    "BATTERY",
    "LITHIUM ION BATTERY",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    9,
    "VBP-30ORGP",
    "ASSY",
    "H",
    "CHARGER",
    "FAST CHARGER",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    10,
    "VBP-31ORGP",
    "COMPONENT",
    "I",
    "SCREEN",
    "LCD SCREEN",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    11,
    "VBP-32ORGP",
    "ASSY",
    "J",
    "BUTTON",
    "POWER BUTTON",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    12,
    "VBP-33ORGP",
    "COMPONENT",
    "K",
    "SENSOR",
    "PROXIMITY SENSOR",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    13,
    "VBP-34ORGP",
    "ASSY",
    "L",
    "LENS",
    "CAMERA LENS",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    14,
    "VBP-35ORGP",
    "COMPONENT",
    "M",
    "FRAME",
    "FRAME SUPPORT",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    15,
    "VBP-36ORGP",
    "ASSY",
    "N",
    "CABLE",
    "USB CABLE",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    16,
    "VBP-37ORGP",
    "COMPONENT",
    "O",
    "SPEAKER",
    "BLUETOOTH SPEAKER",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    17,
    "VBP-38ORGP",
    "ASSY",
    "P",
    "HARD DRIVE",
    "EXTERNAL HDD",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    18,
    "VBP-39ORGP",
    "COMPONENT",
    "Q",
    "GRAPHIC CARD",
    "NVIDIA GRAPHIC CARD",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    19,
    "VBP-40ORGP",
    "ASSY",
    "R",
    "COOLER",
    "CPU COOLER",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    20,
    "VBP-41ORGP",
    "COMPONENT",
    "S",
    "FAN",
    "CASE FAN",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    21,
    "VBP-42ORGP",
    "ASSY",
    "T",
    "POWER SUPPLY",
    "500W PSU",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    22,
    "VBP-43ORGP",
    "COMPONENT",
    "U",
    "KEYBOARD",
    "MECHANICAL KEYBOARD",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    23,
    "VBP-44ORGP",
    "ASSY",
    "V",
    "MOUSE",
    "GAMING MOUSE",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    24,
    "VBP-45ORGP",
    "COMPONENT",
    "W",
    "MONITOR",
    "27 INCH MONITOR",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    25,
    "VBP-46ORGP",
    "ASSY",
    "X",
    "PRINTER",
    "COLOR PRINTER",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    26,
    "VBP-47ORGP",
    "COMPONENT",
    "Y",
    "SCANNER",
    "DOCUMENT SCANNER",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    27,
    "VBP-48ORGP",
    "ASSY",
    "Z",
    "RACK",
    "SERVER RACK",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    28,
    "VBP-49ORGP",
    "COMPONENT",
    "AA",
    "SWITCH",
    "NETWORK SWITCH",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    29,
    "VBP-50ORGP",
    "ASSY",
    "BB",
    "ROUTER",
    "WIRELESS ROUTER",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
  new SpecificationHistoryData(
    30,
    "VBP-51ORGP",
    "COMPONENT",
    "CC",
    "MODULATOR",
    "HDMI MODULATOR",
    "Verywords",
    "HJI (hji@verywords.com)",
    1716499200
  ),
];
