export class LanguageButtonModel {
  title: string;
  image: string;
  languageCode: string;
  isSelected: boolean;

  constructor(
    title: string,
    image: string,
    languageCode: string,
    isSelected: boolean,
  ) {
    this.title = title;
    this.image = image;
    this.languageCode = languageCode;
    this.isSelected = isSelected;
  }
}
