import { Box, Typography } from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import { color } from "../../../../theme/Color";
import CommonButton from "../../../../component/CommonButton";
import {
  ICON_SCOOTER,
  IMAGE_PONYA_HELLO_2_SHADOW,
} from "../../../../constants/appImagePath";
import { HasScooterUiState } from "../HasScooterUiState";
import { HasScooterUiEvent } from "../HasScooterUiEvent";

interface BeforeSelectNFTScreenProps {
  uiState: HasScooterUiState;
  uiEvent: HasScooterUiEvent;
}

const BeforeSelectNFTScreen = ({
  uiState,
  uiEvent,
}: BeforeSelectNFTScreenProps) => {
  return (
    <>
      <Box
        sx={{
          width: "calc(100% - 40px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={ICON_SCOOTER}
              alt="Scooter"
              sx={{
                position: "absolute",
                width: "250px", // 스쿠터 이미지 크기 조절
                height: "218.97px",
              }}
            />
            <Box
              component="img"
              src={IMAGE_PONYA_HELLO_2_SHADOW}
              sx={{ marginTop: "30.26px", width: "292px", zIndex: 1 }}
            />
          </Box>
          <VerticalSpacer height={20} />
          <Typography
            fontStyle={fontStyle.titleL}
            sx={{ display: "flex", textAlign: "center" }}
          >
            <HorizontalSpacer width={23.5} />
            {LocalizedText("ua_g300_readynft_title")}
            <HorizontalSpacer width={23.5} />
          </Typography>
          <VerticalSpacer height={4} />
          <Typography
            fontStyle={fontStyle.bodyMr}
            sx={{ display: "flex", textAlign: "center" }}
            color={color.gray600}
          >
            <HorizontalSpacer width={37} />
            {LocalizedText("ua_g300_readynft_message")}
            <HorizontalSpacer width={37} />
          </Typography>
        </Box>
      </Box>
      <VerticalSpacer height={66} />
      {/* 하단 버튼 */}
      <Box
        sx={{
          position: "absolute",
          bottom: "0px",
          width: "100%",
          display: "flex",
        }}
      >
        <CommonButton
          title={LocalizedText("ua_g300_selectcharacter_button")}
          textColor="white"
          onClick={() => {
            uiEvent.SetwWhatScreen("PickNFTScreen");
          }}
          sx={{
            mx: "20px",
            mb: "14px",
          }}
        />
      </Box>
    </>
  );
};

export default BeforeSelectNFTScreen;
