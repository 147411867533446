import React, { useEffect, useRef, useState } from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

// Custom map styles
const customMapStyles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#aeeeee" }]
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ color: "#f7f1df" }]
  }
];

type Library = "places" | "drawing" | "geometry" | "visualization";

const libraries: Library[] = ["places"];

interface MapProps {
  latitude: number;
  longitude: number;
  apiKey: string;
  onAddressSelected: (address: string, lat: number, lng: number) => void;
}

const GoogleMapWrapper: React.FC<MapProps> = ({
  latitude,
  longitude,
  apiKey,
  onAddressSelected
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      const mapInstance = new google.maps.Map(mapRef.current, {
        center: { lat: latitude, lng: longitude },
        zoom: 12,
        styles: customMapStyles
      });
      setMap(mapInstance);
    }
  }, [isLoaded, latitude, longitude]);

  useEffect(() => {
    if (map) {
      map.setCenter({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude, map]);

  const onSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
    setSearchBox(ref);
  };

  const handlePlacesChanged = () => {
    if (!searchBox || !map) return;

    const places = searchBox.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const location = place.geometry?.location;

      if (location) {
        const lat = location.lat();
        const lng = location.lng();

        map.setCenter(location);
        map.setZoom(15);

        new google.maps.Marker({
          position: location,
          map
        });

        // Log the entire place object for debugging
        console.log("Selected place:", place);

        // Check if formatted_address is valid
        if (place.formatted_address) {
          onAddressSelected(place.formatted_address, lat, lng);
        } else {
          console.error("Formatted address is undefined or empty");
          // Optionally, handle the case where the formatted address is not available
          // You could use a fallback or notify the user
        }
      }
    }
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "400px" }}>
      <LoadScript
        googleMapsApiKey={apiKey}
        libraries={libraries}
        onLoad={() => {
          console.log("Google Maps script loaded successfully");
          setIsLoaded(true);
        }}
        onError={(e) => console.error("Error loading Google Maps script:", e)}
      >
        {isLoaded && (
          <>
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                placeholder="Search for an address"
                style={{
                  boxSizing: "border-box",
                  border: "1px solid transparent",
                  width: "960px",
                  height: "45px",
                  padding: "0 16px",
                  borderRadius: "7px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  fontSize: "16px",
                  outline: "none",
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  zIndex: 10
                }}
              />
            </StandaloneSearchBox>
            <div ref={mapRef} style={{ width: "100%", height: "100%" }} />
          </>
        )}
      </LoadScript>
    </div>
  );
};

export default GoogleMapWrapper;
