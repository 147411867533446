import { Box, Typography } from "@mui/material";
import { RegionManagementServiceType } from "../model/RegionManagementData";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

export function ServiceBadge({
  serviceType,
}: {
  serviceType: RegionManagementServiceType;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        backgroundColor: getBackgroundColor(serviceType),
      }}
    >
      <Typography
        sx={{
          py: "2px",
          px: "8px",
          fontStyle: fontStyle.semiboldXXS,
          color: getTextColor(serviceType),
        }}
      >
        {serviceType}
      </Typography>
    </Box>
  );
}
// 뱃지중에 서비스타입 값을 받으면 내가 정의한 색 반환
function getTextColor(serviceType: RegionManagementServiceType) {
  switch (serviceType) {
    case RegionManagementServiceType.CHARGING:
      return color.successComplete;
    case RegionManagementServiceType.CS_CENTER:
      return color.warningWaiting2;
    case RegionManagementServiceType.CAFE:
      return color.primary500;
    case RegionManagementServiceType.RESTAURANT:
      return color.proceed;
    case RegionManagementServiceType.HOTEL:
      return color.proceed;
    case RegionManagementServiceType.HOTSPOT:
      return color.proceed;
    case RegionManagementServiceType.CLOSURE:
      return color.error;
  }
}

function getBackgroundColor(serviceType: RegionManagementServiceType) {
  switch (serviceType) {
    case RegionManagementServiceType.CHARGING:
      return color.backgroundSuccess;

    case RegionManagementServiceType.CS_CENTER:
      return color.backgroundWarning2;

    case RegionManagementServiceType.CAFE:
      return color.primary100;

    case RegionManagementServiceType.RESTAURANT:
      return color.backgroundProgress;
    case RegionManagementServiceType.HOTEL:
      return color.backgroundProgress;
    case RegionManagementServiceType.HOTSPOT:
      return color.backgroundProgress;
    case RegionManagementServiceType.CLOSURE:
      return color.backgroundError;
  }
}
