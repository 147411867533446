export const color = {
  // Grayscale Colors
  white: "#FFFFFF",
  paleGray: "#F9F9F9",
  paperGray: "#FDFDFD",
  lightGray: "#DCDCDC",
  gray: "#C2C2C2",
  duskGray: "#868686",
  darkGray: "#5E5E5E",
  black: "#000000",
  graphite: "#292929",

  // Purple Colors
  purple01: "#B469FF",
  purple01a30: "#4DB469FF",
  mint01: "#7BFAF2",

  // Neon Colors
  neon01: "#49F300",
  neon01a50: "#8049F300",
  neon02: "#6CFF2D",
  neon03: "#94FB67",
  neon04: "#A1FF78",
  neon05: "#EEFFE7",
  neon05a40: "#66EEFFE7",

  // Green Colors
  green01: "#88F21D",
  green02: "#6BEB33",
  green03: "#52C520",
  green03a30: "#4D52C520",
  green04: "#386524",

  // Yellow Colors
  yellow01: "#F2E800",
  yellow02: "#E8ED00",
  yellow03: "#BEED00",
  yellow03a40: "#66A1ED00",
  yellow04: "#465703",
  yellow05: "#222B00",

  // Red Colors
  red01: "#F9DEDC",
  red02: "#F2B8B5",
  red03: "#B3261E",
  red04: "#8C1D18",
  red05: "#601410",
  red06: "#410E0B",

  // Miscellaneous Colors
  cosmos: "#151515",

  // Theme Colors
  purple80: "#D0BCFF",
  purpleGrey80: "#CCC2DC",
  pink80: "#EFB8C8",

  purple40: "#6650A4",
  purpleGrey40: "#625B71",
  pink40: "#7D5260",

  mainColor: "#B693FB",
  disableColor: "#EBEBEB",
  disableButton: "#BFBFBF",
  ableButton: "#5D38E5",

  // POPLE Colors
  primary50: "#F7F6FD",
  primary100: "#F7F6FD",
  primary200: "#C7C6FB",
  primary300: "#9B93FB",
  primary400: "#8F78E2",
  primary500: "#5D38E5",
  primary600: "#3918B4",

  secondary100: "#F6FEFD",
  secondary200: "#D1FAF5",
  secondary300: "#A2F6EA",
  secondary400: "#60EFDB",
  secondary500: "#16DEC2",
  secondary600: "#14C3AA",

  // Additional Grayscale Colors
  gray50: "#F9F9F9",
  gray100: "#F2F2F2",
  gray200: "#EBEBEB",
  gray300: "#D9D9D9",
  gray400: "#BFBFBF",
  gray500: "#B3B3B3",
  gray600: "#999999",
  gray700: "#676665",
  gray800: "#4D4C4C",
  gray900: "#343332",

  baseWhite: "#F7F6FD",
  baseDimmer: "rgba(0, 0, 0, 0.4)",
  default: "#1B1918",

  // State Colors
  error: "#FF4C4C",
  warning: "#FFCD4C",
  warning2: "#FFA04C",
  success: "#2BCA86",
  progress: "#4C6FFF",

  backgroundError: "#FFE0E0",
  backgroundWarning: "#FFF6E0",
  backgroundWarning2: "#FFEFE0",
  backgroundSuccess: "#EBFAF3",
  backgroundProgress: "#E0E6FF",
  backgroundLogin: "#F3F3F4",

  // Tooling Colors
  toolingRed100: "#FFF4F4",
  toolingRed200: "#FEC7C5",
  toolingRed300: "#FCA4A1",
  toolingRed400: "#FB716D",
  toolingRed500: "#F9423C",

  toolingPink100: "#FEF3F8",
  toolingPink200: "#FFE0EE",
  toolingPink300: "#FFCCE5",
  toolingPink400: "#FFBADA",
  toolingPink500: "#FFA3CE",

  toolingGreen100: "#F9FCE8",
  toolingGreen200: "#EDF6BA",
  toolingGreen300: "#DDEE7F",
  toolingGreen400: "#D0E750",
  toolingGreen500: "#C0DF16",

  toolingPurple100: "#F7F6FD",
  toolingPurple200: "#C7C6FB",
  toolingPurple300: "#B693FB",
  toolingPurple400: "#8F78E2",
  toolingPurple500: "#5D38E5",

  toolingPurple500Hover: "#A38BFC",

  toolingMint100: "#F6FEFD",
  toolingMint200: "#D1FAF5",
  toolingMint300: "#A2F6EA",
  toolingMint400: "#60EFDB",
  toolingMint500: "#16DEC2",

  toolingBlue100: "#F5FCFE",
  toolingBlue200: "#CDF1F8",
  toolingBlue300: "#9BE2F2",
  toolingBlue400: "#74D7ED",
  toolingBlue500: "#38C6E5",

  // NFT Grade Color
  toolingRedBg: "#FEECEC",
  toolingPink2Bg: "#FEEEF7",
  toolingPrimaryBg: "#EFEBFC",
  toolingBlueBg: "#EBF9FC",
  toolingMintBg: "#E8F9F7",

  toolingRed: "#F9423C",
  toolingPink02: "#F552B4",
  toolingPrimary: "#5D38E5",
  toolingBlue: "#3888E5",
  toolingMint: "#14C3AA",

  ////////////////////////// Web Design System //////////////////////////

  // POPLE Colors
  primaryWeb50: "#FCFBFF",
  primaryWeb100: "#EFECFD",
  primaryWeb200: "#5D38E5",
  primaryWeb300: "#AD9BF1",
  primaryWeb400: "#8F78E2",
  primaryWeb500: "#5D38E5",
  primaryWeb700: "#3918B4",

  // Usage Colors
  successComplete: "#2DCE89",
  warningWaiting: "#FFC225",
  // error: "#A5A4A8",
  inactiveOff: "#8E8D92",
  proceed: "#335BFF",
  warningWaiting2: "#FF9233",

  // Usage BackGround
  successCompleteBg: "#EBFAF3",
  warningWaitingBg: "#FFF6E0",
  errorBg: "#FFE0E0",
  inactiveOffBg: "#E8E8E9",
  proceedBg: "#E0E6FF",
  warningWaiting2Bg: "#FFEFE0",

  // navigation
  navBg: "#5D38E5",
  navLogoText: "#5D38E5",
  navBtnUserInfo: "#5D38E5",

  ///////////////////////////////////////////////////////////////////////
};
