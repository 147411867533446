import { Box, Divider } from "@mui/material";
import { HorizontalSpacer } from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export enum CouponSettingsTabType {
  MANAGEMENT = "management",
  HISTORY = "history",
}
export function CouponSettingsTab({}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentTab, changeTab] = useState<CouponSettingsTabType>(
    location.pathname.includes("couponManagement")
      ? CouponSettingsTabType.MANAGEMENT
      : CouponSettingsTabType.HISTORY
  );

  useEffect(() => {
    if (currentTab === CouponSettingsTabType.MANAGEMENT) {
      navigate("/couponManagement");
    } else if (currentTab === CouponSettingsTabType.HISTORY) {
      navigate("/couponSettingHistory");
    }
  }, [currentTab, navigate]);
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == CouponSettingsTabType.MANAGEMENT
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(CouponSettingsTabType.MANAGEMENT);
        }}
      >
        Management
      </Box>
      <HorizontalSpacer width={16} />{/* Todo History부분 추후 구현 }
      {/* {localStorage.getItem("user_type") == "Partner" ? (
        ""
      ) : (
        <>
          <Divider orientation="vertical" />
          <HorizontalSpacer width={16} />
          <Box
            sx={{
              fontStyle: fontStyle.semiboldM,
              cursor: "pointer",
              color:
                currentTab == CouponSettingsTabType.HISTORY
                  ? color.primary500
                  : color.gray600,
            }}
            onClick={() => {
              changeTab(CouponSettingsTabType.HISTORY);
            }}
          >
            History
          </Box>
        </>
      )} */}
    </Box>
  );
}
