import { Box, FormControlLabel, SxProps, Theme, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { fontStyle } from "../theme/Style";
import { color } from "../theme/Color";
import { ICON_CHECK, ICON_INDETERMINATE } from "../constants/imagePath";

export const enum CheckboxState {
  Unchecked = "Unchecked", // 체크되지 않은 상태
  Checked = "Checked", // 체크된 상태
  Indeterminate = "Indeterminate", // 인디터미네이트 상태 (부분 체크)
}

export interface CommonCheckboxProps {
  checkboxState: CheckboxState;
  checkboxName?: string;
  sx?: SxProps<Theme>
  onClick: (newState: CheckboxState) => void; // 상위로 상태 전달
}

export function CommonCheckbox({
  checkboxState,
  checkboxName,
  sx,
  onClick,
}: CommonCheckboxProps) {
  const [currentState, setCurrentState] =
    useState<CheckboxState>(checkboxState);

  useEffect(() => {
    setCurrentState(checkboxState); // 외부 상태가 변경되면 내부 상태에 반영
  }, [checkboxState]);

  const handleClick = () => {
    let newState: CheckboxState;
    if (currentState === CheckboxState.Checked) {
      newState = CheckboxState.Unchecked;
    } else {
      newState = CheckboxState.Checked;
    }
    setCurrentState(newState);
    onClick(newState); // 변경된 상태를 상위로 전달
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "14.4px",
          height: "14.4px",
          borderRadius: "2.29px",
          borderWidth: "1.14px",
          borderStyle: "solid",
          backgroundColor:
            currentState === CheckboxState.Checked ||
            currentState === CheckboxState.Indeterminate
              ? color.primary500
              : "",
          borderColor:
            currentState === CheckboxState.Checked ||
            currentState === CheckboxState.Indeterminate
              ? color.primary500
              : currentState === CheckboxState.Unchecked
              ? color.gray500
              : "",
          marginRight: "6px",
          cursor: "pointer",
          ...sx
        }}
        onClick={handleClick}
      >
        <img
          src={
            currentState === CheckboxState.Checked
              ? ICON_CHECK
              : currentState === CheckboxState.Indeterminate
              ? ICON_INDETERMINATE
              : ""
          }
          alt=""
        />
      </Box>
      <Typography fontStyle={fontStyle.regularS} color={color.gray800}>
        {checkboxName || ""}
      </Typography>
    </Box>
  );
}
