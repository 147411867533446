import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import { color } from "../../../../../theme/Color";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { useState } from "react";
import CommonTextField from "../../../../../component/CommonTextField";

export function ProductionDetailsCardPossible() {
  const [region, setRegion] = useState("");
  return (
    <CardContent
      sx={{
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Product</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Serial Number
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
          />
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Image
          </Typography>
        </Box>
        <HorizontalSpacer width={28} />
        <Box sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Made
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            sx={{ minHeight: "48px" }}
            selectValue={region}
            onChange={(event) => {
              setRegion(event.target.value);
            }}
            menuitems={["Gwang-Ju"]}
            placeholder="Region"
          />
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Serial Number CELL PACK
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
          />
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Serial Number M-BMS-V240
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
          />
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Serial Number FET-V240
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
          />
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Memo CT_SENSOR HARNESS
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
          />
        </Box>
      </Box>
    </CardContent>
  );
}
