import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import { CommonSelect } from "../../../component/CommonSelect";
import CommonTextField from "../../../component/CommonTextField";
import { useEffect, useState } from "react";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";

export interface NfcRow {
  id: string;
  type: string;
  valid: string;
  userRegistrationDate: number;
  cardRegistrationDate: number;
  owner: string;
  ownerUuid: string;
  nfcCardId: string;
}

interface NfcDetailIdCardProps {
  type: string;
  typeError: boolean;
  setType: React.Dispatch<React.SetStateAction<string>>;
  valid: string;
  validError: boolean;
  setValid: React.Dispatch<React.SetStateAction<string>>;
  rows?: NfcRow;
  nfcCardId?: string;
  setNfcCardId: React.Dispatch<React.SetStateAction<string>>;
  nfcCardIdError: boolean;

  // reason: string;
  // setReason: React.Dispatch<React.SetStateAction<string>>;
}

export function NfcDetailIdCard({
  type,
  typeError,
  setType,
  valid,
  validError,
  setValid,
  rows,
  nfcCardId,
  setNfcCardId,
  nfcCardIdError,
}: // reason,
// setReason,
NfcDetailIdCardProps) {
  useEffect(() => {
    if (rows?.nfcCardId) {
      setNfcCardId(rows.nfcCardId);
    }

    if (rows?.valid) {
      setValid(rows.valid);
    }

    if (rows?.type) {
      setType(rows.type);
    }
  }, [
    rows?.nfcCardId,
    setNfcCardId,
    rows?.valid,
    setValid,
    rows?.type,
    setType,
  ]);

  return (
    <CardContent
      sx={{
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            ID
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
            value={nfcCardId}
            error={nfcCardIdError === true}
            onChange={(event) => {
              setNfcCardId(event.target.value);
            }}
          />
          {nfcCardIdError == true && (
            <>
              <VerticalSpacer height={4} />
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Invalid ID
              </Typography>
            </>
          )}
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Type
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            paddingBottom={14}
            paddingTop={14}
            height={20}
            error={typeError === true}
            sx={{
              minHeight: "48px",
              flexGrow: 1,
              fontStyle: fontStyle.regularS,
              color: color.default,
            }}
            itemSx={fontStyle.regularS}
            selectValue={type}
            onChange={(event) => {
              setType(event.target.value);
            }}
            menuitems={[
              "ISO14443A",
              "ISO14443B",
              "ISO15693",
              "FELICA",
              "ULTRALIGHT",
            ]}
            placeholder={rows?.type}
          />
          {typeError == true && (
            <>
              <VerticalSpacer height={4} />
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Select Type option
              </Typography>
            </>
          )}
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Valid
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            paddingBottom={14}
            paddingTop={14}
            height={20}
            error={validError === true}
            sx={{
              minHeight: "48px",
              flexGrow: 1,
              fontStyle: fontStyle.regularS,
              color: color.default,
            }}
            itemSx={fontStyle.regularS}
            selectValue={valid}
            onChange={(event) => {
              setValid(event.target.value);
            }}
            menuitems={["Active", "Inactive"]}
            placeholder={rows?.valid}
          />
          {validError == true && (
            <>
              <VerticalSpacer height={4} />
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Select Valid option
              </Typography>
            </>
          )}
          <VerticalSpacer height={40} />
          {/* <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Reason
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            paddingBottom={14}
            paddingTop={14}
            height={20}
            sx={{
              minHeight: "48px",
              flexGrow: 1,
              fontStyle: fontStyle.regularS,
            }}
            itemSx={fontStyle.regularS}
            selectValue={reason}
            onChange={(event) => {
              setReason(event.target.value);
            }}
            menuitems={
              valid == "Active"
                ? [
                    "Regist(New)",
                    "Regist(Lost)",
                    "Regist(Defect)",
                    "Regist(Request)",
                    "Regist(etc)",
                  ]
                : [
                    "No Regist",
                    "Inactive(Lost)",
                    "Inactive(Defect)",
                    "Inactive(Request)",
                    "Inactive(etc)",
                  ]
            }
            placeholder="Select"
          />
          <VerticalSpacer height={40} /> */}
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Card Registration Date
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{
              style: { padding: "14px 16px", height: "20px", zIndex: 100 },
            }}
            disabled
            value={LocalizedDate({
              date: rows?.cardRegistrationDate,
              type: DateFormatType.AdminType,
            })}
          />
        </Box>
      </Box>
    </CardContent>
  );
}
