import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CertificateItem, { CertificateStatus } from "../model/CertificateItem";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";


interface CertificateCardProps {
  cert: CertificateItem;
  onClick: () => void;
}

export function formatDate(dateString: string): string {
   // 날짜 문자열을 공백으로 분리
   const dateParts = dateString.split(' ');

   // 필요한 부분 추출: 날짜, 월, 연도
   const day = dateParts[2];
   const month = dateParts[1];
   const year = dateParts[5];

   // 원하는 형식으로 결합
   return `${day} ${month} ${year}`;
}

export default function CertificateCard({
  cert: { credentialSubject, status, type, vc },
  onClick,
}: CertificateCardProps) {
  const isDisabled =
    status === CertificateStatus.Expired ||
    status === CertificateStatus.Disposed;

  const getTitle = () => {
    switch (type[1]) {
      case "snsCredential":
        return LocalizedText("ua_g500_membershipvc");
      case "scooterPurchaseCredential":
        return LocalizedText("ua_g500_ownervc");
      case "scooterRentCredential":
        return LocalizedText("ua_g500_rentalvc");
      default:
        return "Certificate";
    }
  };

  const getStatusLabel = () => {
    switch (status) {
      case CertificateStatus.Disposed:
        return LocalizedText("ua_g500_disposed");
      case CertificateStatus.Expired:
        return LocalizedText("ua_g500_expired");
      default:
        return "";
    }
  };

  const isMemberCert = type.includes("snsCredential");

  return (
    <Box
      onClick={!isDisabled ? onClick : undefined}
      sx={{
        width: "100%",
        borderRadius: "10px",
        padding: "16px",
        backgroundColor: isDisabled ? color.gray200 : "white",
        boxShadow: "0px 1px 20px rgba(27, 25, 24, 0.03)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          fontStyle={fontStyle.titleM}
          color={isDisabled ? color.gray600 : color.default}
        >
          {getTitle()}
        </Typography>
        <VerticalSpacer height={4} />

        {isMemberCert ? (
          <Typography fontStyle={fontStyle.bodySr}>
            <Box component="span" sx={{ color: color.gray600 }}>
              {LocalizedText("ua_g500_email")}:
            </Box>{" "}
            <Box component="span" sx={{ color: color.gray800 }}>
              {credentialSubject.sns_id}
            </Box>
          </Typography>
        ) : (
          <Typography fontStyle={fontStyle.bodySr}>
            <Box component="span" sx={{ color: color.gray600 }}>
              {LocalizedText("ua_g500_vin")}:
            </Box>{" "}
            <Box component="span" sx={{ color: color.gray800 }}>
              {credentialSubject.carNo}
            </Box>
          </Typography>
        )}
        <VerticalSpacer height={2} />

        <Typography fontStyle={fontStyle.bodySr}>
          <Box component="span" sx={{ color: color.gray600 }}>
            {LocalizedText("ua_g500_issued")}:
          </Box>{" "}
          <Box component="span" sx={{ color: color.gray800 }}>
            {formatDate(credentialSubject.issueTime)}
          </Box>
        </Typography>
        <VerticalSpacer height={2} />

        {credentialSubject.expireTime && (
          <Typography fontStyle={fontStyle.bodySr}>
            <Box component="span" sx={{ color: color.gray600 }}>
              {LocalizedText("ua_g500_expiration")}:
            </Box>{" "}
            <Box component="span" sx={{ color: color.gray800 }}>
              {formatDate(credentialSubject.issueTime)} ~{" "}
              {formatDate(credentialSubject.expireTime)}
            </Box>
          </Typography>
        )}
      </Box>
      {!isDisabled && <ChevronRightIcon sx={{ color: color.gray600 }} />}
      {isDisabled && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: color.white,
            padding: "2px 8px 2px 8px",
            borderRadius: "5px",
          }}
        >
          <Typography fontStyle={fontStyle.titleS} color={color.gray600}>
            {getStatusLabel()}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
