import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";

interface NfcDiallogProps {
  handleClose: () => void;
  handleOk: () => void;
}

export function BackButtonDiallog({ handleClose, handleOk }: NfcDiallogProps) {
  return (
    <DialogContent
      sx={{
        width: 450,
        height: 170,
        // mt: -1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <DialogContentText
        id="alert-dialog-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontSize: 15,
          color: "black",
        }}
      >
        Do you want to stop editing and move?
        <br />
        The current content will not be saved.
      </DialogContentText>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          mt: 1.5,
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            textTransform: "none",
            height: 40,
            //   backgroundColor: "#5D38E5",
            color: "#5D38E5",
            borderRadius: 5,
            border: 1,
            borderColor: "#5D38E5",
            width: 95,
            ":hover": {
              backgroundColor: "#E9E9E9",
            },
          }}
        >
          Cancle
        </Button>
        <Button
          onClick={handleOk}
          sx={{
            textTransform: "none",
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            width: 75,
            ml: 2,
            ":hover": {
              backgroundColor: "#A38BFC",
            },
          }}
          autoFocus
        >
          OK
        </Button>
      </Box>
    </DialogContent>
  );
}
