import { Box, Divider } from "@mui/material";
import { HorizontalSpacer } from "../../../component/Spacer";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";

export enum RequestManagementTabType {
  REQUEST_MANAGEMENT_CONTROL,
  REQUEST_HISTORY,
}

interface RequestManagementTabProps {
  currentTab: RequestManagementTabType;
  changeTab: (tab: RequestManagementTabType) => void;
}

export function RequestManagementTab({
  currentTab,
  changeTab,
}: RequestManagementTabProps) {
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={12} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == RequestManagementTabType.REQUEST_MANAGEMENT_CONTROL
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(RequestManagementTabType.REQUEST_MANAGEMENT_CONTROL);
        }}
      >
        관리
      </Box>
      <HorizontalSpacer width={16} />
      <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == RequestManagementTabType.REQUEST_HISTORY
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(RequestManagementTabType.REQUEST_HISTORY);
        }}
      >
        이력
      </Box>
    </Box>
  );
}
