import { TableRow } from "@mui/material";
import { ModelManagementTableCell } from "./ModelManagementTableCell";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ModelData } from "../model/ModelData";
import LocalizedDate, { DateFormatType } from "../../../component/LocalizedDate";

interface ModelManagementTableRowProps {
  row: ModelData;
}

export function ModelManagementTableRow({ row }: ModelManagementTableRowProps) {
  return (
    <TableRow>
      <ModelManagementTableCell
        sx={{ paddingLeft: "24px" }}
        childrenContainerSx={{ width: "36px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.no}`}
          />
        }
        align={"center"}
      />
      <ModelManagementTableCell
        childrenContainerSx={{ width: "80px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.category}`}
          />
        }
        align={"center"}
      />
      <ModelManagementTableCell
        // childrenContainerSx={{ width: "Fill" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.modelName}`}
          />
        }
        align={"center"}
      />
      <ModelManagementTableCell
        childrenContainerSx={{ width: "140px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.productName}`}
          />
        }
        align={"center"}
      />
      <ModelManagementTableCell
        childrenContainerSx={{ width: "120px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.partsCode}`}
          />
        }
        align={"center"}
      />
      <ModelManagementTableCell
        childrenContainerSx={{ width: "120px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={LocalizedDate({
              date: row.lastUpdateDate,
              type: DateFormatType.AdminType,
            })}
          />
        }
      />
      <ModelManagementTableCell
        childrenContainerSx={{ width: "120px" }}
        sx={{ paddingRight: "24px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={LocalizedDate({
              date: row.registerDate,
              type: DateFormatType.AdminType,
            })}
          />
        }
      />
    </TableRow>
  );
}
