import { Box, TableRow } from "@mui/material";
import { Text } from "../../../component/Text";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { ReceiveDeliverTableCell } from "./RecieveDeliverTableCell";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { ReceiveDeliverStatusBadge } from "./RecieveDeliverStatusBadge";

interface ReceiveDeliverTableRowProps {
  row?: any;
}

export function ReceiveDeliverTableRow({ row }: ReceiveDeliverTableRowProps) {
  return (
    <TableRow>
      <ReceiveDeliverTableCell
        sx={{ paddingLeft: "24px" }}
        childrenContainerSx={{ width: "36px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.no}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "64px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.category}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "84px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.partsCode}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "106px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.productName}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "100px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.spec}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "86px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.company}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "53px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.quantities}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "72px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.branch}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "44px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.amount}
          />
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "59px" }}
        children={
          <>
            <Box>
              <ReceiveDeliverStatusBadge statusType={row.status} />
            </Box>
          </>
        }
        align={"center"}
      />
      <ReceiveDeliverTableCell
        childrenContainerSx={{ width: "120px" }}
        sx={{ paddingRight: "24px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={LocalizedDate({
              date: row.registerDate,
              type: DateFormatType.AdminType,
            })}
          />
        }
      />
    </TableRow>
  );
}
