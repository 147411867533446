import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const UserWalletGrid = () => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: 0 }}>
      <Grid item lg={4}>
        <Card
          sx={{ width: "100%", height: 520, borderRadius: 4 }}
          elevation={1}
        >
          <Box
            sx={{
              height: "57%",
              backgroundColor: "#EBEBEB",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            인증서 관련 이미지
          </Box>
          <CardContent>
            <Typography sx={{ mt: -0.5, fontWeight: "bold", fontSize: 18 }}>
              가입 증명서
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "80%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  mt: 1,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  // backgroundColor: "red",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Email ID
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Name
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Gender
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Register Date
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4}>
        <Card
          sx={{ width: "100%", height: 520, borderRadius: 4 }}
          elevation={1}
        >
          <Box
            sx={{
              height: "57%",
              backgroundColor: "#EBEBEB",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            인증서 관련 이미지
          </Box>
          <CardContent>
            <Typography sx={{ mt: -0.5, fontWeight: "bold", fontSize: 18 }}>
              스쿠터 소유 증명서
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "80%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  mt: 1,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  // backgroundColor: "red",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Email ID
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Name
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Gender
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Register Date
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4}>
        <Card
          sx={{ width: "100%", height: 520, borderRadius: 4 }}
          elevation={1}
        >
          <Box
            sx={{
              height: "57%",
              backgroundColor: "#EBEBEB",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            인증서 관련 이미지
          </Box>
          <CardContent>
            <Typography sx={{ mt: -0.5, fontWeight: "bold", fontSize: 18 }}>
              스쿠터 렌탈 증명서
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "80%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  mt: 1,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  // backgroundColor: "red",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Email ID
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Name
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Gender
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                    Register Date
                  </Typography>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: 35,
                      },
                    }}
                  ></TextField>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserWalletGrid;
