import { CustomerSupportStationResponse } from "../model/CustomerSupportStationResponse";
import { CustomerSupportStationUiModel } from "../model/CustomerSupportStationUiModel";

export class CustomerSupportStationMapper {
  static toUiModel(
    response: CustomerSupportStationResponse,
  ): CustomerSupportStationUiModel {
    return new CustomerSupportStationUiModel(
      response.svcId, // preferredStationId에 매핑
      response.svcAreaNm, // serviceAreaName에 매핑
      response.openTime, // openTime에 매핑
      response.closeTime, // closeTime에 매핑
    );
  }
}
