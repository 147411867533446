import { useEffect, useState } from "react";

// 스토리지 키 상수
export const LANGUAGE_CODE_STORAGE_KEY = "LANGUAGE_CODE_STORAGE_KEY";
export const COUNTRY_CODE_STORAGE_KEY = "COUNTRY_CODE_STORAGE_KEY";
export const LOCALE_STORAGE_KEY = "LOCALE_STORAGE_KEY";

export const useLocale = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 저장된 로케일, 언어 및 국가 코드 불러오기
    const storedLocale = localStorage.getItem(LOCALE_STORAGE_KEY);
    const storedLanguageCode = localStorage.getItem(LANGUAGE_CODE_STORAGE_KEY);
    const storedCountryCode = localStorage.getItem(COUNTRY_CODE_STORAGE_KEY);

    if (storedLocale && storedLanguageCode && storedCountryCode) {
      // 이미 저장된 경우 아무 작업도 하지 않음
    } else {
      const currentLocale: string = navigator.language; // 예: "ko-KR"
      let locale: string;
      if (
        currentLocale === "ko-KR" || // 미지원
        currentLocale === "en-US" ||
        currentLocale === "km-KH"
      ) {
        locale = currentLocale;
      } else {
        locale = "en-US";
      }
      const [languageCode, countryCode] = locale.split("-");

      // 언어 코드, 국가 코드, 로케일을 각각 로컬 스토리지에 저장
      setLanguageCode(languageCode);
      setCountryCode(countryCode);
      setLocale(locale);
    }
    setLoading(false);
  }, []);

  return loading;
};

// 언어 코드를 로컬 스토리지에 저장
export function setLanguageCode(code: string): void {
  localStorage.setItem(LANGUAGE_CODE_STORAGE_KEY, code);
}

// 국가 코드를 로컬 스토리지에 저장
export function setCountryCode(code: string): void {
  localStorage.setItem(COUNTRY_CODE_STORAGE_KEY, code);
}

// 로케일을 로컬 스토리지에 저장
export function setLocale(locale: string): void {
  localStorage.setItem(LOCALE_STORAGE_KEY, locale);
}

// 로컬 스토리지에서 언어 코드를 가져옴, 없으면 navigator.language에서 추출
export function getLanguageCode(): string {
  return (
    localStorage.getItem(LANGUAGE_CODE_STORAGE_KEY) ??
    navigator.language.split("-")[0]
  );
}

// 로컬 스토리지에서 국가 코드를 가져옴, 없으면 navigator.language에서 추출
export function getCountryCode(): string {
  return (
    localStorage.getItem(COUNTRY_CODE_STORAGE_KEY) ??
    navigator.language.split("-")[1]
  );
}

// 로컬 스토리지에서 로케일을 가져옴, 없으면 navigator.language 반환
export function getLocale(): string {
  return localStorage.getItem(LOCALE_STORAGE_KEY) ?? navigator.language;
}
