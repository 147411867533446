import { useEffect, useState } from "react";
import { InquiryPageUiState } from "./InquiryPageUiState";
import { InquiryPageSideEffect } from "./InquiryPageSideEffect";
import InquiryPageRender from "./InquiryPageRender";
import { useNavigate } from "react-router-dom";
import { InquiryPageUiEvent } from "./InquiryPageUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import { InquiryHistoryModel } from "./model/InquiryHistoryModel";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { CalendarType } from "../../component/InquiryPeriod/CalendarDrawer";

export default function InquiryPage() {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(
    new InquiryPageUiState([], 0, 0, 0, CalendarType.START, false, false, false)
  );

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    getAllInquiryHistory().then((r) => {});
  }, []);

  const sideEffect: InquiryPageSideEffect = {
    back: () => {
      window.history.back();
    },
    navigate: (path: string, options?: { state: any }) => {
      console.log("navigate to ", path, options);
      navigate(path, options);
    },
    requestInquiryHistory: (
      startDate: number | undefined,
      endDate: number,
      monthsToSubtract: number | undefined
    ) => {
      getInquiryHistory(startDate, endDate, monthsToSubtract);
    },
  };

  const uiEvent: InquiryPageUiEvent = {
    onClickCustomDataPicker: function (): void {
      sideEffect.back();
    },
    openDatePicker: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDataPicker: true,
        };
      });
    },
    closeDatePicker: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDataPicker: false,
        };
      });
    },
    openCalendar: function (type: CalendarType): void {
      setUiState((prev) => {
        return {
          ...prev,
          isCalendarOpen: true,
          calendarType: type,
        };
      });
    },
    closeCalendar: function (type: CalendarType, date: number): void {
      setUiState((prev) => {
        return {
          ...prev,
          isCalendarOpen: false,
          startDate: type === CalendarType.START ? date : prev.startDate,
          endDate: type === CalendarType.END ? date : prev.endDate,
          selectedDate: 0,
        };
      });
    },
    selectDate: function (date: number): void {
      setUiState((prev) => {
        return {
          ...prev,
          selectedDate: date,
          startDate:
            prev.calendarType === CalendarType.START ? date : prev.startDate,
          endDate: prev.calendarType === CalendarType.END ? date : prev.endDate,
        };
      });
    },
    showDateAlert: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isDateAlertOpen: true,
        };
      });
    },
    hideDateAlert: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isDateAlertOpen: false,
        };
      });
    },
  };

  const getAllInquiryHistory = async () => {
    const body = {
      page: 1,
      uuid: localStorage.getItem("uuid") ?? "",
    };
    try {
      const response = await ApiService.MobilePost(
        "/pw/ua/contents/personal/list",
        body
      );
      const data = response?.data?.body;

      if (data) {
        const contentList = data.map(
          (item: InquiryHistoryModel) =>
            new InquiryHistoryModel(
              item.status,
              item.title,
              item.category,
              item.id,
              item.registrationDate
            )
        );

        setUiState((prev) => {
          return {
            ...prev,
            historyList: contentList,
          };
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getInquiryHistory = async (
    start: number | undefined,
    end: number,
    monthsToSubtract: number | undefined
  ) => {
    const targetDate = calculateDateFromInputDate(end, monthsToSubtract ?? 0);
    const body = {
      endDate: end,
      page: 1,
      uuid: localStorage.getItem("uuid") ?? "",
      startDate: start ?? targetDate,
    };
    try {
      const response = await ApiService.MobilePost(
        "/pw/ua/contents/personal/filterByDate",
        body
      );
      const data = response?.data?.body;

      if (data) {
        const contentList = data.map(
          (item: InquiryHistoryModel) =>
            new InquiryHistoryModel(
              item.status,
              item.title,
              item.category,
              item.id,
              item.registrationDate
            )
        );

        setUiState((prev) => {
          return {
            ...prev,
            // historyList: mock,
            historyList: contentList,
          };
        });
        // setLoading(false);
      }
    } catch (e) {
      // setError("Failed to load data.");
      console.error(e);
    }
  };

  function calculateDateFromInputDate(
    endDate: number,
    monthsToSubtract: number
  ): number {
    const endDateObj = new Date(endDate); // endDate를 Date 객체로 변환
    const startDateObj = new Date(endDateObj); // 복사해서 새로운 Date 객체 생성

    // 한 달(30일)을 빼기
    startDateObj.setMonth(startDateObj.getMonth() - monthsToSubtract);

    return startDateObj.getTime(); // startDate를 타임스탬프 형식으로 반환
  }

  return (
    <InquiryPageRender
      uiState={uiState}
      sideEffect={sideEffect}
      uiEvent={uiEvent}
    />
  );
}
