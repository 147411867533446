import { Inquiry } from "./Inquiry";
import { InquiryAnswer } from "./InquiryAnswer";

export class InquiryDetailPageUiState {
  inquiry?: Inquiry;
  inquiryAnswer?: InquiryAnswer[];
  isVisibleDeleteDialog: boolean;

  constructor(
    inquiry?: Inquiry,
    inquiryAnswer?: InquiryAnswer[],
    isVisibleDeleteDialog: boolean = false,
  ) {
    this.inquiry = inquiry;
    this.inquiryAnswer = inquiryAnswer;
    this.isVisibleDeleteDialog = isVisibleDeleteDialog;
  }
}
