import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from "react";
import { styled } from "styled-components";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Radio,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import TextEditor from "../../../component/TextEditor";
import { MethodologyProvider } from "../../../context/MethodologyContext";
import { useMethodologies } from "../../../context/MethodologyContext";
import GoogleMapWrapper from "./GoogleMapWrapper";
import { MethodologyType } from "../methodology/enum";
import { useParams } from "react-router-dom";
import { ProjectFormData } from "../../../store/projectType";
import { areaOptions, subAreaOptions } from "../../../store/methodologyType";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import { getFileName } from "../../../utils/FileUtil";

interface ProjectInfoPageProps {
  formData: ProjectFormData;
  tempMethodology: any;
  setTempMethodology: any;
  setFormData: React.Dispatch<React.SetStateAction<ProjectFormData>>;
  errors?: { [key: string]: string };
}
interface WorkspaceImage {
  name: string;
}
interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "48px",
  padding: "12px 16px",
  gap: "2px",
  borderRadius: "10px 0px 0px 0px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "0",
  "& .MuiInputBase-root": {
    height: "48px",
  },
}));
interface Methodology {
  id: number;
  title: string;
  methodologyIdName: string;
  area: string;
  subArea: string;
  reductionAmount: number;
}
const ProjectInfoPage: React.FC<ProjectInfoPageProps> = ({
  formData,
  setFormData,
  tempMethodology,
  setTempMethodology,
  errors,
}) => {
  const { id } = useParams<{ id: string }>();
  const [methodologyData, setMethodologyData] = useState<number | string>("");
  const [menuItems, setMenuItems] = useState<Methodology[]>([]);
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedSubArea, setSelectedSubArea] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<File[]>(
    formData.workspaceImages as File[]
  );

  const apiKey =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    "AIzaSyC5UuKjoIZ8W24wn-ASHVvkeIcy1Gt4VrI";
  const [latitude, setLatitude] = useState<number | undefined>(
    formData.latitude
  );
  const [longitude, setLongitude] = useState<number | undefined>(
    formData.longitude
  );
  const [address, setAddress] = useState<string>(
    formData.company?.address || ""
  );
  const [selectedMethodologyId, setSelectedMethodologyId] = useState<
    number | null
  >(formData?.methodology?.id || formData.methodologyId || null);

  useEffect(() => {
    if (formData.latitude !== latitude) {
      setLatitude(formData.latitude);
    }
    if (formData.longitude !== longitude) {
      setLongitude(formData.longitude);
    }

    if (formData.company && formData.company.address !== address) {
      setAddress(formData.company.address || "");
    }
  }, [formData.latitude, formData.longitude, formData.company?.address]);

  useEffect(() => {
    if (formData.latitude !== latitude) {
      setLatitude(formData.latitude);
    }
    if (formData.longitude !== longitude) {
      setLongitude(formData.longitude);
    }

    if (formData.company && formData.company.address !== address) {
      setAddress(formData.company.address || "");
    }
  }, [formData.latitude, formData.longitude, formData.company?.address]);
  const [isExported, setIsExported] = useState<boolean>(false);
  const [methodologies, setMethodologies] = useState<Methodology[]>([]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    console.log("Radio selected:", selectedValue);

    setFormData((prevData) => ({
      ...prevData,
      isExported: selectedValue === "b",
    }));
  };

  const handleTextChange = (fieldName: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSelectMethodologyChange = (event: SelectChangeEvent<any>) => {
    const methodologyId = event.target.value;
    const selected = methodologies.find((mth) => mth.id === methodologyId);
    if (selected) {
      const area =
        areaOptions.find((el) => el.label.includes(selected?.area))?.value ||
        "";
      const subArea =
        subAreaOptions.find((el) => el.label === selected?.subArea)?.value ||
        "";
      setFormData((prevData) => ({
        ...prevData,
        methodologyId: methodologyId,
      }));
      setTempMethodology({
        area: area,
        subArea: subArea,
      });
      setSelectedMethodologyId(methodologyId);
      setSelectedArea(area);
      setSelectedSubArea(subArea);
    }
  };

  useEffect(() => {
    const fetchMethodologyData = async () => {
      try {
        const response = await fetch(
          SERVER_CONFIG.CARBON_SERVER + "/methodology/list"
        );
        const data: Methodology[] = await response.json();
        setMethodologies(data);

        const selectedMethodology = data.find(
          (item) =>
            item.id === formData.methodology?.id || selectedMethodologyId
        );
        if (selectedMethodology) {
          setMethodologyData(selectedMethodology.id);
        }
      } catch (error) {
        console.error("Error fetching methodology data: ", error);
      }
    };
    fetchMethodologyData();
  }, [formData.methodology?.id]);

  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        workspaceImages: images,
      };
    });
  }, [images]);

  useEffect(() => {
    if (formData?.methodology?.area || tempMethodology?.area)
      setSelectedArea(formData?.methodology?.area || tempMethodology?.area);
    if (formData?.methodology?.subArea || tempMethodology?.subArea)
      setSelectedSubArea(
        formData?.methodology?.subArea || tempMethodology?.subArea
      );
    if (
      id &&
      formData.businessValidationReportFile &&
      typeof formData.businessValidationReportFile === "string"
    ) {
      setFileName(getFileName(formData.businessValidationReportFile));
    }
  }, []);

  const handleSubAreaChange = (event: SelectChangeEvent<string>) => {
    setSelectedSubArea(event.target.value || "");
  };
  const handleImageFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files);
      setImages((prevImages) => [...prevImages, ...filesArray]);
    } else {
      console.error("No files selected");
    }
  };

  const handleTitleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({ ...prev, title: event.target.value }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.target;
    const keys = name.split(".");

    setFormData((prevFormData) => {
      if (keys.length === 1) {
        return { ...prevFormData, [name]: value };
      } else {
        const [parentKey, childKey] = keys;
        return {
          ...prevFormData,
          [parentKey]: {
            ...(prevFormData as any)[parentKey],
            [childKey]: value,
          },
        };
      }
    });
  };
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleReductionAreaChange = (event: SelectChangeEvent<string>) => {
    setSelectedArea(event.target.value);
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleAddressSelection = (
    address: string,
    lat: number,
    lng: number
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: address,
      detailAddress: prevFormData?.detailAddress ?? "",
      latitude: lat || prevFormData.latitude,
      longitude: lng || prevFormData.longitude,
    }));
  };

  const handleReductionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numericValue =
      value === "" ? "" : !isNaN(Number(value)) ? Number(value) : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: numericValue,
    }));
  };

  const [fileName, setFileName] = useState("");
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    const fileInput = event.target.files;
    if (files && files?.length > 0) {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: files[0],
        };
      });
    }

    if (fileInput && fileInput.length > 0) {
      const selectedFile = fileInput[0];
      setFileName(selectedFile.name);
    } else {
      setFileName("");
    }
  };

  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,
          marginLeft: "17px",
          lineHeight: "18px",
          fontWeight: 700,
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container rowSpacing={4.5} sx={{ mt: 0 }}>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader sx={{ mb: "-18px" }} />
            <Box sx={{ m: 1, display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "100%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    타 기관 등록(신청) 여부
                  </CustomTypography>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box>
                      <Radio
                        checked={!formData.isExported}
                        onChange={handleRadioChange}
                        value="a"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "a" }}
                        sx={{
                          color: "#5D38E5",
                          "&.Mui-checked": {
                            color: "#5D38E5",
                          },
                        }}
                      />
                      없음
                    </Box>
                    <Box sx={{ ml: 5 }}>
                      <Radio
                        checked={formData.isExported}
                        onChange={handleRadioChange}
                        value="b"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "B" }}
                        sx={{
                          color: "#5D38E5",
                          "&.Mui-checked": {
                            color: "#5D38E5",
                          },
                        }}
                      />
                      있음 (사업신청 단계에서 등록 인증서 필수 첨부)
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "100%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    프로젝트명
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.title}
                    name="title"
                    onChange={handleTitleChange}
                    InputProps={{ style: { borderRadius: "10px" } }}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    유효기간
                  </CustomTypography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <CustomTextField
                      type="date"
                      sx={{ width: "48.5%" }}
                      size="small"
                      value={formData.startDate}
                      name="startDate"
                      onChange={handleChange}
                      InputProps={{ style: { borderRadius: "10px" } }}
                    />
                    <CustomTypography
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      ~
                    </CustomTypography>
                    <CustomTextField
                      sx={{ width: "48.5%" }}
                      size="small"
                      type="date"
                      value={formData.endDate}
                      name="endDate"
                      onChange={handleChange}
                      InputProps={{ style: { borderRadius: "10px" } }}
                    />
                  </Box>
                </Box>

                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold", mt: 1 }}>
                    사업 계획서 상의 온실가스 감축량
                  </CustomTypography>
                  <CustomTextField
                    value={formData.reductionAmount}
                    size="small"
                    type="number"
                    name="reductionAmount"
                    sx={{ width: "100%" }}
                    onChange={handleReductionChange}
                    InputProps={{
                      style: { borderRadius: "10px" },
                      endAdornment: (
                        <span style={{ color: "#99989D", width: "80px" }}>
                          tCO2-eq
                        </span>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    방법론
                  </CustomTypography>
                  <Select
                    value={
                      selectedMethodologyId !== null
                        ? selectedMethodologyId.toString()
                        : ""
                    }
                    onChange={handleSelectMethodologyChange}
                    displayEmpty
                    size="small"
                    IconComponent={(props) => (
                      <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                    )}
                    sx={{
                      marginLeft: "15px",
                      width: "95%",
                      minHeight: "48px",
                      opacity: 1,
                      borderRadius: "10px",
                      mt: 2,
                      gap: "2px",
                      backgroundColor: "#ffff",
                      "&:hover": {
                        borderColor: "#F9F6FD",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      방법론 선택
                    </MenuItem>
                    {methodologies.map((methodology) => (
                      <MenuItem key={methodology.id} value={methodology.id}>
                        {methodology.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "48.5%" }}>
                        <CustomTypography sx={{ fontWeight: "bold" }}>
                          감축 분야
                        </CustomTypography>
                        <Select
                          sx={{
                            marginLeft: "15px",
                            width: "95%",
                            minHeight: "48px",
                            opacity: 1,
                            borderRadius: "10px",
                            mt: 2,
                            gap: "2px",
                            backgroundColor: "#ffff",
                            "&:hover": {
                              borderColor: "#F9F6FD",
                            },
                          }}
                          displayEmpty
                          value={selectedArea}
                          onChange={handleReductionAreaChange}
                          size="small"
                          inputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                          renderValue={(selected) => {
                            return areaOptions.find(
                              (x) => x.value === selectedArea
                            )?.label;
                          }}
                        >
                          {areaOptions.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>

                      <Box sx={{ width: "48.5%" }}>
                        <CustomTypography sx={{ fontWeight: "bold" }}>
                          세부 분야
                        </CustomTypography>
                        <Select
                          value={selectedSubArea}
                          onChange={handleSubAreaChange}
                          size="small"
                          inputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                          sx={{
                            marginLeft: "15px",
                            width: "95%",
                            minHeight: "48px",
                            opacity: 1,
                            borderRadius: "10px",
                            mt: 2,
                            gap: "2px",
                            backgroundColor: "#ffff",
                            "&:hover": {
                              borderColor: "#F9F6FD",
                            },
                          }}
                          renderValue={(selected) => {
                            return subAreaOptions.find(
                              (x) => x.value === selectedSubArea
                            )?.label;
                          }}
                        >
                          {subAreaOptions.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    주소
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.address || ""}
                    name="address"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                      readOnly: true,
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    상세 주소 입력
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={formData.detailAddress || ""}
                    name="detailAddress"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    위도
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1, borderRadius: "10px" }}
                    size="small"
                    value={latitude !== undefined ? latitude : ""}
                    name="latitude"
                    onChange={(e) => setLatitude(Number(e.target.value))}
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "#f0f0f0",
                        color: "#a0a0a0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    경도
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={longitude !== undefined ? longitude : ""}
                    name="longitude"
                    onChange={(e) => setLongitude(Number(e.target.value))}
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "#f0f0f0",
                        color: "#a0a0a0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "48px",
                    padding: "12px 16px",
                    gap: "2px",
                    borderRadius: "10px 0px 0px 0px",
                    border: "1px solid transparent",
                    opacity: 1,
                    marginLeft: "0",
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 1,
                      borderColor: "#B0AFB3",
                      mt: 1,
                      borderRadius: 3,
                      height: "400px",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <GoogleMapWrapper
                      latitude={latitude || 37.7749}
                      longitude={longitude || -122.4194}
                      apiKey={apiKey}
                      onAddressSelected={handleAddressSelection}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      mb: 1,
                    }}
                  >
                    <CustomTypography sx={{ mr: 2, fontWeight: "bold" }}>
                      사업장 사진
                    </CustomTypography>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#5D38E5",
                        borderRadius: "20px",
                        textTransform: "none",
                        borderColor: "#5D38E5",
                        "&:hover": {
                          backgroundColor: "#5D38E5",
                          color: "white",
                        },
                      }}
                      onClick={() => fileInputRef.current?.click()}
                    >
                      Add Images
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      hidden
                      multiple
                      accept="image/*"
                      onChange={handleImageFileChange}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      height: 280,
                      marginLeft: "15px",
                      marginRight: "15px",
                      p: 1,
                      overflowY: "auto",
                      gap: 1,
                      borderColor: "#B0AFB3",
                      padding: "12px 16px",
                      borderRadius: "10px",
                      border: "1px solid ",
                      opacity: 1,
                      width: "96.8%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 3,
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      {images &&
                        images?.map((image, index) => (
                          <Box
                            key={index}
                            sx={{
                              position: "relative",
                              width: 130,
                              height: 130,
                              borderRadius: "15%",
                              overflow: "hidden",
                              border: 1,
                              borderColor: "#B0AFB3",
                              backgroundColor: "#f0f0f0",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`uploaded ${index}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <IconButton
                              onClick={() => handleRemoveImage(index)}
                              sx={{
                                position: "absolute",
                                top: 1.5,
                                right: 1.5,
                                backgroundColor: "#53515A",
                                "&:hover": {
                                  backgroundColor: "#53515A",
                                },
                                p: 0,
                              }}
                            >
                              <RemoveIcon
                                sx={{ fontSize: 28, color: "white" }}
                              />
                            </IconButton>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                {/* Container for CustomTypography and Button */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    사업 검증 보고서 등록
                  </CustomTypography>
                  <input
                    type="file"
                    name="businessValidationReportFile"
                    // value={formData.businessValidationReportFile}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <Button
                      component="span"
                      variant="text"
                      color="inherit"
                      sx={{
                        ml: 2,
                        marginRight: "10px",
                        textTransform: "none",
                        textDecoration: "underline",
                      }}
                      onChange={handleFileChange}
                    >
                      Upload file
                    </Button>
                  </label>
                </Box>

                <CustomTextField
                  onChange={handleTextFieldChange}
                  sx={{ width: "100%" }}
                  size="small"
                  value={fileName}
                  name="fileName"
                  InputProps={{
                    style: { borderRadius: "10px" },
                    inputProps: { readOnly: true },
                  }}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title={"Details"}
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box>
                <CustomTypography
                  sx={{ fontWeight: "bold", mb: 1, marginLeft: 0 }}
                >
                  프로젝트 내용 및 목적
                </CustomTypography>
                <Box
                  sx={{
                    maxWidth: "100%", // Set the box to take full width
                    width: "1018px",
                    marginLeft: "25px", // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("projectContent", val)
                    }
                    minHeight={200}
                    data={formData.projectContent}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box>
                <CustomTypography sx={{ fontWeight: "bold", mb: 2 }}>
                  사업자 및 온실가스 감축량 소유권
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px", // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("businessOwnership", val)
                    }
                    minHeight={200}
                    data={formData.businessOwnership}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box>
                <CustomTypography sx={{ fontWeight: "bold", mb: 2 }}>
                  프로젝트 인증실적의 중복성 평가
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px", // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("businessAssignment", val)
                    }
                    minHeight={200}
                    data={formData.businessAssignment}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomTypography sx={{ fontWeight: "bold", mb: 2 }}>
                  사업 전,후 공정
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px",
                    mt: 1, // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("prePostProcess", val)
                    }
                    minHeight={200}
                    data={formData.prePostProcess}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomTypography
                  sx={{ fontWeight: "bold", mb: 2, marginLeft: "50px" }}
                >
                  사업 이행 상태
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px",
                    mt: 1, // Subtract 30px (15px on each side) for left-right gap
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("businessPerformanceStatus", val)
                    }
                    minHeight={200}
                    data={formData.businessPerformanceStatus}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomTypography
                  sx={{ fontWeight: "bold", mb: 1, marginLeft: "52px" }}
                >
                  사업 승인 후 변경사항
                </CustomTypography>
                <Box
                  sx={{
                    width: "1018px",
                    marginLeft: "25px",
                    mt: 1,
                  }}
                >
                  <TextEditor
                    setData={(val: string) =>
                      handleTextChange("changeAfterBusinessApproval", val)
                    }
                    minHeight={200}
                    data={formData.changeAfterBusinessApproval}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProjectInfoPage;
