import {
  Box,
  CardContent,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import VerticalSpacer from "../../../component/Spacer";
import CommonTextField from "../../../component/CommonTextField";
import { ICON_SEARCH } from "../../../constants/appImagePath";
import { ApiService } from "../../../restAPI/ApiService";
import { useEffect, useState } from "react";
import { NfcRow } from "./NfcDetailIdCard";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";

interface NfcDetailOwnerCardProps {
  owner: string;
  setOwner: React.Dispatch<React.SetStateAction<string>>;
  rows?: NfcRow;
  ownerUuid: string;
  setOwnerUuid: React.Dispatch<React.SetStateAction<string>>;
}

export function NfcDetailOwnerCard({
  owner,
  setOwner,
  rows,
  ownerUuid,
  setOwnerUuid,
}: NfcDetailOwnerCardProps) {
  const [ownerList, setOwnerList] = useState([]);
  const [slctOwnerError, setSlctOwnerError] = useState(false);
  const [slctOwnerDisabled, setSlctOwnerDisabled] = useState(true);
  const handleOwnerChange = (event: any) => {
    setOwner(event.target.value.name + " (" + event.target.value.email + ")");
    setOwnerUuid(event.target.value.uuid);
  };
  const [search, setSearch] = useState("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearchKeyDown = (event: any) => {
    if ("Enter" !== event.key) return;
    console.log("search : ", search);
    ApiService.WebPost("/pw/backend/api/nfccard/searchOwner", {
      name: search,
    }).then((res) => {
      setOwner("");
      setOwnerList([]);
      setSlctOwnerDisabled(true);

      if (0 === res?.data.body.length) return;

      setOwnerList(res?.data.body);
      setSlctOwnerDisabled(false);
      setSearch("");
    });
  };
  useEffect(() => {
    if (rows?.owner) {
      setOwner(rows.owner);
      setOwnerUuid(rows.ownerUuid);
    }
  }, [rows?.owner, setOwner]);

  return (
    <>
      <CardContent
        sx={{
          width: "100%",
          padding: "0px",
        }}
      >
        <Box>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Owner
          </Typography>
          <VerticalSpacer height={8} />
          <TextField
            sx={{ width: "100%" }}
            value={search}
            onKeyDown={handleSearchKeyDown}
            onChange={handleSearchChange}
            InputProps={{
              style: {
                borderRadius: "10px",
              },

              startAdornment: (
                <InputAdornment position="start">
                  <img src={ICON_SEARCH} width="18px" height="18px" />
                </InputAdornment>
              ),
            }}
            inputProps={{
              sx: {
                py: "0px",
                minHeight: "48px",
              },
            }}
          ></TextField>
          <Select
            // disabled={slctOwnerDisabled}
            value={owner}
            sx={{
              // backgroundColor: `${slctOwnerDisabled ? color.disableColor : ""}`,
              borderRadius: "12px",
              mt: 2,
              width: "100%",
            }}
            onChange={handleOwnerChange}
            renderValue={(v) => {
              return v?.length ? v : ownerUuid;
            }}
          >
            {ownerList &&
              ownerList.map((item: any) => {
                return (
                  <MenuItem
                    key={item}
                    value={item}
                    sx={{
                      fontStyle: fontStyle.semiboldXS,
                      color: color.gray600,
                    }}
                  >
                    {item.name} ({item.email})
                  </MenuItem>
                );
              })}
          </Select>
          {slctOwnerError == true && (
            <>
              <VerticalSpacer height={4} />
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Select Owner option
              </Typography>
            </>
          )}
        </Box>
        <VerticalSpacer height={40} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            User Registration Date
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{
              style: { padding: "14px 16px", height: "20px", zIndex: 100 },
            }}
            disabled
            value={LocalizedDate({
              date: rows?.userRegistrationDate,
              type: DateFormatType.AdminType,
            })}
          />
        </Box>
      </CardContent>
    </>
  );
}
