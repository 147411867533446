import { Card, Grid } from "@mui/material";
import CommonList from "../../../../component/CommonList";
import { color } from "../../../../theme/Color";
import { MaterialRegistrationInspector } from "./MaterialRegistrationInspector";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { MaterialRegistrationFindParts } from "./MaterialRegistrationFindParts";
import VerticalSpacer from "../../../../component/Spacer";

export function ReceiveDeliverRegistration() {
  return (
    <CommonList
      title="자재 입출고 등록"
      headerBackButton={true}
      navigateURL={`/inventoryManagement?tab=receivedeliver`}
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Register"
          height="45px"
          sx={{ display: "flex", flexDirection: "column" }}
          // onClick={} //TODO : 버튼 눌렀을 때 동작 추가
        />
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <MaterialRegistrationInspector />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <MaterialRegistrationFindParts />
        </Card>
      </Grid>
    </CommonList>
  );
}
