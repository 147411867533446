export class PaymentManagementCellData {
    value: string | JSX.Element;
    width?: string;
    align: "center" | "left" | "right";
    paddingLeft?: string;
    paddingRight?: string;
    onClick?: () => void;
  
    constructor(
      value: string | JSX.Element,
      align: "center" | "left" | "right" = "center",
      width?: string,
      paddingLeft?: string,
      paddingRight?: string,
      onClick?: () => void
    ) {
      this.value = value;
      this.align = align;
      this.width = width;
      this.paddingLeft = paddingLeft;
      this.paddingRight = paddingRight;
      this.onClick = onClick;
    }
  }
  