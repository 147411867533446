import { Box } from "@mui/material";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

interface NoticeItemProps {
  title: string;
  date: string;
  onClick: () => void;
}

export default function NoticeItem({ title, date, onClick }: NoticeItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "61px",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          px: "20px",
          py: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text
          sx={{
            fontStyle: fontStyle.titleL,
            color: color.default,
          }}
          text={title}
        />
        <Text
          sx={{
            fontStyle: fontStyle.bodySr,
            color: color.gray600,
          }}
          text={date}
        />
      </Box>
    </Box>
  );
}
