export class MultilingualUiState {
  CreateDialogOpen: boolean = false;
  DetailDialogOpen: boolean = false;

  ID: string = "";

  Code: string = "";
  Language: string = "";
  Translation: string = "";
  Type: string = "";
  State: string = "";
  Description: string = "";

  CodeError: boolean = false;
  LanguageError: boolean = false;
  TranslationError: boolean = false;
  TypeError: boolean = false;
  StateError: boolean = false;
  DescriptionError: boolean = false;
}
