import { Box } from "@mui/material";
import { HorizontalSpacer } from "../../../component/Spacer";
import { ICON_FAQ } from "../../../constants/appImagePath";
import { color } from "../../../theme/Color";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

export function FAQBannerButton({ onClick }: { onClick: () => void }) {
  return (
    <Box
      sx={{
        px: "16px",
        py: "12px",
        borderRadius: "10px",
        backgroundColor: color.primary100,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        minHeight: "71px",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Text
          sx={{
            fontStyle: fontStyle.bodyMr,
            color: color.default,
          }}
          text={LocalizedText("ua_f210_solvequickly")}
        />
        <Text
          sx={{
            fontStyle: fontStyle.titleL,
            color: color.default,
          }}
          text={LocalizedText("ua_f210_faq")}
        />
      </Box>
      <HorizontalSpacer width={8} />
      <Box
        sx={{
          // 원모양
          minWidth: "36px",
          minHeight: "36px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          borderRadius: "50%",
          backgroundColor: color.primary500,
        }}
      >
        <img
          src={ICON_FAQ}
          alt=""
          style={{
            width: "24px",
            height: "24px",
          }}
        />
      </Box>
    </Box>
  );
}
