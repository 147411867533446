import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import {
  EMOJI_HAPPY_FILL,
  EMOJI_SOSO_FILL,
  ICON_BATTERY2,
  ICON_WARNING,
} from "../../constants/appImagePath";

export enum IconTextDialogType {
  SUCCESS,
  WARNING,
  SAD,
  BATTERY,
}

interface IconTextDialogProps {
  title: string;
  content: string;
  type: IconTextDialogType;
  positiveText?: string;
  negativeText?: string;
  isOpen: boolean;
  onPositive?: () => void;
  onNegative?: () => void;
  onClose: () => void;
}

export default function IconTextDialog({
  title,
  content,
  type,
  positiveText = "Confirm",
  negativeText = "Cancel",
  isOpen,
  onPositive,
  onNegative,
  onClose,
}: IconTextDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && onClose) {
          onClose();
        }
      }}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          px: "20px",
          pt: "20px",
          pb: "16px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
        }}
      >
        <img
          src={getEmojiPath(type)}
          alt="icon_warning"
          style={{
            width: "24px",
            height: "24px",
            display: "block",
            margin: "0 auto",
          }}
        />
        <DialogContentText
          sx={{
            pt: "8px",
            fontStyle: fontStyle.titleL,
            textAlign: "center",
            color: getTypeColor(type),
          }}
        >
          {title}
        </DialogContentText>
        <DialogContentText
          sx={{
            pt: "4px",
            fontStyle: fontStyle.bodyMr,
            color: color.gray800,
            textAlign: "center",
          }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "0px",
          pt: "20px",
        }}
      >
        {onNegative ? (
          <Button
            onClick={() => {
              onNegative();
            }}
            sx={{
              fontStyle: fontStyle.titleL,
              color: color.gray400,
            }}
          >
            {negativeText}
          </Button>
        ) : null}

        <Button
          onClick={onPositive}
          sx={{
            fontStyle: fontStyle.titleL,
            color: color.primary500,
          }}
        >
          {positiveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function getEmojiPath(type: IconTextDialogType): string {
  switch (type) {
    case IconTextDialogType.SUCCESS:
      return EMOJI_HAPPY_FILL;
    case IconTextDialogType.WARNING:
      return ICON_WARNING;
    case IconTextDialogType.SAD:
      return EMOJI_SOSO_FILL;
    case IconTextDialogType.BATTERY:
      return ICON_BATTERY2;
  }
}

/**
 * 코드 정렬하면 default;로 분리됩니다. 코드 정렬하고 반드시 확인하세요
 */
function getTypeColor(type: IconTextDialogType): string {
  switch (type) {
    case IconTextDialogType.SUCCESS:
      return color.default; // 확인 필요
    case IconTextDialogType.WARNING:
      return color.primary500;
    case IconTextDialogType.SAD:
      return color.warning2;
    case IconTextDialogType.BATTERY:
      return color.primary500;
  }
}
