import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { Text } from "../../../component/Text";

interface SupportButtonProps {
  iconPath: string;
  title: string;
  onClick: () => void;
}

export default function SupportMenuButton({
  iconPath,
  title,
  onClick,
}: SupportButtonProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        border: `1px solid ${color.gray300}`,
        backgroundColor: color.white,
        width: "100%",
        height: "110px",
      }}
      onClick={onClick}
    >
      <img
        src={iconPath}
        alt=""
        style={{
          width: "32px",
          height: "32px",
          marginBottom: "8px", // 이미지와 텍스트 사이의 간격 추가
        }}
      />
      <Text
        sx={{
          fontStyle: fontStyle.titleL,
          color: color.gray800,
          textAlign: "center", // 텍스트 중앙 정렬
        }}
        text={title}
      />
    </Box>
  );
}
