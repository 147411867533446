interface CertificateItem {
    credentialSubject: CredentialSubject;
    status: CertificateStatus;
    type: string[];
    vc?: string;
}

interface CredentialSubject {
    carNo?: string | null;
    expireTime?: string;
    issueTime: string;
    name?: string;
    sns_id: string;
    sns_type: string;
    vcID: string;
    modelName?: string;
    modelPicUrl?: string;
}

export enum CertificateStatus {
    Active = 1,
    Disposed = 0,
    Expired = 3,
}

export default CertificateItem;