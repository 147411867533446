import { Box } from "@mui/material";
import { APP_LOGO, ICON_GEAR } from "../../../constants/appImagePath";

interface MorePageTopToolBarProps {
  onClick?: () => void;
}

const MorePageTopToolBar = ({ onClick }: MorePageTopToolBarProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "44px",
        }}
      >
        <Box
          component="img"
          src={APP_LOGO}
          sx={{ width: "80px", height: "20px" }}
        />
        <Box
          component="img"
          src={ICON_GEAR}
          sx={{ width: "24px", height: "24px" }}
          onClick={onClick}
        />
      </Box>
    </>
  );
};

export default MorePageTopToolBar;
