import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer from "../../component/Spacer";
import VehicleMngTable from "./section/VehicleMngTable";
import { ApiService } from "../../restAPI/ApiService";
import { VehicleUiState } from "./model/VehicleUiState";
import { VehicleUiEvent } from "./model/VehicleUiEvent";
import { CommonSelect } from "../../component/CommonSelect";

const VehicleMngPage = () => {
  const navigate = useNavigate();
  // 페이지 관련
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);

  // 검색어 관련
  const [searchContents, setSearchContents] = useState("");

  const [uiState, setUiState] = useState(new VehicleUiState());
  const uiEvent: VehicleUiEvent = {
    HandleUiStateChange: (key: keyof VehicleUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
  };

  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/scooterModel/findAll", {}).then(
      (res) => {
        console.log("result : ", res?.data.body);
        uiEvent.HandleUiStateChange("modelList", res?.data.body);
        console.log("scooter model uiState data : ", uiState.modelList);
      }
    );
  }, [searchContents]);

  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/scooter/searchCondition", {
      productName: uiState.productName,
      searchCondition: searchContents,
      page: page,
      size: rowsPerPage,
    }).then((res) => {
      console.log("result : ", res?.data);
      uiEvent.HandleUiStateChange("data", res?.data.body.scooterList);
      // setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage));
      setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage));
      setDocCount(res?.data.body.docCount);
      console.log("scooter List uiState data : ", uiState.data);
      console.log("res data : ", res?.data.body.scooterList);
      console.log("productName : ", uiState.productName);
      console.log("docCount : ", res?.data.body.docCount);
      console.log("page : ", page);
    });
  }, [searchContents, uiState.productName, rowsPerPage, page]);

  useEffect(() => {
    setPage(0);
    console.log("page : ", page);
  }, [searchContents, uiState.productName]);

  return (
    <CommonList title="Scooter">
      <CommonSearch
        placeHolder={"Scooter S/N, Plate Number, Owner, Renter, Location"}
        setSearchContents={setSearchContents}
      >
        <CommonSelect
          selectValue={uiState.productName}
          onChange={(event) => {
            const selectedValue = event?.target.value;
            // "All"일 경우 빈 문자열로 설정
            uiEvent.HandleUiStateChange(
              "productName",
              selectedValue === "All" ? "" : selectedValue
            );
          }}
          menuitems={[
            "All",
            ...uiState.modelList.map((item) => item.modelName),
          ]}
          placeholder="Product Name"
        />
      </CommonSearch>

      <VerticalSpacer height={16} />
      <VehicleMngTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        uiState={uiState}
      />
    </CommonList>
  );
};

export default VehicleMngPage;
