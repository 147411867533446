import { Box, Button, Typography } from "@mui/material";
import CommonCardDialog from "../../../../../component/dialog/CommonCardDialog";
import VerticalSpacer from "../../../../../component/Spacer";
import { fontStyle } from "../../../../../theme/Style";
import { IMAGE_NFT_CARD } from "../../../../../constants/appImagePath";
import { color } from "../../../../../theme/Color";
import { LocalizedText } from "../../../../../di/LanguageRepositoryProvider";

interface DuplicateModalProps {
  dialogOpen: boolean;
  onClick: () => void;
}

const DuplicateModal = ({ dialogOpen, onClick }: DuplicateModalProps) => {
  const ua_g310_alert_nft_title = LocalizedText("ua_g310_alert_nft_title");
  const ua_g310_alert_nft_message = LocalizedText("ua_g310_alert_nft_message");
  const ua_g310_alert_nft_description = LocalizedText("ua_g310_alert_nft_description");

  return (
    <CommonCardDialog
      isOpen={dialogOpen}
      onPositive={() => {}}
      onClose={() => {}}
      children={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box component="img" src={IMAGE_NFT_CARD} sx={{ width: "78.5%" }} />
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography fontStyle={fontStyle.titleL}>{ua_g310_alert_nft_title}</Typography>
            <VerticalSpacer height={4} />
            <Typography fontStyle={fontStyle.bodyMr}>{ua_g310_alert_nft_message}</Typography>
            <VerticalSpacer height={12} />
            <Box
              sx={{
                maxWidth: "100%",
                bgcolor: color.primary50,
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: "12px",
              }}
            >
              <Typography fontStyle={fontStyle.subTitleS}>{ua_g310_alert_nft_description}</Typography>
            </Box>
          </Box>
        </Box>
      }
      button={
        <Button
          sx={{
            width: "100%",
            height: `49px`,
            borderRadius: `10px`,
            backgroundColor: color.primary500,
            textTransform: "none",
            ":hover": {
              backgroundColor: color.primary500,
            },
            zIndex: 1200,
            color: "white",
          }}
          onClick={onClick}
        >
          OK
        </Button>
      }
      sx={{ width: "71%" }}
    />
  );
};

export default DuplicateModal;
