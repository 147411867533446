import { DependencyList, useEffect } from "react";

const useAndroidBackHandler = (
  action: () => void,
  deps: DependencyList = [],
) => {
  useEffect(() => {
    const eventHandler = (event: Event) => {
      if (event.type === "androidBackHandlerEvent") {
        if (window.Android) {
          action();
        }
      }
    };

    window.addEventListener("androidBackHandlerEvent", eventHandler);

    return () => {
      window.removeEventListener("androidBackHandlerEvent", eventHandler);
    };
  }, deps);
};

export default useAndroidBackHandler;
