import { Box, CardContent, TextField, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import { color } from "../../../../../theme/Color";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { useState } from "react";
import CommonTextField from "../../../../../component/CommonTextField";

interface Field {
  label: string;
}

export function ProductionDetailsCardImpossible() {
  const [region, setRegion] = useState("");

  const textFields: Field[] = [
    { label: "Serial Number" },
    { label: "Serial Number CELL PACK" },
    { label: "Serial Number M-BMS-V240" },
    { label: "Serial Number FET-V240" },
    { label: "Memo CT_SENSOR HARNESS" },
  ];
  return (
    <CardContent
      sx={{
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Product</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Serial Number
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
            disabled
          />
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Image
          </Typography>
        </Box>
        <HorizontalSpacer width={28} />
        <Box sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Made
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            sx={{ minHeight: "48px", background: color.gray200 }}
            selectValue="Gwang-Ju"
            onChange={(event) => {
              setRegion(event.target.value);
            }}
            menuitems={["Gwang-Ju"]}
            disabled
          />
          <VerticalSpacer height={40} />
          {textFields.map((textField, index) => (
            <Box sx={{ display: "flex", flexDirection: "column" }} key={index}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                {textField.label}
              </Typography>
              <VerticalSpacer height={8} />
              <CommonTextField
                inputProps={{ style: { padding: "14px 16px", height: "20px" } }}
                disabled
              />
              {index < textFields.length - 1 && <VerticalSpacer height={40} />}
            </Box>
          ))}
        </Box>
      </Box>
    </CardContent>
  );
}
