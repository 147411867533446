import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Zoom, Pagination } from "swiper/modules";
import { Box, IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  IMAGE_EMPTY_IMAGE,
  IMAGE_STATION_01,
} from "../../constants/appImagePath";

interface PWUserAppMapPhotoDetailsProps {
  setDetailsOpen?: any;
  data: any;
}

const PWUserAppMapPhotoDetails = ({
  setDetailsOpen,
  data,
}: PWUserAppMapPhotoDetailsProps) => {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-fraction-color"?: string;
    "--swiper-pagination-bottom"?: string;
    "--swiper-pagination-bullet-horizontal-gap"?: string;
    height?: string;
  };

  // Define the custom styles
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "#fff",
    "--swiper-pagination-bottom": "91.5%",
  };

  return (
    <Box
      sx={{
        zIndex: 1400,
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        backgroundColor: "black",
      }}
    >
      <IconButton
        sx={{
          color: "white",
          position: "fixed",
          zIndex: 1500,
          bottom: "90%",
          left: "5%",
        }}
        onClick={() => {
          setDetailsOpen(false);
        }}
      >
        <KeyboardBackspaceIcon />
      </IconButton>
      <Swiper
        style={swiperStyles}
        zoom={true}
        pagination={{
          type: "fraction",
          clickable: true,
        }}
        modules={[Zoom, Pagination]}
      >
        {data ? (
          data.map((imgData: any) => (
            <SwiperSlide>
              <div className="swiper-zoom-container">
                <img src={imgData} />
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src={IMAGE_EMPTY_IMAGE} />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </Box>
  );
};

export default PWUserAppMapPhotoDetails;
