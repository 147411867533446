import axios from "axios";

export const ApiService = {
  async WebPost(endPoint, body, headers = {}) {
    try {
      return await axios.post("/web" + endPoint, body, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async WebGet(endPoint, headers = {}) {
    try {
      return await axios.get("/web" + endPoint, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async WebPut(endPoint, body, headers = {}) {
    try {
      return await axios.put("/web" + endPoint, body, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async WebDelete(endPoint, body, headers = {}) {
    try {
      return await axios.delete("/web" + endPoint, { headers, data: body });
    } catch (err) {
      console.log(err);
    }
  },
  async MobilePost(endPoint, body, headers = {}, responseType = "json") {
    try {
      return await axios.post("/mobile" + endPoint, body, {
        headers,
        responseType,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async MobileGet(endPoint, headers = {}) {
    try {
      return await axios.get("/mobile" + endPoint, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async MobilePut(endPoint, body, headers = {}) {
    try {
      return await axios.put("/mobile" + endPoint, body, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async MobileDelete(endPoint, headers = {}) {
    try {
      return await axios.delete("/mobile" + endPoint, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async BlockchainPost(endPoint, body, headers = {}) {
    try {
      return await axios.post("/blockchain" + endPoint, body, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async BlockchainGet(endPoint, headers = {}) {
    try {
      return await axios.get("/blockchain" + endPoint, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async BlockchainPut(endPoint, body, headers = {}) {
    try {
      return await axios.put("/blockchain" + endPoint, body, { headers });
    } catch (err) {
      console.log(err);
    }
  },
  async BlockchainDelete(endPoint, headers = {}) {
    try {
      return await axios.delete("/blockchain" + endPoint, { headers });
    } catch (err) {
      console.log(err);
    }
  },

  // http://dev.verywords.com/documents/207
  async SmsAuthPost(endPoint, body, headers = {}, responseType = "json") {
    return await axios.post("/sms" + endPoint, body, {
      headers,
      responseType,
    });
  },

  // http://dev.verywords.com/documents/207
  async post(endPoint, body, headers = {}, responseType = "json") {
    return await axios.post("/mobile" + endPoint, body, {
      headers,
      responseType,
    });
  },

  // for keycloak
  async keycloakDelete(endPoint, userid, headers = {}) {
    try {
      return await axios.delete("/keycloak" + endPoint + "/" + userid, {
        headers,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async keycloakGet(endPoint, username, headers = {}) {
    try {
      return await axios.get("/keycloak" + endPoint + "?username=" + username, {
        headers,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async keycloakPost(endPoint, data, headers = {}) {
    try {
      return await axios.post("/keycloak" + endPoint, data, { headers });
    } catch (err) {
      console.log(err);
    }
  },
};
