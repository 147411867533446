import { PayQRUiState } from "./PayQRUiState";
import { PayQRSideEffect } from "./PayQRSideEffect";
import { PayQRUiEvent } from "./PayQRUiEvent";
import { Box, Typography, Button } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import Toolbar from "../../../component/Toolbar";
import VerticalSpacer from "../../../component/Spacer";
import { ICON_DOWNLOAD, IMAGE_QRCODE } from "../../../constants/appImagePath";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import ToastAlert from "../../../component/ToastAlert";

interface PayQRRenderProps {
  sideEffect: PayQRSideEffect;
  uiState: PayQRUiState;
  uiEvent: PayQRUiEvent;
}

const PayQRRender = ({ sideEffect, uiState, uiEvent }: PayQRRenderProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Toolbar
          title={LocalizedText("ua_g411_payqr")}
          onBack={() => {
            window.history.back();
          }}
        />

        <VerticalSpacer height={40} />

        <Box
          sx={{
            backgroundColor: color.white,
            width: "calc(100% - 70px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: "60px 60px 40px 60px",
            borderRadius: "10px",
            boxShadow: "1px 1px 20px rgba(27, 25, 24, 0.15)",
            boxSizing: "border-box",
          }}
        >
          <Box
            component="img"
            src={uiState.qrcodeUrl}
            alt="QR Code"
            sx={{
              width: "200px",
              height: "200px",
            }}
          />

          <VerticalSpacer height={36} />

          <Typography sx={{ textAlign: "center" }}>
            <Typography fontStyle={fontStyle.subTitleL} color={color.gray700}>
              {uiState.productName}
            </Typography>
            <Typography fontStyle={fontStyle.subTitleL} color={color.gray700}>
             ({uiState.productDescription})
            </Typography>
          </Typography>

          <VerticalSpacer height={16} />

          <Typography fontStyle={fontStyle.headingL} color={color.primary500}>
            $ {uiState.productPrice}
          </Typography>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: "calc(100% - 40px)",
            mt: "auto",
            mb: "38px",
            p: "12px 20px",
            boxSizing: "border-box",
            borderRadius: "10px",
            border: `1px solid ${color.primary500}`,
          }}
          onClick={() => {
            sideEffect.downloadQrCode();
          }}
        >
          <Box
            component="img"
            src={ICON_DOWNLOAD}
            alt="Download"
            sx={{
              width: "20px",
              height: "20px",
              mr: "4px",
            }}
          />
          <Typography fontStyle={fontStyle.titleL} color={color.primary500}>
            {LocalizedText("ua_g411_downloadqr_button")}
          </Typography>
        </Box>

        {/* Toast Alert */}
        <ToastAlert
          open={uiState.isToastOpen}
          onClose={uiEvent.CloseToast}
          message={LocalizedText("ua_g411_toast_saveqr")}
          bottomOffset={95}
        />
      </Box>
    </>
  );
};

export default PayQRRender;
