import { CSDetailResponse, RefairItem } from "./CSDetailResponse";

interface CSReceipt {
  stationName: string;
  completeDate: number;
  problem?: string;
  correct?: string;
  repairFee?: RefairItem[];
  maintenanceCosts?: number;
  totalFee?: number;
}

export function toCSReceipt(item: CSDetailResponse): CSReceipt {
  return {
    stationName: item.preferredStation,
    completeDate: item.completeDate ?? 0,
    problem: item.problem,
    correct: item.correct,
    repairFee: item.repairFee,
    maintenanceCosts: item.maintenanceCosts,
    totalFee: item.totalFee,
  };
}

export type { CSReceipt };
