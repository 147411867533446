import { Box } from "@mui/material";
import CommonButton from "../component/CommonButton";
import { ApiService } from "../restAPI/ApiService";
import { useEffect, useState } from "react";
import axios from "axios";
import VerticalSpacer from "../component/Spacer";
import { getFileHash } from "../utils/FileUtil";
import { getLocale } from "../customHook/useLocale";

const MobileTest = () => {
  const [buttonText, setButtonText] = useState("언어팩 요청");

  // 간단하게 현재 언어팩 버전을 요청하는 함수
  const getCurrentLanguagePackVersion: () => Promise<
    string | null
  > = async () => {
    const locale = getLocale();
    const url = `/pw/aw/system/multilingual/distribute/isRecent/${locale}`;
    await ApiService.MobilePost(url).then((res) => {
      const version = res?.data as string;
      if (version) {
        console.log(version);
        return version;
      }
    });
    return null;
  };

  const requestLanguagePack = async () => {
    const url = "/mobile" + "/pw/aw/system/multilingual/distribute/create";
    axios
      .post<Blob>(
        url,
        {
          // mock data body
          language: "en-US",
          date: "20241009",
          time: "1500",
          uuid: "gJtIDJEBTozLt71O9iDr",
        },
        {
          responseType: "blob", // 서버에서 Blob으로 JSON 파일 받음
        }
      )
      .then(async (res) => {
        // 받은 Blob을 File 객체로 변환 (이미지 파일이 아닌 JSON이므로 파일명 변경)
        const myFile = new File([res.data], "languagePack.json", {
          type: "application/json",
        });

        console.log("header hash : ", res.headers["hash"]);
        console.log("file hash", getFileHash(myFile));

        // Blob 데이터를 Base64로 변환 (Optional - 필요 시 미리보기용)
        const reader = new FileReader();
        reader.onload = (ev) => {
          const previewImage = String(ev.target?.result); // Base64 문자열
          console.log("파일 미리보기 (Base64):", previewImage);
          setButtonText("다운로드 완료");
        };
        reader.readAsDataURL(myFile);

        // Cache Storage에 Blob을 저장
        const cache = await caches.open("myCache");

        // Blob 데이터를 Response 객체로 변환해 캐시에 저장
        const responseForCache = new Response(res.data, {
          headers: { "Content-Type": "application/json" },
        });

        // 캐시에 저장 ('/jsonData'라는 URL 키로 저장)
        await cache.put("/jsonData", responseForCache);
        console.log("JSON 파일이 Cache Storage에 저장되었습니다.");
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  // Cache에서 저장된 파일을 불러오는 함수
  const loadFromCache = async () => {
    const cache = await caches.open("myCache");

    const cachedResponse = await cache.match("/jsonData");
    if (cachedResponse) {
      const jsonData = await cachedResponse.json();
      console.log("Cache에서 불러온 데이터:", jsonData);
    } else {
      console.log("Cache에 데이터가 없습니다.");
    }
  };

  const [myCoordinates, setMyCoordinates] = useState({ lat: null, lng: null });

  function handleSuccess(position: any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    // 좌표를 상태로 저장
    setMyCoordinates({ lat: latitude, lng: longitude });
  }

  function handleError() {
    console.log("cant not access to location");
  }

  const askForCoords = () => {
    return navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  };

  // 1초마다 위치 정보를 가져옴
  useEffect(() => {
    const intervalId = setInterval(() => {
      askForCoords();
    }, 1000); // 1000ms = 1초

    // 컴포넌트 언마운트 시 interval을 정리
    return () => clearInterval(intervalId);
  }, []); // 빈 배열을 전달하여 컴포넌트가 처음 렌더링될 때만 실행

  return (
    <Box>
      <CommonButton
        onClick={() => {
          requestLanguagePack();
        }}
        title={buttonText}
      />
      <VerticalSpacer height={20} />
      <CommonButton
        onClick={() => {
          loadFromCache();
        }}
        title={"캐시 스토리지 확인"}
      />
      <VerticalSpacer height={20} />
      <CommonButton
        onClick={() => {
          getCurrentLanguagePackVersion();
        }}
        title={"버전 요청"}
      />
      <VerticalSpacer height={20} />
      {/* 위도와 경도를 화면에 표시 */}
      {myCoordinates.lat && myCoordinates.lng ? (
        <Box>
          <p>Latitude: {myCoordinates.lat}</p>
          <p>Longitude: {myCoordinates.lng}</p>
        </Box>
      ) : (
        <p>위치 정보가 없습니다.</p>
      )}
    </Box>
  );
};
export default MobileTest;
