import { Box } from "@mui/material";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import {
  ICON_CALENDAR_DATE,
  ICON_DOWN_ARROW,
  ICON_SEARCH,
  ICON_SEARCH_GRAY,
} from "../../constants/appImagePath";
import { Text } from "../Text";

interface DatePickerButtonProps {
  isSelected: boolean;
  text: string;
  onClick: () => void;
}

export default function DatePickerButton({
  isSelected,
  text,
  onClick,
}: DatePickerButtonProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        height: "38px",
        border: "1px solid",
        borderColor: color.gray300,
        borderRadius: "6px",
        px: "8px",
        boxSizing: "border-box",
        flex: 1,
        minWidth: "0",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={ICON_CALENDAR_DATE}
          width="20px"
          height="20px"
          sx={{
            mr: "4px",
          }}
        />
        <Text
          sx={{
            fontStyle: fontStyle.bodyMr,
            color: isSelected ? color.default : color.gray600,
          }}
          text={text}
        />
      </Box>
      <Box component="img" src={ICON_DOWN_ARROW} width="20px" height="20px" />
    </Box>
  );
}

interface SearchButtonProps {
  onClick: () => void;
  isDisabled: boolean;
}

export function SearchButton({ onClick, isDisabled }: SearchButtonProps) {
  return (
    <Box
      component="img"
      src={!isDisabled ? ICON_SEARCH : ICON_SEARCH_GRAY}
      width="20px"
      height="20px"
      sx={{
        p: "8px",
        boxSizing: "border-box",
        border: "1px solid",
        borderColor: color.gray300,
        borderRadius: "6px",
        width: "38px",
        height: "38px",
        flexShrink: 0,
      }}
      onClick={!isDisabled ? onClick : undefined}
    />
  );
}
