import { Box, Typography } from "@mui/material";
import {
  EMOJI_SAD_FILL,
  IMAGE_DODO_SLEEP,
  IMAGE_LOGIN_BACKGROUND,
} from "../../constants/appImagePath";
import { fontStyle } from "../../theme/Style";
import VerticalSpacer from "../../component/Spacer";
import CommonButton from "../../component/CommonButton";

const ServerErrorScreen = ({ onRetry }: { onRetry: () => void }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundImage: `url(${IMAGE_LOGIN_BACKGROUND})`,
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: "100px",
        }}
      >
        <img
          src={EMOJI_SAD_FILL}
          alt="sad"
          style={{
            width: "36px",
            height: "36px",
          }}
        />
        <VerticalSpacer height={20} />
        <Typography
          fontStyle={fontStyle.headingS}
          color="white"
          textAlign={"center"}
        >
          Server unavailable :(
        </Typography>
        <VerticalSpacer height={4} />
        <Typography
          fontStyle={fontStyle.bodyLr}
          color="white"
          textAlign={"center"}
          whiteSpace={"pre-line"}
          sx={{
            px: "20px",
            boxSizing: "border-box",
          }}
        >
          POPLE World is currently unavailable {"\n"} due to a temporary error.
          Please{"\n"}
          restart POPLE World or try again later.
        </Typography>
        <VerticalSpacer height={40} />
        <CommonButton
          onClick={onRetry}
          title="Try again"
          sx={{
            width: "124px",
          }}
          height={46}
        />
      </Box>
      <Box
        component="img"
        src={IMAGE_DODO_SLEEP}
        display=""
        alt="dodo"
        sx={{
          width: "278px",
          height: "300px",
        }}
      />
    </Box>
  );
};

export default ServerErrorScreen;
