import { Box, Divider } from "@mui/material";
import { HorizontalSpacer } from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";

export enum NfcCardManageMentTabType {
  NFCMANAGEMENT = "nfcManagement",
  OWNER_HISTORY = "owner_History",
}

export interface NfcCardManageMentTabProps {
  currentTab: NfcCardManageMentTabType;
  changeTab: (tab: NfcCardManageMentTabType) => void;
}

export function NfcCardManageMentTab({
  currentTab,
  changeTab,
}: NfcCardManageMentTabProps) {
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == NfcCardManageMentTabType.NFCMANAGEMENT
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(NfcCardManageMentTabType.NFCMANAGEMENT);
        }}
      >
        Management
      </Box>
      <HorizontalSpacer width={16} />
      {/* <Divider orientation="vertical" /> // TODO 추후 구현
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == NfcCardManageMentTabType.OWNER_HISTORY
              ? color.primary500
              : color.gray600,
        }}
        onClick={() => {
          changeTab(NfcCardManageMentTabType.OWNER_HISTORY);
        }}
      >
        Owner History
      </Box> */}
    </Box>
  );
}
