import { Box, Button, Typography } from "@mui/material";
import VerticalSpacer from "../../../component/Spacer";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

const NoBatteryReservation = () => {
  return (
    <>
      <>
        <Box sx={{ width: "100%" }}>
          <VerticalSpacer height={16} />
          <Typography fontWeight={"bold"}>
            {LocalizedText("ua_d200_nobatteryreserve_title")}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {LocalizedText("ua_d200_nobatteryreserve_message")}
          </Typography>
          <Button
            sx={{
              width: "100%",
              height: "6vh",
              borderRadius: 2.5,
              mt: 2,
              backgroundColor: "#676665",
              "&:hover": {
                backgroundColor: "#676665", // hover 시 배경색
              },
            }}
            onClick={() => {
              if (window.Android) {
                window.Android.navigate("Map");
              }

              if (window.webkit) {
                window.webkit.messageHandlers.navigate.postMessage("Map");
              }
            }}
            // disableRipple
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: "500",
                ml: 1,
                textTransform: "none",
              }}
            >
              {LocalizedText("ua_d200_nearbystations_button")}
            </Typography>
          </Button>
          <VerticalSpacer height={16} />
        </Box>
      </>
    </>
  );
};

export default NoBatteryReservation;
