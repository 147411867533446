import { Category } from "../../MobileSupport/faq/list/model/Category";
import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";

interface PartnersCategoryButtonProps {
  category: Category;
  onClick: (category: Category) => void;
}

const PartnersCategoryButton = ({
  category,
  onClick,
}: PartnersCategoryButtonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "32px",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "10px",
        border: `1.25px solid ${category.isSelected ? color.primary500 : color.gray300}`,
        px: "16px",
      }}
      onClick={() => {
        onClick(category);
      }}
    >
      <Typography
        fontStyle={fontStyle.titleS}
        color={category.isSelected ? color.primary500 : color.gray800}
      >
        {category.label}
      </Typography>
    </Box>
  );
};

export default PartnersCategoryButton;
