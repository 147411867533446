interface UsageHistoryItem {
    id: number;
    usageType: UsageType;
    title: string;
    date: string;
    mileage: number;
    power: number;
    details?: DailyMileageDetail[];
    isExpanded?: boolean;
}

export interface DailyMileageDetail {
    mileage: number;
    startTime: string;
    endTime: string;
}

export enum UsageType {
    CHARGE,
    USE
}

export default UsageHistoryItem;
