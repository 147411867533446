import { useState } from "react";
import {
  LocalizationProvider,
  DatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SxProps, TextField, Theme } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { color } from "../theme/Color";

interface CommonDatePickerProps {
  disabled?: boolean; // disabled prop 추가
  sx?: SxProps<Theme>; // sx prop 추가
  InputPropsSx?: SxProps<Theme>; // sx prop 추가
  textFieldSx?: SxProps<Theme>; // sx prop 추가
  onChange?: (newDate: Dayjs | null) => void; // onChange prop 추가
  views?: Array<"year" | "month" | "day">; // views prop 추가
  value: Dayjs | null; // 외부에서 관리할 수 있는 value prop 추가
  error?: any;
  minDate?: Dayjs; // 외부에서 주입 가능한 minDate prop 추가
  placeholder?: string;
}

const CommonDatePicker = ({
  disabled,
  InputPropsSx,
  textFieldSx,
  onChange,
  value,
  views, // 기본값 설정
  error,
  sx,
  minDate,
  placeholder,
}: CommonDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="DD MMM YYYY"
        value={value}
        onChange={onChange}
        disabled={disabled}
        openTo={views?.[0]} // 첫 번째 요소를 openTo로 설정
        views={views} // views prop 전달
        minDate={minDate} // 외부에서 주입된 minDate를 설정
        sx={{ ...sx }}
        slotProps={{
          textField: {
            variant: "outlined",
            sx: {
              borderColor: color.gray300,
              ...textFieldSx,
            },
            placeholder: placeholder,
            InputProps: {
              sx: {
                borderRadius: "10px",
                borderColor: color.gray300,
                backgroundColor: disabled ? color.gray200 : "white",

                ...InputPropsSx,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CommonDatePicker;
