import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const UserMembershipGrid = () => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: 0 }}>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ mt: 0 }}>
                <Typography>Email ID</Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  size="small"
                  value={""}
                  InputProps={{
                    style: {
                      borderRadius: "7px",
                    },
                  }}
                />
              </Box>

              {/* 두번째 줄 */}

              <Box sx={{ mt: 2 }}>
                <Typography>Email ID</Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  size="small"
                  value={""}
                  InputProps={{
                    style: {
                      borderRadius: "7px",
                    },
                  }}
                />
              </Box>
              {/* 세번째 줄 */}
              <Box sx={{ mt: 2 }}>
                <Typography>Email ID</Typography>
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  size="small"
                  value={""}
                  InputProps={{
                    style: {
                      borderRadius: "7px",
                    },
                  }}
                />
              </Box>
              {/* 네번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Start Date</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    value={""}
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>End Date</Typography>
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,

            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <CardHeader
              title="Voucher"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 요소 */}
              <Box
                sx={{
                  borderRadius: 3,
                  border: 1,
                  height: 90,
                  borderColor: "#C4C4C4",
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    m: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ ml: 1, fontWeight: "bold", color: "#B0AFB3" }}
                  >
                    Run More 60 Km
                  </Typography>
                  <Typography
                    sx={{ mr: 1, mt: 0.2, color: "#B0AFB3", fontSize: 13 }}
                  >
                    2024-05-30 15:30:00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    m: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#B0AFB3",
                      fontSize: 20,
                      fontWeight: "bold",
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    41 / 60 KM
                  </Typography>
                </Box>
              </Box>
              {/* 두번째 요소 */}
              <Box
                sx={{
                  borderRadius: 3,
                  border: 1,
                  height: 90,
                  borderColor: "#C4C4C4",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    m: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ ml: 1, fontWeight: "bold", color: "#B0AFB3" }}
                  >
                    Run More 60 Km
                  </Typography>
                  <Typography
                    sx={{ mr: 1, mt: 0.2, color: "#B0AFB3", fontSize: 13 }}
                  >
                    2024-05-30 15:30:00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    m: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#B0AFB3",
                      fontSize: 20,
                      fontWeight: "bold",
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    0 / 60 KM
                  </Typography>
                </Box>
              </Box>
              {/* 세번째 요소 */}
              <Box
                sx={{
                  borderRadius: 3,
                  border: 1,
                  height: 90,
                  borderColor: "#C4C4C4",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    m: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ ml: 1, fontWeight: "bold", color: "#B0AFB3" }}
                  >
                    Run More 60 Km
                  </Typography>
                  <Typography
                    sx={{ mr: 1, mt: 0.2, color: "#B0AFB3", fontSize: 13 }}
                  >
                    2024-05-30 15:30:00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    m: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#B0AFB3",
                      fontSize: 20,
                      fontWeight: "bold",
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    0 / 180 KM
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserMembershipGrid;
