import { Box, Typography } from "@mui/material";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import { Category } from "./model/Category";

interface CategoryListProps {
  categoryList: Category[];
  onClick: (category: Category) => void;
}

export default function CategoryList({
  categoryList,
  onClick,
}: CategoryListProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1, // Chip 사이의 간격을 8px로 설정
      }}
    >
      {categoryList.map((it, i) => (
        <Box
          key={i}
          sx={{
            display: "flex",
            borderRadius: "50px",
            border: it.isSelected
              ? `1.25px solid ${color.primary500}`
              : `1.25px solid ${color.gray300}`,
            height: "28px",
            px: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => onClick(it)}
        >
          <Typography
            sx={{
              fontStyle: fontStyle.titleS,
              color: it.isSelected ? color.primary500 : color.gray800,
            }}
          >
            {it.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
