import { useNavigate } from "react-router-dom";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  return (
    <>
      <TableRow
        // sx={{ cursor: "pointer" }}
        onClick={() => {
          //   navigate(`/inquiryMng/inquiryDetails/${row.id}`);
        }}
      >
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.blockNum}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.miner
              ? `${row.miner.slice(0, 6)}...${row.miner.slice(-4)}`
              : ""}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.txns}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.age}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface BlockChainTableProps {
  mngRows: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

const BlockChainTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: BlockChainTableProps) => {
  const headCells = [
    {
      id: "blockNum",
      align: "left",
      disablePadding: false,
      label: "Block",
    },

    {
      id: "miner",
      align: "left",
      disablePadding: false,
      label: "Mined by",
    },

    {
      id: "txns",
      align: "left",
      disablePadding: false,
      label: "Txns",
    },

    {
      id: "age",
      align: "left",
      disablePadding: false,
      label: "Age",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((row: any, index: any) => (
        <Row row={row} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default BlockChainTable;
