import { Box } from "@mui/material";
import { InquiryHistoryModel } from "../model/InquiryHistoryModel";
import InquiryHistoryItem from "./InquiryHistoryItem";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { HorizontalGrayDivider } from "../../../component/Divider";
import VerticalSpacer from "../../../component/Spacer";

interface InquiryHistoryListProps {
  inquiryHistories: InquiryHistoryModel[];
  onClick: (id: string) => void;
}

export default function InquiryHistoryList({
  inquiryHistories,
  onClick,
}: InquiryHistoryListProps) {
  if (inquiryHistories.length == null || inquiryHistories.length == 0) {
    return (
      <Box
        sx={{
          pt: "43px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          sx={{
            fontStyle: fontStyle.bodyMr,
            color: color.gray600,
          }}
          text={LocalizedText("ua_f210_nohistory")}
        />
      </Box>
    );
  }
  return (
    <Box>
      {inquiryHistories.map((it) => (
        <Box key={it.id}>
          <VerticalSpacer height={8} />
          <Box
            sx={{
              px: "8px",
            }}
          >
            <InquiryHistoryItem
              key={it.id}
              status={it.status}
              title={it.title}
              category={it.category}
              registrationDate={it.registrationDate}
              onClick={() => {
                onClick(it.id);
              }}
            />
          </Box>
          <VerticalSpacer height={8} />
          <HorizontalGrayDivider />
        </Box>
      ))}
    </Box>
  );
}
