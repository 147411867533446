import { Box, Button, IconButton } from "@mui/material";
import CommonList from "../../../../component/CommonList";
import CreateAdminGrid from "./CreateAdminGrid";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useAuthRepository } from "../../../../di/AuthRepositoryProvider";
import { ApiService } from "../../../../restAPI/ApiService";
import CommonDialog from "../../../../component/CommonDialog";

const CreateAdminPage = () => {
  const navigate = useNavigate();
  const authRepository = useAuthRepository();
  const [isCreateClick, setIsCreateClick] = useState(false);
  const [createData, setCreateData] = useState<any>({
    uuid: "",
    type: "",
    role: "",
    office: 0,
    group: "IT Department",
    birthOfDate: 0,
    gender: "",
    countryNumber: "",
    phoneNumber: "",
    email: "",
    name: "",
    addressCountry: "",
    addressProvince: "",
    addressDetail: "",
    statistics: [],
    userManagement: [],
    content: [],
    merchandise: [],
    productionMaterial: [],
    operations: [],
    carbonReduction: [],
    blockchain: [],
    system: [],
  });

  const [inValidDataCheck, setInValidDataCheck] = useState({
    type: false,
    role: false,
    office: false,
    group: false,
    birthOfDate: false,
    gender: false,
    countryNumber: false,
    phoneNumber: false,
    email: false,
    name: false,
    addressCountry: false,
    addressProvince: false,
    addressDetail: false,
    checkBox: false,
  });

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [createDialogErrorOpen, setCreateDialogErrorOpen] = useState(false);
  const [doCreate, setDoCreate] = useState(false);

  // 랜덤 비밀번호를 위한 함수
  function generateRandomString(length: number) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  // 핸들러 함수 정의
  const handleCreateDataChange = (key: keyof typeof createData, value: any) => {
    setCreateData((prevData: any) => ({
      ...prevData,
      [key]: value,
    }));
  };

  // 핸들러 함수 정의
  const handleInValidDataCheckChange = (
    key: keyof typeof createData,
    value: any
  ) => {
    setInValidDataCheck((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const validateCreateData = () => {
    // 기본 필드 검증 (inValidDataCheck에 포함된 키만 검증)
    Object.keys(inValidDataCheck).forEach((key) => {
      if (
        key !== "checkBox" &&
        key !== "birthOfDate" &&
        key !== "gender" &&
        key !== "office"
      ) {
        // birthOfDate와 gender는 제외
        if (key === "role" || key === "type") {
          // role 또는 type이 "Select" 상태이면 true로 설정
          handleInValidDataCheckChange(key, createData[key] === "Select");
        } else if (key === "name") {
          // name은 두 글자 이상이어야 함
          handleInValidDataCheckChange(key, createData[key].length < 2);
        } else if (key === "email") {
          // email은 이메일 형식이어야 함
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          handleInValidDataCheckChange(key, !emailRegex.test(createData[key]));
        } else if (
          typeof createData[key] === "string" &&
          createData[key] === ""
        ) {
          handleInValidDataCheckChange(key, true);
        } else if (
          typeof createData[key] === "number" &&
          createData[key] === 0
        ) {
          handleInValidDataCheckChange(key, true);
        } else {
          handleInValidDataCheckChange(key, false);
        }
      }
    });

    // 배열 필드 검증
    const arrayFields = [
      "statistics",
      "userManagement",
      "content",
      "merchandise",
      "productionMaterial",
      "operations",
      "carbonReduction",
      "blockchain",
      "system",
    ];

    // 배열이 모두 비어 있으면 checkBox를 true로 설정하고, 하나라도 값이 있으면 false로 설정
    const allArraysEmpty = arrayFields.every(
      (field) =>
        Array.isArray(createData[field]) && createData[field].length === 0
    );

    handleInValidDataCheckChange("checkBox", allArraysEmpty);
  };
  const handleNavigate = (route: string) => {
    navigate(route);
    // 페이지를 새로고침
    window.location.reload();
  };

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
          textTransform: "none",
          px: "20px",
        }}
        onClick={() => {
          // createData 검증
          validateCreateData();

          setIsCreateClick(true);
        }}
      >
        <AddIcon fontSize="small" />
        Create
      </Button>
    );
  };

  useEffect(() => {
    if (isCreateClick) {
      const isAllValid = Object.values(inValidDataCheck).every(
        (value) => value === false
      );
      if (isAllValid) {
        setCreateDialogOpen(true);
        if (doCreate) {
          // firebase에 먼저 가입 시킨 후 uid를 가져오는 전처리가 필요합니다
          authRepository
            .signUpWithOutLogin(createData.email, generateRandomString(10)) // 랜덤 비밀번호 생성
            .then((res) => {
              //가져온 uid를 createData uuid 키값에 넣어줍니다.
              handleCreateDataChange("uuid", res?.user.uid);
            });
        }
      } else {
        setCreateDialogErrorOpen(true);
        console.log("유효하지 않은 데이터가 있습니다.");
      }
      setIsCreateClick(false);
    }
  }, [inValidDataCheck, isCreateClick, createData, doCreate]);

  useEffect(() => {
    if (createData.uuid) {
      ApiService.WebPost("/pw/users/admin/create", createData).then((res) => {
        handleNavigate("/adminMng");
      });
    }
  }, [createData]);

  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleNavigate("/adminMng");
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  return (
    <CommonList
      title="Admin Registration"
      setModal={null}
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Box>
        <CreateAdminGrid
          createData={createData}
          handleCreateDataChange={handleCreateDataChange}
          inValidDataCheck={inValidDataCheck}
        />
      </Box>
      <CommonDialog
        open={createDialogOpen}
        handleClose={() => {
          setCreateDialogOpen(false);
        }}
        handleOk={() => {
          setDoCreate(true);
          setCreateDialogOpen(false);
        }}
      >
        Would you like to create an admin
      </CommonDialog>
      <CommonDialog
        open={createDialogErrorOpen}
        handleClose={() => {
          setCreateDialogErrorOpen(false);
        }}
        handleOk={() => {
          setCreateDialogErrorOpen(false);
        }}
      >
        There is invalid data.
      </CommonDialog>
    </CommonList>
  );
};

export default CreateAdminPage;
