import { Tabs, Tab, Typography, SxProps, Theme, Box } from "@mui/material";
import { fontStyle } from "../theme/Style";
import { color } from "../theme/Color";

interface TabItem {
  value: string;
  label: string;
}

interface CommonTabsProps {
  currentTab: string;
  tabs: TabItem[];
  onChange: (event: string) => void;
  width?: string;
  activeColor?: string;
  inactiveColor?: string;
  sx?: SxProps<Theme>;
}

export default function CommonTabs({
  currentTab,
  tabs,
  onChange,
  width = "100%",
  activeColor = color.primary500,
  inactiveColor = color.gray400,
  sx = {},
}: CommonTabsProps) {
  return (
    <Box
      sx={{
        width: width,
        borderBottom: `1px solid ${color.gray200}`,
      }}
    >
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        textColor="inherit"
        TabIndicatorProps={{
          style: {
            backgroundColor: activeColor,
            height: "2px"
          },
        }}
        centered
        sx={{
          width: `calc(${width} - 40px)`,
          marginLeft: "20px",
          ...sx,
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            value={tab.value}
            label={
              <Typography
                sx={{
                  fontStyle: fontStyle.titleL,
                  textTransform: "none",
                  color: currentTab === tab.value ? activeColor : inactiveColor,
                }}
              >
                {tab.label}
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              flex: 1,
              height: "50px",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
