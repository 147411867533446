import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import { MaterialHistoryTableRow } from "./MaterialHistoryTableRow";
import {
  MaterialHistoryData,
  MaterialHistoryList,
} from "./model/MaterialHistoryData";

interface MaterialHistoryTableProps {
  headCells: HeadCell[];
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pageCount: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  docCount: number;
}

export function MaterialHistoryTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: MaterialHistoryTableProps) {
  const { order, orderBy, handleChangePage, handleRequestSort, visibleRows } =
    useVisibleRows(MaterialHistoryList, rowsPerPage || 10, setPage, page || 0);
  const pagedata: MaterialHistoryData[] = visibleRows
    .slice(
      (page || 0) * (rowsPerPage || 10),
      ((page || 0) + 1) * (rowsPerPage || 10)
    )
    .map((row) => {
      return new MaterialHistoryData(
        Number(row.no),
        row.category as string,
        row.partsCode as string,
        row.productName as string,
        row.spec as string,
        row.quantities as string,
        row.company as string,
        row.updater as string,
        Number(row.registrationDate)
      );
    });

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      rowsPerPage={rowsPerPage}
      pageCount={pageCount}
      setRowsPerPage={setRowsPerPage}
      handleChangePage={handleChangePage}
      handleRequestSort={handleRequestSort}
      setPage={setPage}
    >
      {pagedata.map((data: MaterialHistoryData) => (
        <MaterialHistoryTableRow row={data} key={data.no} />
      ))}
    </CommonTable>
  );
}
