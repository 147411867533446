import { Box, Card, CardContent, Typography } from "@mui/material";
import { color } from "../../../../theme/Color";
import { PopupManagementUiEvent } from "../model/PopupManagementUiEvent";
import { PopupManagementUiState } from "../model/PopupManagementUiState";
import { ICON_ADDIMAGE } from "../../../../constants/imagePath";
import VerticalSpacer from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { CommonSelect } from "../../../../component/CommonSelect";
import CommonTextField from "../../../../component/CommonTextField";
import CommonDatePicker from "../../../../component/CommonDatePicker";
import dayjs from "dayjs";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CommonDialog from "../../../../component/CommonDialog";

interface PopupManagementCreateCardProps {
  uiEvent: PopupManagementUiEvent;
  uiState: PopupManagementUiState;
  fileInputRef: React.RefObject<HTMLInputElement>;
  fileUrl: string | null;
}

const PopupManagementCreateCard = ({
  uiEvent,
  uiState,
  fileInputRef,
  fileUrl,
}: PopupManagementCreateCardProps) => {
  const countryCodeToName: { [key: string]: string } = {
    KR: "South Korea",
    KH: "Cambodia",
    VN: "Vietnam",
  };
  return (
    <Card
      sx={{
        padding: "0px",
        minWidth: "328px",
        boxShadow: 0,
        border: "1px solid",
        borderColor: color.gray300,
      }}
    >
      <CardContent sx={{ minHeight: "1000px", padding: "0px" }}>
        {fileUrl ? (
          // 이미지가 있을 때
          <Box
            component="img"
            src={fileUrl}
            sx={{
              width: "100%",
              height: "328px",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={uiEvent.HandleButtonClick}
          />
        ) : (
          // 이미지가 없을 때
          <Box
            sx={{
              width: "100%px", // 작은 박스 크기
              height: "328px",
              backgroundColor: color.gray300, // 원하는 배경색
              display: "flex", // 중앙 정렬
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderColor: uiState.inValidCheck.image ? color.error : "",
            }}
            onClick={uiEvent.HandleButtonClick}
          >
            <Box
              component="img"
              src={ICON_ADDIMAGE}
              sx={{
                width: "50px", // 작은 아이콘 크기
                height: "50px",
              }}
            />
            <Box></Box>
          </Box>
        )}
        {uiState.inValidCheck.image && (
          <Typography fontStyle={fontStyle.regularXS} color={color.error}>
            Please enter a image
          </Typography>
        )}
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={uiEvent.HandleFileUpload}
          ref={fileInputRef}
          hidden
        />
        <Box sx={{ padding: "24px" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Name
          </Typography>
          <VerticalSpacer height={6} />
          <CommonTextField
            sx={{ width: "100%" }}
            value={uiState.name}
            inputProps={{
              sx: {
                px: "12px",
                py: "10px",
                height: "20px",
                minWidth: "260px",
              },
            }}
            onChange={(event) => {
              uiEvent.HandleUiStateChange("name", event.target.value);
            }}
            error={uiState.inValidCheck.name}
          />
          {uiState.inValidCheck.name && (
            <Typography fontStyle={fontStyle.regularXS} color={color.error}>
              Please enter a name
            </Typography>
          )}
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Country
          </Typography>
          <VerticalSpacer height={6} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CommonSelect
              selectValue={countryCodeToName[uiState.country] || ""}
              menuitems={uiState.countryList}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("country", event.target.value);
              }}
              sx={{
                height: "40px",
                fontStyle: fontStyle.regularS,
                color: "black",
                width: "100%",
              }}
              placeholder="Select"
              error={uiState.inValidCheck.country}
            />
            {uiState.inValidCheck.country && (
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Please enter a country
              </Typography>
            )}
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Status
          </Typography>
          <VerticalSpacer height={6} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CommonSelect
              selectValue={uiState.status}
              menuitems={["Show", "Period", "Hide"]}
              sx={{
                height: "40px",
                fontStyle: fontStyle.regularS,
                color: "black",
                width: "100%",
              }}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("status", event.target.value);
              }}
              placeholder="Select"
              error={uiState.inValidCheck.status}
            />
            {uiState.inValidCheck.status && (
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Please enter a status
              </Typography>
            )}
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Start Date
          </Typography>
          <VerticalSpacer height={6} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <CommonDatePicker
                value={uiState.startDay ? dayjs(uiState.startDay) : null}
                onChange={(date) => uiEvent.HandleDateChange(date, "startDay")}
                views={["year", "month", "day"]}
                textFieldSx={{ width: "160px" }}
                InputPropsSx={{ height: "40px", width: "160px" }}
                // error={inValidDataCheck.openDate}
                placeholder="Select Date"
                error={uiState.inValidCheck.startDate}
                disabled={uiState.status !== "Period"}
              />
              {/* {uiState.inValidCheck.startDate && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please enter a startdate
                </Typography>
              )} */}
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                inputProps={{
                  sx: {
                    padding: "0px",
                  },
                }}
                InputProps={{
                  sx: {
                    px: "12px",
                    py: "10px",
                    height: "40px",
                    width: "110px", // 여기에 width 설정
                    borderRadius: "8px",
                    backgroundColor:
                      uiState.status === "Period" ? "" : color.gray200,
                  },
                }}
                value={
                  uiState.startTime
                    ? dayjs(uiState.startTime)
                    : dayjs("00:00:00", "HH:mm:ss")
                }
                onChange={(newValue) =>{
                  uiEvent.HandleTimeChange(newValue, "startTime")
                  console.log("time : ", uiState.startDate, uiState.startDay, uiState.startTime, uiState.startTimes)}
                }
                format={uiState.status !== "Period" ? "00:00:00" : "HH:mm:ss"}
                disabled={uiState.status !== "Period"}
              />
            </LocalizationProvider>
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            End Date
          </Typography>
          <VerticalSpacer height={6} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <CommonDatePicker
                value={uiState.endDay ? dayjs(uiState.endDay) : null} // Unix -> dayjs 변환
                onChange={(date) => uiEvent.HandleDateChange(date, "endDay")}
                views={["year", "month", "day"]}
                textFieldSx={{ width: "160px" }}
                InputPropsSx={{ height: "40px", width: "160px" }}
                // error={inValidDataCheck.openDate}
                placeholder="Select Date"
                error={uiState.inValidCheck.endDate}
                disabled={uiState.status !== "Period"}
              />
              {uiState.inValidCheck.endDate && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please enter a enddate
                </Typography>
              )}
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                inputProps={{
                  sx: {
                    padding: "0px",
                  },
                }}
                InputProps={{
                  sx: {
                    px: "12px",
                    py: "10px",
                    height: "40px",
                    width: "110px", // 여기에 width 설정
                    borderRadius: "8px",
                    backgroundColor:
                      uiState.status === "Period" ? "" : color.gray200,
                  },
                }}
                value={
                  uiState.endTime
                    ? dayjs(uiState.endTime)
                    : dayjs("00:00:00", "HH:mm:ss")
                }
                onChange={(newValue) =>
                  uiEvent.HandleTimeChange(newValue, "endTime")
                }
                format={uiState.status !== "Period" ? "00:00:00" : "HH:mm:ss"}
                disabled={uiState.status !== "Period"}
              />
            </LocalizationProvider>
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            URL
          </Typography>
          <VerticalSpacer height={6} />
          <CommonTextField
            sx={{ width: "100%" }}
            value={uiState.url}
            inputProps={{
              sx: {
                px: "12px",
                py: "10px",
                height: "20px",
                minWidth: "260px",
              },
            }}
            onChange={(event) => {
              uiEvent.HandleUiStateChange("url", event.target.value);
            }}
            error={uiState.inValidCheck.url}
          />
          {uiState.inValidCheck.url && (
            <Typography fontStyle={fontStyle.regularXS} color={color.error}>
              Please enter a URL
            </Typography>
          )}
          <VerticalSpacer height={40} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                fontStyle: fontStyle.semiboldS,
                color: color.gray600,
                cursor: "pointer",
              }}
              onClick={() => {
                uiEvent.HandleUiStateChange("cancelDialogOpen", true);
                console.log("time : ", uiState.startDate, uiState.startDay, uiState.startTime, uiState.startTimes)
              }}
            >
              Cancel
            </Box>
            <CommonWebHeaderButton
              sx={{ display: "flex", flexDirection: "column" }}
              buttonContent="Post"
              onClick={() =>
                uiEvent.HandleUiStateChange("createDialogOpen", true)
              }
            />
          </Box>
        </Box>
      </CardContent>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={uiState.createDialogOpen}
        handleOk={uiEvent.HandleCreateOk}
        handleClose={uiEvent.HandleCreateCancel}
      >
        Would you like to register the popup?
      </CommonDialog>
    </Card>
  );
};

export default PopupManagementCreateCard;
