import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import {
  ICON_CHARGE,
  ICON_REDUCTION_POINT,
} from "../../../constants/appImagePath";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { HorizontalSpacer } from "../../../component/Spacer";

interface MorePageButtonProps {
  onCarbonReductionNFTClick?: () => void;
  onChargingMembershipClick?: () => void;
}

const MorePageButton = ({
  onCarbonReductionNFTClick,
  onChargingMembershipClick,
}: MorePageButtonProps) => {
  return (
    <>
      <Box sx={{ width: "100%", display: "flex" }}>
        <Box
          sx={{
            background: "linear-gradient(to bottom right, #5D38E5, #9E84FF)",
            flex: 1,
            height: "72px",
            borderRadius: "10px",
          }}
          onClick={onCarbonReductionNFTClick}
        >
          <Box
            fontStyle={fontStyle.titleM}
            color={"white"}
            sx={{
              ml: "18px",
              mr: "8px",
              height: "100%",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "85%",
                whiteSpace: "pre-line", // 줄바꿈 문자를 인식하도록 설정
              }}
            >
              {/* {LocalizedText("ua_g100_carbonnft")} */}
              {LocalizedText("ua_g100_carbonnft").replace(/\\n/g, "\n")}
            </Box>
            {/* <HorizontalSpacer width={8} /> */}
            <Box
              component="img"
              src={ICON_REDUCTION_POINT}
              // sx={{ position: "absolute", top: 0, right: -8 }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            background: "linear-gradient(to bottom right, #5D38E5, #9E84FF)",
            flex: 1,
            ml: "8px",
            height: "72px",
            borderRadius: "10px",
          }}
          onClick={onChargingMembershipClick}
        >
          <Box
            fontStyle={fontStyle.titleM}
            color={"white"}
            sx={{
              ml: "18px",
              mr: "8px",
              height: "100%",
              display: "flex",
              position: "relative",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "85%" }}>
              {LocalizedText("ua_g100_chargingmembership")}
            </Box>
            <HorizontalSpacer width={8} />
            <Box
              component="img"
              src={ICON_CHARGE}
              sx={{ width: 21, height: 21 }}
              // sx={{ position: "absolute", top: 0, right: -8 }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MorePageButton;
