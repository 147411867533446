import { Box, IconButton, TableRow } from "@mui/material";
import { ProductionManagementCellData } from "../../../../model/ProductionManagementCellData";
import { MaterialsData } from "../../../model/MaterialsData";
import { Text } from "../../../../../../component/Text";
import { fontStyle } from "../../../../../../theme/Style";
import { color } from "../../../../../../theme/Color";
import { RegisterBillOfMaterialTreeTableCell } from "./RegisterBillOfMaterialTreeTableCell";
import { useState } from "react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CommonTextField from "../../../../../../component/CommonTextField";

interface RegisterBillOfMaterialTreeTableRowProps {
  row: MaterialsData;
}

export function RegisterBillOfMaterialTreeTableRow({
  row,
}: RegisterBillOfMaterialTreeTableRowProps) {
  const [amount, setAmount] = useState<number>(row.amount);
  const [productCellData, setProductCellData] = useState<
    ProductionManagementCellData[]
  >([
    new ProductionManagementCellData(row.category, "left", "81px", "4px"),
    new ProductionManagementCellData(row.partsCode, "left", "102px"),
    new ProductionManagementCellData(row.productName, "left", "120px"),
    new ProductionManagementCellData(row.spec, "left", "auto"),
    new ProductionManagementCellData(row.quantities, "left", "77px"),
    new ProductionManagementCellData(String(amount), "right", "67px"),
    new ProductionManagementCellData(row.category, "left", "108px"),
    new ProductionManagementCellData("", "left", "20px"),
    new ProductionManagementCellData("", "left", "50px", "8px", "4px"),
  ]);

  const updateAmountCell = (newAmount: number) => {
    setAmount(newAmount);
    setProductCellData((prevData) => {
      const updatedData = [...prevData];
      updatedData[5] = new ProductionManagementCellData(
        String(newAmount),
        "right",
        "67px"
      );
      return updatedData;
    });
  };

  const handleIncrease = () => updateAmountCell(amount + 1);
  const handleDecrease = () => updateAmountCell(Math.max(amount - 1, 0));

  const handleRemoveCell = (index: number) => {
    setProductCellData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = Number(event.target.value);
    if (!isNaN(newAmount) && newAmount >= 0) {
      updateAmountCell(newAmount);
    }
  };

  return (
    <TableRow>
      {productCellData.map((data, index) => (
        <RegisterBillOfMaterialTreeTableCell
          key={index}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            width: data.width || "auto",
            ...(index === 7 || index === 8 ? { padding: "0px" } : {}),
          }}
          childrenContainerSx={{
            width: data.width,
            display: "flex",
            flexGrow: 1,
            px: "8px",
            py: "4px",
            ...(index === 5 ? { padding: "0px" } : {}),
            ...(index === 7
              ? { justifyContent: "center", height: "18px", padding: "0px" }
              : {}),
          }}
          align={data.align}
          children={
            index === 5 ? (
              <CommonTextField
                value={String(amount)}
                onChange={handleAmountChange}
                inputProps={{
                  sx: {
                    padding: "4px 8px",
                    textAlign: "right",
                  },
                }}
              />
            ) : index === 7 ? (
              <Box>
                <IconButton
                  onClick={handleIncrease}
                  size="small"
                  sx={{ padding: "0px", marginBottom: "-9px" }}
                >
                  <ExpandMoreRoundedIcon
                    sx={{ transform: "rotate(180deg)", fontSize: "16px" }}
                  />
                </IconButton>
                <IconButton
                  onClick={handleDecrease}
                  size="small"
                  sx={{ padding: "0px", marginTop: "-9px" }}
                >
                  <ExpandLessRoundedIcon
                    sx={{ transform: "rotate(180deg)", fontSize: "16px" }}
                  />
                </IconButton>
              </Box>
            ) : index === 8 ? (
              <IconButton
                onClick={() => handleRemoveCell(index)}
                size="small"
                sx={{ color: color.gray800 }}
              >
                <HorizontalRuleIcon
                  sx={{
                    fontSize: "16px",
                    color: "white",
                    backgroundColor: color.gray800,
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            ) : (
              <Text
                sx={{
                  fontStyle: fontStyle.regularXS,
                  color: color.gray800,
                }}
                text={data.value}
              />
            )
          }
        />
      ))}
    </TableRow>
  );
}
