import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSListUiEvent } from "./CSListUiEvent";
import { CSListUiState } from "./CSListUiState";
import { CSListSideEffect } from "./CSListSideEffect";
import CSListRender from "./CSListRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import ProgressBar from "../../../component/ProgressBar";
import { CalendarType } from "../../../component/InquiryPeriod/CalendarDrawer";
import { ApiService } from "../../../restAPI/ApiService";

const CSListPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uiState, setUiState] = useState(new CSListUiState());

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    const uuid = localStorage.getItem("uuid");
    if (uuid != null) {
      getAllCSHistory(1, uuid).then((r) => {});
    }
  }, []);

  const uiEventHandler: CSListUiEvent = {
    openDatePicker: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDataPicker: true,
        };
      });
    },
    closeDatePicker: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isVisibleDataPicker: false,
        };
      });
    },
    openCalendar: (type) => {
      setUiState((prev) => {
        return {
          ...prev,
          calendarType: type,
          isCalendarOpen: true,
        };
      });
    },
    closeCalendar: (type, date) => {
      setUiState((prev) => {
        return {
          ...prev,
          isCalendarOpen: false,
          startDate: type === CalendarType.START ? date : prev.startDate,
          endDate: type === CalendarType.END ? date : prev.endDate,
          selectedDate: 0,
        };
      });
    },
    selectDate: (date) => {
      setUiState((prev) => {
        return {
          ...prev,
          selectedDate: date,
          startDate:
            prev.calendarType === CalendarType.START ? date : prev.startDate,
          endDate: prev.calendarType === CalendarType.END ? date : prev.endDate,
        };
      });
    },
    showDateAlert: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isDateAlertOpen: true,
        };
      });
    },
    hideDateAlert: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isDateAlertOpen: false,
        };
      });
    },
  };

  const sideEffectHandler: CSListSideEffect = {
    navigate: (target: string, options?: { state: any }) => {
      navigate(target, options);
    },
    requestCSList(startDate, endDate, monthsToSubtract) {
      getCSHistory(startDate, endDate, monthsToSubtract);
    },
  };

  async function getAllCSHistory(page: number, uuid: string) {
    const requestBody = {
      page: page,
      uuid: uuid,
    };
    try {
      const response = await ApiService.MobilePost(
        "/pw/ua/contents/cs/list",
        requestBody
      );
      const data = response?.data?.body;
      console.log("GET ALL CS HISTORY", response);
      if (data) {
        setUiState((prev) => {
          return {
            ...prev,
            historyList: data,
          };
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function getCSHistory(
    start: number | undefined,
    end: number,
    monthsToSubtract: number | undefined
  ) {
    const targetDate = calculateDateFromInputDate(end, monthsToSubtract ?? 0);
    const requestBody = {
      endDate: end,
      page: 1,
      uuid: localStorage.getItem("uuid") ?? "",
      startDate: start ?? targetDate,
    };

    try {
      const response = await ApiService.MobilePost(
        "/pw/ua/contents/cs/filter",
        requestBody
      );
      const data = response?.data?.body;

      if (data) {
        setUiState((prev) => {
          return {
            ...prev,
            historyList: data,
          };
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  function calculateDateFromInputDate(end: number, monthsToSubtract: number) {
    const targetDate = new Date(end);
    targetDate.setMonth(targetDate.getMonth() - monthsToSubtract);
    return targetDate.getTime();
  }

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <>
      <CSListRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandler}
      />
    </>
  );
};

export default CSListPage;
