import { Grid } from "@mui/material";
import { UserDetailsUiEvent } from "../../../UserDetailsUiEvent";
import { UserDetailsUiState } from "../../../UserDetailsUiState";

interface UserScooterMaintenanceCardProps {
  uiState: UserDetailsUiState;
  uiEvent: UserDetailsUiEvent;
}
const UserScooterMaintenanceCard = ({
  uiState,
  uiEvent,
}: UserScooterMaintenanceCardProps) => {
  return (
    <>
      <Grid item lg={12} sx={{ mt: "16px" }}></Grid>
    </>
  );
};

export default UserScooterMaintenanceCard;
