import { Box, Typography } from "@mui/material";
import Toolbar from "../../component/Toolbar";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { color } from "../../theme/Color";
import VerticalSpacer from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";

const LoginTermsOfUsePrivacyDetails = () => {
  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_b121_gnb")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        <VerticalSpacer height={16} />
        <Box
          sx={{
            width: "calc(100% - 40px)",
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: color.primary50,
              borderRadius: "10px",
              minHeight: "46px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontStyle: fontStyle.titleL,
              color: color.primary500,
            }}
          >
            {LocalizedText("ua_g731_termsofuse")}
          </Box>
          <VerticalSpacer height={16} />
          <Typography fontStyle={fontStyle.titleL}>
            Chapter 1 General Provisions
          </Typography>
          <VerticalSpacer height={24} />
          <Typography fontStyle={fontStyle.titleL}>
            Article 1 (Purpose)
          </Typography>
          <VerticalSpacer height={16} />
          <Typography fontStyle={fontStyle.bodyLr}>
            These terms and conditions govern the rights, obligations, and
            responsibilities between the company and members in relation to the
            use of the service provided by the POPLE World ( English: POPLE
            World) application (hereinafter “app”) operated by VERYWORDS Co. ,
            Ltd. (hereinafter “company”). The purpose is to stipulate matters
            relating to matters and procedures for member service use. * PC
            communication, smartphone app, This also applies to the use of
            services using wireless, etc., as long as it does not conflict with
            their nature.
          </Typography>
          <VerticalSpacer height={24} />
          <Typography fontStyle={fontStyle.titleL}>
            Article 2 (Definition of Terms)
          </Typography>
          <Typography
            fontStyle={fontStyle.bodyLr}
            sx={{ whiteSpace: "pre-line" }}
          >
            1. “Service” refers to the online service space that operates
            electric scooter (hereinafter “scooter)-related services and related
            membership systems provided by the company specified in Article 6,
            and services are provided using member accounts (email and
            password). This refers to an online service that can be provided
            {"\n"}
            {"\n"}
            2. “ Member” refers to a person who enters into a service agreement
            with the company in accordance with the agreement to these terms and
            conditions and uses the service on the site operated by the company.
            {"\n"}
            {"\n"}
            3. “ E-mail ” means a combination of letters and numbers registered
            in the POPLE World database after entering the email account
            originally used by the member and approved by the company for member
            identification and service use.
            {"\n"}
            {"\n"}
            4. “Password” means a combination of letters or numbers determined
            by the member to confirm that the member matches the approved email
            address and to protect confidentiality.
            {"\n"}
            {"\n"}
            5. “ Carbon reduction amount ” refers to the amount reduced by using
            the company’s mobility product, an scooter. This refers to the
            reduction in greenhouse gas emissions resulting from driving
            distance.
            {"\n"}
            {"\n"}6 . “ Station ” refers to a battery charging station where you
            can charge your scooter (hereinafter “ Charging Station ”), a
            station where you can receive repairs for your broken scooter
            (hereinafter “AS Center ” ), Charging station and after-sales
            service center, This refers to a cultural complex space (hereinafter
            referred to as “ Popple Station ”) that serves various functions
            such as a cafe space .{"\n"}
            {"\n"}
            7. “ Termination” means that the company or member terminates the
            effectiveness of the service agreement for the future.
            {"\n"}
            {"\n"}
            8. Among the terms used in these Terms and Conditions, terms not
            specified in Article 2 shall be governed by relevant laws and
            service-specific guidance, and other than that, general transaction
            practices shall apply.
          </Typography>
          <VerticalSpacer height={16} />
        </Box>
      </Box>
    </>
  );
};

export default LoginTermsOfUsePrivacyDetails;
