export enum MethodologyType {
  standard = "standard",
  area = "area",
  subArea = "subArea",
  DOMESTIC = "DOMESTIC",
  INTERNATIONAL = "INTERNATIONAL",
  CDM = "CDM",
  ALL = "ALL",
  REMOVAL = "REMOVAL",
  FORESTRY = "FORESTRY",
  REDUCTION = "REDUCTION",
  SOIL_CARBON = "SOIL_CARBON",
  DAC = "DAC",
  // CARBON_REMOVAL = "CARBON_REMOVAL",
  E_MOBILITY = "E_MOBILITY",
  REPLACE_ELEMENT = "REPLACE_ELEMENT",
  CHEMICALS = "CHEMICALS",
  ENERGY_CONVERSION = "ENERGY_CONVERSION",
  RENEWABLE_ENERGY = "RENEWABLE_ENERGY",
  CHEMICAL = "CHEMICAL",
  AGRICULTURE = "AGRICULTURE",
  CCS = "CCS",
  SECONDHAND = "SECONDHAND",
  REPLACE_VEHICLE = "REPLACE_VEHICLE",
  SERVICE = "SERVICE",
  ETC = "ETC",
  RECYCLE = "RECYCLE"
}

export enum ParamType {
    standard = "standard",
    type = "type",
    unit = "unit",
    BASELINE = "BASELINE",
    PROJECT = "PROJECT",
    FUEL_CONSUMPTION = "FUEL_CONSUMPTION",
    EMISSION_FACTOR =  "EMISSION_FACTOR",
    NET_CALORIC_VALUE = "NET_CALORIC_VALUE",
    MJ_I = "MJ_I",
    KG_CO2_EQ_KM = "KG_CO2_EQ_KM"


}
export interface Param {
    id: number;
    standard: string;
    type: string;
    name: string;
    value: number;
    unit: string
    methodology: {
      id: number;
      methodologyIdName: string;
      title: string;
    };
    version: string;
    region: string;
    description: string;
    createdAt: string;
  }
  