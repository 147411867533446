import { Box } from "@mui/material";
import { color } from "../../../theme/Color";
import { HorizontalSpacer } from "../../../component/Spacer";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";

interface LanguageButtonProps {
  title: string;
  image: string;
  isSelected: boolean;
  onClick: () => void;
}

export default function LanguageButton({
  title,
  image,
  isSelected,
  onClick,
}: LanguageButtonProps) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "41px",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "50%", // 둥근 모양으로 만들기
            border: `1px solid ${color.gray300}`, // 1px 회색 테두리 추가
            objectFit: "cover", // 이미지가 잘리지 않도록 설정
          }}
        />
        <HorizontalSpacer width={10} />
        <Text
          sx={{
            color: isSelected ? color.primary500 : color.default,
            fontStyle: fontStyle.subTitleL,
            flexGrow: 1,
          }}
          text={title}
        />
        <HorizontalSpacer width={20} />
        {isSelected && <Text text={"✔️"} />}
      </Box>
    </Box>
  );
}
