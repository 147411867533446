import { Box, Typography } from "@mui/material";
import VerticalSpacer from "../../../component/Spacer";
import Toolbar from "../../../component/Toolbar";
import { useNavigate } from "react-router-dom";
import GradeCard from "./section/GradeCard";
import { color } from "../../../theme/Color";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";

const NFTGradeRender = () => {
  useAndroidBackHandler(() => {
    if (window.Android) {
      window.history.back();
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title="Carbon Reduction NFT"
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        <VerticalSpacer height={17} />
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            width: "calc(100% - 40px)",
            scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
            "&::-webkit-scrollbar": {
              display: "none", // Webkit 브라우저에서    스크롤바 숨기기
            },
          }}
        >
          <GradeCard
            title="RED"
            fontColor={color.toolingRed}
            criteria="~ 45.5"
            distance="(0 ~ 1,000 km Driving)"
            content="POPLE Station 2% Discount"
          />
          <GradeCard
            title="Pink"
            fontColor={color.toolingPink02}
            criteria="~ 109.3"
            distance="(1,001 ~ 3,400 km Driving)"
            content="POPLE Station 3% Discount"
          />
          <GradeCard
            title="Purple"
            fontColor={color.toolingPrimary}
            criteria="~ 141.2"
            distance="(3,401 ~ 6,500 km Driving)"
            content="POPLE Station 5% Discount"
          />
          <GradeCard
            title="Blue"
            fontColor={color.toolingBlue}
            criteria="~ 295.9"
            distance="(6,501 ~ 13,000 km Driving)"
            content="POPLE Station 7% Discount"
          />
          <GradeCard
            title="Mint"
            fontColor={color.toolingMint}
            criteria="~ 591.9"
            distance="(13,001 km Driving)"
            content="NEW NFT Character"
          />
        </Box>
      </Box>
    </>
  );
};

export default NFTGradeRender;
