import {Box, Typography} from "@mui/material";
import {color} from "../../../theme/Color";
import VerticalSpacer, {HorizontalSpacer} from "../../../component/Spacer";
import {fontStyle} from "../../../theme/Style";
import {ICON_BELL, ICON_PHONE_CALL} from "../../../constants/appImagePath";

interface EmergencyCardProps {
    title: String,
    content: String,
    onClick: () => void
}

export default function EmergencyCard(
    {title, content, onClick} : EmergencyCardProps
) {
    return (
        <Box
            width="auto"
            height="auto"
            sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "6px",
                border: `1px solid ${color.error}`,
                backgroundColor: color.backgroundError
            }}
            onClick={onClick}>
            <HorizontalSpacer width={16}/>
            <Box
                sx={{
                    flexDirection: "column",
                    py: "12px",
                    flexGrow: 1
                }}>
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex"
                    }}>
                    <img
                        src={ICON_BELL}
                        alt=""
                        style={{
                            width: "20px",
                            height: "20px"
                        }}/>
                    <HorizontalSpacer width={8}/>
                    <Typography
                        sx={{
                            color: color.error,
                            fontStyle: fontStyle.titleS
                        }}>{title}</Typography>
                </Box>
                <VerticalSpacer height={8}/>
                <Typography
                    sx={{
                        color: color.error,
                        fontStyle: fontStyle.bodySr
                    }}>{content}</Typography>
            </Box>

            <HorizontalSpacer width={16}/>
            <img
                src={ICON_PHONE_CALL}
                alt=""
                style={{
                    width: "36px",
                    height: "36px"
                }}/>
            <HorizontalSpacer width={16}/>
        </Box>
    );
}