import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MembershipOverviewRender from "./MembershipOverviewRender";
import { MembershipOverviewSideEffect } from "./MembershipOverviewSideEffect";
import { MembershipOverviewUiEvent } from "./MembershipOverviewUiEvent";
import { MembershipOverviewUiState } from "./MembershipOverviewUiState";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import ProgressBar from "../../../component/ProgressBar";
import MembershipInfo, { MembershipStatus } from "../model/MembershipInfo";
import { ApiService } from "../../../restAPI/ApiService";
import ProductItem from "../model/ProductItem";
import { getCountryCode } from "../../../customHook/useLocale";

interface MembershipOverviewPageProps {
  userUUID: string;
}

const MembershipOverviewPage = ({ userUUID }: MembershipOverviewPageProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); //나중에 true로 변경
  const [uiState, setUiState] = useState(new MembershipOverviewUiState());

  useAndroidBackHandler(() => {
    if (uiState.isChangeMembershipDrawerOpen) {
      uiEventHandler.HideChangeMembershipDrawer();
    } else if (uiState.isVoucherDrawerOpen) {
      uiEventHandler.HideVoucherDrawer();
    } else if (uiState.isChangeMembershipDialogOpen) {
      uiEventHandler.HideChangeMembershipDialog();
    } else {
      window.history.back();
    }
  }, [
    uiState.isChangeMembershipDrawerOpen,
    uiState.isVoucherDrawerOpen,
    uiState.isChangeMembershipDialogOpen,
  ]);

  useEffect(() => {
    (async () => {
      try {
        await updateMyMembershipInfo(localStorage.getItem("uuid") || "");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (uiState.isChangeMembershipDrawerOpen) {
        await updateChangeMembershipList();
      }

      if (uiState.isVoucherDrawerOpen) {
        await updateVoucherList();
      }
    };

    fetchData();
  }, [uiState.isChangeMembershipDrawerOpen, uiState.isVoucherDrawerOpen]);

  const uiEventHandler: MembershipOverviewUiEvent = {
    ShowVoucherDrawer: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isVoucherDrawerOpen: true,
        };
      });
    },

    HideVoucherDrawer: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isVoucherDrawerOpen: false,
        };
      });
    },

    ShowChangeMembershipDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isChangeMembershipDialogOpen: true,
        };
      });
    },

    HideChangeMembershipDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isChangeMembershipDialogOpen: false,
        };
      });
    },
    SelectVoucher: (event: ProductItem) => {
      setUiState((prev) => {
        return {
          ...prev,
          selectedVoucher: event,
        };
      });
    },

    ShowChangeMembershipDrawer: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isChangeMembershipDrawerOpen: true,
        };
      });
    },

    HideChangeMembershipDrawer: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isChangeMembershipDrawerOpen: false,
        };
      });
    },

    SelectMembership: (event: ProductItem) => {
      setUiState((prev) => {
        return {
          ...prev,
          selectedMembership: event,
        };
      });
    },
  };

  const sideEffectHandlers: MembershipOverviewSideEffect = {
    navigate: function (target: string, options?: { state: any }): void {
      console.log("navigate", target);
      navigate(target, options);
    },
  };

  // 멤버십 정보 조회 함수

  async function getMyMembershipInfo(
    uuid: string,
  ): Promise<MembershipInfo | null> {
    let membershipInfo: MembershipInfo | null = null;
    const url = "/pw/ua/product/MV/myMembership";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    }
    try {
      await ApiService.MobilePost(url, requestBody).then((res) => {
        membershipInfo = res?.data.body;
        console.log("getMyMembershipInfo response", membershipInfo);
      });
      return membershipInfo;
    } catch (e) {
      console.log("failed to get membership", e);
      return null;
    }
  }

  async function updateMyMembershipInfo(uuid: string) {
    const membershipInfo = await getMyMembershipInfo(uuid);
    if (membershipInfo) {
      setUiState((prev) => {
        return {
          ...prev,
          membershipInfo: membershipInfo,
          membershipStatus: calculateMembershipStatus(membershipInfo),
        };
      });
      console.log("MEMBERSHIPSTATUS", uiState.membershipStatus);
    }
  }

  //상품 조회

  async function getProductList(type: string): Promise<ProductItem[]> {
    let productList: ProductItem[] = [];
    const requestBody = {
      country: getCountryCode(),
      type: type,
    };
    try {
      await ApiService.MobilePost("/pw/ua/product/MV/list", requestBody).then(
        (res) => {
          productList = res?.data.body;
          console.log("productList response", productList);
        },
      );
      return productList;
    } catch (e) {
      console.log("productList error", e);
      return [];
    }
  }

  async function updateVoucherList() {
    const productList = await getProductList("Voucher");

    if (productList) {
      setUiState((prev) => ({
        ...prev,
        voucherItems: productList,
      }));
    }
  }

  async function updateChangeMembershipList() {
    const productList = await getProductList("Membership");

    if (productList && uiState.membershipInfo) {
      const currentMembershipName =
        uiState.membershipStatus === MembershipStatus.Expired
          ? uiState.membershipInfo.expiredName
          : uiState.membershipInfo.name;
      const filteredList = productList.filter(
        (item) => item.name !== currentMembershipName,
      );

      setUiState((prev) => ({
        ...prev,
        changeMembershipItems: filteredList,
      }));
    }
  }

  // 멤버십 상태 계산 함수

  const calculateMembershipStatus = (
    membershipInfo: MembershipInfo,
  ): MembershipStatus => {
    if (!membershipInfo.status) {
      return MembershipStatus.Expired;
    }

    const currentDate = new Date(); // 현재 날짜
    const renewalStartDate = new Date(membershipInfo.extendStartDate ?? 0); // 멤버십 갱신 시작일
    const renewalEndDate = new Date(membershipInfo.extendEndDate ?? 0); // 멤버십 갱신 종료일

    if (currentDate >= renewalStartDate && currentDate <= renewalEndDate) {
      return MembershipStatus.ActiveWithinRenewal;
    } else {
      return MembershipStatus.ActiveOutsideRenewal;
    }
  };

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <>
      <MembershipOverviewRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandlers}
        userUUID={userUUID}
      />
    </>
  );
};

export default MembershipOverviewPage;
