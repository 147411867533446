import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import PurchaseHistoryItem, {
  PurchaseType,
} from "../../model/PurchaseHistoryItem";
import LocalizedDate, { DateFormatType } from "../../../../component/LocalizedDate";

interface PurchaseHistoryCellProps {
  item: PurchaseHistoryItem;
}

const PurchaseHistoryCell = ({ item }: PurchaseHistoryCellProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: "8px",
        py: "16px",
        borderBottom: `1px solid ${color.gray200}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography fontStyle={fontStyle.subTitleL} color={color.default}>
          {item.purchaseType === PurchaseType.PURCHASE
            ? LocalizedText("ua_g421_purchase")
            : LocalizedText("ua_g421_change")}
          {" - "}
          {item.name}
        </Typography>
        <Typography fontStyle={fontStyle.titleL} color={color.primary500}>
          - $ {item.price.toLocaleString()}
        </Typography>
      </Box>
      <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
        {LocalizedDate({
          date: item.billingDate,
          type: DateFormatType.YearMonthDayTime,
        })}
      </Typography>
    </Box>
  );
};

export default PurchaseHistoryCell;
