import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import {
  ProductionData,
  ProductionList,
  ProductionStatusType,
} from "./model/ProductionData";
import { ProductionTableRow } from "./ProductionTableRow";

interface ProductionManagementTableProps {
  headCells?: HeadCell[];
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: number;
}
function isProductionStatusType(status: any): status is ProductionStatusType {
  return Object.values(ProductionStatusType).includes(status);
}

function isProductionData(data: any): data is ProductionData {
  return (
    typeof (data as ProductionData).no === "number" &&
    typeof (data as ProductionData).category === "string" &&
    typeof (data as ProductionData).partsCode === "string" &&
    typeof (data as ProductionData).productName === "string" &&
    typeof (data as ProductionData).spec === "string" &&
    typeof (data as ProductionData).made === "string" &&
    typeof (data as ProductionData).quantities === "string" &&
    typeof (data as ProductionData).amount === "number" &&
    isProductionStatusType((data as ProductionData).status) &&
    typeof (data as ProductionData).registrationDate === "number"
  );
}

export function ProductionTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: ProductionManagementTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(ProductionList, rowsPerPage || 10, setPage, page || 0);

  const pagedata: ProductionData[] = (visibleRows as unknown[])
    .filter(isProductionData)
    .slice(
      (page || 0) * (rowsPerPage || 10),
      ((page || 0) + 1) * (rowsPerPage || 10)
    );

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {pagedata.map((data: ProductionData, index: number) => (
        <ProductionTableRow row={data} key={index} />
      ))}
    </CommonTable>
  );
}
