import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fontStyle } from "../../../../theme/Style";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import CommonTable, { useVisibleRows } from "../../../../component/CommonTable";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  const navigate = useNavigate();
  const rowData = row.value;
  return (
    <>
      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/adminMng/adminDetails/${rowData.uuid}`);
        }}
      >
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.index}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Box fontStyle={fontStyle.regularXXS}>{rowData.role}</Box>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {rowData.name}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {rowData.email}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {rowData.phoneNumber}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {rowData.status}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {rowData.answerDate == 0 ? (
              <></>
            ) : (
              <>
                {LocalizedDate({
                  date: row.lastLoginDate,
                  type: DateFormatType.AdminType,
                })}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: rowData.registrationDt,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface AdminTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

const AdminTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: AdminTableProps) => {
  const headCells = [
    {
      id: "index",
      align: "center",
      disablePadding: false,
      label: "No",
    },
    {
      id: "role",
      align: "left",
      disablePadding: false,
      label: "Role",
    },
    {
      id: "name",
      align: "left",
      disablePadding: false,
      label: "Name",
    },
    {
      id: "email",
      align: "left",
      disablePadding: false,
      label: "E-mail",
    },
    {
      id: "phoneNumber",
      align: "left",
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "status",
      align: "left",
      disablePadding: false,
      label: "Status",
    },
    {
      id: "lastLoginDate",
      align: "left",
      disablePadding: false,
      label: "Last Login Date",
    },
    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Registration Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((row: any, index: any) => (
        <Row row={row} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default AdminTable;
