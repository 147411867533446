import { Inquiry } from "../model/Inquiry";
import { InquiryResponse } from "../model/InquiryResponse";

export function toInquiry(it: InquiryResponse): Inquiry {
  return new Inquiry(
    it.title,
    it.imageUrls,
    it.registrationDate,
    it.content,
    it.id,
    it.category,
    it.status,
  );
}
