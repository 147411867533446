import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CommonList from "../../component/CommonList";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import { ApiService } from "../../restAPI/ApiService";
import CsMngDetailsGrid from "./section/CsMngDetailsGrid";
import CsDetailsRender from "./CsDetailsRender";
import { CsDetailsSideEffect } from "./model/CsDetailsSideEffect";
import { CsDetailsUiEvent } from "./model/CsDetailsUiEvent";
import { CsDetailsUiState, Repair } from "./model/CsDetailsUiState";
import { CsResponse } from "./model/CsResponse";
import axios from "axios";
import CommonDialog from "../../component/CommonDialog";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";

const CsDetailsPage = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>();
  const pathname = useLocation().pathname;
  const inquiryId = pathname.split("/").pop();
  const [uiState, setUiState] = useState<CsDetailsUiState>({
    reportOpen: false,
    onBtnComplete: false,
    problemDescription: "",
    receiptDate: -1,
    reporter: "",
    preferredCSStationId: -1,
    title: "",
    content: "",
    preferredCSDate: -1,
    completeDate: -1,
    phoneNumber: "",
    totalFee: 0,
    registrationDate: -1,
    correctiveAction: "",
    vin: "",
    id: "",
    writer: "",
    preferredCSStation: "",
    email: "",
    status: "",
    deleteDate: -1,
    repairFee: [{ name: "Repair Cost", count: 1, price: 0, amount: 0 }],
  });

  useEffect(() => {
    uiEvent.onChangeBtnComplete();
  }, [uiState.problemDescription, uiState.correctiveAction, uiState.totalFee]);

  const uiEvent: CsDetailsUiEvent = {
    onChangeData: (values): void => {
      setUiState((prev) => {
        var ck = "Complete" === values.status || "Receipt" === values.status;
        return {
          ...prev,
          reportOpen: ck || false,
          problemDescription: values.problemDescription || "",
          receiptDate: values.receiptDate || -1,
          reporter: values.reporter || "",
          preferredCSStationId: values.preferredCSStationId || -1,
          title: values.title || "",
          content: values.content || "",
          preferredCSDate: values.preferredCSDate || -1,
          completeDate: values.completeDate || -1,
          phoneNumber: values.phoneNumber || "",
          totalFee: values.totalFee || 0,
          registrationDate: values.registrationDate || -1,
          correctiveAction: values.correctiveAction || "",
          vin: values.vin || "",
          id: values.id || "",
          writer: values.writer || "",
          preferredCSStation: values.preferredCSStation || "",
          email: values.email || "",
          status: values.status || "",
          deleteDate: values.deleteDate || -1,
          repairFee: values.repairFee || [
            { name: "Repair Cost", count: 1, price: 0, amount: 0 },
          ],
        };
      });
    },
    onChangeProblemDescription: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          problemDescription: value || "",
        };
      });
    },
    onChangeCorrectiveAction: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          correctiveAction: value || "",
        };
      });
    },
    onChangeRepairFeeLineName: (value, index): void => {
      setUiState((prev) => {
        uiState.repairFee[index].name = value;
        return {
          ...prev,
          repairFee: uiState.repairFee || [],
        };
      });
    },
    onChangeRepairFeeLinePrice: (value, index): void => {
      setUiState((prev) => {
        uiState.repairFee[index].price = value;
        uiState.repairFee[index].amount =
          uiState.repairFee[index].count * value;
        uiState.totalFee = 0;

        for (let i = 0; i < uiState.repairFee.length; ++i) {
          uiState.totalFee += uiState.repairFee[i].amount;
        }
        return {
          ...prev,
          repairFee: uiState.repairFee || [],
          totalFee: uiState.totalFee,
        };
      });
    },
    onChangeRepairFeeLineCount: (value, index): void => {
      setUiState((prev) => {
        uiState.repairFee[index].count = value;
        uiState.repairFee[index].amount =
          uiState.repairFee[index].price * value;
        uiState.totalFee = 0;

        for (let i = 0; i < uiState.repairFee.length; ++i) {
          uiState.totalFee += uiState.repairFee[i].amount;
        }
        return {
          ...prev,
          repairFee: uiState.repairFee || [],
          totalFee: uiState.totalFee,
        };
      });
    },
    onChangeReportOpen: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          reportOpen: value || false,
        };
      });
    },
    onAddNewRepairFeeRow: (): void => {
      setUiState((prev) => {
        console.log("[onAddRepairFeeRow]");
        return {
          ...prev,
          repairFee: [
            ...uiState.repairFee,
            { name: "", count: 1, price: 0, amount: 0 },
          ],
        };
      });
    },
    onChangeBtnComplete: (): void => {
      setUiState((prev) => {
        let isEnabled = true;

        if (
          "" === uiState.problemDescription ||
          "" === uiState.correctiveAction
        ) {
          isEnabled = false;
        }
        for (let i = 0; i < uiState.repairFee.length; ++i) {
          if (!isEnabled) break;
          if ("" === uiState.repairFee[i].name) {
            isEnabled = false;
          }
        }
        return {
          ...prev,
          onBtnComplete: isEnabled,
        };
      });
    },
  };

  const sideEffect: CsDetailsSideEffect = {
    handelBtnReceive: (inquiryId: string) => {
      ApiService.WebPost(`/pw/aw/contents/cs/receipt`, {
        id: inquiryId,
      }).then((res) => {
        console.log("res : ", res);
        if (res?.data?.result) {
          console.log(res?.data?.result);
          uiEvent.onChangeReportOpen(true);
          window.location.reload();
        }
      });
    },
    onClickCompleteButton: () => {
      let isSwitch = true;

      if (
        "" === uiState.problemDescription ||
        "" === uiState.correctiveAction
      ) {
        isSwitch = false;
      }
      for (let i = 0; i < uiState.repairFee.length; ++i) {
        if (!isSwitch) break;
        if ("" === uiState.repairFee[i].name) {
          isSwitch = false;
        }
      }

      if (!isSwitch) {
        setOpen(true);
        return;
      }

      setOpenConfirm(true);
    },
    handelCompleteRegistrationReport: (inquiryId) => {
      setProgress(true);
      const uuid = localStorage.getItem("user_uuid");
      if (!uuid) return;

      let totalFee = String(uiState.totalFee);
      if (-1 === totalFee.indexOf(".")) {
        totalFee += ".0";
      }

      console.log(totalFee);

      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
      };

      ApiService.WebPost(
        "/pw/aw/contents/cs/createReport",
        {
          id: inquiryId,
          uuid: uuid,
          identifiedProblemDescription: uiState.problemDescription,
          correctiveAction: uiState.correctiveAction,
          totalFee: totalFee,
          repairFee: uiState.repairFee,
        },
        headers
      )
        .then((res) => {
          console.log(res?.data);
          setOpen(false);
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setProgress(false);
        });
    },
  };

  const handleOk = () => {
    setOpenConfirm(false);
    if (inquiryId) {
      sideEffect.handelCompleteRegistrationReport(inquiryId);
    }
  };

  const handleClose = () => {
    setOpenConfirm(false);
  };

  return (
    <>
      <CsDetailsRender
        uiState={uiState}
        uiEvent={uiEvent}
        sideEffect={sideEffect}
      />
      <SimpleDialog
        children="Please check report - empty value"
        isOpen={open}
        positiveText="OK"
        onPositive={() => {
          uiState.onBtnComplete = false;
          setOpen(false);
        }}
        onClose={() => {
          uiState.onBtnComplete = false;
          setOpen(false);
        }}
      />
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={openConfirm}
        handleOk={handleOk}
        handleClose={handleClose}
      >
        Once the CS id markeed as Completed, it
        <br />
        cannot be modified.
        <br />
        Do you want to complete CS?
      </CommonDialog>
    </>
  );
};

export default CsDetailsPage;
