import ProductItem from "../../model/ProductItem";
import { Box, Typography } from "@mui/material";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer from "../../../../component/Spacer";
import ProductCard from "./ProductCard";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";

interface VoucherSectionProps {
  voucherData: ProductItem[];
}

const VoucherSection = ({ voucherData }: VoucherSectionProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        px: "16px",
        boxSizing: "border-box",
      }}
    >
      <VerticalSpacer height={32} />
      <Typography
        fontStyle={fontStyle.titleL}
        color={color.default}
        sx={{
          px: "16px",
          textAlign: "center",
          whiteSpace: "normal",
          mb: "4px",
        }}
      >
        {LocalizedText("ua_g410_voucher_title")}
      </Typography>
      <Typography
        fontStyle={fontStyle.bodyMr}
        color={color.gray700}
        sx={{ textAlign: "center" }}
      >
        {LocalizedText("ua_g410_voucher_message")}
      </Typography>

      <VerticalSpacer height={32} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {voucherData.map((item, index) => (
          <ProductCard
            key={item.id}
            productType="Voucher"
            title={item.name}
            price={`$ ${item.price}`}
            description={item.description}
            tag={item.label}
          />
        ))}
      </Box>
    </Box>
  );
};

export default VoucherSection;
