import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { ReactNode } from "react";

interface SimpleDialogProps {
  children?: ReactNode;
  positiveText?: string;
  nagativeText?: string;
  isOpen: boolean;
  onPositive?: () => void;
  onNagative?: () => void;
  onClose: () => void;
}

export default function SimpleDialog({
  children,
  positiveText = "Confirm",
  nagativeText = "Cancel",
  isOpen,
  onPositive,
  onNagative,
  onClose,
}: SimpleDialogProps) {
  const widthVal = "web" === window.document.body.id ? "" : "70%";
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "20px",
          px: "20px",
          pt: "20px",
          pb: "16px",
          minWidth: {widthVal},
          minHeight: "11%",
        },
      }}
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && onClose) {
          onClose();
        }
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
        }}
      >
        <DialogContentText
          sx={{
            fontStyle: fontStyle.bodyMr,
            whiteSpace: "pre-line",
          }}
        >
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "0px",
          pt: "16px",
        }}
      >
        {onNagative ? (
          <Button
            onClick={onNagative}
            sx={{
              fontStyle: fontStyle.titleL,
              color: color.gray400,
            }}
          >
            {nagativeText}
          </Button>
        ) : null}
        <Button
          onClick={onPositive}
          sx={{
            fontStyle: fontStyle.titleL,
            color: color.primary500,
          }}
        >
          {positiveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
