import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { Pagination, TablePagination, Typography } from "@mui/material";
import { fontStyle } from "../theme/Style";
import { color } from "../theme/Color";
import { HorizontalSpacer } from "./Spacer";
import { CheckboxState, CommonCheckbox } from "./CommonCheckbox";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

export interface HeadCell {
  disablePadding: boolean;
  align: "center" | "left" | "right" | "justify" | "inherit"; // 타입 지정
  id: string;
  label: string;
}

interface EnhancedTableProps {
  headCells: readonly HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  checkbox?: boolean;
  checkboxState?: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { headCells, order, orderBy, onRequestSort, checkbox = false, checkboxState } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {checkbox && (
          <TableCell padding="checkbox">
            <CommonCheckbox
              checkboxState={CheckboxState.Unchecked} // 초기 상태
              checkboxName=""
              onClick={(_state) => checkboxState}
              sx={{ height: "16px", width: "16px", margin: 0 }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              position: "relative",
              fontStyle: fontStyle.semiboldXXS,
              color: color.gray600,
            }}
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            <TableSortLabel
              sx={{ position: "absolute" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface CommonTableProps {
  headCells: any;
  children?: React.ReactNode;
  order: any;
  orderBy: any;
  page: any;
  docCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  pageCount?: any;
  handleRequestSort: any;
  handleChangePage: any;
  setPage: any;
  paginationEnabled?: boolean;
  checkbox?: boolean;
  checkboxState?: boolean;
}

const CommonTable = ({
  headCells,
  children,
  order,
  orderBy,
  page,
  docCount,
  pageCount,
  rowsPerPage,
  setRowsPerPage,
  handleRequestSort,
  handleChangePage,
  setPage,
  paginationEnabled = true,
  checkbox = false,
  checkboxState,
}: CommonTableProps) => {
  const pagenav = () => {
    return null;
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, borderRadius: "10px" }} elevation={0}>
        <TableContainer>
          <Table sx={{ minWidth: 100 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              checkbox={checkbox}
            />
            <TableBody>{children}</TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {paginationEnabled && (
            <>
              {" "}
              <Pagination
                sx={{
                  mt: 2,
                  mb: 2,
                  ml: 2,
                  flex: 1,
                  "& .MuiPaginationItem-root": {
                    fontWeight: "bold",
                    color: color.gray600,
                    "&.Mui-selected": {
                      background: color.primaryWeb100,
                      color: color.primaryWeb500,
                    },
                  },
                }}
                count={pageCount}
                shape="rounded"
                defaultPage={1}
                page={page + 1}
                // color="secondary"
                onChange={handleChangePage}
              />
              <TablePagination
                component="div"
                sx={{
                  flex: 1,
                }}
                count={docCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Showing"
                // labelDisplayedRows={() => `out of ${docCount}`}
                labelDisplayedRows={() => ``}
                ActionsComponent={pagenav} // 페이지 버튼 없애기
              />
            </>
          )}
          <HorizontalSpacer width={30} />
        </Box>
      </Paper>
    </Box>
  );
};

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function useVisibleRows(
  rows: any[],
  rowsPerPage: number,
  setPage?: any,
  page?: any
) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  // console.log(page, rowsPerPage, rows);
  const visibleRows = React.useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy)).slice(
      0,
      rows.length
    );
  }, [order, orderBy, page, rowsPerPage, rows]);

  return {
    order,
    orderBy,
    page,
    visibleRows,
    handleRequestSort,
    handleChangePage,
  };
}

export default CommonTable;
