import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../theme/Style";
import CommonButton from "../../component/CommonButton";
import { useEffect, useState } from "react";
import VerticalSpacer from "../../component/Spacer";
import Toolbar from "../../component/Toolbar";
import { useLocation, useNavigate } from "react-router-dom";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { color } from "../../theme/Color";
import { ApiService } from "../../restAPI/ApiService";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import ProgressBar from "../../component/ProgressBar";

const MyRideWatingPage = () => {
  const navigate = useNavigate();
  const [qr, setQRData] = useState("");
  const [hasQr, setHasQr] = useState(false);
  const location = useLocation();
  const { state } = location.state || {}; // 전달된 상태를 받음
  const [openDialog, setOpenDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("state is :", state);

  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  useEffect(() => {
    const handleAppToWeb = (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      setQRData(customEvent.detail);
    };

    window.addEventListener("dispatchQRData", handleAppToWeb);
    return () => {
      window.removeEventListener("dispatchQRData", handleAppToWeb);
    };
  }, []);

  useEffect(() => {
    // console.log(hasQr);
    if (qr === "") {
      setHasQr(false);
    } else {
      setHasQr(true);
      navigate("/MyRide", {
        state: {
          hasQr: true,
        },
      });
    }
  }, [qr]);

  return (
    <>
      {loading && <ProgressBar />}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_e310_gnb")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "auto",
          }}
        >
          <VerticalSpacer height={20} />
          <Box sx={{ width: "100%", mb: 2 }}>
            <Typography fontStyle={fontStyle.headingS}>
              {LocalizedText("ua_e200_confirmation_title")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              mb: 2,
              backgroundColor: color.primary50,
              borderRadius: "10px",
            }}
          >
            <Box sx={{ p: 2.5, pt: 1.8, pb: 1.8 }}>
              <Typography fontStyle={fontStyle.titleL} color={color.primary500}>
                {LocalizedText("ua_e200_userinfo")}
              </Typography>
              <Typography
                fontStyle={fontStyle.titleL}
                sx={{ display: "flex", mt: 1 }}
                color={color.gray700}
              >
                {LocalizedText("ua_e200_name")}
                <Typography fontStyle={fontStyle.bodyLr} sx={{ ml: 1 }}>
                  Very Kim
                </Typography>
              </Typography>
              <Box
                fontStyle={fontStyle.titleL}
                color={color.gray700}
                sx={{ display: "flex" }}
              >
                {LocalizedText("ua_e200_email")}
                <Typography fontStyle={fontStyle.bodyLr} sx={{ ml: 1 }}>
                  veryvedi@gmail.com{" "}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              mb: 2,
              backgroundColor: color.primary50,
              borderRadius: "10px",
            }}
          >
            <Box sx={{ p: 2.5, pt: 1.8, pb: 1.8 }}>
              <Typography fontStyle={fontStyle.titleL} color={color.primary500}>
                {LocalizedText("ua_e200_scooterinfo")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={state.modelPicUrl}
                    style={{
                      backgroundColor: "#F2F2F2",
                      borderRadius: 10,
                      height: "70px",
                      width: "70px",
                      objectFit: "cover", // 이미지가 박스에 맞게 잘리며 비율 유지
                    }}
                  />
                  <Box sx={{ display: "flex", ml: 2, flexDirection: "column" }}>
                    <Typography fontStyle={fontStyle.titleL}>
                      {state.productName}
                    </Typography>
                    <Box
                      color={color.gray700}
                      sx={{ display: "flex" }}
                      fontStyle={fontStyle.titleL}
                    >
                      {LocalizedText("ua_e200_contract")}
                      <Typography sx={{ ml: 1 }} fontStyle={fontStyle.bodyLr}>
                        {state.agreementDt}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "91%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            {LocalizedText("ua_e200_pressbutton")}
          </Typography>
          <CommonButton
            title={LocalizedText("ua_e200_getscooter_button")}
            textColor="white"
            onClick={() => {
              // if (window.Android) {
              //   window.Android.openQRCamera();
              // }
              // if (window.webkit) {
              //   window.webkit.messageHandlers.startQRScan.postMessage("Hi");
              // }
              setLoading(true);
              ApiService.WebPost("/pw/backend/api/scooterAgreement/received", {
                agreementNumber: state.agreementNumber,
                uuid: localStorage.getItem("uuid") || "",
              })
                .then((res) => {
                  console.log(res?.data.body);
                  if (res?.data.body.status == "not Hand Over") {
                    setOpenErrorDialog(true);
                    setLoading(false);
                  } else {
                    setOpenDialog(true);
                    setLoading(false);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
            sx={{
              m: "20px",
            }}
            disabled={state.receiptConfirmation != "Hand Over"}
          />
        </Box>
      </Box>
      <SimpleDialog
        isOpen={openDialog}
        onPositive={() => {
          window.history.back();
        }}
        onClose={() => {}}
        children={LocalizedText("ua_e200_alert_complete_message")}
        positiveText="OK"
      />
      <SimpleDialog
        isOpen={openErrorDialog}
        onPositive={() => {
          window.history.back();
        }}
        onClose={() => {}}
        children={
          LocalizedText("ua_e200_alert_failed_message") + "\nReason : TIME_OUT"
        }
        positiveText="OK"
      />
    </>
  );
};

export default MyRideWatingPage;
