import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  APP_LOGO,
  APP_LANGUAGE_BACKGROUND,
  IMAGE_US,
  IMAGE_KHMER
} from "../../constants/appImagePath";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { useNavigate } from "react-router-dom";
import { LanguageButtonModel } from "../MobileSetting/language/model/LanguageButtonModel";
import CommonButton from "../../component/CommonButton";

const LoginLanguagePage = () => {
  const navigate = useNavigate();
  const [isCanNext, setIsCanNext] = useState(false);
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    localStorage.removeItem("LOCALE_STORAGE_KEY");
  }, []);

  return (
    <Box
      sx={{
        backgroundImage:  `url(${APP_LANGUAGE_BACKGROUND})`,
        display: "flex",
        position: "fixed",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "block",
          padding: "30px 15px",
          position: "relative",
          width: "calc(100% - 30px)",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "10%",
          }}
        >
          <img
            src={APP_LOGO}
            alt="Login Character"
            style={{
              width: "auto",
              height: "30px",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "10%",
          }}
        >
          <Typography
            fontStyle={fontStyle.headingS}
            sx={{
              color: color.white,
              width: "100%",
            }}
          >
            Choose your preferred
          </Typography>
          <Typography
            fontStyle={fontStyle.headingS}
            sx={{
              color: color.white,
              width: "100%",
            }}
          >
           language for POPLE World.
          </Typography>
        </Box>

        <Box
          sx={{
            position: "relative",
            textAlign: "center",
            width: "100%",
            height: "70%",
          }}
        >
          <Select
            name="countryCode"
            value={countryCode || ""}
            size="small"
            sx={{ width: "50%" }}
            style={{
              borderRadius: "7px",
              backgroundColor: "white",
              marginTop: "50%",
              textAlign: "left",
            }}
            onChange={(it)=>{
              let val = it.target.value;
              if('string' === typeof val){
                localStorage.setItem("LOCALE_STORAGE_KEY", val);
                setCountryCode(val);
                setIsCanNext(true);
              }
            }}
          >
            <MenuItem value="en-US">English</MenuItem>
            <MenuItem value="km-KH">Khmer</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "10%",
          }}
        >
          <CommonButton
            title="Next"
            textColor="white"
            disabled={ !isCanNext }
            onClick={() => {
              if(localStorage.getItem("LOCALE_STORAGE_KEY")){
                navigate("/loginmobile");
              }
            }}
            sx={{
              bottom: 0,
              position: "absolute",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default LoginLanguagePage;