import { Box, Typography } from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { ICON_QRCODE } from "../../../constants/appImagePath";
interface CouponHisCardProps {
  state: string;
  useWhere?: string;
  content?: string;
  issuedDate?: string;
  expiredOrUsedDate?: string;
  handleDrawerOpenChange?: any;
  couponId: string;
  setCouponId?: any;
}
const CouponHisCard = ({
  state,
  useWhere,
  content,
  issuedDate,
  expiredOrUsedDate,
  handleDrawerOpenChange,
  couponId,
  setCouponId,
}: CouponHisCardProps) => {
  const ua_g500_issued = LocalizedText("ua_g500_issued");
  const ua_g322_expired = LocalizedText("ua_g322_expired");
  const ua_g322_used = LocalizedText("ua_g322_used");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.03)",
          bgcolor: "white",
          minHeight: 30,
          display: "flex",
        }}
      >
        <HorizontalSpacer width={20} />
        <Box sx={{ width: "100%" }}>
          <VerticalSpacer height={16} />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography fontStyle={fontStyle.bodyMr}>{useWhere}</Typography>
              <Typography
                fontStyle={fontStyle.titleXL}
                color={color.primary500}
              >
                {content}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontStyle={fontStyle.subTitleM}
                  color={color.gray500}
                >
                  {ua_g500_issued}
                </Typography>
                <HorizontalSpacer width={5} />
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                  {issuedDate}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  fontStyle={fontStyle.subTitleM}
                  color={color.gray500}
                >
                  {state == "Used" ? ua_g322_used : ua_g322_expired}
                </Typography>
                <HorizontalSpacer width={5} />
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                  {expiredOrUsedDate}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                bgcolor: color.gray100,
                px: "8px",
                py: "4px",
                borderRadius: "8px",
              }}
            >
              <Typography fontStyle={fontStyle.titleS} color={color.gray700}>
                {state == "Used" ? ua_g322_used : ua_g322_expired}
              </Typography>
            </Box>
          </Box>

          <VerticalSpacer height={16} />
        </Box>

        <HorizontalSpacer width={20} />
      </Box>
    </>
  );
};

export default CouponHisCard;
