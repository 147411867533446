import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import { ModelData, ModelList } from "../model/ModelData";
import { ModelManagementTableRow } from "./ModelManagementTableRow";

interface ModelManagementTableProps {
  headCells?: HeadCell[];
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: number;
}

export function ModelManagementTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  page,
  pageCount,
  setPage,
  docCount,
}: ModelManagementTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(ModelList, rowsPerPage || 10, setPage, page || 0);
  const pagedata: ModelData[] = visibleRows
    .slice(
      (page || 0) * (rowsPerPage || 10),
      ((page || 0) + 1) * (rowsPerPage || 10)
    )
    .map((row) => {
      return new ModelData(
        Number(row.no),
        row.category as string,
        row.modelName as string,
        row.partsCode as string,
        row.productName as string,
        Number(row.lastUpdateDate),
        Number(row.registerDate)
      );
    });
  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {pagedata.map((data: ModelData) => (
        <ModelManagementTableRow row={data} key={data.no} />
      ))}
    </CommonTable>
  );
}
