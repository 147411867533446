import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IssuedHistoryUiState } from "./IssuedHistoryUiState";
import { IssuedHistorySideEffect } from "./IssuedHistorySideEffect";
import IssuedHistoryRender from "./IssuedHistoryRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";

const IssuedHistoryPage = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new IssuedHistoryUiState());

  useAndroidBackHandler(() => {
    window.history.back();
  });

  const sideEffectHandlers: IssuedHistorySideEffect = {
    navigate: function (target: string): void {
      navigate(`/${target}`);
    },
  };

  return (
    <>
      <IssuedHistoryRender uiState={uiState} sideEffect={sideEffectHandlers} />
    </>
  );
};

export default IssuedHistoryPage;