export class User {
  id: string;
  name: string;
  email: string;
  localNumber: string;
  phoneNumber: string;
  birthOfDate: number;
  gender: string;
  countryAddress: string;
  cityAddress: string;
  detailAddress: string;
  constructor(
    id: string,
    name: string,
    email: string,
    localNumber: string,
    phoneNumber: string,
    birthOfDate: number,
    gender: string,
    countryAddress: string,
    cityAddress: string,
    detailAddress: string
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.localNumber = localNumber;
    this.phoneNumber = phoneNumber;
    this.birthOfDate = birthOfDate;
    this.gender = gender;
    this.countryAddress = countryAddress;
    this.cityAddress = cityAddress;
    this.detailAddress = detailAddress;
  }
}

/**
 * @deprecated 이 메서드는 더 이상 사용되지 않습니다. 새로운 유저 정보를 가져오는 방법을 고민하고 있습니다.
 * 일단 localStorage 에서 가져오는 방식으로 사용합니다.
 */
export const mockUser = new User(
  `${localStorage.getItem("uuid")}`, // uuid //스쿠터 인계 테스트 하고 싶다면 123e4567-e89b-12d3-a456-426614174000 이 uuid 로 테스트 가능
  `${localStorage.getItem("name")}`, // 이름
  `${localStorage.getItem("email")}`, // 이메일
  "+82", // 지역번호
  `${localStorage.getItem("user_phone_number")}`, // 전화번호
  909619200000, // 생일(unix 시간)
  "male", // 성별
  "korea", // 국가
  "용인시 수지구", // 도시(시/군/구)
  "동천역 2번출구" // 상세주소
);
