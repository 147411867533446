import LoginTermsOfUseRender from "./LoginTermsOfUseRender";

const LoginTermsOfUsePage = () => {
  return (
    <>
      <LoginTermsOfUseRender />
    </>
  );
};

export default LoginTermsOfUsePage;
