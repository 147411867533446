import { useState } from "react";
import { Box } from "@mui/material";
import { Text } from "../Text";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import VerticalSpacer from "../Spacer";
import { PeriodButton, PeriodButtonType } from "./PeriodButton";
import DatePickerButton, { SearchButton } from "./DatePickerButton";
import LocalizedDate, { DateFormatType } from "../LocalizedDate";

interface InquiryPeriodProps {
  isSelectedCustom: boolean;
  onClickSearch: () => void;
  startDate: number;
  endDate: number;
  onClickStartDate: () => void;
  onClickEndDate: () => void;
  onPeriodChange: (type: PeriodButtonType) => void;
}

export default function InquiryPeriod({
  isSelectedCustom,
  onClickSearch,
  startDate,
  endDate,
  onClickStartDate,
  onClickEndDate,
  onPeriodChange,
}: InquiryPeriodProps) {
  const [period, setPeriod] = useState<PeriodButtonType>(PeriodButtonType.ALL);

  const startdateText = LocalizedText("ua_g421_startdate");
  const enddateText = LocalizedText("ua_g421_enddate");

  const periodButtonList = [
    {
      title: LocalizedText("ua_g421_all"),
      isSelected: false,
      type: PeriodButtonType.ALL,
    },
    {
      title: LocalizedText("ua_g421_1month"),
      isSelected: false,
      type: PeriodButtonType.ONE_MONTH,
    },
    {
      title: LocalizedText("ua_g421_3month"),
      isSelected: false,
      type: PeriodButtonType.THREE_MONTH,
    },
    {
      title: LocalizedText("ua_g421_custom"),
      isSelected: false,
      type: PeriodButtonType.CUSTOM,
    },
  ];

  const handlePeriodClick = (type: PeriodButtonType) => {
    setPeriod(type);
    onPeriodChange(type);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {/* 조회기간 Title */}
      <Text
        sx={{
          fontStyle: fontStyle.titleM,
          color: color.gray800,
        }}
        text={LocalizedText("ua_f210_inquiryperiod")}
      />
      <VerticalSpacer height={6} />

      {/* 조회기간 버튼 */}
      <Box
        sx={{
          display: "flex",
          gap: "8px",
        }}
      >
        {periodButtonList.map((item, index) => (
          <PeriodButton
            key={index}
            model={{
              ...item,
              isSelected: item.type === period,
            }}
            onClick={() => handlePeriodClick(item.type)}
          />
        ))}
      </Box>
      <VerticalSpacer height={8} />

      {isSelectedCustom && (
        <Box
          sx={{
            display: "flex",
            gap: "8px",
          }}
        >
          <DatePickerButton
            isSelected={startDate !== 0}
            text={
              startDate == 0
                ? startdateText
                : `${LocalizedDate({
                    date: startDate,
                    type: DateFormatType.YearMonthDay,
                  })}`
            }
            onClick={onClickStartDate}
          />
          <DatePickerButton
            isSelected={endDate !== 0}
            text={
              endDate == 0
                ? enddateText
                : `${LocalizedDate({
                    date: endDate,
                    type: DateFormatType.YearMonthDay,
                  })}`
            }
            onClick={onClickEndDate}
          />
          <SearchButton
            onClick={onClickSearch}
            isDisabled={startDate === 0 || endDate === 0}
          />
        </Box>
      )}
    </Box>
  );
}
