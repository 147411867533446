import CommonList from "../../component/CommonList";
import { CommonWebHeaderButton } from "../../component/CommonWebHeaderButton";
import { ModelManagementPage } from "./section/ModelManagementPage";

const ModelManagement = () => {
  return (
    <CommonList
      title="모델 관리"
      headerButton={
        <CommonWebHeaderButton
          width="122px"
          height="45px"
          buttonContent="Register"
          addIcon={true}
        />
      }
    >
      <ModelManagementPage />
    </CommonList>
  );
};

export default ModelManagement;
