import { Box } from "@mui/material";
import { InquiryPageUiState } from "./InquiryPageUiState";
import { InquiryPageSideEffect } from "./InquiryPageSideEffect";
import Toolbar from "../../component/Toolbar";
import VerticalSpacer from "../../component/Spacer";
import { Divider, HorizontalGrayDivider } from "../../component/Divider";
import { InquiryPageUiEvent } from "./InquiryPageUiEvent";
import { PeriodButtonType } from "./section/PeriodButtonType";
import { FAQBannerButton } from "./section/FAQBannerButton";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import { Text } from "../../component/Text";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { ROUTE_PATHS } from "../../constants/RouteConstants";
import InquiryPeriod from "../../component/InquiryPeriod/InquiryPeriod";
import InquiryHistoryList from "./section/InquiryHistoryList";
import CalendarDrawer, {
  CalendarType,
} from "../../component/InquiryPeriod/CalendarDrawer";
import CommonButton from "../../component/CommonButton";

interface InquiryPageRenderProps {
  uiState: InquiryPageUiState;
  sideEffect: InquiryPageSideEffect;
  uiEvent: InquiryPageUiEvent;
}

export default function InquiryPageRender({
  uiState,
  sideEffect,
  uiEvent,
}: InquiryPageRenderProps) {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        title={LocalizedText("ua_f210_inquiry")}
        onBack={() => {
          sideEffect.back();
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "20px",
          flexShrink: 0,
        }}
      >
        <VerticalSpacer height={8} />
        <Text
          sx={{
            fontStyle: fontStyle.bodyLr,
            color: color.gray900,
            whiteSpace: "pre-line",
          }}
          text={LocalizedText("ua_f210_cantfind")}
        />

        <VerticalSpacer height={12} />
        <FAQBannerButton
          onClick={() => {
            sideEffect.navigate(ROUTE_PATHS.FAQ_LIST);
          }}
        />
        <VerticalSpacer height={16} />
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1, // 남은 공간을 모두 차지
          overflowY: "auto", // 이 박스에만 스크롤 허용
        }}
      >
        {/*  이박스 */}
        <VerticalSpacer height={16} />
        <Text
          sx={{
            px: "20px",
            fontStyle: fontStyle.titleL,
            color: color.default,
          }}
          text={LocalizedText("ua_f210_inquiryhistory")}
        />
        <VerticalSpacer height={16} />
        <HorizontalGrayDivider />
        <VerticalSpacer height={16} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: "20px",
          }}
        >
          <InquiryPeriod
            isSelectedCustom={uiState.isVisibleDataPicker}
            startDate={uiState.startDate}
            endDate={uiState.endDate}
            onPeriodChange={(type) => {
              if (type === PeriodButtonType.CUSTOM) {
                uiEvent.openDatePicker();
              } else {
                uiEvent.closeDatePicker();
                const currentDate = new Date().getTime();
                switch (type) {
                  case PeriodButtonType.ALL:
                    sideEffect.requestInquiryHistory(
                      undefined,
                      currentDate,
                      100,
                    );
                    break;
                  case PeriodButtonType.ONE_MONTH:
                    sideEffect.requestInquiryHistory(undefined, currentDate, 1);
                    break;
                  case PeriodButtonType.THREE_MONTH:
                    sideEffect.requestInquiryHistory(undefined, currentDate, 3);
                    break;
                }
              }
            }}
            onClickStartDate={() => uiEvent.openCalendar(CalendarType.START)}
            onClickEndDate={() => uiEvent.openCalendar(CalendarType.END)}
            onClickSearch={() => {
              sideEffect.requestInquiryHistory(
                uiState.startDate,
                uiState.endDate,
                undefined,
              );
            }}
          />
        </Box>
        <VerticalSpacer height={16} />
        <HorizontalGrayDivider />
        <Box
          sx={{
            px: "20px",
            py: "16px",
          }}
        >
          <InquiryHistoryList
            inquiryHistories={uiState.historyList}
            onClick={(id) => {
              sideEffect.navigate(ROUTE_PATHS.INQUIRY_DETAIL, {
                state: { id },
              });
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          px: "20px",
          py: "20px",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          boxSizing: "border-box",
        }}
      >
        <CommonButton
          title={LocalizedText("ua_f210_registerinquiry_button")}
          onClick={() => {
            sideEffect.navigate(ROUTE_PATHS.INQUIRY_REGISTRATION);
          }}
        />
      </Box>

      <CalendarDrawer
        title={LocalizedText("ua_f210_modal_selectdate")}
        isOpen={uiState.isCalendarOpen}
        onClose={() => {
          uiEvent.closeCalendar(uiState.calendarType, uiState.selectedDate);
        }}
        onSelectDate={(date) => {
          uiEvent.selectDate(date);
        }}
        onInvalidDate={uiEvent.showDateAlert}
        selectedDate={uiState.selectedDate}
        startDate={uiState.startDate}
        endDate={uiState.endDate}
        type={uiState.calendarType}
      />

      <SimpleDialog
        isOpen={uiState.isDateAlertOpen}
        onPositive={() => {
          uiEvent.closeCalendar(uiState.calendarType, 0);
          uiEvent.hideDateAlert();
        }}
        onClose={uiEvent.hideDateAlert}
        children={LocalizedText("ua_f210_modal_error")}
        positiveText="OK"
      />
    </Box>
  );
}
