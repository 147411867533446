import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NFTDetailUiState } from "./NFTDetailUiState";
import { NFTDetailSideEffect } from "./NFTDetailSideEffect";
import NFTDetailRender from "./NFTDetailRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { NFTItem } from "../model/WalletNFT";
import { ApiService } from "../../../restAPI/ApiService";
import { useLanguageRepository } from "../../../di/LanguageRepositoryProvider";

const NFTDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [uiState, setUiState] = useState(new NFTDetailUiState());
  const languageRepository = useLanguageRepository();

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    setUiState((prev) => ({
      ...prev,
      imageLoadError: false,
    }));
  }, [location.state]);
    

  useEffect(() => {
    const { tokenId, type } = location.state as {
      tokenId: string;
      type: string;
    };
    (async () => {
      if (tokenId && type) {
        await updateNFTDetail(tokenId, type);
      }
    })();
  }, []);

  const sideEffectHandlers: NFTDetailSideEffect = {
    navigate: function (target: string): void {
      navigate(`/${target}`);
    },
    back: function (): void {
      window.history.back();
    },
  };

  // NFT Detail 정보를 가져오는 함수
  async function getNFTDetail(
    tokenId: string,
    type: string
  ): Promise<NFTItem | null> {
    try {
      let nftItem: NFTItem | null = null;

      const requestBody = {
        tokenId: tokenId,
        type: type,
      };
      await ApiService.BlockchainPost("/nft/getNft", requestBody).then(
        (res) => {
          nftItem = res?.data.body;
          console.log("NFT Detail Response", nftItem);
        }
      );
      return nftItem;
    } catch (e) {
      console.log("Certificate Info Error", e);
      return null;
    }
  }

  async function updateNFTDetail(tokenId: string, type: string) {
    const nftItem = await getNFTDetail(tokenId, type);
    if (nftItem) {
      setUiState((prev) => ({
        ...prev,
        nftDetail: nftItem,
      }));
    }
  }

  return (
    <>
      <NFTDetailRender
        uiState={uiState}
        sideEffect={sideEffectHandlers}
        languageRepository={languageRepository}
        onImageLoadError={() => {
          setUiState((prev) => ({
            ...prev,
            imageLoadError: true,
          }));
        }}
        onImageLoad={() => {
          setUiState((prev) => ({
            ...prev,
            imageLoadError: false,
          }));
        }}
      />
    </>
  );
};

export default NFTDetailPage;
