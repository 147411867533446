import { InquiryHistoryStatus } from "../../model/InquiryHistoryStatus";

export class Inquiry {
  title: string;
  imageUrls: string[];
  registrationDate: number;
  content: string;
  id: string;
  category: string;
  status: InquiryHistoryStatus;

  constructor(
    inquiryTitle: string,
    imageUrls: string[],
    registrationDate: number,
    inquiryContent: string,
    id: string,
    category: string,
    status: InquiryHistoryStatus,
  ) {
    this.title = inquiryTitle;
    this.imageUrls = imageUrls;
    this.registrationDate = registrationDate;
    this.content = inquiryContent;
    this.id = id;
    this.category = category;
    this.status = status;
  }
}
