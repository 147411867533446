import { useEffect, useState } from "react";
import FAQListRender from "./FAQListRender";
import { FAQListUiState } from "./FAQListUiState";
import { FAQListUiEvent } from "./FAQListUiEvent";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../../restAPI/ApiService";
import { Category } from "./model/Category";
import useAndroidBackHandler from "../../../../customHook/useAndroidBackHandler";
import { getCountryCode } from "../../../../customHook/useLocale";
import { ROUTE_PATHS } from "../../../../constants/RouteConstants";

export default function FAQListPage() {
  const [uiState, setUiState] = useState(new FAQListUiState([], []));
  const navigate = useNavigate();

  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  useEffect(() => {
    getCategoryList().then((r) => {});
  }, []);

  useEffect(() => {
    uiState.categoryItems.map((it, i) => {
      if (it.isSelected) {
        getFAQList(1, it.label).then((r) => {});
      }
    });
  }, [uiState.categoryItems]);

  const uiEventHandler: FAQListUiEvent = {
    selectCategory: (category: Category) => {
      setUiState((prev) => {
        return {
          ...prev,
          categoryItems: prev.categoryItems.map((item, i) => {
            return {
              ...item,
              isSelected: item === category,
            };
          }),
        };
      });
    },

    selectQuestion: (faq) => {
      navigate(ROUTE_PATHS.FAQ_DETAIL, {
        state: { id: faq.id, uuid: localStorage.getItem("uuid") ?? "" },
      });
    },

    navigate: (path) => {
      navigate(path);
    },
  };

  async function getCategoryList() {
    const requestBody = {
      country: getCountryCode(),
    };
    await ApiService.MobilePost(
      "/pw/ua/contents/faq/getCategory",
      requestBody,
    ).then((res) => {
      let categories = res?.data.body;
      let categoryList: Category[] = [];
      if (categories && categories.lenght !== 0) {
        for (let i = 0; i < categories.length; i++) {
          categoryList.push({
            label: categories[i],
            isSelected: i === 0,
          });
        }
      }
      setUiState((prev) => {
        return { ...prev, categoryItems: categoryList };
      });
    });
  }

  async function getFAQList(page: number, category: string) {
    const requestBody = {
      page: page,
      category: category,
      country: getCountryCode(),
    };
    await ApiService.MobilePost("/pw/ua/contents/faq/list", requestBody).then(
      (res) => {
        const body = res?.data.body;
        setUiState((prev) => {
          return {
            ...prev,
            questions: body ? body : [],
          };
        });
      },
    );
  }

  return <FAQListRender uiState={uiState} uiEvent={uiEventHandler} />;
}
