import { CalendarType } from "../../../component/InquiryPeriod/CalendarDrawer";
import PurchaseHistoryItem from "../model/PurchaseHistoryItem";

export class PurchaseHistoryUiState {
  currentTab: string = "Membership";
  isVisibleDataPicker: boolean = false;
  calendarType: CalendarType = CalendarType.START;
  startDate: number = 0;
  endDate: number = 0;
  periodDuration: number | undefined = 100; // 기간 단위 (개월)
  isCalendarOpen: boolean = false;
  selectedDate: number = 0;
  isDateAlertOpen: boolean = false;
  historyList: PurchaseHistoryItem[] = [];
}
