import { Box, Card, Grid } from "@mui/material";
import CommonList from "../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";
import { color } from "../../../../../theme/Color";
import { MaterialBOMTree } from "./MaterialBOMTree";
import { useNavigate } from "react-router-dom";

export function MaterialRegistration() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/registerBillOfMaterials"); // 버튼 클릭 시 상태 변경
  };
  return (
    <CommonList
      title="자재 등록"
      headerBackButton={true}
      navigateURL={`/productionManagement?tab=materials`}
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Register"
          height="45px"
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={onClick}
        />
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <MaterialBOMTree />
        </Card>
      </Grid>
    </CommonList>
  );
}
