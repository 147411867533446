import { Box, Grid } from "@mui/material";
import { color } from "../../../theme/Color";
import { ICON_ADD } from "../../../constants/imagePath";

import MembershipVoucherCard from "./MembershipVoucherCard";
import { MembershipVoucherUiEvent } from "../model/MembershipVoucherUiEvent";
import { MembershipVoucherUiState } from "../model/MembershipVoucherUiState";
import dayjs, { Dayjs } from "dayjs";

interface MembershipVoucherGridProps {
  rows: any;
  countryLists: any;
  uiState: MembershipVoucherUiState;
  uiEvent: MembershipVoucherUiEvent;
}
const MembershipVoucherGrid = ({
  rows,
  countryLists,
  uiState,
  uiEvent,
}: MembershipVoucherGridProps) => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {rows.map((data: any, index: any) => {
        return (
          <MembershipVoucherCard
            key={index}
            data={data}
            countryLists={countryLists}
            uiState={uiState}
            uiEvent={uiEvent}
          />
        );
      })}
      <Grid item lg={4}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            minWidth: "330px",
            height: "100%",
            minHeight: "704px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "80px",
              height: "80px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: color.primary500,
              borderRadius: "80px",
              cursor: "pointer",
              ":hover": {
                backgroundColor: color.primary300,
                "& .hover-content": {
                  opacity: 1, // 호버 시 보이도록
                  visibility: "visible", // 호버 시 보이도록
                },
              },
            }}
            onClick={() => {
              // id가 빈 문자열인 항목이 이미 있는지 확인
              const isIdEmptyExists = uiState.MVDataRows.some(
                (row) => row.id === ""
              );

              // id가 빈 문자열인 항목이 없다면 새로운 데이터 로우 추가
              const today: Dayjs = dayjs(); // 현재 날짜와 시간을 today 변수에 저장
              const minDate = today.add(30, "day");
              if (!isIdEmptyExists) {
                uiEvent.HandleUiStateChange("MVDataRows", [
                  ...uiState.MVDataRows, // 기존 배열 데이터 유지
                  {
                    country: "Select",
                    description: "",
                    distributeDate: minDate.valueOf(),
                    id: "",
                    label: ["", ""],
                    name: "",
                    price: 0,
                    type: "Select",
                    uuid: localStorage.getItem("user_uuid"),
                  }, // 추가할 새로운 데이터
                ]);
              } else {
                alert("이전 카드를 Post 하십시오");
              }
            }}
          >
            <Box component="img" src={ICON_ADD} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MembershipVoucherGrid;
